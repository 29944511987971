import { SharedStateConfig, sharedState } from "../shared-state";
import { CategoriesData, onCategoriesSnapshot } from "../../lib/categories";

//
// Loads incidentCategories
//

export const incidentCategoriesConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.incidentCategories.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onCategoriesSnapshot(
                'incidentCategories',
                'licenseeId',
                licenseeId,
                (data) => { // onLoaded
                    done();
                    set(data);
                },
                (error) => { // onError
                    done();
                    clear();
                    console.log(`Error getting incidentCategories`, error);
                }
            );
        }
    }
};
