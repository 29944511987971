import React from 'react';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import { IonAlert } from '@ionic/react';
import './AlertManager.css';

//
// Handles showing the user an alert message
//

export type AlertMessage = string | number | Element[] | (() => void) | undefined;

export const alertMessageConfig: SharedStateConfig<AlertMessage> = {
    isAlwaysActive: true,
    default: '',
    notes: 'Allows components to show an alert message.'
};

export const alertMessage = (message?: AlertMessage) => {
    sharedState.alertMessage.set(message);
};

export const todo = (message: string) => {
    sharedState.alertMessage.set(`${message}<br/><br/>This feature has not been implemented yet.`);
};

const AlertManager: React.FC = () => {
    const alertMessage = sharedState.alertMessage.use();
    return <IonAlert
        isOpen={(alertMessage) ? true : false}
        message={alertMessage as any}
        buttons={['OK']}
        onDidDismiss={(e) => sharedState.alertMessage.set('')}
    />
};

export default AlertManager;
