import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { useReportSettings } from '../Reporting';
import { auhoritiesNotifiedForReviewByRegion, authoritiesNotifiedForReviewLabels, currentIncidentStatuses, incidentTypes, incidentWhoInvolvedTypes, injuryConclusions } from '../../HealthSafety/Incidents/incidents-util';
import { sharedState } from '../../../shared-state/shared-state';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import reporting, { colours } from '../../../lib/reporting';
import SeaPieGraph from '../../../components/reporting/SeaPieGraph/SeaPieGraph';
import ViewIncidentTypesReport from '../../../modals/Reporting/HealthSafety/ViewIncidentTypesReport/ViewIncidentTypesReport';
import ViewIncidentCategoriesReport from '../../../modals/Reporting/HealthSafety/ViewIncidentCategoriesReport/ViewIncidentCategoriesReport';
import ViewIncidentNotifiedReport from '../../../modals/Reporting/HealthSafety/ViewIncidentNotifiedReport/ViewIncidentNotifiedReport';
import ViewIncidentPersonnelInvolvedReport from '../../../modals/Reporting/HealthSafety/ViewPersonnelInvolvedReport/ViewPersonnelInvolvedReport';
import ViewIncidentInjuryOutcomesReport from '../../../modals/Reporting/HealthSafety/ViewInjuryOutcomesReport/ViewInjuryOutcomesReport';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import ViewIncidentStatusReport from '../../../modals/Reporting/HealthSafety/ViewIncidentStatusReport/ViewIncidentStatusReport';
import ViewContributingFactorsReport from '../../../modals/Reporting/HealthSafety/ViewContributingFactorsReport/ViewContributingFactorsReport';
import ViewInjuryTypesReport from '../../../modals/Reporting/HealthSafety/ViewInjuryTypesReport/ViewInjuryTypesReport';
import ViewInjuryLocationsReport from '../../../modals/Reporting/HealthSafety/ViewInjuryLocationsReport/ViewInjuryLocationsReport';
import './HealthSafetyReporting.css';
import { Incident } from '../../../shared-state/HealthSafety/incidents';

interface HealthSafetyReportingProps {
    visible: boolean,
    graphAvailability: {
        count: number,
        incidentStatus: boolean,
        incidentTypes: boolean,
        incidentCategories: boolean,
        contributingFactors: boolean,
        injuryTypes: boolean,
        injuryLocations: boolean,
        incidentsNotified: boolean,
        incidentPersonnelInvolved: boolean,
        incidentInjuryOutcomes: boolean
    }
}

const HealthSafetyReporting: React.FC<HealthSafetyReportingProps> = ({
    visible,
    graphAvailability
}) => {
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const incidents = sharedState.incidents.use(visible ? 1 : 101);
    const incidentCategories = sharedState.incidentCategories.use(visible ? 1 : 101);
    const incidentReviews = sharedState.incidentReviews.use(visible ? 1 : 101);
    const incidentCauses = sharedState.incidentCauses.use(visible ? 1 : 101);
    const injuryTypes = sharedState.injuryTypes.use(visible ? 1 : 101);
    const injuryLocations = sharedState.injuryLocations.use(visible ? 1 : 101);
    const licenseeSettings = sharedState.licenseeSettings.use(visible ? 1 : 101);
    const { selectedVesselId, selectedVesselIds, dateRange, dateRangeDescription } = useReportSettings();
    const [modalToShow, setModalToShow] = useState<string>();
    const [selectedIncidentStatuses, setSelectedIncidentStatuses] = useState(Object.keys(currentIncidentStatuses));

    const vesselName = useMemo(() => {
        if (selectedVesselId && vessels?.byId[selectedVesselId]) {
            return ' - ' + vessels.byId[selectedVesselId].name;
        }
        return '';
    }, [selectedVesselId, vessels?.byId]);

    useEffect(() => {
        if (visible) {
            logPageView('Reporting/HealthSafetyReporting');
        }
    }, [visible]);

    // const demoIncidentsByStatus = useMemo(() => {
    //     const data = {
    //         title: "Demo Incidents/Events by Vessel",
    //         subTitle: "(Live Status)",
    //         data: [] as any[],
    //         units: undefined,
    //         categories: ['Draft', 'For Review', 'In Review'],
    //     }
    //     let seed = 10;
    //     const random = () => {
    //         var x = Math.sin(seed++) * 10000;
    //         return x - Math.floor(x);
    //     }
    //     for (let i = 1; i <= numVessels; i++) {
    //         data.data.push({
    //             name: `Vessel ${i === 2 ? 'with no data to display ' : ''}#${i}`,
    //             values: Object.keys(currentIncidentStatuses).map((statusId: string) => {
    //                 if (selectedIncidentStatuses.indexOf(statusId) !== -1) {
    //                     return (i === 2) ? 0 : Math.floor(random()*6);
    //                 }
    //                 return 0;
    //             })
    //         });
    //     }
    //     return data;
    // }, [numVessels, selectedIncidentStatuses]);

    // const demoContributingFactors = useMemo(() => {
    //     const data = {
    //         title: "Demo Contributing Factors",
    //         data: [] as any[],
    //         //units: undefined
    //         units: 'Events / Incidents',
    //         sortData: true,
    //     }
    //     let seed = 10;
    //     const random = () => {
    //         var x = Math.sin(seed++) * 10000;
    //         return x - Math.floor(x);
    //     }
    //     for (let i = 1; i <= numVessels; i++) {
    //         data.data.push({
    //             name: `Factor ${i === 3 ? 'with no data to display ' : ''}#${i}`,
    //             value: (i === 3) ? 0 : Math.floor(random()*30)
    //         });
    //     }
    //     return data;
    // }, [numVessels]);

    const filteredIncidents = useMemo(() => {
        if (
            visible &&
            graphAvailability.count > 0 &&
            incidents?.all &&
            selectedVesselIds?.length > 0
        ) {
            const filtered = [];
            const whenTo = dateRange.to + (24*60*60*1000);
            let index = 0;
            // Skip incidents that are after dateRange.to
            for (; index < incidents.all.length; index++) {
                if (incidents.all[index].whenEvent < whenTo) {
                    break;
                }
            }
            // Traverse incidents that are from dateRange.from
            for (; index < incidents.all.length; index++) {
                const incident = incidents.all[index];
                if (incident.whenEvent < dateRange.from) {
                    break;
                }
                if (
                    incident.state !== 'draft' &&
                    selectedVesselIds.indexOf(incident.vesselId) !== -1
                ) {
                    filtered.push(incident);
                }
            }
            return filtered;
        }
        return undefined;
    }, [visible, graphAvailability, incidents, selectedVesselIds, dateRange]);

    const filteredCurrentIncidents = useMemo(() => {
        if (
            visible &&
            graphAvailability.count > 0 &&
            incidents?.all &&
            selectedIncidentStatuses
        ) {
            const filtered: Incident[] | undefined = [];
            incidents.all.forEach((incident) => {
                if (selectedIncidentStatuses.indexOf(incident.state) !== -1 && selectedVesselIds.indexOf(incident.vesselId) !== -1) {
                    filtered.push(incident);
                }
            });
            return filtered;
        }
        return undefined;
    }, [visible, graphAvailability, incidents?.all, selectedIncidentStatuses, selectedVesselIds]);

    const currentIncidentsGraphData = useMemo(() => {
        if (
            filteredCurrentIncidents &&
            selectedVesselIds
        ) {
            const array: GraphData[] = [] ;
            const byVesselId: {
                [vesselId: string]: GraphData
            }= {};
            const indexByStatus = {
                'draft': 0,
                'forReview': 1,
                'inReview': 2,
                'completed': 3
            };
            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0, 0]
                }
                byVesselId[vesselId] = item;
                array.push(item);
            });
            filteredCurrentIncidents.forEach((incident) => {
                if (
                    selectedIncidentStatuses.indexOf(incident.state) >= 0 &&
                    byVesselId[incident.vesselId]
                ) {
                    byVesselId[incident.vesselId].values![indexByStatus[incident.state]]++;
                }
            });
            return array;
        }
        return undefined;
    }, [filteredCurrentIncidents, selectedVesselIds, selectedIncidentStatuses]);

    const selectedIncidentStatusNames = useMemo(() => {
        return Object.keys(currentIncidentStatuses).map((statusId: string) => {
            if (selectedIncidentStatuses.indexOf(statusId) !== -1) {
                return currentIncidentStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        }).filter((v) => v !== undefined) as string[];
    }, [selectedIncidentStatuses]);

    const filteredNotifiableIncidents = useMemo(() => {
        if (
            filteredIncidents &&
            incidentReviews?.byId
        ) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.state === 'completed') {
                    const review = incidentReviews.byId[incident.id];
                    if (review?.notifiedAuthorities) {
                        filtered.push(incident);
                    }
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents, incidentReviews]);

    const filteredWhoInvolvedIncidents = useMemo(() => {
        if (filteredIncidents) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.whoInvolvedTypes?.length > 0) {
                    filtered.push(incident);
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents]);

    const filteredInjuryOutcomes = useMemo(() => {
        if (filteredIncidents && incidentReviews?.byId) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.state === 'completed') {
                    const review = incidentReviews.byId[incident.id];
                    if (review?.injuryConclusions && review?.injuryConclusions?.length > 0) {
                        filtered.push(incident);
                    }
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents, incidentReviews]);

    const incidentTypesGraphData = useMemo(() => {
        if (filteredIncidents) {
            const totals: {
                [key: string]: number
            } = {};
            Object.keys(incidentTypes).forEach((key: string) => {
                totals[key] = 0;
            });
            filteredIncidents.forEach((incident) => {
                totals[incident.type]++;
            });
            // Convert to format SeaPieGraph expects
            const data: GraphData[] = [];
            Object.keys(incidentTypes).forEach((key) => {
                data.push({
                    name: incidentTypes[key],
                    value: totals[key]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredIncidents]);

    const incidentCategoriesGraphData = useMemo(() => {
        if (filteredIncidents && incidentCategories?.byId) {
            const totals: {
                [key: string]: number
            } = {};
            const categoryIds = [] as string[];
            incidentCategories.ids.forEach((categoryId: string) => {
                if (incidentCategories.byId[categoryId].state === 'active') {
                    categoryIds.push(categoryId);
                    totals[categoryId] = 0;
                }
            });
            filteredIncidents.forEach((incident) => {
                if (incident.categoryId && totals[incident.categoryId] !== undefined) {
                    totals[incident.categoryId]++;
                }
            });
            // Convert to format SeaPieGraph expects
            const data: GraphData[] = [];
            categoryIds.forEach((key) => {
                data.push({
                    name: incidentCategories.byId[key].name,
                    value: totals[key]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredIncidents, incidentCategories]);

    const notifiableIncidentsGraphData = useMemo(() => {
        if (filteredIncidents && incidentReviews?.byId && licenseeSettings) {
            const totals: {
                [key: string]: number
            } = {};
            const notifiableTypes = auhoritiesNotifiedForReviewByRegion[licenseeSettings.region];
            notifiableTypes.forEach((type: string) => {
                totals[type] = 0;
            });
            filteredIncidents.forEach((incident) => {
                if (incident.state === 'completed') {
                    const review = incidentReviews.byId[incident.id];
                    if (review?.notifiedAuthorities) {
                        totals[review.notifiedAuthorities]++;
                    }
                }
            });
            // Convert to format SeaPieGraph expects
            const data: GraphData[] = [];
            notifiableTypes.forEach((type: string) => {
                data.push({
                    name: authoritiesNotifiedForReviewLabels[type],
                    value: totals[type]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredIncidents, incidentReviews, licenseeSettings]);

    const incidentWhoInvolvedGraphData = useMemo(() => {
        if (filteredIncidents) {
            const totals: {
                [key: string]: number
            } = {};
            Object.keys(incidentWhoInvolvedTypes).forEach((type: string) => {
                totals[type] = 0;
            });
            filteredIncidents.forEach((incident) => {
                incident?.whoInvolvedTypes?.forEach((type: string) => {
                    totals[type]++;
                });
            });
            // Convert to format SeaPieGraph expects
            const data: GraphData[] = [];
            Object.keys(incidentWhoInvolvedTypes).forEach((type: string) => {
                data.push({
                    name: incidentWhoInvolvedTypes[type],
                    value: totals[type]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredIncidents]);

    const incidentInjuryConclusionsGraphData = useMemo(() => {
        if (filteredIncidents && incidentReviews?.byId) {
            const totals: {
                [key: string]: number
            } = {};
            Object.keys(injuryConclusions).forEach((conclusion: string) => {
                totals[conclusion] = 0;
            });
            filteredIncidents.forEach((incident) => {
                if (incident.state === 'completed') {
                    const review = incidentReviews.byId[incident.id];
                    review?.injuryConclusions?.forEach((conclusion: string) => {
                        totals[conclusion]++;
                    });
                }
            });
            // Convert to format SeaPieGraph expects
            const data: GraphData[] = [];
            Object.keys(injuryConclusions).forEach((conclusion: string) => {
                data.push({
                    name: injuryConclusions[conclusion],
                    value: totals[conclusion]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredIncidents, incidentReviews]);

    const filteredContributingFactorIncidents = useMemo(() => {
        if (filteredIncidents) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.causeIds?.length > 0) {
                    filtered.push(incident);
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents]);

    const incidentContributingFactorsGraphData = useMemo(() => {
        if (filteredContributingFactorIncidents && incidentCauses?.ids) {
            const data: GraphData[] = [];
            const totals: {
                [key: string]: number
            } = {};
            incidentCauses.ids.forEach((causeId: string) => {
                totals[causeId] = 0;
            });
            filteredContributingFactorIncidents.forEach((incident) => {
                incident.causeIds?.forEach((causeId: string) => {
                    totals[causeId]++;
                });
            });
            // Convert to format SeaHorizontalBarGraph expects
            incidentCauses.ids.forEach((causeId: string) => {
                data.push({
                    name: incidentCauses.byId[causeId].name,
                    value: totals[causeId]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredContributingFactorIncidents, incidentCauses]);

    const filteredInjuryTypeIncidents = useMemo(() => {
        if (filteredIncidents) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.injuries && incident.injuries?.length > 0) {
                    for (let i = 0; i < incident.injuries.length; i++) {
                        if (incident.injuries[i].typeIds?.length && (incident.injuries[i].typeIds?.length as number) > 0) {
                            filtered.push(incident);
                            break;
                        }
                    }
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents]);

    const incidentInjuryTypesGraphData = useMemo(() => {
        if (filteredInjuryTypeIncidents && injuryTypes?.ids) {
            const data: GraphData[] = [];
            const totals: {
                [key: string]: number
            } = {}
            injuryTypes.ids.forEach((typeId: string) => {
                totals[typeId] = 0;
            });
            filteredInjuryTypeIncidents.forEach((incident) => {
                incident.injuries?.forEach((injury) => {
                    injury.typeIds?.forEach((typeId: string) => {
                        totals[typeId]++;
                    });
                });
            });
            // Convert to format SeaHorizontalBarGraph expects
            injuryTypes.ids.forEach((typeId: string) => {
                data.push({
                    name: injuryTypes.byId[typeId].name,
                    value: totals[typeId]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredInjuryTypeIncidents, injuryTypes]);

    const filteredLocationIncidents = useMemo(() => {
        if (filteredIncidents) {
            const filtered: Incident[] = [];
            filteredIncidents.forEach((incident) => {
                if (incident.injuries?.length && incident.injuries?.length > 0) {
                    for (let i = 0; i < incident.injuries.length; i++) {
                        if (incident.injuries[i].locationIds?.length && incident.injuries[i].locationIds?.length as number > 0) {
                            filtered.push(incident);
                            break;
                        }
                    }
                }
            });
            return filtered;
        }
        return undefined;
    }, [filteredIncidents]);

    const incidentLocationsGraphData = useMemo(() => {
        if (filteredLocationIncidents && injuryLocations?.ids) {
            const data: GraphData[] = [];
            const totals: {
                [key: string]: number
            } = {};
            injuryLocations.ids.forEach((locationId: string) => {
                totals[locationId] = 0;
            });
            filteredLocationIncidents.forEach((incident) => {
                incident.injuries?.forEach((injury) => {
                    injury.locationIds?.forEach((locationId: string) => {
                        totals[locationId]++;
                    });
                });
            });
            // Convert to format SeaHorizontalBarGraph expects
            injuryLocations.ids.forEach((locationId: string) => {
                data.push({
                    name: injuryLocations.byId[locationId].name,
                    value: totals[locationId]
                });
            });
            return data;
        }
        return undefined;
    }, [filteredLocationIncidents, injuryLocations]);

    const dateRangeSubTitle = useMemo(() => {
        return `(${dateRangeDescription})`;
    }, [dateRangeDescription]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            {/* <SeaNoData
                dataName="contacts"
                isLoading={!contacts}
                hasNoData={contacts?.all?.length === 0}
                isUsingFilter={false}
            /> */}
            
            {/* <div className="columns" style={{ marginBottom: '20px' }}>
                <div style={{ alignSelf: 'center', paddingRight: '12px' }}>
                    Test number of vessels:
                </div>
                <div style={{ width: '100px', padding: '4px 4px 0px 0px' }}>
                    <SeaInput
                        //label="Number of Vessels"
                        value={numVessels}
                        style={{ height: '40px '}}
                        onchange={(e) => {
                            setNumVessels((current: number) => {
                                try {
                                    const x = parseInt(e.detail.value);
                                    if (!isNaN(x)) {
                                        return x;
                                    }
                                } catch(e) {}
                                return 1;
                            });
                        }}
                    />
                </div>
                <div>
                    <SeaButton
                        shape="square"
                        onClick={(e) => setNumVessels((current) => current - 1)}
                    >-</SeaButton>
                </div>
                <div>
                    <SeaButton
                        shape="square"
                        onClick={(e) => setNumVessels((current) => current + 1)}
                    >+</SeaButton>
                </div>
            </div> */}
            <div className={`reporting-grid max-${graphAvailability.count}-graphs`}>
                {graphAvailability.incidentStatus &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title="Current Incidents/Events by Vessel"
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('incidentStatus');
                        }}
                        data={currentIncidentsGraphData}
                        units="Events/Incidents"
                        categories={selectedIncidentStatusNames}
                        colourPalette={reporting.colours.incidentStatuses}
                    />
                }
                {graphAvailability.incidentTypes &&
                    <SeaPieGraph
                        n={n++}
                        title="Incident/Event Types"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            //setShowViewIncidentTypesReport(true);
                            setModalToShow('incidentTypes');
                        }}
                        data={incidentTypesGraphData}
                        showAllLabels={true}
                    />
                }
                {graphAvailability.incidentCategories &&
                    <SeaPieGraph
                        n={n++}
                        title="Incident/Event Categories"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('incidentCategories');
                        }}
                        data={incidentCategoriesGraphData}
                        hashNamesForColours={true}
                    />
                }
                {/* <SeaHorizontalStackedBarGraph
                    n={n++}
                    title={demoIncidentsByStatus.title}
                    subTitle={demoIncidentsByStatus.subTitle}
                    mode="dashboard"
                    visible={visible}
                    onClick={(e) => {
                        setModalToShow('demoIncidentStatus');
                    }}
                    data={demoIncidentsByStatus.data}
                    units={demoIncidentsByStatus.units}
                    categories={demoIncidentsByStatus.categories}
                    colourPalette={reporting.colours.incidentStatuses}
                />
                <SeaHorizontalBarGraph
                    n={n++}
                    title={demoContributingFactors.title}
                    subTitle={dateRangeSubTitle}
                    mode="dashboard"
                    visible={visible}
                    onClick={(e) => {
                        setModalToShow('demoContributingFactors');
                    }}
                    data={demoContributingFactors.data}
                    sortData={demoContributingFactors.sortData}
                    units={demoContributingFactors.units}
                    colourPalette={[colours.skyBlue]}
                    hashNamesForColours={false}
                />
                <SeaHorizontalBarGraph
                    n={n++}
                    title={demoContributingFactors.title+' Colourful'}
                    subTitle={dateRangeSubTitle}
                    mode="dashboard"
                    visible={visible}
                    onClick={(e) => {
                        setModalToShow('demoContributingFactorsColourful');
                    }}
                    data={demoContributingFactors.data}
                    sortData={demoContributingFactors.sortData}
                    units={demoContributingFactors.units}
                    colourPalette={reporting.colours.default}
                    hashNamesForColours={true}
                /> */}
                {graphAvailability.contributingFactors &&
                    <SeaHorizontalBarGraph
                        n={n++}
                        title="Incident/Event Contributing Factors"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('contributingFactors');
                        }}
                        data={incidentContributingFactorsGraphData}
                        sortData={true}
                        units="Events / Incidents"
                        colourPalette={[colours.skyBlue]}
                        hashNamesForColours={false}
                        yLabelWidth={250}
                    />
                }
                {graphAvailability.injuryTypes &&
                    <SeaHorizontalBarGraph
                        n={n++}
                        title="Injury Types"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('injuryTypes');
                        }}
                        data={incidentInjuryTypesGraphData}
                        sortData={true}
                        units="People Injured"
                        colourPalette={[colours.orangeSoda]}
                        hashNamesForColours={false}
                        yLabelWidth={200}
                    />
                }
                {graphAvailability.injuryLocations &&
                    <SeaHorizontalBarGraph
                        n={n++}
                        title="Injury Locations"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('injuryLocations');
                        }}
                        data={incidentLocationsGraphData}
                        sortData={true}
                        units="People Injured"
                        colourPalette={[colours.lavender]}
                        hashNamesForColours={false}
                        yLabelWidth={200}
                    />
                }
                {graphAvailability.incidentsNotified &&
                    <SeaPieGraph
                        n={n++}
                        title="Incidents/Events Notified to Authorities"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('incidentsNotified');
                        }}
                        data={notifiableIncidentsGraphData}
                        showAllLabels={true}
                    />
                }
                {graphAvailability.incidentPersonnelInvolved &&
                    <SeaPieGraph
                        n={n++}
                        title="Personnel Involved with Incidents/Events"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('incidentPersonnelInvolved');
                        }}
                        data={incidentWhoInvolvedGraphData}
                        showAllLabels={true}
                    />
                }
                {graphAvailability.incidentInjuryOutcomes &&
                    <SeaPieGraph
                        n={n++}
                        title="Injury Outcomes"
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('incidentInjuryOutcomes');
                        }}
                        data={incidentInjuryConclusionsGraphData}
                        showAllLabels={true}
                    />
                }
            </div>
            {visible &&
                <>
                    {graphAvailability.incidentTypes &&
                        <ViewIncidentTypesReport
                            title="Incident/Event Types"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'incidentTypes'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentTypes' : undefined);
                            }}
                            graphData={incidentTypesGraphData}
                            filteredIncidents={filteredIncidents}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.incidentCategories &&
                        <ViewIncidentCategoriesReport
                            title="Incident/Event Categories"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'incidentCategories'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentCategories' : undefined);
                            }}
                            graphData={incidentCategoriesGraphData}
                            filteredIncidents={filteredIncidents}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.incidentsNotified &&
                        <ViewIncidentNotifiedReport
                            title="Incidents/Events Notified to Authorities"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'incidentsNotified'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentsNotified' : undefined);
                            }}
                            graphData={notifiableIncidentsGraphData}
                            filteredIncidents={filteredNotifiableIncidents}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.incidentPersonnelInvolved &&
                        <ViewIncidentPersonnelInvolvedReport
                            title="Personnel Involved with Incidents/Events"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'incidentPersonnelInvolved'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentPersonnelInvolved' : undefined);
                            }}
                            graphData={incidentWhoInvolvedGraphData}
                            filteredIncidents={filteredWhoInvolvedIncidents}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.incidentInjuryOutcomes &&
                        <ViewIncidentInjuryOutcomesReport
                            title="Injury Outcomes"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'incidentInjuryOutcomes'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentInjuryOutcomes' : undefined);
                            }}
                            graphData={incidentInjuryConclusionsGraphData}
                            filteredIncidents={filteredInjuryOutcomes}
                            vesselName={vesselName}
                        />
                    }
                    {/* <ViewIncidentStatusReport
                        title="Demo Incidents/Events by Vessel"
                        showModal={modalToShow === 'demoIncidentStatus'}
                        setShowModal={(showModal) => {
                            setModalToShow(showModal ? 'demoIncidentStatus' : undefined);
                        }}
                        graphData={demoIncidentsByStatus.data}
                        filteredIncidents={filteredCurrentIncidents}
                        categories={selectedIncidentStatusNames}
                        selectedIncidentStatuses={selectedIncidentStatuses}
                        setSelectedIncidentStatuses={setSelectedIncidentStatuses}
                    /> */}
                    {graphAvailability.incidentStatus &&
                        <ViewIncidentStatusReport
                            title="Current Incidents/Events by Vessel"
                            subTitle="(Live Status)"
                            showModal={modalToShow === 'incidentStatus'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'incidentStatus' : undefined);
                            }}
                            graphData={currentIncidentsGraphData}
                            filteredIncidents={filteredCurrentIncidents}
                            categories={selectedIncidentStatusNames}
                            selectedIncidentStatuses={selectedIncidentStatuses}
                            setSelectedIncidentStatuses={setSelectedIncidentStatuses}
                            vesselName={vesselName}
                        />
                    }
                    {/* <ViewContributingFactorsReport
                        title={demoContributingFactors.title}
                        subTitle={dateRangeSubTitle}
                        showModal={modalToShow === 'demoContributingFactors'}
                        setShowModal={(showModal) => {
                            setModalToShow(showModal ? 'demoContributingFactors' : undefined);
                        }}
                        graphData={demoContributingFactors.data}
                        filteredIncidents={filteredContributingFactorIncidents}
                        yLabelWidth={250}
                    /> */}
                    {/* <ViewContributingFactorsReport
                        title={demoContributingFactors.title+' Colourful'}
                        subTitle={dateRangeSubTitle}
                        showModal={modalToShow === 'demoContributingFactorsColourful'}
                        setShowModal={(showModal) => {
                            setModalToShow(showModal ? 'demoContributingFactorsColourful' : undefined);
                        }}
                        graphData={demoContributingFactors.data}
                        filteredIncidents={filteredContributingFactorIncidents}
                    /> */}
                    {graphAvailability.contributingFactors &&
                        <ViewContributingFactorsReport
                            title="Incident/Event Contributing Factors"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'contributingFactors'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'contributingFactors' : undefined);
                            }}
                            graphData={incidentContributingFactorsGraphData}
                            filteredIncidents={filteredContributingFactorIncidents}
                            yLabelWidth={250}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.injuryTypes &&
                        <ViewInjuryTypesReport
                            title="Injury Types"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'injuryTypes'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'injuryTypes' : undefined);
                            }}
                            graphData={incidentInjuryTypesGraphData}
                            filteredIncidents={filteredInjuryTypeIncidents}
                            yLabelWidth={250}
                            vesselName={vesselName}
                        />
                    }
                    {graphAvailability.injuryLocations &&
                        <ViewInjuryLocationsReport
                            title="Injury Locations"
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'injuryLocations'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'injuryLocations' : undefined);
                            }}
                            graphData={incidentLocationsGraphData}
                            filteredIncidents={filteredLocationIncidents}
                            yLabelWidth={200}
                            vesselName={vesselName}
                        />
                    }
                </>
            }
        </>
    );
};

export default HealthSafetyReporting;
