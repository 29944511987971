import React, { useEffect, useState } from 'react';
import { logPageView } from '../../../lib/firebase';
import { useSearchParams } from 'react-router-dom';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';
import style from './AccountActivated.module.css';

const AccountActivated: React.FC = () => {
    const [email, setEmail] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('email')) {
            setEmail(searchParams.get('email') ?? '');
        }
        logPageView('AccountActivated');
    }, [searchParams]);

    return (
        <CenteredBoxPageLayout>
            <div className={style.instructions}>
                <h3>Welcome to Sea Flux!</h3>
                <p>
                    Your account has been successfully activated!
                </p>
                <p>
                    You will now be able to login to the Sea Flux app.
                </p>
                <p>
                    <a
                        href="https://apps.apple.com/us/app/sea-flux/id1560908960"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src="/assets/AppStore.png" alt="Apple App Store" className={style.store}/>
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=nz.co.orchid.seaflux"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src="/assets/GooglePlay.png" alt="Google Play Store" className={style.store}/>
                    </a>
                </p>
                <p>
                    You can also login to the <a href={`/login?email=${encodeURIComponent(email)}`}>Desktop Web App</a>.
                </p>
            </div>
        </CenteredBoxPageLayout>
    );
};

export default AccountActivated;
