import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, addInterval, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import Yup from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditDrillProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: Drill,
    level?: number
}

const EditDrill: React.FC<EditDrillProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
            };
        } else {
            return {
                name: '',
                interval: '',
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            interval: Yup.string().max(4).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('drill')) { return; }
            if (!vesselId) {
                throw new Error("No vesselId");
            }
            // Process form
            const action = traceAction('drills') as Action;
            const batch = splittableBatch(firestore, 20 - 0);
            if (itemToUpdate) {
                action.type = 'update';
                action.docId = itemToUpdate.id;
                batch.set(
                    doc(firestore, 'drills', itemToUpdate.id),
                    {
                        updatedBy: userId,
                        whenUpdated: action.whenAction,
                        name: data.name,
                        interval: data.interval,
                        whenDue: itemToUpdate.whenLastCompleted ? addInterval(itemToUpdate.whenLastCompleted, data.interval) : deleteValue,
                        touched: serverTimestamp()
                    } as Partial<Drill>,
                    { merge: true }
                );
                logAction(
                    batch,
                    'Update',
                    'drills',
                    itemToUpdate.id,
                    data.name,
                    [itemToUpdate.vesselId]
                );

                // If interval changed we'll need to recalculate whenDue for all previously created reports
                if (itemToUpdate.interval !== data.interval) {
                    const reports = drillReports?.byDrillId[itemToUpdate.id]
                    reports?.forEach((report) => {
                        if (report?.drills?.length > 0) {
                            const newDrills = [...report.drills];
                            let needsUpdating = false;
                            for (let i = 0; i < newDrills.length; i++) {
                                if (newDrills[i]?.id === itemToUpdate.id) {
                                    newDrills[i].whenDue = addInterval(report.whenCompleted, data.interval);
                                    needsUpdating = true;
                                }
                            }
                            if (needsUpdating) {
                                batch.set(
                                    doc(firestore, 'drillReports', report.id),
                                    {
                                        drills: [...newDrills],
                                        touched: serverTimestamp()
                                    },
                                    { merge: true }
                                );
                            }
                        }
                    });
                    onCollectionUpdated(batch, 'drillReports');
                };

            } else {
                const newRef = doc(collection(firestore, 'drills'));
                action.type = 'create';
                action.docId = newRef.id;
                batch.set(newRef, {
                    vesselId: vesselId,
                    addedBy: userId,
                    name: data.name,
                    interval: data.interval,
                    whenAdded: action.whenAction,
                    state: 'active',
                    touched: serverTimestamp()
                } as Omit<Drill, 'id'>);
                logAction(
                    batch,
                    'Add',
                    'drills',
                    newRef.id,
                    data.name,
                    [vesselId]
                );
            }

            onCollectionUpdated(batch, 'drills');

            action.data = {
                data,
                itemToUpdate
            };
            action.save(`${itemToUpdate ? 'Update' : 'Add'} drill ${data?.name}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Drill' : 'Add New Drill'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size={'thin'}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="Drill name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectInterval
                                label="Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Drill' : 'Save New Drill'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDrill;
