import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";

//
// Loads userRoles.
// They represent default userPermissions to aid in quickly setting up a new crew member.
//

export type UserRole = {
    id:                                 string,
    addedBy:                            string,
    licenseeId:                         string,
    name:                               string,
    state:                              string,
    updatedBy?:                         string,
    whenAdded:                          number,
    whenUpdated?:                       number,
    vesselSettings:                     number,
    engineHours:                        number,
    logbook:                            number,
    safetyEquipmentChecks:              number,
    safetyEquipmentList:                number,
    hazardRegister:                     number,
    drills:                             number,
    incidentAccidentMedicalRegister:    number,
    maintenanceSchedule:                number,
    maintenanceHistory:                 number,
    survey:                             number,
    jobList:                            number,
    sparePartsList:                     number,
    vesselCertificates:                 number,
    vesselDocuments:                    number,
    standardOperatingProcedures:        number,
    healthSafetyMeetings:               number,
    dangerousGoodsRegister:             number,
    equipmentManualDocuments:           number,
    customForms:                        number,
    crewParticulars:                    number,
    crewCertificates:                   number,
    crewTraining:                       number,
    contacts:                           number,
    companyPlan:                        number,
    companyDocuments:                   number
};

export type UserRolesData = {
    byId: {
        [id: string]: UserRole
    }
};

export const userRolesConfig: SharedStateConfig<UserRolesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => Object.keys(sharedState.userRoles.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onSnapshot(
                query(
                    collection(firestore, 'userRoles'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('state'),
                    orderBy('name')
                ),
                (snap) => {
                    done();
                    const byId = {} as {
                        [id: string]: UserRole
                    };
                    snap.docs.forEach((doc) => {
                        byId[doc.id] = {
                            id: doc.id,
                            ...doc.data()
                        } as UserRole;
                    });
                    set({
                        byId
                    });
                }, (error) => {
                    done();
                    clear();
                    console.log(`Error getting userRoles ${licenseeId}`, error);
                }
            );
        }
    }
};
