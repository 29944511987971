import { IonSpinner } from '@ionic/react';
import React from 'react';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaNoData.css';

// Show this component while data is still loading
// or once loaded, there is no data

interface SeaNoDataProps {
    dataName: string,
    isLoading?: boolean,
    hasNoData?: boolean
    isUsingFilter?: boolean | string,
    isHistory?: boolean
}

const SeaNoData: React.FC<SeaNoDataProps> = ({ dataName, isLoading, hasNoData, isUsingFilter, isHistory }) => {
    if (isLoading) {
        return <IonSpinner name="crescent" className="sea-spinner"/>;
    } else if (hasNoData) {
        return (
            <div className="sea-no-data columns">
                <div><SeaIcon icon="info"/></div>
                {isUsingFilter ? <div>
                    No matching {dataName} were found
                </div> : <div>
                    {isHistory
                        ? `This ${dataName} has no history`
                        : `You currently have no ${dataName}`
                    }
                </div>}
            </div>
        );
    }
    return null;
};

export default SeaNoData;
