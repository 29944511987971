import React, { useState } from 'react';
import { firebaseSettings } from '../../lib/firebase';
import packageJson from '../../../package.json';
import SeaLinkButton from '../SeaLinkButton/SeaLinkButton';
import ContactModal from '../../modals/Footer/ContactModal';
import './SeaFooter.css';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { isPlatform } from '@ionic/react';
// import termsPDF from '../../assets/legalDocuments/NZ_T&Cs_01.02.24.pdf';
// import privacyPDF from'../../assets/legalDocuments/Privacy_policy_New_Zealand_07.02.24.pdf';
// import { FileOpener } from '@ionic-native/file-opener';

const SeaFooter: React.FC = () => {
    const [showContactModal, setShowContactModal] = useState(false);
    // const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    // const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);

    const onOpenContactModal = () => {
        setShowContactModal(true);
    };

    // zombie code, example of how to download a file
    // const downloadPDF = (type: 'privacy' | 'terms') => {
    //     const fileUrl = type === 'privacy' ? privacyPDF : termsPDF;
    //     const name = type === 'privacy' ? 'Privacy policy New Zealand 07.02.24' : "NZ T&C's 01.02.24";
    //     if (!isPlatform('hybrid')) {
    //         const a = document.createElement('a');
    //         a.href = fileUrl;
    //         a.download = `${name}.pdf`;
    //         document.body.appendChild(a);
    //         a.click();
    //         console.log('File downloaded: ' + a.download);
    //         document.body.removeChild(a);
    //     } else {
    //         fetch(fileUrl)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             // Convert the Blob to base64
    //             const reader = new FileReader();
    //             reader.readAsDataURL(blob);
    //             reader.onloadend = async () => {
    //                 const base64data = reader.result;
    //                 if (!base64data) {
    //                     console.error('No base64data');
    //                     return;
    //                 }
    //                 // Use the Filesystem API to save it to the device
    //                 try {
    //                     const fileName = `${name}.pdf`;
    //                     const document = await Filesystem.writeFile({
    //                         path: fileName,
    //                         data: base64data.toString(),
    //                         directory: Directory.Documents,
    //                     });
    //                     await FileOpener.open(
    //                         document.uri, // The URI of the saved file
    //                         'application/pdf' // The MIME type of the file
    //                     );
    //                     console.log('File written to device: ' + document.uri);
    //                 } catch (error) {
    //                     console.error('Error writing file:', error);
    //                 }
    //             };
    //         })
    //         .catch(error => console.error('Error fetching file:', error));
    //     }
    // };


    // const onOpenPrivacyModal = () => {
    //     setShowPrivacyModal(true);
    // }

    // const onOpenTermsAndConditionsModal = () => {
    //     setShowTermsAndConditionsModal(true);
    // }

    return (
        <div>
            <div className="sea-footer">
                <div>
                    <div>&copy; {(new Date()).getFullYear()} SEA FLUX.</div>
                </div>
                <div>
                    <div>
                        Version {packageJson.version},
                        Build {packageJson.build}
                        {firebaseSettings.projectId === 'sea-flux-3c853' ? '' : ` (${firebaseSettings.projectId?.substring(firebaseSettings.projectId.lastIndexOf('-') + 1)})`}
                    </div>
                </div>
                <div>
                    <SeaLinkButton mode="small-link" onClick={(e) => onOpenContactModal()}>Contact</SeaLinkButton>
                </div>
                <div>
                    <SeaLinkButton mode="small-link" onClick={(e) => window.open('https://sea-flux.com/privacy-policy/')}>Privacy</SeaLinkButton>
                </div>
                <div>
                    <SeaLinkButton mode="small-link" onClick={(e) => window.open('https://sea-flux.com/terms-and-conditions/')}>Terms of Use</SeaLinkButton>
                </div>
            </div>
            <ContactModal showModal={showContactModal} setShowModal={setShowContactModal} />
            {/* <PrivacyModal showModal={showPrivacyModal} setShowModal={setShowPrivacyModal} /> */}
            {/* <TermsAndConditionsModal showModal={showTermsAndConditionsModal} setShowModal={setShowTermsAndConditionsModal} /> */}
        </div>
    );
};

export default SeaFooter;
