import { Rect, Text } from '@react-pdf/renderer';
import React, { useCallback, useEffect, useState } from 'react';
import { HoverInfo } from '../SvgHoverInfo/SvgHoverInfo';
import { isTouchDevice } from '../../../lib/util';

interface SvgTextProps {
    text: string,
    x?: number,
    y?: number,
    maxWidth?: number,
    fill?: string,
    fillOpacity?: number,
    className?: string,
    style?: any,
    forPdf?: boolean,
    textFadeGradient?: string,
    setHoverInfo?: (hoverInfo: HoverInfo | undefined) => void,
    triggerHoverInfo?: boolean,
    hoverText?: string
}

const SvgText: React.FC<SvgTextProps> = ({
    text,
    x = 0,
    y = 0,
    maxWidth, // need to specify style.fontSize to work with PDFs
    fill = 'currentcolor',
    fillOpacity,
    className,
    style,
    forPdf,
    textFadeGradient = 'textFade',
    setHoverInfo,
    triggerHoverInfo,
    hoverText
}) => {
    const [truncated, setTruncated] = useState(false);

    const onHover = useCallback((activate: boolean) => {
        if (setHoverInfo && maxWidth && (truncated || hoverText)) {
            if (activate) {
                setHoverInfo({
                    text: hoverText ? hoverText : text,
                    x: x + (maxWidth / 2),
                    y: y - 12
                });
            } else {
                setHoverInfo(undefined);
            }
        }
    }, [setHoverInfo, truncated, text, x, y, maxWidth, hoverText]);

    useEffect(() => {
        if (triggerHoverInfo) {
            onHover(true);
        }
    }, [triggerHoverInfo, onHover]);

    const onTextRef = useCallback((element: SVGTextElement | null) => {
        let isActive = true;
        let delay = 0;
        const adjust = () => {
            if (element && maxWidth) {
                let s = text;
                if (element.getComputedTextLength !== undefined) {
                    if (element.getComputedTextLength() === 0) {
                        return tryAgain(); // element not ready yet
                    }
                    while (element.getComputedTextLength() > maxWidth) {
                        s = s.slice(0,-1);
                        element.textContent = s + '...';
                        setTruncated(true);
                    }
                    return;
                }
                if (element.getBBox !== undefined) {
                    while (element.getBBox().width > maxWidth) {
                        s = s.slice(0,-1);
                        element.textContent = s + "...";
                        setTruncated(true);
                    }
                    return;
                }
            }
        };
        const tryAgain = () => {
            if (!isActive) return;
            delay += 100;
            setTimeout(() => {
                if (!isActive) return;
                adjust();
            }, delay);
        };
        adjust();
        return () => { isActive = false; };
    }, [maxWidth, text]);

    if (forPdf) {
        return (
            <>
                <Text
                    style={style}
                    x={x}
                    y={y}
                    fill={fill}
                    fillOpacity={fillOpacity}
                >{text}</Text>
                {maxWidth && style?.fontSize &&
                    <Rect
                        x={x + maxWidth - 20}
                        y={y - parseFloat(style.fontSize)}
                        width={1000}
                        height={parseFloat(style.fontSize) * 1.25}
                        fill={`url('#${textFadeGradient}')`}
                    />
                }
            </>
        );
    }

    return (
        <React.Fragment>
            <text
                x={x}
                y={y}
                fill={fill}
                fillOpacity={fillOpacity}
                className={`${className ? className : ''}`}
                style={style}
                ref={onTextRef}
                onMouseOver={(e) => onHover(true)}
                onClick={(e) => {
                    if (isTouchDevice && maxWidth && (truncated || hoverText)) {
                        e.preventDefault();
                        e.stopPropagation();
                        onHover(true);
                    }
                }}
                onMouseOut={(e) => onHover(false)}
            >{text}</text>
        </React.Fragment>
    );
};

export default SvgText;
