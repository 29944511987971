import { SplittableBatch, firestore, storage } from './firebase';
import { doc, WriteBatch, arrayUnion, serverTimestamp, FieldValue } from "firebase/firestore";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { isImage, haveValuesChanged, toInt } from './util';
import { sharedState } from '../shared-state/shared-state';
//base64js = require('base64-js')

// when stored in firestore within an images or documents field
// the value parsed as follows:
// <state><id>.<extension>
export interface SeaFile {
    // values that can de derived from firestore documents
    id?: string, // firestore id
    state?: number, // 0=pending upload, 1=uploaded, 2=uploaded and processed (has thumb version)
    ext?: string, // extension like jpeg, png, pdf etc.
    // values also stored in firestore files collection
    collection?: string, // firestore collection this is/will be attached to
    field?: string,      // firestore collection.field this is/will be attached to
    docId?: string,      // firestore doc id for which this is attached to. if undefined, it means is not attached
    whenAdded?: Number,  // when file is added (before it is uploaded)
    whenUploaded?: Number, // when file was succesfully uploaded to Storage
    whenProcessed?: Number, // when file was processed into thumbnail version
    // values created by SeaFileUpload component
    src?: string, // what can be passed to an img tag
    base64?: string, // image data that can be used to construct an img.src (see getFileImageSrc). Can also be uploaded as data
    name?: string, // original file name (not present when using getPhoto)
    contentType?: string, // mime type that was in original file (not present when using getPhoto)
    lastModified?: number, // last modified for the original file (not present when using getPhoto)
    authorId?: string, // userId for the author who created or edited the document (only used for *.sfdocs)
    unique?: string, // temporary unique identifier for use by key
    isSignature?: boolean, // set to true if it's a signature
    emailToken?: string // email passcode to allow emails to access file later on (uuid v4)
}

export const hasSignatureChanged = (file: SeaFile | undefined, originalValue: string | undefined): boolean => {
    if (file && originalValue) {
        if (file.id) {
            return (file.id !== originalValue.substring(1, 21));
        }
        return true; // Must be a freshly drawn signature
    } else if (file || originalValue) {
        // One exists and one doesn't
        return true;
    }
    return false;
}

export const getFileNameFromString = (file: string) => {
    if (file.indexOf('_') !== -1) {
        return file.substring(file.indexOf('_') + 1);
    }
    return file;
};

export const getExtFromString = (file: string | undefined) => {
    if (file && file.indexOf('.') !== -1) {
        return file.substring(file.lastIndexOf('.') + 1);
    }
    return '';
};

export const getImgSrcFromExt = (ext: string | undefined, imageSize: string): string => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdf':
                return `/assets/file-pdf${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
            case 'sfdoc':
                return `/assets/file-sfdoc${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
            case 'doc':
            case 'docx':
            case 'odt':
            case 'txt':
            case 'xls':
            case 'xlsx':
                return `/assets/file-doc${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
        }
    }
    return `/assets/file-attached${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getImgSrcPlaceholder = (imageSize: string) => {
    return `/assets/file-null${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileMissingSrc = (imageSize: string) => {
    return `/assets/missing${imageSize === 'tiny' ? '_tiny' : ''}@2x.png`;
};

export const getFileSrc = (id: string, ext: string): Promise<string> => {
    return getDownloadURL(
        storageRef(storage, `files/${id}.${ext}`)
    );
}

export const getFileSrcFromString = (file: string, imageSize = 'full'): Promise<string> => {
    const ext = file.substring(file.lastIndexOf('.') + 1);
    const state = toInt(file.substring(0,1), 0);
    const id = file.substring(1, 21);
    if (isImage(ext)) {
        return getDownloadURL(
            storageRef(storage, `files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`)
        );
        //return storage.ref().child(`files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`).getDownloadURL();
    } else {
        return getFileSrc(id, ext);
    }
};

// make files from firestore values
export const makeSeaFiles = (values: string[] | undefined): SeaFile[] => {
    if (values) {
        const files = [] as SeaFile[];
        values.forEach((value: string) => {
            const file = {
                state: toInt(value[0], 0),
                ext: value.substring(value.lastIndexOf('.') + 1)
            } as SeaFile;
            if (value.indexOf('_') !== -1) {
                file.id = value.substring(1, value.indexOf('_'));
                file.name = value.substring(value.indexOf('_') + 1);
            } else {
                file.id = value.substring(1, value.indexOf('.'));
            }
            files.push(file);
        });
        return files;
    }
    return [];
};

export const makeSignature = (value: string | undefined): SeaFile | undefined => {
    if (value && value.length > 0) {
        return {
            state: toInt(value[0], 0),
            ext: value.substring(value.lastIndexOf('.') + 1),
            id: value.substring(1, value.indexOf('.')),
            isSignature: true
        };
    }
    return undefined;
};

// Check whether files has changed from original firestore values
export const haveFilesChanged = (files: SeaFile[] | undefined, originalValues: string[] | undefined): boolean => {
    if (originalValues === undefined || originalValues.length === 0) {
        return (files && files.length > 0) ? true : false;
    }
    if (files === undefined || files.length === 0) {
        return true;
    }
    const originalFiles = makeSeaFiles(originalValues);
    if (originalFiles.length !== files.length) {
        return true;
    }
    for (let i = 0; i < files.length; i++) {
        if (haveValuesChanged(files[i], originalFiles[i])) {
            return true;
        }
    }
    return false;
}

// Indicate files are being referred to by a firestore document with a particular docId
export const saveFileRefs = (batch: WriteBatch | SplittableBatch, files: SeaFile[], collection: string, docId: string) => {
    if (files?.length > 0) {
        files.forEach((file: SeaFile) => {
            // To make sure the onFileUpdate function is triggered, we must have a value change...
            // Therefore, let's through in a time value
            let triggerCheck: FieldValue | undefined = undefined;
            if (file.state !== undefined && (
                file.state < 1 ||
                (file.ext && isImage(file.ext) && file.state < 2)
            )) {
                // This file might not be in its final form
                // Therefore let's check that the document update that would be going along side
                // this function call is not overwriting a newer state saved by the server
                triggerCheck = serverTimestamp(); // Server timestamp is used so this will be a unique value that will trigger functions/onFileUpdate
            }
            batch.set(
                doc(firestore, 'files', ''+file.id),
                {
                    //docId: docId,
                    refs: arrayUnion({
                        collection: collection,
                        docId: docId
                    }),
                    triggerCheck
                },
                { merge: true }
            );
        });
    }
};

// Convert an array of SeaFile to an array of "<state><id>.<ext>" values
export const seaFilesToValue = (files: SeaFile[]): string[] => {
    let array = [] as string[];
    files.forEach((file: SeaFile) => {
        array.push(`${file.state}${file.id}${file.name ? ('_' + file.name.substring(0, file.name.lastIndexOf('.'))) : ''}.${file.ext}`);
    });
    return array;
}

export const signatureToValue = (file: SeaFile | undefined): string | undefined => {
    if (file) {
        return `${file.state}${file.id}.${file.ext}`;
    }
    return undefined;
}

export const sfdocToValue = (file: SeaFile): string | undefined => {
    if (file) {
        // return `${file.state}${file.id}_${file.name}.sfdoc`; // For now, we don't have a good reason to embed the title... its repeated redundantly and expected to be already known. Will not be visible with the fullscreen viewer anyway for the foreseeable future
        return `${file.state}${file.id}^${file.authorId}.sfdoc`; // authorId is embedded because sfdocs are uniquely created/edited by users directly
    }
    return undefined;
};

export const getImgSrc = (state: number, id: string, ext: string, imageSize: string): Promise<string> => {
    // if (state === 0) {
    //     return getFileMissingSrc(imageSize);
    // }
    if (isImage(ext)) {
        //return getStorageUrl(licenseeId, id, ext, (state === 2) ? ('_'+imageSize) : '');
        return getDownloadURL(
            storageRef(storage, `files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`)
        ).catch((e) => {
            console.log(`Failed to getDownloadURL error`, e);
            return Promise.resolve(getImgSrcFromExt(ext, imageSize));
        });
        //return storage.ref().child(`files/${id}${(state === 2) ? ('_'+imageSize) : ''}.${ext}`).getDownloadURL();
    }
    return Promise.resolve(getImgSrcFromExt(ext, imageSize));
};

export const getImgSrcFromSeaFile = (file: SeaFile, imageSize: string): Promise<string> => {
    if (file.state === 0) {
        return Promise.resolve(getFileMissingSrc('full'));
        //return '/assets/placeholder.png'; // image not yet uploaded on another's device
    } else if (file.base64) {
        if (file.ext && !isImage(file.ext)) {
            return Promise.resolve(getImgSrcFromExt(file.ext, imageSize));
        }
        return Promise.resolve(`data:image/jpeg;base64, ${file.base64}`);
    } else if (file.src) {
        return Promise.resolve(file.src);
    } else if (file.id && sharedState.licenseeId.current) {
        return getImgSrc(file.state as number, file.id, file.ext as string, imageSize);
    }
    return Promise.resolve('/assets/placeholder.png');
};

export const getImgSrcFromString = (file: string, imageSize: string): Promise<string> => {
    return getImgSrc(
        toInt(file.substring(0,1), 0),
        file.substring(1, 21),
        file.substring(file.lastIndexOf('.') + 1),
        imageSize
    );
};

export const renderSrc = (file: string, src: string, size: string): Promise<string> => {
    if (src === 'nonImage') {
        return Promise.resolve(
            getImgSrcFromExt(getExtFromString(file), size)
        );
    } else if (src === 'noCache') {
        if (file[0] === '0') { // Not yet uploaded by another device
            return Promise.resolve(
                getFileMissingSrc(size)
            );
        }
        return getImgSrcFromString(file, size);
    }
    return Promise.resolve(src);
};
