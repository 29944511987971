import React from 'react';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import SeaToast from '../../components/SeaToast/SeaToast';
import './ToastManager.css';

//
// Handles showing the user a Toast message
//

export const toastMessageConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    default: '',
    notes: 'Allows components to show a toast message.'
};

export const showToast = (message: string) => {
    sharedState.toastMessage.set(message);
};

const ToastManager: React.FC = () => {
    const toastMessage = sharedState.toastMessage.use();
    return <SeaToast message={toastMessage ?? ''} setMessage={sharedState.toastMessage.set}/>
};

export default ToastManager;
