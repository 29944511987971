import React, { useEffect, useState } from 'react';
import { firebaseSettings, firestore, functions, logPageView, functionsRegion } from '../../../lib/firebase';
import { collection, query, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, where, QueryConstraint, QueryDocumentSnapshot, DocumentData } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { sharedState } from '../../../shared-state/shared-state';
import { defaultCrewPermissions, defaultLicenseePermissions } from '../../../lib/permissions';
import { IonSelectOption } from '@ionic/react';
import { renderFullName } from '../../../shared-state/Core/users';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import packageJson from '../../../../package.json';
import SeaScrollableArea from '../../../components/SeaScrollableArea/SeaScrollableArea';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';
import { UserPermissions } from '../../../shared-state/Core/userPermissions';

const OrchidAdmin: React.FC = () => {
    const serverInfo = sharedState.serverInfo.use();
    const [userId, setUserId] = useState<string>('');
    const [firebaseFunction, setFirebaseFunction] = useState<string>("");
    const [customFirebaseFunction, setCustomFirebaseFunction] = useState<string>("");
    const [exportData, setExportData] = useState<any[]>([]);
    const [importJson, setImportJson] = useState<string>();

    useEffect(() => {
        logPageView('Orchid');
    }, []);

    const actions = [] as any[];
    const doActions = (startTime = 0): any => {
        if (startTime === 0) {
            return doActions(Date.now());
        }
        if (actions.length === 0) {
            console.log(`Completed all actions in ${Math.round((Date.now() - startTime) / 1000)}s`);
            return;
        }
        const action = actions.shift();
        return action().then(() => {
            return doActions(startTime);
        }).catch((error: any) => {
            console.log('ERROR!', error);
        });
    }

    const fixUserPermissions = () => {
        const promises = [] as any[];
        console.log('Making sure all users have userPermissions...');
        promises.push(
            getDocs(
                query(
                    collection(firestore, 'userPermissions')
                )
            ).then((snap) => {
                snap.docs.forEach((_doc) => {
                    const userPermissions = {
                        id: _doc.id,
                        ..._doc.data()
                    } as UserPermissions;
                    if (userPermissions.customForms === undefined) {
                        console.log('userPermissions: adding customForms permission for userId', userPermissions.id);
                        actions.push(() => {
                            return updateDoc(
                                doc(firestore, 'userPermissions', userPermissions.id),
                                {
                                    customForms: userPermissions.vesselDocuments
                                }
                            );
                        });
                    }
                });
            })
        );
        promises.push(
            getDocs(
                query(
                    collection(firestore, 'users')
                )
            ).then((snap) => {
                snap.docs.forEach((_doc) => {
                    const user = _doc.data();
                    actions.push(() => {
                        //console.log('looking for userPermissions for id', doc.id);

                        return getDoc(
                            doc(firestore, 'userPermissions', _doc.id)
                        ).then((userPermissions) => {
                            if (user.isSuperAdmin) {
                                console.log(`userPermissions: deleting for superAdmin ${renderFullName(user)}...`);
                                return deleteDoc(
                                    doc(firestore, 'userPermissions', _doc.id)
                                );
                            } else if (user.isLicensee) {
                                console.log(`userPermissions: setting defaultLicenseePermissions for ${renderFullName(user)}...`);
                                return setDoc(
                                    doc(firestore, 'userPermissions', _doc.id),
                                    defaultLicenseePermissions,
                                    { merge: true }
                                ).then(() => {
                                    console.log('done');
                                    return Promise.resolve();
                                });
                            } else if (!userPermissions.exists()) {
                                console.log(`userPermissions: adding defaultCrewPermissions for ${renderFullName(user)}...`);
                                return setDoc(
                                    doc(firestore, 'userPermissions', _doc.id),
                                    defaultCrewPermissions
                                ).then(() => {
                                    console.log('done');
                                    return Promise.resolve();
                                });
                            } else if (userPermissions.data().whenRoleChanged) {
                                console.log(`userPermissions: deleting field whenRoleChanged for ${_doc.id}`);
                                return setDoc(
                                    doc(firestore, 'userPermissions', _doc.id),
                                    {},
                                    { merge: true }
                                );
                            }
                            return Promise.resolve();
                        });
                    });
                });
            })
        );
        Promise.all(promises).then(() => {
            doActions();
        });
    };

    const refreshCustomClaims = () => {
        const promises = [] as any[];
        console.log('Refreshing all custom claims...');
        promises.push(
            getDocs(
                query(
                    collection(firestore, 'users')
                )
            ).then((snap) => {
                snap.docs.forEach((_doc) => {
                    const user = _doc.data();
                    actions.push(() => {
                        console.log('refreshing custom claims for userId', _doc.id);
                        return setDoc(
                            doc(firestore, 'users', _doc.id),
                            { whenRoleChanged: Date.now() },
                            { merge: true }
                        ).then(() => {
                            console.log('done');
                        });
                    });
                });
            })
        );
        Promise.all(promises).then(() => {
            doActions();
        });
    };

    const trimOptions = () => {
        console.log('trimOptions...');
        const promises = [] as any[];
        const settingsArray = [
            {
                collection: 'scheduledMaintenanceTasks',
                fields: ['system', 'equipment', 'location']
            }, {
                collection: 'equipmentManualDocuments',
                fields: ['system', 'equipment']
            }, {
                collection: 'voyages',
                fields: ['departureFrom', 'destinationTo']
            }, {
                collection: 'jobs',
                fields: ['system', 'equipment', 'location']
            }, {
                collection: 'maintenanceTasksCompleted',
                fields: ['system', 'equipment', 'location']
            }, {
                collection: 'spareParts',
                fields: ['system', 'equipment', 'location']
            }, {
                collection: 'safetyCheckItems',
                fields: ['item', 'location']
            }, {
                collection: 'safetyEquipmentItems',
                fields: ['item', 'location']
            }
        ];
        let count = 0;
        settingsArray.forEach((settings) => {
            promises.push(
                getDocs(
                    query(
                        collection(firestore, settings.collection)
                    )
                ).then((docs) => {
                    docs.forEach((_doc) => {
                        const data = _doc.data();
                        settings.fields.forEach((field) => {
                            if (data[field] !== undefined && data[field].trim() !== data[field]) {
                                count++;
                                actions.push(() => {
                                    console.log(`Trimming ${settings.collection} ${field}`, data[field]);
                                    const o: {
                                        [key: string]: any
                                    } = {} ;
                                    o[field] = data[field].trim();
                                    return setDoc(
                                        doc(firestore, settings.collection, _doc.id),
                                        o,
                                        { merge: true }
                                    );
                                });
                            }
                        });
                    });
                })
            );
        });
        Promise.all(promises).then(() => {
            return doActions();
        }).then(() => {
            console.log('Found '+count+' fields to trim');
        }).catch((error) => {
            console.log('error!', error);
        });
    };

    const runFirebaseFunction = () => {
        if (firebaseFunction === 'adminAnalyseFiles') {
            return callFirebaseFunction({}, firebaseFunction);
        } else {
            callFirebaseFunction({}, firebaseFunction);
        }
    };

    const runCustomFirebaseFunction = () => {
        callFirebaseFunction({}, customFirebaseFunction);
    };

    const callFirebaseFunction = (data: any, functionName: string) => {
        console.log(`callFirebaseFunction ${functionName}...`, data);
        const startTime = Date.now();
        return httpsCallable(
            functions,
            functionName,
            { timeout: 10 * 60 * 1000 }
        )(data).then((result: any) => {
            console.log('runFirebaseFunction result', result);
            return Promise.resolve(result);
        }).catch((error) => {
            console.error('runFirebaseFunction error', error);
        }).finally(() => {
            console.log(`Took ${Math.round((Date.now() - startTime) / 1000)}s`);
        });
    };

    const gatherExportData = (makePretty: boolean) => {
        console.log(`gatherExportData exportData`, exportData);
        const queries = [...exportData];
        const results = [] as any[];
        let count = 0;
        const processQueries = (): Promise<any> => {
            if (queries.length === 0) {
                return Promise.resolve();
            }
            const _query = queries.shift();
            console.log('>>> processing _query', _query);
            const constraints = [] as QueryConstraint[];
            return Promise.resolve().then(() => {
                if (_query.where[0].field === 'doc.id') {
                    return getDoc(
                        doc(firestore, _query.collection, _query.where[0].value)
                    ).then((doc) => {
                        if (doc.exists()) {
                            return [doc];
                        }
                        return [];
                    });
                }

                _query.where.forEach((_where: any) => {
                    let value = _where.value;
                    if (_where.type === 'number') {
                        value = parseFloat(value);
                    } else if (_where.type === 'boolean') {
                        value = (value.toLowerCase().trim() === 'true') ? true : false
                    }
                    constraints.push(
                        where(_where.field, _where.constraint, value)
                    );
                });

                console.log('>>> constraints', constraints);

                return getDocs(
                    query(
                        collection(firestore, _query.collection),
                        ...constraints
                    )
                ).then((snap) => {
                    return snap.docs;
                });

            }).then((docs: QueryDocumentSnapshot<DocumentData>[]) => {
                const queryResults = {
                    collection: _query.collection,
                    cmd: _query.cmd,
                    documents: [] as any[]
                };
                docs.forEach((doc) => {
                    queryResults.documents.push({
                        id: doc.id,
                        data: _query.cmd === 'delete' ? undefined : doc.data()
                    });
                });
                count += docs.length;
                console.log(`>>> queryResults (${docs.length})`, queryResults);
                results.push(queryResults);
                return processQueries();
            }).catch((error) => {
                console.log('error processsing query', error);
            });
        };
        return processQueries().then(() => {
            console.log(`>>> results (${count} docs total)`, results);
            if (makePretty) {
                setImportJson(
                    JSON.stringify(results, undefined, 2)
                );
            } else {
                setImportJson(
                    JSON.stringify(results)
                );
            }
        });
    };

    const importData = () => {
        const operations = JSON.parse(importJson as string) as any[];
        console.log(`>>> importData operations`, operations);
        const count = {
            delete: 0,
            set: 0,
            merge: 0
        };

        const processOperations = (): Promise<any> => {
            if (operations.length === 0) {
                return Promise.resolve();
            }
            const operation = operations.shift();
            console.log('>>> operation', operation);

            const processDocuments = (): Promise<any> => {
                if (operation.documents.length === 0) {
                    return Promise.resolve();
                }
                const document = operation.documents.shift();
                console.log('>>> document', document);
                if (operation.cmd === 'delete') {
                    return deleteDoc(
                        doc(firestore, operation.collection, document.id)
                    ).then(() => {
                        console.log(`Deleted ${operation.collection} ${document.id}`);
                        count.delete++;
                        return processDocuments();
                    });
                }
                return setDoc(
                    doc(firestore, operation.collection, document.id),
                    document.data,
                    { merge: (operation.cmd === 'merge') ? true : false }
                ).then(() => {
                    if (operation.cmd === 'merge') {
                        count.merge++;
                    } else {
                        count.set++;
                    }
                    console.log(`${operation.cmd === 'merge' ? 'Merged' : 'Set'} ${operation.collection} ${document.id}`, document.data);
                    return processDocuments();
                });
            };

            return processDocuments().then(() => {
                return processOperations();
            }).catch((error) => {
                console.log('>>> ERROR processing Json', error);
            });
        };

        return processOperations().then(() => {
            console.log(`Completed import.`, count);
        });
    };

    return (
        <div>
        <SeaScrollableArea>
        <div style={{ padding: '0px 20px 32px 20px' }}>
            <h1>Orchid Admin Console</h1>
            <div style={{ height: '16px' }}></div>
            <div>
                App: v{packageJson.version}, build {packageJson.build}. {serverInfo &&
                    <>
                        AVAILABLE: web=<b>{serverInfo.webBuildAvailable}</b>, ios=<b>{serverInfo.iosBuildAvailable}</b>, android=<b>{serverInfo.androidBuildAvailable}</b>, REQUIRED: web=<b>{serverInfo.webBuildRequired}</b>, ios=<b>{serverInfo.iosBuildRequired}</b>, android=<b>{serverInfo.androidBuildRequired}</b>
                    </>
                }
            </div>
            <br/>
            <SeaButton disabled onClick={(e) => trimOptions()}>Fix untrimmed options</SeaButton>
            <br/>
            <SeaButton onClick={(e) => refreshCustomClaims()}>Refresh all auth custom claims</SeaButton>
            <br/>
            <div className="columns" style={{ padding: '20px 0px' }}>
                <div>
                    <SeaInput
                        label="User Id"
                        value={userId}
                        onchange={(e) => {
                            setUserId(e.detail.value);
                            sharedState.userId.set(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ alignSelf: 'flex-end', paddingLeft: '10px' }}>
                    <SeaButton
                        onClick={(e) => {
                            confirmAction(`
                                Are you sure you want to make '${userId}' the licensee?
                                Remember to backup data first!
                                Probably should do at 1am...
                            `, 'Yes, do it!').then(() => {
                                showToast('Please wait. This could take minutes...');
                                return httpsCallable(
                                    functions,
                                    'adminChangeLicensee',
                                    { timeout: 10 * 60 * 1000 }
                                )({
                                    userId: userId
                                }).then((result: any) => {
                                    console.log('httpsCallable result', result);
                                    if (result?.data?.error) {
                                        alertMessage('ERROR: '+result.data.error);
                                    } else {
                                        alertMessage(`${result.data.message} ${result.data.docsUpdated} docs updated. ${result.data.filesUpdated} files updated. Took ${result.data.timeTaken}ms`);
                                    }
                                }).catch((error) => {
                                    console.log('error', error);
                                });
                            }).catch(() => {});
                        }}
                    >
                        Make User Become Licensee
                    </SeaButton>
                </div>
            </div>
            <div className="columns" style={{ padding: '20px 0px' }}>
                <div>
                    <SeaSelect
                        label="Firebase Functions"
                        value={firebaseFunction}
                        onchange={(e) => setFirebaseFunction(e.detail.value)}
                        width="250px"
                    >
                        <IonSelectOption value="">Select function...</IonSelectOption>
                        <IonSelectOption value="adminDoFirestoreBackup">adminDoFirestoreBackup</IonSelectOption>
                        <IonSelectOption value="adminDoWeeklyReports">adminDoWeeklyReports</IonSelectOption>
                        <IonSelectOption value="adminDoDailyNotifications">adminDoDailyNotifications</IonSelectOption>
                        {/* <IonSelectOption value="adminFixFileStatePropagation">adminFixFileStatePropagation</IonSelectOption> */}
                        {/* <IonSelectOption value="adminFixOrphanMistakes">adminFixOrphanMistakes</IonSelectOption> */}
                        <IonSelectOption value="adminProcessTraceReports">adminProcessTraceReports</IonSelectOption>
                        <IonSelectOption value="adminProcessErrorReports">adminProcessErrorReports</IonSelectOption>
                        <IonSelectOption value="adminFixCustomForms">adminFixCustomForms</IonSelectOption>
                        <IonSelectOption value="adminAnalyseFiles">adminAnalyseFiles</IonSelectOption>
                        <IonSelectOption value="adminFixBackupIssues">adminFixBackupIssues</IonSelectOption>
                        <IonSelectOption value="adminGatherData">adminGatherData</IonSelectOption>
                        <IonSelectOption value="adminPrepareForMigration">adminPrepareForMigration</IonSelectOption>
                        <IonSelectOption value="adminDoMigration">adminDoMigration</IonSelectOption>
                        <IonSelectOption value="adminDoPostMigration">adminDoPostMigration</IonSelectOption>
                        <IonSelectOption value="adminDoChecks">adminDoChecks</IonSelectOption>
                        <IonSelectOption value="adminDoMaintenance">adminDoMaintenance</IonSelectOption>
                        <IonSelectOption value="adminFixChangeLicenseeProblems">adminFixChangeLicenseeProblems</IonSelectOption>
                        <IonSelectOption value="adminCalculateMetrics">adminCalculateMetrics</IonSelectOption>
                    </SeaSelect>

                </div>
                <div style={{ alignSelf: 'flex-end', paddingLeft: '10px' }}>
                    <SeaButton
                        onClick={(e) => runFirebaseFunction()}
                    >
                        Run Function
                    </SeaButton>
                </div>
                {firebaseFunction &&
                    <div style={{ alignSelf: 'flex-end', padding: '0px 0px 18px 20px' }}>
                        <a href={`https://console.firebase.google.com/u/1/project/${firebaseSettings.projectId}/functions/logs?functionFilter=${firebaseFunction}(${functionsRegion})&search=&severity=DEBUG`} target="_blank">View Firebase Logs...</a>
                    </div>
                }
            </div>
            <div className="columns" style={{ padding: '20px 0px' }}>
                <div>
                    <SeaInput
                        //label="Custom function"
                        value={customFirebaseFunction}
                        onchange={(e) => setCustomFirebaseFunction(e.detail.value)}
                    />
                </div>
                <div style={{ alignSelf: 'flex-end', paddingLeft: '10px' }}>
                    <SeaButton
                        onClick={(e) => runCustomFirebaseFunction()}
                    >
                        Run Custom
                    </SeaButton>
                </div>
                {customFirebaseFunction &&
                    <div style={{ alignSelf: 'flex-end', padding: '0px 0px 18px 20px' }}>
                        <a href={`https://console.firebase.google.com/u/1/project/${firebaseSettings.projectId}/functions/logs?functionFilter=${customFirebaseFunction}(${functionsRegion})&search=&severity=DEBUG`} target="_blank">View Firebase Logs...</a>
                    </div>
                }
            </div>
            <div>
                <h2>Export</h2>
                {exportData?.map((query, queryIndex) => {
                    return (
                        <React.Fragment key={queryIndex}>
                            <div className="columns" style={{ padding: '8px 8px' }}>
                                <div>
                                    <SeaInput
                                        label="Collection"
                                        value={query.collection}
                                        onchange={(e) => {
                                            setExportData((current) => {
                                                const queries = [...current];
                                                queries[queryIndex].collection = e.detail.value;
                                                return queries;
                                            });
                                        }}
                                    />
                                </div>
                                <div style={{ width: '100px' }}>
                                    <SeaSelect
                                        label="Operation"
                                        value={query.cmd}
                                        onchange={(e) => {
                                            setExportData((current) => {
                                                const queries = [...current];
                                                queries[queryIndex].cmd = e.detail.value;
                                                return queries;
                                            });
                                        }}
                                    >
                                        <IonSelectOption value="set">set</IonSelectOption>
                                        <IonSelectOption value="merge">merge</IonSelectOption>
                                        <IonSelectOption value="delete">delete</IonSelectOption>
                                    </SeaSelect>
                                </div>
                                <div style={{ padding: '20px 0px 0px 8px' }}>
                                    <SeaButton onClick={(e) => {
                                        setExportData((current) => {
                                            const queries = [...current];
                                            queries.splice(queryIndex, 1);
                                            return queries;
                                        });
                                    }}>-</SeaButton>
                                </div>
                            </div>
                            {query.where.map((where: any, whereIndex: number) => {
                                return (
                                    <div key={whereIndex} className="columns" style={{ padding: '0px 8px 8px 24px' }}>
                                        <div>
                                            <SeaInput
                                                label={whereIndex > 0 ? 'And' : 'Where'}
                                                help={whereIndex === 0 ? {
                                                    text: 'Use "doc.id" to match an exact document by it\'s id'
                                                } : undefined}
                                                value={where.field}
                                                onchange={(e) => {
                                                    setExportData((current) => {
                                                        const queries = [...current];
                                                        queries[queryIndex].where[whereIndex].field = e.detail.value;
                                                        return queries;
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '100px' }}>
                                            <SeaSelect
                                                label=" "
                                                value={where.constraint}
                                                onchange={(e) => {
                                                    setExportData((current) => {
                                                        const queries = [...current];
                                                        queries[queryIndex].where[whereIndex].constraint = e.detail.value;
                                                        return queries;
                                                    });
                                                }}
                                            >
                                                <IonSelectOption value="==">==</IonSelectOption>
                                                <IonSelectOption value="!=">!=</IonSelectOption>
                                                <IonSelectOption value="<">&lt;</IonSelectOption>
                                                <IonSelectOption value="<=">&lt;=</IonSelectOption>
                                                <IonSelectOption value=">">&gt;</IonSelectOption>
                                                <IonSelectOption value=">=">&gt;=</IonSelectOption>
                                                <IonSelectOption value="array_contains">array_contains</IonSelectOption>
                                                <IonSelectOption value="array_contains_any">array_contains_any</IonSelectOption>
                                            </SeaSelect>
                                        </div>
                                        <div style={{ width: '120px' }}>
                                            <SeaSelect
                                                label=" "
                                                value={where.type}
                                                onchange={(e) => {
                                                    setExportData((current) => {
                                                        const queries = [...current];
                                                        queries[queryIndex].where[whereIndex].type = e.detail.value;
                                                        return queries;
                                                    });
                                                }}
                                            >
                                                <IonSelectOption value="string">string</IonSelectOption>
                                                <IonSelectOption value="number">number</IonSelectOption>
                                                <IonSelectOption value="boolean">true/false</IonSelectOption>
                                            </SeaSelect>
                                        </div>
                                        <div>
                                            <SeaInput
                                                label=" "
                                                value={where.value}
                                                onchange={(e) => {
                                                    setExportData((current) => {
                                                        const queries = [...current];
                                                        queries[queryIndex].where[whereIndex].value = e.detail.value;
                                                        return queries;
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div style={{ padding: '20px 0px 0px 8px' }}>
                                            {(query.where.length > 1) &&
                                                <SeaButton onClick={(e) => {
                                                    setExportData((current) => {
                                                        const queries = [...current];
                                                        queries[queryIndex].where.splice(whereIndex, 1);
                                                        return queries;
                                                    });
                                                }}>-</SeaButton>
                                            }
                                            <SeaButton onClick={(e) => {
                                                setExportData((current) => {
                                                    const queries = [...current];
                                                    queries[queryIndex].where.push({
                                                        field: '',
                                                        constraint: '==',
                                                        type: 'string',
                                                        value: ''
                                                    });
                                                    return queries;
                                                });
                                            }}>+</SeaButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                <SeaButton onClick={(e) => {
                    setExportData((current) => {
                        const queries = [...current];
                        queries.push({
                            collection: '',
                            cmd: 'set',
                            where: [{
                                field: '',
                                constraint: '==',
                                type: 'string',
                                value: ''
                            }]
                        });
                        return queries;
                    });
                }}>+ Query</SeaButton>
                {(exportData.length > 0) &&
                    <>
                        <SeaButton onClick={(e) => {
                            gatherExportData(false);
                        }}>Export Json</SeaButton>
                        <SeaButton onClick={(e) => {
                            gatherExportData(true);
                        }}>Export Json Pretty</SeaButton>
                    </>
                }
            </div>
            <div style={{ paddingTop: '12px' }}>
                <h2>Import Data</h2>
                <SeaTextarea
                    value={importJson}
                    onchange={(e) => {
                        setImportJson(e.detail.value);
                    }}
                    height={500}
                />
                {importJson &&
                    <SeaButton onClick={(e) => importData()}>
                        Process Json
                    </SeaButton>
                }
            </div>
        </div>
        </SeaScrollableArea>
        </div>
    );
};

export default OrchidAdmin;
