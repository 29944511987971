import { isPlatform } from '@ionic/react';
import { Filesystem, Directory, Encoding, FileInfo } from '@capacitor/filesystem';
import { isImage, getContentType, toInt } from './util';
import { firestore, storage } from './firebase';
import { doc, setDoc } from "firebase/firestore";
import { getBlob, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { toByteArray, fromByteArray } from 'base64-js';
import { logDebug } from './logDebug';
import { Device } from '@capacitor/device';
import { sharedState } from '../shared-state/shared-state';
import { onFileCached, onFileUploaded, onFullSizeFileLoaded } from '../shared-state/General/appActivity';
import write_blob from "capacitor-blob-writer";
//import { Capacitor, Plugins } from '@capacitor/core';
//import { CapacitorSQLite } = Plugins;
//import { useSQLite, SQLiteDBConnection } from 'react-sqlite-hook/dist';

//export const filesTimer = startTimer('files');
//export let tmpLog = '';

let isFileCacheReady = false;
export let filesDirectory = isPlatform('hybrid') ? Directory.Data : Directory.Cache;
//filesDirectory = Directory.Cache;
export const filesFolder = 'SeaFluxFiles';
export const cachedFiles = {} as any;

let cacheMode = 'FULL'; // OFF | LIMITED | FULL
const minSpaceForFullCacheMode = 650000000; // 650MB
const minSpaceForNotPurging = 500000000; // 500MB
// cacheMode ON: default mode at startup
// cacheMode LIMITED: turn on if there is not enough room to store cached files. will only cache new thumbnails and signatures
// cacheMode OFF: turn on if there is not enough room to even store thumbnails. No more files will be cached.
// if space < minSpaceForFullCacheMode, cacheMode = LIMITED
// if space < minSpaceForNotPurging, will need to purge until space < minSpaceForNotPurging
//

const filesToCache = [] as string[];
const filesToCacheById = {} as any; // To prevent duplicate files being added to filesToCache
let filesBeingCached = 0;
let totalFilesDownloading = 0;
const cacheErrorsAllowed = 10;
let cacheErrorsCount = 0;
const maxSimultaneousFilesBeingCached = 1; // This must be 1 so that "no more space" handling can work

const filesToLoad = [] as any[];
let filesBeingLoaded = 0;
let maxSimultaneousFilesBeingLoaded = 2;
export const setSimultaneousLoads = (loads: number) => {
    maxSimultaneousFilesBeingLoaded = loads;
};

const filesToDelete = [] as string[];
let filesBeingDeleted = 0;
const maxSimultaneousFilesBeingDeleted = 1;

const filesToUpload = [] as string[];
let filesBeingUploaded = 0;
let totalFilesUploading = 0;
const maxSimultaneousFilesBeingUploaded = 1; // Must be 1 so that monitoring progress makes sense
const uploadErrorsAllowed = 3;
let uploadErrorsCount = 0;

let purgeableFiles = [] as any[]; // List of files that can be deleted if necessary to make space.

let onUploadInfo = (state: any) => {};
let onDownloadInfo = (state: any) => {};
let onCacheModeSet = (cacheMode: string) => {};
let onErrorsOnHoldLoaded = (errorsOnHold: any[]) => {};

export const listenToUploads = (_onUploadInfo: (state: any) => void) => {
    onUploadInfo = _onUploadInfo;
};
export const listenToDownloads = (_onDownloadInfo: (isDownloading: boolean) => void) => {
    onDownloadInfo = _onDownloadInfo;
};
export const listenToCacheMode = (_onCacheModeSet: (cacheMode: string) => void) => {
    onCacheModeSet = _onCacheModeSet;
    initCacheMode();
};
export const listenToErrorsOnHoldLoaded = (_onErrorsOnHoldLoaded: (errorsOnHold: any[]) => void) => {
    onErrorsOnHoldLoaded = _onErrorsOnHoldLoaded;
};

export const onLicenseeIdChangedForFileSync = () => { // This should be called when licenseeId is changed
    if (sharedState.licenseeId.current) {
        processFilesToUpload(); // Now that we have licenseeId we can start uploading any queued files
        processFilesToCache();  // Now that we have licenseeId we can start downloading any queued files
    }
}

export const onOnlineStatusChangedForFileSync = () => { // This should be called when onlineStatus is changed
    if (sharedState.onlineStatus.current?.isOnline) {
        processFilesToCache(); // In case processFilesToCache broke when offline
        if (sharedState.licenseeId.current) {
            processFilesToUpload(); // In case broke when offline
            processFilesToCache();  // In case broke when offline
        }
    }
}

// Register files that have been found during loading data
let filesToRegister = { // (Only needed for desktop)
    files: [] as any[],
    signatures: [] as string[],
    richText: [] as string[]
};
export const registerFiles = (files: string[] | undefined) => {
    if (files && files.length > 0) {
        if (!isFileCacheReady) {
            filesToRegister.files.push(files);
            return;
        }
        for (let i = 0; i < files.length; i++) {
            const state = toInt(files[i][0], 0);
            if (state === 0) {
                continue; // Not available to be downloaded (stuck waiting on another device)
            }
            const id = files[i].substring(1, 21);
            const ext = files[i].substring(files[i].lastIndexOf('.') + 1);
            if (state === 1) {
                // Original file
                const cachedFile = cachedFiles[id];
                if (
                    cachedFile === undefined || // No cache yet
                    cachedFile[0] === '0' // Current cached file is state=0 so needs replacing
                ) {
                    console.log('Registered file!');
                    //console.log(`Register file ${id}: `, files[i]);
                    cacheFile(1, id, ext, '');
                }
            } else { // state === 2
                // Processed image (F=full)
                let cachedFile = cachedFiles[`F${id}`];
                if (cachedFile === undefined) { // No processed image in cache yet
                    //console.log(`Register full image ${id}: `, files[i]);
                    cacheFile(2, id, ext, 'F');
                }
                // Processed image (T=tiny)
                cachedFile = cachedFiles[`T${id}`];
                if (cachedFile === undefined) { // No processed image in cache yet
                    //console.log(`Register tiny image ${id}: `, files[i]);
                    cacheFile(2, id, ext, 'T');
                }
            }
        }
    }
};
export const registerSignature = (file: string | undefined) => {
    if (file) {
        if (!isFileCacheReady) {
            filesToRegister.signatures.push(file);
            return;
        }
        const state = toInt(file[0], 0);
        if (state === 0) {
            return; // Not available to be downloaded (stuck waiting on another device)
        }
        const id = file.substring(1, 21);
        if (state === 1) {
            const cachedFile = cachedFiles[id];
            if (
                cachedFile === undefined || // No cache yet
                cachedFile[0] === '0' // Current cached file is state=0 so needs replacing
            ) {
                //console.log('Register signature file: '+file);
                cacheFile(1, id, 'png', '');
            }
        } else { // state === 2
            const cachedFile = cachedFiles[`S${id}`];
            if (cachedFile === undefined) { // No processed signature in cache yet
                //console.log('Register processed signature: '+file);
                cacheFile(2, id, 'png', 'S');
            }
        }
    }
};
export const registerRichText = (sfdoc: any | undefined) => {
    // TODO: Could deregister/remove versions that aren't the latest?
    if (sfdoc) {
        const keys = Object.keys(sfdoc);
        if (keys.length > 0) {
            if (!isFileCacheReady) {
                filesToRegister.richText.push(sfdoc);
                return;
            }
            const file = sfdoc[keys.sort()[keys.length - 1]]; // Just cache the latest version
            const state = toInt(file[0], 0);
            if (state === 0) {
                return; // Not available to be downloaded (stuck waiting on another device)
            }
            const id = file.substring(1, 21);
            if (state === 1) {
                const cachedFile = cachedFiles[id];
                if (
                    cachedFile === undefined || // No cache yet
                    cachedFile[0] === '0' // Current cached file is state=0 so needs replacing
                ) {
                    //console.log('Register rich text file: '+file);
                    cacheFile(1, id, 'sfdoc', '');
                }
            } else { // state === 2
                const cachedFile = cachedFiles[`R${id}`];
                if (cachedFile === undefined) { // No optimised rich text in cache yet
                    //console.log('Register optimised rich text: '+file);
                    cacheFile(2, id, 'sfdoc', 'R');
                }
            }
        }
    }
};

// fileType should be F for full, T for tiny, or S for signature
export const getCachedImgSrcs = (files: string[], fileType: string, callback: (srcs: string[]) => void) => {
    //console.log('getCachedSrcs files', files);
    const task = {
        fileType: fileType,
        files: [...files],
        srcs: [],
        callback: callback,
        cancel: false
    };
    filesToLoad.push(task);
    processFilesToLoad();
    return () => {
        task.cancel = true;
    };
};

const loadCachedImgSrcs = (task: any): Promise<any> => {
    if (task.cancel) {
        return Promise.resolve();
    }
    if (task.files.length === 0) {
        task.callback(task.srcs);
        return Promise.resolve();
    }
    const file = task.files.shift();
    if (file === '') {
        task.srcs.push('noCache');
        return loadCachedImgSrcs(task);
    }
    if (!isImage(file.substring(file.lastIndexOf('.') + 1))) {
        // non image file
        task.srcs.push('nonImage');
        return loadCachedImgSrcs(task);
    }
    return getCachedFileSrc(file, task.fileType).then((src) => {
        task.srcs.push(src);
    }).catch((error) => {
        task.srcs.push('noCache');
    }).finally(() => {
        return loadCachedImgSrcs(task);
    });
};

const processFilesToLoad = () => {
    if (
        filesBeingLoaded >= maxSimultaneousFilesBeingLoaded ||
        filesToLoad.length === 0
    ) {
        return;
    }
    let task = filesToLoad.shift();
    if (task === undefined) {
        logDebug('Loading cached files all done.');
        return;
    }

    filesBeingLoaded++;
    //console.log('loading cached files task', task);
    loadCachedImgSrcs(task).then(() => {
        // loaded files successfully
    }).catch((error) => {
        console.error('Error, loading cached files!', error);
    }).finally(() => {
        filesBeingLoaded--;
        processFilesToLoad();
    });
};


// If in cache, return src with embedded base64
// If not, return Promise.reject()
export const getCachedFileSrc = (file: string, fileType = ''): Promise<string> => {
    const id = file.substring(1, 21);
    const ext = file.substring(file.lastIndexOf('.') + 1);
    const contentType = getContentType(ext);

    let cachedFile = cachedFiles[`${fileType}${id}`];
    if (cachedFile === undefined) {
        // try for original file
        cachedFile = cachedFiles[id];
    }
    if (cachedFile) {
        const whenStarted = Date.now();
        return loadFileBase64(`${filesFolder}/${cachedFile}`).then((base64) => {
            if (base64) {
                if (fileType === 'F') {
                    onFullSizeFileLoaded(whenStarted, base64.length);
                }
                if (contentType === 'application/seaflux') {
                    return base64; // Just return data directly
                }
                return `data:${contentType};base64, ${base64}`;
            } else {
                return `data:text/plain;base64, ${base64}`;
            }
        }).catch((error) => {
            logDebug('Error loading cached file: '+JSON.stringify(error));
            return Promise.reject();
        });
    } else {
        console.log(`Could not find cached file for ${file}!`);
    }
    return Promise.reject(); // File not found in cache
};
// Get file URI for a cached file
export const getCachedFileUri = (file: string, fileType = ''): Promise<string> => {
    const id = file.substring(1, 21);
    let cachedFile = cachedFiles[`${fileType}${id}`];
    if (cachedFile === undefined) {
        cachedFile = cachedFiles[id];
    }
    if (cachedFile) {
        return Filesystem.getUri({
            path: `${filesFolder}/${cachedFile}`,
            directory: filesDirectory
        }).then((result) => {
            console.log('getCachedFileUri result='+JSON.stringify(result));
            return result.uri;
        }).catch((error) => {
            logDebug('Error getting cached file uri: '+JSON.stringify(error));
            return Promise.reject();
        });
    }
    return Promise.reject('Cached file missing: '+file);
}

// Cache file to local storage by fetching from Storage
// fileType: ''=any 'F'=full image 'T'=tiny image 'S'=processed signature
export const cacheFile = (state: number, id: string, ext: string, fileType = '') => {
    if (cacheMode === 'OFF') {
        return; // no caching currently allowed
    }
    const fileToCache = `${state}${fileType}${id}.${ext}`;
    if (filesToCacheById[fileToCache]) {
        return; // Already queued
    }
    filesToCacheById[fileToCache] = true; // Need to record that we are handling this fileToCache so we don't double up
    // Save temporary cache placeholder file
    // Filesystem.writeFile({
    //     path: `${filesFolder}/__${fileToCache}`,
    //     data: '',
    //     directory: filesDirectory,
    //     encoding: Encoding.UTF8,
    //     recursive: true // create any missing parent directories
    // console.log(`cacheFile ${filesFolder}/__${fileToCache}`);
    write_blob({ // (Replaced Filesystem.writeFile with write_blob)
        blob: new Blob([]),
        path: `${filesFolder}/__${fileToCache}`,
        directory: filesDirectory,
        recursive: true
    }).catch((error) => {
        console.error(`error creating cache placeholder fileToCache=${fileToCache}`, error);
        //return Promise.reject(error);
    }).then((savedFile: any) => {
        //console.log('Saved placeholder file '+JSON.stringify(savedFile));
        filesToCache.push(fileToCache);
        totalFilesDownloading++;
        processFilesToCache();
    });
};
const processFilesToCache = () => {
    //console.log(`processFilesToCache length=${filesToCache.length} filesBeingCached=${filesBeingCached}`);
    if (
        !isFileCacheReady ||
        !sharedState.onlineStatus.current?.isOnline ||
        !sharedState.licenseeId.current ||
        filesBeingCached >= maxSimultaneousFilesBeingCached
    ) {
        return;
    }
    const fileToCache = filesToCache.shift();
    if (fileToCache === undefined) {
        logDebug('Caching files all done.');
        totalFilesDownloading = 0;
        //saveFilesToCacheToJsonFile();
        onDownloadInfo({
            isDownloading: false,
            totalFiles: totalFilesDownloading,
            filesLeft: 0
        });
        return;
    }

    //logDebug('fileToCache='+fileToCache);
    const state = toInt(fileToCache[0], 0);
    if (state === 0) {
        logDebug('Nothing to cache for '+fileToCache+' as it is a local file on another device');
        processFilesToCache();
        return;
    }

    filesBeingCached++;
    onDownloadInfo({
        isDownloading: true,
        totalFiles: totalFilesDownloading,
        filesLeft: filesToCache.length
    });

    return processFileToCache(fileToCache, state).catch((error) => {
        //logDebug('Failed to cache file '+fileToCache+' '+JSON.stringify(error));
        delete filesToCacheById[fileToCache];
        if (cacheErrorsCount > cacheErrorsAllowed) {
            cacheErrorsCount = 0;
            //console.log(`processFileToCache failed ${cacheErrorsAllowed} times for fileToCache=${cacheErrorsAllowed}`);
            onDownloadInfo({
                error: error,
                data: {
                    cause: `cacheErrorsCount exceeded ${cacheErrorsAllowed}`
                }
            });
        } else {
            //logDebug('Pushing '+fileToCache+' to cache queue');
            if (sharedState.onlineStatus.current?.isOnline) {
                cacheErrorsCount++;
            }
            if (fileToCache) {
                filesToCache.push(fileToCache); // Queue up file to be attempted to be cached again
                filesToCacheById[fileToCache as string] = true;
            }
        }
    }).finally(() => {
        filesBeingCached--;
        //console.log('Finished caching a file. filesToCache.length='+filesToCache.length);
        processFilesToCache();
        setTimeout(() => {
            processFilesToCache(); // Call just to make sure loop continues
        }, 5000);
    });
};

const processFileToCache = (fileToCache: string, state: number): Promise<any> => {
    if (cacheMode === 'OFF') {
        return Promise.resolve(); // caching is off so just ignore
    }
    let id: string;
    let fileType = '';
    let type = '';
    if (state === 2) {
        fileType = fileToCache[1];
        id = fileToCache.substring(2, 22);
        switch (fileType) {
            case 'T':
                type = '_tiny'; break;
            case 'S':
                type = '_sig'; break;
            case 'R':
                type = '_opt'; break; // (optimised Rich Text *.sfdoc file)
            default: // F=full
                type = '_full';
        }
    } else {
        id = fileToCache.substring(1, 21);
    }

    if (cacheMode === 'LIMITED' && !(fileType === 'T' || fileType === 'S')) {
        // if not a signature or thumbnail, we don't wont to cache in LIMITED mode
        return Promise.resolve();
    }
    const ext = fileToCache.substring(fileToCache.lastIndexOf('.') + 1);
    //const url = getStorageUrl(licenseeId, id, ext, type);

    const whenStarted = Date.now();
    let size = 0;
    return ensureFreeSpace().then(() => {
        const fileRef = storageRef(storage, `files/${id}${type}.${ext}`);
        // Download via a blob EVERY TIME
        return getBlob(fileRef).then((blob: Blob) => {
            size = blob.size;
            return saveFileToLocalStorage(state, id, ext, fileType, blob);
        });
        // if (isPlatform('hybrid')) {
        //     // Hybrid app: download via ArrayBuffer 
        //     return getBytes(fileRef).then((buffer: ArrayBuffer) => {
        //         return saveFileUsingBuffer(state, id, ext, fileType, buffer);
        //     });
        // } else {
        //     // Not hybrid app: download via Blob
        //     return getBlob(fileRef).then((blob: Blob) => {
        //         return saveFileToLocalStorage(state, id, ext, fileType, blob);
        //     //     return convertBlobToBase64(blob);
        //     // }).then((base64) => {
        //     //     return saveFileToLocalStorage(state, id, ext, fileType, base64 as string);
        //     });
        // }
    }).then(() => {
        // successfully cached file
        console.log('cached file!');
        onFileCached(whenStarted, size);
        delete filesToCacheById[fileToCache];

        // Delete cache placeholder file
        return Filesystem.deleteFile({
            path: `${filesFolder}/__${fileToCache}`,
            directory: filesDirectory
        }).catch((error) => {
            console.error(`error deleting cache placeholder fileToCache=${fileToCache}`, error);
        });
    });

    // let url: string;
    // return getDownloadURL(
    //     storageRef(storage, `files/${id}${type}.${ext}`)
    // ).then((_url) => {
    //     url = _url;
    //     return ensureFreeSpace();
    // }).then(() => {
    //     return fetch(url, {
    //         //method: 'GET',
    //         //withCredentials: true,
    //         //crossorigin: true,
    //         //mode: 'no-cors'
    //     });
    // }).then((response) => {
    //     if (response.ok) {
    //         if (isPlatform('hybrid')) {
    //             return response.arrayBuffer()
    //             .then((buffer: ArrayBuffer) => {
    //                 return saveFileUsingBuffer(state, id, ext, fileType, buffer);
    //             });
    //         } else {
    //             return response.blob()
    //             .then((blob) => {
    //                 return convertBlobToBase64(blob);
    //             }).then((base64) => {
    //                 return saveFileToLocalStorage(state, id, ext, fileType, base64 as string);
    //             });
    //         }
    //     }
    //     //logDebug('response not ok');
    //     return Promise.reject({message: 'fetch returned statusText='+response.statusText});
    // }).then(() => {
    //     // successfully cached file
    //     delete filesToCacheById[fileToCache];

    //     // Delete cache placeholder file
    //     return Filesystem.deleteFile({
    //         path: `${filesFolder}/__${fileToCache}`,
    //         directory: filesDirectory
    //     }).catch((error) => {
    //         console.error(`error deleting cache placeholder fileToCache=${fileToCache}`, error);
    //     });
    // });
};

const initCacheMode = () => {
    Device.getInfo().then((info) => {
        if (info.realDiskFree && info.realDiskFree < minSpaceForFullCacheMode) {
            cacheMode = 'LIMITED';
        } else {
            cacheMode = 'FULL';
        }
        logDebug(`initCacheMode ${cacheMode} difference=${((info.realDiskFree ? info.realDiskFree : 0) - minSpaceForFullCacheMode)}`);
        onCacheModeSet(cacheMode);
    });
};

const ensureFreeSpace = (): Promise<any> => {
    return Device.getInfo().then((info) => {
        //logDebug(`info.realDiskFree=${info.realDiskFree}`);
        if (info.realDiskFree && info.realDiskFree < minSpaceForFullCacheMode) {
            //logDebug(`>>> not enough space for full cacheMode. missing=${ info.realDiskFree - minSpaceForFullCacheMode }`);
            cacheMode = 'LIMITED';
            onCacheModeSet(cacheMode);
            if (info.realDiskFree < minSpaceForNotPurging) {
                logDebug(`Not enough space for not purging even! missing=${ info.realDiskFree - minSpaceForNotPurging }`);
                // Also need to purge first
                return purgeFiles();
            }
        }
        return Promise.resolve();
    });
};

export const findPurgeableFiles = () => {
    const startTime = Date.now();
    let files = [] as any[];
    return Filesystem.readdir({
        path: filesFolder,
        directory: filesDirectory
    }).then((result) => {
        const gatherFileInfo = (_files: FileInfo[]): Promise<any> => {
            if (_files.length === 0) {
                return Promise.resolve();
            }
            const file = _files.shift();
            if (
                file &&
                !file.name.startsWith('_') &&
                !file.name.startsWith('2T') &&
                !file.name.endsWith('.__chunk')
            ) {
                return Filesystem.stat({
                    path: `${filesFolder}/${file.name}`,
                    directory: filesDirectory
                }).then((result) => {
                    //console.log('>>> result', result);
                    files.push({
                        file: file.name,
                        size: result.size
                    });
                    return gatherFileInfo(_files);
                });
            }
            return gatherFileInfo(_files);
        };
        return gatherFileInfo(result.files);
    }).then(() => {
        logDebug(`Gathered ${files.length} files. Took ${(Date.now() - startTime)}`);
        files.sort((a, b) => {
            return a.size - b.size;
        });
        const signatures: string[] = [];
        const others: string[] = [];
        files.forEach((file) => {
            if (file.file.startsWith('2S')) {
                signatures.push(file);
            } else {
                others.push(file);
            }
        });
        files = [...signatures, ...others];
        logDebug(`Processed ${files.length} files. Took ${(Date.now() - startTime)}`);
        purgeableFiles = files;
    });
};

const purgeFiles = (): Promise<any> => {
    if (purgeableFiles.length === 0) {
        return findPurgeableFiles().then(() => {
            if (purgeableFiles.length === 0) {
                cacheMode = 'OFF'; // turn caching off
                onCacheModeSet(cacheMode);
                logDebug('cacheMode OFF');
                return Promise.reject('No files left to purge!');
            } else {
                return purgeFiles();
            }
        });
    }
    const fileToPurge = purgeableFiles.pop();
    logDebug(`Purging ${filesFolder}/${fileToPurge.file}`);
    const purgeFile = (fileNumber: number): Promise<any> => {
        return Filesystem.deleteFile({
            path: `${filesFolder}/${fileToPurge.file}${fileNumber > 1 ? `.${fileNumber}.__chunk` : ''}`,
            directory: filesDirectory
        }).then(() => {
            return purgeFile(fileNumber + 1);
        }).catch((e) => {
            if (fileNumber === 1) {
                logDebug('ERROR deleting file e='+JSON.stringify(e));
            }
            return Promise.resolve();
        });
    };
    return purgeFile(1).then(() => {
        const id = (fileToPurge.file[0] === '2') ? fileToPurge.file.substring(1, 22) : fileToPurge.file.substring(1, 21);
        delete cachedFiles[id];
        logDebug(`Deleted fileToPurge=${fileToPurge.file}`);
        return Device.getInfo();
    }).then((info) => {
        logDebug(`New info.realDiskFree=${info.realDiskFree}`);
        //logDebug(`>>> new minSpaceRequired=${minSpaceRequired}`);
        //logDebug(`>>> new isSmaller=${(info.realDiskFree && info.realDiskFree < minSpaceRequired)?'YES':'NO'}`);
        if (info.realDiskFree && info.realDiskFree < minSpaceForNotPurging) {
            return purgeFiles();
        }
        return Promise.resolve();
    }).catch((e) => {
        logDebug('another ERROR! e='+JSON.stringify(e));
        return Promise.resolve();
    });
};





























// Save file to local storage
export const saveFileToLocalStorage = (state: number, id: string, ext: string, fileType: string, blob: Blob): Promise<void> => {
    // return Filesystem.writeFile({
    //     path: `${filesFolder}/${state}${fileType}${id}.${ext}`,
    //     data: base64,
    //     directory: filesDirectory,
    //     recursive: true // create any missing parent directories
    // })
    //console.log(`saveFileToLocalStorage state=${state} id=${id} ext=${ext} fileType=${fileType} blob.size=${blob?.size}`);
    return write_blob({ // (Replace Filesytem.writeFile with write_blob)
        blob: blob,
        path: `${filesFolder}/${state}${fileType}${id}.${ext}`, 
        directory: filesDirectory, 
        recursive: true
    }).catch((error) => {
        //console.error('saveFileToLocalStorage had error for id='+id+', error='+JSON.stringify(error));
        return Promise.reject(error);
    }).then(() => {
        // Delete any cached files with a lesser state
        if (state === 2) {
            if (cachedFiles[id]) {
                deleteFile(cachedFiles[id]);
                delete cachedFiles[id];
            }
        } else if (cachedFiles[id] && cachedFiles[id] !== `${state}${id}.${ext}`) {
            deleteFile(cachedFiles[id]);
        }
        // Add to cachedFiles map
        cachedFiles[`${fileType}${id}`] = `${state}${fileType}${id}.${ext}`;
        return Promise.resolve();
    });
};
// const sliceSize = 1024 * 1024; // chunk size to write to files using
// const maxFileSlices = 10; // Max file size = 10MB (Files larger than this get broken up)
// export const saveFileUsingBuffer = (state: number, id: string, ext: string, fileType: string, buffer: ArrayBuffer): Promise<void> => {
//     //logDebug('buffer.byteLength='+buffer.byteLength);
//     // const path = `${filesFolder}/_tmp${state}${fileType}${id}.${ext}`;

//     // Save buffer to 1 or more files.
//     // Returns the number of files saved.

//     return write_blob({
//         blob: new Blob([buffer]),
//         path: `${filesFolder}/${state}${fileType}${id}.${ext}`,
//         directory: filesDirectory,
//         recursive: true
//     }).then(() => {
//         //logDebug(`Renamed cached file to ${filesFolder}/${state}${fileType}${id}.${ext}`);
//         // Delete any cached files with a lesser state
//         if (state === 2) {
//             if (cachedFiles[id]) {
//                 deleteFile(cachedFiles[id]);
//                 delete cachedFiles[id];
//             }
//         } else if (cachedFiles[id] && cachedFiles[id] !== `${state}${id}.${ext}`) {
//             deleteFile(cachedFiles[id]);
//         }
//         // Add to cachedFiles map
//         cachedFiles[`${fileType}${id}`] = `${state}${fileType}${id}.${ext}`;
//         return Promise.resolve();
//     });

//     // const saveChunks = (sliceIndex: number, fileCount: number): Promise<number> => {
//     //     const bufferIndexFrom = sliceIndex * sliceSize;
//     //     if (bufferIndexFrom >= buffer.byteLength) {
//     //         return Promise.resolve(fileCount); // Finished!
//     //     }
//     //     const bufferIndexTo = Math.min(buffer.byteLength, bufferIndexFrom + sliceSize);

//     //     if (sliceIndex % maxFileSlices === 0) { // Create new file
//     //         return Filesystem.writeFile({
//     //             path: `${path}${fileCount > 0 ? `.${fileCount + 1}.__chunk` : ''}`,
//     //             data: arrayBufferToBase64(buffer.slice(bufferIndexFrom, bufferIndexTo)),
//     //             directory: filesDirectory,
//     //             recursive: true // Create any missing parent directories
//     //         }).then((savedFile: any) => {
//     //             return saveChunks(sliceIndex + 1, fileCount + 1);
//     //         });
//     //     } else { // Append to existing file
//     //         return Filesystem.appendFile({
//     //             path: `${path}${fileCount > 1 ? `.${fileCount}.__chunk` : ''}`,
//     //             data: arrayBufferToBase64(buffer.slice(bufferIndexFrom, bufferIndexTo)),
//     //             directory: filesDirectory
//     //         }).then(() => {
//     //             //logDebug('appended to file ending '+newSliceIndex);
//     //             return saveChunks(sliceIndex + 1, fileCount);
//     //         });
//     //     }
//     // };

//     // const renameTmpFiles = (fileNumber: number): Promise<any> => {
//     //     if (fileNumber < 1) {
//     //         return Promise.resolve();
//     //     }
//     //     return Filesystem.rename({
//     //         from: `${path}${fileNumber > 1 ? `.${fileNumber}.__chunk` : ''}`,
//     //         to: `${filesFolder}/${state}${fileType}${id}.${ext}${fileNumber > 1 ? `.${fileNumber}.__chunk` : ''}`,
//     //         directory: filesDirectory
//     //     }).then(() => {
//     //         return renameTmpFiles(fileNumber - 1);
//     //     });
//     // };

//     // return saveChunks(0, 0).catch((error: any) => {
//     //     logDebug('saveFileUsingBuffer had error for id='+id+', error='+JSON.stringify(error));
//     //     return Promise.reject(error);
//     // }).then((numFilesSaved: number) => {
//     //     //logDebug(`Finished file ${path}`);
//     //     return renameTmpFiles(numFilesSaved);
//     //     // return Filesystem.rename({
//     //     //     from: `${pathPrefix}.${ext}`,
//     //     //     to: `${filesFolder}/${state}${fileType}${id}.${ext}`,
//     //     //     directory: filesDirectory
//     //     // });
//     // }).then(() => {
//     //     //logDebug(`Renamed cached file to ${filesFolder}/${state}${fileType}${id}.${ext}`);
//     //     // Delete any cached files with a lesser state
//     //     if (state === 2) {
//     //         if (cachedFiles[id]) {
//     //             deleteFile(cachedFiles[id]);
//     //             delete cachedFiles[id];
//     //         }
//     //     } else if (cachedFiles[id] && cachedFiles[id] !== `${state}${id}.${ext}`) {
//     //         deleteFile(cachedFiles[id]);
//     //     }
//     //     // Add to cachedFiles map
//     //     cachedFiles[`${fileType}${id}`] = `${state}${fileType}${id}.${ext}`;
//     //     return Promise.resolve();
//     // });
// };

export const loadFileBase64 = (_path: string) => {
    const readBase64 = (base64s: string[], fileNumber: number): Promise<any> => {
        const path = `${_path}${(fileNumber > 1) ? `.${fileNumber}.__chunk` : ''}`;
        return Filesystem.readFile({
            path: path,
            directory: filesDirectory
        }).then((content) => {
            if (content?.data) {
                if (content.data && (content.data as string).length > 0) {
                    return readBase64([...base64s, content.data as string], fileNumber + 1);
                }
                return base64s;
            }
            return Promise.reject(`No data found for file ${path}`);
        }).catch((error) => {
            //if (error?.message && error.message.toLowerCase().indexOf('exist') !== -1) {
            if (fileNumber > 1) { // Probably just no *.__chunk file to load
                return base64s; // Nothing to add to base64
            }
            return Promise.reject(error?.message);
        });
    };

    return readBase64([], 1).then((base64s: string[]) => {
        if (base64s.length > 0) {
            if (base64s.length === 1) {
                return base64s[0];
            } else {
                // Need to combine base64 strings as you can't just concatenate them due to variable padding
                console.log(`Combining ${base64s.length} base64 chunks for ${_path}...`);
                const byteArrays = [] as Uint8Array[];
                let combinedLength = 0;

                for (let i = 0; i < base64s.length; i++) {
                    byteArrays[i] = toByteArray(base64s[i]);
                    combinedLength += byteArrays[i].length;
                    console.log(`>>> byteArrays[${i}].length=${byteArrays[i].length}`);
                }
                const combinedByteArray = new Uint8Array(combinedLength);
                let offset = 0;
                byteArrays.forEach(byteArray => {
                    combinedByteArray.set(byteArray, offset);
                    offset += byteArray.length;
                });

                return fromByteArray(combinedByteArray);
            }
        }
        return Promise.reject(`No data found for file ${_path}`);
    });
};

// Cache file to local storage by fetching from Storage
export const deleteFile = (fileToDelete: string) => {
    filesToDelete.push(fileToDelete);
    processFilesToDelete();
};
const processFilesToDelete = () => {
    if (!isFileCacheReady || filesBeingDeleted >= maxSimultaneousFilesBeingDeleted) {
        return; // Enough other threads already working on this
    }
    const fileToDelete = filesToDelete.shift();
    if (fileToDelete === undefined) {
        return;
    }
    filesBeingDeleted++;
    const processDeletion = (fileNumber: number): Promise<any> => {
        return Filesystem.deleteFile({
            path: `${filesFolder}/${fileToDelete}${fileNumber > 1 ? `.${fileNumber}.__chunk` : ''}`,
            directory: filesDirectory
        }).then(() => {
            return processDeletion(fileNumber + 1);
        }).catch((error) => {
            if (fileNumber === 1) {
                console.error('error deleting file', error);
                logDebug(`ERROR deleting file ${JSON.stringify(error)} (ignoring)`);
            }
            return Promise.resolve();
        });
    };

    return processDeletion(1).then(() => {
        logDebug(`deleted file ${fileToDelete}`);
        filesBeingDeleted--;
        processFilesToDelete();
        return Promise.resolve();
    });
};

let errorsOnHoldToSave = undefined as any;
let errorsOnHoldSaving = false;
export const saveErrorsOnHold = (errorsOnHold: any[]) => {
    errorsOnHoldToSave = errorsOnHold;
    setTimeout(() => {
        processErrorsOnHoldToSave();
    }, 100);
};
const processErrorsOnHoldToSave = () => {
    if (errorsOnHoldSaving) return; // Already in the process of saving
    errorsOnHoldSaving = true;
    if (errorsOnHoldToSave !== undefined && isFileCacheReady) {
        const jsonString = JSON.stringify(errorsOnHoldToSave);
        errorsOnHoldToSave = undefined;
        // Filesystem.writeFile({
        //     path: `${filesFolder}/_errorsOnHold.json`,
        //     data: jsonString,
        //     encoding: Encoding.UTF8,
        //     directory: filesDirectory,
        //     recursive: true // create any missing parent directories
        write_blob({ // (Replaced Filesystem.writeFile with write_blob)
            blob: new Blob([jsonString], { type: 'text/plain' }),
            path: `${filesFolder}/_errorsOnHold.json`,
            directory: filesDirectory,
            recursive: true
        }).catch((error) => {
            logDebug('Error saving _errorsOnHold.json error='+JSON.stringify(error));
            //return Promise.reject(error);
        }).then((savedFile: any) => {
            logDebug('Saved filesToCache file '+JSON.stringify(savedFile));
            //return Promise.resolve();
        }).finally(() => {
            errorsOnHoldSaving = false;
            setTimeout(() => {
                processErrorsOnHoldToSave();
            }, 100);
        });
    } else {
        errorsOnHoldSaving = false;
        setTimeout(() => {
            processErrorsOnHoldToSave();
        }, 100);
    }
};
const loadErrorsOnHold = () => {
    Filesystem.readFile({
        path: `${filesFolder}/_errorsOnHold.json`,
        directory: filesDirectory,
        encoding: Encoding.UTF8
    }).then((content) => {
        //console.log('loadErrorsOnHold content.data', content.data);
        if (content && content.data) {
            onErrorsOnHoldLoaded(JSON.parse(content.data as string));
        }
    }).catch((error) => {
        // No json file, can just ignore
    });
};

let errorsToSave = [] as any[];
let errorsSaving = false;
export const saveError = (error: any) => {
    errorsToSave.push(error);
    setTimeout(() => {
        processErrorsToSave();
    }, 100);
};
const processErrorsToSave = () => {
    if (errorsSaving) return; // Already in the process of saving
    errorsSaving = true;
    if (errorsToSave.length > 0) {
        const errorToSave = errorsToSave.shift();
        const dataToAppend = JSON.stringify(errorToSave) + ',';
        // Note: When loading this file, it will and to be wrapped in [ ... ] to get array of objects
        // return Filesystem.appendFile({
        //     path: `${filesFolder}/_errors.json`,
        //     data: dataToAppend,
        //     encoding: Encoding.UTF8,
        //     directory: filesDirectory
        return write_blob({ // (Replace Filesystem.appendFile with write_blob)
            blob: new Blob([dataToAppend], { type: 'text/plain' }),
            path: `${filesFolder}/_errors.json`,
            directory: filesDirectory,
            recursive: true
        }).catch((error) => {
            logDebug('Error saving _errors.json error='+JSON.stringify(error));
        }).then((savedFile: any) => {
            logDebug('Appended to _errors.json dataToAppend='+dataToAppend);
        }).finally(() => {
            errorsSaving = false;
            setTimeout(() => {
                processErrorsOnHoldToSave();
            }, 100);
        });
    } else {
        errorsSaving = false;
        setTimeout(() => {
            processErrorsToSave();
        }, 100);
    }
};

// let cacheJsonN = 0;
// const saveFilesToCacheToJsonFile = () => {
//     if (isPlatform('hybrid') || true) {
//         const files = [...filesToCache];
//         const n = ++cacheJsonN;
//         setTimeout(() => {
//             if (n === cacheJsonN) {
//                 // Create user file
//                 Filesystem.writeFile({
//                     path: `${filesFolder}/_filesToCache${licenseeId}.json`,
//                     data: JSON.stringify(files),
//                     encoding: Encoding.UTF8,
//                     directory: filesDirectory,
//                     recursive: true // create any missing parent directories
//                 }).catch((error) => {
//                     logDebug('error saving filesToCache file error='+JSON.stringify(error));
//                     //return Promise.reject(error);
//                 }).then((savedFile: any) => {
//                     logDebug('Saved filesToCache file '+JSON.stringify(savedFile));
//                     //return Promise.resolve();
//                 });
//             }
//         }, 1000);
//     }
// };

// export const loadFilesToCacheFromJsonFile = (licenseeId: string) => {
//     Filesystem.readFile({
//         path: `${filesFolder}/_filesToCache${licenseeId}.json`,
//         directory: filesDirectory,
//         encoding: Encoding.UTF8
//     }).then((content) => {
//         console.log('>>> loadFilesToCacheFromJsonFile content.data', content.data);
//         const files = JSON.parse(content.data);
//         if (files && files.length > 0) {
//             files.forEach((file: string) => {
//                 console.log('[] file', file);
//                 if (file.startsWith('SIG')) {
//                     registerSignature(file.substring(3));
//                 } else {
//                     registerFiles([file]);
//                 }
//             });
//         }
//     }).catch((error) => {
//         // no json file, can just ignore
//     });
// };














































// ...
export const uploadFile = (filePath: string) => {
    filesToUpload.push(filePath);
    totalFilesUploading++;
    processFilesToUpload();
};
const processFilesToUpload = () => {
    if (
        !isFileCacheReady ||
        !sharedState.onlineStatus.current?.isOnline ||
        !sharedState.licenseeId.current ||
        filesBeingUploaded >= maxSimultaneousFilesBeingUploaded
    ) {
        return;
    }
    const fileToUpload = filesToUpload.shift();
    if (fileToUpload === undefined) {
        return;
    }
    const id = fileToUpload.substring(1, 21);
    if (cachedFiles[id] && cachedFiles[id] !== fileToUpload) {
        console.log('File already must be uploaded ('+fileToUpload+')');
        deleteFile(fileToUpload);
        processFilesToUpload();
        return;
    }

    //totalFilesUploading = Math.max(totalFilesUploading, filesToUpload.length + 1);
    filesBeingUploaded++;
    // const state = toInt(fileToUpload[0], 0);
    const ext = fileToUpload.substring(fileToUpload.lastIndexOf('.') + 1);
    // const type = isImage(ext) ? '_full' : '';
    // const url = getStorageUrl(licenseeId, id, ext, type);

    // If possible, we want:
    //   file name
    //   contentType
    
    const whenStarted = Date.now();
    let size = 0;
    loadFileBase64(`${filesFolder}/${fileToUpload}`).then((base64) => {
        //console.log('>>> content', content);
        console.log(`Uploading to storage files/${id}.${ext}`);
        const ref = storageRef(storage, `files/${id}.${ext}`);
        //const ref = storage.ref().child(`files/${id}.${ext}`);
        const now = Date.now();
        const metadata = {
            cacheControl: 'public,max-age=30000000',
            customMetadata: {
                licenseeIds: sharedState.licenseeId.current,
                whenUploaded: now,
                whenMetadata: now,
                //passcode: generateCode(20),
                fromDevice: true
                // Not available from device...
                // uploadedBy: userId
                // collection: file.collection,
                // field: file.field
            }
        } as any;
        // if (file.name) {
        //     metadata.contentDisposition = `attachment; filename="${file.name}"`;
        // }
        if (false) { //contentType) {
            //metadata.contentType = file.contentType;
        } else if (ext && getContentType(ext)) {
            metadata.contentType = getContentType(ext);
        }

        let uploadTask = undefined as any;
        if (base64) {
            size = base64.length;
            //uploadTask = uploadString(ref, content.data, 'base64', metadata);
            //uploadTask = uploadString(ref, content.data, 'base64', metadata);
            uploadTask = uploadBytesResumable(
                ref,
                toByteArray(base64),
                metadata
            );
        }
        return doUploadTask(uploadTask);

    }).then(() => {
        console.log('Completed file!', fileToUpload);
        
        onFileUploaded(whenStarted, size);
        // Update file state to 1 (uploaded)

        setDoc(
            doc(firestore, 'files', id),
            {
                state: 1
            },
            { merge: true }
        ).then(() => {
            // Successfully updated state in file document
            // Let's move it
            Filesystem.rename({
                from: `${filesFolder}/0${id}.${ext}`,
                to: `${filesFolder}/1${id}.${ext}`,
                directory: filesDirectory
            }).then(() => {
                console.log(`Renamed 0${id}.${ext} to 1${id}.${ext}`);
                cachedFiles[id] = `1${id}.${ext}`;
            }).catch((error) => {
                console.error(`Error renaming 0${id}.${ext} to 1${id}.${ext}`, error);
                onUploadInfo({
                    error: error,
                    data: {
                        message: `Error renaming 0${id}.${ext} to 1${id}.${ext}`,
                        fileId: id,
                        cachedFilesOfId: cachedFiles[id]
                    }
                });
            });
        }).catch((error: any) => {
            // File successully uploaded but failed to update /files doc
            // Possible reasons:
            // [1] Have changed licencee on device and therefore failing security rules (unlikely)
            // [2] The batch write that occurred subsequent to the file being uploaded (or cached to be uploaded) must have failed (or failed upon sync) <--- much more likely
            // In the event of [2], we'll want to get rid of ${filesFolder}/${fileToUpload} so we don't try again (and fail forever!)
            // We could also attempt to delete the file from storage? ...no, this probably has negative security implications
            console.log(`Failed to update file.id=${id} after successfully uploading files/${id}.${ext}`);
            
            // If we can't update state it's probably the fact that the original document this file was uploaded for was never written.
            // We can assume an error report has already been generated for the original problem.
            // The original problem probably involved the entire batch failing, therefore the file will never be wanted anyway...
            // Therefore, let's not show an error message this time...
            // onUploadInfo({
            //     error: error,
            //     data: {
            //         message: `Error updating file state`,
            //         fileId: id,
            //         cachedFilesOfId: cachedFiles[id]
            //     }
            // });

            // Delete local file so this doesnt keep happening...
            deleteFile(`${filesFolder}/0${id}.${ext}`);
        });

        if (filesToUpload.length === 0) {
            totalFilesUploading = 0;
            onUploadInfo({
                isUploading: false,
                totalFiles: totalFilesUploading,
                filesLeft: filesToUpload.length,
                progress: 1
            });
        }

        return Promise.resolve();
    }).catch((error) => {
        logDebug('ERROR processFilesToUpload '+JSON.stringify(error));
        if (uploadErrorsCount > uploadErrorsAllowed) {
            uploadErrorsCount = 0;
            onUploadInfo({
                error: error,
                data: {
                    cause: `uploadErrorsCount exceeded ${uploadErrorsAllowed}`
                }
            });
        } else {
            logDebug('Pushing '+fileToUpload+' to upload queue');
            if (sharedState.onlineStatus.current?.isOnline) {
                uploadErrorsCount++;
            }
            filesToUpload.push(fileToUpload); // Queue up file to be attempted to be uploaded again
        }
    }).finally(() => {
        filesBeingUploaded--;
        processFilesToUpload();
    });
};

const doUploadTask = (uploadTask: any): Promise<void> => {
    return new Promise((resolve, reject) => {
        onUploadInfo({
            isUploading: true,
            totalFiles: totalFilesUploading,
            filesLeft: filesToUpload.length,
            progress: ((totalFilesUploading - 1 - filesToUpload.length) / totalFilesUploading)
        });
        uploadTask.on('state_changed',
            (snap: any) => {
                let progress = (snap.bytesTransferred / snap.totalBytes);
                // progress *= (1.0 / filesToUpload.length);
                // progress += (1.0 / filesToUpload.length) * index;
                console.log('Progress: ' + progress);
                onUploadInfo({
                    isUploading: true,
                    totalFiles: totalFilesUploading,
                    filesLeft: filesToUpload.length,
                    progress: (progress / totalFilesUploading)
                        + ((totalFilesUploading - 1 - filesToUpload.length) / totalFilesUploading)
                });
                // setUploadProgress(progress);
            },
            (error: any) => {
                console.error('upload error!', error);
                switch (error.code) {
                    case 'storage/unauthorized':
                        return reject({message: 'Access to upload was denied'});
                    case 'storage/canceled':
                        return reject({code: 'cancelled', message: 'Upload was cancelled'});
                    // case 'storage/unknown':
                    //     // Unknown error occurred, inspect error.serverResponse
                    //     break;
                }
                return reject(error);
            },
            () => {
                onUploadInfo({
                    isUploading: true,
                    totalFiles: totalFilesUploading,
                    filesLeft: filesToUpload.length,
                    progress: ((totalFilesUploading - filesToUpload.length) / totalFilesUploading)
                });
                resolve();
            }
        );
    });
};

// Lists contents of filesFolder and places references into cachedFiles
// Will cleanup any files with obsolete states
export const initFileSync = (): Promise<void> => {
    logDebug('Init fileSync...');
    //filesTimer.mark('initFileSync');
    const initStarted = Date.now();
    return Filesystem.mkdir({ // Make sure filesDirectory exists
        path: filesFolder,
        directory: filesDirectory
    }).catch((error) => {
        //filesTimer.mark('caught mkdir error');
        // Ignore error, it's probably just that the directory already exists
    }).then(() => {
        //filesTimer.mark('Filesystem.readdir');
        return Filesystem.readdir({
            path: filesFolder,
            directory: filesDirectory
        });
    }).then((result) => {
        //filesTimer.mark('Filesystem.readdir done');
        let countFilesToCache = 0;
        let countCachedFiles = 0;
        
        result.files.forEach((file) => {
            //console.log(`<> file=${file}!`);
            if (file.name.startsWith('_')) {
                if (file.name.startsWith('__')) {
                    // Deal with cache placeholder file
                    const fileToCacheKey = file.name.substring(2);
                    //console.log('Found cache placeholder file='+fileToCacheKey);
                    filesToCache.push(fileToCacheKey);
                    filesToCacheById[fileToCacheKey] = true;
                    totalFilesDownloading++;
                    countFilesToCache++;
                } else if (file.name.startsWith('_tmp')) {
                    // Deal with unfinished file
                    logDebug('Found unfinished file='+file.name);
                    deleteFile(file.name);
                } else if (file.name === '_errorsOnHold.json') {
                    loadErrorsOnHold();
                } else {
                    logDebug('Skip file='+file.name);
                }
                return;
            }
            if (file.name.endsWith('.__chunk')) {
                return; // Overflow files don't affect cachedFiles maps
            }

            // Deal with previously cached file

            const state = toInt(file.name[0], 0);
            let id: string;
            let fileType = '';
            if (state === 2) {
                id = file.name.substring(2, 22);
                fileType = file.name[1];
            } else {
                id = file.name.substring(1, 21);
            }

            if (state === 2) {
                // Check if this replaces any lesser states
                if (cachedFiles[id]) {
                    deleteFile(cachedFiles[id]);
                }
                cachedFiles[`${fileType}${id}`] = file.name;
                countCachedFiles++;
            } else {
                if (
                    cachedFiles[`F${id}`] ||
                    cachedFiles[`T${id}`] ||
                    cachedFiles[`S${id}`]
                ) {
                    // This is replaced by state=2 version
                    deleteFile(file.name);
                } else if (cachedFiles[id]) {
                    const existingState = toInt(cachedFiles[id][0], 0);
                    if (state > existingState) {
                        // This replaces state=0
                        deleteFile(cachedFiles[id]);
                        cachedFiles[id] = file.name;
                        countCachedFiles++;
                    } else {
                        // This is replaced by state=1
                        deleteFile(file.name);
                    }
                } else { // not in cachedFiles yet
                    cachedFiles[id] = file.name;
                    countCachedFiles++;
                    if (state === 0) { // waiting to be uploaded
                        if (isPlatform('hybrid')) { // Only hybrid apps upload while offline
                            filesToUpload.push(file.name);
                            totalFilesUploading++;
                        } else {
                            // Shouldn't be files waiting to be uploaded
                            deleteFile(file.name);
                        }
                    }
                }
            }
        });

        console.log('filesToUpload', filesToUpload);

        isFileCacheReady = true;
        logDebug(`Init fileSync took ${(Date.now() - initStarted)}ms. ${countCachedFiles} cached files. ${countFilesToCache} files to be cached.`);
        //filesTimer.mark('initFileSync init done');

        if (!isPlatform('hybrid')) {
            filesToRegister.files.forEach((files) => {
                registerFiles(files);
            });
            filesToRegister.signatures.forEach((signature) => {
                registerSignature(signature);
            });
            filesToRegister.richText.forEach((sfdoc) => {
                registerRichText(sfdoc);
            });
            filesToRegister = { // Cleanup
                files: [],
                signatures: [],
                richText: []
            };
        }

        processFilesToCache();
        processFilesToDelete();
        //filesTimer.mark('initFileSync processFilesToDelete done');
        //tmpLog = filesTimer.render();
        return Promise.resolve();
    });
};











































// function arrayBufferToBase64(arrayBuffer: any) {
//     var base64    = '';
//     var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  
//     var bytes         = new Uint8Array(arrayBuffer);
//     var byteLength    = bytes.byteLength;
//     var byteRemainder = byteLength % 3;
//     var mainLength    = byteLength - byteRemainder;
  
//     var a, b, c, d;
//     var chunk;
  
//     // Main loop deals with bytes in chunks of 3
//     for (var i = 0; i < mainLength; i = i + 3) {
//         // Combine the three bytes into a single integer
//         chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
    
//         // Use bitmasks to extract 6-bit segments from the triplet
//         a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
//         b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
//         c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
//         d = chunk & 63;               // 63       = 2^6 - 1
    
//         // Convert the raw binary segments to the appropriate ASCII encoding
//         base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
//     }
  
//     // Deal with the remaining bytes and padding
//     if (byteRemainder == 1) {
//         chunk = bytes[mainLength];
    
//         a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
    
//         // Set the 4 least significant bits to zero
//         b = (chunk & 3)   << 4; // 3   = 2^2 - 1
    
//         base64 += encodings[a] + encodings[b] + '==';
//     } else if (byteRemainder == 2) {
//         chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
    
//         a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
//         b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4
    
//         // Set the 2 least significant bits to zero
//         c = (chunk & 15)    <<  2; // 15    = 2^4 - 1
    
//         base64 += encodings[a] + encodings[b] + encodings[c] + '=';
//     }
    
//     return base64;
// }
