import React, { useEffect, useState, useMemo } from 'react';
import { formatValue, warnDays, whenDueToClassName, formatInterval, formatDate, makeDateTime, formatSeaDate, getDayOffsetMillis } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { renderCategoryName } from '../../../../lib/categories';
import { useSafetyChecksCompletedDuringVoyage } from '../../../../shared-state/VesselLogbook/safetyChecksCompletedDuringVoyage';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import ViewSafetyCheck from '../../../VesselSafety/SafetyEquipmentChecks/ViewSafetyCheck/ViewSafetyCheck';
import './VoyageSafetyChecksTab.css';
import { Voyage } from '../../../../shared-state/VesselLogbook/voyages';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';

interface VoyageSafetyChecksTabProps {
    showModal: boolean,
    selectedVoyage: Voyage,
    setSafetyChecksNumber: (value: number) => void
}

const VoyageSafetyChecksTab: React.FC<VoyageSafetyChecksTabProps> = ({
    showModal,
    selectedVoyage,
    setSafetyChecksNumber
}) => {
    const safetyCheckItems = sharedState.safetyCheckItems.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit } = usePageLimiter();
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const safetyChecksCompletedDuringVoyage = useSafetyChecksCompletedDuringVoyage(showModal ? selectedVoyage : undefined);
    const [selectedItem, setSelectedItem] = useState<SafetyCheckItem>();

    const voyageSafetyChecks = useMemo(() => {
        if (safetyCheckItems?.prioritised && safetyChecksCompletedDuringVoyage !== undefined) {
            const checks: SafetyCheckItem[] = [];
            const hasItem: {
                [safetyCheckItemId: string]: boolean
            } = {};
            const maxWhenDue = getDayOffsetMillis(warnDays.safetyEquipmentChecks[0]);
            for (let i = 0; i < safetyCheckItems.prioritised.length; i++) {
                const item = safetyCheckItems.prioritised[i];
                if (item.whenDue > maxWhenDue && !item.hasFault) {
                    break;
                }
                hasItem[item.id] = true;
                checks.push(item);
            }
            safetyChecksCompletedDuringVoyage.forEach((item) => {
                if (hasItem[item.id] === undefined) {
                    checks.push(item);
                }
            });
            checks.sort((a, b) => {
                return (a.whenDue - (a.hasFault ? 1000000000000 : 0)) - (b.whenDue - (b.hasFault ? 1000000000000 : 0));
            });
            return checks;
        }
        return undefined;
    }, [safetyCheckItems, safetyChecksCompletedDuringVoyage]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && safetyCheckItems?.byId[selectedItem.id]) {
            setSelectedItem(safetyCheckItems.byId[selectedItem.id])
        };
    }, [safetyCheckItems, selectedItem?.id]);

    // Determine safetyChecksNumber for parent tab button
    useEffect(() => {
        setSafetyChecksNumber(0);
        if (showModal && voyageSafetyChecks && voyageSafetyChecks.length > 0) {
            const today = makeDateTime(formatSeaDate()); //DateTime.fromISO(formatSeaDate());
            let failCount = 0;
            voyageSafetyChecks.forEach((item) => {
                if (item.hasFault) {
                    failCount++;
                } else {
                    const due = makeDateTime(item.whenDue);
                    const days = due.diff(today, ['days']).days;
                    if (days < 0) {
                        failCount++;
                    }
                }
            });
            setSafetyChecksNumber(failCount);
        }
    }, [voyageSafetyChecks, showModal, setSafetyChecksNumber]);

    const onViewSafetyCheck = (item: SafetyCheckItem) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    return (<>
        <SeaNoData
            dataName="forms or documents"
            isLoading={!voyageSafetyChecks}
            hasNoData={voyageSafetyChecks && voyageSafetyChecks.length === 0}
            isUsingFilter={false}
        />
        <div className={`voyage-safety-checks has-thumbs ${voyageSafetyChecks && voyageSafetyChecks.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Safety Item</div>
                <div>Location</div>
                <div>Interval</div>
                <div>Next Check</div>
                <div>Status</div>
            </div>
            {voyageSafetyChecks && mapArrayWithLimit(voyageSafetyChecks, (item : SafetyCheckItem) => {
                return (
                    <div key={item.id} className={`sea-card sea-row-history clickable ${item.hasFault ? 'fail fault' : whenDueToClassName(item.whenDue, warnDays.safetyEquipmentChecks[0])}`} onClick={(e) => onViewSafetyCheck(item)}>
                        <div>
                            <SeaFileImage files={item.files} size="tiny"/>
                        </div>
                        <div className="bold">{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                        <div>{formatValue(renderCategoryName(item.locationId, vesselLocations))}</div>
                        <div>{formatInterval(item.interval)}</div>
                        <div>{formatDate(item.whenDue)}</div>
                        <div><SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault}/></div>
                    </div>
                );
            })}
            {limitTriggerElement}
        </div>

        {showModal &&
            <ViewSafetyCheck
                showModal={showViewItemModal}
                setShowModal={setShowViewItemModal}
                selectedItem={selectedItem}
                level={2}
            />
        }
    </>);
};

export default VoyageSafetyChecksTab;
