import { SharedStateConfig, sharedState } from "../shared-state";

//
// Determines if the app's core data is loaded.
// If isCoreDataLoaded is false, the user will see a loading spinner.
// Whether the core data is loaded or not will be determined differently depending on what the login state is: not logged in, normal user logged in, or superAdmin loggedin.
//

export const isCoreDataLoadedConfig: SharedStateConfig<boolean> = {
    isAlwaysActive: true,
    dependencies: [
        'userPending',
        'user',
        'superAdmin',
        'serverInfo',
        'userPermissions',
        'deviceInfo',
        //'licenseeSettings', must have loaded if isDataSyncActive is defined
        'users',
        'vessels',
        'customFormVersions',
        'isDataSyncActive',
        'whenLicenseeTouched',
        'whenVesselTouched'
    ],
    default: false,
    notes: 'Determines if the app\'s core data is loaded.',
    run: (done, set, clear) => {
        done();

        if (sharedState.userPending.current) {
            // Login state is up in the air.
            // Reset to false.
            set(false);
            return;
        }

        if (sharedState.isCoreDataLoaded.current) {
            // We've already determined that core data is loaded. (We'll only need to recalculate when userPending becomes true)
            return;
        }

        // Determine isCoreDataLoaded is now true - depending on login state
        if (sharedState.user.current) {
            // Login state: normal user is logged in
            if (
                sharedState.serverInfo.current &&
                sharedState.userPermissions.current &&
                sharedState.deviceInfo.current &&
                sharedState.users.current &&
                sharedState.vessels.current &&
                sharedState.customFormVersions.current &&
                sharedState.isDataSyncActive.current !== undefined && (
                    sharedState.isDataSyncActive.current === false || (
                        sharedState.whenLicenseeTouched.current &&
                        sharedState.whenVesselTouched.current
                    )
                )
            ) {
                set(true);
            }
        } else if (sharedState.superAdmin.current) {
            // Login state: superAdmin is logged in
            // serverInfo is the only thing we need to wait for
            if (sharedState.serverInfo.current) {
                set(true);
            }
        } else {
            // Login state: not logged in
            set(true); // There is no core data to be loaded when you're not logged in
        }
    }
};
