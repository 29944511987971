import React, { useState } from 'react';
import { regions } from '../../lib/util';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import CompanyPlan from './CompanyPlan/CompanyPlan';
import CustomForms from './CustomForms/CustomForms';
import CompanyDocumentsPage from './CompanyDocumentsPage/CompanyDocumentsPage';
import './CompanyDocuments.css';

const CompanyDocuments: React.FC = () => {
    const licenseeSettings = sharedState.licenseeSettings.use();
    const selectedSectionTab = sharedState.selectedSectionTab.use();
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();

    return (
        <StandardPageLayout
            section="documents"
            scrollEvents={selectedSectionTab === 'Plan'}
            onScroll={onScroll}
        >
            <SeaBreadcrumb>
                <SeaLink to="/documents">Company Document Register</SeaLink>
            </SeaBreadcrumb>

            <SeaTabsGroup id="CompanyDocuments" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                <SeaTab
                    tab="Plan"
                    requireRole="companyPlan"
                    setTab={sharedState.selectedSectionTab.set}
                >
                    {(licenseeSettings && regions[licenseeSettings?.region]?.companyPlanShort) ?
                        regions[licenseeSettings.region].companyPlanShort :
                        '...'
                    }
                </SeaTab>
                <SeaTab
                    tab="CompanyDocuments"
                    requireRole="companyDocuments"
                    setTab={sharedState.selectedSectionTab.set}
                >
                    Company Documents
                </SeaTab>
                <SeaTab
                    tab="Forms"
                    requireRole="customForms"
                    setTab={sharedState.selectedSectionTab.set}
                >
                    Forms/Checklists
                </SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="Plan" selectedTab={selectedSectionTab}>
                <CompanyPlan
                    visible={selectedSectionTab === 'Plan'}
                    setOnScroll={setOnScroll}
                />
            </SeaTabContent>
            <SeaTabContent tab="CompanyDocuments" selectedTab={selectedSectionTab}>
                <CompanyDocumentsPage
                    visible={selectedSectionTab === 'CompanyDocuments'}
                />
            </SeaTabContent>
            <SeaTabContent tab="Forms" selectedTab={selectedSectionTab}>
                <CustomForms visible={selectedSectionTab === 'Forms'}/>
            </SeaTabContent>

        </StandardPageLayout>
    );
};

export default CompanyDocuments;
