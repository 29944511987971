import { IonSpinner } from '@ionic/react';
import { getBasedOnBackgroundColour, hashStringToNumber } from './util';
import { ModalSpace } from '../layouts/AppLayout/AppLayout';

export interface GraphConfig {
    colourMode: "dark" | "light" | undefined;
    width: number;
    height: number;
    divStyle: object;
    renderColour: (index: number, colourMap?: Record<number, string>) => string;
    renderTextColour: (index: number, colourMap?: Record<number, string>) => string;
    padding: number;
    showHeader: boolean;
    headerHeight: number;
    labelColour: string;
    lineColour: string;
    axisLabelColour: string;
}

interface AxisCalculationResult {
    range: number;
    spacing: number;
    n: number;
    values: number[];
}

export type ColourMap = Record<number, string>;

export interface ReportingConfig {
    cornerRadius: number;
    titleSize: number;
    graphPadding: {
        top: number;
        bottom: number;
    };
    xAxisLabelSpace: number;
    labelBox: {
        w: number;
        h: number;
        textGap: number;
        spacing: number;
        gap: number;
    };
    pdf: {
        label: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
        };
        xValue: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            textAnchor: string;
            //opacity: number;
        };
        xAxisLabel: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            textAnchor: string;
        };
        barValue: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            textAnchor: string;
        };
    };
    graphSpinner: JSX.Element;
    colours: {
        taskStatuses: string[];
        faultableTaskStatuses: string[];
        incidentStatuses: string[];
        jobPriorities: string[];
        jobPrioritiesBg: string[];
        completedMaintenanceTasks: string[];
        default: string[];
    };
    getConfig: (mode: 'dashboard' | 'modal' | 'pdf', modalSpace: ModalSpace | undefined, colourPallete?: string[]) => GraphConfig;
    calculateAxis: (maxValue?: number, allowFractions?: boolean, maxTicks?: number) => AxisCalculationResult;
    allowBarValue: (value: number, range: number) => boolean;
    makeColourMapUsingIndexes: (data: any[], colourPalette: string[]) => ColourMap;
    makeColourMapUsingNames: (data: any[], colourPalette: string[]) => ColourMap;

}

const reporting = {
    cornerRadius: 25,
    titleSize: 18,
    graphPadding: {
        top: 24,
        bottom: 18
    },
    xAxisLabelSpace: 44,
    labelBox: {
        w: 35,
        h: 20,
        textGap: 8,
        spacing: 12, // spacing between label boxes vertically
        gap: 12, // gap between labels horizontally
    },
    pdf: {
        label: {
            fontFamily: 'Montserrat',
            fontSize: '15',
            fontWeight: 400
        },
        xValue: {
            fontFamily: 'Montserrat',
            fontSize: '12',
            fontWeight: 400,
            textAnchor: 'middle',
            //opacity: 0.8
        },
        xAxisLabel: {
            fontFamily: 'Montserrat',
            fontSize: '15',
            fontWeight: 400,
            textAnchor: 'middle'
        },
        barValue: {
            fontFamily: 'Montserrat',
            fontSize: '13',
            fontWeight: 500,
            textAnchor: 'end'
        }
    },
    graphSpinner: (
        <div className="sea-graph-spinner">
            <IonSpinner name="crescent" className="sea-spinner" />
        </div>
    )
} as ReportingConfig;

export const colours = {
    // status colours
    draftStatus:    'b6ddea', //'cef2fe',
    warnStatus:     'f8ce51', //'ffc107',
    warnLowStatus:  'eeee99', //'ffc107',
    dangerStatus:   'eb6072', //'eb445a',
    faultedStatus:  'D23232', //'eb445a',
    draftStatusBg:    'f2f9ff', //'cef2fe',
    warnStatusBg:     'ffe2b9', //'ffc107',
    warnLowStatusBg:  'ffffe1', //'ffc107',
    dangerStatusBg:   'f5d7d7', //'eb445a',
    faultedStatusBg:  'eb445a', //'eb445a',
    // general pallete
    darkBlue:       '0369D8',
    skyBlue:        '64B5F5',
    lightOrange:    'FEB74D',
    lilac:          'C4B6FD',
    mint:           '80C784',
    slateBlue:      '7C6EE0',
    mulberry:       'C14F97',
    salmonPink:     'FF8D9D',
    beige:          'DEB782',
    appleGreen:     '6EB541',
    steelBlue:      '408BA6',
    brown:          'A56622',
    orange:         'FF9C24',
    lavender:       'C67CE8',
    orangeSoda:     'F17455',
    avocado:        '7C9433',
    brickRed:       'A25766',
    pewterBlue:     '8FBDBC',
    red:            'D85555',
    purple:         '895BCD',
    lemon:          'C7C005',
    oceanGreen:     '358874',
    oceanSpray:     '53CAA5',
    gray:           '808080',
    hotPink:        'FF66C7',
    menthol:        'CFFF9E',
    darkTeal:       '3D666B',
    mudBrown:       '8B6E55'
};
reporting.colours = {
    taskStatuses: [
        colours.dangerStatus,
        colours.warnStatus
    ],
    faultableTaskStatuses: [
        colours.faultedStatus,
        colours.dangerStatus,
        colours.warnStatus
    ],
    incidentStatuses: [
        colours.draftStatus,
        colours.dangerStatus,
        colours.warnStatus
    ],
    jobPriorities: [
        colours.faultedStatus, // fault
        colours.dangerStatus, // high
        colours.warnStatus, // medium
        colours.warnLowStatus, // low
        colours.draftStatus, // shipyard
    ],
    jobPrioritiesBg: [
        colours.faultedStatusBg, // fault
        colours.dangerStatusBg, // high
        colours.warnStatusBg, // medium
        colours.warnLowStatusBg, // low
        colours.draftStatusBg, // shipyard
    ],
    completedMaintenanceTasks: [
        colours.mint,
        colours.menthol
    ],
    default: [
        colours.darkBlue,
        colours.skyBlue,
        colours.lightOrange,
        colours.lilac,
        colours.mint,
        colours.slateBlue,
        colours.mulberry,
        colours.salmonPink,
        colours.beige,
        colours.appleGreen,
        colours.steelBlue,
        colours.brown,
        colours.orange,
        colours.lavender,
        colours.orangeSoda,
        colours.avocado,
        colours.brickRed,
        colours.pewterBlue,
        colours.red,
        colours.purple,
        colours.lemon,
        colours.oceanGreen,
        colours.oceanSpray,
        colours.gray,
        colours.hotPink,
        colours.menthol,
        colours.darkTeal,
        colours.mudBrown
    ]
};

reporting.getConfig = (mode: 'dashboard' | 'modal' | 'pdf', modalSpace: ModalSpace | undefined, colourPallete?: ColourMap) => {
    const colourMode = (mode === 'dashboard') ? 'dark' : 'light';
    if (!colourPallete) {
        colourPallete = reporting.colours.default;
    }
    let colourMapObject: ColourMap = {};
    if (Array.isArray(colourPallete)) {
        colourPallete.forEach((colour, index) => {
            colourMapObject[index] = colour;
        });
        colourPallete = colourMapObject;
    }
    let graph = {
        colourMode,
        width: 600,
        height: 450,
        divStyle: {},
        renderColour: (index: number, colourMap: ColourMap) => {
            if (colourMap) {
                return '#'+colourMap[index];
            }
            return '#'+colourMapObject[index % Object.keys(colourMapObject).length];
        },
        renderTextColour: (index: number, colourMap: ColourMap) => {
            if (colourMap) {
                return getBasedOnBackgroundColour(colourMap[index]);   
            }
            return getBasedOnBackgroundColour(colourMapObject[index % Object.keys(colourMapObject).length]);
        },
        padding: 0,
        showHeader: false,
        headerHeight: 0
    } as GraphConfig;

    if (mode === 'modal') {
        graph.width = Math.min(920 - (2 * 32), modalSpace?.innerWidth ?? 0);
        graph.width = Math.max(500, graph.width);
        graph.height = 400;
        if (graph.width <= 650) {
            graph.height = Math.floor((400/650) * graph.width);
        }
        if (graph.width === 500) {
            graph.divStyle = {
                maxWidth: '100%'
                //maxHeight: `${height}px`,
            };
        } else {
            graph.divStyle = {
                width: '100%',
                //height: `${graph.height}px`,
            };
        }
        graph.padding = 10;
    } else if (mode === 'pdf') {
        graph.width = 850;
        graph.height = 360;
        graph.padding = 0;
    } else { // mode = dashboard
        graph.divStyle = {
            maxWidth: `${graph.width}px`,
            //maxHeight: `${height}px`,
        };
        graph.padding = 32;
        graph.showHeader = true;
        graph.headerHeight = (graph.padding + reporting.titleSize);
    }
    graph.labelColour = (graph.colourMode === 'dark') ? '#ffffff' : '#666666';
    graph.lineColour = (graph.colourMode === 'dark') ? '#7f818c' : '#CCCCCC';
    graph.axisLabelColour = (graph.colourMode === 'dark') ? '#eeeeee' : '#777777';
    return graph;
};

reporting.calculateAxis = (maxValue = 10, allowFractions = false, maxTicks = 10) => {
    // Get a nice number
    // see: https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks/16363437#16363437
    // const niceNum = (range: number, round: boolean) => {
    //     let exponent; // exponent of range
    //     let fraction; // fractional part of range
    //     let niceFraction; // nice, rounded fraction 
    //     exponent = Math.floor(Math.log10(range));
    //     fraction = range / Math.pow(10, exponent);
    //     if (round) {
    //         if (fraction < 1.5) {
    //             niceFraction = 1;
    //         } else if (fraction < 3) {
    //             niceFraction = 2;
    //         } else if (fraction < 5) {
    //             niceFraction = 2.5;
    //         } else if (fraction < 7) {
    //             niceFraction = 5;
    //         } else {
    //             niceFraction = 10;
    //         }
    //     } else {
    //         if (fraction <= 1) {
    //             niceFraction = 1;
    //         } else if (fraction <= 2) {
    //             niceFraction = 2;
    //         } else if (fraction <= 2.5) {
    //             niceFraction = 2.5;
    //         } else if (fraction <= 5) {
    //             niceFraction = 5;
    //         } else {
    //             niceFraction = 10;
    //         }
    //     }
    //     return niceFraction * Math.pow(10, exponent);
    // }
    // let range = niceNum(maxValue, false);
    // let spacing = niceNum(range / (maxTicks - 1), true);

    // const niceNum = (range: number) => {
    //     let exponent; // exponent of range
    //     let fraction; // fractional part of range
    //     let niceFraction; // nice, rounded fraction 
    //     exponent = Math.floor(Math.log10(range));
    //     fraction = range / Math.pow(10, exponent);
    //     if (fraction < 1.5) {
    //         niceFraction = 1;
    //     } else if (fraction < 4) {
    //         niceFraction = 2;
    //     } else if (fraction < 5) {
    //         niceFraction = 2.5;
    //     } else if (fraction < 7) {
    //         niceFraction = 5;
    //     } else {
    //         niceFraction = 10;
    //     }
    //     return niceFraction * Math.pow(10, exponent);
    // }
    // let spacing = niceNum(maxValue / (maxTicks - 1));
    // let range = Math.floor(maxValue / spacing) * spacing;
    // if (range < maxValue) {
    //     range += spacing;
    // }

    //let spacing = niceNum(range / (maxTicks - 1), true);
    //let range = niceNum(maxValue, false);

    // const niceNum = (x: number, round: boolean) => {
    //     const nice = [1,2,2.5,5];
    //     let factor;
    //     if (round) {
    //         factor = Math.log10
    //     } else {
    //         factor =
    //     }
    //     return factor;
    // }

    //let exponent = Math.floor(Math.log10(maxValue)) - 1;
    //let fraction = maxValue / Math.pow(10, exponent);
    const spacings = [1, 2, 2.5, 5];
    let spacing = 0;
    let range = 0;
    let n = 0;
    for (let e = -1; e <= 0; e++) {
        const exponent = Math.floor(Math.log10(maxValue)) + e;
        for (let i = 0; i < spacings.length; i++) {
            spacing = spacings[i] * Math.pow(10, exponent);
            if (!allowFractions) {
                spacing = Math.max(1, spacing);
            }
            range = Math.ceil(maxValue / spacing) * spacing;
            n = range / spacing;
            if (n <= maxTicks) {
                break;
            }
        }
        if (n <= maxTicks) {
            break;
        }
    }
    if (n <= 5) {
        n = 5;
    } else {
        n = 10;
    }
    if (spacing === 2.5 && n === 10) { // hack to stop 2.5 5 7.5 10 etc
        spacing = 5;
        n = 5;
    }
    range = n * spacing;

    const values = [] as number[];
    for (let i = 0; i <= n; i++) {
        values[i] = i * spacing;
    }

    return {
        range,
        spacing,
        n,
        values
    };

    // return {
    //     range: exponent,
    //     spacing: fraction,
    //     n: 0 //Math.ceil(range / spacing)
    // };
};

reporting.allowBarValue = (value: number, range: number) => {
    return (value / range) >= (0.03 * (''+value).length);
};

// Functions to generate colour maps
// { <index>: <colour>, ... }
const generateColour = (colour: string, group: number) => {
    if (group === 0) {
        return colour;
    }
    // Make random colour
    let seed = group + hashStringToNumber(colour);
    const random = () => {
        var x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }
    let c = '';
    const hex = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
        c += hex[Math.floor(random() * 14) + 2];
    }
    return c;
}
reporting.makeColourMapUsingIndexes = (data: any[], colourPalette: string[]) => {
    const numColours = colourPalette.length;
    const map = {} as {
        [index: number]: string
    }
    for (let i = 0; i < data.length; i++) {
        map[i] = colourPalette[ i % numColours ];
        if (i >= numColours && numColours > 1) {
            map[i] = generateColour(map[i], Math.floor(i / numColours));
        }
    }
    return map;
};
reporting.makeColourMapUsingNames = (data: any[], colourPalette: string[]) => {
    const n = data.length;
    const numColours = colourPalette.length;
    const map = {} as {
        [index: number]: string
    };
    let iStart = 0;
    let group = 0;
    while (iStart < n) {
        const indexUsed = {} as {
            [index: number]: boolean
        };
        const iEnd = Math.min(n, iStart + numColours);
        for (let i = iStart; i < iEnd; i++) {
            let index = Math.abs(hashStringToNumber(data[i].name)) % numColours;
            while (indexUsed[index]) {
                index++;
                if (index >= numColours) {
                    index = 0;
                }
            }
            indexUsed[index] = true;
            map[i] = generateColour(colourPalette[index], group);
        }
        iStart += numColours;
        group++;
    }
    return map;
};

export default reporting;
