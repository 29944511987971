import React from 'react';
import { DateTime } from 'luxon';
import { isPlatform } from '@ionic/react';
import { VesselsData } from '../shared-state/Core/vessels';
//Settings.defaultZoneName = 'Pacific/Auckland';

export type DateTimeAcceptable = string | number | DateTime | Date | null;

// Need to keep in sync with /firebase/functions/common/util.js
export const warnDays = {
    crewTraining: [7],
    crewCertificates: [60, 90, 180],
    drills: [7],
    riskRegister: [30],
    healthSafetyMeetings: [5],
    dangerousGoods: [7],
    maintenanceSchedule: [7, 30, 60, 90],
    safetyEquipmentChecks: [7, 30],
    safetyEquipmentExpiries: [60, 90, 180],
    vesselCertificates: [60, 90, 180],
    vesselDocuments: [60],
    companyDocuments: [60],
    companyPlan: [60],
};

// Return the server URL
export const getServerUrl = () => {
    if (isPlatform('hybrid')) {
        return 'https://seaflux.netlify.app'; // production server
    }
    return process.env.REACT_APP_SERVER;
};

// Return a url to a file or image in Firebase Storage
// Now Obsolete. We now only access storage objects via URLs obtained through storage.getDownloadURL (so we can have security rules)
// export const getStorageUrl = (licenseeId: string, fileId: string, ext: string, type = '') => {
//     return `https://firebasestorage.googleapis.com/v0/b/${firebaseSettings.storageBucket}/o/files%2F${fileId}${type}.${ext}?alt=media`; // ('%2F' is '/' encoded)
//     //return `https://storage.cloud.google.com/${firebaseSettings.storageBucket}/files/${fileId}${type}.${ext}?alt=media&authuser=1`; // ('%2F' is '/' encoded)
//     //return `https://storage.cloud.google.com/${firebaseSettings.storageBucket}/files/${fileId}${type}.${ext}?alt=media`; // ('%2F' is '/' encoded)
// };

interface Region {
    name: string;
    greeting: string;
    companyPlan: string;
    companyPlanShort: string;
    vesselReg: string;
    taxReg: string;
}

interface Regions {
    [id: string]: Region;
}

interface TaskStatuses {
    [id: string]: string;
}

// Need to keep in sync with /firebase/functions/common/util.js
export const regions: Regions = {
    nz: {
        name: 'New Zealand',
        greeting: 'Kia Ora',
        companyPlan: 'Maritime Transport Operator Plan',
        companyPlanShort: 'MTOP',
        vesselReg: 'MNZ number',
        taxReg: 'IRD number'
    },
    au: {
        name: 'Australia',
        greeting: 'G\'day',
        companyPlan: 'Safety Management System',
        companyPlanShort: 'Safety Management System',
        vesselReg: 'AMSA number',
        taxReg: 'ABN number'
    },
    uk: {
        name: 'United Kingdom',
        greeting: 'Hello',
        companyPlan: 'Safety Management System',
        companyPlanShort: 'Safety Management System',
        vesselReg: 'MCA number',
        taxReg: 'UTR number'
    },
    default: {
        name: "International (Superyacht)",
        greeting: 'Hello',
        companyPlan: 'Safety Management System',
        companyPlanShort: 'Safety Management System',
        vesselReg: 'MNZ/AMSA/MCA number',
        taxReg: 'Tax ID'
    }
};

export const taskStatuses: TaskStatuses = {
    overdue: 'Overdue',
    upcoming: 'Upcoming'
};

export const faultableTaskStatuses: TaskStatuses = {
    faulted: 'Faulted',
    overdue: 'Overdue',
    upcoming: 'Upcoming'
};

// Get safe insets. See global.css env(safe-area-inset-*)
export const isTouchDevice = (
    ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    ((navigator as any).msMaxTouchPoints > 0)
);

export const getSafeInsetTop = () => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--safe-inset-top"));
};
export const getSafeInsetRight = () => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--safe-inset-right"));
};
export const getSafeInsetBottom = () => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--safe-inset-bottom"));
};
export const getSafeInsetLeft = () => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--safe-inset-left"));
};

const lastTapped = {} as any;
export const preventMultiTap = (id: string, cooldown = 1000) => {
    if (lastTapped[id] === undefined || lastTapped[id] < (Date.now() - cooldown)) {
        // Tap is ok. Record tap for future calls.
        lastTapped[id] = Date.now();
        return false;
    }
    console.log(`multiTap prevented for ${id}!`);
    return true;
}

export const startTimer = (name: string) => {
    const startTime = Date.now();
    const history = [] as any[];
    history.push({ time: startTime, name: 'Started' });
    console.log(`(--${name}--) Timer started`);
    const renderDiff = (millis: number) => {
        let t = millis;
        const mins = Math.floor(t / (60 * 1000));
        t -= mins * (60 * 1000);
        const seconds = Math.floor(t / 1000);
        t -= seconds * 1000;
        let s = '';
        s += mins.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        s += ':'
        s += seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        s += '.';
        s += t.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
        return s;
    };
    const renderCheckpoint = (index?: number) => {
        if (index === undefined) {
            index = history.length - 1;
        }
        let s = `(--${name}--) ${renderDiff(history[index].time - startTime)}`;
        if (history.length > 1 && index > 0) {
            s += ` +${renderDiff(history[index].time - history[index - 1].time)}`;
        }
        s += ` ${history[index].name}`;
        return s;
    };
    const renderAll = () => {
        let s = '';
        for (let i = 0; i < history.length; i++) {
            s += renderCheckpoint(i)+'\n';
            //console.log(renderCheckpoint(i));
        }
        console.log(s);
        return s;
    };
    return {
        mark: (name: string, _renderAll = true) => {
            const checkpoint = { time: Date.now(), name };
            history.push(checkpoint);
            if (_renderAll) {
                renderAll();
            } else {
                console.log(renderCheckpoint());
            }
        },
        render: renderAll
    };
};

export const hashStringToNumber = (s: string) => {
    var hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
      chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

// Make a Luxon DateTime object from either epoch millis or an ISO formatted string
// If undefined or null, will return the current time
export const makeDateTime = (date?: DateTimeAcceptable): DateTime => {
    if (date) {
        if (typeof date === 'number') {
            return DateTime.fromMillis(date);
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date);
        }
        if (date instanceof DateTime) {
            return date;
        }
    }
    return DateTime.now();
};
// Format epoch millis (or ISO date) into the format required by SeaDate component
export const formatSeaDate = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISODate();
};
// Format epoch millis (or ISO date) into the format required by SeaDatetime component
export const formatSeaDatetime = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISO().substring(0, 16); // (don't want anything after minutes)
};
export const formatDatetimeISO = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toISO();
};
// Format epoch millis (or ISO date) into something like 3 Apr 21
export const formatDate = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('dd LLL yy');
};
export const formatDateLonger = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('dd LLLL yyyy');
};
export const formatDatetime = (date?: DateTimeAcceptable, inbetween = '\n') => {
    return makeDateTime(date).toFormat('dd LLL yyyy' + inbetween + 'HH:mm');
};
export const formatDatetimeLonger = (date?: DateTimeAcceptable, inbetween = '\n') => {
    return makeDateTime(date).toFormat('dd LLLL yyyy'+inbetween + 'HH:mm');
};
export const formatDatetimeForPdf = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm, d LLL yyyy');
};
export const formatTime = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm');
};
export const formatTime24Hour = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('HH:mm');
};
export const formatShortTimeDuration = (millis: number) => {
    const min = Math.floor(millis / (60 *1000));
    millis -= min * 60 * 1000;
    const seconds = Math.floor(millis / 1000);
    let s = '';
    if (min < 10)  {
        s += '0'
    };
    s += min;
    s += ':';
    if (seconds < 10) {
        s += '0';
    }
    s += seconds;
    return s;
};
// Format epoch millis (or ISO date) into something like 3 Apr 21
export const formatDateSimplify = (date?: DateTimeAcceptable) => {
    const s = formatDate(date);
    if (s === formatDate()) {
        return 'Today';
    } else if (s === formatDate(addInterval(makeDateTime(), '-1d'))) {
        return 'Yesterday';
    }
    if (makeDateTime(date).toFormat('yy') === makeDateTime().toFormat('yy')) {
        return makeDateTime(date).toFormat('dd LLL');
    }
    return makeDateTime(date).toFormat('dd LLL yy');
};
export const toSafeFilename = (filename: string | undefined) => {
    if (filename) {
        return filename.replace(/[^a-z0-9_\-\.]/gi, '_');
    }
    return '';
};

// Format epoch millis (or ISO date) into something like Apr 2021
export const formatMonth = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('LLL yyyy');
};
export const formatMonthLonger = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('LLLL yyyy');
};

// Format epoch millis (or ISO date) into something like 2021 01
export const formatMonthISO = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('yyyy-MM') + '-01';
};

// Format epoch millis (or ISO date) into something like 2021
export const formatYear = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('yyyy');
};

// Format epoch millis (or ISO date) into something like Mon 21
export const formatDay = (date?: DateTimeAcceptable) => {
    return makeDateTime(date).toFormat('ccc d');
};

//
export const formatDateRange = (from?: DateTimeAcceptable, to?: DateTimeAcceptable) => {
    const fromDate = makeDateTime(from);
    const toDate = makeDateTime(to);
    if (fromDate.year === toDate.year) {
        if (fromDate.month === toDate.month) {
            if (fromDate.day === toDate.day) {
                return `${toDate.toFormat('dd LLL yyyy')}`;
            }
            return `${fromDate.toFormat('dd')} - ${toDate.toFormat('dd LLL yyyy')}`;
        }
        return `${fromDate.toFormat('dd LLL')} - ${toDate.toFormat('dd LLL yyyy')}`;
    }
    return `${fromDate.toFormat('dd LLL yyyy')} - ${toDate.toFormat('dd LLL yyyy')}`;
}

export const renderCamelCase = (camelCase: string) => {
    if (camelCase?.length > 0) {
        let s = '';
        s = camelCase[0].toUpperCase();
        for (let i = 1; i < camelCase.length; i++) {
            if (camelCase[i] === camelCase[i].toUpperCase()) {
                s += ' ';
            }
            s += camelCase[i];
        }
        return s;
    }
    return '';
};

// Convert dates returned from SeaDate or SeaDatetime components into epoch millis that firestore wants
export const toMillis = (date?: string) => {
    if (date) {
        return DateTime.fromISO(date).toMillis();
    }
    return 0;
};

// Convert string to int
export const toInt = (s: any, valueOnFail: any = 0) => {
    if (s) {
        try {
            if (!isNaN(parseInt(''+s))) {
                return parseInt(''+s);
            }
        } catch (e) {}
    }
    return valueOnFail;
};

// Convert string to float
export const toFloat = (s: any, valueOnFail: any = 0) => {
    if (s) {
        try {
            if (!isNaN(parseFloat(''+s))) {
                return parseFloat(''+s);
            }
        } catch (e) {}
    }
    return valueOnFail;
};

export const hours24ToMillis = (hours24: string) => {
    if (hours24) {
        const a = hours24.split(':');
        if (a.length > 1) {
            return (toInt(a[0], 0) * 60 * 60 * 1000) + (toInt(a[1], 0) * 60 * 1000);
        } else if (a.length === 1) {
            return (toInt(a[0], 0) * 60 * 60 * 1000);
        }
    }
    return 0;
};
export const combineDateAndHours24 = (dateMillis: number, hours24: string) => {
    return dateMillis + hours24ToMillis(hours24);
};

// Add an interval to a date. Return epoch millis.
export const addInterval = (date: DateTimeAcceptable, interval?: string): number => {
    if (!interval) {
        return makeDateTime(date).toMillis();
    }
    return makeDateTime(date).plus(convertInterval(interval)).toMillis();
};
export const subtractInterval = (date: DateTimeAcceptable, interval: string): number => {
    return makeDateTime(date).minus(convertInterval(interval)).toMillis();
};
// interval can be anything listed as an option under <SeaSelectInterval> component
export const convertInterval = (interval: string) => {
    const x = toInt(interval.substring(0, interval.length - 1), 0);
    const t = interval.substring(interval.length - 1);
    switch (t) {
        case 'w': return {weeks: x};
        case 'm': return {months: x};
        case 'd': return {days: x};
        case 'y': return {years: x};
    }
    return {};
};
// This should be kept in sync with firebase/functions/common/util.js formatInterval
export const formatInterval = (interval?: string) => {
    if (!interval) {
        return '';
    }
    switch (interval) {
        case '1d': return 'Daily';
        case '7d': return 'Weekly';
        case '14d': return 'Fortnightly';
        case '5w': return '5 Weekly';
        case '1m': return 'Monthly';
        case '2m': return '2 Monthly';
        case '3m': return '3 Monthly';
        case '4m': return '4 Monthly';
        case '5m': return '5 Monthly';
        case '6m': return '6 Monthly';
        case '9m': return '9 Monthly';
        //case '2m': return 'Bimonthly';
        //case '3m': return 'Quarterly';
        //case '6m': return 'Half-Yearly';
        case '12m': return 'Annually';
        case '18m': return '18 Monthly';
        case '24m': return '2 Yearly';
        case '30m': return '2.5 Yearly';
        case '36m': return '3 Yearly';
        case '48m': return '4 Yearly';
        case '60m': return '5 Yearly';
        case '72m': return '6 Yearly';
        case '96m': return '8 Yearly';
        case '120m': return '10 Yearly';
    }
    return '';
};
export const formatEmailReminder = (interval?: string) => {
    if (!interval) {
        return '';
    }
    switch (interval) {
        case '0d': return 'On the day';
        case '1d': return '1 Day before';
        case '2d': return '2 Days before';
        case '3d': return '3 Days before'
        case '7d': return '1 Week before';
        case '14d': return '2 Weeks before';
        case '1m': return '1 Month before';
        case '2m': return '2 Months before';
        case '3m': return '3 Months before';
        case '6m': return '6 Months before';
    }
    return '';
};
// Returns millis for a particular day. Defaults to today.
// For tomorrow you would use offsetDays = 1
export const getDayOffsetMillis = (offsetDays = 0): number => {
    return DateTime.fromISO(makeDateTime().toISODate()).toMillis() + (offsetDays * 24 * 60 * 60 * 1000);
};

export const formatTextAreaText = (s: string | undefined, forAlert = false) => {
    if (s) {
        if (forAlert) {
            return s.replace(/\n/g, '<br>');
        }
        return s.split('\n').map((text, index) => {
            return <React.Fragment key={`${text}_${index}`}>
                {index > 0 && <br/>}
                {text}
            </React.Fragment>;
        });
    }
    return undefined;
};

export const formatTextSingleLine = (s?:string): string => {
    if (s && s.indexOf('\n') !== -1) {
        return s.substring(0, s.indexOf('\n'));
    }
    return s || '';
};

export const formatDp = (x: number, dp: number, avoidIfPossible = false): string => {
    if (dp === 0) {
        return ''+Math.round(x);
    }
    const factor = Math.pow(10, dp);
    let s = ''+(Math.round(x * factor) / factor);
    if (avoidIfPossible) {
        return s;
    }
    if (s.indexOf('.') === -1) {
        s += '.';
    }
    while (s.length - s.indexOf('.') < dp + 1 ) {
        s += '0';
    }
    return s;
}

export const formatCurrency = (x: number, dp?: number) => {
    if (dp === undefined) {
        if (x % 1 === 0) { // Is whole number
            dp = 0;
        } else {
            dp = 2;
        }
    }
    return '$'+formatDp(x, dp);
};

export const generateCode = (numChars: number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < numChars; i++) {
        code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return code;
};

// This is appropriate for formik values where each property is a value (not an object)
// To check multi level objects use haveObjectsChanged
export const haveValuesChanged = (currentValues: any, initialValues: any) => {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(initialValues);
    const bProps = Object.getOwnPropertyNames(currentValues);
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return true;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (initialValues[propName] !== currentValues[propName]) {
            return true;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return false;
};

export const hasArrayChanged = (currentArray: any[] | undefined, initialArray: any[] | undefined) => {
    if (currentArray && currentArray.length > 0) {
        if (initialArray && initialArray.length > 0) {
            // Need to check items
            if (initialArray.length !== currentArray.length) {
                return true;
            }
            for (let i = 0 ; i < initialArray.length; i++) {
                if (typeof initialArray[i] === 'object') {
                    if (haveValuesChanged(initialArray[i], currentArray[i])) {
                        return true;
                    }
                } else if (initialArray[i] !== currentArray[i]) {
                    return true; // mismatch found!
                }
            }
            return false; // array contents match
        } else {
            return true; // one exists, one doesn't
        }
    } else if (initialArray && initialArray.length > 0) {
        return true; // one exists, one doesn't
    }
    return false; // both arrays are undefined or empty
};

export const haveObjectsChanged = (currentObject: any, initialObject: any) => {
    return !areObjectsEqual(currentObject, initialObject);
};
// Recursively check two objects are equivalent to each other
export const areObjectsEqual = (a: any, b: any) => {
    if (typeof a !== typeof b) {
        return false;
    }
    if (a === b) {
        return true;
    }
    if (typeof a === 'object') {
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }
        const keys = Object.keys(a);
        for (let i = 0; i < keys.length; i++) {
            if (b[keys[i]] === undefined) return false;
            if (!areObjectsEqual(a[keys[i]], b[keys[i]])) {
                return false;
            }
        }
        return true;
    }
    return false;
};

export const getObjectWithoutFirestoreFieldValues = (obj: any, indent = 0): any => {
    // let spacing = '';
    // for (let i = 0; i < indent; i++) {
    //     spacing += '  ';
    // }
    if (Array.isArray(obj)) {
        const result = [] as any[];
        obj.forEach((value, index) => {
            // console.log(`${spacing}[${index}]`);
            result[index] = getObjectWithoutFirestoreFieldValues(value, indent + 1);
        });
        return result;
    } else if (typeof obj === 'object') {
        if (obj._delegate && obj._delegate._methodName) {
            // console.log(`${spacing}(Firebase Function Removed)`);
            return undefined;
        }
        const result = {} as any;
        Object.keys(obj).forEach((key: string) => {
            // console.log(`${spacing}${key}`);
            result[key] = getObjectWithoutFirestoreFieldValues(obj[key], indent + 1);
        });
        return result;
    } else if (typeof obj === 'string') {
        // console.log(`${spacing}"${obj}"`);
        return obj;
    } else {
        // console.log(`${spacing}${obj}`);
        return obj;
    }
};

export const jsonToStrings = (obj: any, inbetween = ': ') => {
    const array = [] as string[];
    Object.keys(obj).forEach((key) => {
        array.push(`${key}${inbetween}${obj[key]}`);
    });
    return array;
};

// If a value is empty return '-'
export const formatValue = (value: string | number | undefined | (() => void) | JSX.Element[], replaceEmptyWith?: string) => {
    return ((value || value === 0) ? value : replaceEmptyWith ? replaceEmptyWith : '-') as string;
};

export const formatRiskRating = (rating: string) => {
    switch (rating) {
        case '2': return 'Low';
        case '4': return 'Medium';
        case '6': return 'High';
    }
    return '';
};

export const formatVessels = (vesselIds?: string[] | string, vessels?: VesselsData) => {
    if (vesselIds && vessels?.byId) {
        if (vesselIds === 'any') {
            return '';
        }
        let s = '';
        for (let i = 0; i < vesselIds.length; i++) {
            if (vessels.byId[vesselIds[i]]) {
                if (s.length > 0) {
                    s += ', ';
                }
                s += vessels.byId[vesselIds[i]].name;
            }
        }
        return s;
    }
    return '-';
};

// Given whenDue, return 'fail' or 'warn' or '' depending on relation to today
export const whenDueToClassName = (whenDue: any, warnDays: number) => {
    const due = DateTime.fromISO(formatSeaDate(whenDue)); // Crunch day to match local timezone
    const today = DateTime.fromISO(formatSeaDate());
    const days = due.diff(today, ['days']).days;
    if (days < 0) {
        return 'fail';
    } else if (days < warnDays) {
        return 'warn';
    }
    return '';
};
// Calculates number of days between two epoch dates
export const dayDifferenceBetweenDates = (startDate: any, endDate: any) => {
    const start = DateTime.fromISO(formatSeaDate(startDate));
    const end = DateTime.fromISO(formatSeaDate(endDate));
    const difference: any = end.diff(start, ['days']);
    return difference.values.days + 1
};
export const engineHoursLeftToClassName = (engineHoursLeft: number) => {
    if (engineHoursLeft < 0) {
        return 'fail';
    } else if (engineHoursLeft < 50) {
        return 'warn';
    }
    return '';
};

// Splits an array into an array of arrays where each array within the output array is groupSize in size
export const splitArrayIntoGroups = (array: any[] | undefined, groupSize: number) => {
    const groups: any[][] = [];
    let group: any[] = [];
    groups.push(group);
    if (array) {
        for (let i = 0; i < array.length; i++) {
            if (i % groupSize === 0 && i > 0) {
                group = [];
                groups.push(group);
            }
            group.push(array[i]);
        }
    }
    return groups;
};

// Trim strings. Remove blanks. Remove duplicates.
export const cleanupStringArray = (array: string[] | undefined) => {
    if (array === undefined) return undefined;
    const _array = [] as string[];
    array.forEach((value: string) => {
        if (value !== undefined) {
            const trimmed = value.trim();
            if (trimmed.length > 0 && _array.indexOf(trimmed) === -1) {
                _array.push(trimmed);
            }
        }
    });
    return _array;
};

// 
export const extractSearchTerms = (searchText: string, toLowerCase: boolean): string[] => {
    if (searchText && searchText.length > 0) {
        const terms = searchText.split(' ');
        for (let i = terms.length - 1; i >= 0; i--) {
            terms[i].trim();
            if (toLowerCase) {
                terms[i] = terms[i].toLowerCase();
            }
            terms[i] =terms[i].trim();
            if (terms[i].length === 0) {
                terms.splice(i, 1); // Remove from terms
            }
        }
        return terms;
    }
    return [];
};

// For fading in an image once loaded
export const revealImage = (e: any) => {
    e.target.style.opacity = 1;
};

// Will animate fading in an HTML element i.e. "pulsing" it
export const pulseElement = (element: Element, delay = 1) => {
    element.classList.remove('pulse-end');
    element.classList.add('pulse-start');
    setTimeout(() => {
        element.classList.remove('pulse-start');
        element.classList.add('pulse-end');
    }, delay);
}
export const pulseElementById = (id: string, delay = 1) => {
    const element = document.getElementById(id);
    if (element) {
        pulseElement(element, delay);
    }
};
export const pulseElementsByClassName = (className: string, delay = 1) => {
    const elements = document.getElementsByClassName(className);
    if (elements?.length) {
        for (let i = 0; i < elements.length; i++) {
            pulseElement(elements[i], delay);
        }
    }
};

// Deep clone/copy an object
// Works if you do not use Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types within your object
export const deepClone = (object: any) => {
    return JSON.parse(JSON.stringify(object));
};

// 
export const convertBlobToBase64 = (blob: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const convertBase64toBlob = (base64: string, contentType='', sliceSize=512) => {
    // Source: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const byteCharacters = atob(base64);
    const byteArrays: Uint8Array[] = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

// Makes a function that helps fix autofill not being registered correctly in all browsers.
// It does this by piggy-backing off blur event and getting the field value at that time.
// export const onBlurWithAutofillFix = (
//     name: string,
//     setFieldValue: (id: string, value: string) => void,
//     handleBlur: (e: CustomEvent) => void
// ) => {
//     return (e: CustomEvent) => {
//         if (e?.detail?.target?.value) {
//             setFieldValue(name, e.detail.target.value);
//         }
//         handleBlur(e);
//     };
// };


const mimeTypes = {
    '3g2': 'video/3gpp2',
    '3gp': 'video/3gpp',
    '7z': 'application/x-7z-compressed',
    aac: 'audio/aac',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    epub: 'application/epub+zip',
    gif: 'image/gif',
    gz: 'application/gzip',
    // heic: 'image/heic', // These currently dont convert due to firebase imagemagick being v6 (my guess anyway)
    // heif: 'image/heif',
    htm: 'text/html',
    html: 'text/html',
    jar: 'application/java-archive',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'text/javascript',
    json: 'application/json',
    jsonld: 'application/ld+json',
    mid: 'audio/midi',
    midi: 'audio/midi',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpeg: 'video/mpeg',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    opus: 'audio/opus',
    pdf: 'application/pdf',
    png: 'image/png',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar: 'application/vnd.rar',
    rtf: 'application/rtf',
    svg: 'image/svg+xml',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ts: 'video/mp2t',
    txt: 'text/plain',
    vsd: 'application/vnd.visio',
    wav: 'audio/wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    xhtml: 'application/xhtml+xml',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'text/xml',
    zip: 'application/zip',
} as any;

export const getContentType = (ext: string) => {
    if (mimeTypes[ext.toLowerCase()]) {
        return mimeTypes[ext.toLowerCase()];
    }
    //return undefined;
    return 'application/octet-stream';
};
export const isImage = (ext: string) => {
    if (getContentType(ext)?.startsWith('image/')) {
        return true;
    }
    return false;
};

export const isPdf = (ext: string | undefined) => {
    if (!ext) {
        return false;
    }
    if (getContentType(ext)?.startsWith('application/pdf') || ext.toLowerCase().endsWith('.pdf') || ext.toLowerCase() === 'pdf') {
        return true;
    }
    if (ext.endsWith('.pdf')) {
        return true;
    }
    return false;
}
export const stripBase64Prefix = (content: string) => {
    if (content.startsWith('data:')) {
        return content.substring(content.indexOf(', ') + 2);
    }
    return content;
};

export const easeQuadInOut = (t: number) => {
    if (t <= 0.5) return 2.0 * t * t;
    t -= 0.5;
    return 2.0 * t * (1.0 - t) + 0.5;
};

export const waitUntil = (testFunction: () => any, pollInterval = 100, maxWait = 60 * 1000) => {
    return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const interval = setInterval(() => {
            const result = testFunction();
            if (result) {
                clearInterval(interval);
                resolve(result);
            } else if (Date.now() - startTime >= maxWait) {
                clearInterval(interval);
                reject('waitUntil timed out');
            }
        }, pollInterval);
    });
};


// See: https://www.nzism.gcsb.govt.nz/ism-document/#1858
// a minimum password length of 16 characters with no complexity requirement; or
// a minimum password length of ten characters, consisting of at least three of the following character sets:
//   lowercase characters (a-z)
//   uppercase characters (A-Z)
//   digits (0-9)
//   punctuation and special characters.
//
// NOTE: This should be kept consistent with functions/users-and-security.js > isPasswordOk
//
export const passwordRequirements = (
    <div style={{
        textAlign: 'left'
    }}>
        Your password must be either:
        <ul style={{
            marginBlockStart: '4px',
            marginBlockEnd: '4px'
        }}>
            <li>At least 16 characters long</li>
            <li>Or, at least 10 characters long and contain at least one lowercase, uppercase, and digit or special character</li>
        </ul>
    </div>
);
export const isPasswordOk = (password: string) => {
    if (password.length >= 16) {
        return true;
    }
    if (password.length < 10) {
        return false;
    }
    let hasUppercase = 0;
    let hasLowercase = 0;
    let hasDigit = 0;
    let hasSpecial = 0;
    for (let i = 0; i < password.length; i++) {
        const c = password[i];
        if (c >= 'a' && c <= 'z') {
            hasLowercase = 1;
        } else if (c >= 'A' && c <= 'Z') {
            hasUppercase = 1;
        } else if (c >= '0' && c <= '9') {
            hasDigit = 1;
        } else {
            hasSpecial = 1;
        }
    }
    return (
        (hasLowercase + hasUppercase + hasDigit + hasSpecial) >= 3
    );
};


// Possible maximum session times in seconds
const daySeconds = 24 * 60 * 60;
const weekSeconds = 7 * daySeconds;
const yearSeconds = 365 * daySeconds;
const monthSeconds = yearSeconds / 12;
export const maxSessionOptions = [
    {
        value: (0).toString(),
        description: 'Unlimited'
    // },{
    //     value: (2 * 60).toString(),
    //     description: '2 minutes (testing)'
    },{
        value: (1 * weekSeconds).toString(),
        description: '1 week'
    },{
        value: (2 * weekSeconds).toString(),
        description: '2 weeks'
    },{
        value: (3 * weekSeconds).toString(),
        description: '3 weeks'
    },{
        value: (1 * monthSeconds).toString(),
        description: '1 month'
    },{
        value: (6 * weekSeconds).toString(),
        description: '6 weeks'
    },{
        value: (2 * monthSeconds).toString(),
        description: '2 months'
    },{
        value: (3 * monthSeconds).toString(),
        description: '3 months'
    },{
        value: (4 * monthSeconds).toString(),
        description: '4 months'
    },{
        value: (6 * monthSeconds).toString(),
        description: '6 months'
    }
];

// Return dark or light font colour depending on how dark rgbString is
export const getBasedOnBackgroundColour = (rgbString: string, darkValue: any = 'var(--text-on-white)', lightValue: any = '#f5f5f5') => { // input format (hex): RRGGBB
    const r = parseInt(rgbString.substring(0, 2), 16) / 255;
    const g = parseInt(rgbString.substring(2, 4), 16) / 255;
    const b = parseInt(rgbString.substring(4, 6), 16) / 255;
    // const luminosity = (0.3 * r) + (0.59 * g) + (0.11 * b); // see: https://www.baeldung.com/cs/convert-rgb-to-grayscale (luminosity method)
    // // console.log(`>>> rgbString=${rgbString} r=${r} g=${g} b=${b} luminosity=${luminosity}`);
    // if (luminosity < 0.5) {
    //     return lightValue;
    // }
    const luminosity = (0.2126 * r) + (0.7152 * g) + (0.0722 * b); // see: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
    if (luminosity <= 0.5) {
        return lightValue;
    }
    return darkValue;
};

export const formatList = (items: string[] | undefined, join = ', ', lastJoin = ' and ', missingValue = '') => {
    if (items?.length) {
        let s = items[0];
        for (let i = 1; i < items.length; i++) {
            if ( i >= items.length - 1) {
                s += lastJoin;
            } else {
                s += join;
            }
            s += items[i];
        }
        return s;
    }
    return missingValue;
}

export const formatSparePartsList = (sparePartsStock: { [key: string]: { added?: number, used?: number}}, spareParts?: any[]) => {
    const arr = [] as any[];
    if (!spareParts) {
        return '';
    }
    spareParts.forEach((part) => {
        const quantity = toFloat(sparePartsStock[part.id]?.used || 0);
        if (quantity > 0) {
            arr.push(`${quantity} x ${part.item}`);
        };
    })
    return arr.join(', ');
};

// Searches through JSX and returns all strings concatenated together.
// If a string is parsed in it will be returned.
// If is about to return an empty string, it will return fallback.
export const jsxToText = (jsx: any, fallback = '') => {
    if (jsx) {
        if (typeof jsx === 'string') {
            return jsx ? jsx : fallback;
        }
        let text = '';
        const findText = (object: any) => {
            if (typeof object === 'string') {
                text += ' '+object;
                return;
            }
            if (object?.props?.icon) {
                text += ` [${object.props.icon}]`;
            }
            if (object?.props?.children) {
                if (typeof object.props.children === 'string') {
                    text += ' '+object.props.children; // string found embedded within JSX
                } else {
                    object.props.children.forEach((child: any) => {
                        if (typeof child === 'string') {
                            text += ' '+child;
                        } else {
                            findText(child);
                        }
                    });
                }
            }
        };
        if (Array.isArray(jsx)) {
            jsx.forEach((node) => {
                findText(node);
            });
        } else {
            findText(jsx);
        }
        return text ? text.trim() : fallback;
    }
    return fallback;
};
