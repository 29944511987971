import React, { useEffect, useState } from 'react';
import { formatDate, formatValue, formatEmailReminder, formatInterval, warnDays } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../lib/permissions';
import { WriteBatch } from "firebase/firestore";
import { RichTextState, initialRichTextState, loadSfdoc } from '../../../../lib/richText';
import { SplittableBatch } from '../../../../lib/firebase';
import { SeaScrollable } from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sharedState } from '../../../../shared-state/shared-state';
import { VesselDocument } from '../../../../shared-state/VesselDocuments/vesselDocuments';
import { CategoriesData } from '../../../../lib/categories';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditVesselDocument from '../EditVesselDocument/EditVesselDocument'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaRichText from '../../../../components/lexical/SeaRichText/SeaRichText';

interface ViewVesselDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: VesselDocument,
    vesselDocumentCategories?: CategoriesData
}

const ViewVesselDocument: React.FC<ViewVesselDocumentProps> = ({showModal, setShowModal, selectedItem, vesselDocumentCategories}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();
    const [visible, setVisible] = useState(false);
    const [modalContentRef, setModalContentRef] = useState<React.RefObject<SeaScrollable>>();

    // Handle loading
    useEffect(() => {
        if (selectedItem?.sfdoc) {
            return loadSfdoc(
                selectedItem.sfdoc,
                setRichTextState
                // getDefaultContent,
                // licenseeSettings !== undefined,
            );
        }
    }, [selectedItem?.sfdoc]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    if (!selectedItem) {
        return <></>;
    }
    
    const editButtons = (
        <RequirePermissions 
            role="vesselDocuments"
            level={permissionLevels.EDIT}
        >
            <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Document</SeaButton>
            <RequirePermissions
                role="vesselDocuments"
                level={permissionLevels.FULL}
            >
                <div className="spacer-wide"></div>
                <SeaLinkButton
                    mode="standard-link"
                    onClick={(e) => {
                        deleteIfConfirmed(
                            'vesselDocuments',
                            selectedItem.id,
                            (batch: WriteBatch | SplittableBatch) => {
                                onCollectionUpdated(batch, 'vesselDocuments');
                                setShowModal(false)
                            },
                            'vessel document',
                            selectedItem?.title,
                            vesselId ? [vesselId] : []
                        );
                    }}
                >
                    Delete Document
                </SeaLinkButton>
            </RequirePermissions>
        </RequirePermissions>
    );

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedItem?.title}
                    </span>
                    {selectedItem?.whenExpires &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenExpires}
                            warnDays={warnDays.vesselDocuments[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="rich-text"
            noTitleOverflow={true}
            scrollEvents={true}
            onScroll={onScroll}
            onOpened={() => {
                setVisible(true);
            }}
            onClosed={() => {
                setVisible(false);
            }}
            setModalContentRef={setModalContentRef}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                {selectedItem?.categoryId && vesselDocumentCategories?.byId ? <SeaGridCell label="Category" w="50">
                    {formatValue(vesselDocumentCategories.byId[selectedItem.categoryId].name)}
                </SeaGridCell> : null}
                {selectedItem?.type === 'renewable' && 
                    <>
                        <SeaGridCell label="Renewal Interval" w="50">
                            {formatValue(formatInterval(selectedItem?.interval))}
                        </SeaGridCell>
                        <SeaGridCell label="Expiry" w="50">
                            {formatDate(selectedItem?.whenExpires)}
                        </SeaGridCell>
                        <SeaGridCell label="Notification" w="50">
                            {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                        </SeaGridCell>
                    </>
                }
            </SeaGrid>

            <div className="view-modal-buttons">
                {editButtons}
            </div>

            {showModal && vesselDocumentCategories &&
                <EditVesselDocument
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                    vesselDocumentCategories={vesselDocumentCategories}
                />
            }

            {selectedItem?.sfdoc &&
                <>
                    <div className="end-info-line"></div>
                    <SeaRichText
                        forModal={true}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        modalContentRef={modalContentRef}
                        editButtons={editButtons}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewVesselDocument;
