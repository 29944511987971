import React, { useMemo, useState } from "react";
import { formatSeaDate, subtractInterval, toMillis } from "../../../../lib/util";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useFormik } from "formik";
import { alertMessage } from "../../../../managers/AlertManager/AlertManager";
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import Yup, { notTooOld } from "../../../../lib/yup";
import SeaModal from "../../../../components/SeaModal/SeaModal";
import SeaDate from "../../../../components/SeaDate/SeaDate";
import MaintenanceHistoryPdf from "../../../../pdfs/VesselMaintenance/MaintenanceHistoryPdf";
import SeaButton from "../../../../components/SeaButton/SeaButton";
import "./MaintenanceHistoryDateModal.css";

interface SeaSelectDateModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    maintenanceHistorys?: {
        [key: string]: MaintenanceTaskCompleted[]
    };
    monthCategories?: string[];
}

const SeaSelectDateModal: React.FC<SeaSelectDateModalProps> = ({
    showModal,
    setShowModal,
    maintenanceHistorys,
    monthCategories,
}) => {
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [selectedItems, setSelectedItems] = useState<{
        [key: string]: MaintenanceTaskCompleted[]
    }>();
    const [selectedMonthCategories, setSelectedMonthCategories] = useState<string[]>();

    const defaultValues = useMemo(() => {
        return {
            dateStart: formatSeaDate(subtractInterval(Date.now(), "1m")),
            dateEnd: formatSeaDate(),
        };
    }, []);

    const onOpened = () => {
        resetForm();
        setValues(defaultValues);
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm } = useFormik({
        initialValues: defaultValues,
        validationSchema: Yup.object({
            dateStart: Yup.date().required().min(...notTooOld),
            dateEnd: Yup.date().required().min(...notTooOld),
        }),
        onSubmit: (data) => {
            const whenFrom = toMillis(data.dateStart) as number;
            let whenTo = (toMillis(data.dateEnd) as number) + 24 * 60 * 60 * 1000;
            if (whenFrom >= whenTo) { 
                alertMessage("Date Start must be before Date End!");
                return;
            }
            const items: {
                [key: string]: MaintenanceTaskCompleted[]
            } = {};
            const categories: string[] = [];
            if (monthCategories) {
                for (let i = 0; i < monthCategories?.length; i++) {
                    const month = toMillis(monthCategories[i]);
                    if (!month || (month > whenTo || month < whenFrom)) {
                        continue;
                    }
                    categories.push(monthCategories[i]);
                } 
            }
            if (maintenanceHistorys) {
                for (const [key, value] of Object.entries(maintenanceHistorys)) {
                    const date = toMillis(key)
                    if (!date || (date > whenTo || date < whenFrom)) {
                        continue;
                    }
                    items[key] = value
                }
            }
            if (Object.keys(items).length === 0) {
                alertMessage(
                    "No completed maintenace reports were found in that date range."
                );
                return;
            }
            setSelectedItems(items);
            setSelectedMonthCategories(categories);
            setGeneratingPdf(true);
        },
    });

    return (
        <SeaModal
            title="Generate PDF"
            showModal={showModal}
            setShowModal={setShowModal}
            //isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDate
                                label="Date Start"
                                name="dateStart"
                                value={values.dateStart}
                                onchange={handleChange}
                                zone="white"
                                error={touched.dateStart ? errors.dateStart : ""}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="Date End"
                                name="dateEnd"
                                value={values.dateEnd}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateEnd ? errors.dateEnd : ""}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <div>
                                <SeaButton zone="white" type="submit" size='wide'>Generate PDF</SeaButton>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </form>
            {showModal &&
                <MaintenanceHistoryPdf
                    generatingPdf={generatingPdf}
                    setGeneratingPdf={setGeneratingPdf}
                    selectedItems={selectedItems || {}}
                    selectedMonthCategories={selectedMonthCategories}
                />
            }
        </SeaModal>
    );
};

export default SeaSelectDateModal;
