import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";

//
// Load contacts
// Note: all contacts are always loaded regardless of permission
//

export interface Contact extends CreateableDocument, UpdateableDocument {
    address?: string;
    categoryId: string;
    company: string;
    deletedBy?: string;
    email?: string;
    licenseeId: string;
    name: string;
    notes?: string;
    number?: string;
    state: string;
    vendorNumber?: string;
    whenDeleted?: number;
}

export type ContactsData = {
    all: Contact[], // Active contacts in alhpabetical order
    byId: {
        [contactId: string]: Contact
    },
    byCategoryId: {
        [categoryId: string]: Contact[] // Alphabetical list
    },
    count: number
};

export const contactsConfig: SharedStateConfig<ContactsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.contacts.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId)  {
            return onSnapshot(
                query(
                    collection(firestore, 'contacts'),
                    where('licenseeId', '==', licenseeId),
                    //where('state', '==', 'active'),
                    orderBy('company', 'asc'),
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    done();
                    const contacts = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as Contact;
                    });
                    const all = [] as Contact[];
                    const byId = {} as {
                        [contactId: string]: Contact
                    };
                    const byCategoryId = {} as {
                        [categoryId: string]: Contact[]
                    };
                    contacts.forEach((contact: Contact) => {
                        byId[contact.id] = contact;
                        if (contact.state === 'active') {
                            all.push(contact);
                            if (byCategoryId[contact.categoryId] === undefined) {
                                byCategoryId[contact.categoryId] = [];
                            }
                            byCategoryId[contact.categoryId].push(contact);
                        }
                    });

                    set({
                        all, // active contacts in alphabetical order
                        byId,
                        byCategoryId,
                        count: contacts.length
                    });
                }, (error) => {
                    done();
                    // This should be very rare
                    console.log(`Error getting contacts for licensee ${licenseeId}`, error);
                }
            );
        }

    }
};
