/*
    Like a normal React Link, except fades between pages
*/
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

interface SeaLinkProps {
    children?: ReactNode,
    to: string
}

const SeaLink: React.FC<SeaLinkProps> = (props) => {
    const navigate = useNavigate();

    const onClick = (e:  React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        navigate(props.to);
    };

    return (
        <a
            href={props.to}
            onClick={(e) => onClick(e)}
            {... props}
        >
            {props.children}
        </a>
    );
};

export default SeaLink;
