import React from 'react';
import { IonSearchbar } from '@ionic/react';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaSearchbar.css';

interface SeaSearchbarProps {
    zone?: 'blue' | 'white' | 'grey',
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    label?: string,
    value: string,
    setValue: (value: string) => void,
    placeholder?: string,
    error?: string,
    warn?: boolean,
    maxWidth?: string,
    disabled?: boolean,
    debounce?: number,
    enterkeyhint?: 'done' | 'enter' | 'go' | 'next' | 'previous' | 'search' | 'send',
    autocomplete?: 'on' | 'off' | 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'email' | 'username' | 'new-password' | 'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-extension' | 'impp' | 'url' | 'photo'
}

const SeaSearchbar: React.FC<SeaSearchbarProps> = ({ zone, inputmode, label, value, setValue, placeholder, error, warn, maxWidth = '300px', disabled, debounce = 250, enterkeyhint, autocomplete }) => {

    //const [value, setValue] = useState('');

    const onChange = (e: any) => {
        if (e?.detail?.value) {
            setValue(e.detail.value);
        } else {
            setValue('');
        }
    }

    let color = 'light';
    switch (zone) {
        case 'blue':
            color = 'light'; // white
            break;
        case 'white':
        case 'grey':
            color = 'primary'; // blue
            break;
    }

    return (
        <>
            {label && <SeaLabel>{label}</SeaLabel>}
            <IonSearchbar
                className={`sea-searchbar ${zone}-zone ${(error || warn) ? 'has-error' : ''}`}
                style={{
                    maxWidth: maxWidth
                }}
                //ref={forwardedRef}
                color={color}
                inputmode={inputmode}
                mode="md"
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                debounce={debounce}
                enterkeyhint={enterkeyhint}
                autocomplete={autocomplete}
                onIonChange={onChange}
                animated={false}
            />
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaSearchbar;
