import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { IonSpinner } from '@ionic/react';
import { getFileNameFromString, getFileSrcFromString } from '../../lib/files';
import _ from 'lodash';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './SeaPDFViewer.css';
import { getCachedFileSrc } from '../../lib/fileSync';

const pdfWorker = `${process.env.PUBLIC_URL}assets/pdf/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
type SeaPDFViewerProps = {
  file?: string;
  size?: 'tiny' | 'tiny2' | 'medium' | 'full';
  maxPages?: number;
  showFullPDFBtn?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  allowScroll?: boolean
};

const SeaPDFViewer: React.FC<SeaPDFViewerProps> = ({ file, maxPages, showFullPDFBtn, size = 'full', onClick, className, allowScroll }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfURI, setPDFURI] = useState<string>();
  const [numPages, setNumPages] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isDocumentSmallerThanContainer, setIsDocumentSmallerThanContainer] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const [pagesRendered, setPagesRendered] = useState(false);
  const [layoutRendered, setLayoutRendered] = useState(false); 
  const containerRef = useRef<HTMLDivElement>(null);
  const lastElementObserver = useRef({} as any);
  const isLastElementVisibleRef = useRef(false);
  const resizeCompleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // This useMemo hook calculates the width of the PDF viewer based on the size and loading state.
  const width = useMemo(() => {
    if (size === 'tiny' && loading) {
      return 40;
    } else if (size === 'tiny') {
      return 28;
    } else if (size === 'tiny2' && loading) {
      return 40;
    } else if (size === 'tiny2') {
      return 40;
    } else if (size === 'medium' && loading) {
      return 100;
    } else if (size === 'medium') {
      return 200;
    } else if (size === 'full') {
      return undefined;
    }
  }, [loading, size])

  const pageHeight = useMemo(() => {
    const pageAspectRatio = 0.71; // Aspect ratio of a standard page
    return containerWidth ? containerWidth / pageAspectRatio : 0;
  }, [containerWidth]);

  // Fetches URI of PDF file and sets the number of pages
  useEffect(() => {
    let isMounted = true;
    let cancelFetch = false; // Flag to cancel fetch

    const fetchURIFromFile = async () => {
        if (!file) return;
        setLoading(true);
        try {
            const uri = await getCachedFileSrc(file);
            if (cancelFetch) return;
            if (!uri) throw new Error('File not found');
            if (isMounted) {
                setNumPages(maxPages || 1);
                setPDFURI(uri);
                setLoading(false);
            }
        } catch (error) {
            const uri = await getFileSrcFromString(file);
            if (cancelFetch) return;
            if (isMounted) {
                setNumPages(maxPages || 1);
                setPDFURI(uri);
            }
        } finally {
            if (isMounted) setLoading(false);
        }
    };

    fetchURIFromFile();

    return () => {
        isMounted = false;
        cancelFetch = true; // Set flag to cancel fetch
    };
}, [file, maxPages]);

const debouncedHandleResize = _.debounce(function handleResize() {
  let _containerWidth = containerRef.current?.offsetWidth || 0;
  if (!allowScroll) {
      const pageAspectRatio = 0.71; // Aspect ratio of a standard page
      let newWidth = (containerRef.current?.offsetHeight || 0) * pageAspectRatio;
      if (newWidth < _containerWidth) {
          _containerWidth = newWidth;
      }
  }
  if (_containerWidth && numPages) {
      setIsDocumentSmallerThanContainer(numPages * _containerWidth / 0.71 < (containerRef.current?.offsetHeight || 0));
  }
  setContainerWidth(_containerWidth);

  // Clear any existing timeout to ensure it doesn't fire prematurely
  if (resizeCompleteTimeoutRef.current) {
      clearTimeout(resizeCompleteTimeoutRef.current);
  }

  // Set a new timeout that will mark layout as rendered after a certain period of no further resize events
  resizeCompleteTimeoutRef.current = setTimeout(() => {
      setLayoutRendered(true); // Set layoutRendered to true once resizing is confirmed to be done
  }, 300); // Slightly longer than the debounce period to ensure no further resizes
}, 200);

  // Sets the container width and handles the resize event
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (size !== 'full') {
      setContainerWidth(width);
      setLayoutRendered(true);
      return;
    }
    debouncedHandleResize();
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      debouncedHandleResize.cancel();
    }
  });

  useEffect(() => {
    return () => {
        // Cleanup timeout when component unmounts or dependencies change
        if (resizeCompleteTimeoutRef.current) {
            clearTimeout(resizeCompleteTimeoutRef.current);
        }
    };
}, []);

  useEffect(() => {
    if (!allowScroll) {
      return;
    }
  
    const measureDivPosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const topPosition = rect.top; // Distance from the top of the viewport to the top of the div
        if (!topPosition) { // Handles case where containerRef.current has been initialized but its position has not been updated
          setTimeout(measureDivPosition, 10);
          return;
        }
        const availableSpace = window.innerHeight - topPosition; // Space from the top of the div to the bottom of the window
        
        setContainerHeight(availableSpace);
      }
    };
  
    const handleResize = () => {
      if (window.innerHeight !== windowHeight) {
        setWindowHeight(window.innerHeight);
        measureDivPosition();
      }
    };
  
    // Initial measurement and event listener setup
    measureDivPosition();
    window.addEventListener('resize', handleResize);
  
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowScroll, windowHeight, containerRef.current]); 

  useEffect(() => {
    if (!layoutRendered || totalPages === 0) {
        return;
    }

    if (size !== 'full') {
        setNumPages(1);
    } else {
        const visiblePages = allowScroll ? Math.ceil((containerHeight || 0) / pageHeight) : 1;

        if (allowScroll && containerHeight) {
            const pagesToLoad = Math.min(visiblePages, totalPages);
            setNumPages(maxPages && pagesToLoad > maxPages ? maxPages : pagesToLoad);
        } else {
            setNumPages(maxPages && totalPages > maxPages ? maxPages : 1);
        }

        if (containerWidth && containerHeight) {
            setIsDocumentSmallerThanContainer(totalPages * containerWidth / 0.71 < containerHeight);
        }
    }
    setLoading(false);
}, [layoutRendered, totalPages, size, containerHeight, pageHeight, allowScroll, containerWidth, maxPages]);

  // Handles when pdf document is loaded
  const onDocumentLoadSuccess = useCallback(({ numPages: loadedNumPages }: { numPages: number }) => {
    setTotalPages(loadedNumPages);
    setLoading(false);
  }, []);

  const handlePageLoaded = (pageNumber: number) => {
    if (pageNumber === numPages) {
      setTimeout(() => setPagesRendered(true), 50) // Timeout applied to avoid flickering as pdf renders
    }
  }

  const isLoaded = useMemo(() => {
    if (pagesRendered && layoutRendered) {
      return true
    }
    return false
  }, [layoutRendered, pagesRendered])

  const triggerElementRef = useCallback((node) => {
    if (!allowScroll || (numPages && numPages >= totalPages) || !isLoaded) {
      return;
    }
    if (lastElementObserver.current?.disconnect) {
        lastElementObserver.current.disconnect();
    }

    lastElementObserver.current = new IntersectionObserver((entries) => {
        isLastElementVisibleRef.current = entries[0].isIntersecting;
        if (isLastElementVisibleRef.current) {
          setNumPages(totalPages)
        }
    });

    if (node) {
        lastElementObserver.current.observe(node);
    }

    return () => {
        if (lastElementObserver.current?.disconnect) {
            lastElementObserver.current.disconnect();
        }
    };
}, [allowScroll, isLoaded, numPages, totalPages]);

  return (
    <div className="pdf-viewer-flex-container">
      <div
        className={`
          sea-pdf-viewer
          document-container
          ${size}
          ${className ?? ''} 
          ${isDocumentSmallerThanContainer ? 'center-document' : ''}
        `}
        style={{minHeight: pageHeight}}
        ref={containerRef}
      >
        {!isLoaded ?
          size === 'full' ?
          <IonSpinner name="crescent" className="sea-spinner"  />
          : <img
              src={`/assets/file-pdf${size.includes('tiny') ? '_tiny' : ''}@2x.png`}
              alt={file ? getFileNameFromString(file) : 'pdf'}
              className={`sea-file-image ${size} file`}
              onClick={onClick}
            />
        : null}
          <Document
            file={pdfURI}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setLoading(false)}
            onError={() => setLoading(false)}
            renderMode='canvas'
            className={`${!isLoaded ? 'hidden' : ''} ${onClick ? 'clickable' : ''}`}
            onClick={onClick}
            >
            {Array.from({ length: numPages || 0 }, (_, index) => (
              <div className="page-container" key={index}>
                <Page
                  onLoadSuccess={() => handlePageLoaded(index + 1)}
                  className={!isLoaded ? 'hidden' : ''}
                  renderTextLayer={false}
                  pageNumber={index + 1}
                  width={containerWidth}
                />
              </div>
            ))}
          </Document>
      </div>
      {allowScroll && isLoaded && (numPages || 0) < totalPages ?
        <div ref={triggerElementRef} style={{height: '8px'}}></div>
      : null}
    </div> 
  );
};

export default SeaPDFViewer;