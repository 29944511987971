import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sharedState } from '../../shared-state/shared-state';
import SeaScrollableArea from '../../components/SeaScrollableArea/SeaScrollableArea';
import SeaFooter from '../../components/SeaFooter/SeaFooter';
import './StandardPageLayout.css';

interface StandardPageLayoutProps {
    children: ReactNode,
    section: string,
    scrollEvents?: boolean, // Must be set to true to enable onScroll
    onScroll?: (event: Event) => void
}

const StandardPageLayout: React.FC<StandardPageLayoutProps> = ({
    children,
    section,
    scrollEvents = false,
    onScroll
}) => {
    const location = useLocation();

    useEffect(() => {
        sharedState.layoutMode.set('standard');
        sharedState.selectedSection.set(section);
    }, [section]);

    useEffect(() => {
        if (location.pathname.startsWith('/vessel/')) {
            sharedState.vesselId.set(location.pathname.substring(8, 28));
        }
    }, [location]);

    return (
        <div className="standard-layout">
            <SeaScrollableArea
                ref={(node) => {
                    if (node) {
                        sharedState.scrollableContent.set(node);
                    }
                }}
                scrollX={true}
                scrollEvents={scrollEvents}
                onScroll={onScroll}
                className="standard-content-bg"
            >
                <div className="standard-layout-content-container">
                    <div className="standard-layout-content">
                        {children}
                    </div>
                    <SeaFooter/>
                </div>
            </SeaScrollableArea>
        </div>
    );
};

export default StandardPageLayout;
