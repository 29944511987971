import * as React from "react";

function SvgCrew(props) {
  return (
    <svg
      width={28}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.281 7.752a3.149 3.149 0 003.145-3.145 3.149 3.149 0 00-3.145-3.145 3.149 3.149 0 00-3.145 3.145 3.149 3.149 0 003.145 3.145zm0-5.455a2.311 2.311 0 012.31 2.31 2.311 2.311 0 01-2.31 2.31 2.311 2.311 0 01-2.31-2.31 2.311 2.311 0 012.31-2.31zM22.281 8.915c-1.144 0-2.26.395-3.148 1.113a.419.419 0 00-.06.588c.144.178.408.208.586.06a4.12 4.12 0 012.622-.926 4.18 4.18 0 014.175 4.175v4.572a.417.417 0 10.835 0v-4.575a5.015 5.015 0 00-5.01-5.007zM6.3 7.752a3.149 3.149 0 003.145-3.145A3.149 3.149 0 006.3 1.462a3.149 3.149 0 00-3.145 3.145A3.149 3.149 0 006.3 7.752zm0-5.455a2.311 2.311 0 012.31 2.31 2.311 2.311 0 01-2.31 2.31 2.311 2.311 0 01-2.31-2.31 2.311 2.311 0 012.31-2.31zM9.51 10.613a.419.419 0 00-.062-.588A5.017 5.017 0 006.3 8.912a5.015 5.015 0 00-5.01 5.01v4.575a.417.417 0 10.836 0v-4.575A4.18 4.18 0 016.3 9.747c.966 0 1.873.32 2.622.927.181.147.443.12.587-.061zM14.29 9.174a3.149 3.149 0 003.146-3.145 3.149 3.149 0 00-3.145-3.145 3.149 3.149 0 00-3.145 3.145 3.149 3.149 0 003.145 3.145zm0-5.455a2.311 2.311 0 012.31 2.31 2.311 2.311 0 01-2.31 2.31 2.311 2.311 0 01-2.31-2.31 2.311 2.311 0 012.31-2.31zM14.29 10.337a5.015 5.015 0 00-5.009 5.01v4.575a.417.417 0 10.835 0v-4.575a4.18 4.18 0 014.175-4.175 4.18 4.18 0 014.174 4.175v4.575a.417.417 0 10.835 0v-4.575a5.015 5.015 0 00-5.01-5.01z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgCrew;
