import { isPlatform } from '@ionic/react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import {
    $deleteTableColumn,
    $getElementGridForTableNode,
    $getTableCellNodeFromLexicalNode,
    $getTableColumnIndexFromTableCellNode,
    $getTableNodeFromLexicalNodeOrThrow,
    $getTableRowIndexFromTableCellNode,
    $insertTableColumn,
    $insertTableColumn__EXPERIMENTAL,
    $insertTableRow,
    $isTableCellNode,
    $isTableRowNode,
    $removeTableRowAtIndex,
    getTableSelectionFromTableElement,
    HTMLTableElementWithWithTableSelectionState,
    TableCellHeaderStates,
    TableCellNode,
} from '@lexical/table';
import {
    $getRoot,
    $getSelection,
    $isRangeSelection,
    DEPRECATED_$isGridSelection,
} from 'lexical';
import * as React from 'react';
import {ReactPortal, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import LexIcon from '../../ui/LexIcon/LexIcon';
import LexButton from '../../ui/LexButton/LexButton';

type LexTableCellActionMenuProps = Readonly<{
    contextRef: {current: null | HTMLElement};
    onClose: () => void;
    setIsMenuOpen: (isOpen: boolean) => void;
    tableCellNode: TableCellNode;
    setTableCellNode: (node: TableCellNode | null) => void;
}>;

function TableActionMenu({
    onClose,
    tableCellNode: _tableCellNode,
    setTableCellNode,
    setIsMenuOpen,
    contextRef,
}: LexTableCellActionMenuProps) {
    const [editor] = useLexicalComposerContext();
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    const [tableCellNode, updateTableCellNode] = useState(_tableCellNode);
    const [selectionCounts, updateSelectionCounts] = useState({
        columns: 1,
        rows: 1,
    });

    useEffect(() => {
        return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
            const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === 'updated';
            const nodeRemoved = nodeMutations.get(tableCellNode.getKey()) === 'destroyed';
            if (nodeUpdated) {
                editor.getEditorState().read(() => {
                    updateTableCellNode(tableCellNode.getLatest());
                });
            }
            // Commented out as it was preventing the button from being created again
            //  else if (nodeRemoved) {
            //     setTableCellNode(null)
            // }
        });
    }, [editor, setTableCellNode, tableCellNode]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
    
            if (DEPRECATED_$isGridSelection(selection) && selection.anchor.getNode()) {
                const selectionShape = selection.getShape();
                updateSelectionCounts({
                    columns: selectionShape.toX - selectionShape.fromX + 1,
                    rows: selectionShape.toY - selectionShape.fromY + 1,
                });
            }
        });
    }, [editor]);

    useEffect(() => {
        const menuButtonElement = contextRef.current;
        const dropDownElement = dropDownRef.current;

        if (menuButtonElement != null && dropDownElement != null) {
            const menuButtonRect = menuButtonElement.getBoundingClientRect();

            dropDownElement.style.opacity = '1';

            dropDownElement.style.left = `${
                Math.min(menuButtonRect.left + menuButtonRect.width + window.pageXOffset + 5, window.innerWidth - 160)
            }px`;

            dropDownElement.style.top = `${
                menuButtonRect.top + window.pageYOffset
            }px`;
        }
    }, [contextRef, dropDownRef, ]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropDownRef.current != null &&
                contextRef.current != null &&
                !dropDownRef.current.contains(event.target as Node) &&
                !contextRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        }

        window.addEventListener('click', handleClickOutside);

        return () => window.removeEventListener('click', handleClickOutside);
    }, [setIsMenuOpen, contextRef]);

    const clearTableSelection = useCallback(() => {
        editor.update(() => {
            if (tableCellNode.isAttached()) {
                const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

                const tableElement = editor.getElementByKey(
                    tableNode.getKey(),
                ) as HTMLTableElementWithWithTableSelectionState;

                if (!tableElement) {
                    throw new Error('Expected to find tableElement in DOM');
                }

                const tableSelection = getTableSelectionFromTableElement(tableElement);
                if (tableSelection !== null) {
                    tableSelection.clearHighlight();
                }

                tableNode.markDirty();
                updateTableCellNode(tableCellNode.getLatest());
            }

            // Ben: I commented out the following to prevent page scrolling to top. It does leave some artifacts when deleting rows however.
            // const rootNode = $getRoot();
            // rootNode.selectStart();

        });
    }, [editor, tableCellNode]);

    const insertTableRowAtSelection = useCallback(
        (shouldInsertAfter: boolean) => {
            editor.update(() => {
                const selection = $getSelection();

                const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

                let tableRowIndex;

                if (DEPRECATED_$isGridSelection(selection)) {
                    const selectionShape = selection.getShape();
                    tableRowIndex = shouldInsertAfter
                        ? selectionShape.toY
                        : selectionShape.fromY;
                } else {
                    tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);
                }

                const grid = $getElementGridForTableNode(editor, tableNode);

                $insertTableRow(
                    tableNode,
                    tableRowIndex,
                    shouldInsertAfter,
                    selectionCounts.rows,
                    grid,
                );

                clearTableSelection();

                onClose();
            });
        },
        [editor, tableCellNode, selectionCounts.rows, clearTableSelection, onClose],
    );

    const insertTableColumnAtSelection = useCallback(
        (shouldInsertAfter: boolean) => {
            editor.update(() => {
                const selection = $getSelection();
    
                const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
    
                let tableColumnIndex;
    
                if (DEPRECATED_$isGridSelection(selection)) {
                    const selectionShape = selection.getShape();
                    tableColumnIndex = shouldInsertAfter
                        ? selectionShape.toX
                        : selectionShape.fromX;
                } else {
                    tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
                }
    
                // Adjust the index if we are inserting after
                if (shouldInsertAfter) {
                    tableColumnIndex += 1;
                }
    
                const grid = $getElementGridForTableNode(editor, tableNode);
                // If we are inserting before the current column, we need to insert one column at a time
                if (!shouldInsertAfter) {
                    for (let i = 0; i < selectionCounts.columns; i++) {
                        let currentIndex = tableColumnIndex;
                        currentIndex += i;
        
                        $insertTableColumn(
                            tableNode,
                            currentIndex,
                            shouldInsertAfter,
                            1, // Insert one column at a time
                            grid,
                        );
                    }
                } else {
                    const totalColumns = grid.columns;
                    // If we are inserting after the last column, we need to use the experimental insert
                    if (tableColumnIndex === totalColumns) {
                        for (let i = 0; i < selectionCounts.columns; i++) {
                            $insertTableColumn__EXPERIMENTAL(shouldInsertAfter);
                        }
                    } else {
                        $insertTableColumn(
                            tableNode,
                            tableColumnIndex,
                            shouldInsertAfter,
                            selectionCounts.columns,
                            grid,
                        );
                    }
                }
    
                clearTableSelection();
    
                onClose();
            });
        },
        [
            editor,
            tableCellNode,
            selectionCounts.columns,
            clearTableSelection,
            onClose,
        ],
    );

    const deleteTableRowsAtSelection = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            const selection = $getSelection();
    
            if (DEPRECATED_$isGridSelection(selection)) {
                const selectionShape = selection.getShape();
                const tableRows = tableNode.getChildren();
                const tableRowsToRemove = tableRows.slice(
                    selectionShape.fromY,
                    selectionShape.toY + 1,
                );
    
                tableRowsToRemove.forEach((tableRow) => {
                    if (!$isTableRowNode(tableRow)) {
                        throw new Error('Expected table row');
                    }
                    tableRow.remove();
                });
            } else {
                const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);
                $removeTableRowAtIndex(tableNode, tableRowIndex);
            }
    
            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);

    const deleteTableAtSelection = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            tableNode.remove();

            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);

    const deleteTableColumnsAtSelection = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            const selection = $getSelection();
    
            const deleteTableCell = (tableCell: any) => {
                if (!$isTableCellNode(tableCell)) {
                    throw new Error('Expected table cell');
                }
    
                tableCell.remove();
            };
    
            if (DEPRECATED_$isGridSelection(selection)) {
                const selectionShape = selection.getShape();
                const tableRows = tableNode.getChildren();
    
                for (let r = 0; r < tableRows.length; r++) {
                    const tableRow = tableRows[r];
    
                    if (!$isTableRowNode(tableRow)) {
                        throw new Error('Expected table row');
                    }
    
                    const tableCells = tableRow.getChildren();
                    const tableCellsToRemove = tableCells.slice(
                        selectionShape.fromX,
                        selectionShape.toX + 1,
                    );
    
                    tableCellsToRemove.forEach(deleteTableCell);
                }
            } else {
                const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
                $deleteTableColumn(tableNode, tableColumnIndex);
            }
    
            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);

    const toggleTableRowIsHeader = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

            const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

            const tableRows = tableNode.getChildren();

            if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
                throw new Error('Expected table cell to be inside of table row.');
            }

            const tableRow = tableRows[tableRowIndex];

            if (!$isTableRowNode(tableRow)) {
                throw new Error('Expected table row');
            }

            tableRow.getChildren().forEach((tableCell) => {
                if (!$isTableCellNode(tableCell)) {
                    throw new Error('Expected table cell');
                }

                tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW);
            });

            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);

    const toggleTableColumnIsHeader = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

            const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);

            const tableRows = tableNode.getChildren();

            for (let r = 0; r < tableRows.length; r++) {
                const tableRow = tableRows[r];

                if (!$isTableRowNode(tableRow)) {
                    throw new Error('Expected table row');
                }

                const tableCells = tableRow.getChildren();

                if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
                    throw new Error('Expected table cell to be inside of table row.');
                }

                const tableCell = tableCells[tableColumnIndex];

                if (!$isTableCellNode(tableCell)) {
                    throw new Error('Expected table cell');
                }

                tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN);
            }

            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);

    return createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={`${isPlatform('desktop') ? 'platform-desktop' : ''} lex-popover lex-dropdown dropdown`}
            ref={dropDownRef}
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <LexButton onClick={() => insertTableRowAtSelection(false)}>
                Insert{' '}
                {selectionCounts.rows === 1 ? 'row' : `${selectionCounts.rows} rows`}{' '}
                above
            </LexButton>
            <LexButton onClick={() => insertTableRowAtSelection(true)}>
                Insert{' '}
                {selectionCounts.rows === 1 ? 'row' : `${selectionCounts.rows} rows`}{' '}
                below
            </LexButton>
            <hr />
            <LexButton onClick={() => insertTableColumnAtSelection(false)}>
                Insert{' '}
                {selectionCounts.columns === 1
                    ? 'column'
                    : `${selectionCounts.columns} columns`}{' '}
                left
            </LexButton>
            <LexButton onClick={() => insertTableColumnAtSelection(true)}>
                Insert{' '}
                {selectionCounts.columns === 1
                    ? 'column'
                    : `${selectionCounts.columns} columns`}{' '}
                right
            </LexButton>
            <hr />
            <LexButton onClick={() => deleteTableColumnsAtSelection()}>
                Delete column{selectionCounts.columns > 1 ? 's' : ''}
            </LexButton>
            <LexButton onClick={() => {
                deleteTableRowsAtSelection()
            }}>
                Delete row{selectionCounts.rows > 1 ? 's' : ''}
            </LexButton>
            <LexButton onClick={() => deleteTableAtSelection()}>
                Delete table
            </LexButton>
            <hr />
            <LexButton onClick={() => toggleTableRowIsHeader()}>
                {(tableCellNode.__headerState & TableCellHeaderStates.ROW) === TableCellHeaderStates.ROW
                    ? 'Remove'
                    : 'Add'
                }{' '}
                row header
            </LexButton>
            <LexButton onClick={() => toggleTableColumnIsHeader()}>
                {(tableCellNode.__headerState & TableCellHeaderStates.COLUMN) === TableCellHeaderStates.COLUMN
                    ? 'Remove'
                    : 'Add'
                }{' '}
                column header
            </LexButton>
        </div>,
        document.body
    );
}

function LexTableCellActionMenuContainer({
    anchorElem,
}: {
    anchorElem: HTMLElement;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();

    const menuButtonRef = useRef<HTMLDivElement>(null);
    const menuRootRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(
        null,
    );

    const moveMenu = useCallback(() => {
        const menu = menuButtonRef.current;
        const selection = $getSelection();
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (selection == null || menu == null) {
            setTableMenuCellNode(null);
            return;
        }

        const rootElement = editor.getRootElement();

        if (
            $isRangeSelection(selection) &&
            rootElement !== null &&
            nativeSelection !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
                selection.anchor.getNode(),
            );

            if (tableCellNodeFromSelection == null) {
                setTableMenuCellNode(null);
                return;
            }

            const tableCellParentNodeDOM = editor.getElementByKey(
                tableCellNodeFromSelection.getKey(),
            );

            if (tableCellParentNodeDOM == null) {
                setTableMenuCellNode(null);
                return;
            }
            if (menuButtonRef.current !== null) {
                menuButtonRef.current.style.display = 'block';
            }
            setTableMenuCellNode(tableCellNodeFromSelection);
        } else if (!activeElement) {
            setTableMenuCellNode(null);
        }
    }, [editor]);

    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                moveMenu();
            });
        });
    }, [editor, moveMenu]);

    useEffect(() => {
        const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;

        if (menuButtonDOM != null && tableCellNode != null) {
            const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey());

            if (tableCellNodeDOM != null) {
                const tableCellRect = tableCellNodeDOM.getBoundingClientRect();
                const menuRect = menuButtonDOM.getBoundingClientRect();
                const anchorRect = anchorElem.getBoundingClientRect();

                const top = tableCellRect.top - anchorRect.top + 4;
                const left = tableCellRect.right - menuRect.width - 10 - anchorRect.left;

                menuButtonDOM.style.opacity = '1';

                menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`;
            } else {
                menuButtonDOM.style.opacity = '0';
                menuButtonDOM.style.transform = 'translate(-10000px, -10000px)';
            }
        }
    }, [menuButtonRef, tableCellNode, editor, anchorElem]);

    const prevTableCellDOM = useRef(tableCellNode);

    useEffect(() => {
        if (prevTableCellDOM.current !== tableCellNode) {
            setIsMenuOpen(false);
        }

        prevTableCellDOM.current = tableCellNode;
    }, [prevTableCellDOM, tableCellNode]);

    return (
        <div className="table-cell-action-button-container" ref={menuButtonRef}>
            {tableCellNode != null && (
                <>
                    <button
                        className="table-cell-action-button chevron-down"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsMenuOpen(!isMenuOpen);
                        }}
                        ref={menuRootRef}
                    >
                        <LexIcon icon="chevron-down" width="10px" height="10px" yOffset={-2} />
                    </button>
                    {isMenuOpen && (
                        <TableActionMenu
                            contextRef={menuRootRef}
                            setIsMenuOpen={setIsMenuOpen}
                            onClose={() => {
                                if (menuButtonRef.current !== null) {
                                    menuButtonRef.current.style.display = 'none';
                                }
                                setIsMenuOpen(false)
                            }}
                            tableCellNode={tableCellNode}
                            setTableCellNode={setTableMenuCellNode}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default function LexTableActionMenu({
    anchorElem = document.body,
}: {
    anchorElem?: HTMLElement;
}): null | ReactPortal {
    const isEditable = useLexicalEditable();
    return createPortal(
        isEditable ? (
            <LexTableCellActionMenuContainer anchorElem={anchorElem} />
        ) : null,
        anchorElem,
    );
}
