import { CreateableDocument, UpdateableDocument, SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { registerFiles } from '../../lib/fileSync';
import { formatYear } from '../../lib/util';

export interface SurveyReport extends UpdateableDocument, CreateableDocument {
    deletedBy?: string;
    files: string[];
    inOrOutWater: string;
    location?: string;
    personnelPresent?: string;
    state: string;
    surveyor?: string;
    title: string;
    vesselId: string;
    whenDeleted?: number;
    whenSurveyed: number;
}

export type VesselSurveyReportsData = { 
    data: {
        [id: string]: SurveyReport[]
    },
    byId: {
        [id: string]: SurveyReport
    },
    categories: string[]
};

export const vesselSurveyReportsConfig: SharedStateConfig<VesselSurveyReportsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => Object.keys(sharedState.vesselSurveyReports.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId && canView('survey')) {
            return onSnapshot(
                query(
                    collection(firestore, 'surveyReports'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('whenSurveyed', 'desc')
                ),
                (snap) => {
                    done();
                    const _surveyReports = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as SurveyReport;
                    });

                    const data = {} as {
                        [id: string]: SurveyReport[]
                    };
                    const byId = {} as {
                        [id: string]: SurveyReport
                    };
                    const categories: string[] = [];
                    _surveyReports.forEach((report) => {
                        registerFiles(report.files);
                        let whenSurveyed = formatYear(report.whenSurveyed)
                        if (data[whenSurveyed] === undefined) {
                            categories.push(whenSurveyed);
                            data[whenSurveyed] = [];
                        }
                        data[whenSurveyed].push(report);
                        byId[report.id] = report;
                    });
                    categories.sort().reverse();

                    set({
                        data,
                        categories,
                        byId
                    });

                }, (error) => {
                    // This should be very rare
                    done();
                    console.log('Failed to access Survey Documents', error.message, error, {
                        vesselId
                    });
                }
            );
        }
    },
}