import React, { ReactNode } from 'react';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import './ModalBoxManager.css';

//
// A ModalBox is a box-like modal that is centered on the screen.
// As only one can appear at a time, it is managed here and made available to components throughout the app.
//

export type ModalBox = {
    show: boolean,
    fadeIn: boolean,
    content: ReactNode,
    type: string
};

export const modalBoxConfig: SharedStateConfig<ModalBox> = {
    isAlwaysActive: true,
    default: {
        show: false,
        fadeIn: false,
        content: undefined as ReactNode,
        type: ''
    },
    notes: 'Allows components to display a custom modal box.'
};

export const openModalBox = (
    content: ReactNode,
    type = ''
) => {
    sharedState.modalBox.set({
        show: true,
        fadeIn: false,
        content: content,
        type: type
    });
    setTimeout(() => {
        //if (!isMounted.current) return;
        sharedState.modalBox.set((current: ModalBox | undefined) => {
            return {
                ...current,
                show: true,
                fadeIn: true,
                content: current?.content
            } as ModalBox;
        });
    }, 10);
};

export const updateModalBox = (content: ReactNode) => {
    sharedState.modalBox.set((current: ModalBox | undefined) => {
        return {
            ...current,
            content: content
        } as ModalBox;
    });
};

export const closeModalBox = (e?: React.MouseEvent) => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    setTimeout(() => {
        //if (!isMounted.current) return;
        sharedState.modalBox.set((current: ModalBox | undefined) => {
            return {
                ...current,
                show: true,
                fadeIn: false
            } as ModalBox;
        });
        setTimeout(() => {
            //if (!isMounted.current) return;
            sharedState.modalBox.set((current: ModalBox | undefined) => {
                return {
                    ...current,
                    show: false,
                    fadeIn: false,
                    content: undefined
                } as ModalBox;
            });
        }, 101);
    }, 10);
};

const ModalBoxManager: React.FC = () => {
    const modalBox = sharedState.modalBox.use();

    if (modalBox?.show) {
        return (
            <div
                className={`no-select sea-fullscreen-view modal-box ${modalBox.type} ${ modalBox.fadeIn ? 'reveal' : 'conceal' }`}
                onClick={(e) => closeModalBox(e) }
            >
                <div className="center-box">
                    {modalBox.content}
                </div>
            </div>
        );
    }
    return null;
};

export default ModalBoxManager;
