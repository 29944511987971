import React, { ReactNode } from 'react';
import { disableSwipe, enableSwipe } from '../../shared-state/General/swipeGestures';

interface SeaModalScrollableAreaProps {
    children?: ReactNode,
}

const SeaModalScrollableArea: React.FC<SeaModalScrollableAreaProps> = ({ children }) => {
    return (
        <div
            style={{ overflowX: 'auto' }}
            onTouchMove={(e) => {
                disableSwipe();
            }}
            onTouchEnd={(e) => {
                enableSwipe(500);
            }}
        >
            {children}
        </div>
    );
};

export default SeaModalScrollableArea;
