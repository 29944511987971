import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatValue, formatTextAreaText, formatDate } from '../../../../lib/util';
import { permissionLevels } from '../../../../lib/permissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import EditDrillReport from '../EditDrillReport/EditDrillReport';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';

interface ViewDrillReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: DrillReport,
    level?: number,
}

const ViewDrillReport: React.FC<ViewDrillReportProps> = ({showModal, setShowModal, selectedItem, level = 1}) => {
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    return (
        <SeaModal
            title={`Drill Report by ${renderFullNameForUserId(selectedItem?.addedBy)}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            level={level}
            onOpened={() => {console.log(`Viewing drillReport ${selectedItem?.id}`);}}
        >
            <div className="columns">
                <div className="rows">
                    <div>
                        <IonGrid className="form-grid view-item-with-image">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="sea-label">Date Completed</div>
                                    <div>{formatDate(selectedItem?.whenCompleted)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Location</div>
                                    <div>{formatValue(selectedItem?.location)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">People Involved</div>
                                    <div>
                                        {selectedItem?.crewInvolvedIds?.map((crewId, index) => {
                                            if (renderFullNameForUserId(crewId)) {
                                                if (index === 0) {
                                                    return renderFullNameForUserId(crewId);
                                                } else {
                                                    return `, ${renderFullNameForUserId(crewId)}`;
                                                }
                                            } else {
                                                return '-';
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Drills Completed</div>
                                    <div>
                                        {selectedItem?.drills?.map((drill, index) => {
                                            if (index === 0) {
                                                return (
                                                    vesselDrills?.byId[drill.id]
                                                        ? vesselDrills?.byId[drill.id].name
                                                        : drill.name
                                                )
                                            } else {
                                                return (
                                                    vesselDrills?.byId[drill.id]
                                                        ? `, ${vesselDrills?.byId[drill.id].name}`
                                                        : `, ${drill.name}`
                                                )
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Scenario</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.scenario))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Equipment</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.equipment))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Further Training</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.furtherTraining))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Modification to current operating procedures</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.modification))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Signed off by</div>
                                    <SeaSignatureImage file={selectedItem?.signature}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
                <div className="right">
                    <SeaFileImage files={selectedItem?.files} size="medium" showOthers/>
                </div>
            </div>

            <div style={{ height: '60px' }}></div>
            
            <div className="columns">
                <div className="columns">
                   <RequirePermissions
                        role="drills"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Report</SeaButton>
                        <div className="spacer-wide"></div>
                    </RequirePermissions>
                    {/* <SeaButton onClick={(e) => onGeneratePdf()} zone="white" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            {showModal &&
                <EditDrillReport
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={level + 1}
                />
            }
        </SeaModal>
    );
};

export default ViewDrillReport;
