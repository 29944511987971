import React, { useCallback, useMemo } from 'react'
import { IonButton } from '@ionic/react';
import { toFloat } from '../../lib/util';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { SparePart } from '../../shared-state/VesselMaintenance/spareParts';
import SeaInput from '../SeaInput/SeaInput';
import './SeaSparePartsAdjustments.css';

interface SeaSparePartsAdjustmentsProps {
    spareParts: SparePart[] | undefined;
    sparePartsStock: { [key: string]: {added?: number, used?: number} };
    setSparePartsStock: React.Dispatch<React.SetStateAction<{ [key: string]: {added?: number, used?: number} }>>;
    initialSparePartsStock: { [key: string]: {added?: number, used?: number} };
}

interface SparePartsRowProps {
    sparePart: SparePart;
    sparePartsStock: { [key: string]: {added?: number, used?: number} };
    setSparePartsStock: React.Dispatch<React.SetStateAction<{ [key: string]: {added?: number, used?: number} }>>;
    initialSparePartsStock: { [key: string]: {added?: number, used?: number} };
}




const SeaSparePartsAdjustments = ({spareParts, sparePartsStock, initialSparePartsStock, setSparePartsStock}: SeaSparePartsAdjustmentsProps) => {
  return (
    <div className={`spare-parts-quantity has-status ${(spareParts?.length) ? 'reveal' : 'conceal'}`}>
        <div className="sea-row-history headings-history">
            <div>Item</div>
            <div>Stock Used</div>
            <div>Stock Added</div>
            <div>Stock</div>
        </div>
        {spareParts?.map((sparePart) => (
            <SparePartsRow key={sparePart.id} sparePart={sparePart} sparePartsStock={sparePartsStock} setSparePartsStock={setSparePartsStock} initialSparePartsStock={initialSparePartsStock} />
        ))}
    </div>
  )
};

const SparePartsRow = ({sparePart, sparePartsStock, setSparePartsStock, initialSparePartsStock}: SparePartsRowProps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const used = useMemo(() => toFloat(sparePartsStock[sparePart.id]?.used), [sparePart.id, sparePartsStock]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const added = useMemo(() => toFloat(sparePartsStock[sparePart.id]?.added), [sparePart.id, sparePartsStock]);
    const initialUsed = useMemo(() => toFloat(initialSparePartsStock[sparePart.id]?.used), [initialSparePartsStock, sparePart.id]);
    const initialAdded = useMemo(() => toFloat(initialSparePartsStock[sparePart.id]?.added), [initialSparePartsStock, sparePart.id]);
    const stock = useMemo(() => {
        return toFloat(sparePart.quantity) + added - initialAdded - used + initialUsed;
    }, [sparePart.quantity, added, initialAdded, used, initialUsed]);

    const maxUseable = useMemo(() => {
        return used + stock;
    }, [stock, used]);

    const handleStockChange = useCallback((name: "added" | "used", value: number) => {
        setSparePartsStock((prev) => {
            const stock = { ...prev };
            if (!stock[sparePart.id]) {
                stock[sparePart.id] = {};
            }
            stock[sparePart.id][name] = value;
            return stock;
        })
    }, [sparePart.id, setSparePartsStock]);

    const handleBlur = (value: string, name: "added" | "used") => {
        // if stock is negative, account for it
        if (stock < 0) {
            const _stock = name === "used" ? stock : -stock;
            let adjustment = toFloat(value) + _stock;
            if (adjustment < 0) {
                adjustment = 0;
            }
            handleStockChange(name, adjustment);
            alertMessage(`Stock level for ${sparePart.item} cannot be negative. Set stock ${name === 'used' ? 'used' : 'added'} to ${adjustment}.`);
            return;
        }
    }

    return (
        <div key={sparePart.id} className={`sea-card sea-row-history`}>
            <div>{sparePart.item}</div>
            <div className="truncate">
                <IonButton
                    onClick={() => handleStockChange('used', toFloat(sparePartsStock[sparePart.id]?.used) - 1)}
                    disabled={used <= 0}
                >
                    -
                </IonButton>
                <SeaInput
                    inputmode="numeric"
                    type="number"
                    value={used}
                    onchange={(e) => handleStockChange('used', toFloat(e.detail.value))}
                    maxLength={3}
                    onblur={(e) => handleBlur(e.detail.target.value, 'used')}
                    max={maxUseable.toString()}
                />
                <IonButton
                    onClick={() => handleStockChange('used', toFloat(sparePartsStock[sparePart.id]?.used) + 1)}
                    disabled={stock <= 0}
                >
                    +
                </IonButton>
            </div>
            <div className="truncate">
                <IonButton
                    onClick={() => handleStockChange('added', toFloat(sparePartsStock[sparePart.id]?.added) - 1)}
                    disabled={added <= 0 || stock <= 0}
                >
                    -
                </IonButton>
                <SeaInput
                    inputmode="numeric"
                    type="number"
                    value={added}
                    onchange={(e) => handleStockChange('added', toFloat(e.detail.value))}
                    onblur={(e) => handleBlur(e.detail.target.value, 'added')}
                    maxLength={3}
                />
                <IonButton
                    onClick={() => handleStockChange('added', toFloat(sparePartsStock[sparePart.id]?.added) + 1)}
                >
                    +
                </IonButton>
            </div>
            <div className="truncate">{stock}</div>
        </div>
    );
}

export default SeaSparePartsAdjustments