import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from 'react';
import { sharedState } from '../shared-state';

export interface OverdueStat {
    safetyCheckItems: { overdue: number; stale: boolean };
    safetyEquipmentItems: { overdue: number; stale: boolean };
    drills: { overdue: number; stale: boolean };
    scheduledMaintenanceTasks: { overdue: number; stale: boolean };
    jobs: { overdue: number; stale: boolean };
    vesselCertificates: { overdue: number; stale: boolean };
    vesselDocuments: { overdue: number; stale: boolean };
    safetyMeetings: { overdue: number; stale: boolean };
};

export interface OverdueStats {
    whenUpdated: number;
    [vesselId: string]: OverdueStat | number; // whenUpdated is also a property of OverdueStats, hence the union type
}

export const useOverdueStats = (triggerRefreshOverdueStats:() => void) => {
    const licenseeId = sharedState.licenseeId.use();
    const [overdueStats, setOverdueStats] = useState<OverdueStats>();

    useEffect(() => {
        setOverdueStats(undefined);
        if (licenseeId) {
            return onSnapshot(
                doc(firestore, 'overdueStats', licenseeId),
                (snap) => {
                    if (!snap.exists()) {
                        setOverdueStats(undefined);
                        triggerRefreshOverdueStats();
                    } else {
                        setOverdueStats(snap.data() as OverdueStats);
                    }
                }, (error) => {
                    console.log(`Failed to access overdueStats for licenseeId=${licenseeId}`, error);
                }
            );
        }
    }, [licenseeId, triggerRefreshOverdueStats]);

    return overdueStats;
};
