import React, { useState, useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { reportError } from '../../managers/ErrorsManager/ErrorsManager';
import { listenToUploads, listenToDownloads } from '../../lib/fileSync';
//import { listenToDataSyncInfo } from '../../lib/dataSync';
import SeaIcon from '../SeaIcon/SeaIcon';
import SyncModal from '../../modals/Utilities/SyncModal/SyncModal';
import './SyncFilesAndData.css';

const SyncFiles: React.FC = () => {
    const onlineStatus = sharedState.onlineStatus.use();
    const dataSyncStatus = sharedState.dataSyncStatus.use();

    const [showSyncModal, setShowSyncModal] = useState(false);

    //const [dataSyncInfo, setDataSyncInfo] = useState<any>();
    const [uploadInfo, setUploadInfo] = useState<any>();
    const [downloadInfo, setDownloadInfo] = useState<any>();

    const onUploading = (uploadInfo: any) => {
        if (uploadInfo.error) {
            reportError(`Failed to upload a file`, uploadInfo.error.message, uploadInfo.error, uploadInfo.data);
        } else {
            setUploadInfo(uploadInfo);
        }
    };
    const onDownloading = (downloadInfo: any) => {
        if (downloadInfo.error) {
            //reportError(`Failed to download a file`, downloadInfo.error.message, downloadInfo.error);
            console.log(`Failed to download a file`, downloadInfo.error.message, downloadInfo.error, downloadInfo.data);
        } else {
            setDownloadInfo(downloadInfo);
        }
    };
    // const onDataSyncInfo = (dataSyncInfo: any) => {
    //     setDataSyncInfo(dataSyncInfo);
    // };

    //data.category[]
    useEffect(() => {
        listenToUploads(onUploading);
        listenToDownloads(onDownloading);
        //listenToDataSyncInfo(onDataSyncInfo);
    }, []);

    const isSyncing = (
        (dataSyncStatus && dataSyncStatus.totalTasks > 0) ||
        (uploadInfo?.isUploading) ||
        (downloadInfo?.isDownloading)
    );

    return (
        <>
            <div
                className={`item name sync ${isSyncing ? 'reveal' : 'conceal slooow'}`}
                style={{
                    cursor: (isSyncing ? 'pointer' : 'initial')
                }}
                onClick={(e) => {
                    if (isSyncing) {
                        setShowSyncModal(true);
                    }
                }}
            >
                <div style={{
                    padding: '2px 0px 0px 2px',
                    fontWeight: 'normal',
                    fontSize: '11px',
                    textDecoration: (onlineStatus?.isOnline) ? 'none' : 'line-through',
                    color: (onlineStatus?.isOnline) ? 'var(--text-on-grey)' : 'var(--ion-color-danger)'
                }}>
                    <div className="sync-spinner">
                        <SeaIcon icon="sync" forceFontSize="28px"/>
                    </div>
                </div>
            </div>
            <SyncModal
                showModal={showSyncModal}
                setShowModal={setShowSyncModal}
                dataSyncStatus={dataSyncStatus}
                uploadInfo={uploadInfo}
                downloadInfo={downloadInfo}
            />
        </>
    );

};


export default SyncFiles;