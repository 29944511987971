import React, { useMemo } from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { pdfStyles, pixelsToCm } from '../../../lib/pdf';
import { formatDatetime, formatTextAreaText, formatValue } from '../../../lib/util';
import { authoritiesNotified, incidentTypes, incidentWhoInvolvedTypes, injuryOutcomes } from '../../../pages/HealthSafety/Incidents/incidents-util';
import { renderCategoryName, renderCategoryNames } from '../../../lib/categories';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import SeaAuthorshipPDF from '../../Components/SeaAuthorshipPdf';

interface IncidentReportCommonPageProps {
    selectedItem: Incident,
    reportTitle: string,
    auth: any,
    addedBy?: string,
    completedBy?: string,
    updatedBy?: string,
    signature?: any,
    images?: any[],
}

const IncidentReportCommonPage: React.FC<IncidentReportCommonPageProps> = ({selectedItem, reportTitle, addedBy, completedBy, updatedBy, signature, images, auth}) => {
    const { incidentCategories, incidentCauses, vessels, injuryTypes, injuryLocations } = auth;
    const vessel = useMemo(() => 
        selectedItem.vesselId ? 
            vessels?.byId[selectedItem.vesselId]?.name : undefined, 
        [vessels?.byId, selectedItem.vesselId]
        );
        
    return (
        <Page
            style={pdfStyles.page}
            orientation="portrait"
            size="A4" // 21cm x 29.7cm
            wrap={true}
        >

            {/* Header */}
            <View wrap={false} style={pdfStyles.header}>
                <Text style={pdfStyles.heading}>{reportTitle}</Text>
            </View>

            {/* Authorship */}
            <SeaAuthorshipPDF item={selectedItem} addedBy={addedBy} completedBy={completedBy} updatedBy={updatedBy} />

            {/* Type */}
            <View wrap={false} style={[pdfStyles.row, {position: 'relative'}]}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.labelHeading}>Type</Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Vessel / Facility</Text>
                    <Text>{vessel}</Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Type</Text>
                    <Text>
                        {formatValue(incidentTypes[selectedItem?.type])}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Event Category</Text>
                    <Text>
                        {formatValue(renderCategoryName(selectedItem?.categoryId, incidentCategories))}
                    </Text>
                </View>
                    <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Report #</Text>
                    <Text>
                        {formatValue(selectedItem?.reportNum)}
                    </Text>
                </View>
            </View>

            {/* Personnel */}
            <View style={pdfStyles.greyLine}></View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.labelHeading}>Personnel</Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Types of Personnel Involved</Text>
                    <Text>
                        {
                            formatValue(selectedItem?.whoInvolvedTypes?.map((type: string) => {
                                return incidentWhoInvolvedTypes[type];
                            }).join(', '))
                        }
                    </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>First Reported By</Text>
                    <Text>
                        {formatValue(selectedItem.reportedBy)}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Role in the Event</Text>
                    <Text>
                        {formatValue(selectedItem?.role)}
                    </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Person/s Involved</Text>
                    <Text>
                        {formatValue(selectedItem?.whoInvolved)}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Witnesses</Text>
                    <Text>
                        {formatValue(selectedItem?.witnesses)}
                    </Text>
                </View>
            </View>


            {/* Event */}
            <View style={pdfStyles.greyLine}></View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.labelHeading}>Event</Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Date & Time</Text>
                    <Text>
                        {formatDatetime(selectedItem?.whenEvent, ', ')}
                </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Event Location</Text>
                    <Text>
                        {formatValue(selectedItem?.location)}
                </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Environmental Conditions</Text>
                    <Text>
                        {formatValue(selectedItem?.conditions)}
                </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Reported to Authorities?</Text>
                    <Text>
                        {formatValue(selectedItem?.notifiedAuthorities && authoritiesNotified[selectedItem?.notifiedAuthorities])}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Believed Causes of This Event</Text>
                    <Text>
                        {formatValue(renderCategoryNames(selectedItem?.causeIds, incidentCauses))}
                    </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Damage to Property</Text>
                    <Text>
                        {formatValue(selectedItem?.propertyDamage)}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.label}>Description</Text>
                    <Text>
                        {formatValue(formatTextAreaText(selectedItem?.description))}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Initial Actions Taken</Text>
                    <Text>
                        {formatValue(formatTextAreaText(selectedItem?.initialActions))}
                    </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Corrective Actions Suggested</Text>
                    <Text>
                        {formatValue(formatTextAreaText(selectedItem?.prevention))}
                    </Text>
                </View>
            </View>
            <View style={pdfStyles.greyLine}></View>
            <View wrap={false} style={pdfStyles.rowNoSpace}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.label}>Images / Documents</Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={[pdfStyles.column1, { flexDirection: 'column', textAlign: 'center' }]}>
                    {images?.length ? (
                        images.map((image, index) => image.element)
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>
                
        {/* Injury */}
        {selectedItem?.type === 'injury' && selectedItem?.injuries && selectedItem?.injuries?.length > 0 && (
            <>
                <View style={pdfStyles.greyLine}></View>
                <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column1}>
                        <Text style={pdfStyles.labelHeading}>
                                Injur{selectedItem.injuries.length === 1 ? 'y' : 'ies'}
                        </Text>
                    </View>
                </View>
                {selectedItem.injuries.map((injury, index) => (
                    <View key={index}>
                    {index > 0 && 
                    <View style={pdfStyles.endInfoLineSub} />}
                        <View style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>{selectedItem.injuries?.length === 1 ? 'Person injured' : `Person injured (${index + 1})`}</Text>
                                <Text>
                                    {formatValue(injury?.whoInjured)}
                                </Text>
                            </View>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Type</Text>
                                <Text>
                                    {formatValue(renderCategoryNames(injury?.typeIds, injuryTypes))}
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Location</Text>
                                <Text>
                                    {formatValue(renderCategoryNames(injury?.locationIds, injuryLocations))}
                                </Text>
                            </View>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Outcome</Text>
                                <Text>
                                    {formatValue(injury?.outcome && injuryOutcomes[injury?.outcome])}
                                </Text>
                            </View>
                        </View>
                    </View>
                ))}
            </>
        )}

        {/* Signature */}
        <View style={pdfStyles.greyLine} />
        <View wrap={false} style={pdfStyles.rowNoSpace}>
            <View style={pdfStyles.column1}>
                <Text style={pdfStyles.label}>Signature</Text>
            </View>
        </View>
            {signature ? (
            <View wrap={false} style={pdfStyles.rowNoSpace}>
                <View
                    style={{
                        padding: '0 0 0 0',
                        width: pixelsToCm(352)+'cm',
                        height: pixelsToCm(202)+'cm'
                    }}
                    wrap={false}
                >
                    <Image
                        src={signature}
                        style={{
                            width: pixelsToCm(350)+'cm',
                            height: pixelsToCm(200)+'cm'
                        }}
                    />
                </View>
            </View>
        ) : (
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column1}>
                    <Text>-</Text>
                </View>
            </View>
        )}
        </Page>
    );
};

export default IncidentReportCommonPage;
