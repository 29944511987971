import React, { useMemo, useState } from 'react';
import { maxSessionOptions } from '../../../../../lib/util';
import { sharedState } from '../../../../../shared-state/shared-state';
import SeaButton from '../../../../../components/SeaButton/SeaButton';
import SeaGrid from '../../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../../components/SeaGridCell/SeaGridCell';
import EditLicenseeSettingsModal from '../../EditLicenseeSettingsModal/EditLicenseeSettingsModal';
import './LicenseeSettingsTab.css';

interface LicenseeSettingsTabProps {
    showModal: boolean
}

const LicenseeSettingsTab: React.FC<LicenseeSettingsTabProps> = ({showModal}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const [showEditLicenseeSettingsModal, setShowEditLicenseeSettingsModal] = useState(false);

    const maxSessionDescription = useMemo(() => {
        if (licenseeSettings?.maxSessionSeconds) {
            for (let i = 0; i < maxSessionOptions.length; i++) {
                if (parseInt(maxSessionOptions[i].value) === licenseeSettings.maxSessionSeconds) {
                    return maxSessionOptions[i].description;
                }
            }
        }
        return 'Unlimited';
    }, [licenseeSettings]);

    return (<>
        <div>
            {licenseeSettings &&
                <>
                    <h2>
                        Multi Factor Authentication: <b>{licenseeSettings.hasMfaEmail ? 'Enabled' : 'Disabled'}</b>
                    </h2>
                    <div style={{ height: '10px' }}></div>
                    {
                        licenseeSettings.hasMfaEmail ? (
                            <p>
                                When logging in, your crew will also need to provide a passcode sent to them via email.
                            </p>
                        ) : (
                            <>
                                <p>
                                    If Multi Factor Authentication is enabled, your crew will also need to provide an email passcode when logging in.
                                </p>
                                <p>
                                    If you would like to enable Multi Factor Authentication, please contact the Sea Flux team
                                </p>
                                <p>
                                    +64223080209 or +64279404383
                                </p>
                            </>
                        )
                    }
                </>
            }
        </div>
        <div style={{ marginTop: '50px' }}>
            <h2>Session Management</h2>
            <div style={{ height: '20px' }}></div>
            <SeaGrid>
                <SeaGridCell
                    label="Maximum Session Time"
                    help={{text: 'This is the duration a user is allowed to remain logged in before being required to login again.'}}
                    w="50"
                >
                    {maxSessionDescription}
                </SeaGridCell>
            </SeaGrid>
            <div className="view-modal-buttons">
                <SeaButton zone="white" onClick={(e) => setShowEditLicenseeSettingsModal(true)}>
                    Edit Licensee Settings
                </SeaButton>
            </div>
        </div>
        {showModal &&
            <EditLicenseeSettingsModal
                showModal={showEditLicenseeSettingsModal}
                setShowModal={setShowEditLicenseeSettingsModal}
            />
        }
    </>);
};

export default LicenseeSettingsTab;
