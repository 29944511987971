import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../lib/firebase';
import { SharedStateConfig, sharedState } from '../../shared-state';
import { CustomForm } from './customForms';

export type CustomFormTemplate = CustomForm;

export type CustomFormTemplatesData = {
    categories: string[],
    byCategory: {
        [category: string]: CustomFormTemplate[]
    }
};

export const customFormTemplatesConfig: SharedStateConfig<CustomFormTemplatesData> = {
    isAlwaysActive: false,
    dependencies: [],
    countLiveDocs: () => sharedState.customFormTemplates.current?.categories.length ?? 0,
    run: (done, set, clear) => {
        clear();
        return onSnapshot(
            query(
                collection(firestore, 'customForms'),
                where('state', '==', 'active'),
                where('isTemplate', '==', true),
                orderBy('templateCategory', 'asc'),
                orderBy('title', 'asc')
            ),
            (snap) => {
                done();
                const _customFormTemplates = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as CustomFormTemplate;
                });

                const categories = [] as string[];
                const byCategory = {} as {
                    [category: string]: CustomFormTemplate[]
                };
                _customFormTemplates.forEach((template) => {
                    if (template.templateCategory && byCategory[template.templateCategory] === undefined) {
                        byCategory[template.templateCategory] = [];
                        categories.push(template.templateCategory);
                    }
                    if (template.templateCategory) {
                        byCategory[template.templateCategory].push(template);
                    }
                });
                set({
                    categories,
                    byCategory
                });
            }, (error) => {
                done();
                console.log(`Failed to access form/checklist templates`, error);
            }
        );
    }
};
