import React, { useState } from 'react';
import { sharedState } from '../../../../shared-state/shared-state';
import { CustomFormTemplate } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormTemplates';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import ViewCustomFormTemplate from '../ViewCustomFormTemplate/ViewCustomFormTemplate';
import './BrowseCustomFormTemplates.css';

interface BrowseCustomFormTemplatesProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowEditCustomFormModal: (showModal: boolean) => void,
    setEditCustomFormItem: (item: CustomForm) => void,
    setTemplateToUse: (template: Partial<CustomFormTemplate>) => void,
    level?: number,
    //setShowEditCustomFormModal: (show: boolean) => void
}

const BrowseCustomFormTemplates: React.FC<BrowseCustomFormTemplatesProps> = ({showModal, setShowModal, setShowEditCustomFormModal, setEditCustomFormItem, setTemplateToUse, level }) => {
    const onlineStatus = sharedState.onlineStatus.use(showModal);
    const customFormTemplates = sharedState.customFormTemplates.use(showModal);
    const [showViewCustomFormTemplate, setShowViewCustomFormTemplate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<CustomFormTemplate>();

    const onViewTemplate = (template: CustomFormTemplate) => {
        setSelectedTemplate(template);
        setShowViewCustomFormTemplate(true);
    }

    return (
        <SeaModal
            title="Form/Checklist Templates"
            showModal={showModal}
            setShowModal={setShowModal}
            level={level}
            size="standard"
        >

            {(onlineStatus?.isOnline) && <>
                <SeaNoData
                    dataName="form/checklist"
                    isHistory={true}
                    isLoading={!customFormTemplates}
                    hasNoData={customFormTemplates?.categories && customFormTemplates.categories.length === 0}
                />

                <div className={`custom-form-templates ${(customFormTemplates?.categories?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                    {customFormTemplates?.categories.map((category: string) => {
                        return (<React.Fragment key={category}>
                            <div className="category-heading">
                                <div>{category}</div>
                            </div>
                            {customFormTemplates.byCategory[category]?.map((template: CustomFormTemplate) => {
                                return (
                                    <div
                                        key={template.id}
                                        className={`sea-card sea-row-history clickable`}
                                        onClick={(e) => onViewTemplate(template)}
                                    >
                                        <div>{template.title}</div>
                                    </div>
                                );
                            })}
                        </React.Fragment>);
                    })}
                </div>

                {showModal &&
                    <ViewCustomFormTemplate
                        showModal={showViewCustomFormTemplate}
                        setShowModal={setShowViewCustomFormTemplate}
                        setShowBrowseTemplatesModal={setShowModal}
                        setShowEditCustomFormModal={setShowEditCustomFormModal}
                        setEditCustomFormItem={setEditCustomFormItem}
                        level={2}
                        selectedTemplate={selectedTemplate}
                        setTemplateToUse={setTemplateToUse}
                    />
                }
            </>}
            {!onlineStatus?.isOnline &&
                <p>Templates are only accessible while online.</p>
            }
        </SeaModal>
    );
};

export default BrowseCustomFormTemplates;
