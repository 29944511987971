import { collection, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { Vessel } from "../Core/vessel";

//
// Loads all vessels.
// This can only be used by superAdmin.
//

export type AllVesselsData = {
    byId: {
        [id: string]: Vessel;
    }
}

export const useAllVessels = () => {
    const [allVessels, setAllVessels] = useState<AllVesselsData>();

    useEffect(() => {
        setAllVessels(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'vessels'),
                // where('state', '==', 'active')
            ),
            (snap) => {
                const byId = {} as any;

                snap.docs.forEach((doc) => {
                    byId[doc.id] = {
                        id: doc.id,
                        ...doc.data()
                    };
                });

                setAllVessels({
                    byId
                });
            },
            (error) => {
                console.log(`error getting allVessels`, error);
            }
        );
    }, []);

    return allVessels;
};
