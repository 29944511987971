import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { UserDetails } from "../Crew/userDetails";

//
// Loads all active userDetails accross all licensee accounts.
// This is A LOT loading ALL userDetails when we just want those that are licensees.
// Oh well... only superAdmin can/will use this. Maybe we'll optimise this later...
//

export const useLicenseeDetailsById = () => {
    const [licenseeDetailsById, setLicenseeDetailsById] = useState<{
        [licenseeId: string]: UserDetails
    }>();

    useEffect(() => {
        setLicenseeDetailsById(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'userDetails'),
                where('state', '==', 'active')
            ),
            (snap) => {
                const byId = {} as {
                    [licenseeId: string]: UserDetails
                };
                for (const doc of snap.docs) {
                    byId[doc.id] = {
                        id: doc.id,
                        ...doc.data()
                    } as UserDetails;
                }
                setLicenseeDetailsById(byId);
            }
        );
    }, []);

    return licenseeDetailsById;
};
