import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { sharedState } from "../shared-state";
import { useEffect, useState } from "react";
import { TrainingTaskReport } from "./trainingTaskReports";
import { UserType } from "../Core/user";
import { registerFiles } from "../../lib/fileSync";

export type UserTrainingTaskReportsData = TrainingTaskReport[];

export const useUserTrainingTaskReports = (selectedUser: UserType | undefined) => {
    const licenseeId = sharedState.licenseeId.use();
    const [userTrainingTaskReports, setUserTrainingTaskReports] = useState<TrainingTaskReport[]>();

    useEffect(() => {
        setUserTrainingTaskReports(undefined);
        if (licenseeId && selectedUser) {
            return onSnapshot(
                query(collection(firestore, "trainingTaskReports"), where("state", "==", "active"), where("licenseeId", "==", licenseeId), where("completedBy", "array-contains", selectedUser.id), orderBy("whenDue", "desc")),
                (snap) => {
                    const all = snap.docs.map((doc) => {
                        registerFiles(doc.data().files);
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReport;
                    });

                    all.sort((a, b) => {
                        return b.whenDue - a.whenDue;
                    });

                    setUserTrainingTaskReports(all);
                },
                (error) => {
                    // This should be very rare
                    console.log("Failed to access training task reports ", error);
                }
            );
        }
    }, [selectedUser, licenseeId]);

    return userTrainingTaskReports;
};
