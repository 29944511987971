import React from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import MaintenanceSchedule from './MaintenanceSchedule/MaintenanceSchedule';
import JobList from './JobList/JobList';
import MaintenanceHistory from './MaintenanceHistory/MaintenanceHistory';
import SparePartsList from './SparePartsList/SparePartsList';
import EquipmentList from './EquipmentList/EquipmentList';
import EquipmentManualDocuments from './EquipmentManualDocuments/EquipmentManualDocuments';
import './VesselMaintenance.css';

const VesselMaintenance: React.FC = () => {
    const vessel = sharedState.vessel.use();
    const vesselId = sharedState.vesselId.use();
    const selectedSectionTab = sharedState.selectedSectionTab.use();

    return (
        <StandardPageLayout section="vesselMaintenance">
            <SeaBreadcrumb>
                <SeaLink to="/fleet">Fleet DashBoard</SeaLink>
                {vessel && <SeaLink to={`/vessel/${ vesselId }`}>{ vessel.name }</SeaLink>}
                {vessel && <SeaLink to={`/vessel/${ vesselId }/maintenance`}>Maintenance</SeaLink>}
            </SeaBreadcrumb>

            <SeaTabsGroup id="VesselMaintenance" selectedTab={selectedSectionTab} setTab={sharedState.selectedSectionTab.set}>
                <SeaTab tab="MaintenanceSchedule" requireRole="maintenanceSchedule" setTab={sharedState.selectedSectionTab.set}>Maintenance<br/>Schedule</SeaTab>
                <SeaTab tab="JobList" requireRole="jobList" setTab={sharedState.selectedSectionTab.set}>Job List</SeaTab>
                <SeaTab tab="PartsList" requireRole="sparePartsList" setTab={sharedState.selectedSectionTab.set}>{vessel?.isShoreFacility ? 'Inventory List' : 'Spare Parts List'}</SeaTab>
                <SeaTab tab="EquipmentList" requireRole="maintenanceSchedule" setTab={sharedState.selectedSectionTab.set}>Equipment List</SeaTab>
                <SeaTab tab="EquipmentManuals" requireRole="equipmentManualDocuments" setTab={sharedState.selectedSectionTab.set}>Equipment Manuals</SeaTab>
                <SeaTab tab="MaintenanceHistory" requireRole="maintenanceHistory" setTab={sharedState.selectedSectionTab.set}>Maintenance History</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="MaintenanceSchedule" selectedTab={selectedSectionTab}>
                <MaintenanceSchedule
                    visible={selectedSectionTab === 'MaintenanceSchedule'}
                />
            </SeaTabContent>
            <SeaTabContent tab="JobList" selectedTab={selectedSectionTab}>
                <JobList visible={selectedSectionTab === 'JobList'}/>
            </SeaTabContent>
            <SeaTabContent tab="MaintenanceHistory" selectedTab={selectedSectionTab}>
                <MaintenanceHistory visible={selectedSectionTab === 'MaintenanceHistory'}/>
            </SeaTabContent>
            <SeaTabContent tab='PartsList' selectedTab={selectedSectionTab}>
                <SparePartsList
                    visible={selectedSectionTab === 'PartsList'}
                />
            </SeaTabContent>
            <SeaTabContent tab='EquipmentList' selectedTab={selectedSectionTab}>
                <EquipmentList
                    visible={selectedSectionTab === 'EquipmentList'}
                />
            </SeaTabContent>
            {!vessel?.isShoreFacility && 
                <SeaTabContent tab="EquipmentManuals" selectedTab={selectedSectionTab}>
                    <EquipmentManualDocuments visible={selectedSectionTab === 'EquipmentManuals'}/>
                </SeaTabContent>
            }

        </StandardPageLayout>
    );
};

export default VesselMaintenance;
