import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { SafetyEquipmentItem } from '../../VesselSafety/safetyEquipmentItems';
import { SafetyCheckItem } from '../../VesselSafety/safetyCheckItems';

export const makeSafetyItems = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        };
    }) as SafetyEquipmentItem[] | SafetyCheckItem[];
};
export const sortSafetyItems = (all: SafetyEquipmentItem[] | SafetyCheckItem[]) => {
    all.sort((a, b) => {
        if (a.whenFaulted !== undefined && b.whenFaulted !== undefined) {
            return a.whenFaulted - b.whenFaulted;
        } else {
            return 0;
        }
    });
};
