import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { VesselDocument } from "../VesselDocuments/vesselDocuments";
import { registerFiles, registerRichText } from "../../lib/fileSync";

export type DashboardVesselDocumentsData = {
    byId: {
        [id: string]: VesselDocument;
    };
    top5: VesselDocument[];
    numHighestPriority: number;
};

export const dashboardVesselDocumentsConfig: SharedStateConfig<DashboardVesselDocumentsData> = {
    isAlwaysActive: false,
    dependencies: ["vesselId"],
    countLiveDocs: () => sharedState.dashboardVesselDocuments.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId && canView("vesselDocuments")) {
            return onSnapshot(
                query(collection(firestore, "vesselDocuments"), where("vesselId", "==", vesselId), where("state", "==", "active"), where("whenExpires", "<", getDayOffsetMillis(warnDays.vesselDocuments[0])), orderBy("whenExpires", "asc")),
                (snap) => {
                    done();
                    const byId: { [id: string]: VesselDocument } = {};
                    const upcoming = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as VesselDocument;
                        registerFiles(item.files);
                        registerRichText(item.sfdoc);
                        byId[doc.id] = item;
                        return item;
                    });
                    set({
                        byId,
                        top5: upcoming.slice(0, 5),
                        numHighestPriority: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access documents for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    },
};
