import React, { useState } from 'react';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaColourModal from '../SeaColourModal/SeaColourModal';
import './SeaColour.css';

interface SeaColourProps {
    name?: string,
    label?: string,
    color: string,
    setColor: (color: string) => void,
    colorSwatches?: string[],
    editColorTitle?: string,
    level?: number
}

const SeaColour: React.FC<SeaColourProps> = ({
    name,
    label,
    color,
    setColor,
    colorSwatches,
    editColorTitle = 'Choose Colour',
    level = 3
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {label && <SeaLabel>{label}</SeaLabel>}
            <div
                className="sea-input sea-colour box"
                style={{
                    backgroundColor: color
                }}
                onClick={(e) => {
                    setShowModal(true);
                }}
            >
            </div>
            <SeaColourModal
                title={editColorTitle}
                showModal={showModal}
                setShowModal={setShowModal}
                color={color}
                setColor={setColor}
                colorSwatches={colorSwatches}
                level={level+1}
            />
        </>
    );
};

export default SeaColour;
