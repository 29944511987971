import React, { useState, useEffect } from 'react';
import { listenToCacheMode } from '../../lib/fileSync';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaIcon from '../SeaIcon/SeaIcon';
import './CachedFilesStatus.css';

const CachedFilesStatus: React.FC = () => {
    const [cacheMode, setCacheMode] = useState('FULL');

    const onCacheMode = (_cacheMode: string) => {
        setCacheMode(_cacheMode);
    };

    useEffect(() => {
        listenToCacheMode(onCacheMode);
    }, []);

    if (cacheMode === 'FULL') {
        return null;
    }

    return (
        <>
            <div
                className={`item name status`}
                style={{
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    openContextualHelp(e, {
                        text: 'Your device is running out of space!\n\nSea Flux is unable to reliably cache files until there is more space available.\n\nPlease consider uninstalling apps or deleting files off your device that you no longer need.'
                    });
                }}
            >
                <div style={{
                    position: 'relative',
                    color: 'var(--ion-color-danger)'
                }}>
                    <div style={{position: 'relative', top: '-1px'}}>
                        <SeaIcon icon="data" forceFontSize="26px"/>
                        <div className="cache-warn-icon bg" style={{ color: 'var(--bg-header)' }}>
                            <SeaIcon icon="triangle" forceFontSize="21px"/>
                        </div>
                        <div className="cache-warn-icon">
                            <SeaIcon icon="warn" forceFontSize="21px"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default CachedFilesStatus;
