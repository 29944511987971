let log = '';

export const logDebug = (s: string) => {
    console.log('debug:'+s);
    // if (log !== '') {
    //     log += '\n';
    // }
    // log += s;
};

export const getDebugLog = () => {
    return log;
};
