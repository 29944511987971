import { View, Text } from "@react-pdf/renderer";
import { renderCamelCase } from "../../../lib/util";
import { pdfStyles } from "../../../lib/pdf";
import React from "react";

export const renderIncidentState = (state: string) => {
    return <div className={`sea-status incident ${state}`}>{renderCamelCase(state)}</div>;
};

export const renderIncidentStatePDF = (state: string) => {
    let bgColor = "#2dd36f";
    let fontColor = "#ffffff";
    let fontStyle = "normal";
    if (state === "inReview") {
        bgColor = "#f99e1b";
    } else if (state === "forReview") {
        bgColor = "#eb445a";
    } else if (state === "draft") {
        fontStyle = "italic";
        bgColor = "#cef2fe";
        fontColor = "#666666";
    }

    return (
        <View
            style={{
                ...pdfStyles.status,
                backgroundColor: bgColor,
                color: fontColor,
                fontStyle: fontStyle,
            }}
        >
            <Text>{renderCamelCase(state)}</Text>
        </View>
    );
};

export const currentIncidentStatuses: Record<string, string> = {
    draft: "Draft",
    forReview: "For Review",
    inReview: "In Review",
};

export const authoritiesNotified: Record<string, string> = {
    n: "No, it's not requried",
    y: "Yes",
    unsure: "I'm unsure if this is a notifiable event",
    amsa: "Yes, I've notified AMSA",
    whs: "Yes, I've notified Work Health and Safety",
    mnz: "Yes, I've notified Maritime New Zealand",
    ws: "Yes, I've notified Work Safe",
    mca: "Yes, I've notified MCA",
    hse: "Yes, I've notified HSE",
    miab: "Yes, I've notified MAIB",
};

export const authoritiesNotifiedForReview: Record<string, string> = {
    n: "No, it's not requried",
    y: "Yes",
    amsa: "Yes, AMSA has been notified",
    whs: "Yes, WHS has been notified",
    amsaWhs: "Yes, both AMSA and WHS have been notified",
    mnz: "Yes, Maritime New Zealand has been notified",
    ws: "Yes, Work Safe has been notified",
    mnzWs: "Yes, both MNZ and WS have been notified",
    mca: "Yes, MCA has been notified",
    hse: "Yes, HSE has been notified",
    miab: "Yes, MAIB has been noitified",
    mcaHse: "Yes, MCA and HSE have been notified",
    mcaMiab: "Yes, MCA and MAIB have been notified",
    hseMiab: "Yes, HSE and MAIB have notified",
    mcaHseMiab: "Yes, MCA, HSE, and MAIB have been notified",
};

export const authoritiesNotifiedForReviewLabels: Record<string, string> = {
    n: "Not notified",
    y: "Notified",
    amsa: "AMSA",
    whs: "WHS",
    amsaWhs: "AMSA and WHS",
    mnz: "Maritime New Zealand",
    ws: "Work Safe",
    mnzWs: "MNZ and WS",
    mca: "MCA",
    hse: "HSE",
    miab: "MAIB",
    mcaHse: "MCA and HSE",
    mcaMiab: "MCA and MAIB",
    hseMiab: "HSE and MAIB",
    mcaHseMiab: "MCA, HSE, and MAIB",
};

export const auhoritiesNotifiedByRegion: Record<string, string[]> = {
    au: ["n", "unsure", "amsa", "whs"],
    nz: ["n", "unsure", "mnz", "ws"],
    uk: ["n", "unsure", "mca", "hse", "miab"],
    default: ["n", "unsure", "mca", "miab"],
};

export const auhoritiesNotifiedForReviewByRegion: Record<string, string[]> = {
    au: ["n", "amsa", "whs", "amsaWhs"],
    nz: ["n", "mnz", "ws", "mnzWs"],
    uk: ["n", "mca", "hse", "miab", "mcaHse", "mcaMiab", "hseMiab", "mcaHseMiab"],
    default: ["n", "mca", "hse", "miab", "mcaHse", "mcaMiab", "hseMiab", "mcaHseMiab"],
};

export const incidentTypes: Record<string, string> = {
    incident: "Incident",
    injury: "Injury",
    nearMiss: "Near Miss",
    illness: "Illness",
    event: "Event (General)",
};

export const incidentWhoInvolvedTypes: Record<string, string> = {
    employee: "Employee(s)",
    contractor: "Contractor(s)",
    trainnee: "Trainnee(s)",
    volunteer: "Volunteer(s)",
    guest: "Guest(s)",
    public: "Member(s) of the public",
};

export const injuryOutcomes: Record<string, string> = {
    none: "None",
    basicFirstAid: "Basic First Aid",
    doctor: "Doctor",
    hospital: "Hospitalisation",
};

export const injuryConclusions: Record<string, string> = {
    none: "None",
    basicFirstAid: "Basic First Aid",
    doctor: "Doctor - No lost work time injury",
    doctorLost: "Doctor - Lost work time injury",
    hospital: "Hospitalisation - No lost work time injury",
    hospitalLost: "Hospitalisation - Lost work time injury",
};

export const incidentSeverities: Record<number, string> = {
    0: "Not Set",
    10: "Low",
    20: "Medium",
    30: "High",
    40: "Severe",
    50: "Catastrophic",
};

export const yesNoNa: Record<string, string> = {
    na: "Not Applicable",
    n: "No",
    y: "Yes",
};

export const controlStrategies: Record<string, string> = {
    na: "Not Applicable",
    elimination: "Elimination",
    substitution: "Substitution",
    Isolation: "Isolation",
    engineering: "Engineering",
    admin: "Administrative",
    ppe: "PPE",
}

export const addedToRiskRegister: Record<string, string> = {
    na: "Not Applicable",
    already: "Already in risk assessments",
    y: "Yes",
    n: "No",
};

export const lostTimes: Record<string, string> = {
    "0": "None",
    "1d": "1 day",
    "2d": "2 days",
    "3d": "3 days",
    "4d": "4 days",
    "5d": "5 days",
    "6d": "6 days",
    "7d": "7 days",
    "2w": "2 weeks",
    "3w": "3 weeks",
    "4w": "4 weeks",
    "5w": "5 weeks",
    "6w": "6 weeks",
    "7w": "7 weeks",
    "8w": "8 weeks",
    "3m": "3 months",
    "4m": "4 months",
    "5m": "5 months",
    "6m": "6 months",
};

export type CurrentIncidentStatuses = keyof typeof currentIncidentStatuses;
export type AuthoritiesNotified = keyof typeof authoritiesNotified;
export type AuthoritiesNotifiedForReview = keyof typeof authoritiesNotifiedForReview;
export type AuthoritiesNotifiedForReviewLabels = keyof typeof authoritiesNotifiedForReviewLabels;
export type AuthoritiesNotifiedByRegion = keyof typeof auhoritiesNotifiedByRegion;
export type AuthoritiesNotifiedForReviewByRegion = keyof typeof auhoritiesNotifiedForReviewByRegion;
export type IncidentTypes = keyof typeof incidentTypes;
export type IncidentWhoInvolvedTypes = keyof typeof incidentWhoInvolvedTypes;
export type InjuryOutcomes = keyof typeof injuryOutcomes;
export type InjuryConclusions = keyof typeof injuryConclusions;
export type IncidentSeverities = keyof typeof incidentSeverities;
export type YesNoNa = keyof typeof yesNoNa;
export type ControlStrategies = keyof typeof controlStrategies;
export type AddedToRiskRegister = keyof typeof addedToRiskRegister;
export type LostTimes = keyof typeof lostTimes;
