import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { registerFiles, registerSignature } from "../../lib/fileSync";

//
// Loads Safety Meeting Reports
//

export interface SafetyMeetingReport extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    files: string[];
    jobIds?: string[];
    notes?: string;
    personnelPresentIds: string[];
    personnelPresentNames: string[];
    sendToCrew?: boolean;
    signature: string;
    state: string;
    type: string;
    vesselIds: string[];
    whenDeleted?: number;
    whenMeeting: number;
}

export type SafetyMeetingsData = {
    all: SafetyMeetingReport[],
    byId: {
        [reportId: string]: SafetyMeetingReport
    },
    byVesselId: {
        [vesselId: string]: SafetyMeetingReport[];
    },
    notesByVesselId: {
        [vesselId: string]: string
    }
};

export const safetyMeetingReportsConfig: SharedStateConfig<SafetyMeetingsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselIds'],
    countLiveDocs: () => sharedState.safetyMeetingReports.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselIds = sharedState.vesselIds.current;
        if (
            canView('healthSafetyMeetings') &&
            vesselIds &&
            vesselIds.length > 0
        ) {
            return setupArrayQueryListener(
                'health & safety meetings', // what
                collection(firestore, 'safetyMeetingReports'),
                [where('state', '==', 'active')], // baseConstraints
                'vesselIds',
                'array-contains-any',
                vesselIds,
                [orderBy('whenMeeting', 'desc')], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    done();
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as SafetyMeetingReport;
                    });

                    if (isCombined) { // Need to sort by whenMeeting
                        all.sort((a, b) => {
                            return b.whenMeeting - a.whenMeeting;
                        });
                    }

                    const byId = {} as {
                        [reportId: string]: SafetyMeetingReport
                    };
                    const byVesselId = {} as {
                        [vesselId: string]: SafetyMeetingReport[];
                    };
                    const notesByVesselId = {} as {
                        [vesselId: string]: string
                    };
                    all.forEach((report: SafetyMeetingReport, index: number) => {
                        registerFiles(report.files);
                        registerSignature(report.signature);
                        //let whenMeeting = formatMonthISO(report.whenMeeting)
                        //if (allByCategory[whenMeeting] === undefined) {
                        //    categories.push(whenMeeting);
                        //    allByCategory[whenMeeting] = [];
                        //}
                        //allByCategory[whenMeeting].push(report);
                        byId[report.id] = report;

                        report.vesselIds.forEach((vesselId: string) => {
                            if (byVesselId[vesselId] === undefined) {
                                byVesselId[vesselId] = [];
                                notesByVesselId[vesselId] = report.notes || '';
                            }
                            byVesselId[vesselId].push(report);
                        });
                    });

                    set({
                        all, // all data in an array
                        //allByCategory, // all data by array's of category
                        //categories,
                        byId,
                        byVesselId, // ordered arrays of reports by vesselId
                        notesByVesselId
                    });
                }, (error) => {
                    done();
                }
            );
        }
    }
};
