import React, { useEffect } from 'react';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import './SeaFormError.css';

interface SeaFormErrorProps {
    message?: string
}

const SeaFormError: React.FC<SeaFormErrorProps> = ({ message }) => {
    useEffect(() => {
        if (message) {
            alertMessage(message);
        }
    }, [message]);

    return <></>;
};

export default SeaFormError;
