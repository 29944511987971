import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { UserType } from "./user";

// 
// Loads superAdmin data based on superAdminId.
// We know the user has superAdmin access if this value is defined.
//

export type SuperAdmin = UserType;

export const superAdminConfig: SharedStateConfig<SuperAdmin> = {
    isAlwaysActive: true,
    dependencies: ['superAdminId'],
    countLiveDocs: () => sharedState.superAdmin.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const superAdminId = sharedState.superAdminId.current;
        if (superAdminId) {
            return onSnapshot(
                doc(firestore, 'users', superAdminId),
                (snap) => {
                    done();
                    if (!snap.exists()) {
                        console.log('No superAdmin matching id! '+superAdminId);
                        clear();
                    } else {
                        //console.log('got user', snapshot.docs[0].data())
                        set({
                            id: snap.id,
                            ...snap.data()
                        });
                    }
                    sharedState.userPending.set(false);
                },
                (error) => {
                    done();
                    console.log(`Error getting superAdmin ${superAdminId}`, error);
                    sharedState.userPending.set(false);
                }
            );
        }
    },
};

