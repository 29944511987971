import { sharedState, SharedStateConfig } from "../shared-state";

//
// Used to record a recent history of App activity including
// * foreground/background
// * online/offline transitions
// * File caching
// * File uploading
// * Page navigation
// * Modal opening
// * User action
// * Full size file loaded
//
// A new session starts whenever the app comes into the foreground (appState.isActive = true)
//

export type AppActivity = {
    init: number, // when started recording appActivity
    isActive: {
        [when: number]: boolean
    },
    isOnline: {
        [when: number]: boolean
    },
    fileCached: {
        [when: number]: number // blob.size
    },
    fileUploaded: {
        [when: number]: number // base64.length
    },
    fullSizeFileLoaded: {
        [when: number]: number
    },
    page: {
        [when: number]: string
    },
    modal: {
        [when: number]: string
    },
    action: {
        [when: number]: string
    }
};

export const appActivityFields = [
    'isActive',
    'isOnline',
    'fileCached',
    'fileUploaded',
    'fullSizeFileLoaded',
    'page',
    'modal',
    'action'
];

let currentState = {
    isActive: true,
    isOnline: false,
    todayMillis: 0
}

export const appActivityConfig: SharedStateConfig<AppActivity> = {
    isAlwaysActive: true,
    dependencies: ['onlineStatus', 'appState', 'todayMillis'],
    notes: `Records recent history if app activity for use in debugging/error reporting`,
    run: (done, set, clear) => {
        done();
        const appActivity = sharedState.appActivity.current;
        const appState = sharedState.appState.current!;
        const isOnline = sharedState.onlineStatus.current?.isOnline ?? false;
        const todayMillis = sharedState.todayMillis.current!;
        const now = Date.now();
        if (appActivity === undefined) {
            // Initialisation
            set({
                init: now,
                isActive: {
                    [now]: appState.isActive
                },
                isOnline: {
                    [now]: isOnline
                },
                fileCached: {},
                fileUploaded: {},
                fullSizeFileLoaded: {},
                page: {},
                modal: {},
                action: {}
            });
        } else {
            // Update appActivity for changes in appState.isActive or onlineStatus.isOnline
            const newAppActivity = { ...appActivity }
            if (isOnline !== currentState.isOnline) {
                newAppActivity.isOnline[now] = isOnline;
            }
            if (appState.isActive !== currentState.isActive) {
                newAppActivity.isActive[now] = appState.isActive;
            }
            if (todayMillis !== currentState.todayMillis) {
                // New, let's wipe data older than yesterday midnight
                limitAppActivityByTime(newAppActivity, todayMillis - (24 * 60 * 60 * 1000));
            }
            set(newAppActivity);
        }
        saveAppActivity();

        // Update currentState
        currentState.isActive = appState.isActive;
        currentState.isOnline = isOnline;
        currentState.todayMillis = todayMillis;
    }
};

const saveAppActivity = () => {
    setTimeout(() => {
        window.localStorage.setItem('__appActivity', JSON.stringify(sharedState.appActivity.current));
    });
};

export const limitAppActivityByTime = (appActivity: AppActivity, minMillis: number)=> {
    let count = 0;
    const limit = (timeData: {
        [when: number]: any
    }) => {
        Object.keys(timeData).forEach((key: any) => {
            if (key < minMillis) {
                delete timeData[key];
                count++;
            }
        });
        console.log(`Done timeData keys=${Object.keys(timeData).length}`, timeData);
    };
    appActivityFields.forEach((field) => {
        limit((appActivity as any)[field]);
    });
    console.log(`limited appActivity by removing ${count} attributes`);
    return appActivity;
};

export const appActivityToJson = (json: string, maxLength = 200000): string => {
    if (json.length > maxLength) {
        // Halve the time window
        // 1. Find current minMillis
        let minMillis = Date.now();
        let maxMillis = 0;
        const newAppActivity = JSON.parse(json);
        appActivityFields.forEach((field) => {
            Object.keys(newAppActivity[field]).forEach((key) => {
                minMillis = Math.min(minMillis, Number(key));
                maxMillis = Math.max(maxMillis, Number(key));
            });
        });
        // 2. Halve time between maxMillis and minMillis
        minMillis += (maxMillis - minMillis) / 2;
        // 3. Limit using minMillis to see to hopefully come under maxLength
        return appActivityToJson(
            JSON.stringify(
                limitAppActivityByTime(newAppActivity, minMillis)
            ),
            maxLength
        );
    }
    return json;
}

export const onFileCached = (whenStarted: number, size: number) => {
    sharedState.appActivity.set((current) => {
        const o = { ...current! };
        o.fileCached[whenStarted] = size;
        return o;
    });
    saveAppActivity();
};

export const onFileUploaded = (whenStarted: number, size: number) => {
    sharedState.appActivity.set((current) => {
        const o = { ...current! };
        o.fileUploaded[whenStarted] = size;
        return o;
    });
    saveAppActivity();
};

export const onFullSizeFileLoaded = (whenStarted: number, size: number) => {
    sharedState.appActivity.set((current) => {
        const o = { ...current! };
        o.fullSizeFileLoaded[whenStarted] = size;
        return o;
    });
    saveAppActivity();
};

export const onPageViewed = (name: string) => {
    sharedState.appActivity.set((current) => {
        if (current?.page) {
            const o = { ...current! };
            o.page[Date.now()] = name;
            return o;
        } else {
            return current;
        }
    });
    saveAppActivity();
};

export const onModalOpened = (name: string) => {
    sharedState.appActivity.set((current) => {
        if (current?.modal) {
            const o = { ...current! };
            o.modal[Date.now()] = name;
            return o;
        } else {
            return current;
        }
    });
    saveAppActivity();
};

export const onUserAction = (name: string) => {
    sharedState.appActivity.set((current) => {
        if (current?.action) {
            const o = { ...current! };
            o.action[Date.now()] = name;
            return o;
        } else {
            return current;
        }
    });
    saveAppActivity();
};
