import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, formatSeaDate, toMillis, addInterval, preventMultiTap } from '../../../../lib/util';
import { makeCategoryId, renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';

interface EditSafetyCheckProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: SafetyCheckItem,
    level?: number
}

const EditSafetyCheck: React.FC<EditSafetyCheckProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                itemId: itemToUpdate.itemId ? ''+itemToUpdate.itemId : '',
                locationId: itemToUpdate.locationId ? ''+itemToUpdate.locationId : '',
                description: itemToUpdate.description ? ''+itemToUpdate.description : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
                whenLastChecked: itemToUpdate.whenLastChecked ? formatSeaDate(itemToUpdate.whenLastChecked) : '',
                isCritical: (vesselSafetyItems?.byId[itemToUpdate.itemId]?.isCritical) ? true : false,
                categoryId: itemToUpdate.categoryId as string,
            };
        } else {
            return {
                itemId: '',
                locationId: '',
                description: '',
                interval: '',
                whenLastChecked: formatSeaDate(),
                isCritical: false,
                categoryId: vesselSafetyCheckCategories?.ids.find(id => vesselSafetyCheckCategories?.byId[id].name === 'General') ?? '',
            };
        }
    }, [itemToUpdate, vesselSafetyCheckCategories, vesselSafetyItems?.byId]);


    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            itemId: Yup.string().max(500).required(),
            locationId: Yup.string().max(500),
            description: Yup.string().max(5000),
            interval: Yup.string().max(4).required(),
            whenLastChecked: Yup.date().max(formatSeaDate()).required().min(...notTooOld),
            categoryId: Yup.string().required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('safetyCheck')) { return; }
            if (!vesselId) {
                reportError('No vesselId');
                return;
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const action = traceAction('safetyCheckItems') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'safetyCheckItems', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            itemId: makeCategoryId(
                                data.itemId,
                                vesselSafetyItems,
                                deleteValue,
                                batch,
                                'vesselSafetyItems',
                                'vesselId',
                                vesselId,
                                { 
                                    isCritical: data.isCritical ? true : false
                                }
                            ),
                            locationId: makeCategoryId(
                                data.locationId,
                                vesselLocations,
                                deleteValue,
                                batch,
                                'vesselLocations',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            categoryId: makeCategoryId(
                                data.categoryId,
                                vesselSafetyCheckCategories,
                                deleteValue,
                                batch,
                                'safetyCheckCategories',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            description: data.description ? data.description : deleteValue,
                            interval: data.interval,
                            hasFault: false,
                            whenLastChecked: toMillis(data.whenLastChecked),
                            whenDue: addInterval(data.whenLastChecked, data.interval),
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'safetyCheckItems', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'safetyCheckItems',
                        itemToUpdate.id,
                        renderCategoryName(data.itemId, vesselSafetyItems),
                        [vesselId]
                    );

                } else {
                    const newRef = doc(collection(firestore, 'safetyCheckItems'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        itemId: makeCategoryId(
                            data.itemId,
                            vesselSafetyItems,
                            undefined,
                            batch,
                            'vesselSafetyItems',
                            'vesselId',
                            vesselId,
                            { 
                                isCritical: data.isCritical ? true : false
                            }
                        ),
                        locationId: makeCategoryId(
                            data.locationId,
                            vesselLocations,
                            undefined,
                            batch,
                            'vesselLocations',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        categoryId: makeCategoryId(
                            data.categoryId,
                            vesselSafetyCheckCategories,
                            deleteValue,
                            batch,
                            'safetyCheckCategories',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        description: data.description ? data.description : undefined,
                        interval: data.interval,
                        hasFault: false,
                        whenLastChecked: toMillis(data.whenLastChecked),
                        whenDue: addInterval(data.whenLastChecked, data.interval),
                        whenAdded: action.whenAction,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'safetyCheckItems', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'safetyCheckItems',
                        newRef.id,
                        renderCategoryName(data.itemId, vesselSafetyItems),
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'safetyCheckItems');

                action.data = {
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                action.save(`${ itemToUpdate ? 'Update' : 'Add' } safety check ${renderCategoryName(data.itemId, vesselSafetyItems)}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);

            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload safety check files`, error.message, error, {
                        data,
                        files: seaFilesToValue(files),
                        itemToUpdate
                    });
                }
            });

        }
    });

    const onClosed = () => {
        setFieldValue('itemId', '');
        setFieldValue('locationId', '');
    }

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);
    
    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Safety Check' : 'Add Safety Check'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSafetyItems}
                                label="Item"
                                name="itemId"
                                initialCategoryId={initialValues.itemId}
                                categoryId={values.itemId}
                                otherPlaceholder="Add New Item"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.itemId ? errors.itemId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselLocations}
                                label="Location"
                                name="locationId"
                                initialCategoryId={initialValues.locationId}
                                categoryId={values.locationId}
                                otherPlaceholder="Add New Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.locationId ? errors.locationId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSafetyCheckCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="6" style={{paddingRight: '4px'}}>
                            <SeaCheckbox
                                mode="beside-input"
                                label="Critical Equipment"
                                name="isCritical"
                                checked={values.isCritical ? true : false}
                                setFieldValue={setFieldValue}
                                help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}
                            >
                               This equipment is critical
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Description"
                                name="description"
                                value={values.description}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.description ? errors.description : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectInterval
                                label="Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
                                disabled={itemToUpdate ? true : false}
                                label="Last Check"
                                name="whenLastChecked"
                                value={values.whenLastChecked}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenLastChecked ? errors.whenLastChecked : ''}
                            />
                        </IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="safetyCheckItems"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Safety Check' : 'Save New Safety Check'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditSafetyCheck;
