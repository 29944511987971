import { SharedStateConfig, sharedState } from "../shared-state";
import { CategoriesData, onCategoriesSnapshot } from "../../lib/categories";

//
// Loads vesselSystems
//

export const vesselSystemsConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.vesselSystems.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId) {
            return onCategoriesSnapshot(
                'vesselSystems',
                'vesselId',
                vesselId,
                (data) => { // onLoaded
                    done();
                    set(data);
                },
                (error) => { // onError
                    done();
                    clear();
                    console.log(`Error getting vesselSystems`, error);
                }
            );
        } else {
            done();
        }
    }
};
