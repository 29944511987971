import React, { useState, useEffect, useMemo, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue, formatDate, formatRiskRating, formatVessels } from '../../../lib/util';
import { permissionLevels } from '../../../lib/permissions';
import { onProfilerRender } from '../../../lib/performance';
import { sharedState } from '../../../shared-state/shared-state';
import { Hazard } from '../../../shared-state/HealthSafety/hazardRegistry_deprecated';
import EditHazard from '../../../modals/HealthSafety/HazardRegisterDeprecated/EditHazardDeprecated/EditHazardDeprecated';
import ViewHazard from '../../../modals/HealthSafety/HazardRegisterDeprecated/ViewHazardDeprecated/ViewHazardDeprecated';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import SeaStatus from '../../../components/SeaStatus/SeaStatus';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaSelectVesselFilter from '../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter';
import './HazardRegisterDeprecated.css';

interface HazardRegisterDeprecatedProps {
    visible: boolean
}

const HazardRegisterDeprecated: React.FC<HazardRegisterDeprecatedProps> = ({visible}) => {
    const hazards = sharedState.hazardRegistry.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [filterVesselId, setFilterVesselId] = useState<string>();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Hazard>();

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/HazardRegistry');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [filterVesselId, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && hazards?.byId[selectedItem.id]) { 
            setSelectedItem(hazards.byId[selectedItem.id])
        };
    }, [hazards, selectedItem?.id]);

    const isUsingFilter = useMemo(() => {
        return filterVesselId && filterVesselId !== 'all' ? true : false;
    }, [filterVesselId]);

    const filteredHazards = useMemo(() => {
        if (
            filterVesselId &&
            filterVesselId !== 'all' &&
            hazards?.byVesselId
        ) {
            return hazards.byVesselId[filterVesselId] ? hazards.byVesselId[filterVesselId] : [];
        }
        return hazards?.all;
    }, [hazards, filterVesselId]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: Hazard) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    return (
        <RequirePermissions
            role="hazardRegister"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="hazard-register page-head">
                <div><h2>Hazard Register</h2></div>
                <div className="actions">
                    <SeaSelectVesselFilter
                        filterVesselId={filterVesselId}
                        setFilterVesselId={setFilterVesselId}
                    />
                    <RequirePermissions
                        role="hazardRegister"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New / Report Hazard
                        </SeaButton>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            <SeaNoData
                dataName="hazards"
                isLoading={!hazards}
                hasNoData={filteredHazards && filteredHazards.length === 0}
                isUsingFilter={isUsingFilter}
            />

            <div className={`hazard-register has-thumbs has-status ${isUsingFilter ? 'hide-vessels' : 'show-vessels'} ${(filteredHazards?.length || 0) > 0 ? 'reveal' : 'conceal' }`}>
                <div className="sea-row headings">
                    <div></div>
                    <div>Hazard</div>
                    <div>Vessels / Facilities</div>
                    <div>Notes</div>
                    <div>Date Added</div>
                    <div>Risk Rating</div>
                </div>
                <Profiler id="healthSafety.hazards" onRender={onProfilerRender}>
                    {mapArrayWithLimit(filteredHazards, (item: Hazard) => {
                        return (
                            <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate">{item.name}</div>
                                <div className="truncate">{formatVessels(item.vesselIds, vessels)}</div>
                                <div className="truncate">{formatValue(item.riskNotes)}</div>
                                <div className="truncate">{formatValue(formatDate(item.whenAdded))}</div>
                                <div>
                                    <SeaStatus status={
                                            item.riskRating === '2' ? 'ok' :
                                            item.riskRating === '4' ? 'warn' :
                                            item.riskRating === '6' ? 'fail' :
                                            'fail'
                                    }>
                                        {formatRiskRating(item.riskRating)}
                                    </SeaStatus>
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditHazard
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={isUsingFilter ? filterVesselId : undefined}
                    />
                    {selectedItem && <ViewHazard
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </RequirePermissions>
    );
};

export default HazardRegisterDeprecated;
