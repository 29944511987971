import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { getDayOffsetMillis, warnDays } from "../../../lib/util";
import { sharedState } from "../../shared-state";
import { ReportingEquipmentByIdData } from './reportingEquipmentById';
import { ScheduledMaintenanceTask } from "../../VesselMaintenance/maintenanceSchedule";

const makeTasks = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        } as ScheduledMaintenanceTask;
    });
};
const sortTasks = (all: ScheduledMaintenanceTask[]) => {
    all.sort((a, b) => {
        return (a.whenDue || 0) - (b.whenDue || 0);
    });
};

export const useReportingMaintenanceTasks = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedTaskStatuses: string[] | undefined,
    equipmentById: ReportingEquipmentByIdData | undefined,
    equipmentCriticality: string
) => {
    const todayMillis = sharedState.todayMillis.use();
    const [reportingMaintenanceTasks, setReportingMaintenanceTasks] = useState<ScheduledMaintenanceTask[]>();

    useEffect(() => {
        if (!selectedTaskStatuses || selectedTaskStatuses.length === 0 || selectedVesselIds.length === 0) {
            // Nothing to load
            setReportingMaintenanceTasks([]);
            return;
        }
        setReportingMaintenanceTasks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length &&
            equipmentById
        ) {
            const includeOverdue = (selectedTaskStatuses.indexOf('overdue') !== -1);
            const includeUpcoming = (selectedTaskStatuses.indexOf('upcoming') !== -1);
            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            const baseConstraints = [
                where('state', '==', 'active')
            ];
            if (includeOverdue && includeUpcoming) {
                baseConstraints.push(
                    where('whenDue', '<', getDayOffsetMillis(warnDays.maintenanceSchedule[0]))
                );
            } else if (includeOverdue) { // Include overdue only
                baseConstraints.push(
                    where('whenDue', '<', todayMillis)
                );
            } else { // Include upcoming only
                baseConstraints.push(
                    where('whenDue', '<', getDayOffsetMillis(warnDays.maintenanceSchedule[0])),
                    where('whenDue', '>=', todayMillis)
                );
            }

            return setupArrayQueryListener(
                'scheduledMaintenanceTasks', // what
                collection(firestore, 'scheduledMaintenanceTasks'),
                baseConstraints,
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('whenDue', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    let all = makeTasks(docs);

                    // Filter by criticality
                    if (equipmentCriticality === 'critical') {
                        all = all.filter((item) => equipmentById[item.equipmentId]?.isCritical);
                    } else if (equipmentCriticality === 'nonCritical') {
                        all = all.filter((item) => !equipmentById[item.equipmentId]?.isCritical);
                    }

                    if (isCombined) {
                        sortTasks(all);
                    }
                    setReportingMaintenanceTasks(all);
                }
            );

        }
    }, [isActive, todayMillis, selectedVesselIds, selectedTaskStatuses, equipmentById, equipmentCriticality]);

    return reportingMaintenanceTasks;

};
