import React, { useState, useRef, useEffect } from 'react';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaInput from '../SeaInput/SeaInput';
import { IonSelectOption } from '@ionic/react';
import './SeaSelectOrOther.css';

//
// Like a SeaSelect but allows you to enter a new value not found in the current list
//
// Notes:
//   Uses the SeaInput as the main input that gets controlled by the parent and is the holder of the real value
//   It is hidden from view while the SeaSelect is active
//   Any changes to SeaSelect are propagated to SeaInput
//

interface SeaSelectOrOtherProps {
    options: string[], // List of possible options
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    value?: string,
    selectedText?: string,
    placeholder?: string,
    otherPlaceholder?: string,
    error?: string,
    disabled?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
}

const SeaSelectOrOther: React.FC<SeaSelectOrOtherProps> = ({
    options,
    zone,
    name,
    label,
    value,
    selectedText,
    placeholder,
    otherPlaceholder,
    error,
    disabled,
    onchange,
    onblur,
    onfocus
}) => {
    const [selectValue, setSelectValue] = useState(value);
    const [showInput, setShowInput] = useState(false);
    const [orphanValue, setOrhpanValue] = useState<string>(); // A value that no longer exists in the list of options (This can occur if the user has deleted an item from a <SeaInputList>)
    const customRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!showInput) {
            // Fresh value must be coming from parent
            if (
                value &&
                (options === undefined || options.indexOf(value) === -1)
            ) {
                // Not in options, so let's create a special option just for this value
                setOrhpanValue(value);
            }
            setSelectValue(value);
        }
    }, [value, options]);

    const onSelectChange = (e: CustomEvent) => {
        setSelectValue(e.detail.value);
        let value = e.detail.value;
        if (value === '_new') {
            setShowInput(true);
            if (customRef.current) {
                (customRef.current as any).focus();
            }
            value = '';
        } else {
            setShowInput(false);
        }
        const input = (customRef.current as any).inputRef.current;
        input.value = value;
        const event = new Event("input", { bubbles: true });
        input.dispatchEvent(event);
    }

    return (
        <>
            <SeaSelect
                //ref={forwardedRef}
                zone={zone}
                name={`${name}_select`}
                label={label}
                disabled={disabled}
                value={selectValue}
                placeholder={placeholder}
                selectedText={selectedText}
                onchange={onSelectChange}
                //onblur={onblur}
                //onfocus={onfocus}
                warn={error ? true : false}
            >
                <IonSelectOption value="">Not Set</IonSelectOption>
                {orphanValue &&
                    <IonSelectOption value={orphanValue}>{orphanValue}</IonSelectOption>
                }
                {options?.sort().map((option) => {
                    return (<IonSelectOption key={`${name}-${option}`} value={option}>{option}</IonSelectOption>);
                })}
                <IonSelectOption value="_new">Add New...</IonSelectOption>
            </SeaSelect>
            <div className={`sea-select-input ${showInput ? 'show' : ''}`}>
                <div style={{ height: '4px' }}></div>
                <SeaInput
                    ref={customRef}
                    name={name}
                    placeholder={otherPlaceholder}
                    value={value}
                    //onchange={onInputChange}
                    //onblur={onInputBlur}

                    onchange={onchange}
                    onblur={onblur}
                    onfocus={onfocus}

                    zone={zone}
                    type="text"
                    inputmode="text"
                    warn={error ? true : false}
                />
            </div>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaSelectOrOther;
