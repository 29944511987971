import * as React from "react";

function SvgSafety(props) {
  return (
    <svg
      width={34}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.81 22.744a11.525 11.525 0 000-11.483 11.588 11.588 0 00-4.2-4.209A11.52 11.52 0 0011.11 7.03a11.584 11.584 0 00-4.222 4.209 11.517 11.517 0 00-.001 11.525 11.51 11.51 0 0019.924-.02zm-6.368-.436a6.438 6.438 0 001.723-1.732l3.645 2.104a10.653 10.653 0 01-3.263 3.273l-2.105-3.645zm-6.345-.513a5.587 5.587 0 01-2.032-2.022 5.515 5.515 0 112.032 2.022zM7.89 11.306a10.665 10.665 0 013.284-3.274l2.105 3.644a6.442 6.442 0 00-1.744 1.735L7.89 11.306zm18.364 10.603l-3.644-2.104a6.424 6.424 0 000-5.605l3.644-2.103a10.636 10.636 0 010 9.812zm-.444-10.584l-3.644 2.103a6.498 6.498 0 00-1.723-1.732l2.104-3.642a10.677 10.677 0 013.262 3.272v-.002zm-4.032-3.72l-2.103 3.643a6.425 6.425 0 00-5.627-.018l-2.103-3.643a10.632 10.632 0 019.833.02v-.001zm-14.335 4.47l3.644 2.104a6.422 6.422 0 000 5.646L7.443 21.93a10.628 10.628 0 010-9.853zM7.89 22.7l3.643-2.104a6.444 6.444 0 001.743 1.735l-2.103 3.643a10.644 10.644 0 01-3.284-3.274h0zm4.054 3.717l2.103-3.643a6.424 6.424 0 005.627-.018l2.103 3.642a10.632 10.632 0 01-9.832.02h-.001z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgSafety;
