import * as React from "react";

function SvgDuck(props) {
  return (
    <svg
      width={48}
      height={38}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.477 18.816c5.742 0 11.813-4.676 11.813-10.5h-8.04c-.081-.246-.081-.492-.081-.739-.739-3.363-3.446-6.07-6.727-6.808-.82-.164-1.559-.328-2.215-.328-5.086 0-9.187 4.183-9.187 9.187v9.188h-5.004c-2.133 0-4.102-.82-5.743-2.051-.41-.328-.902-.574-1.476-.574-.246 0-.574.082-.82.246-.903.328-1.395 1.148-1.395 2.05v.329H2.665c-1.641 0-2.872 1.476-2.625 3.035 1.394 8.695 9.023 15.34 18.129 15.34h8.203c5.578 0 10.582-4.512 10.336-10.172-.082-2.379-1.067-4.594-2.543-6.316v-1.887h1.312zm3.774-7.875h4.921c-.984 2.707-4.593 4.758-7.464 5.168 1.312-1.313 2.296-3.118 2.543-5.168zm-7.055 11.566c1.148 1.313 1.887 2.953 1.887 4.676.082 1.805-.575 3.527-1.97 4.922-1.476 1.64-3.609 2.46-5.741 2.46h-8.203c-7.793 0-14.274-5.66-15.504-13.124H6.93c1.395 5.332 6.07 9.187 11.813 9.187h1.64a.647.647 0 00.657-.656v-1.313c0-.328-.329-.656-.657-.656h-1.64c-5.004 0-9.106-3.855-9.516-8.695 1.723 1.477 4.02 2.133 6.809 2.133h7.629V9.628a6.53 6.53 0 016.562-6.562c3.61 0 6.563 2.953 6.563 6.562 0 2.79-1.805 5.168-4.184 6.153-1.066.492-.984.41-1.066.492v5.496l.656.738zM30.227 8.316c-.738 0-1.312.656-1.312 1.312 0 .739.574 1.313 1.312 1.313.656 0 1.313-.575 1.313-1.313 0-.656-.657-1.312-1.313-1.312z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDuck;
