import React, { useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { refreshApp } from '../../../App';
import SeaButton from '../../../components/SeaButton/SeaButton';
import './PleaseWait.css';

interface PleaseWaitProps {
    message: string
}

const PleaseWait: React.FC<PleaseWaitProps> = ({
    message
}) => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 10000); // Set timeout for 10 seconds
        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    const handleReload = () => {
        refreshApp('Restart pressed after authentication took longer than 10s')
    };

    return (
        <div className="cbl-bg please-wait container">
            <div>
                <div style={{ paddingLeft: '8px' }}>
                    <IonSpinner name="crescent" className="sea-spinner" style={{ opacity: 0.5, position: 'relative', top: '-16px', left: '12px' }}/>
                </div>
                <div style={{ fontSize: '18px', textAlign: 'center' }}>
                    <p>{message}</p>
                </div>
                {showButton && (message === 'Authenticating...') && (
                    <div style={{ textAlign: 'center' }}>
                        <p>Authentication is taking longer than expected...</p>
                        <SeaButton onClick={handleReload}>Restart Sea Flux</SeaButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PleaseWait;
