import * as React from "react";

function SvgPeople(props) {
  return (
    <svg
      width={53}
      height={38}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.04 16.19a6.53 6.53 0 006.562-6.562c0-3.61-2.953-6.562-6.562-6.562a6.53 6.53 0 00-6.563 6.562c0 3.692 2.871 6.563 6.563 6.563zm0-10.5c2.132 0 3.937 1.805 3.937 3.938 0 2.215-1.805 3.938-3.937 3.938-2.215 0-3.938-1.723-3.938-3.938 0-2.133 1.723-3.937 3.938-3.937zM26.665 18.817c5.004 0 9.187-4.102 9.187-9.188 0-5.004-4.183-9.187-9.187-9.187-5.086 0-9.188 4.183-9.188 9.187a9.172 9.172 0 009.188 9.188zm0-15.75c3.609 0 6.562 2.953 6.562 6.562a6.53 6.53 0 01-6.562 6.563c-3.692 0-6.563-2.871-6.563-6.563a6.53 6.53 0 016.563-6.562zm20.015 15.75H43.4c-1.313 0-2.461.41-3.445 1.066.82.574 1.476 1.149 2.133 1.887.41-.164.82-.328 1.312-.328h3.281c1.969 0 3.61 1.805 3.61 3.937 0 .738.574 1.313 1.312 1.313.656 0 1.313-.575 1.313-1.313 0-3.61-2.871-6.562-6.235-6.562zM8.29 16.19a6.53 6.53 0 006.562-6.563c0-3.61-2.953-6.562-6.562-6.562a6.53 6.53 0 00-6.563 6.562c0 3.692 2.871 6.563 6.563 6.563zm0-10.5c2.132 0 3.937 1.804 3.937 3.937 0 2.215-1.805 3.938-3.937 3.938-2.215 0-3.938-1.723-3.938-3.938 0-2.133 1.723-3.937 3.938-3.937zm24.937 14.765c-2.789 0-3.445.985-6.562.985-3.2 0-3.856-.985-6.645-.985-2.953 0-5.824 1.395-7.547 3.856a9.901 9.901 0 00-1.558 5.332v3.61c0 2.214 1.722 3.937 3.937 3.937h23.625c2.133 0 3.938-1.723 3.938-3.938v-3.61c0-1.968-.657-3.773-1.641-5.331-1.723-2.461-4.594-3.856-7.547-3.856zm6.563 12.797c0 .739-.657 1.313-1.313 1.313H14.852a1.296 1.296 0 01-1.312-1.313v-3.61c0-1.394.328-2.706 1.148-3.855 1.066-1.64 3.117-2.707 5.332-2.707 2.297 0 3.117.985 6.645.985 3.527 0 4.265-.985 6.562-.985 2.215 0 4.266 1.067 5.332 2.707.82 1.149 1.149 2.461 1.149 3.856v3.61h.082zm-26.496-13.37c-.985-.657-2.133-1.067-3.364-1.067H6.65c-3.445 0-6.234 2.953-6.234 6.562 0 .738.574 1.313 1.312 1.313.656 0 1.313-.575 1.313-1.313 0-2.133 1.558-3.937 3.609-3.937H9.93c.41 0 .82.164 1.23.328a11.712 11.712 0 012.133-1.887z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPeople;
