import { IonIcon } from '@ionic/react';
import React from 'react';
import {
    settingsSharp,
    caretUp,
    caretDown,
    caretForward,
    caretBack,
    add,
    close,
    checkmarkOutline,
    createOutline,
    // documentSharp,
    documentTextSharp,
    documentTextOutline,
    camera,
    chevronBack,
    chevronForward,
    mailOutline,
    logOutOutline,
    imageOutline,
    personOutline,
    cloudOfflineOutline,
    cloudDownloadOutline,
    cloudUploadOutline,
    constructOutline,
    refreshOutline,
    syncOutline,
    trashOutline,
    archiveOutline,
    arrowUndoOutline,
    informationCircle,
    downloadOutline,
    shareOutline,
    openOutline,
    clipboardOutline,
    readerOutline,
    alert,
    alertCircle,
    warning,
    build,
    helpCircle,
    serverOutline,
    triangle,
    receiptOutline,
    menuOutline,
    timerOutline,
    timeOutline,
    arrowUpOutline,
    navigateOutline,
    navigate,
    flag,
} from 'ionicons/icons';
import './SeaIcon.css';

interface SeaIconProps {
    slot?: 'icon-only' | 'start' | 'end',
    icon: 'moveUp' | 'moveDown' | 'moveRight' | 'moveLeft' | 'settings' | 'add' | 'close' | 'tick' | 'edit' | 'pdf' | 'document' | 'photo' | 'left' | 'right' | 'trash' | 'mail' | 'image' | 'logout' | 'profile' | 'offline' | 'cloudDownload' | 'cloudUpload' | 'refresh' | 'sync' | 'archive' | "undo" | 'info' | 'download' | 'open' | 'share' | 'clipboard' | 'notes' | 'alert' | 'warn' | 'build' | 'help' | 'data' | 'triangle' | 'menu' | 'log' | '!'| 'timer' | 'clock' | 'windArrow' | 'waveArrow' | 'navigate' | 'flag' | 'maintenance',
    marginTop?: number,
    marginBottom?: number,
    forceFontSize?: string
}

export type SeaIconOptions = SeaIconProps['icon'];

const SeaIcon: React.FC<SeaIconProps> = ({ slot, icon, marginTop, marginBottom, forceFontSize }) => {
    let fontSize = '12px';
    let obj = documentTextSharp;
    let isPDF = false;
    const originalMarginBottom = marginBottom;
    switch (icon) {
        case 'settings':
            obj = settingsSharp;
            fontSize = '20px';
            break;
        case 'moveUp':
            obj = caretUp;
            fontSize = '120%';
            marginBottom = -2;
            break;
        case 'moveDown':
            obj = caretDown;
            fontSize = '120%';
            marginBottom = -2;
            break;
        case 'moveRight':
            obj = caretForward;
            fontSize = '120%';
            marginBottom = -2;
            break;
        case 'moveLeft':
            obj = caretBack;
            fontSize = '120%';
            marginBottom = -2;
            break;
        case 'add':
            obj = add;
            fontSize = '25px';
            marginBottom = 1;
            break;
        case 'close':
            obj = close;
            fontSize = '36px';
            break;
        case 'tick':
            obj = checkmarkOutline;
            fontSize = '18px';
            marginBottom = 0;
            break;
        case 'edit':
            obj = createOutline;
            fontSize = '19px';
            break;
        case 'pdf':
            isPDF = true;
            // obj = documentSharp;
            // fontSize = '18px';
            break;
        case 'document':
            obj = documentTextOutline;
            fontSize = '18px';
            break;
        case 'photo':
            obj = camera;
            fontSize = '20px';
            break;
        case 'left':
            obj = chevronBack;
            fontSize = '20px';
            break;
        case 'right':
            obj = chevronForward;
            fontSize = '20px';
            break;
        case 'trash':
            obj = trashOutline;
            fontSize = '20px';
            break;
        case 'mail':
            obj = mailOutline;
            fontSize = '20px';
            break;
        case 'logout':
            obj = logOutOutline;
            fontSize = '30px';
            marginBottom = -4;
            break;
        case 'image':
            obj = imageOutline;
            fontSize = '20px';
            break;
        case 'profile':
            obj = personOutline;
            fontSize = '24px';
            break;
        case 'offline':
            obj = cloudOfflineOutline;
            fontSize = '22px';
            break;
        case 'cloudDownload':
            obj = cloudDownloadOutline;
            fontSize = '24px';
            break;
        case 'cloudUpload':
            obj = cloudUploadOutline;
            fontSize = '24px';
            break;
        case 'maintenance':
            obj = constructOutline;
            fontSize = '24px';
            break;
        case 'refresh':
            obj = refreshOutline;
            fontSize = '25px';
            break;
        case 'sync':
            obj = syncOutline;
            fontSize = '24px';
            break;
        case 'archive':
            obj = archiveOutline;
            fontSize = '22px';
            break;
        case "undo":
            obj = arrowUndoOutline;
            fontSize = '20px';
            break;
        case 'info':
            obj = informationCircle;
            fontSize = '22px';
            marginBottom = -5;
            break;
        case 'download':
            obj = downloadOutline;
            fontSize = '22px';
            break;
        case 'open':
            obj = openOutline;
            fontSize = '22px';
            break;
        case 'share':
            obj = shareOutline;
            fontSize = '22px';
            break;
        case 'clipboard':
            obj = clipboardOutline;
            fontSize = '22px';
            break;
        case 'notes':
            obj = readerOutline;
            fontSize = '20px';
            break;
        case 'alert':
            obj = alertCircle;
            fontSize = '24px';
            break;
        case 'warn':
            obj = warning;
            fontSize = '24px';
            marginBottom = -5;
            break;
        case 'build':
            obj = build;
            fontSize = '24px';
            marginBottom = 2;
            break;
        case 'help':
            obj = helpCircle;
            fontSize = '24px';
            //marginBottom = 2;
            break;
        case 'data':
            obj = serverOutline;
            fontSize = '24px';
            break;
        case 'triangle':
            obj = triangle;
            fontSize = '24px';
            break;
        case 'menu':
            obj = menuOutline;
            fontSize = '30px';
            break;
        case 'log':
            obj = receiptOutline;
            fontSize = '24px';
            break;
        case '!':
            obj = alert;
            fontSize = '24px';
            break;
        case 'timer':
            obj = timerOutline;
            fontSize = '24px';
            break;
        case 'clock':
            obj = timeOutline;
            fontSize = '30px';
            break;
        case 'windArrow':
            obj = arrowUpOutline;
            fontSize = '17px';
            break;
        case 'waveArrow':
            obj = navigateOutline;
            fontSize = '14px';
            break;
        case 'navigate':
            obj = navigate;
            fontSize = '28px';
            break;
        case 'flag':
            obj = flag;
            fontSize = '22px';
            marginBottom = -2;
            break;
    }
    if (forceFontSize) {
        fontSize = forceFontSize;
    }
    if (originalMarginBottom !== undefined) {
        marginBottom = originalMarginBottom;
    }

    return (
        <>
            {isPDF 
            ? 
                <svg // Custom PDF Icon
                    width={15}
                    height={19}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M7.06054 9.5387C7.27148 9.18713 7.30663 7.88635 6.99023 7.88635C6.91991 7.88635 6.88476 8.9762 7.06054 9.5387ZM7.02538 11.191C6.74413 11.8942 6.39257 12.7028 6.00585 13.3707C6.67382 13.1246 7.37695 12.7731 8.2207 12.6324C7.79882 12.2809 7.34179 11.7887 7.02538 11.191ZM3.7207 15.5856C3.7207 15.5856 4.17773 15.3746 4.95116 14.1442C4.70507 14.3903 3.89648 15.0231 3.7207 15.5856ZM9.41601 6.12854C8.92382 6.12854 8.57226 5.77698 8.57226 5.28479V0.50354H1.54101C1.04882 0.50354 0.697258 0.890259 0.697258 1.34729V17.6598C0.697258 18.152 1.04882 18.5035 1.54101 18.5035H13.3535C13.8105 18.5035 14.1973 18.152 14.1973 17.6598V6.12854H9.41601ZM9.13476 12.1754C9.80273 12.1051 11.209 11.9645 11.7012 12.4215C12.123 12.8434 12.0527 13.9684 10.998 13.9684C10.4355 13.9684 9.52148 13.6871 8.74804 13.2653C7.90429 13.4059 6.88476 13.7926 5.9707 14.109C5.09179 15.6207 4.45898 16.2535 3.82616 16.2535C3.65038 16.2535 3.26366 16.1481 3.08788 15.902C2.41991 15.0934 4.0371 14.0035 4.98632 13.5114C4.98632 13.5114 4.98632 13.5114 5.02148 13.5114C5.44335 12.7731 6.04101 11.4723 6.42773 10.4879C6.14648 9.36292 5.9707 8.44885 6.14648 7.78088C6.35741 7.11292 7.6582 7.00745 7.83398 8.02698C8.00976 8.65979 7.76366 9.64417 7.62304 10.277C7.93945 11.1559 8.43163 11.7535 9.13476 12.1754ZM13.9512 4.19495L10.5059 0.749634C10.3301 0.609009 10.1191 0.50354 9.9082 0.50354H9.69726V5.00354H14.1973V4.7926C14.1973 4.58167 14.0918 4.37073 13.9512 4.19495ZM11.3144 13.1949C11.4902 13.0895 11.2441 12.7731 9.83788 12.8785C11.1387 13.441 11.3144 13.1949 11.3144 13.1949Z" fill="currentColor" />
                </svg>
            : 
                <IonIcon class="sea-icon" slot={slot} icon={obj} style={{
                    fontSize: fontSize,
                    marginTop: marginTop ? marginTop : 'unset',
                    marginBottom: marginBottom ? marginBottom : 'unset'
                }}/>
            }
        </>
    );
};

export default SeaIcon;
