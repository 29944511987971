import React, { useState, useRef, useCallback } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc } from "firebase/firestore";
import { CategoriesData, haveCategoriesChanged, makeCategoryCustomDataForEditing, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import { CustomFields } from '../../../../shared-state/VesselLogbook/voyages';

interface EditSafetyEquipmentCheckSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    safetyCheckCategories?: CategoriesData
}

const EditSafetyEquipmentCheckSettings: React.FC<EditSafetyEquipmentCheckSettingsProps> = ({showModal, setShowModal, safetyCheckCategories}) => {
    const userId = sharedState.userId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const formRef = useRef<HTMLFormElement>(null);
    const [tab, setTab] = useState("safetyItems");
    const [safetyItemIds, setSafetyItemIds] = useState<string[]>();
    const [safetyItemNames, setSafetyItemNames] = useState<string[]>();
    const [safetyItemCustomData, setSafetyItemCustomData] = useState<CustomFields[]>();
    const [locationIds, setLocationIds] = useState<string[]>();
    const [locationNames, setLocationNames] = useState<string[]>();
    const [categoryIds, setCategoryIds] = useState<string[]>();
    const [categoryNames, setCategoryNames] = useState<string[]>();

    const onOpened = () => {
        if (vesselSafetyItems) {
            setSafetyItemIds(makeCategoryIdsForEditing(vesselSafetyItems));
            setSafetyItemNames(makeCategoryNamesForEditing(vesselSafetyItems));
            setSafetyItemCustomData(makeCategoryCustomDataForEditing(vesselSafetyItems));
        }
        if (vesselLocations) {
            setLocationIds(makeCategoryIdsForEditing(vesselLocations));
            setLocationNames(makeCategoryNamesForEditing(vesselLocations));
        }
        if (safetyCheckCategories) {
            setCategoryIds(makeCategoryIdsForEditing(safetyCheckCategories));
            setCategoryNames(makeCategoryNamesForEditing(safetyCheckCategories));
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!vesselId) {
            throw new Error("No vesselId");
        }
        const action = traceAction('vessels', 'update') as Action;
        action.docId = vesselId;
        const batch = splittableBatch(firestore, 20 - 0);
        const vesselRef = vessel ?
            doc(firestore, 'vessels', vesselId as string)
            : doc(collection(firestore, 'vessels'));
        action.docId = vesselRef.id;

        batch.set(
            doc(firestore, 'vessels', vesselId),
            {
                updatedBy: userId,
                whenUpdated: action.whenAction
            },
            { merge: true }
        );
        
        // Save vesselSafetyItems
        saveCategoryChanges(
            batch,
            safetyItemIds,
            safetyItemNames,
            safetyItemCustomData,
            vesselSafetyItems,
            'vesselSafetyItems',
            'vesselId',
            vesselRef.id,
            action
        );
        // Save vesselLocations
        saveCategoryChanges(
            batch,
            locationIds,
            locationNames,
            undefined,
            vesselLocations,
            'vesselLocations',
            'vesselId',
            vesselRef.id,
            action
        );

        // Save safetyCheckCategories
        saveCategoryChanges(
            batch,
            categoryIds,
            categoryNames,
            undefined,
            safetyCheckCategories,
            'safetyCheckCategories',
            'vesselId',
            vesselRef.id,
            action
        );

        action.data = {
            vessel,
            safetyItemIds,
            safetyItemNames,
            safetyItemCustomData,
            locationIds,
            locationNames,
        };

        action.save(`Update vessel ${vessel?.name ?? ''} settings`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error: any) => {
            action.reportError(error.message, error);
        });
        setTab("safetyItems");
        setShowModal(false);
        showToast(`Safety Check settings updated`);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(safetyItemIds, safetyItemNames, vesselSafetyItems, safetyItemCustomData) ||
        haveCategoriesChanged(locationIds, locationNames, vesselLocations)
    }, [locationIds, locationNames, safetyItemCustomData, safetyItemIds, safetyItemNames, vesselLocations, vesselSafetyItems]);

    return (
        <SeaModal
            title="Safety Check Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={() => {setTab("safetyItems")} }
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="safetyItems" mode="forms">Safety Items</SeaTab>
                    <SeaTab tab="locationItems" mode="forms">Locations</SeaTab>
                    <SeaTab tab="safetyCheckCategories" mode="forms">Categories</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Safety Check Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="safetyItems" selectedTab={tab}>
                    <SeaInputList
                        name="safetyItems"
                        label="Safety Items"
                        maxWidth="500px"
                        ids={safetyItemIds}
                        setIds={setSafetyItemIds}
                        values={safetyItemNames}
                        setValues={setSafetyItemNames}
                        customFields={[{ columnName: 'Critical', fieldName: 'isCritical', fieldType: 'boolean', fieldInputType: 'checkbox' }]}
                        customData={safetyItemCustomData}
                        setCustomData={setSafetyItemCustomData}
                        addNewText="Add safety item"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="locationItems" selectedTab={tab}>
                    <SeaInputList
                        name="locationItems"
                        label="Locations"
                        maxWidth="500px"
                        ids={locationIds}
                        setIds={setLocationIds}
                        values={locationNames}
                        setValues={setLocationNames}
                        addNewText="Add location"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="safetyCheckCategories" selectedTab={tab}>
                <SeaInputList
                                name="safetyCheckCategories"
                                label="Safety Check Categories"
                                maxWidth="350px"
                                ids={categoryIds}
                                setIds={setCategoryIds}
                                values={categoryNames}
                                setValues={setCategoryNames}
                                addNewText="Add Category"
                                confirmDelete={true}
                            />
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditSafetyEquipmentCheckSettings;
