import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {INSERT_TABLE_COMMAND} from '@lexical/table';
import {
    $createNodeSelection,
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $setSelection,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    EditorThemeClasses,
    Klass,
    LexicalCommand,
    LexicalEditor,
    LexicalNode,
} from 'lexical';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
// import invariant from 'shared/invariant';

import {$createTableNodeWithDimensions, TableNode} from '../../nodes/TableNode/TableNode';
// import Button from '../ui/Button';
// import {DialogActions} from '../ui/Dialog';
// import TextInput from '../ui/TextInput';

export type CellContextShape = {
    cellEditorConfig: null | CellEditorConfig;
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
    set: (
        cellEditorConfig: null | CellEditorConfig,
        cellEditorPlugins: null | JSX.Element | Array<JSX.Element>,
    ) => void;
};
    
export type CellEditorConfig = Readonly<{
    namespace: string;
    nodes?: ReadonlyArray<Klass<LexicalNode>>;
    onError: (error: Error, editor: LexicalEditor) => void;
    readOnly?: boolean;
    theme?: EditorThemeClasses;
}>;
    
export const CellContext = createContext<CellContextShape>({
    cellEditorConfig: null,
    cellEditorPlugins: null,
    set: () => {
        // Empty
    },
});
    
export function TableContext({children}: {children: JSX.Element}) {
    const [contextValue, setContextValue] = useState<{
        cellEditorConfig: null | CellEditorConfig;
        cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
    }>({
        cellEditorConfig: null,
        cellEditorPlugins: null,
    });
    return (
        <CellContext.Provider
        value={useMemo(() => ({
                cellEditorConfig: contextValue.cellEditorConfig,
                cellEditorPlugins: contextValue.cellEditorPlugins,
                set: (cellEditorConfig, cellEditorPlugins) => {
                    setContextValue({cellEditorConfig, cellEditorPlugins});
                },
            }), [contextValue.cellEditorConfig, contextValue.cellEditorPlugins]
        )}>
            {children}
        </CellContext.Provider>
    );
}
            
export function TablePlugin({
    cellEditorConfig,
    children,
}: {
    cellEditorConfig: CellEditorConfig;
    children: JSX.Element | Array<JSX.Element>;
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    const cellContext = useContext(CellContext);
    
    useEffect(() => {
        if (!editor.hasNodes([TableNode])) {
            console.error('TablePlugin: TableNode is not registered on editor');
        }
        
        cellContext.set(cellEditorConfig, children);
    }, [cellContext, cellEditorConfig, children, editor]);
    
    return null;
}
            