import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { getDayOffsetMillis, warnDays } from "../../../lib/util";
import { sharedState } from "../../shared-state";
import { makeSafetyItems, sortSafetyItems } from "./reportingSafetyFunctions";
import { ReportingSafetyItemById } from './reportingSafetyItemsById';
import { SafetyEquipmentItem } from '../../VesselSafety/safetyEquipmentItems';

export const useReportingSafetyEquipments = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedSafetyEquipmentStatuses: string[],
    vesselSafetyItemsById: ReportingSafetyItemById | undefined,
    safetyCriticality: string
) => {
    const todayMillis = sharedState.todayMillis.use();
    const [reportingSafetyEquipment, setReportingSafetyEquipment] = useState<SafetyEquipmentItem[]>();

    useEffect(() => {
        if (selectedSafetyEquipmentStatuses.length === 0 || selectedVesselIds.length === 0) {
            // Nothing to load
            setReportingSafetyEquipment([]);
            return;
        }
        setReportingSafetyEquipment(undefined);
        if (
            isActive &&
            selectedVesselIds?.length
        ) {
            const includeOverdue = (selectedSafetyEquipmentStatuses.indexOf('overdue') !== -1);
            const includeUpcoming = (selectedSafetyEquipmentStatuses.indexOf('upcoming') !== -1);
            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            const baseConstraints = [
                where('state', '==', 'active')
            ];
            if (includeOverdue && includeUpcoming) {
                baseConstraints.push(
                    where('whenDue', '<', getDayOffsetMillis(warnDays.safetyEquipmentExpiries[0]))
                );
            } else if (includeOverdue) { // Include overdue only
                baseConstraints.push(
                    where('whenDue', '<', todayMillis)
                );
            } else { // Include upcoming only
                baseConstraints.push(
                    where('whenDue', '<', getDayOffsetMillis(warnDays.safetyEquipmentExpiries[0])),
                    where('whenDue', '>=', todayMillis)
                );
            }

            return setupArrayQueryListener(
                'safetyEquipmentItems', // what
                collection(firestore, 'safetyEquipmentItems'),
                baseConstraints,
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('whenDue', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    let all = makeSafetyItems(docs) as SafetyEquipmentItem[];

                    // Filter by criticality
                    if (vesselSafetyItemsById) {
                        if (safetyCriticality === 'critical') {
                            all = all.filter((item) => vesselSafetyItemsById[item.itemId]?.isCritical);
                        } else if (safetyCriticality === 'nonCritical') {
                            all = all.filter((item) => !vesselSafetyItemsById[item.itemId]?.isCritical)
                        }
                    }

                    if (isCombined) {
                        sortSafetyItems(all);
                    }
                    setReportingSafetyEquipment(all);
                }
            );

        }
    }, [isActive, todayMillis, selectedVesselIds, selectedSafetyEquipmentStatuses, vesselSafetyItemsById, safetyCriticality]);

    return reportingSafetyEquipment;

};
