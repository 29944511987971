import { Timestamp, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { cachedDataInfo } from "./cachedDataInfo";
import { LicenseeDataSyncCollection, onFreshLicenseeData } from "./dataSyncTasks";

//
// Loads whenLicenseeTouched.
// Used to trigger updates to the local cache if we find out there's something new on the server which we are not already actively listening to
//

export type WhenLicenseeTouchedBase = {
    id: string;
    touched: Timestamp;
    licenseeId: string;
    count: number;
};

export type WhenVesselTouchedCollections = {
    [K in LicenseeDataSyncCollection]: Timestamp;
};

export type WhenLicenseeTouched = WhenLicenseeTouchedBase & WhenVesselTouchedCollections;

export type WhenLicenseeTouchedData = WhenLicenseeTouched;
// {
//     [Property in ('touched' | LicenseeDataSyncCollection)]?: Timestamp;
// };
// Note: had to remove this due to a "circular reference"

export const whenLicenseeTouchedConfig: SharedStateConfig<WhenLicenseeTouchedData> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId', 'isDataSyncActive'],
    countLiveDocs: () => sharedState.whenLicenseeTouched.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const isDataSyncActive = sharedState.isDataSyncActive.current;
        if (licenseeId && isDataSyncActive) {
            return onSnapshot(
                doc(firestore, 'whenLicenseeTouched', licenseeId),
                (snap) => {
                    done();
                    const whenLicenseeTouched = {
                        id: snap.id,
                        ...snap.data()
                    } as WhenLicenseeTouched;
                    set(whenLicenseeTouched);
                    if (whenLicenseeTouched.touched) {
                        const props = Object.getOwnPropertyNames(whenLicenseeTouched);
                        for (var i = 0; i < props.length; i++) {
                            const prop = props[i];
                            if (prop === 'id' || prop === 'touched') {
                                continue;
                            }
                            if (
                                cachedDataInfo.licensee[prop as LicenseeDataSyncCollection] &&
                                cachedDataInfo.licensee[prop as LicenseeDataSyncCollection] as number >= (whenLicenseeTouched[prop as LicenseeDataSyncCollection] as Timestamp).toMillis()
                            ) {
                                // We already have the latest data.
                                // Therefore we wont need to cache anything.
                            } else {
                                // cachedDataInfo.licensee[prop] is behind
                                onFreshLicenseeData(
                                    prop as LicenseeDataSyncCollection,
                                    (whenLicenseeTouched[prop as LicenseeDataSyncCollection] as Timestamp).toMillis()
                                );
                            }
                        }
                    }
                }
            );
        } else {
            done(); // Not sure about this...
        }
    },
    notes: 'Indicates when something was last touched on the server for various licensee collections'
};
