import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { SafetyCheckCompleted } from '../../VesselSafety/completedSafetyCheckItems';
import { ReportingSafetyItemById } from './reportingSafetyItemsById';

export const useReportingCompletedSafetyChecks = (
    isActive: boolean,
    selectedVesselIds: string[],
    completedSafetyChecks: {
        [id: string]: number;
    } | undefined,
    vesselSafetyItemsById: ReportingSafetyItemById | undefined,
    safetyCriticality: string
) => {
    const [reportingCompletedSafetyChecks, setReportingCompletedSafetyChecks] = useState<SafetyCheckCompleted[]>();

    useEffect(() => {
        setReportingCompletedSafetyChecks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length &&
            completedSafetyChecks &&
            vesselSafetyItemsById
        ) {

            let isCriticalityOk = (item: SafetyCheckCompleted) => true;
            if (safetyCriticality === 'critical') {
                isCriticalityOk = (item: SafetyCheckCompleted) => {
                    return vesselSafetyItemsById[item.itemId]?.isCritical ? true : false
                };
            } else if (safetyCriticality === 'nonCritical') {
                isCriticalityOk = (item: SafetyCheckCompleted) => {
                    return !(vesselSafetyItemsById[item.itemId]?.isCritical)
                };
            }

            return setupArrayQueryListener(
                'safetyCheckItems', // what
                collection(firestore, 'safetyCheckItems'),
                [
                    where('state', '==', 'active')
                ],
                'vesselId',
                'in',
                selectedVesselIds,
                [], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const list: SafetyCheckCompleted[] = [];
                    docs.forEach((doc) => {
                        if (completedSafetyChecks[doc.id]) {
                            const data = {
                                id: doc.id,
                                ...doc.data()
                             } as SafetyCheckCompleted;

                            if (isCriticalityOk(data)) { // Filter by criticality
                                list.push({
                                    id: doc.id,
                                    name: vesselSafetyItemsById[data.itemId]?.name,
                                    files: data.files || [],
                                    interval: data.interval,
                                    isCritical: vesselSafetyItemsById[data.itemId]?.isCritical,
                                    vesselId: data.vesselId,
                                    timesCompleted: completedSafetyChecks[doc.id]
                                } as SafetyCheckCompleted);
                            }
                        }
                    });

                    // Sort alphabetically
                    list.sort((a, b) => {
                        if (a.name && b.name) {
                            return a.name.localeCompare(b.name);
                        } else {
                            return 0;
                        }
                    });

                    setReportingCompletedSafetyChecks(list);
                }
            );
        }
    }, [isActive, selectedVesselIds, safetyCriticality, completedSafetyChecks, vesselSafetyItemsById]);

    return reportingCompletedSafetyChecks;

}
