import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { IonItem, IonList } from '@ionic/react';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { closeModalBox, openModalBox, updateModalBox } from '../../managers/ModalBoxManager/ModalBoxManager';
import { UserType } from '../../shared-state/Core/user';
import { Contact } from '../../shared-state/Crew/contacts';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaTabsGroup from '../SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../SeaTab/SeaTab';
import SeaTabContent from '../SeaTabContent/SeaTabContent';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaSelectCrewOrContact.css';

/*

Uses of this:
    crew > vessel access
    voyage > Personnel involved
    drills > create report > drill type (JUST STRINGS)
    drills > create report > personell involved
    maintenance schedule > connect equipment document
    maintenance schedule > connect contact
    health & safety > create meeting report > personell present

*/

interface SeaSelectCrewOrContactProps {
    label?: string,
    help?: SeaHelp,
    modalTitle?: string,
    value: {userId?: string, contactId?: string, name?: string} | undefined,
    setValue: (value: {userId: string | undefined, contactId: string | undefined} | undefined) => void,
    crew?: UserType[]
    required?: boolean, // if true will show an error when not set.
    requiredError?: string,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    //disabled?: boolean,
    //emptyText?: string
}

const SeaSelectCrewOrContact: React.FC<SeaSelectCrewOrContactProps> = ({
    label,
    help,
    modalTitle,
    value,
    setValue,
    crew,
    required,
    requiredError,
    isSubmitting
    //isSubmitting
}) => {
    const contacts = sharedState.contacts.use();

    const [touched, setTouched] = useState(false);
    //const [tab, setTab] = useState("crew");
    const tabRef = useRef("crew");

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && (value?.userId === undefined && value?.contactId === undefined)) {
        error = requiredError ? requiredError : 'Please select a crew memember or contact';
    }

    const contactsSorted = useMemo(() => {
        if (contacts?.all?.length) {
            const array = [...contacts.all];
            array.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return array;
        }
        return undefined;
    }, [contacts]);

    const summary = useMemo(() => {
        if (value) {
            if (value.userId) {
                return renderFullNameForUserId(value.userId);
            } else if (value.contactId && contacts?.byId && contacts.byId[value.contactId]) {
                return contacts.byId[value.contactId].name;
            }
        }
        return '';
    }, [value, contacts?.byId]);

    const openPopover = useCallback(() => {
        const stopClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const renderContent = () => {
            return (<>
                {modalTitle &&
                    <div className="sea-select-multi-title">
                        {modalTitle}
                    </div>
                }
                <div className="sea-select-multi-links" onClick={stopClick}>
                    <SeaTabsGroup
                        selectedTab={tabRef.current}
                        setTab={(_tab: string) => {
                            //setTab(_tab);
                            tabRef.current = _tab;
                            updateModalBox(renderContent());
                        }}
                        mode="forms"
                        mini={true}
                        allowFades={false}
                    >
                        <SeaTab tab="crew" mode="forms">Crew</SeaTab>
                        <SeaTab tab="contacts" mode="forms">Contacts</SeaTab>
                    </SeaTabsGroup>
                </div>
                <div
                    className="sea-select-multi-box"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <SeaTabContent tab="crew" selectedTab={tabRef.current}>
                        <IonList mode="md">
                            {crew?.map((_user) => {
                                return (
                                    <IonItem
                                        key={_user.id}
                                        button={true}
                                        onClick={(e) => {
                                            setValue({
                                                userId: _user.id,
                                                contactId: undefined
                                            });
                                            closeModalBox();
                                        }}
                                    >
                                        {_user.firstName} {_user.lastName}
                                    </IonItem>
                                );
                            })}
                        </IonList>
                    </SeaTabContent>
                    <SeaTabContent tab="contacts" selectedTab={tabRef.current}>
                        <IonList mode="md">
                            {contactsSorted?.map((contact: Contact) => {
                                return (
                                    <IonItem
                                        key={contact.id}
                                        button={true}
                                        onClick={(e) => {
                                            setValue({
                                                userId: undefined,
                                                contactId: contact.id
                                            });
                                            closeModalBox();
                                        }}
                                    >
                                        {contact.name}
                                    </IonItem>
                                );
                            })}
                        </IonList>
                    </SeaTabContent>
                </div>
                {/* <div className="sea-select-multi-actions" onClick={stopClick}>
                    <SeaButton size="small" mini onClick={closeModalBox}>
                        OK
                    </SeaButton>
                </div> */}
            </>);
        };
        openModalBox(renderContent(), 'crew-or-contact');
    }, [crew, contactsSorted]);

    return <>
        {label && <div><SeaLabel help={help}>{label}</SeaLabel></div>}
        <div
            className={`sea-input sea-select multi ${error ? 'has-error' : ''}`}
            onClick={openPopover}
        >
            <div className="select-text no-select">
                {summary}
            </div>
            <div className="select-icon">
                <div className="select-icon-inner"></div>
            </div>
        </div>
        <SeaInputError>{error}</SeaInputError>
    </>
};

export default SeaSelectCrewOrContact;
