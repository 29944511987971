// export const mapApiKey = 'sV6dTd0N5lkDAG4lWhatkZAwe16eEpKn'; // development
// export const forecastApiKey = 'OMOUHSgmFGt96bSVwGCpzfiopjjDYkxo'; // development
export const mapApiKey = 'LpWYBTnnrRcFQavURCFoP1rDKjWu1jC7'; // production
export const forecastApiKey = 'qE0QBolEMqrbqNtu4fPx0URb5mkRfUuz'; // production

export const metersPerSecondToKnots = 1.943844;
const desaturation = 0.3;

export const compassdirections = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];

export const radiansToCompassDirection = (radians: number) => {
    let directionIndex = radians - Math.PI;
    const piece = Math.PI / 8;
    directionIndex += (piece / 2);
    if (directionIndex < 0) {
        directionIndex += (Math.PI * 2);
    }
    directionIndex = Math.floor(directionIndex / piece);
    return compassdirections[directionIndex];
};

export const windGradient = [ // Array of [value, R, G, B]
    [0,  154,12,204],
    [5,  53,55,251],
    [10, 59,193,36],
    [15, 205,214,15],
    [20, 239,112,7],
    [25, 241,1,1],
    [30, 186,4,3],
    [35, 130,3,2],
    [40, 100,3,2],
    [45, 55,4,3],
    [50, 94,94,94]
];
export const wavesGradient = [
    [0, 145,2,213,256],
    [1, 54,57,252,256],
    [2, 1,191,213,256],
    [3, 81,197,110,256],
    [4, 212,172,44,256],
    [5, 225,102,43,256],
    [6, 226,18,99,256],
    [7, 219,5,10,256],
    [8, 143,6,5,256],
    [9, 62,9,8,256],
    [10,91,91,91,256]
];
export const tempGradient = [ // Array of [value, R, G, B]
    [-70, 115,70,105],
    [-55, 202,172,195],
    [-40, 162,70,145],
    [-25, 143,89,169],
    [-15, 157,219,217],
    [-8,  106,191,181],
    [-4,  100,166,189],
    [0,   93,133,198],
    [1,   68,125,99],
    [10,  128,147,24],
    [21,  243,183,4],
    [30,  232,83,25],
    [47,  71,14,0]
];

// Desaturate an RGB colour by desaturation factor f (0-1)
export const desaturate = (rgb: number[], f = desaturation) => {
    const grey = 0.3 * rgb[0] + 0.6 * rgb[1] + 0.1 * rgb[2];
    const result = [...rgb];
    result[0] = rgb[0] + f * (grey - rgb[0]);
    result[1] = rgb[1] + f * (grey - rgb[1]);
    result[2] = rgb[2] + f * (grey - rgb[2]);
    return result;
}
export const whiten = (rgb: number[], f = 0.5) => {
    const result = [...rgb];
    result[0] = rgb[0] + f * (255 - rgb[0]);
    result[1] = rgb[1] + f * (255 - rgb[1]);
    result[2] = rgb[2] + f * (255 - rgb[2]);
    return result;
}

export const rgbToString = (rgb: number[]) => {
    const col = whiten(desaturate(rgb, 0.3), 0.1);
    return `rgb(${col[0]},${col[1]},${col[2]})`;
};
export const mixRgb = (rgb1: number[], rgb2: number[], mix = 0.5) => {
    return [
        Math.round(rgb1[0] * mix + rgb2[0] * (1-mix)),
        Math.round(rgb1[1] * mix + rgb2[1] * (1-mix)),
        Math.round(rgb1[2] * mix + rgb2[2] * (1-mix))
    ];
};
export const getGradientRgb = (value: number, gradient: number[][]) => {
    if (value <= gradient[0][0]) {
        return gradient[0].slice(1, 4);
    } else if (value >= gradient[gradient.length - 1][0]) {
        return gradient[gradient.length - 1].slice(1, 4);
    }
    let index = 1;
    while (value > gradient[index][0]) {
        index++;
    }
    const mix = 1 - ((value - gradient[index-1][0]) / (gradient[index][0] - gradient[index-1][0]));
    return mixRgb(
        gradient[index-1].slice(1, 4),
        gradient[index].slice(1, 4),
        mix
    );
};

export const getGradientBackgroundImage = (previousValue: number, value: number, nextValue: number, gradient: number[][], blendPercent: number) => {
    const previousRgb = getGradientRgb(previousValue, gradient);
    const rgb = getGradientRgb(value, gradient);
    const nextRgb = getGradientRgb(nextValue, gradient);
    let s = 'linear-gradient(90deg';
    if (previousValue === value) {
        s += `, ${rgbToString(rgb)} 0%`;
    } else {
        s += `, ${rgbToString(mixRgb(previousRgb, rgb))} 0%, ${rgbToString(rgb)} ${blendPercent}%`;
    }
    if (value === nextValue) {
        s += `, ${rgbToString(rgb)} 100%`;
    } else {
        s += `, ${rgbToString(rgb)} ${(100 - blendPercent)}%, ${rgbToString(mixRgb(rgb, nextRgb))} 100%`;
    }
    s += ')';
    return s;
};







// Adapted from https://github.com/nerik/formatcoords
function computeFor(initValue: number) {
    var o = {} as {
        initValue: number,
        degrees: number,
        degreesInt: number,
        degreesFrac: number,
        secondsTotal: number,
        minutes: number,
        minutesInt: number,
        seconds: number,
        secondsInt: number
    };
    o.initValue = initValue;
    o.degrees = Math.abs(initValue);
    o.degreesInt = Math.floor(o.degrees);
    o.degreesFrac = o.degrees - o.degreesInt;
    o.secondsTotal = 3600 * o.degreesFrac; 
    o.minutes = o.secondsTotal / 60; 
    o.minutesInt = Math.floor(o.minutes);
    o.seconds = o.secondsTotal - (o.minutesInt * 60);
    o.secondsInt = Math.floor(o.seconds);
    return o;
}
export const formatCoords = (lat: number, lon: number) => {
    const o = {} as {
        north: boolean,
        east: boolean,
        latValues: {
            degreesInt: number,
            minutesInt: number,
            secondsInt: number
        },
        lonValues: {
            degreesInt: number,
            minutesInt: number,
            secondsInt: number
        }
    };
    o.north = lat > 0;
	o.east = lon > 0;
	o.latValues = computeFor(lat);
	o.lonValues = computeFor(lon);
    return <>
        {o.north ? 'N' : 'S'}{o.latValues.degreesInt}&deg;{o.latValues.minutesInt}'{o.latValues.secondsInt}, {o.east ? 'E' : 'W'}{o.lonValues.degreesInt}&deg;{o.lonValues.minutesInt}'{o.lonValues.secondsInt}
    </>;
};
