import React, { useEffect, useState } from 'react';
import { formatValue, formatInterval } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { colours } from '../../../../lib/reporting';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { SafetyCheckCompleted } from '../../../../shared-state/VesselSafety/completedSafetyCheckItems';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalBarGraph, { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import CompletedSafetyCheckReportPdf from '../../../../pdfs/Reporting/Safety/CompletedSafetyCheckReportPdf';
import './ViewCompletedSafetyChecksReport.css';

interface ViewCompletedSafetyChecksReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    completedSafetyChecks?: SafetyCheckCompleted[],
    safetyCriticality: string,
    setSafetyCriticality: React.Dispatch<React.SetStateAction<string>>,
}

const ViewCompletedSafetyChecksReport: React.FC<ViewCompletedSafetyChecksReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    completedSafetyChecks,
    safetyCriticality,
    setSafetyCriticality
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const {selectedVesselId} = useReportSettings();

    useEffect(() => {
        if (showModal) {
            logModalView('CompletedSafetyChecksReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={onGeneratePdf}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
                extraComponents={[
                    <SeaSelect
                        label="Critical?"
                        value={safetyCriticality}
                        onchange={(e) => {
                            setSafetyCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                sortData={true}
                units="Tasks Completed"
                colourPalette={[colours.mint]}
                hashNamesForColours={false}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="safety check tasks"
                    isLoading={!completedSafetyChecks}
                    hasNoData={completedSafetyChecks?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`safety-checks has-thumbs ${(completedSafetyChecks && completedSafetyChecks.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Safety Item</div>
                        <div>Times Completed</div>
                        <div>Interval</div>
                        <div>Vessel</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(completedSafetyChecks, (item) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(item.name)}
                                </div>
                                <div>
                                    {formatValue(item.timesCompleted)}
                                </div>
                                <div>
                                    {formatInterval(item.interval)}
                                </div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    {item.isCritical ? (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
            {showModal && completedSafetyChecks &&
                <CompletedSafetyCheckReportPdf
                    generatingPdf={generatingPdf}
                    setGeneratingPdf={setGeneratingPdf}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={completedSafetyChecks}
                />
            }
        </SeaModal>
    );
};

export default ViewCompletedSafetyChecksReport;
