import React, { useState, useCallback, useEffect, useRef } from 'react';
import { getContentType } from '../../../../lib/util';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getRoot,
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    createCommand,
    LexicalCommand,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    DEPRECATED_$isGridSelection,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    OUTDENT_CONTENT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import {
    $createHeadingNode,
    $createQuoteNode,
    $isHeadingNode,
    HeadingTagType,
} from '@lexical/rich-text';
import {
    $isListNode,
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $selectAll,
    $setBlocksType,
} from '@lexical/selection';
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    mergeRegister
} from '@lexical/utils';
import {INSERT_TABLE_COMMAND} from '@lexical/table';
import {$isDecoratorBlockNode} from '@lexical/react/LexicalDecoratorBlockNode';
import {INSERT_HORIZONTAL_RULE_COMMAND} from '@lexical/react/LexicalHorizontalRuleNode';
import {INSERT_IMAGE_COMMAND} from '../../plugins/LexImages/LexImages';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { disableSwipe, enableSwipe } from '../../../../shared-state/General/swipeGestures';
import { SeaFile } from '../../../../lib/files';
import LexButton from '../../ui/LexButton/LexButton';
import LexButtonDivider from '../../ui/LexButtonDivider/LexButtonDivider';
import LexIcon from '../../ui/LexIcon/LexIcon';
import LexDropDown from '../../ui/LexDropDown/LexDropDown';
import LexDropDownOption from '../../ui/LexDropDownOption/LexDropDownOption';
import SeaColourModal from '../../../SeaColourModal/SeaColourModal';
import SeaModal from '../../../SeaModal/SeaModal';
import SeaInput from '../../../SeaInput/SeaInput';
import SeaButton from '../../../SeaButton/SeaButton';
import SeaFileSelector from '../../../SeaFileSelector/SeaFileSelector';
import './LexToolBar.css';

// type InsertTableCommandPayload = Readonly<{
//     columns: string;
//     rows: string;
//     includeHeaders?: boolean;
// }>;

//export const INSERT_NEW_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> = createCommand('INSERT_NEW_TABLE_COMMAND');

const isApple: boolean = /Mac|iPod|iPhone|iPad/.test(navigator.platform);

const fontFamilyOptions: [string, string][] = [
    ['Arial', 'Arial'],
    ['Brush Script MT', 'Brush Script MT'],
    ['Courier New', 'Courier New'],
    ['Garamond', 'Garamond'],
    ['Georgia', 'Georgia'],
    ['Montserrat', 'Montserrat'],
    ['Tahoma', 'Tahoma'],
    ['Times New Roman', 'Times New Roman'],
    ['Trebuchet MS', 'Trebuchet MS'],
    ['Verdana', 'Verdana'],
];

const fontSizeOptions: [string, string][] = [
    ['10px', '10px'],
    ['11px', '11px'],
    ['12px', '12px'],
    ['13px', '13px'],
    ['14px', '14px'],
    ['15px', '15px'],
    ['16px', '16px'],
    ['18px', '18px'],
    ['20px', '20px'],
    ['24px', '24px'],
    ['30px', '30px'],
];

const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    //check: 'Check List',
    //code: 'Code Block',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
};

const lexColorSwatches = [
    '#000000','#2b2b2b','#555555','#808080','#aaaaaa','#d5d5d5','#ffffff',
    '#92d051','#fdf054','#fbbe43','#fb4322','#9bc2e6','#be80fe','#fe80e3'
];

interface LexToolBarProps {
    level?: number
}

const LexToolBar: React.FC<LexToolBarProps> = ({ level = 1 }) => {
    const isMounted = useRef(false);
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
    const [fontSize, setFontSize] = useState<string>('13px');
    const [fontColor, setFontColor] = useState<string>('#333333');
    const [bgColor, setBgColor] = useState<string>('#ffffff');
    const [colorPicker, setColorPicker] = useState({
        showModal: false,
        mode: 'color', // or background-color
        color: '#ffffff'
    });
    const [insertTable, setInsertTable] = useState({
        showModal: false,
        rows: '5',
        columns: '5'
    });
    const [fontFamily, setFontFamily] = useState<string>('Montserrat');
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [insertImageClickEvent, setInsertImageClickEvent] = useState<React.MouseEvent | undefined>(undefined);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    const focus = useCallback(() => {
        editor.focus();
        setTimeout(() => {
            if (!isMounted.current) return;
            editor.focus();
            setTimeout(() => {
                if (!isMounted.current) return;
                editor.focus();
                setTimeout(() => {
                    if (!isMounted.current) return;
                    editor.focus();
                }, 100);
            }, 100);
        }, 100);
    }, [editor]);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                ? anchorNode
                : $findMatchingParent(anchorNode, (e) => {
                    const parent = e.getParent();
                    return parent !== null && $isRootOrShadowRoot(parent);
                    });
        
            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }
        
            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);
        
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsSubscript(selection.hasFormat('subscript'));
            setIsSuperscript(selection.hasFormat('superscript'));
            // setIsCode(selection.hasFormat('code'));
            // setIsRTL($isParentElementRTL(selection));
        
            // Update links
            // const node = getSelectedNode(selection);
            // const parent = node.getParent();
            // if ($isLinkNode(parent) || $isLinkNode(node)) {
            //     setIsLink(true);
            // } else {
            //     setIsLink(false);
            // }
        
            if (elementDOM !== null) {
                //setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode,
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type as keyof typeof blockTypeToBlockName);
                    } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                    // if ($isCodeNode(element)) {
                    //     const language =
                    //     element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
                    //     setCodeLanguage(
                    //     language ? CODE_LANGUAGE_MAP[language] || language : '',
                    //     );
                    //     return;
                    // }
                }
            }

            // Handle buttons
            setFontSize(
                $getSelectionStyleValueForProperty(selection, 'font-size', '13px')
            );
            setFontColor(
                $getSelectionStyleValueForProperty(selection, 'color', '#000000')
            );
            setBgColor(
                $getSelectionStyleValueForProperty(
                selection,
                'background-color',
                '#ffffff',
                )
            );
            setFontFamily(
                $getSelectionStyleValueForProperty(selection, 'font-family', 'Montserrat')
            );
        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            // editor.registerEditableListener((editable) => {
            //     setIsEditable(editable);
            // }),
            activeEditor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            activeEditor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            ),
            activeEditor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            ),
        );
    }, [activeEditor, editor, updateToolbar]);

    const applyStyleText = useCallback(
        (styles: Record<string, string>) => {
          activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, styles);
            }
        });
    }, [activeEditor]);

    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            $selectAll(selection);
            selection.getNodes().forEach((node) => {
              if ($isTextNode(node)) {
                node.setFormat(0);
                node.setStyle('');
                $getNearestBlockElementAncestorOrThrow(node).setFormat('');
              }
              if ($isDecoratorBlockNode(node)) {
                node.setFormat('');
              }
            });
          }
        });
      }, [activeEditor]);

    const onBlockTypeChange = useCallback((value: string) => {
        setBlockType(value as keyof typeof blockTypeToBlockName);
        switch(value) {
            case 'bullet':
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
                break;
            case 'number':
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
                break;
            case 'paragraph':
                editor.update(() => {
                    const selection = $getSelection();
                    if (
                      $isRangeSelection(selection) ||
                      DEPRECATED_$isGridSelection(selection)
                    )
                      $setBlocksType(selection, () => $createParagraphNode());
                  });
                break;
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                editor.update(() => {
                    const selection = $getSelection();
                    if (
                        $isRangeSelection(selection) ||
                        DEPRECATED_$isGridSelection(selection)
                    ) {
                        $setBlocksType(selection, () =>
                            $createHeadingNode(value as HeadingTagType),
                        );
                    }
                });
                break;
            case 'quote':
                editor.update(() => {
                    const selection = $getSelection();
                    if (
                        $isRangeSelection(selection) ||
                        DEPRECATED_$isGridSelection(selection)
                    ) {
                        $setBlocksType(selection, () => $createQuoteNode());
                    }
                });
                break;
        }
    }, [editor]);

    return (
        <div
            className="lex-toolbar"
            onTouchMove={(e) => {
                disableSwipe();
            }}
            onTouchEnd={(e) => {
                enableSwipe(500);
            }}
        >
            <LexButton
                title={isApple ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
                onClick={() => {
                    activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                disabled={!canUndo}
            >
                <LexIcon icon="arrow-counterclockwise"/>
            </LexButton>
            <LexButton
                title={isApple ? 'Redo (⌘Y)' : 'Redo (Ctrl+Y)'}
                onClick={() => {
                    activeEditor.dispatchCommand(REDO_COMMAND, undefined);
                }}
                disabled={!canRedo}
            >
                <LexIcon icon="arrow-clockwise"/>
            </LexButton>
            <LexButtonDivider />
            <LexDropDown
                value={blockType}
                onChange={onBlockTypeChange}
                maxWidth="140px"
            >
                <LexDropDownOption value="paragraph">
                    <LexIcon icon="text-paragraph" mode="start"/>Normal
                </LexDropDownOption>
                <LexDropDownOption value="h1">
                    <LexIcon icon="type-h1" mode="start"/>Section Heading
                </LexDropDownOption>
                <LexDropDownOption value="h2">
                    <LexIcon icon="type-h2" mode="start"/>Sub Heading
                </LexDropDownOption>
                <LexDropDownOption value="h3">
                    <LexIcon icon="type-h3" mode="start"/>Heading 3
                </LexDropDownOption>
                <LexDropDownOption value="bullet">
                    <LexIcon icon="list-ul" mode="start"/>Bullet List
                </LexDropDownOption>
                <LexDropDownOption value="number">
                    <LexIcon icon="list-ol" mode="start"/>Numbered List
                </LexDropDownOption>
                <LexDropDownOption value="quote">
                    <LexIcon icon="chat-square-quote" mode="start"/>Quote
                </LexDropDownOption>
            </LexDropDown>
            <LexButtonDivider />
            <LexDropDown
                contentPrefix={<LexIcon icon="font-family" mode="start"/>}
                value={fontFamily}
                setValue={setFontFamily}
                onChange={(value) => {
                    editor.update(() => {
                        const selection = $getSelection();
                        if ($isRangeSelection(selection)) {
                            $patchStyleText(selection, {
                                'font-family': value
                            });
                        }
                    });
                }}
                showSelectedContent={true}
                maxWidth="150px"
            >
                {fontFamilyOptions.map(([value, text]) => {
                    return (
                        <LexDropDownOption key={value} value={value}>
                            {text}
                        </LexDropDownOption>
                    );
                })}
            </LexDropDown>
            <LexDropDown
                value={fontSize}
                setValue={setFontSize}
                onChange={(value) => {
                    editor.update(() => {
                        const selection = $getSelection();
                        if ($isRangeSelection(selection)) {
                            $patchStyleText(selection, {
                                'font-size': value
                            });
                        }
                    });
                }}
            >
                {fontSizeOptions.map(([value, text]) => {
                    return (
                        <LexDropDownOption key={value} value={value}>
                            {text}
                        </LexDropDownOption>
                    );
                })}
            </LexDropDown>
            <LexButtonDivider />
            <LexButton
                title={isApple ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                active={isBold}
            >
                <LexIcon icon="type-bold"/>
            </LexButton>
            <LexButton
                title={isApple ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                active={isItalic}
            >
                <LexIcon icon="type-italic"/>
            </LexButton>
            <LexButton
                title={isApple ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                active={isUnderline}
            >
                <LexIcon icon="type-underline"/>
            </LexButton>
            <LexButton
                title="Choose Font Colour"
                onClick={() => {
                    setColorPicker({
                        showModal: true,
                        color: fontColor,
                        mode: 'color'
                    });
                }}
            >
                <div style={{ position: 'relative' }}>
                    <LexIcon icon="font-color" yOffset={-1} opacity={1} />
                    <div className="colour-swish" style={{ backgroundColor: fontColor }}></div>
                </div>
            </LexButton>
            <LexButton
                title="Choose Highlight Colour"
                onClick={() => {
                    setColorPicker({
                        showModal: true,
                        color: bgColor,
                        mode: 'background-color'
                    });
                }}
            >
                <div style={{ position: 'relative' }}>
                    <LexIcon icon="paint-bucket" yOffset={-1} opacity={0.6} scale={0.8} />
                    <div className="colour-swish" style={{ backgroundColor: bgColor }}></div>
                </div>
            </LexButton>
            <LexDropDown
                defaultContent={<LexIcon icon="dropdown-more" />}
            >
                <LexButton
                    title="Strikethrough"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                    }}
                    active={isStrikethrough}
                >
                    <LexIcon icon="type-strikethrough" mode="start"/>
                    Strikethrough
                </LexButton>
                <LexButton
                    title="Subscript"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
                    }}
                    active={isSubscript}
                >
                    <LexIcon icon="type-subscript" mode="start"/>
                    Subscript
                </LexButton>
                <LexButton
                    title="Superscript"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
                    }}
                    active={isSuperscript}
                >
                    <LexIcon icon="type-superscript" mode="start"/>
                    Superscript
                </LexButton>
                <LexButton
                    title="Clear all text formatting"
                    onClick={clearFormatting}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="trash" mode="start"/>
                    Clear Formatting
                </LexButton>
            </LexDropDown>
            <LexButtonDivider />
            <LexDropDown
                defaultContent={
                    <>
                        <LexIcon icon="text-left" mode="start" />
                        Align
                    </>
                }
            >
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="text-left" mode="start"/>
                    Left Align
                </LexButton>
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="text-center" mode="start"/>
                    Centre Align
                </LexButton>
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="text-right" mode="start"/>
                    Right Align
                </LexButton>
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="justify" mode="start"/>
                    Justify Align
                </LexButton>
                <div style={{
                    height: '1px',
                    backgroundColor: 'var(--input-border-color)',
                    margin: '4px 4px'
                }}></div>
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="outdent" mode="start"/>
                    Outdent
                </LexButton>
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="indent" mode="start"/>
                    Indent
                </LexButton>
                {/* <LexButton
                    onClick={() => {
                        console.log('state JSON', activeEditor.getEditorState().toJSON());
                        console.log('state string', JSON.stringify(activeEditor.getEditorState().toJSON()));
                    }}
                    hideDropDownPopover={true}
                >
                    Test: JSON
                </LexButton> */}
            </LexDropDown>
            <LexButtonDivider />
            <LexDropDown
                defaultContent={
                    <>
                        <LexIcon icon="plus" />
                        Insert
                    </>
                }
            >
                <LexButton
                    onClick={() => {
                        activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="horizontal-rule" mode="start"/>
                    Horizontal Rule
                </LexButton>
                <LexButton
                    onClick={(e: React.MouseEvent) => {
                        setInsertImageClickEvent(e);
                        // activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                        //     altText: 'This is my alt text',
                        //     src: 'https://www.unigreet.com/wp-content/uploads/2022/11/100-very-special-good-morning-images-quotes-photos.jpg'
                        // });
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="file-image" mode="start"/>
                    Image
                </LexButton>
                <LexButton
                    onClick={() => {
                        setInsertTable({
                            showModal: true,
                            rows: '5',
                            columns: '5'
                        });
                    }}
                    hideDropDownPopover={true}
                >
                    <LexIcon icon="table" mode="start"/>
                    Table
                </LexButton>
            </LexDropDown>
            <SeaColourModal
                showModal={colorPicker.showModal}
                setShowModal={(showModal: boolean) => {
                    setColorPicker({...colorPicker, showModal: showModal});
                    if (!showModal) {
                        focus();
                    }
                }}
                color={colorPicker.color}
                setColor={(color: string) => {
                    if (colorPicker.mode === 'color') {
                        setFontColor(color);
                        applyStyleText({'color': color});
                    } else { // background-color
                        setBgColor(color);
                        applyStyleText({'background-color': color});
                    }
                }}
                colorSwatches={lexColorSwatches}
                title={colorPicker.mode === 'color' ? 'Font Colour' : 'Highlight Colour'}
                level={level + 1}
            />
            <SeaModal
                title="Insert New Table"
                showModal={insertTable.showModal}
                setShowModal={(showModal: boolean) => {
                    setInsertTable({
                        ...insertTable,
                        showModal: showModal
                    });
                }}
                size="thin"
                level={level + 1}
            >
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                inputmode="numeric"
                                type="number"
                                label="Number of rows"
                                value={insertTable.rows}
                                onchange={(e) => {
                                    setInsertTable({
                                        ...insertTable,
                                        rows: e.detail.value
                                    });
                                }}
                                style={{ maxWidth: '150px' }}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                inputmode="numeric"
                                type="number"
                                label="Number of columns"
                                value={insertTable.columns}
                                onchange={(e) => {
                                    setInsertTable({
                                        ...insertTable,
                                        columns: e.detail.value
                                    });
                                }}
                                style={{ maxWidth: '150px' }}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <div style={{ height: '20px' }}></div>
                            <SeaButton
                                size="wide"
                                onClick={() => {
                                    const rows = parseInt(insertTable.rows);
                                    if (isNaN(rows) || rows <= 0) {
                                        alertMessage('Please enter a valid number for "Number of Rows"');
                                        return;
                                    } else if (rows > 1000) {
                                        alertMessage('You cannot insert a table with more than 1000 rows!');
                                        return;
                                    }
                                    const columns = parseInt(insertTable.columns);
                                    if (isNaN(columns) || columns <= 0) {
                                        alertMessage('Please enter a valid number for "Number of Columns"');
                                        return;
                                    } else if (columns > 1000) {
                                        alertMessage('You cannot insert a table with more than 1000 columns!');
                                        return;
                                    }
                                    activeEditor.dispatchCommand(
                                        INSERT_TABLE_COMMAND,
                                        {
                                            columns: ''+columns,
                                            rows: ''+rows
                                        }
                                    );
                                    setInsertTable({
                                        ...insertTable,
                                        showModal: false
                                    });
                                }}
                            >
                                Insert Table
                            </SeaButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </SeaModal>
            <SeaFileSelector
                clickEvent={insertImageClickEvent}
                onFilesSelected={(files: SeaFile[]) => {
                    if (files && files.length > 0) {
                        files.forEach((file: SeaFile) => {
                            let contentType = 'image/jpeg';
                            if (file.contentType) {
                                contentType = file.contentType;
                            } else if (file.ext) {
                                contentType = getContentType(file.ext);
                            }
                            activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                                src: `data:${contentType};base64, ${file.base64}`,
                                //src: 'https://www.unigreet.com/wp-content/uploads/2022/11/100-very-special-good-morning-images-quotes-photos.jpg'
                                altText: file.name ? file.name : 'An Image',
                                //maxWidth: lexContentWidth
                                width: (contentType.indexOf('svg') !== -1) ? 300 : 0
                            });
                        });
                    }
                }}
                allowMultiple={false}
                onlyAllowImages={true}
                maxUploadMB={50}
            />
        </div>
    );
}

export default LexToolBar;
