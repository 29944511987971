import React, { useState, useEffect } from 'react';
import { IonProgressBar } from '@ionic/react';
import { sharedState } from '../../../shared-state/shared-state';
import { DataSyncStatus } from '../../../shared-state/DataSyncSystem/dataSyncTasks';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import './SyncModal.css';

interface SyncModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    dataSyncStatus?: DataSyncStatus,
    uploadInfo?: any,
    downloadInfo?: any
}

const SyncModal: React.FC<SyncModalProps> = ({showModal, setShowModal, dataSyncStatus, uploadInfo, downloadInfo}) => {
    const onlineStatus = sharedState.onlineStatus.use(showModal);
    const [showAtleast, setShowAtleast] = useState({
        dataSync: false,
        uploads: false,
        downloads: false
    });

    useEffect(() => {
        if (showModal) {
            setShowAtleast((current) => {
                return {
                    dataSync: Boolean(current.dataSync || (dataSyncStatus && dataSyncStatus.totalTasks > 0)),
                    uploads: (current.uploads || uploadInfo?.isUploading),
                    downloads: (current.downloads || downloadInfo?.isDownloading)
                };
            });
        }
    }, [showModal, dataSyncStatus, uploadInfo, downloadInfo]);

    const onOpened = () => {
        setShowAtleast({
            dataSync: (dataSyncStatus && dataSyncStatus.totalTasks > 0) ? true : false,
            uploads: (uploadInfo?.isUploading),
            downloads: (downloadInfo?.isDownloading)
        });
    };

    const isSyncing = (
        (dataSyncStatus && dataSyncStatus.totalTasks > 0) ||
        (uploadInfo?.isUploading) ||
        (downloadInfo?.isDownloading)
    );

    return (
        <SeaModal
            title="Cloud Sync"
            showModal={showModal}
            setShowModal={setShowModal}
            size="thin"
            onOpened={onOpened}
        >
            <div style={{ minHeight: '400px' }}>
                <div style={{ display: 'flex', minHeight: '36px' }}>
                    {isSyncing ? (
                        <div className="sync-spinner big" style={{ color: (onlineStatus?.isOnline) ? 'var(--ion-color-primary)' : 'var(--ion-color-danger)' }}>
                            <SeaIcon icon="sync" forceFontSize="32px"/>
                        </div>
                    ) : (
                        <div>
                            <SeaIcon icon="tick" forceFontSize="32px"/>
                        </div>
                    )}
                    <div className="sync-summary">
                        {isSyncing ?
                            (
                                (onlineStatus?.isOnline)
                                ?
                                <>
                                    Sea Flux is syncing your device with the cloud...
                                </>
                                :
                                <>
                                    You are currently offline.
                                    <br/>
                                    Syncing will resume once you've reconnected.
                                </>
                            ) : (
                                <>
                                    Your device is synced with the cloud.
                                </>
                            )
                        }
                    </div>
                </div>
                
                {(showAtleast.dataSync || (dataSyncStatus && dataSyncStatus.totalTasks > 0)) &&
                    <div className="sync-progress-container">
                        <div className="info">
                            Syncing data... {(dataSyncStatus && dataSyncStatus.totalTasks > 0) ? '' : '(Done)'}
                        </div>
                        <IonProgressBar
                            color={(onlineStatus?.isOnline) ? 'primary' : 'danger'}
                            value={
                                dataSyncStatus?.totalTasks
                                ?
                                ((dataSyncStatus.totalTasks - dataSyncStatus.tasksLeft - 1) / dataSyncStatus.totalTasks)
                                :
                                1
                            }
                        />
                    </div>
                }
                {(showAtleast.uploads || uploadInfo?.isUploading) &&
                    <div className="sync-progress-container">
                        <div className="info">
                            Uploading files... {
                                !(uploadInfo?.isUploading)
                                ?
                                '(Done)'
                                :
                                `(${uploadInfo.totalFiles - uploadInfo.filesLeft} of ${uploadInfo.totalFiles})`
                            }
                        </div>
                        <IonProgressBar
                            color={(onlineStatus?.isOnline) ? 'primary' : 'danger'}
                            value={
                                uploadInfo?.isUploading
                                ?
                                uploadInfo.progress
                                :
                                1
                            }
                        />
                    </div>
                }
                {(showAtleast.downloads || downloadInfo?.isDownloading) &&
                    <div className="sync-progress-container">
                        <div className="info">
                            Downloading files... {
                                !(downloadInfo?.isDownloading)
                                ?
                                '(Done)'
                                :
                                `(${downloadInfo.totalFiles - downloadInfo.filesLeft} of ${downloadInfo.totalFiles})`
                            }
                        </div>
                        <IonProgressBar
                            color={(onlineStatus?.isOnline) ? 'primary' : 'danger'}
                            value={
                                downloadInfo?.isDownloading
                                ?
                                ((downloadInfo.totalFiles - downloadInfo.filesLeft - 1) / downloadInfo.totalFiles)
                                :
                                1
                            }
                        />
                    </div>
                }
                <div style={{ padding: '40px 0px 50px' }}>
                    {isSyncing && `Note: You can continue using the app while we keep syncing in the background.`}
                </div>
            </div>
            <SeaButton zone="white" type="submit" size="standard" onClick={(e) => setShowModal(false)}>
                OK
            </SeaButton>
        </SeaModal>
    );
};

export default SyncModal;
