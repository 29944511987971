import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { warnDays } from '../../../lib/util';
import { permissionLevels } from '../../../lib/permissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import { Drill } from '../../../shared-state/VesselSafety/drills';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditDrill from '../../../modals/VesselSafety/Drills/EditDrill/EditDrill';
import EditDrillReport from '../../../modals/VesselSafety/Drills/EditDrillReport/EditDrillReport';
import ViewDrill from '../../../modals/VesselSafety/Drills/ViewDrill/ViewDrill';
import ViewDrillHistory from '../../../modals/VesselSafety/Drills/ViewDrillHistory/ViewDrillHistory';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import ViewDrillUser from '../../../modals/VesselSafety/Drills/ViewDrillUser/ViewDrillUser';
import './Drills.css';

interface DrillsProps {
    visible: boolean
}

const Drills: React.FC<DrillsProps> = ({visible}) => {
    const vesselDrills = sharedState.vesselDrills.use(visible ? 1 : 101);
    const drillReports = sharedState.drillReports.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditDrillModal, setShowEditDrillModal] = useState(false);
    const [showViewDrillModal, setShowViewDrillModal] = useState(false);
    const [showViewDrillHistoryModal, setShowViewDrillHistoryModal] = useState(false);
    const [selectedDrill, setSelectedDrill] = useState<Drill>();
    const [showViewDrillUserModal, setShowViewDrillUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [showEditDrillReportModal, setShowEditDrillReportModal] = useState(false);
    const [expandedDrills, setExpandedDrills] = useState<{
        [key: string]: boolean
    }>({});
    
    useEffect(() => {
        if (visible) {
            logPageView('VesselSafety/Drills');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedDrill fresh
    useEffect(() => {
        if (selectedDrill?.id && vesselDrills?.byId[selectedDrill.id]) {
            setSelectedDrill(vesselDrills.byId[selectedDrill.id])
        };
    }, [vesselDrills, selectedDrill?.id]);

    const onAddNewDrill = () => {
        setShowEditDrillModal(true);
    };
    const onAddNewDrillReport = () => {
        setShowEditDrillReportModal(true);
    };
    const onViewDrillHistory = () => {
        setShowViewDrillHistoryModal(true)
    };
    const onViewDrill = (drill: Drill) => {
        console.log('Viewing drill', drill?.id);
        setShowViewDrillModal(true);
        setSelectedDrill(drill)
    };

    const onViewDrillUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, drill: Drill, user: UserType) => {
        e.stopPropagation();
        setSelectedDrill(drill);
        setSelectedUser(user);
        setShowViewDrillUserModal(true);
    };

    const toggleDrill = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, taskId: string) => {
        e.stopPropagation();
        const o = {...expandedDrills};
        if (expandedDrills[taskId]) {
            delete o[taskId];
            setExpandedDrills(o);
        } else {
            o[taskId] = true;
            setExpandedDrills(o);
        }
    };

    return (
        <RequirePermissions
            role="drills"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="drills page-head">
                <div><h2>Drills</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="drills"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton onClick={(e) => onAddNewDrill()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New Drill
                        </SeaButton>
                        <div className="spacer"></div>
                        <SeaButton disabled={!vesselDrills?.all.length} zone="grey" onClick={(e) => onAddNewDrillReport()}><SeaIcon icon="notes" slot="start" />Create Drill Report</SeaButton>
                    <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaButton onClick={(e) => onViewDrillHistory()} zone="grey">
                        <SeaIcon slot="start" icon="archive"/>
                        Drill History
                    </SeaButton>
                </div>
            </div>

            <div className="drills-content">
                <SeaNoData
                    dataName="drills"
                    isLoading={!vesselDrills}
                    hasNoData={vesselDrills && vesselDrills?.all?.length === 0}
                    isUsingFilter={false}
                />

                <div className={`drills ${(vesselDrills && vesselDrills?.all?.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row headings">
                        <div>Drill</div>
                        {drillReports?.users?.map((user: UserType) => {
                            return <div key={user.id}>{renderFullName(user)}</div>
                        })}
                        <div></div>
                    </div>
                    <Profiler id="safety.drills" onRender={onProfilerRender}>
                        {mapArrayWithLimit(vesselDrills?.all, (drill: Drill) => {
                            let foundUser = false;
                            return (
                                <div key={drill.id} className={`sea-card sea-row no-select ${expandedDrills[drill.id] ? 'expand' : 'shrunk'}`} onClick={(e) => onViewDrill(drill)}>
                                    <div className="bold truncate-3">
                                        {drill.name}
                                    </div>
                                    {drillReports?.users?.map((user: UserType) => {
                                        const key = `${drill.id}${user.id}`;
                                        const item = drillReports.byDrillAndUser[key];
                                        if (item?.report) {
                                            foundUser = true;
                                            return (
                                                <div
                                                    key={key}
                                                    data-fullname={renderFullName(user)}
                                                    className="slot"
                                                    onClick={(e) => onViewDrillUser(e, drill, user)}>
                                                    <div className={`pushy due ${
                                                        (item.whenDueDiff < 0) ? 'od' : 
                                                        ((item.whenDueDiff < warnDays.drills[0]) ? 'soon' : 'ok')
                                                    }`}>
                                                        {Math.abs(item.whenDueDiff)}
                                                        <div>
                                                            {item.whenDueDiff === 1 || item.whenDueDiff === -1 ? 'DAY' : 'DAYS'}{item.whenDueDiff < 0 && ' OD'}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={key}>

                                                </div>
                                            );
                                        }
                                    })}
                                    {foundUser &&
                                        <div
                                            className={`more`}
                                            onClick={(e) => toggleDrill(e, drill.id)}
                                        >
                                            <span>Crew</span> <SeaIcon icon={expandedDrills[drill.id] ? 'moveUp' : 'moveDown'}/>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        {limitTriggerElement}
                    </Profiler>

                </div>

            </div>
            {visible &&
                <>
                    <EditDrillReport
                        showModal={showEditDrillReportModal}
                        setShowModal={setShowEditDrillReportModal}
                    />
                    <EditDrill
                        showModal={showEditDrillModal}
                        setShowModal={setShowEditDrillModal}
                    />
                    <ViewDrill
                        showModal={showViewDrillModal}
                        setShowModal={setShowViewDrillModal}
                        selectedItem={selectedDrill}
                    />
                    {selectedDrill && <ViewDrillUser
                        showModal={showViewDrillUserModal}
                        setShowModal={setShowViewDrillUserModal}
                        selectedDrill={selectedDrill}
                        selectedUser={selectedUser}
                    />}
                    <ViewDrillHistory
                        showModal={showViewDrillHistoryModal}
                        setShowModal={setShowViewDrillHistoryModal}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default Drills;
