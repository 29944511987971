import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from './voyages';
import { useState, useEffect } from 'react';
import { CustomFormCompleted } from '../CompanyDocuments/CustomForms/customFormsCompleted';

//
// Loads crew certificates (active)
//


export type VoyageCompletedCustomFormsData = {
    byId: {
        [id: string]: CustomFormCompleted
    },
    all: CustomFormCompleted[]
};

export const useVoyageCompletedCustomForms = (selectedVoyage: Voyage | undefined) => {
    const [voyageCompletedCustomForms, setVoyageCompletedCustomForms] = useState<VoyageCompletedCustomFormsData>();

    useEffect(() => {
        setVoyageCompletedCustomForms(undefined);
        if (selectedVoyage) {
            return onSnapshot(
                query(
                    collection(firestore, 'customFormsCompleted'),
                    where('attachTo', '==', 'voyage'),
                    where('attachToVesselId', '==', selectedVoyage.vesselId),
                    where('attachToId', '==', selectedVoyage.id),
                    where('state', '==', 'active'),
                    orderBy('whenAdded', 'desc')
                ),
                (snap) => {
                    const byId = {} as {
                        [id: string]: CustomFormCompleted
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as CustomFormCompleted;
                        byId[o.id] = o;
                        return o;
                    });
                    setVoyageCompletedCustomForms({
                        byId,
                        all
                    });
                }, (error) => {
                    console.log(`Failed to access completed forms for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage
                    });
                }
            );
        }
    }, [selectedVoyage]);

    return voyageCompletedCustomForms;
};
