import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, arrayRemove, serverTimestamp } from "firebase/firestore";
import { formatDate, formatInterval, formatValue, formatTextAreaText, warnDays } from '../../../../lib/util';
import { permissionLevels } from '../../../../lib/permissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { TrainingTaskReport, TrainingTaskReportsData } from '../../../../shared-state/Crew/trainingTaskReports';
import { TrainingTask } from '../../../../shared-state/Crew/trainingTasks';
import { openContextualHelp } from '../../../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './ViewTrainingTaskUser.css';

interface ViewTrainingTaskUserProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    trainingTaskReports: TrainingTaskReportsData,
    selectedTask: TrainingTask,
    selectedUser: UserType | undefined,
    vesselId?: string,
    selectedReport?: TrainingTaskReport,
}

const ViewTrainingTaskUser: React.FC<ViewTrainingTaskUserProps> = ({showModal, setShowModal, trainingTaskReports, selectedTask, selectedUser, vesselId, selectedReport}) => {
    const vessels = sharedState.vessels.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const [taskUserHistory, setTaskUserHistory] = useState<TrainingTaskReport[]>();

    const onDeleteUserFromReport = (report: TrainingTaskReport) => {
        const action = traceAction('trainingTaskReports') as Action;
        action.docId = report.id;
        const batch = splittableBatch(firestore, 20 - 0);
        const { completedBy } = report;

        if (selectedUser && completedBy.length > 1) { // Remove user from report
            action.type = 'update';
            batch.set(
                doc(firestore, 'trainingTaskReports', report.id),
                {
                    completedBy: arrayRemove(selectedUser.id),
                    updatedBy: userId,
                    whenUpdated: action.whenAction,
                    touched: serverTimestamp(),
                },
                { merge: true }
            );
        } else { // Only 1 user so remove and delete report
            action.type = 'delete';
            batch.set(
                doc(firestore, 'trainingTaskReports', report.id),
                {
                    deletedBy: userId,
                    whenDeleted: action.whenAction,
                    state: 'deleted',
                    completedBy: [],
                    touched: serverTimestamp(),
                },
                { merge: true }
            );
        }
        
        onCollectionUpdated(batch, 'trainingTaskReports');

        logAction(
            batch,
            'Delete',
            'trainingTaskReports',
            report.id,
            `${selectedUser?.firstName} ${selectedUser?.lastName} - ${selectedTask.task}`,
            [selectedTask.vesselId],
            selectedUser ? [selectedUser.id as string] : undefined
        );

        action.data = {
            report,
            selectedUser,
            selectedTask
        };
        action.save(`Remove ${selectedUser?.firstName} ${selectedUser?.lastName} from ${selectedTask?.task}`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        showToast('Crew member removed from training report');
        setShowModal(false);
    };

    const onOpened = () => {
        setTaskUserHistory(undefined);
        const _taskUserHistory: TrainingTaskReport[] = [];
        trainingTaskReports?.all.forEach((report) => {
            if (
                report.taskId === selectedTask.id &&
                selectedUser?.id &&
                report.completedBy.indexOf(selectedUser.id) !== -1
            ) {
                _taskUserHistory.push(report);
            }
        });
        setTaskUserHistory(_taskUserHistory);
    };

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {renderFullName(selectedUser)} - {selectedTask?.task}
                    </span>
                    {selectedReport?.whenDue &&
                        <SeaStatusDueDate
                            whenDue={selectedReport?.whenDue}
                            warnDays={warnDays.crewTraining[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            onOpened={onOpened}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedTask?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Vessel" w="50">
                    {vesselId && vessels?.byId[vesselId]?.name}
                </SeaGridCell>
                <SeaGridCell label="Retraining Interval" w="50">
                    {formatInterval(selectedTask?.interval)}
                </SeaGridCell>
                <SeaGridCell label="Next Due" w="50">
                    {formatDate(selectedReport?.whenDue)}
                </SeaGridCell>
                {/* <SeaGridCell label="Status" w="50">
                    {formatStatus(selectedReport?.whenDueDiff)}
                </SeaGridCell> */}
            </SeaGrid>

            <div className="end-info-line"></div>
            
            <div className="columns">
                <h2>Training History</h2>
                {/* <div className="right">
                    <SeaButton zone="white" onClick={(e) => onAddNewDrillReport()}><SeaIcon icon="notes" slot="start" />Create Drill Report</SeaButton>
                </div> */}
            </div>

            <SeaNoData
                dataName="training task"
                isHistory={true}
                isLoading={!taskUserHistory}
                hasNoData={taskUserHistory && taskUserHistory.length === 0}
            />
            <div className={`training-task-user-history ${taskUserHistory && taskUserHistory.length > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Trainer</div>
                    <div>Notes</div>
                    <div></div>
                </div>   
                {taskUserHistory?.map((report) => {
                    return (
                        <div key={report.id} className='sea-card sea-row-history'>
                            <div>{formatDate(report.whenCompleted)}</div>
                            <div className="truncate-3">{formatValue(report.trainer)}</div>
                            <div
                                className="truncate-3"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(report.notes)})}
                            >
                                {formatValue(formatTextAreaText(report.notes))}
                            </div>
                            <RequirePermissions
                                role="crewTraining"
                                level={permissionLevels.FULL}
                            >
                                <div
                                    onClick={
                                        (e) => {
                                            confirmAction('Are you sure you want to delete this crew member from this training report?', 'Yes, delete from report')
                                            .then(() => {
                                                onDeleteUserFromReport(report);
                                            }).catch(() => {});
                                        }
                                    }
                                >
                                    <div><SeaIcon icon='trash' slot="icon-only" /></div>
                                </div>
                            </RequirePermissions>
                        </div>
                    )
                })}
            </div>
        </SeaModal>
    );
};

export default ViewTrainingTaskUser;
