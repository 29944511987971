import React, { useEffect, useState } from 'react';
import { formatDate, formatValue, faultableTaskStatuses, formatInterval, warnDays, whenDueToClassName } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';
import { ReportingSafetyItemById } from '../../../../shared-state/Reporting/SafetyReporting/reportingSafetyItemsById';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import reporting from '../../../../lib/reporting';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SafetyCheckStatusReportPdf from '../../../../pdfs/Reporting/Safety/SafetyCheckStatusReportPdf';
import './ViewSafetyCheckStatusReport.css';

const safetyCheckStatusOptions = Object.keys(faultableTaskStatuses).map((statusId: string) => {
    return {
        id: statusId,
        name: faultableTaskStatuses[statusId]
    };
});

interface ViewSafetyCheckStatesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredSafetyChecks?: SafetyCheckItem[],
    categories?: (string | undefined)[],
    selectedSafetyCheckStatuses: string[],
    setSelectedSafetyCheckStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    safetyCriticality: string,
    setSafetyCriticality: React.Dispatch<React.SetStateAction<string>>,
    vesselSafetyItemsById?: ReportingSafetyItemById
}

const ViewSafetyCheckStatesReport: React.FC<ViewSafetyCheckStatesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredSafetyChecks,
    categories,
    selectedSafetyCheckStatuses,
    setSelectedSafetyCheckStatuses,
    safetyCriticality,
    setSafetyCriticality,
    vesselSafetyItemsById
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('SafetyCheckStatusReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={onGeneratePdf}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Safety Check Statuses"
                        values={selectedSafetyCheckStatuses}
                        setValues={setSelectedSafetyCheckStatuses}
                        options={safetyCheckStatusOptions}
                        useAllOption={true}
                    />,
                    <SeaSelect
                        label="Critical?"
                        value={safetyCriticality}
                        onchange={(e) => {
                            setSafetyCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.faultableTaskStatuses}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="safety check tasks"
                    isLoading={!filteredSafetyChecks}
                    hasNoData={filteredSafetyChecks?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`safety-equipment-checks has-thumbs has-status ${(filteredSafetyChecks && filteredSafetyChecks.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Safety Item</div>
                        <div>Interval</div>
                        <div>Next Check</div>
                        <div>Vessel</div>
                        <div>Status</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(filteredSafetyChecks, (item) => {
                        return (
                            <div
                                key={item.id}
                                className={`sea-card sea-row-history ${item.hasFault ? 'fail fault' : whenDueToClassName(item.whenDue, warnDays.safetyEquipmentChecks[0])}`}
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(vesselSafetyItemsById?.[item.itemId]?.name)}
                                </div>
                                <div>{formatInterval(item.interval)}</div>
                                <div>{formatDate(item.whenDue)}</div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault}/>
                                </div>
                                <div>
                                    {vesselSafetyItemsById?.[item.itemId]?.isCritical && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical bottom"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>

            {showModal && filteredSafetyChecks &&
                <SafetyCheckStatusReportPdf
                    generatingPdf={generatingPdf}
                    setGeneratingPdf={setGeneratingPdf}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredSafetyChecks}
                    vesselSafetyItemsById={vesselSafetyItemsById}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
        </SeaModal>
    );
};

export default ViewSafetyCheckStatesReport;
