import React, { useState } from 'react';
import { firestore, functions, splittableBatch } from '../../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { regions, formatDate, formatValue } from '../../../../../lib/util';
import { permissionLevels } from '../../../../../lib/permissions';
import { logAction } from '../../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../../shared-state/Core/users';
import { sharedState } from '../../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../../shared-state/DataSyncSystem/dataSync';
import { UserType } from '../../../../../shared-state/Core/user';
import { confirmAction } from '../../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../../managers/ToastManager/ToastManager';
import { UserDetails } from '../../../../../shared-state/Crew/userDetails';
import { Action, reportError, traceAction } from '../../../../../managers/ErrorsManager/ErrorsManager';
import SeaIcon from '../../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../../components/SeaLinkButton/SeaLinkButton';
import SeaGrid from '../../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../../components/RequirePermissions/RequirePermissions';
import './UserProfileTab.css';

interface UserProfileTabProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedUserDetails: UserDetails,
    setShowEditUserModal: (showModal: boolean) => void
    selectedUser?: UserType
}

const UserProfileTab: React.FC<UserProfileTabProps> = ({
    showModal,
    setShowModal,
    selectedUserDetails,
    setShowEditUserModal,
    selectedUser
}) => {
    const licenseeId = sharedState.licenseeId.use(showModal)!;
    const userId = sharedState.userId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [loading, setLoading] = useState(false);

    const onEditUser = () => {
        setShowEditUserModal(true);
    };
    const onResendWelcomeEmail = () => {
        if (!selectedUser) {
            console.error('No selected user');
            return;
        }
        const action = traceAction('welcomeEmails', 'create') as Action;
        const newRef = doc(collection(firestore, 'welcomeEmails'));
        action.docId = newRef.id;
        action.data = {selectedUser};
        const batch = splittableBatch(firestore, 20 - 0);

        batch.set(
            newRef,
            {
                emailedTo: selectedUser.id,
                sentBy: userId,
                whenAdded: action.whenAction,
                isExpired: false
                //origin: getServerUrl()
            }
        );

        action.save(`Resend welcome email to ${selectedUser.firstName} ${selectedUser.lastName}`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });
        // newRef.set({
        //     emailedTo: selectedUser.id,
        //     sentBy: userId,
        //     whenAdded: action.whenAction,
        //     isExpired: false
        //     //origin: getServerUrl()
        // }).then(() => {
        //     action.reportSuccess();
        // }).catch((error) => {
        //     action.reportError(error.message, error);
        // });

        showToast(`Sending a welcome email to ${selectedUser?.firstName} ${selectedUser?.lastName}...`);
        setShowModal(false);
    };

    const onArchiveUser = () => {
        if (!selectedUser) {
            console.error('No selected user');
            return;
        }
        confirmAction('Are you sure you want to archive this user?', 'Yes, archive').then(() => { 
            const action = traceAction('users', 'archive') as Action;
            action.docId = selectedUser.id;
            const batch = splittableBatch(firestore, 20 - 0);
            batch.set(
                doc(firestore, 'users', selectedUser.id as string),
                {
                    archivedBy: userId,
                    whenArchived: action.whenAction,
                    state: 'archived'
                },
                { merge: true }
            );

            batch.set(
                doc(firestore, 'userDetails', selectedUser.id as string),
                {
                    state: 'archived',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'userDetails');
            onCollectionUpdated(batch, 'users');

            logAction(
                batch,
                'Archive',
                'users',
                selectedUser.id as string,
                renderFullName(selectedUser),
                undefined,
                [selectedUser.id as string]
            );

            action.data = {selectedUser};
            action.save(`Archive user ${renderFullName(selectedUser)}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            showToast('User has now been archived');
            setShowModal(false);
        }).catch(() => {});
    };

    const onResetLogin = () => {
        if (!selectedUser) {
            console.error('No selected user');
            return;
        }
        confirmAction('Are you sure you want to reset this user\'s login?\nNote: They will no longer be able to login until they respond to a new welcome email we\'ll send them.', 'Yes, reset login').then(() => {
            setLoading(true);

            httpsCallable(functions, 'resetAccount')({
                userId: selectedUser.id,
                //email: selectedUser.email
            }).then((result: any) => {
                console.log('resetAccount result', result);
                if (result.data.error) {
                    reportError(`Failed to reset login for ${selectedUser.firstName}  ${selectedUser.lastName}`, result.data.error);
                } else {
                    onResendWelcomeEmail();
                }
            }).catch((error) => {
                console.log('resetAccount error', error);
            }).finally(() => {
                setLoading(false);
            });

        }).catch(() => {});
    };
   
    return (<>
        <div className="crew-particulars">
            <SeaGrid>
                <SeaGridCell label="Position" w="33">
                    {formatValue(selectedUser?.position)}
                </SeaGridCell>
                {selectedUser?.isStaff &&
                    <>
                        <SeaGridCell label="Date of birth" w="33">
                            {formatValue(selectedUserDetails?.dateOfBirth && formatDate(selectedUserDetails?.dateOfBirth))}
                        </SeaGridCell>
                        <SeaGridCell label="Inducted date" w="33">
                            {formatValue(selectedUser?.whenInducted ? formatDate(selectedUser?.whenInducted) : '')}
                        </SeaGridCell>
                    </>
                }
                <SeaGridCell label="Email" w="33">
                    {selectedUserDetails?.email}
                </SeaGridCell>
                <SeaGridCell label="Phone #" w="33">
                    {formatValue(selectedUserDetails?.contactNumber)}
                </SeaGridCell>
                <SeaGridCell label="Address" w="33">
                    {formatValue(selectedUserDetails?.address)}
                </SeaGridCell>
                {(!selectedUser?.isStaff || selectedUser?.isLicensee) && <>
                    <SeaGridCell label="Company Name" w="33">
                        {formatValue(selectedUserDetails?.companyName)}
                    </SeaGridCell>
                </>}
                {selectedUser?.isLicensee && <>
                    <SeaGridCell label="Company Address" w="33">
                        {formatValue(selectedUserDetails?.companyAddress)}
                    </SeaGridCell>
                </>}
                {selectedUser?.isStaff &&
                    <>
                        <SeaGridCell label="Passport #" w="33">
                            {formatValue(selectedUserDetails?.passportNumber)}
                        </SeaGridCell>
                        <SeaGridCell label="Next of kin" w="33">
                            {formatValue(selectedUserDetails?.nextOfKin?.name)}
                        </SeaGridCell>
                        <SeaGridCell label="Next of kin relationship" w="33">
                            {formatValue(selectedUserDetails?.nextOfKin?.relationship)}
                        </SeaGridCell>
                        <SeaGridCell label="Next of kin contact" w="33">
                            {formatValue(selectedUserDetails?.nextOfKin?.phone)}
                        </SeaGridCell>
                        {/* Removed as we don't want to store this information on the system */}
                        {/* <SeaGridCell label="Bank account #" w="33">
                            {formatValue(selectedUserDetails?.bankAccountNumber)}
                        </SeaGridCell>
                        <SeaGridCell label={licenseeSettings && regions[licenseeSettings?.region]?.taxReg} w="33">
                            {formatValue(selectedUserDetails?.irdNumber)}
                        </SeaGridCell> */}
                        <SeaGridCell label="Medical doctor" w="33">
                            {formatValue(selectedUserDetails?.medicalDoctor?.name)}
                        </SeaGridCell>
                        <SeaGridCell label="Doctor contact" w="33">
                            {formatValue(selectedUserDetails?.medicalDoctor?.phone)}
                        </SeaGridCell>
                        <SeaGridCell label="Medical issues" w="33">
                            {formatValue(selectedUserDetails?.currentMedicalIssues)}
                        </SeaGridCell>
                        <SeaGridCell label="Current medication" w="33">
                            {formatValue(selectedUserDetails?.currentMedication)}
                        </SeaGridCell>
                        <SeaGridCell label="Previous injuries/surgeries" w="33">
                            {formatValue(selectedUserDetails?.previousInjuryOrSurgery)}
                        </SeaGridCell>
                        <SeaGridCell label="Allergies" w="33">
                            {formatValue(selectedUserDetails?.allergies)}
                        </SeaGridCell>
                        <SeaGridCell label="Blood type" w="33">
                            {formatValue(selectedUserDetails?.bloodType)}
                        </SeaGridCell>
                        <SeaGridCell label="Vessel / Facility Access" w="33">
                            {vessels?.all?.map((vessel) => {
                                if (selectedUser?.vesselIds?.find((id: string) => id === vessel.id)) {
                                    return vessel.name;
                                }
                                return undefined;
                            }).filter(Boolean).join(', ')}
                        </SeaGridCell>
                    </>
                }
                <SeaGridCell label="Status" w="33">
                    {
                        selectedUser?.whenActivated ? `Activated ${formatDate(selectedUser?.whenActivated)}` : (
                            selectedUser?.whenWelcomed ? `Emailed ${formatDate(selectedUser?.whenWelcomed)}` : (
                                `Added ${formatDate(selectedUser?.whenAdded)}`
                            )
                        )
                    }
                </SeaGridCell>
            </SeaGrid>
        </div>
        <RequirePermissions
            role="crewParticulars"
            level={permissionLevels.EDIT}
        >
            <div className="view-modal-buttons">
                {selectedUser?.state !== 'archived' && <>
                    <SeaButton disabled={loading} zone="white" onClick={(e) => onEditUser()}><SeaIcon icon="edit" slot="start" />Edit Profile</SeaButton>
                    {!selectedUser?.isLoginDisabled && (
                        !selectedUser?.whenActivated ? <>
                            <div className="spacer-wide"></div>
                            <SeaButton disabled={loading} zone="white" onClick={(e) => onResendWelcomeEmail()}>Resend Welcome Email</SeaButton>
                        </>
                        :
                        <>
                            <div className="spacer-wide"></div>
                            <SeaButton disabled={loading} zone="white" onClick={(e) => onResetLogin()}>Reset Login</SeaButton>
                        </>
                    )}
                    <RequirePermissions
                        role="crewParticulars"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        {(selectedUser?.id !== userId && selectedUser?.id !== licenseeId) &&
                            <SeaLinkButton mode="standard-link" onClick={(e) => onArchiveUser()}>Archive Profile</SeaLinkButton>
                        }
                    </RequirePermissions>
                </>}
            </div>
        </RequirePermissions>
    </>);
};

export default UserProfileTab;
