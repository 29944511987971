import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { registerFiles } from "../../lib/fileSync";
import { getDayOffsetMillis, warnDays } from "../../lib/util";

//
// Loads safety check items
//

export interface SafetyCheckItem extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    description?: string;
    faultJobId?: string;
    files: string[];
    hasFault?: boolean;
    interval: string;
    isCritical?: boolean;
    itemId: string;
    location?: string
    locationId?: string;
    name?: string;
    state: string;
    vesselId: string;
    whenDeleted?: number;
    whenDue: number;
    whenFaulted?: number;
    whenLastChecked: number;
    categoryId: string;
}

export type SafetyCheckItemsData = {
    all: SafetyCheckItem[],
    byCategoryId: {
        [categoryId: string]: SafetyCheckItem[]
    }
    byId: {
        [id: string]: SafetyCheckItem
    },
    prioritised: SafetyCheckItem[],
    count: number
};

export const safetyCheckItemsConfig: SharedStateConfig<SafetyCheckItemsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSafetyItems', 'todayMillis'],
    countLiveDocs: () => sharedState.safetyCheckItems.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSafetyItems = sharedState.vesselSafetyItems.current;
        if (
            vesselId &&
            vesselSafetyItems?.ids &&
            canView('safetyEquipmentChecks')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckItems'),
                    where('vesselId', '==', vesselId),
                    where('state', 'in', ['active', 'completed']),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const items = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckItem;
                    });
                    const byId = {} as {
                        [id: string]: SafetyCheckItem
                    };
                    const byCategoryId = {} as {
                        [categoryId: string]: SafetyCheckItem[]
                    }
                    const byItemId = {} as {
                        [id: string]: SafetyCheckItem[]
                    };
                    items.forEach((item: SafetyCheckItem) => {
                        registerFiles(item.files);
                        byId[item.id] = item;
                        if (byItemId[item.itemId] === undefined && item.state === 'active') {
                            byItemId[item.itemId] = [];
                        }
                        if (byCategoryId[item.categoryId] === undefined && item.state === 'active') {
                            byCategoryId[item.categoryId] = [];
                        }
                        byItemId[item.itemId].push(item);
                    });

                    let prioritised = [...items].filter((item) => item.state === 'active');
                    prioritised.sort((a, b) => {
                        return (
                            a.whenDue -
                            (a.hasFault ? 1000000000000 : 0) -
                            (b.whenDue - (b.hasFault ? 1000000000000 : 0))
                        );
                    });
                    const maxWhenDue = getDayOffsetMillis(
                        warnDays.safetyEquipmentChecks[warnDays.safetyEquipmentChecks.length - 1]
                    );
                    for (let i = 0; i < prioritised.length; i++) {
                        if (
                            prioritised[i].whenDue > maxWhenDue &&
                            !prioritised[i].hasFault
                        ) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }
                    const all = [] as SafetyCheckItem[];
                    vesselSafetyItems.ids.forEach((id: string) => {
                        if (byItemId[id]) {
                            all.push(...byItemId[id]);
                        }
                    });
                    // After populating byCategoryId, sort each category's items
                    Object.keys(byCategoryId).forEach(categoryId => {
                        byCategoryId[categoryId].sort((a, b) => {
                            const nameA = vesselSafetyItems.byId[a.itemId]?.name?.toLowerCase() || ''; // Fallback to empty string if undefined
                            const nameB = vesselSafetyItems.byId[b.itemId]?.name?.toLowerCase() || '';
                            return nameA.localeCompare(nameB);
                        });
                    });
                    set({
                        all: all,
                        byCategoryId,
                        prioritised: prioritised,
                        byId,
                        count: items.length
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access safety check items for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    }
};