import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { ArchivableDocument, CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { registerFiles } from "../../lib/fileSync";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { renderFullNameForUserId } from "../Core/users";

//
// Loads crew certificates (active)
//

export interface CrewCertificate extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    emailReminder?: string;
    files: string[];
    heldBy: string;
    issuedBy?: string;
    licenseeId: string;
    state: string;
    searchText?: string;
    title: string;
    type: string;
    wasRenewed?: boolean;
    whenExpires?: number;
    whenIssued: number;
    whenToRemind?: number;
}

export type CertificateHolderInfo = {
    id: string;
    name: string,
    certificates: CrewCertificate[]
};

export type CrewCertificatesData = {
    prioritised: CrewCertificate[],
    top5: CrewCertificate[],
    byId: {
        [id: string]: CrewCertificate
    },
    holdersSorted: CertificateHolderInfo[],
    holders: {
        [holderId: string]: CertificateHolderInfo
    },
    numHighestPriority: number,
    count: number
};

export const crewCertificatesConfig: SharedStateConfig<CrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'licenseeId', 'users' ],
    countLiveDocs: () => sharedState.crewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        if (userId && licenseeId && users) {
            return onSnapshot(
                query(
                    collection(firestore, 'crewCertificates'),
                    canView('crewCertificates') ? (
                        where('licenseeId', '==', licenseeId)
                    ) : (
                        where('heldBy', '==', userId)
                    ),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const _certificates = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data()
                        } as CrewCertificate;
                        let searchText = item.title
                        if (item.heldBy) {
                            searchText += renderFullNameForUserId(item.heldBy).toLowerCase();
                        }
                        if (item.issuedBy) {
                            searchText += item.issuedBy;
                        }
                        return {
                            ...item,
                            searchText
                        }
                    });
                    const filteredCertificates = [] as CrewCertificate[];
                    const holders = {} as {
                        [holderId: string]: CertificateHolderInfo
                    };
                    const holdersSorted: CertificateHolderInfo[] = [];
                    const byId = {} as {
                        [id: string]: CrewCertificate
                    };
                    _certificates.forEach((certificate: CrewCertificate) => {
                        registerFiles(certificate.files);
                        if (users.byId[certificate.heldBy] && users.byId[certificate.heldBy].state === 'active') { // Only include certificates that are held by active users
                            filteredCertificates.push(certificate)
                            byId[certificate.id] = certificate;
                            if (holders[certificate.heldBy]) {
                                holders[certificate.heldBy].certificates.push(certificate);
                            } else {
                                holders[certificate.heldBy] = {
                                    id: certificate.heldBy,
                                    name: renderFullNameForUserId(certificate.heldBy),
                                    certificates: [certificate]
                                };
                                holdersSorted.push(holders[certificate.heldBy]);
                            };
                        };
                    });
                    holdersSorted.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    
                    let prioritised = [...filteredCertificates] as CrewCertificate[];
                    prioritised.sort((a, b) => {
                        return ((a.type === 'renewable' ? a.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) -
                                (b.type === 'renewable' ? b.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER));
                    });
                    // prioritised should only contain whenExpires up to a set amount days in the future
                    // (and should not contain any nonExpiring either)
                    const maxWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[warnDays.crewCertificates.length - 1]);
                    const minWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[0]);
                    let numHighestPriority = 0
                    for (let i = 0; i < prioritised.length; i++) {
                        if (prioritised[i].whenExpires && prioritised[i].whenExpires as number < minWhenExpires && prioritised[i].type !== 'nonExpiring') {
                            numHighestPriority++
                        }
                        if (prioritised[i].type === 'nonExpiring' || (prioritised[i].whenExpires && prioritised[i].whenExpires as number >= maxWhenExpires)) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        };
                    };
                    const top5 = prioritised.slice(0, 5).filter((p) => p.whenExpires && p.whenExpires < minWhenExpires);
                    set({
                        prioritised,
                        top5,
                        byId,
                        holdersSorted,
                        holders,
                        numHighestPriority,
                        count: _certificates.length
                    });
    
                }, (error) => {
                    // This should be very rare
                    done();
                    console.log('Failed to access crew certificates ', error);
                }
            );
        }
    }
};
