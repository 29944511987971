import * as React from "react";

function SvgX(props) {
  return (
    <svg
      width={14}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.23 6.35l3.516-3.516a1.044 1.044 0 000-1.547l-.774-.773a1.044 1.044 0 00-1.546 0L6.91 4.029 3.36.514a1.044 1.044 0 00-1.548 0l-.773.773a1.044 1.044 0 000 1.547L4.555 6.35 1.039 9.9a1.044 1.044 0 000 1.547l.773.774a1.044 1.044 0 001.547 0L6.91 8.705l3.516 3.516a1.044 1.044 0 001.546 0l.774-.774a1.044 1.044 0 000-1.547L9.23 6.35z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgX;
