import React, { useCallback, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { toMillis, formatDatetime, formatCurrency } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Job } from '../../../../shared-state/VesselMaintenance/jobs';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { makeSeaFiles } from '../../../../lib/files';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface UpdateJobListProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: Job,
    level?: number
}

const UpdateJobList: React.FC<UpdateJobListProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const users = sharedState.users.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = {
        notes: ''
    };

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm , isValid, isSubmitting} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            notes: Yup.string().max(5000).required()
        }), onSubmit: (data) => {
            if (!itemToUpdate?.id) {
                return;
            }
            // Process form
            const action = traceAction('jobs', 'update') as Action;
            action.docId = itemToUpdate.id;
            const batch = splittableBatch(firestore, 20 - 0);

            const description = `${(itemToUpdate.description ? (itemToUpdate.description.trim()+'\n\n') : '')}${data.notes.trim()}\n(${renderFullName()}, ${formatDatetime(null, ', ')})`;

            batch.set(
                doc(firestore, 'jobs', itemToUpdate.id),
                {
                    updatedBy: userId,
                    whenUpdated: action.whenAction,
                    description: description,
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            let equipmentItem = undefined;
            if (itemToUpdate?.equipmentId && equipment?.byId && equipment.byId[itemToUpdate.equipmentId]) {
                equipmentItem = equipment.byId[itemToUpdate.equipmentId];
            }

            let assignedToName = '';
            if (itemToUpdate.assignedTo?.userId) {
                assignedToName = renderFullName(users?.byId[itemToUpdate.assignedTo.userId]);
            } else if (itemToUpdate.assignedTo?.contactId) {
                assignedToName = `${contacts?.byId[itemToUpdate.assignedTo.contactId]?.name} (Contact)`;
            }
            sendVesselNotification(batch, 'jobCreated', 'jobs', {
                isUpdate: true,
                id: itemToUpdate.id,
                task: itemToUpdate.task,
                description: description,
                jobNum: itemToUpdate.jobNum ? itemToUpdate.jobNum : undefined,
                priority: itemToUpdate.priority, 
                assignedToName: assignedToName ? assignedToName : undefined,
                tags: itemToUpdate.tags ? itemToUpdate.tags?.join(', ') : undefined,
                system: (equipmentItem?.system) ? equipmentItem.system : undefined,
                equipment: (equipmentItem?.equipment) ? equipmentItem.equipment : undefined,
                location: itemToUpdate.location?.trim() ? itemToUpdate.location.trim() : undefined,
                isCritical: itemToUpdate.isCritical ? itemToUpdate.isCritical : undefined,
                whenDue: itemToUpdate.whenDue ? toMillis(itemToUpdate.whenDue as unknown as string) : undefined,
                estimatedCost: (itemToUpdate.estimatedCost) ? formatCurrency(itemToUpdate.estimatedCost, 2) : undefined
            }, makeSeaFiles(itemToUpdate?.files));

            onCollectionUpdated(batch, 'jobs');
            logAction(
                batch,
                'Update',
                'jobs',
                itemToUpdate.id,
                itemToUpdate.task,
                [itemToUpdate.vesselId]
            );

            action.data = {
                itemToUpdate,
                description,
                equipmentItem
            };
            action.save(`Append job description ${itemToUpdate.task}`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });

    const isModalDirty = useCallback(() => {
        return values.notes !== undefined && values.notes.length > 0;
    }, [values]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title="Update Task"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="thin"
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes to Add"
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">Update Task With Notes</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default UpdateJobList;
