import React, { useCallback } from 'react';
import { SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import { IonPopover } from '@ionic/react';
import { SeaHelp } from '../../components/SeaContextualHelp/SeaContextualHelp';
import { formatTextAreaText, isImage, revealImage } from '../../lib/util';
import { getCachedFileSrc } from '../../lib/fileSync';
import { getExtFromString, getImgSrcFromExt, getImgSrcFromString } from '../../lib/files';
import { viewFilesFullScreen } from '../FileViewerManager/FileViewerManager';
import './ContextualHelpManager.css';

//
// Handles showing the user ContextualHelp
//

export type ContextualHelp = {
    event: any,
    showPopover: boolean,
    help?: SeaHelp,
    imgSrcs?: string[]
};

export const contextualHelpConfig: SharedStateConfig<ContextualHelp> = {
    isAlwaysActive: true,
    default: {
        event: undefined,
        showPopover: false,
        help: undefined,
        imgSrcs: undefined
    },
    notes: 'Allows components to show contextual help.'
};

export const openContextualHelp = (event: any, help: SeaHelp) => {
    event.stopPropagation();
    // event.persist(); // No longer needed in React 17+
    if (help.files && help.files.length > 0) {
        const promises = [] as Promise<any>[];
        const imgSrcs = [] as string[];
        help.files.forEach((file: string, index: number) => {
            const ext = getExtFromString(file);
            if (isImage(ext)) {
                promises.push(
                    getCachedFileSrc(file, 'F').then((src) => {
                        imgSrcs[index] = src;
                    }).catch((e) => {
                        //imgSrcs[index] = getImgSrcFromString(file, (index === 0) ? 'medium' : 'tiny');
                        return getImgSrcFromString(file, (index === 0) ? 'medium' : 'tiny').then((_imgSrc) => {
                            imgSrcs[index] = _imgSrc;
                        })
                    })
                );
            } else {
                imgSrcs[index] = getImgSrcFromExt(ext, 'medium');
            }
        });
        Promise.all(promises).then(() => {
            // setContextualHelp({
            //     event: event,
            //     showPopover: true,
            //     help: help,
            //     imgSrcs: imgSrcs
            // });
            sharedState.contextualHelp.set({
                event: event,
                showPopover: true,
                help: help,
                imgSrcs: imgSrcs
            });
        });
    } else {
        // setContextualHelp({
        //     event: event,
        //     showPopover: true,
        //     help: help,
        //     imgSrcs: undefined
        // });
        sharedState.contextualHelp.set({
            event: event,
            showPopover: true,
            help: help,
            imgSrcs: undefined
        });
    }
};

const ContextualHelpManager: React.FC = () => {
    const contextualHelp = sharedState.contextualHelp.use();
    
    const closeContextualHelp = useCallback(() => {
        sharedState.contextualHelp.clear();
    }, []);

    return (
        <>
            {contextualHelp?.help &&
                <IonPopover
                    event={contextualHelp.event}
                    isOpen={contextualHelp.showPopover}
                    onDidDismiss={(e) => closeContextualHelp()}
                    cssClass={`sea-help-popover${((contextualHelp.help.files === undefined || contextualHelp.help.files.length === 0) && (contextualHelp.help.text && contextualHelp.help.text.length < 250)) ? ' small': ''}`}
                    //mode="ios"
                >
                    <div
                        className="sea-help-popover-content no-select"
                        onClick={(e) => closeContextualHelp()}
                    >
                        {contextualHelp?.help?.files && contextualHelp.help.files.length > 0 && contextualHelp.imgSrcs &&
                            <>
                                <div
                                    className="img-container"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        viewFilesFullScreen(contextualHelp.help?.files!, 0);
                                        closeContextualHelp();
                                    }}
                                >
                                    <img
                                        className="sea-help-img sea-file-image fade"
                                        src={contextualHelp.imgSrcs[0]}
                                        onLoad={revealImage}
                                        alt="Help"
                                    />
                                </div>
                                {contextualHelp.help.files.length > 1 &&
                                    <>
                                        <div className="img-container-tiny">
                                            {contextualHelp.help.files.map((file: any, index: number) => {
                                                if (index === 0) return undefined;
                                                return <img
                                                    key={index}
                                                    className="sea-help-img-tiny sea-file-image fade"
                                                    src={contextualHelp.imgSrcs?.[index]}
                                                    onLoad={revealImage}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        viewFilesFullScreen(contextualHelp.help?.files!, index);
                                                        closeContextualHelp();
                                                    }}
                                                    alt="View Files"
                                                />;
                                            })}
                                        </div>
                                    </>
                                }
                                {contextualHelp.help.text &&
                                    <div style={{ height: '8px' }}></div>
                                }
                            </>
                            // <SeaFileImage files={files} size="medium" showOthers/>
                        }
                        {contextualHelp.help.text && formatTextAreaText(contextualHelp.help.text)}
                    </div>
                </IonPopover>
            }
        </>
    );
};

export default ContextualHelpManager;
