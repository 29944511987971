import React, { useCallback, useMemo } from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn } from "../../lib/pdf";
import { formatValue } from "../../lib/util";
import { renderFullName } from "../../shared-state/Core/users";
import { renderCategoryName } from "../../lib/categories";
import { sharedState } from '../../shared-state/shared-state';
import { Equipment } from '../../shared-state/VesselMaintenance/equipment';
import { getImgSrcFromString } from "../../lib/files";
import SeaPdfGenerator from "../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../components/PdfItemsHeader/PdfItemsHeader";
import CriticalFlagPDF from "../../assets/svg/CriticalFlagPDF";

interface EquipmentListPdfProps {
    generatingPdf: boolean;
    setGeneratingPdf: (generatingPdf: boolean) => void;
    categories: {
        [key: string]: Equipment[];
    };
}

const EquipmentListPdf: React.FC<EquipmentListPdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    categories,
}) => {
    const vessel = sharedState.vessel.use(generatingPdf);
    const vesselLocations = sharedState.vesselLocations.use(generatingPdf);
    const vesselSystems = sharedState.vesselSystems.use(generatingPdf);
    const title = `Equipment List - ${vessel?.name}`;

    const firstIndex = useMemo(() => {
        let index = 0;
        if (categories) {
            for (const value of Object.values(categories)) {
                if (value) {
                    return index;
                }
                index++;
            }
        }
    }, [categories]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Equipment</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Location</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Make</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Model</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Serial #</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                    </View>
                    <View
                        wrap={false}
                        style={pdfStyles.itemHeadingsLine}
                    ></View>
                    
                    {Object.entries(categories)?.map(([systemId, values], i: number) => {
                            if ( !values ) {
                                return null
                            }
                            else 
                                return <React.Fragment key={systemId}>
                                    {/* Render System Text */}
                                    <View style={pdfStyles.labelHeading} wrap={false}>
                                        {firstIndex !== i && (
                                            <View
                                                style={pdfStyles.itemsCategorySpacer}
                                            ></View>
                                        )}
                                        <Text style={pdfStyles.itemsCategory}>
                                            {renderCategoryName(
                                                    systemId,
                                                    vesselSystems
                                                )}
                                        </Text>
                                    </View>
                                    {/* Map each item in this system */}
                                    {values.map(
                                    (item: Equipment, index: number) => {
                                        const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                        return (
                                            <React.Fragment key={item.id}>
                                                {index > 0 && (
                                                    <View
                                                        style={pdfStyles.itemsRowSpacer}
                                                    ></View>
                                                )}
                                                <View
                                                    wrap={false}
                                                    style={pdfStyles.itemsRow}
                                                >
                                                    <View style={pdfStyles.thumbColumn}>
                                                        {imgSrc && 
                                                            <Image
                                                                src={imgSrc}
                                                                style={pdfStyles.thumbImg}
                                                            />
                                                        }
                                                    </View>
                                                    <View style={styleItemColumn(1.6)}>
                                                        <Text>
                                                            {item.equipment}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text>
                                                            {formatValue(
                                                                renderCategoryName(
                                                                    item?.locationId,
                                                                    vesselLocations
                                                                )
                                                            )}
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={styleItemColumn(1)}
                                                    >
                                                        <Text>
                                                           {item.make}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text>
                                                           {item.model}
                                                        </Text>
                                                    </View>
                                                     <View style={styleItemColumn(1)}>
                                                        <Text>
                                                           {item.serial}
                                                        </Text>
                                                    </View>
                                                    <View style={pdfStyles.criticalColumn}>
                                                        {item.isCritical && (
                                                            <CriticalFlagPDF />
                                                        )}
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            }
                        )}
                </Page>
            </Document>
        );
    }, [title, categories, firstIndex, vesselSystems, vesselLocations]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="EquipmentList"
        />
    );
};

export default EquipmentListPdf;
