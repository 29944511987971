import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { isPlatform } from '@ionic/react';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import { StatusBar, Style } from '@capacitor/status-bar';

// Add classes for different platforms
const root = document.getElementById('root');
if (isPlatform('ios')) root?.classList.add('platform-ios');
if (isPlatform('android')) root?.classList.add('platform-android');
if (isPlatform('hybrid')) root?.classList.add('platform-hybrid');
//if (true) root?.classList.add('platform-hybrid'); // To force android emulator to think it is a device -- Also need to comment out desktop below
if (isPlatform('desktop')) root?.classList.add('platform-desktop');
if (isPlatform('ipad')) root?.classList.add('platform-ipad');
if (isPlatform('iphone')) root?.classList.add('platform-iphone');
if (isPlatform('tablet')) root?.classList.add('platform-tablet');
if (isPlatform('mobile')) root?.classList.add('platform-mobile');

// Configure status bar for ios
if (isPlatform('ios') && isPlatform('hybrid')) {
    //StatusBar.show();
    // StatusBar obsolete
    // StatusBar?.setStyle({
    //     style: Style.Dark // light text on dark background
    // });
}


ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
