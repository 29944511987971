import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { registerFiles } from '../../lib/fileSync';
import { UserType } from '../Core/user';
import { useEffect, useState } from 'react';

export interface UserDocument extends CreateableDocument, UpdateableDocument {
    deletedBy?: string;
    files: string[];
    licenseeId: string;
    name: string;
    state: string;
    userId: string;
    vesselIds?: string[];
    whenDeleted?: number;
}

export type CrewDocumentsData = {
    byId: { [id: string]: UserDocument },
    all: UserDocument[]
};

export const useCrewDocuments = (selectedUser?: UserType) => {
    const licenseeId = sharedState.licenseeId.current;
    const [crewDocuments, setCrewDocuments] = useState<CrewDocumentsData>();
    
    useEffect(() => {
        setCrewDocuments(undefined);
        if (selectedUser && licenseeId) {
            return onSnapshot(
                query(
                    collection(firestore, 'userDocuments'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    where('userId', '==', selectedUser.id),
                    orderBy('whenAdded', 'desc')
                ),
                (snap) => {
                    const byId = {} as {
                        [id: string]: UserDocument
                    };
                    const all = snap.docs.map((doc) => {
                        const o = {
                            id: doc.id,
                            ...doc.data()
                        } as UserDocument;
                        byId[o.id] = o;
                        registerFiles(o.files);
                        return o;
                    });
                    setCrewDocuments({
                        byId,
                        all
                    });
    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access crew documents for user ${selectedUser.id} `, error);
                }
            );
        }
    }, [selectedUser, licenseeId]);

    return crewDocuments;
};
