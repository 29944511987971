import { collection, getDocs, query, where } from "firebase/firestore";
import { analytics, firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { logEvent as logAnalyticsEvent, setUserId as setAnalyticsUserId, setUserProperties as setAnalyticsUserProperties } from "firebase/analytics";
import { refreshApp } from "../../App";
import { logoutUser } from "./user";
import { checkSessionTimeout } from "./handleSessionTimeout";

//
// Handles security claims (it's sourced within the Firebase Authentication object and is how the majority of backend authentication is done).
// 1. Initially, will attempt to get a stale token - this is so auto-login will work while offline.
// 2. Once online, we will verify by getting a fresh token using authUser.getIdTokenResult.
// 3. Checks if security claims are old, and if they are, restart the app
//

// Initially, will attempt to get a stale token - this is so auto-login will work while offline.
export const handleSecurityClaimsStaleConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['authUser'],
    default: 'Not initialised',
    run: (done, set, clear) => {
        const authUser = sharedState.authUser.current;
        if (authUser) {
            // Get claims that don't need to be fresh (will be fast and work offline)
            authUser.getIdTokenResult(false).then((result: any) => {
                done();
                set(`Received stale idToken for firestoreUserId=${result.claims.firestoreUserId}`);
                setAnalyticsUserId(analytics, result.claims.firestoreUserId);
                logAnalyticsEvent(analytics, 'login', {method: 'password'});
                
                
                console.log(`result.claims (stale) claimsLength=${JSON.stringify(result.claims).length} idTokenLength=${JSON.stringify(result).length}`, result.claims);
                // const o = {
                //     firestoreUserId: result.claims.firestoreUserId,
                //     licenseeId: result.claims.licenseeId,
                //     role: result.claims.role,
                //     sfma: result.claims.sfma,
                //     sfmss: result.claims.sfmss,
                //     state: result.claims.state,
                //     vesselIds: result.claims.vesselIds,
                //     whenRoleChanged: result.claims.whenRoleChanged
                // };
                // console.log(`size of my bits=${JSON.stringify(o).length}`, o);

                sharedState.securityClaims.set({
                    ...result.claims,
                    isFresh: false
                });
                if (result.claims.isSuperAdmin) {
                    sharedState.superAdminId.set(result.claims.firestoreUserId);
                    sharedState.userId.clear();
                    setAnalyticsUserProperties(
                        analytics,
                        {
                            type: 'superAdmin',
                            isSuperAdmin: true
                        }
                    );
                } else {
                    sharedState.superAdminId.clear();
                    sharedState.userId.set(result.claims.firestoreUserId);
                }
            }).catch((error: any) => {
                done();
                set('Failed to obtain stale idToken');
                console.log('Error getting stale claims', error);
                //console.log(`Fetching user based on authUser.uid=${authUser.uid}...`);
                // Should have user with uid in cached data
                
                getDocs(
                    query(
                        collection(firestore, 'users'),
                        where('uid', '==', authUser.uid)
                    )
                ).then((snap) => {
                    if (snap.docs.length > 0) {
                        //console.log(`Found userId=${snap.docs[0].id} data=${JSON.stringify(snap.docs[0].data())}`);
                        sharedState.userId.set(snap.docs[0].id);
                    } else {
                        console.error(`No user found for authUser.uid=${authUser.uid}`);
                    }
                }).catch((error) => {
                    console.error(`Error getting user based on authUser.uid=${authUser.uid}`, error);
                });
            });
        } else {
            //console.log('Did not get authUser! (get stale claims)');
            set('Need authUser to initialise');
            sharedState.superAdminId.clear();
            sharedState.userId.clear();
        }
    }
};

// Once online, we will verify by getting a fresh token using authUser.getIdTokenResult.
export const handleSecurityClaimsFreshConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['userId', 'onlineStatus', 'authUser'],
    default: 'Not initialised',
    run: (done, set, clear) => {
        done();
        const userId = sharedState.userId.current;
        const onlineStatus = sharedState.onlineStatus.current;
        if (userId && !onlineStatus?.isOnline) {
            set(`Got userId=${userId} but am offline so wont attempt to get fresh claims.`);
        }
        const authUser = sharedState.authUser.current;
        if (authUser && userId && onlineStatus?.isOnline) {
            // Get claims that must be fresh (must be online)
            authUser.getIdTokenResult(true).then((result: any) => {
                set(`Received fresh idToken for firestoreUserId=${result.claims.firestoreUserId}`);
                sharedState.securityClaims.set({
                    ...result.claims,
                    isFresh: true
                });

                // Do we need to check if userId or superAdminId changed? Very likely not.
                if (result.claims.isSuperAdmin) {
                    // Is superAdmin, therefore we don't need to check for MFA or session timeout
                } else if (result.claims.sfok === undefined || result.claims.sfmss === undefined) {
                    // This is a invalid security token (custom token generated by signIn function should include sfok=true)
                    // Need to log out to prevent an infinite loop of logging in.
                    set(`Invalid security token. Logging out!`);
                    //if (debugRefresh) refreshApp(`Invalid security token (normally would logout) result.claims=${JSON.stringify(result.claims)}`);
                    logoutUser().then(() => {
                        console.log('Logged out due to an invalid security token...');
                    }).finally(() => {
                        refreshApp(`Logged out due to invalid security token...`);
                    });
                } else {
                    checkSessionTimeout();
                }
            }).catch((error: any) => {
                console.error('Error getting fresh claims', error);
            });
        } else {
            set(`Can't obtain while offline or unauthenticated`);
        }
    }
};

// Checks if security claims are old, and if they are, restart the app
export const handleOldSecurityClaimsConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['user', 'superAdmin', 'securityClaims'],
    default: 'None',
    notes: 'Used to check if security claims are old (it they are, refreshes app)',
    run: (done, set, clear) => {
        done();
        const securityClaims = sharedState.securityClaims.current;
        const user = sharedState.user.current;
        const superAdmin = sharedState.superAdmin.current;
        if (superAdmin) {
            // superAdmin doesn't have roles that change
            set('Irrelevant for superadmin');
        } else if (
            securityClaims &&
            securityClaims.isFresh &&
            securityClaims.whenRoleChanged &&
            user &&
            !user.fromCache &&
            user.whenRoleChanged
        ) {
            if (user.whenRoleChanged > securityClaims.whenRoleChanged) {
                set('Security claims are old! Restarting app!');
                refreshApp(`user.whenRoleChanged > whenRoleChanged (${user.whenRoleChanged} > ${securityClaims.whenRoleChanged}) user.fromCache=${user.fromCache}`);
            } else {
                set('Security claims are up to date');
            }
        } else {
            set('Not enough information to determine if security claims are old');
        }
    }
};

