import { IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaTextarea.css';

interface SeaTextareaProps {
    zone?: 'blue' | 'white' | 'grey',
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    name?: string,
    id?: string,
    label?: string,
    help?: any,
    value?: string,
    placeholder?: string,
    error?: string,
    disabled?: boolean,
    height?: number,
    minLength?: number,
    maxLength?: number,
    readonly?: boolean,
    required?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onkeyup?: (e: CustomEvent) => void
}

const SeaTextarea = React.forwardRef<HTMLInputElement, SeaTextareaProps>(({ zone, inputmode, name, id, label, help, value, placeholder, error, disabled, height = 140, minLength, maxLength, readonly, required, onchange, onblur, onfocus, onkeyup }, forwardedRef:any) => {
    
    const [hasFocus, setHasFocus] = useState(false);
    
    let color = 'primary';
    switch (zone) {
        case 'blue':
            color = 'light'; // white
            break;
        case 'white':
        case 'grey':
            color = 'primary'; // blue
            break;
    }

    const onIonFocus = (e: CustomEvent) => {
        setHasFocus(true);
        if (onfocus) {
            onfocus(e);
        }
    }
    const onIonBlur = (e:CustomEvent) => {
        setHasFocus(false);
        if (onblur) {
            onblur(e);
        }
    }

    return (
        <>
            {label && <SeaLabel help={help}>{label}</SeaLabel>}
            <IonTextarea
                style={{'--sea-textarea-height': `${height}px`}}
                className={`sea-input sea-textarea ${hasFocus ? 'has-focus' : ''} ${zone}-zone ${error ? 'has-error' : ''}`}
                ref={forwardedRef}
                color={color}
                inputmode={inputmode}
                name={name}
                id={id}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                minlength={minLength}
                maxlength={maxLength}
                readonly={readonly}
                required={required}
                onIonChange={onchange}
                onIonBlur={onIonBlur}
                onIonFocus={onIonFocus}
                onIonInput={onkeyup}
                //autoGrow={true}
            />
            <SeaInputError>{error}</SeaInputError>
        </>
    );
});

export default SeaTextarea;
