import React, { ReactNode } from 'react';
import { IonButton } from '@ionic/react';
import { useNavigate } from 'react-router';
import { onUserAction } from '../../shared-state/General/appActivity';
import { jsxToText } from '../../lib/util';
import './SeaButton.css';

interface SeaButtonProps {
    children: ReactNode,
    zone?: 'blue' | 'white' | 'grey' | 'gold',
    shape?: 'circle' | 'square' | 'square-outline',
    type?: 'button' | 'reset' | 'submit',
    disabled?: boolean,
    onClick?: (e: React.MouseEvent) => void,
    size?: 'small' | 'standard' | 'wide',
    mini?: boolean,
    enhanced?: boolean,
    transparent?: boolean,
    href?: string,
    target?: string,
    to?: string,
    faded?: boolean
}

const SeaButton: React.FC<SeaButtonProps> = ({ children, zone, shape, type = 'button', disabled, size, mini, onClick, enhanced, transparent, href, target, to, faded }) => {
    const navigate = useNavigate();

    if (zone === undefined) zone = 'white';
    let color = 'primary';
    switch (zone) {
        case 'blue':
            color = 'light'; // white
            break;
        case 'white':
        case 'grey':
            color = enhanced ? 'secondary' : 'primary'; // blue
            break;
    }

    const handleOnClick = (e: React.MouseEvent) => {
        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick(e);
        };
    };

    const handleMouseUp = (e: React.MouseEvent) => {
        onUserAction(`Clicked "${jsxToText(children, 'Unknown')}"`);
    };


    return (
        <IonButton
            color={color}
            className={`sea-button ${zone}-zone ${shape} ${transparent ? 'transparent' : ''} ${size ? size : ''} ${mini ? 'mini' : ''} ${faded ? 'faded' : ''}`}
            shape="round"
            type={type}
            disabled={disabled}
            onClick={handleOnClick}
            href={href}
            target={target}
            onMouseUp={handleMouseUp}
        >
            {children}
        </IonButton>
    );
};

export default SeaButton;
