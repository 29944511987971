import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sharedState } from '../../shared-state/shared-state';
import PleaseWait from '../../pages/AccountManagement/PleaseWait/PleaseWait';

interface RequireUserProps {
    children: ReactNode
}

const RequireUser: React.FC<RequireUserProps> = ({ children }) => {
    const user = sharedState.user.use();
    const superAdmin = sharedState.superAdmin.use();
    const userPending = sharedState.userPending.use();
    const isCoreDataLoaded = sharedState.isCoreDataLoaded.use();
    const navigate = useNavigate();
    const location = useLocation();

    if (userPending || !isCoreDataLoaded) {
        return <PleaseWait message={userPending ? 'Authenticating...' : 'Loading Core Data...'}/>
    }

    let isAllowed = false;
    if (user) {
        if (user.isLoginDisabled || user.isDeactivated || user.state !== 'active') {
            isAllowed = superAdmin ? true : false;
        } else {
            isAllowed = true;
        }
    }

    if (!isAllowed) {
        setTimeout(() => {
            if (superAdmin) {
                navigate('/admin');
            } else {
                navigate(`/login?d=${encodeURIComponent(location.pathname + (location.search ? location.search : ''))}`);
            }
        });
        return <></>;
    }

    return <>{children}</>;
};

export default RequireUser;
