import React, { useState, useRef, useEffect } from 'react';
import { IonSelectOption } from '@ionic/react';
import { CategoriesData, renderCategoryName } from "../../lib/categories";
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaInput from '../SeaInput/SeaInput';
import './SeaSelectCategory.css';

interface SeaSelectCategoryProps {
    categories: CategoriesData | undefined,
    initialCategoryId?: string, // either a categoryId or a new category name
    categoryId?: string, // either a categoryId or a new category name
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    placeholder?: string,
    otherPlaceholder?: string,
    error?: string,
    disabled?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
}

const SeaSelectCategory: React.FC<SeaSelectCategoryProps> = ({
    categories,
    zone,
    name,
    label,
    categoryId,
    initialCategoryId,
    placeholder,
    otherPlaceholder,
    error,
    disabled,
    onchange,
    onblur,
    onfocus
}) => {
    const [selectValue, setSelectValue] = useState(categoryId);
    const [isNewCategory, setIsNewCategory] = useState(false);
    const [orphanCategoryId, setOrphanCategoryId] = useState<string>();
    const customRef = useRef<HTMLInputElement>(null);
    const [isProgrammaticChange, setIsProgrammaticChange] = useState(false);

    // Create an orphan category if needed
    useEffect(() => {
        //console.log(`initialCategoryId=${initialCategoryId}`, (initialCategoryId && categories?.byId[initialCategoryId]));
        if (initialCategoryId === '') {
            setSelectValue(initialCategoryId);
        } else if (initialCategoryId && categories) {
            if (categories?.byId[initialCategoryId]) {
                if (categories.byId[initialCategoryId].state !== 'active') {
                    // Not in options, so let's create a special option just for this value
                    setOrphanCategoryId(initialCategoryId);
                }
                setSelectValue(initialCategoryId);
            } else {
                setSelectValue('_new');
                setIsNewCategory(true);
                const input = (customRef.current as any).inputRef.current;
                input.value = initialCategoryId;
            }
        }
    }, [initialCategoryId, categories]);

    useEffect(() => {
        if (
            categoryId &&
            categories &&
            categories?.byId[categoryId]
        ) {
            setSelectValue(categoryId);
        } else if (categoryId === '') {
            setSelectValue('');
        }
    }, [categoryId, categories]);

    const onSelectChange = (e: CustomEvent) => {
        if (isProgrammaticChange) {
            setIsProgrammaticChange(false);
            return;
        }
       setSelectValue(e.detail.value);
        let value = e.detail.value;
        if (value === '_new') {
            setIsProgrammaticChange(true);
            setIsNewCategory(true);
            if (customRef.current) {
                (customRef.current as any).focus();
            }
            value = '';
        } else {
            setIsNewCategory(false);
        }
        const input = (customRef.current as any).inputRef.current;
        input.value = value;
        const event = new Event("input", { bubbles: true });
        input.dispatchEvent(event);
    }

    return (
        <>
            <SeaSelect
                zone={zone}
                name={`${name}_select`}
                label={label}
                disabled={disabled}
                value={selectValue}
                placeholder={placeholder}
                onchange={onSelectChange}
                warn={error ? true : false}
            >
                <IonSelectOption value="">Not Set</IonSelectOption>
                {orphanCategoryId &&
                    <IonSelectOption value={orphanCategoryId}>{renderCategoryName(orphanCategoryId, categories)}</IonSelectOption>
                }
                {categories?.ids?.map((id: string) => {
                    if (categories.byId[id].state === 'active') {
                        return (<IonSelectOption key={id} value={id}>{renderCategoryName(id, categories)}</IonSelectOption>);
                    }
                    return undefined;
                })}
                <IonSelectOption value="_new">Add New...</IonSelectOption>
            </SeaSelect>
            <div className={`sea-select-input ${isNewCategory ? 'show' : ''}`}>
                <div style={{ height: '4px' }}></div>
                <SeaInput
                    ref={customRef}
                    name={name}
                    placeholder={otherPlaceholder}
                    value={categoryId}

                    onchange={onchange}
                    onblur={onblur}
                    onfocus={onfocus}

                    zone={zone}
                    type="text"
                    inputmode="text"
                    warn={error ? true : false}
                />
            </div>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaSelectCategory;
