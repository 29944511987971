import React from 'react';
import './SeaChecks.css';
import TickIcon from '../../assets/svg/Tick';
import XIcon from '../../assets/svg/X';
import BackSlashIcon from '../../assets/svg/BackSlash';
import SeaInputError from '../SeaInputError/SeaInputError';

export interface SeaCheck {
    check: string,
    value: string
}

/*
    [
        {
            check:
            value:
        }
        ...
    ]
*/

interface SeaChecksProps {
    label?: string,
    note?: string,
    data?: SeaCheck[],
    setData?: (data: SeaCheck[]) => void,
    error?: string
}

const SeaChecks: React.FC<SeaChecksProps> = ({ label, note, data, setData, error }) => {

    const toggleValue = (index: number, value: string) => {
        if (data && setData) {
            const _data = [] as SeaCheck[];
            for (let i = 0; i < data.length; i++) {
                if (i === index) {
                    _data.push({
                        check: data[i].check,
                        value: (data[i].value === value) ? '' : value
                    });
                } else {
                    _data.push({
                        check: data[i].check,
                        value: data[i].value
                    });
                }
            }
            setData(_data);
        }
    };

    if (data === undefined || data.length === 0) {
        return null;
    }
    return (
        <div className="sea-checks no-select">
            {label &&
                <div className="columns">
                    <div className="sea-label checks-label">
                        {label}
                    </div>
                    {note &&
                        <div className="sea-label note margin">
                            {note}
                        </div>
                    }
                </div>
            }
            <div className="row">
                <div className="sea-label option"><TickIcon className="tick" /></div>
                <div className="sea-label option">FAULT</div>
                <div className="sea-label option">N/A</div>
            </div>
            {data?.map((item, index) => {
                return (
                    <div key={`${item.check}${index}`} className="row">
                        <div>
                            <div
                                className={`sea-checkbox pass ${item?.value === 'pass' ? 'on' : ''}`}
                                onClick={(e) => toggleValue(index, 'pass')}
                            >
                                <TickIcon className="check-icon" style={{ margin: '12px 0px 0px 10px' }} />
                            </div>
                        </div>
                        <div>
                            <div
                                className={`sea-checkbox fail ${item?.value === 'fail' ? 'on' : ''}`}
                                onClick={(e) => toggleValue(index, 'fail')}
                            >
                                <XIcon className="check-icon" style={{ margin: '13px 0px 0px 13px' }} />
                            </div>
                        </div>
                        <div>
                            <div
                                className={`sea-checkbox na ${item?.value === 'na' ? 'on' : ''}`}
                                onClick={(e) => toggleValue(index, 'na')}
                            >
                                <BackSlashIcon className="check-icon" style={{ margin: '10px 0px 0px 8px' }} />
                            </div>
                        </div>
                        <div className="margin">
                            {item.check}
                        </div>
                    </div>
                );
            })}
            <SeaInputError>{error}</SeaInputError>
        </div>
    );
};

export default SeaChecks;
