import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Vessel } from './vessel';
import { useState, useEffect } from 'react';

//
// Loads all vessels based on a licenseeId.
// This can only be used by superAdmin.
//

export const useLicenseeVessels = (licenseeId: string) => {
    const [licenseeVessels, setLicenseeVessels] = useState<Vessel[]>();

    useEffect(() => {
        setLicenseeVessels(undefined);
        if (licenseeId) {
            return onSnapshot(
                query(
                    collection(firestore, 'vessels'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    const vessels: Vessel[] = [];
                    snap.docs.forEach((doc) => {
                        vessels.push({
                            id: doc.id,
                            ...doc.data()
                        } as Vessel);
                    });
                    setLicenseeVessels(vessels);
                }
            );
        }
    }, [licenseeId]);

    return licenseeVessels;
};
