import React, { ReactNode } from 'react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaLabel from '../SeaLabel/SeaLabel';
import './SeaGridCell.css';

interface SeaGridCellProps {
    children: ReactNode,
    w?: '100' | '75' | '66' | '50' | '33' | '25',
    label?: string,
    help?: SeaHelp,
    capitalize?: boolean,
    short?: boolean,
    style?: React.CSSProperties
}

const SeaGridCell: React.FC<SeaGridCellProps> = ({ children, w = '100', label, help, capitalize, short, style }) => {

    return (
        <div className={`w${w} ${capitalize ? 'capitalize' : ''} ${short ? 'short' : ''}`} style={style}>
            {label &&
                <SeaLabel help={help}>{label}</SeaLabel>
            }
            {children}
        </div>
    );
};

export default SeaGridCell;
