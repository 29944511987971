import React, { ReactNode } from 'react';
import { Defs, LinearGradient, Rect, Stop, Svg } from '@react-pdf/renderer';
import reporting from '../../../lib/reporting';

interface SeaGraphProps {
    title?: string,
    subTitle?: string,
    children?: ReactNode,
    mode: 'dashboard' | 'modal' | 'pdf',
    n?: number,
    divStyle?: any,
    onClick?: (e: React.MouseEvent) => void,
    onMouseOut?: () => void,
    colourMode?: 'dark' | 'light',
    width?: number,
    height?: number,
    showHeader?: boolean,
    padding?: number,
}

const SeaGraph: React.FC<SeaGraphProps> = ({
    title,
    subTitle,
    children,
    mode,
    n,
    divStyle,
    onClick,
    onMouseOut,
    colourMode,
    width,
    height,
    showHeader,
    padding,
}) => {

    if (mode === 'pdf') {
        return (
            <Svg viewBox={`0 0 ${width} ${height}`}>
                <Defs>
                    <LinearGradient
                        id="textFade"
                        x1={''}
                        x2={''}
                        y1={''}
                        y2={''}
                    >
                        <Stop offset="0%" stopColor="white" stopOpacity={0}/>
                        <Stop offset="1%" stopColor="white" stopOpacity={1}/>
                        <Stop offset="100%" stopColor="white" stopOpacity={1}/>
                    </LinearGradient>
                </Defs>
                <Rect
                    x={0}
                    y={0}
                    width='100%'
                    height='100%'
                    rx={reporting.cornerRadius}
                    ry={reporting.cornerRadius}
                    fill="white"
                />
                {children}
            </Svg>
        );
    }

    return (
        <div
            className={`svg-graph-container ${mode}`}
            style={{
                ...(divStyle as React.CSSProperties),
                cursor: (onClick ? 'pointer' : 'default'),
                '--graph': n,
            } as React.CSSProperties}
            onMouseOut={(e) => {
                if (onMouseOut) {
                    onMouseOut();
                }
            }}
            onClick={onClick}
        >
            <svg className={`svg-graph ${colourMode}`} viewBox={`0 0 ${width} ${height}`}>
                <rect
                    className="container"
                    rx={reporting.cornerRadius}
                    ry={reporting.cornerRadius}
                    width={width}
                />
                {showHeader &&
                    <text
                        className="title"
                        x={padding}
                        y={(padding || 0) + reporting.titleSize - 6}
                        fill="currentcolor"
                    >
                        {title}&nbsp;
                        <tspan className="sub-title" dx={2}>{subTitle}</tspan>
                    </text>
                }
                {children}
            </svg>
        </div>
    );
};

export default SeaGraph;
