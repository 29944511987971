import React from 'react';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaFormHasErrors.css';

interface SeaFormHasErrorsProps {
    hasSubmitted?: boolean,
    isValid?: boolean
}

const SeaFormHasErrors: React.FC<SeaFormHasErrorsProps> = ({ hasSubmitted, isValid }) => {
    return <div className={`sea-form-has-errors columns${(hasSubmitted && !isValid) ? ' active' : ''}`}>
        <SeaIcon icon="alert" />
        <div>Please check the form for errors.</div>
    </div>
};

export default SeaFormHasErrors;
