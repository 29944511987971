import { IonToast, createAnimation } from '@ionic/react';
import React from 'react';
import './SeaToast.css';

interface SeaToastProps {
    message: string,
    setMessage: (message: string) => void,
    duration?: number
}

const enterAnimation = (baseEl: any, options: any) => {
    //console.log('baseEl', baseEl);
    //console.log('options', options);
    return createAnimation()
      .addElement(baseEl.querySelector('.toast-wrapper'))
      //.easing('ease-out')
      .duration(200)
      .fromTo('opacity', '0.01', '1')
      .fromTo('top', '-20px', '20px');
}

const SeaToast: React.FC<SeaToastProps> = ({ message, setMessage, duration }) => {
    return <IonToast
        isOpen={(message && message.length > 0) ? true : false}
        message={message}
        position="top"
        duration={duration ? duration : 3000}
        enterAnimation={enterAnimation}
        onDidDismiss={(e) => { setMessage(''); }}
    />
};

export default SeaToast;
