import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { UserRole } from '../Crew/userRoles';

//
// Contains user permissions as stored in firestore.
// Should match what can be found in userClaims.
//

export interface UserPermissions extends UserRole {
    userId?: string;
}

export const userPermissionsConfig: SharedStateConfig<UserPermissions> = {
    isAlwaysActive: true,
    dependencies: ['userId'],
    countLiveDocs: () => sharedState.userPermissions.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        if (userId) {
            return onSnapshot(
                doc(firestore, 'userPermissions', userId),
                (snap) => {
                    done();
                    if (!snap.exists()) {
                        clear();
                        sharedState.userPending.set(false);
                    } else {
                        set({
                            ...snap.data()
                        } as UserPermissions);
                        sharedState.userPending.set(false);
                    }
                }, (error) => {
                    done();
                    sharedState.userPending.set(false);
                    console.log(`Error getting userPermissions for ${userId}`, error);
                }
            );
        }
    },
};
