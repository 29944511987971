import React, { ReactNode, useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaScrollableArea from '../../components/SeaScrollableArea/SeaScrollableArea';
import './CenteredBoxPageLayout.css';

interface CenteredBoxPageLayoutProps {
    children: ReactNode,
    boxMode?: 'form' | 'message'
}

const CenteredBoxPageLayout: React.FC<CenteredBoxPageLayoutProps> = ({ children, boxMode = 'form' }) => {
    useEffect(() => {
        sharedState.layoutMode.set('centeredBox');
    }, []);

    return (
        <div>
            <SeaScrollableArea className="cbl-bg">
                <div className="cbl-centered">
                    <div className="cbl-logo">
                        <img src="/assets/seaflux.png" alt="Sea Flux Logo"/>
                    </div>
                    <div className={`cbl-box ${boxMode}`}>
                        {children}
                    </div>
                </div>
            </SeaScrollableArea>
        </div>
    );
};

export default CenteredBoxPageLayout;
