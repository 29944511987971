import React from 'react';
import './LexIcon.css';

interface LexIconProps {
    icon: 'text-paragraph' | 'arrow-counterclockwise' | 'arrow-clockwise' | 'font-family' | 'font-color' | 'paint-bucket' | 'type-bold' | 'type-italic' | 'type-strikethrough' | 'type-subscript' | 'type-superscript' | 'type-underline' | 'type-h1' | 'type-h2' | 'type-h3' | 'dropdown-more' | 'list-ul' | 'list-ol' | 'chat-square-quote' | 'trash' | 'text-left' | 'text-center' | 'text-right' | 'justify' | 'indent' | 'outdent' | 'plus' | 'horizontal-rule' | 'file-image' | 'table' | 'chevron-down',
    mode?: 'alone' | 'start' | 'end',
    opacity?: number,
    yOffset?: number,
    scale?: number,
    width?: string,
    height?: string
}

const LexIcon: React.FC<LexIconProps> = ({
    icon,
    mode = 'alone',
    opacity = 0.75,
    yOffset = 1,
    scale,
    width = '20px',
    height = '20px'
}) => {
    return (
        <span className={`lex-toolbar-icon ${mode}`} style={{
            backgroundImage: `url(/assets/lex/${icon}.svg)`,
            opacity: opacity,
            top: `${yOffset}px`,
            transform: scale ? `scale(${scale})` : undefined,
            width: width,
            height: height
        }}></span>
    );
};

export default LexIcon;
