import { sharedState } from "../shared-state";

//
// Listens for "secret" user interactions that will enable the debugger.
// To activate the debugger, either:
// * Type debug333
// * or click/tap right at the top of the screen 7 times in a row, with each click/tap being further to the right
// 
// Once activated, or anytime you're in the development environment, you can open/close the debugger using F2.
//

const debugSecret = 'debug333';
let debugSecretIndex = 0;
let debugClicks = 0;
let debugClickX = 0;
let previouslyActivated = false;

export const activateDebugger = () => {
    previouslyActivated = true;
    debugSecretIndex = 0;
    debugClickX = 0;
    debugClicks = 0;
    sharedState.showDebugger.set((current: boolean | undefined) => !current);
};

export const initDebugger = () => {

    const keyupListenenr = (event: KeyboardEvent) => {
        if (event.key === 'F2' && previouslyActivated) {
            activateDebugger();
            return;
        }

        if (debugSecret[debugSecretIndex] === event.key) {
            debugSecretIndex++;
            if (debugSecretIndex >= debugSecret.length) {
                activateDebugger();
                return;
            }
        } else {
            debugSecretIndex = (event.key === debugSecret[0]) ? 1 : 0;
        }
    };

    const mouseupListenener = (event: MouseEvent) => {
        if (
            event.clientY < 50 &&
            event.clientX > debugClickX
        ) {
            debugClickX = event.clientX;
            debugClicks++;
            if (debugClicks >= 7) {
                activateDebugger();
            }
        } else {
            debugClickX = 0;
            debugClicks = 0;
        }
    };

    document.addEventListener('keyup', keyupListenenr);
    document.addEventListener('mouseup', mouseupListenener);

    if (process.env.NODE_ENV === 'development') {
        previouslyActivated = true;
    }

    // Return cleanup function
    return () => {
        document.removeEventListener('keyup', keyupListenenr);
        document.removeEventListener('mouseup', mouseupListenener);
    };
};
