import { IonModal, createAnimation } from '@ionic/react';
import React, { ReactNode, useEffect, useCallback } from 'react';
import SeaScrollableArea from '../SeaScrollableArea/SeaScrollableArea';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaAlert.css';
import { OverlayEventDetail } from '@ionic/core';

interface SeaAlertProps {
    children?: ReactNode,
    showAlert: boolean,
    setShowAlert: (showAlert: boolean) => void,
    onOpened?: () => void,
    onClosed?: () => void,
    type: 'pdf'
}

const SeaAlert: React.FC<SeaAlertProps> = ({ children, showAlert, setShowAlert, onOpened, onClosed, type }) => {

    useEffect(() => {
        if (showAlert && onOpened) {
            onOpened();
        } else if (!showAlert && onClosed) {
            onClosed();
        }
    }, [showAlert]);

    const onDidDismiss = (e: CustomEvent<OverlayEventDetail<any>>) => {
        setShowAlert(false);
    }
    const onDidPresent = (e: CustomEvent<OverlayEventDetail<any>>) => {
        //setShowAlert(true);
    }

    const enterAnimation = useCallback((baseEl: any) => {
        const backdropAnimation = createAnimation()
            .addElement(baseEl.querySelector('ion-backdrop')!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(baseEl.querySelector('.modal-wrapper')!)
            .keyframes([
                { offset: 0, opacity: '0', transform: 'scale(0.8)' },
                { offset: 1, opacity: '0.99', transform: 'scale(1)' }
            ]);

        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(150)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    }, []);
    const leaveAnimation = useCallback((baseEl: any) => {
        return enterAnimation(baseEl).direction('reverse');
    }, []);

    return (
        <IonModal
            isOpen={showAlert}
            mode="ios"
            showBackdrop={true}
            backdropDismiss={true}
            cssClass={`sea-alert ${type}`}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            onDidDismiss={onDidDismiss}
            onDidPresent={onDidPresent}
        >
            <SeaScrollableArea>
                <div className="alert-close" onClick={(e) => setShowAlert(false)}>
                    <SeaIcon icon="close" forceFontSize="26px"/>
                </div>
                <div className="alert-container">
                    <div>
                        {children}
                    </div>
                </div>
            </SeaScrollableArea>
        </IonModal>
    );
};

export default SeaAlert;
