import { isPlatform } from "@ionic/react";
import { SharedStateConfig, runSharedState, sharedState } from "../shared-state";
import { LicenseeDataSyncCollection, VesselDataSyncCollection } from "./dataSyncTasks";

//
// Maintains cachedDataInfo which stores when the last time various types of data have been loaded onto the device.
// This is how we know we'll need to get fresh data so that we're ready to go offline.
// This system is only relevant for ios/android apps AND if licenseeSettings.hasOffline is true.
//
// cachedDataInfo is kept in sync with window.localStorage via items called _<userId>_licensee and _<userId>_vessel_<vesselId>
//
// Note: A Firestore Timestamp represents a point in time independent of any time zone or calendar,
// represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time.
// Use Timestamp.fromDate or fromMillis to make one. Use toMillis() to convert epoch millis.
//

type CachedDataInfo = {
    licensee: {
        [Property in LicenseeDataSyncCollection]?: number
    },
    vessels: {
        [vesselId: string]: {
            [Property in VesselDataSyncCollection]?: number
        }
    }
};

export const cachedDataInfo = {
    licensee: {},
    vessels: {}
} as CachedDataInfo;

export const updateLicenseeCachedDataInfo = (collection: LicenseeDataSyncCollection, touched: number | undefined) => {
    cachedDataInfo.licensee[collection] = touched;
    window.localStorage.setItem(`_${sharedState.userId.current}_licensee`, JSON.stringify(cachedDataInfo.licensee));
};

export const updateVesselCachedDataInfo = (vesselId: string, collection: VesselDataSyncCollection, touched: number | undefined) => {
    if (cachedDataInfo.vessels[vesselId] === undefined) {
        cachedDataInfo.vessels[vesselId] = {};
    }
    cachedDataInfo.vessels[vesselId][collection] = touched;
    window.localStorage.setItem(`_${sharedState.userId.current}_vessel_${vesselId}`, JSON.stringify(cachedDataInfo.vessels[vesselId]));
};

export const handleCachedDataInfoConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['userId', 'vesselIds', 'licenseeSettings'],
    default: 'Not initialised',
    notes: 'Initialises cachedDataInfo which stores how fresh the device\'s local cached data is',
    run: (done, set, clear) => {
        done();
        const userId = sharedState.userId.current;
        const vesselIds = sharedState.vesselIds.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (
            !isPlatform('hybrid') ||
            !licenseeSettings?.hasOffline
        ) {
            set(`Not applicable unless an app with offline mode enabled`);
            sharedState.isDataSyncActive.set(false);
        } else if (
            userId &&
            vesselIds
        ) {
            // Load cachedData
            try {
                const licenseeJson = window.localStorage.getItem(`_${userId}_licensee`);
                cachedDataInfo.licensee = licenseeJson ? JSON.parse(licenseeJson) : {};

                vesselIds.forEach((vesselId: string) => {
                    const vesselJson = window.localStorage.getItem(`_${userId}_vessel_${vesselId}`);
                    cachedDataInfo.vessels[vesselId] = vesselJson ? JSON.parse(vesselJson) : {};
                });

                set(`cachedDataInfo loaded for user ${userId} along with ${vesselIds.length} vessels`);
            } catch (e) {
                set('Failed to load cachedDataInfo!');
            }

            sharedState.isDataSyncActive.set(true);
        } else {
            set(`Not ready.`);
        }
    }
};

export const handleObsoleteCachedDataConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['user', 'licenseeSettings'],
    default: 'Not run.',
    notes: 'Responsible for resetting the local data cache if permissions have changed.',
    run: (done, set, clear) => {
        done();
        const user = sharedState.user.current;
        const licenseeSettings = sharedState.licenseeSettings.current;
        if (!user?.whenRoleChanged || !user?.vesselIds) {
            set(`Not ready.`);
        } else if (!licenseeSettings?.hasOffline) {
            set(`Does not have offline mode.`);
        } else {
            const cachedWhenRoleChanged = window.localStorage.getItem(`_${user.id}_whenRoleChanged`);
            if (
                !cachedWhenRoleChanged ||
                Number(cachedWhenRoleChanged) < user.whenRoleChanged
            ) {
                resetCachedDataInfo();
                console.log(`cachedDataInfo has been reset due to a newer user.whenRoleChange=${user.whenRoleChanged} cachedWhenRoleChanged=${cachedWhenRoleChanged}`);
                set(`Local data cache reset!`);
            } else {
                set(`Local data cache is up to date with the latest userPermissions.`);
            }
        }
    }
};

export const resetCachedDataInfo = () => {
    const user = sharedState.user.current;
    if (!user) return;
    // Any existing cached data is now possibly obsolete due to new permissions.
    cachedDataInfo.licensee = {};
    cachedDataInfo.vessels = {};
    window.localStorage.removeItem(`_${user.id}_licensee`);
    user.vesselIds?.forEach((vesselId: string) => {
        window.localStorage.removeItem(`_${user.id}_vessel_${vesselId}`);
    });
    window.localStorage.setItem(`_${user.id}_whenRoleChanged`, ''+user.whenRoleChanged);
    // Need to manually rerun whenLicenseeTouched & whenVesselTouched
    runSharedState('whenLicenseeTouched');
    runSharedState('whenVesselTouched');
};
