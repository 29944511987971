import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../../shared-state";
import { CustomFormElementType } from '../../../components/CustomFormElement/CustomFormElement';

//
// Load customForms
//

export interface CustomForm extends CreateableDocument, UpdateableDocument {
    categoryId: string;
    crewElement?: CustomFormElementType;
    deletedBy?: string;
    forCrew: boolean;
    forVesselIds: string[];
    isTemplate?: boolean;
    lastCompletedBy?: string;
    lastCompletedVesselIds?: string[];
    latestVersion: number;
    licenseeId: string;
    state: string;
    templateCategory?: string;
    title: string;
    vesselsElement?: CustomFormElementType;
    whenDeleted?: number;
    whenLastCompleted?: number;
    [key: string]: any;
}

export type CustomFormsData = {
    all: CustomForm[],
    byId: {
        [customFormId: string]: CustomForm
    },
    byCategoryId: {
        [categoryId: string]: CustomForm[] // Alphabetical list
    }
};

export const customFormsConfig: SharedStateConfig<CustomFormsData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId', 'vesselIds'],
    countLiveDocs: () => sharedState.customForms.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (
            licenseeId &&
            vesselIds &&
            vesselIds.length > 0
        ) {
            return setupArrayQueryListener(
                'customForms', // what
                collection(firestore, 'customForms'),
                [
                    where('state', '==', 'active'),
                    where('licenseeId', '==', licenseeId)
                ], // baseConstraints
                'forVesselIds',
                'array-contains-any',
                ['none', ...vesselIds],
                [orderBy('title', 'asc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    done();
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as CustomForm;
                    });

                    if (isCombined) { // Need to sort by name
                        all.sort((a, b) => {
                            return a.title.localeCompare(b.title);
                        });
                    }

                    const byId = {} as {
                        [customFormId: string]: CustomForm
                    };

                    const byCategoryId = {} as {
                        [categoryId: string]: CustomForm[]
                    };
                    all.forEach((customForm) => {
                        byId[customForm.id] = customForm;
                        if (byCategoryId[customForm.categoryId] === undefined) {
                            byCategoryId[customForm.categoryId] = [];
                        }
                        byCategoryId[customForm.categoryId].push(customForm);
                    });

                    set({
                        all,
                        byId,
                        byCategoryId
                    });
                },
                (error) => { // onError
                    done();
                }
            );
        } else {
            done();
        }

    }
};
