import * as React from "react";

function SvgDashboard(props) {
  return (
    <svg
      width={27}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.76 13.154H5.092V4.32h6.666v8.834zm0 8.666H5.092v-4.5h6.666v4.5zm10.833 0h-6.667v-8.833h6.667v8.833zm-6.667-13v-4.5h6.667v4.5h-6.667z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgDashboard;
