import React, { useCallback } from 'react';
import { IonCheckbox, IonInput } from '@ionic/react';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaButton from '../SeaButton/SeaButton';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaInputList.css';

type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';

interface SeaInputListProps {
    zone?: 'blue' | 'white' | 'grey',
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
    name?: string,
    label?: string,
    values?: string[],
    setValues?: (values: string[]) => void, // callback to set values
    ids?: string[],
    setIds?: (ids: string[]) => void, // callback to set ids
    customFields?: { fieldName: string, columnName: string, fieldType: 'boolean' | 'string' | 'number', fieldInputType?: 'checkbox' | 'text' | 'radio', textInputType?: TextFieldTypes }[],
    customData?: any[],
    setCustomData?: (customData: any[]) => void, // callback to set ids
    maxWidth?: string, // maxWidth of inputs
    placeholder?: string,
    error?: string,
    disabled?: boolean,
    minLength?: number,
    maxLength?: number,
    readonly?: boolean,
    addNewText?: string,
    confirmDelete?: boolean,
    itemName?: string,
}

const SeaInputList: React.FC<SeaInputListProps> = ({ zone, inputmode, type, name, label, values, setValues, ids, setIds, customData, setCustomData, customFields, maxWidth, placeholder, error, disabled, minLength, maxLength, readonly, addNewText, confirmDelete, itemName = 'item' }) => {

    const onInputChanged = useCallback((e: CustomEvent, index: number) => {
        const newValue = e.detail.value;
  
        // Check if the new value is different from the old value. Used to prevent resetting data when a row is removed/added.
        if (newValue !== values?.[index]) {
            const _values = [...values as string[]];
            _values[index] = newValue;
            if (setValues) {
                setValues(_values);
            }
        }
    }, [setValues, values]);

    const onCustomDataChanged = useCallback((e: CustomEvent, index: number, field: string, input: string) => {
        const newValue = input === 'checkbox' || input === 'radio' ? e.detail.checked : e.detail.value;
        // Check if the new value is different from the old value. Used to prevent resetting data when a row is removed/added.
        if (newValue !== customData?.[index][field]) {
            const _customData = [...customData as any[]];
            _customData[index][field] = newValue;
            if (setCustomData) {
                setCustomData(_customData);
            }
        }
    }, [customData, setCustomData]);

    const onRemoveValue = (index: number) => {
        if (confirmDelete && values && values[index].trim().length > 0) {
            confirmAction(
                `Are you sure you want to delete this ${itemName}?`,
                `Yes, delete ${itemName}`
            ).then(() => {
                removeValue(index);
            }).catch(() => {});
        } else {
            removeValue(index);
        }
    };

    const removeValue = (index: number) => {
        if (ids && setIds) {
            let _ids = [...ids as string[]];
            _ids.splice(index, 1);
            // if (_ids.length == 0) {
            //     _ids = [''];
            // }
            setIds(_ids);
        }
        if (setValues) {
            let _values = [...values as string[]];
            _values.splice(index, 1);
            // if (_values.length == 0) {
            //     _values = [''];
            // }
            setValues(_values);
        }
        if (customData && setCustomData) {
            let _customData = [...customData as any[]];
            _customData.splice(index, 1);
            // if (_ids.length == 0) {
            //     _ids = [''];
            // }
            setCustomData(_customData);
        }
    };

    const addNewInput = () => {
        if (setValues) {
            const _values = [...values as string[], ''];
            setValues(_values);
        }
        if (ids && setIds) {
            const _ids = [...ids as string[], ''];
            setIds(_ids);
        }
        if (customData && setCustomData) {
            const _customData = [...customData as any[], {}];
            setCustomData(_customData);
        }
    }

    return (
        <>
            {label && <SeaLabel>{label}</SeaLabel>}
            {values && values.map((value, index) => {
                return (
                    <div className="input-list-row" key={`${index}-${ids?.[index]}`}>
                        <div style={{ maxWidth: maxWidth, flexGrow: 1 }}>
                            <IonInput
                                name={`${index}-${ids?.[index]}`}
                                value={values?.[index]}
                                style={{ maxWidth: maxWidth }}
                                className={`sea-input ${zone}-zone ${error ? 'has-error' : ''}`}
                                inputmode={inputmode}
                                type={type}
                                placeholder={placeholder}
                                disabled={disabled}
                                minlength={minLength}
                                maxlength={maxLength}
                                readonly={readonly}
                                onIonChange={(e) => onInputChanged(e, index)}
                            />
                        </div>
                        {customFields && customFields.map((customField) => {
                            if (customField.fieldInputType === 'checkbox') {
                                return (
                                    <div className="sea-checkbox-container beside-input" key={`${customField}`}>
                                        {label && index === 0 && <div className="column-header"><SeaLabel>{customField.columnName}</SeaLabel></div>}
                                        <IonCheckbox
                                            name={`${index}-${ids?.[index]}-${customField}`}
                                            className="sea-checkbox"
                                            value={customData && customData[index] && customData[index][customField.fieldName] ? customData[index][customField.fieldName] : false}
                                            checked={customData && customData[index] && customData[index][customField.fieldName] ? customData[index][customField.fieldName] : false}
                                            onIonChange={(e) => onCustomDataChanged(e, index, customField.fieldName, 'checkbox')}
                                            disabled={disabled}
                                        />
                                    </div>
                                )
                            }
                            return undefined;
                        })
                        }
                        <div>
                            <div className="trash" onClick={(e) => onRemoveValue(index)}>
                                <SeaIcon slot="icon-only" icon="trash"/>
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="sea-add-new-button">
                <SeaButton zone="white" shape="circle" onClick={(e) => addNewInput()}>
                    <SeaIcon slot="icon-only" icon="add" />
                </SeaButton>
                <div className="text" onClick={(e) => addNewInput()}>
                    {addNewText ? addNewText : 'Add New'}
                </div>
            </div>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaInputList;
