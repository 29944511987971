import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { registerFiles } from "../../lib/fileSync";
import { getDayOffsetMillis, warnDays } from "../../lib/util";
import { SafetyCheckItem } from "../VesselSafety/safetyCheckItems";

//
// Loads dashboard safety items (active)
//

export type DashboardSafetyCheckItemsData = {
    top5: SafetyCheckItem[],
    numHighestPriority: number,
    byId: {
        [id: string]: SafetyCheckItem
    }
};

export const dashboardSafetyCheckItemsConfig: SharedStateConfig<DashboardSafetyCheckItemsData> = {
    isAlwaysActive: false,
    dependencies: ['dashboardSafetyCheckFaultedItems', 'dashboardSafetyCheckUpcomingItems'],
    run: (done, set, clear) => {
        done();
        const dashboardSafetyCheckFaultedItems = sharedState.dashboardSafetyCheckFaultedItems.current;
        const dashboardSafetyCheckUpcomingItems = sharedState.dashboardSafetyCheckUpcomingItems.current;
        if (dashboardSafetyCheckFaultedItems || dashboardSafetyCheckUpcomingItems) {
            let prioritised = [...(dashboardSafetyCheckFaultedItems || []), ...(dashboardSafetyCheckUpcomingItems || [])];
            const byId: { [id: string]: SafetyCheckItem } = {};
            prioritised.forEach((item) => {
                byId[item.id] = item;
            });
            set({
                byId,
                top5: prioritised.slice(0, 5),
                numHighestPriority: prioritised.length,
            });
        } else {
            clear();
        }
    },
    notes: 'The top5 faulted and upcoming safety check items for the vessel dashboard'
};

export const dashboardSafetyCheckFaultedItemsConfig: SharedStateConfig<SafetyCheckItem[]> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'todayMillis'], // todayMillis is used due to calculating around days
    countLiveDocs: () => sharedState.dashboardSafetyCheckFaultedItems.current?.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('safetyEquipmentChecks')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('hasFault', '==', true),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const faulted = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckItem;
                    });
                    faulted.forEach((item) => {
                        registerFiles(item.files);
                    });
                    set(faulted);
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access faulted dashboard safety check items for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },
    notes: 'Faulted safety check items for dashboard use only'
};

export const dashboardSafetyCheckUpcomingItemsConfig: SharedStateConfig<SafetyCheckItem[]> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'todayMillis'], // todayMillis is used due to calculating around days
    countLiveDocs: () => sharedState.dashboardSafetyCheckUpcomingItems.current?.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('safetyEquipmentChecks')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'safetyCheckItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenDue', '<', getDayOffsetMillis(warnDays.safetyEquipmentChecks[0])),
                    where('hasFault', '==', false),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const upcoming = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyCheckItem;
                    });
                    upcoming.forEach((item) => {
                        registerFiles(item.files);
                    });
                    set(upcoming);
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access upcoming dashboard safety check items for vessel ${vesselId}`,
                        error
                    );
                }
            )
        } else {
            done();
        }
    },
    notes: 'Upcoming safety check items that aren\'t faults for dashboard use only'
};
