import React, { useState } from 'react'
import SeaModal from '../../../../components/SeaModal/SeaModal'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';

interface EditUserRoleModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    handleNewRole: (role: string) => void;
    currentRoleName?: string;
}

const EditUserRoleModal = ({setShowModal, showModal, handleNewRole, currentRoleName}: EditUserRoleModalProps) => {
    const [role, setRole] = useState(currentRoleName || '');

    return (
        <SeaModal
            title={currentRoleName ? "Edit Role Name" : "Add New Role"}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={() => role && role !== currentRoleName ? true : false}
            size="thin"
            level={2}
            >
                <SeaInput
                    label="Role Name"
                    value={role}
                    onchange={(e) => setRole(e.detail.value)}
                />
                <SeaButton
                    onClick={() => {
                        handleNewRole(role);
                        setShowModal(false);
                    }}
                >
                    {currentRoleName ? 'Save' : 'Add Role'}
                </SeaButton>
            </SeaModal>
    )
}

export default EditUserRoleModal