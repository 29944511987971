import * as React from "react";

function SvgMaintenance(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.365 17.419l-7.303-7.264L18.58 6.61a2.477 2.477 0 002.748-.52l1.27-1.27a2.487 2.487 0 00-3.517-3.516l-1.258 1.27a2.488 2.488 0 00-.531 2.723L13.756 8.85l-.534-.529c.046-.181.083-.365.113-.55A6.527 6.527 0 005.667.366a.92.92 0 00-.536 1.495.46.46 0 00.049.06l2.063 2.214c.106.111.197.237.269.373a1.695 1.695 0 01-2.8 1.87 4.885 4.885 0 00-.133-.152L2.443 3.93a.92.92 0 00-1.502.209.87.87 0 00-.106.186 6.67 6.67 0 00-.398 1.456 6.523 6.523 0 005.456 7.445c.064 0 .128.014.193.023l-4.25 4.087a3.415 3.415 0 002.187 5.913h.136a3.39 3.39 0 002.316-.92l5.683-5.423 5.223 5.18a3.39 3.39 0 002.36 1.075h.135a3.415 3.415 0 002.502-5.729l-.013-.013zm-3.248-13.54l1.27-1.27a.64.64 0 11.904.904l-1.27 1.27a.639.639 0 11-.904-.904zM5.221 20.99a1.566 1.566 0 01-2.123-2.3l5.52-5.29 2.236 2.218L5.22 20.99zm15.715-.09a1.566 1.566 0 01-2.215-.084v-.017l-9.43-9.351c-.015-.016-.036-.028-.054-.044l-.055-.046a.717.717 0 00-.09-.055.486.486 0 00-.07-.037l-.084-.032-.09-.027-.074-.014H8.604a1 1 0 00-.12.014H8.43l-.078.016a4.6 4.6 0 01-2.164.174 4.676 4.676 0 01-3.963-4.97l.992 1.065.075.088a3.535 3.535 0 002.864 1.248A3.537 3.537 0 008.602 2.87l-.587-.632a4.68 4.68 0 013.328 5.968v.025a.92.92 0 00.198 1.017l9.501 9.453a1.566 1.566 0 01-.097 2.2h-.009z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMaintenance;
