import { FieldValue, WriteBatch, doc, serverTimestamp } from "firebase/firestore";
import { SplittableBatch, firestore } from "../../lib/firebase";
import { sharedState } from "../shared-state";
import { LicenseeDataSyncCollection, VesselDataSyncCollection, licenseeDataSyncTaskConfigs, vesselDataSyncTaskConfigs } from "./dataSyncTasks";

//
// Pages, components and modals should call these functions to indicate licensee or vesselData is updated
//

export const onCollectionUpdated = (
    batch: WriteBatch | SplittableBatch,
    collection: string,
    vesselId?: string | undefined,
) => {
    if (licenseeDataSyncTaskConfigs[collection as LicenseeDataSyncCollection]) {
        // This collection is data synced licensee wide
        touchLicenseeData(batch, collection as LicenseeDataSyncCollection);
    } else if (vesselDataSyncTaskConfigs[collection as VesselDataSyncCollection]) {
        // This collection is data synced vessel
        touchVesselData(batch, collection as VesselDataSyncCollection, vesselId);
    } else {
        // This collection is not data synced.
        // This is usually because it is always being actively listened to anyway (like users or vessels for example).
        // Keep your hands to yourself...
    }
    // Mark any overdue stats stale that are affected
    switch (collection) {
        case 'engines':
            // Engine hours changing will make scheduledMaintenanceTasks stale (because they rely on engine hours)
            markVesselOverdueStatStale(
                batch,
                'scheduledMaintenanceTasks',
                vesselId
            );
            break;
        case 'drills':
        case 'jobs':
        case 'safetyCheckItems':
        case 'safetyEquipmentItems':
        case 'scheduledMaintenanceTasks':
        case 'vesselCertificates':
        case 'vesselDocuments':
            markVesselOverdueStatStale(
                batch,
                collection,
                vesselId
            );
            break;
    }
};


// Call this if licensee-wide collections have been updated (use onVesselUpdated for vessel specific collections)
const touchLicenseeData = (
    batch: WriteBatch | SplittableBatch,
    collection: LicenseeDataSyncCollection
) => {
    const licenseeId = sharedState.licenseeId.current;
    if (licenseeId) {
        const obj = {
            touched: serverTimestamp()
        } as {
            [key in LicenseeDataSyncCollection]?: FieldValue;
        };
        obj[collection] = serverTimestamp();
        batch.set(
            doc(firestore, 'whenLicenseeTouched', licenseeId),
            obj,
            { merge: true }
        );
    }
};

// Call this if vessel specific collections have been updated
const touchVesselData = (
    batch: WriteBatch | SplittableBatch,
    collection: VesselDataSyncCollection,
    _vesselId?: string
) => {
    const vesselId = _vesselId ? _vesselId : sharedState.vesselId.current;
    if (!vesselId) {
        return;
    }
    // Update whenVesselTouched
    const obj = {
        licenseeId: sharedState.licenseeId.current,
        touched: serverTimestamp()
    } as {
        [key in VesselDataSyncCollection]?: FieldValue;
    };
    obj[collection] = serverTimestamp();
    batch.set(
        doc(firestore, 'whenVesselTouched', vesselId),
        obj,
        { merge: true }
    );
};

// Update overdueStats collection to indicate if certain values that overdueStats rely on are now stale
export const markVesselOverdueStatStale = (
    batch: WriteBatch | SplittableBatch,
    collection: string,
    _vesselId: string | undefined
) => {
    const vesselId = _vesselId ? _vesselId : sharedState.vesselId.current;
    const licenseeId = sharedState.licenseeId.current;
    if (!vesselId || !licenseeId) {
        return;
    }
    const obj = {} as {
        [vesselId: string]: {
            [objectId: string]: {
                stale?: boolean;
            };
        };
    };
    obj[vesselId] = {};
    obj[vesselId][collection] = {
        stale: true,
    };
    batch.set(doc(firestore, 'overdueStats', licenseeId), obj, {
        merge: true,
    });
};
