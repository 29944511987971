import React, { ReactNode } from 'react';
import './SeaLinkButton.css';

/*
    This is really a button that doesn't look like a button (i.e. could look like a link)
    It is to get around the fact we're using a link that acts as a button so shouldn't use an <a/> tag
    see: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
*/

interface SeaLinkButtonProps {
    children: ReactNode,
    onClick?: (e: React.MouseEvent) => void,
    style?: React.CSSProperties,
    mode?: 'small-link' | 'standard-link' | 'simple-link'
}

const SeaLinkButton: React.FC<SeaLinkButtonProps> = ({ children, onClick, style, mode }) => {
    return (
        <button
            className={`sea-link-button ${mode ? mode : ''}`}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    );
};

export default SeaLinkButton;
