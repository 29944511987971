import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { ReportingEquipmentByIdData } from "./reportingEquipmentById";
import { Job } from "../../VesselMaintenance/jobs";
import { registerFiles } from "../../../lib/fileSync";

const makeTasks = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        registerFiles(doc.data().files);
        return {
            id: doc.id,
            ...doc.data()
        } as Job;
    });
};

export const useReportingJobs = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedJobPriorities: string[] | undefined,
    equipmentById: ReportingEquipmentByIdData | undefined,
    equipmentCriticality: string
) => {
    const [reportingJobs, setReportingJobs] = useState<Job[]>();

    useEffect(() => {
        if (!selectedJobPriorities || selectedJobPriorities.length === 0 || selectedVesselIds.length === 0) {
            // Nothing to load
            setReportingJobs([]);
            return;
        }
        setReportingJobs(undefined);
        if (isActive && selectedVesselIds?.length) {
            return setupArrayQueryListener(
                "jobs", // what
                collection(firestore, "jobs"),
                [where("state", "==", "active"), where("priority", "in", selectedJobPriorities)],
                "vesselId",
                "in",
                selectedVesselIds,
                [orderBy("task", "asc")],
                (docs: QueryDocumentSnapshot<DocumentData>[], isCombined: boolean) => {
                    // processDocs
                    let all = makeTasks(docs);
                    if (isCombined || true) {
                        all.reverse();
                        // Sort with the following order:
                        // 1. priority
                        // 2. whenDue, (undefined is ordered last)
                        // 3. task
                        // Filter by criticality
                        if (equipmentById) {
                            if (equipmentCriticality === "critical") {
                                all = all.filter((item) => item.equipmentId && equipmentById[item.equipmentId]?.isCritical);
                            } else if (equipmentCriticality === "nonCritical") {
                                all = all.filter((item) => item.equipmentId && !equipmentById[item.equipmentId]?.isCritical);
                            }
                        }
                        all.sort((a, b) => {
                            if (a.priority !== b.priority) { // sort first by priority
                                return b.priority.localeCompare(a.priority);
                            } else if (a.whenDue !== b.whenDue) {
                                // next sort by whenDue (may be undefined)
                                return (a.whenDue ? a.whenDue : Number.MAX_SAFE_INTEGER) - (b.whenDue ? b.whenDue : Number.MAX_SAFE_INTEGER);
                            } else {
                                // sort by task
                                return a.task.localeCompare(b.task);
                            }
                        });
                    }
                    setReportingJobs(all);
                }
            );
        }
    }, [isActive, selectedVesselIds, selectedJobPriorities, equipmentById, equipmentCriticality]);

    return reportingJobs;
};
