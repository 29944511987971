import React, { useEffect, useState } from 'react';
import { sharedStateCategories, SharedStateType, sharedState, useAllSharedStateForAnalysis } from '../../shared-state/shared-state';
import SeaTabsGroup from '../SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../SeaTab/SeaTab';

const maxValueLength = 100000;
const formatSharedStateValue = (value: any) => {
    if (!value) return ''+value;
    let s = JSON.stringify(value, undefined, 4);
    if (s.length > maxValueLength) {
        return s.substring(0, maxValueLength)+`... (${ String(10000 * 100 / s.length).substring(0, 4) }% shown)`;
    };
    return s;
};

const columns = [
    {name: 'Shared Data', style: {padding: '0px 4px 0px 0px', flex: '0 275px', overflow: 'hidden'}},
    {name: 'Live Docs', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Runs', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Changed', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Subscribers', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Priority', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Active?', style: {padding: '0px 4px 0px 4px', flex: '0 60px', textAlign: 'center', overflow: 'hidden'} as React.CSSProperties},
    {name: 'Depends On', style: {padding: '0px 4px 0px 4px', flex: '1 1', overflow: 'hidden'}},
    {name: 'Triggers', style: {padding: '0px 4px 0px 4px', flex: '1.5 1', overflow: 'hidden'}},
    {name: 'Notes', style: {padding: '0px 0px 0px 4px', flex: '1 1', overflow: 'hidden'}},
];

let currentTab = 'All';

const SharedStateAnalysis: React.FC = () => {
    const state = useAllSharedStateForAnalysis();
    const [tab, setTab] = useState<string>(currentTab);
    const [valuesToShow, setValuesToShow] = useState<any>({});

    useEffect(() => {
        currentTab = tab; // Remember tab for next time we view this
    }, [tab]);

    const toggleItem = (sharedStateType: SharedStateType) => {
        setValuesToShow((current: any) => {
            const newValuesToShow = {...current};
            if (current[sharedStateType]) {
                delete newValuesToShow[sharedStateType];
            } else {
                newValuesToShow[sharedStateType] = true;
            }
            return newValuesToShow;
        });
    };

    // console.log(`<> Rendering SharedStateAnalysis`);

    return (
        <div style={{
            padding: '0px 8px',
            minWidth: '1200px',
            color: 'var(--text-on-white)',
            
        }}>
            <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                <SeaTab tab="All" mode="forms">All</SeaTab>
                {sharedStateCategories?.map((category) => {
                    return <SeaTab key={category} tab={category} mode="forms">{category}</SeaTab>
                })}
            </SeaTabsGroup>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '8px',
            }}>
                {columns.map((column) => {
                    return (
                        <div key={column.name} style={{...column.style, fontWeight: '500', color: 'white' }}>
                            {column.name}
                        </div>
                    );
                })}
            </div>
            {Object.keys(sharedState).sort().map((sharedStateType) => {
                const sharedStateItem = sharedState[sharedStateType as SharedStateType];
                if (tab && tab !== 'All' && sharedStateItem.category !== tab) {
                    return null;
                }
                const currentValue = state[sharedStateType as SharedStateType];
                return (
                    <div
                        key={sharedStateType} 
                        style={{
                            ...columns[0].style,
                            opacity: (currentValue === undefined) ? 0.5 : 1,
                            backgroundColor: '#f5f5f5',
                            borderRadius: '8px',
                            marginBottom: '4px',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={(e) => toggleItem(sharedStateType as SharedStateType)}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <div style={{ ...columns[0].style, fontSize: '16px' }}>
                                {sharedStateType}
                            </div>
                            <div style={{ ...columns[1].style }}>
                                {sharedStateItem.countLiveDocs() ?? '-'}
                            </div>
                            <div style={{ ...columns[2].style }}>
                                {sharedStateItem.countRuns}
                            </div>
                            <div style={{ ...columns[3].style }}>
                                {sharedStateItem.countChanged}
                            </div>
                            <div style={{ ...columns[4].style }}>
                                {sharedStateItem.subscribers}
                            </div>
                            <div style={{ ...columns[5].style }}>
                                {sharedStateItem.priority}
                            </div>
                            <div style={{ ...columns[6].style }}>
                                {sharedStateItem.isAlwaysActive ? (
                                    'Always'
                                ) : (
                                    sharedStateItem.isActive ? (
                                        'Yes'
                                    ) : (
                                        sharedStateItem.priority > 1 ? 'Queued' : 'No'
                                    )
                                )}
                            </div>
                            <div style={{ ...columns[7].style }}>
                                {sharedStateItem.dependencies.join(', ')}
                            </div>
                            <div style={{ ...columns[8].style }}>
                                {sharedStateItem.triggers.join(', ')}
                            </div>
                            <div style={{ ...columns[9].style }}>
                                {sharedStateItem.notes}
                            </div>
                        </div>
                        {valuesToShow[sharedStateType] &&
                            <div style={{ paddingTop: '4px', whiteSpace: 'pre-wrap', cursor: 'text' }} onClick={(e) => e.stopPropagation()}>
                                {formatSharedStateValue(currentValue)}
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default SharedStateAnalysis;
