import * as React from "react";
function SvgWind(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.649 12.63h18.5c2 0 3.5-1.5 3.5-3.5s-1.5-3-3.5-3-3 1-3.5 2.5M1.649 17.13h14.5c2 0 3.5 1.5 3.5 3.5s-1.5 3-3.5 3-3-1-3.5-2.5M1.649 8.13h8.5c2 0 3.5-1.5 3.5-3.5s-1.5-3-3.5-3-3 1-3.5 2.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgWind;
