import React, { useState, useRef, useEffect } from 'react';
import { disableSwipe, enableSwipe } from '../../shared-state/General/swipeGestures';
import { Map, Marker } from 'leaflet';
import { Geolocation } from '@capacitor/geolocation';
import { waitUntil } from '../../lib/util';
import { IonSelectOption, IonSpinner } from '@ionic/react';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import {
    mapApiKey,
    forecastApiKey,
    metersPerSecondToKnots,
    desaturate,
    formatCoords
} from '../../lib/mapping';
import SeaModalScrollableArea from '../SeaModalScrollableArea/SeaModalScrollableArea';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaWindyForecastTable from '../SeaWindyForecastTable/SeaWindyForecastTable';
import './SeaWindyMap.css';

export type ForecastData = Partial<{
    lat?: number,
    lon?: number,
    t: number[],
    temp: number[],
    windX: number[],
    windY: number[],
    gust: number[],
    waveD: number[],
    waveH: number[],
    waveP: number[]
}>;

type LocationType = {
    latitude: number,
    longitude: number,
    zoom: number,
    accuracy: number
};
interface SeaWindyMapProps {
    isActive: boolean,
    location: LocationType,
    setLocation: (location: LocationType) => void,
    forecastData: ForecastData,
    setForecastData: (data: ForecastData) => void,
    showTable?: boolean
}

const numTimeSlots = 100;

const SeaWindyMap: React.FC<SeaWindyMapProps> = ({
    isActive,
    location,
    setLocation,
    forecastData,
    setForecastData,
    showTable = true
}) => {
    const [loadingForecast, setLoadingForecast] = useState(true);
    const [windyLayer, setWindyLayer] = useState("wind");

    const leafletMapRef = useRef<Map>();
    const windyStoreRef = useRef<any>();
    const locationPickerRef = useRef<{
        windyPicker: any,
        mobileMarker: Marker | undefined,
        isMapReady: boolean
    }>({
        windyPicker: undefined,
        mobileMarker: undefined,
        isMapReady: false
    });
    const windyPickerRef = useRef<any>();
    //const windyUtilsRef = useRef<any>();

    const forecastDataRef = useRef<any>({});
    const lastTimeTimeSelectedRef = useRef(0);
    const [forecastError, setForecastError] = useState<any>();
    const [selectedTime, setSelectedTime] = useState(0);

    // const gotoBrowserLocation = () => {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         console.log('navigator.geolocation.getCurrentPosition position', position);
    //         gotoLocation(position, false);
    //     }, (error) => {
    //         //setDataError(error);
    //     });
    // };

    const gotoDeviceLocation = () => {
        Geolocation.getCurrentPosition().then((position: any) => {
            console.log('Geolocation.getCurrentPosition() position', position);
            gotoLocation(position, false);
        }).catch((error) => {
            alertMessage('Failed to get your location. Please make sure you have given Sea Flux permission to access your device\'s location.');
        });
    };

    const gotoLocation = (position: GeolocationPosition, animate: boolean) => {
        if (position.coords) {
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
                zoom: 10
            });
        }
        if (leafletMapRef.current) {
            if (animate) {
                leafletMapRef.current.flyTo([position.coords.latitude, position.coords.longitude], 10, { animate: true, duration: 1 });
            } else {
                leafletMapRef.current.setView([position.coords.latitude, position.coords.longitude], 10);
            }

            setLocationPickerLocation(position.coords.latitude, position.coords.longitude);
            fetchForecastData(position.coords.latitude, position.coords.longitude);
        }
    };

    const onPickerMoved = (latitude: number, longitude: number) => {
        console.log(`onPickerMoved! latitude=${latitude} longitude=${longitude}`);
        if (leafletMapRef.current) {
            leafletMapRef.current.flyTo([latitude, longitude], undefined, { animate: true, duration: 0.5 });
        }
        setLocation({
            ...location,
            latitude,
            longitude
        });
        fetchForecastData(latitude, longitude);
    };

    const setLocationPickerLocation = (latitude: number, longitude: number) => {
        if (locationPickerRef.current?.mobileMarker) {
            locationPickerRef.current.mobileMarker.setLatLng([latitude, longitude]);
        } else if (locationPickerRef.current?.windyPicker) {
            locationPickerRef.current.windyPicker.open({
                lat: latitude,
                lon: longitude
            });
        } else {
            waitUntil(() => locationPickerRef.current.isMapReady).then(() => {
                if ((window as any)?.W?.detectDevice?.device === 'mobile') {
                    // Windy is in mobile mode
                    // We will use a basic marker to detonate location
                    locationPickerRef.current.mobileMarker = (window as any).L.marker(
                        [latitude, longitude],
                        {
                            title: 'Forecast location',
                            draggable: true,
                            autoPan: true,
                            autoPanPadding: (window as any).L.point(100, 100),
                            autoPanSpeed: 10
                        }
                    );
                    locationPickerRef.current.mobileMarker?.addTo(leafletMapRef.current as Map);
                    locationPickerRef.current.mobileMarker?.on('dragend', (data) => {
                        const latlng = locationPickerRef.current.mobileMarker?.getLatLng();
                        if (latlng) {
                            onPickerMoved(latlng.lat, latlng.lng);
                        }
                    });
                } else {
                    // Windy is not in mobile mode
                    // We can use windy picker
                    windyPickerRef.current.on('pickerOpened', (e: any) => {
                        locationPickerRef.current.windyPicker = windyPickerRef.current;
                        windyPickerRef.current.on('pickerMoved', (data: any) => {
                            onPickerMoved(data.lat, data.lon);
                        });
                    });
                    windyPickerRef.current.open({
                        lat: latitude,
                        lon: longitude
                    });
                }
            });
        }
    };

    const fetchForecastData = (latitude: number, longitude: number) => {
        if (!showTable) {
            return;
        }
        setLoadingForecast(true);
        let gfsJson: any;
        fetch('https://api.windy.com/api/point-forecast/v2', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lat: latitude,
                lon: longitude,
                model: "gfs",
                parameters: ["ptype", "temp", "wind", "windGust"],
                levels: ["surface"],
                key: forecastApiKey
            })
        }).then((rawResponse) => {
            return rawResponse.json();
        }).then((_gfsJson) => {
            gfsJson = _gfsJson;
            return fetch('https://api.windy.com/api/point-forecast/v2', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    lat: latitude,
                    lon: longitude,
                    model: "gfsWave",
                    parameters: ["waves"],
                    levels: ["surface"],
                    key: forecastApiKey
                })
            });
        }).then((rawResponse) => {
            return rawResponse.json();
        }).then((gfsWaveJson) => {
            // Compile and convert data
            const data = {
                t: [] as number[],      // ts
                temp: [] as number[],   // (K) temp-surface
                windX: [] as number[],  // (m/s) wind_u-surface
                windY: [] as number[],  // (m/s) wind_v-surface
                gust: [] as number[],   // (m/s) gust-surface
                waveD: [] as number[],  // (direction in degrees) waves_direction-surface
                waveH: [] as number[],  // (height in m) waves_height-surface
                waveP: [] as number[],  // (period in s) waves_period-surface
            };
            let gfsIndex = 0;
            let gfsWaveIndex = 0;
            while (
                data.t.length < numTimeSlots &&
                gfsIndex < gfsJson.ts.length &&
                gfsWaveIndex < gfsWaveJson.ts.length
            ) {
                if (gfsJson.ts[gfsIndex] === gfsWaveJson.ts[gfsWaveIndex]) {
                    data.t.push(gfsJson.ts[gfsIndex]);
                    data.temp.push(Math.round(gfsJson['temp-surface'][gfsIndex] - 273.15));  // (Convert K to celcius) 0dp
                    data.windX.push(Math.round(gfsJson['wind_u-surface'][gfsIndex] * metersPerSecondToKnots * 10000) / 10000); // (Convert from m/s to kt) 4dp
                    data.windY.push(Math.round(gfsJson['wind_v-surface'][gfsIndex] * metersPerSecondToKnots * 10000) / 10000); // (Convert from m/s to kt) 4dp
                    data.gust.push(Math.round(gfsJson['gust-surface'][gfsIndex] * metersPerSecondToKnots * 100) / 100);    // (Convert from m/s to kt) 2dp
                    data.waveD.push(Math.round(gfsWaveJson['waves_direction-surface'][gfsWaveIndex] * 100) / 100);  // degrees, 2dp
                    data.waveH.push(Math.round(gfsWaveJson['waves_height-surface'][gfsWaveIndex] * 10) / 10);       // m, 1dp
                    data.waveP.push(Math.round(gfsWaveJson['waves_period-surface'][gfsWaveIndex] * 10) / 10);       // s
                    gfsIndex++;
                    gfsWaveIndex++;
                } else {
                    if (gfsJson.ts[gfsIndex] < gfsWaveJson.ts[gfsWaveIndex]) {
                        gfsIndex++;
                    } else {
                        gfsWaveIndex++;
                    }
                }
            }
            //data.waveD =[0, 45, 90, 135, 180, 225, 270, 315, 360, 45, 90, 135, 180, 225, 270, 315]; // Test wave directions
            // console.log('data', data);
            setForecastData(data);
            forecastDataRef.current = data;
            setLoadingForecast(false);
            selectNearestTime(Date.now()); // Determine selected time based on current time
        }).catch((error) => {
            setForecastError(error);
        });
    };

    const selectNearestTime = (t: number) => {
        console.log('selectNearestTime t='+t);
        let index = 0;
        while (index + 1 < forecastDataRef.current.t.length && forecastDataRef.current.t[index] < t) {
            index++;
        }
        index = Math.max(0, index - 1);
        setSelectedTime(forecastDataRef.current.t[index]);
    };

    const initWindy = () => {
        const options = {
            // Required: API key
            key: mapApiKey,
        
            // Put additional console output
            verbose: true,
        
            // Optional: Initial state of the map
            lat: location.latitude,
            lon: location.longitude,
            zoom: location.zoom
        };
        console.log('windyInit! options', options);

        // Initialize Windy API
        (window as any).windyInit(options, (windyAPI: any) => {
            // windyAPI is ready, and contain 'map', 'store',
            // 'picker' and other usefull stuff

            const { map, store, picker, colors, broadcast, utils } = windyAPI;
            leafletMapRef.current = map;
            windyStoreRef.current = store;
            windyPickerRef.current = picker;
            //windyUtilsRef.current = utils;

            colors.wind.changeColor([
                [0,desaturate([154,12,204,256])],
                [2.5742574257425743,desaturate([53,55,251,256])],
                [5,desaturate([59,193,36,256])],
                [7.7227722772277225,desaturate([205,214,15,256])],
                [10.297029702970297,desaturate([239,112,7,256])],
                [12.871287128712872,desaturate([241,1,1,256])],
                [15.445544554455445,desaturate([186,4,3,256])],
                [18.019801980198018,desaturate([130,3,2,256])],
                [20.594059405940595,desaturate([100,3,2,256])],
                [23.168316831683168,desaturate([55,4,3,256])],
                [25.742574257425744,desaturate([94,94,94,256])]
            ]);

            colors.waves.changeColor([
                [0,[145,2,213,256]],
                [1,[54,57,252,256]],
                [2,[1,191,213,256]],
                [3,[81,197,110,256]],
                [4,[212,172,44,256]],
                [5,[225,102,43,256]],
                [6,[226,18,99,256]],
                [7,[219,5,10,256]],
                [8,[143,6,5,256]],
                [9,[62,9,8,256]],
                [10,[91,91,91,256]]
            ]);

            // console.log('store', store);
            // console.log('store.getAllowed(overlay)', store.getAllowed('overlay'), store.get('overlay'));
            // console.log('store.getAllowed(favOverlays)', store.getAllowed('favOverlays'), store.get('favOverlays'));
            // console.log('store.getAllowed(isolines)', store.getAllowed('isolines'), store.get('isolines'));
            // console.log('store.getAllowed(timestamp)', store.getAllowed('timestamp'), store.get('timestamp'));
            // console.log('store.getAllowed(product)', store.getAllowed('product'), store.get('product'));

            store.set('isolines', 'pressure');
            store.set('latlon', false);

            if (document.getElementById('logo')) {
                (document.getElementById('logo') as any).target = '_blank';
            }

            map.on('click', (data: any) => {
                if (locationPickerRef.current.isMapReady) {
                    setLocationPickerLocation(data.latlng.lat, data.latlng.lng);
                    onPickerMoved(data.latlng.lat, data.latlng.lng);
                }
            });

            broadcast.once('redrawFinished', () => {
                locationPickerRef.current = {
                    ...locationPickerRef.current,
                    isMapReady: true
                };
            });

            broadcast.on('paramsChanged', (data: any) => {
                if (
                    locationPickerRef.current.isMapReady &&
                    forecastDataRef.current.t &&
                    (Date.now() - lastTimeTimeSelectedRef.current) > 1000
                ) {
                    selectNearestTime(store.get('timestamp'));
                }
            });

            gotoDeviceLocation();

        });
    };

    useEffect(() => {
        if (isActive) {
            // activate
            setLoadingForecast(true);
            setForecastData({});
            forecastDataRef.current = {};
            setForecastError(undefined);
            locationPickerRef.current = {
                windyPicker: undefined,
                mobileMarker: undefined, // Fallback marker used when on mobile
                isMapReady: false
            };
            waitUntil(() => document.getElementById('windy')).then(() => {
                initWindy();
            });
        } else {
            // deactivate
            leafletMapRef.current = undefined;
            locationPickerRef.current = {
                mobileMarker: undefined,
                windyPicker: undefined,
                isMapReady: false
            };
        }
    }, [isActive]);

    const selectTime = (t: number, layer: string | undefined) => {
        setSelectedTime(t);
        lastTimeTimeSelectedRef.current = Date.now();
        if (windyStoreRef.current) {
            windyStoreRef.current.set('timestamp', t);
        }
        if (layer) {
            setWindyLayer(layer);
            if (windyStoreRef.current) {
                windyStoreRef.current.set('overlay', layer);
            }
        }
    };

    let day = '';

    return (
        <>
            <div className="windy-layer-buttons no-select">
                <SeaSelect
                    value={windyLayer}
                    onchange={(e) => {
                        const layer = e.detail.value;
                        setWindyLayer(layer);
                        if (windyStoreRef.current) {
                            windyStoreRef.current.set('overlay', layer);
                        }
                    }}
                >
                    <IonSelectOption value="wind">Wind</IonSelectOption>
                    <IonSelectOption value="gust">Gusts</IonSelectOption>
                    <IonSelectOption value="waves">Waves</IonSelectOption>
                    <IonSelectOption value="swell1">Swell</IonSelectOption>
                    <IonSelectOption value="temp">Temperature</IonSelectOption>
                    <IonSelectOption value="satellite">Satellite</IonSelectOption>
                </SeaSelect>
            </div>

            <div
                id="windy"
                onTouchMove={(e) => {
                    disableSwipe();
                }}
                onTouchEnd={(e) => {
                    enableSwipe(500);
                }}
            >
            </div>

            <div className="windy-nav-button-container">
                <div
                    className={`pushy no-select windy-nav-button`}
                    aria-label='Navigate to current location'
                    onClick={(e) => {
                        if (
                            locationPickerRef.current.isMapReady && (
                                locationPickerRef.current.mobileMarker ||
                                locationPickerRef.current.windyPicker
                            )
                        ) {
                            gotoDeviceLocation();
                        }
                    }}
                    title="Centre on current location"
                >
                    <SeaIcon icon="navigate"/>
                </div>
            </div>

            <div style={{ height: '20px' }}></div>

            {forecastError &&
                <div style={{ backgroundColor: 'pink', padding: '20px' }}>
                    <p>
                        <b>There was a problem getting forecast data.</b>
                    </p>
                    {forecastError?.message}
                    <p style={{ fontSize: '10px', opacity: 0.6 }}>
                        {JSON.stringify(forecastError)}
                    </p>
                </div>
            }

            {loadingForecast &&
                <div>
                    <IonSpinner
                        name="crescent"
                        className="sea-spinner-2"
                        style={{
                            color: 'var(--ion-color-primary)',
                            width: '32px',
                            height: '32px'
                        }}
                    />
                </div>
            }

            {showTable &&
                <div className={(forecastData?.t) ? 'reveal' : 'conceal'}>
                    {!loadingForecast && forecastData?.t &&
                        <SeaModalScrollableArea>
                            <h2 style={{
                                fontSize: '16px',
                                marginTop: '0px'
                            }}>
                                Forecast {location && formatCoords(location.latitude, location.longitude)}
                            </h2>
                            <SeaWindyForecastTable
                                mode='interact-with-map'
                                forecastData={forecastData}
                                //windyUtilsRef={windyUtilsRef}
                                selectedTime={selectedTime}
                                selectTime={selectTime}
                            />
                        </SeaModalScrollableArea>
                    }
                </div>
            }
        </>
    );
};

export default SeaWindyMap;
