import * as React from "react";

function SvgLogBook(props) {
  return (
    <svg
      width={28}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.569 14.307h2.267a9.501 9.501 0 002.42 5.64L4.649 21.5a.686.686 0 000 .993.739.739 0 00.513.205.739.739 0 00.513-.205l1.608-1.554a10.164 10.164 0 005.835 2.342v2.192c0 .388.325.702.726.702.4 0 .726-.314.726-.702v-2.192a10.175 10.175 0 005.835-2.342l1.607 1.554a.74.74 0 00.513.205.739.739 0 00.514-.205.685.685 0 000-.993l-1.608-1.553a9.495 9.495 0 002.421-5.64h2.265c.4 0 .726-.314.726-.702a.714.714 0 00-.726-.702h-2.265a9.495 9.495 0 00-2.421-5.64l1.607-1.554a.685.685 0 000-.993.744.744 0 00-1.026 0L20.404 6.27A10.172 10.172 0 0014.57 3.93v-2.19a.714.714 0 00-.725-.702.714.714 0 00-.727.702v2.19A10.175 10.175 0 007.282 6.27L5.675 4.717a.745.745 0 00-1.027 0 .686.686 0 000 .993l1.608 1.553a9.497 9.497 0 00-2.421 5.64H1.569a.714.714 0 00-.727.702c0 .388.326.702.727.702zm3.722 0h4.46a3.92 3.92 0 00.686 1.6l-3.152 3.046a8.12 8.12 0 01-1.994-4.646zm8.552-3.32c1.494 0 2.71 1.174 2.71 2.618s-1.216 2.619-2.71 2.619-2.709-1.175-2.709-2.62c0-1.443 1.215-2.618 2.708-2.618zM8.31 19.944l3.152-3.045a4.22 4.22 0 001.655.662v4.312a8.701 8.701 0 01-4.807-1.929zm6.259 1.928v-4.31a4.227 4.227 0 001.655-.664l3.152 3.046a8.703 8.703 0 01-4.807 1.928zm5.833-2.92l-3.152-3.047c.341-.47.579-1.013.686-1.6h4.46a8.125 8.125 0 01-1.994 4.647zm1.994-6.05h-4.46a3.922 3.922 0 00-.686-1.6l3.152-3.046a8.12 8.12 0 011.994 4.646zm-3.02-5.639l-3.153 3.048a4.21 4.21 0 00-1.654-.664V5.336a8.704 8.704 0 014.806 1.928zm-6.26-1.928v4.312a4.206 4.206 0 00-1.654.662L8.311 7.264a8.697 8.697 0 014.807-1.928zm-5.832 2.92l3.152 3.047a3.923 3.923 0 00-.687 1.6H5.291a8.121 8.121 0 011.994-4.647z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default SvgLogBook;
