import React, { useCallback, useEffect, useMemo, useState } from "react";
import SeaModal from "../../../../components/SeaModal/SeaModal";
import { defaultCrewPermissions, defaultLicenseePermissions, defaultSkipperPermissions } from "../../../../lib/permissions";
import { firestore, splittableBatch } from "../../../../lib/firebase";
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged } from "../../../../lib/util";
import { useFormik } from "formik";
import { logAction } from "../../../../shared-state/General/actionLog";
import { renderFullName } from "../../../../shared-state/Core/users";
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, reportError, traceAction } from "../../../../managers/ErrorsManager/ErrorsManager";
import { UserRole, UserRolesData } from '../../../../shared-state/Crew/userRoles';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import SeaUserPermissions from "../../../../components/SeaUserPermissions/SeaUserPermissions";
import SeaTabsGroup from "../../../../components/SeaTabsGroup/SeaTabsGroup";
import SeaTab from "../../../../components/SeaTab/SeaTab";
import SeaButton from "../../../../components/SeaButton/SeaButton";
import EditUserRoleModal from "./EditUserRoleModal";
import SeaLinkButton from "../../../../components/SeaLinkButton/SeaLinkButton";
import SeaTabContent from "../../../../components/SeaTabContent/SeaTabContent";
import './CrewSettingsModal.css'

interface CrewSettingsModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

interface UserRolesType {
    [key: string]: UserRole;
}

const DEFAULT_USER_ROLES: UserRolesType = {
    Admin: {...defaultLicenseePermissions, name: 'Admin'} as UserRole,
    Skipper: {...defaultSkipperPermissions, name: 'Skipper'} as UserRole,
    Crew: {...defaultCrewPermissions, name: 'Crew'} as UserRole,
};

const CrewSettingsModal = ({ showModal, setShowModal }: CrewSettingsModalProps) => {
    const userId = sharedState.userId.use(showModal);
    const userRoles = sharedState.userRoles.use(showModal) as UserRolesData;
    const licenseeId = sharedState.licenseeId.use(showModal);
    const [roleModalOpen, setRoleModalOpen] = useState(false);
    const [tab, setTab] = useState("userRoles");
    const [editingName, setEditingName] = useState(false);

    const initialValues = useMemo(() => {
        return Object.keys(userRoles?.byId || {}).length > 0 ? userRoles?.byId : DEFAULT_USER_ROLES
    }, [userRoles?.byId]);

    const [currentRoleId, setCurrentRoleId] = useState((initialValues && Object.keys(initialValues)[0]) || "Admin");

   useEffect(() => {
        const firstRoleKey = Object.keys(initialValues).find(role => initialValues[role]?.state !== "deleted");
        if (firstRoleKey) {
            setCurrentRoleId(firstRoleKey);
        }
    }, [initialValues]);
    
    const { handleSubmit, values, setFieldValue, isSubmitting, isValid, setSubmitting } = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
            Promise.resolve()
                .then(() => {
                    if (!userId) {
                        throw new Error("No userId");
                    }
                    // Process form
                    const action = traceAction("userRoles") as Action;
                    const batch = splittableBatch(firestore, 20 - 1); // -2 for userPermissions needing 2 extra security calls
                    Object.keys(data).forEach((role) => {
                        if (userRoles?.byId?.[role]) {
                            if (licenseeId && haveValuesChanged(data[role], userRoles.byId[role])) {
                                action.type = "update";
                                action.docId = licenseeId;

                                // Update User Roles doc
                                batch.set(
                                    doc(firestore, "userRoles", role),
                                    {
                                        ...(data as UserRolesType)[role],
                                        whenUpdated: action.whenAction,
                                        updatedBy: userId,
                                        touched: serverTimestamp(),
                                    } as UserRole,
                                    { merge: true }
                                );
                                logAction(
                                    batch,
                                    'Update',
                                    'userRoles',
                                    userId,
                                    renderFullName(),
                                    undefined,
                                    [licenseeId]
                                );
                                action.data = {
                                    licenseeId,
                                    ...data,
                                };
                                action.save(`Update ${role} user role for ${licenseeId}`, batch);
                            }
                        } else {
                            const newUserRolesRef = doc(collection(firestore, "userRoles"));
                            action.type = "create";
                            action.docId = newUserRolesRef.id;

                            batch.set(newUserRolesRef, {
                                ...data[role], // had to move to the front to avoid typescript error
                                licenseeId: licenseeId,
                                whenAdded: action.whenAction,
                                addedBy: userId,
                                name: role,
                                state: "active",
                                touched: serverTimestamp(),
                            });
                            logAction(
                                batch,
                                'Add',
                                'userRoles',
                                newUserRolesRef.id,
                                `${licenseeId} userRole created`,
                                undefined,
                                [newUserRolesRef.id]
                            );
                            action.data = {
                                licenseeId,
                                name: role,
                                ...data,
                            };
                            action.save(`Add ${role} user roles for ${licenseeId}`, batch);
                        }
                    });

                    onCollectionUpdated(batch, "userRoles");

                    batch
                        .commit()
                        .then(() => {
                            action.reportSuccess();
                        })
                        .catch((error) => {
                            action.reportError(error?.message, error);
                        });
                })
                .catch((error: any) => {
                    reportError(`Failed to update user roles`, error?.message, error, {
                        licenseeId,
                        data,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    setShowModal(false);
                });
        },
        enableReinitialize: true,
    });

    const currentRole = useMemo(() => {
        if (!values || !values[currentRoleId]) {
            return undefined;
        }
        return values[currentRoleId];
    }, [currentRoleId, values]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [values, initialValues]);

    const handleNewRoleClick = useCallback((e: React.MouseEvent<Element, MouseEvent>, id?: string) => {
        e.preventDefault();
        e.stopPropagation();
        if (id) {
            setEditingName(true);
        }
        setRoleModalOpen(true);
    }, []);

    const handleNewRole = useCallback((name: string) => {
        if (editingName) {
            setFieldValue(currentRoleId, { ...values[currentRoleId], name: name});
            setEditingName(false);
            return;
        }
        setFieldValue(name, defaultCrewPermissions);
        setCurrentRoleId(name);
    }, [editingName, setFieldValue, currentRoleId, values]);

    const handleDelete = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setFieldValue(`${currentRoleId}.state`, "deleted");
        setCurrentRoleId(Object.keys(values)[0]);
    }, [setFieldValue, currentRoleId, values]);

    const saveDisabled = useMemo(() => {
        return (!isModalDirty() && userRoles ? true : false) || !isValid || isSubmitting;
    }, [isModalDirty, userRoles, isValid, isSubmitting]);


    const handleSetData = useCallback((permissionId: keyof UserRole, value: number) => {
        if (Number(currentRole?.[permissionId]) !== Number(value)) {
            setFieldValue(`${currentRoleId}.${permissionId}`, value);
        }
    }, [currentRole, setFieldValue, currentRoleId]);


    return (
        <SeaModal
            title="Crew Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            size="semi-wide"
            tabsPanel={
                <>
                    <SeaTabsGroup key="crew-settings" selectedTab={tab} setTab={setTab} mode="forms">
                        <SeaTab tab="userRoles" mode="forms">
                            User Roles
                        </SeaTab>
                    </SeaTabsGroup>
                    <SeaTabContent tab="userRoles" selectedTab={tab}>
                        <SeaTabsGroup
                            selectedTab={currentRoleId}
                            setTab={setCurrentRoleId}
                            mode="forms"
                            mini
                            showPlus
                            plusText="Add User Role"
                            onAddTab={handleNewRoleClick}
                        >
                            {Object.keys(values).map((role) => {
                                if (values[role as keyof typeof DEFAULT_USER_ROLES]?.state === "deleted") {
                                    return null;
                                }
                                return (
                                <SeaTab key={role} tab={role} mode="forms">
                                    {values[role as keyof typeof DEFAULT_USER_ROLES]?.name || role}
                                </SeaTab>
                                );
                            })}
                        </SeaTabsGroup>
                    </SeaTabContent>
                </>
            }
        >
            <form onSubmit={handleSubmit}>
                <SeaTabContent tab="userRoles" selectedTab={tab}>
                    <div className="user-roles">
                        {currentRole && <SeaUserPermissions data={currentRole} setFieldData={handleSetData} currentTab={currentRoleId} />}
                        <SeaLinkButton mode="standard-link" onClick={(e) => handleNewRoleClick(e, currentRoleId)}>Rename Role</SeaLinkButton>
                        <SeaLinkButton mode="standard-link" onClick={handleDelete}>Delete Role</SeaLinkButton>
                    </div>

                    <div className="view-modal-buttons">
                        <SeaButton type="submit" disabled={saveDisabled}>
                            Save Settings
                        </SeaButton>
                    </div>
                </SeaTabContent>
            </form>

            {roleModalOpen && 
                <EditUserRoleModal showModal={roleModalOpen} setShowModal={setRoleModalOpen} handleNewRole={handleNewRole} currentRoleName={editingName ? values[currentRoleId]?.name : undefined} />
            }
        </SeaModal>
    );
};

export default CrewSettingsModal;
