import React, { useState } from 'react';
import { ColorResult, HuePicker} from 'react-color';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaModal from '../SeaModal/SeaModal';
import SeaButton from '../SeaButton/SeaButton';
import './SeaColourModal.css';

// From: https://stackoverflow.com/questions/13806483/increase-or-decrease-color-saturation
const floatToHex = (val: number) => { return ('0' + Math.round(val * 255).toString(16)).substr(-2); };
const rgb2hex    = (rgb: number[]) => { return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]); };
const applySaturationToHexColor = (hex: string, saturationPercent: number) => {
    const saturationFloat   = saturationPercent / 100;
    const rgbIntensityFloat = [
        parseInt(hex.substr(1,2), 16) / 255,
        parseInt(hex.substr(3,2), 16) / 255,
        parseInt(hex.substr(5,2), 16) / 255
    ];

    const rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function(a, b){ return a - b; });
    const maxIntensityFloat       = rgbIntensityFloatSorted[2];
    const mediumIntensityFloat    = rgbIntensityFloatSorted[1];
    const minIntensityFloat       = rgbIntensityFloatSorted[0];

    if (maxIntensityFloat === minIntensityFloat) {
        return hex;
    }

    // New color max intensity wont change. Lets find medium and weak intensities.
    let newMediumIntensityFloat;
    const newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

    if (mediumIntensityFloat === minIntensityFloat) {
        // Weak colors have equal intensity.
        newMediumIntensityFloat = newMinIntensityFloat;
    } else {
        // Calculate medium intensity with respect to original intensity proportion.
        var intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
        newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
    }

    const newRgbIntensityFloat       = [] as any[];
    const newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

    // We've found new intensities, but we have then sorted from min to max.
    // Now we have to restore original order.
    rgbIntensityFloat.forEach(function(originalRgb) {
        var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
        newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
    });

    return rgb2hex(newRgbIntensityFloat);
}

const applyValueToHexColor = (hex: string, value: number) => {
    const rgb = [
        parseInt(hex.substr(1,2), 16) / 255,
        parseInt(hex.substr(3,2), 16) / 255,
        parseInt(hex.substr(5,2), 16) / 255
    ];
    const max = Math.max(Math.max(rgb[0], rgb[1]), rgb[2]);

    let result = [
        (rgb[0] === max) ? 1 : (rgb[0] / max),
        (rgb[1] === max) ? 1 : (rgb[1] / max),
        (rgb[2] === max) ? 1 : (rgb[2] / max)
    ];
    result = [
        result[0] * value,
        result[1] * value,
        result[2] * value
    ];
    return rgb2hex(result);
}

const isGrayscale = (hex: string) => { // hex = #xxxxxx
    return (
        hex.length >= 7 &&
        hex.substring(1,3) === hex.substring(3,5) &&
        hex.substring(1,3) === hex.substring(5,7)
    );
}

interface SeaColourModalProps {
    title?: string,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    color: string,
    setColor: (color: string) => void,
    colorSwatches?: string[],
    level?: number
}

const SeaColourModal: React.FC<SeaColourModalProps> = ({
    showModal,
    setShowModal,
    color,
    setColor,
    colorSwatches,
    title = 'Choose Colour',
    level = 3
}) => {
    const [currentColor, setCurrentColor] = useState('#ffffff');

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="thin"
            level={level}
            onOpened={() => {
                setCurrentColor(color);
            }}
        >
            <SeaLabel>Colour Chosen</SeaLabel>
            <div className="sea-input" style={{ width: '100%', height: '50px', backgroundColor: currentColor }}></div>
            <div style={{ height: '26px' }}></div>
            {colorSwatches && colorSwatches.length > 0 &&
                <>
                    <SeaLabel>Swatches</SeaLabel>
                    <div className="swatches-container">
                        {colorSwatches.map((_color) => {
                            return (
                                <div
                                    key={_color}
                                    className="sea-input sea-colour swatch"
                                    style={{ backgroundColor: _color }}
                                    onClick={(e) => {
                                        setCurrentColor(_color)
                                    }}
                                >
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ height: '22px' }}></div>
                </>
            }
            <SeaLabel>Customise</SeaLabel>
            <HuePicker
                color={currentColor}
                onChange={(result: ColorResult) => {
                    setCurrentColor(result.hex);
                }}
                height="50px"
                width="100%"
            />
            {!isGrayscale(currentColor) &&
                <>
                    <div style={{ height: '22px' }}></div>
                    <SeaLabel>Saturation</SeaLabel>
                    <div className="swatches-container">
                        {[12.5,25,37.5,50,62.5,75,87.5].map((saturation: number) => {
                            return (
                                <div
                                    key={saturation}
                                    className="sea-input sea-colour swatch"
                                    style={{ backgroundColor: applySaturationToHexColor(currentColor, saturation) }}
                                    onClick={(e) => {
                                        setCurrentColor(applySaturationToHexColor(currentColor, saturation))
                                    }}
                                >
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ height: '22px' }}></div>
                    <SeaLabel>Brightness</SeaLabel>
                    <div className="swatches-container">
                        {[0.1428,0.2857,0.4285,0.5714,0.7142,0.8571,1].map((value: number) => {
                            return (
                                <div
                                    key={value}
                                    className="sea-input sea-colour swatch"
                                    style={{ backgroundColor: applyValueToHexColor(currentColor, value) }}
                                    onClick={(e) => {
                                        setCurrentColor(applyValueToHexColor(currentColor, value))
                                    }}
                                >
                                </div>
                            );
                        })}
                    </div>
                </>
            }
            <div style={{ height: '60px' }}></div>
            <SeaButton zone="white" onClick={(e) => {
                setColor(currentColor);
                setShowModal(false);
            }}>
                Choose Colour
            </SeaButton>

        </SeaModal>
    );
};

export default SeaColourModal;
