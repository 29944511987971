import React from 'react';
import './SeaGridDividingLine.css';

interface SeaGridDividingLineProps {
    // children: ReactNode,
    // w?: '100' | '50' | '33' | '25',
    // label?: string,
    // help?: SeaHelp,
    // capitalize?: boolean,
    // short?: boolean
}

const SeaGridDividingLine: React.FC<SeaGridDividingLineProps> = () => {

    return (
        <div className={`w100`} style={{ height: '1px', minHeight: '1px' }}>
            <div className="dividing-line"></div>
        </div>
    );
};

export default SeaGridDividingLine;
