import React, { useState, useRef, useEffect } from 'react';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaWindyMap, { ForecastData } from '../SeaWindyMap/SeaWindyMap';
import './SeaWindyMapFullscreen.css';

interface SeaWindyMapFullscreenProps {
    show: boolean,
    setShow: (show: boolean) => void
}

const SeaWindyMapFullscreen: React.FC<SeaWindyMapFullscreenProps> = ({
    show,
    setShow
}) => {
    const isMounted = useRef(false);
    const [fadeIn, setFadeIn] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [location, setLocation] = useState({
        latitude: -41.2924, // Near Wellington (so we centre in on NZ by default)
        longitude: 174.7787,
        zoom: 5,
        accuracy: 0
    });
    const [forecastData, setForecastData] = useState<ForecastData>({});

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                if (!isMounted.current) return;
                setFadeIn(true);
                setIsActive(true);
            }, 1);
        }
    }, [show]);

    const onClose = () => {
        setFadeIn(false);
        setTimeout(() => {
            if (!isMounted.current) return;
            setShow(false);
            setIsActive(false);
        }, 500);
    };

    if (show) {
        return (
            <div className={`sea-windy-fullscreen ${fadeIn ? 'reveal' : 'conceal'}`}>
                <div className="sea-modal-x" onClick={(e) => onClose()}>
                    <SeaIcon icon="close"/>
                </div>

                    <SeaWindyMap
                        isActive={isActive}
                        location={location}
                        setLocation={setLocation}
                        forecastData={forecastData}
                        setForecastData={setForecastData}
                    />

            </div>
        );
    }
    return null;
};

export default SeaWindyMapFullscreen;
