import React, { useState } from 'react';
import './SeaCustomFields.css';
import { IonCol } from '@ionic/react';
import SeaInput from '../SeaInput/SeaInput';
import { toFloat } from '../../lib/util';

/*
    [
        {
            name:
            value:
        }
        ...
    ]
*/

export interface CustomFields {
    [key: string]: any;
}

interface SeaCustomFieldsProps {
    data?: any[],
    setData?: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
    minLength?: number,
    maxLength?: number,
    isHours?: boolean,
    totalHours?: number,
    setTotalHours?: (hours: number) => void
}

const SeaCustomFields: React.FC<SeaCustomFieldsProps> = ({ data, setData, inputmode = 'text', type = 'text', minLength, maxLength, isHours, totalHours, setTotalHours }) => {

    const [errors, setErrors] = useState<{
        [key: string]: string
    }>({});

    const onChange = (e: CustomEvent<any>, name: string) => {
        if (data && data.length > 0 && setData) {
            const _data = data.map((item) => {
                if (item.name === name) {
                    return {
                        name: name,
                        value: e.detail.value
                    }
                }
                return {
                    name: item.name,
                    value: item.value
                };
            });
            setData(_data);
            checkValue(_data, name);
            updateTotalHours(_data);
        }
    }

    const updateTotalHours = (data: CustomFields[]) => {
        let x = 0;
        data?.forEach((item) => {
            if (item.value.length > 0) {
                x += toFloat(item.value, 0);
            }
        });
        if (setTotalHours) {
            setTotalHours(x);
        }
    }

    const onBlur = (e: CustomEvent<any>, name: string) => {
        if (data) {
            checkValue(data, name);
        }
    }

    const addError = (name: string, error: string) => {
        const _errors = Object.assign({}, errors);
        _errors[name] = error;
        setErrors(_errors);
    };
    const removeError = (name: string) => {
        if (errors[name]) {
            const _errors = Object.assign({}, errors);
            delete _errors[name];
            setErrors(_errors);
        }
    };

    const checkValue = (data: CustomFields[], name: string) => {
        if (isHours) {
            data?.forEach((item) => {
                if (item.name === name) {
                    if (item.value.length > 0 && isNaN(parseFloat(item.value))) {
                        addError(name, 'Must be a valid number');
                    } else {
                        removeError(name);
                    }
                }
            });
        }
    }

    if (data === undefined || data.length === 0) {
        return null;
    }
    return (<>
        {data?.map((item: any) => {
            return (
                <IonCol key={item.name} size="3">
                    <SeaInput
                        label={item.name}
                        value={item.value}
                        onchange={(e) => onChange(e, item.name)}
                        onblur={(e) => onBlur(e, item.name)}
                        zone="white"
                        type={type}
                        inputmode={inputmode}
                        minLength={minLength}
                        maxLength={maxLength}
                        debounce={200}
                        error={errors[item.name] ? errors[item.name] : ''}
                    />
                </IonCol>
            );
        })}
        {isHours && setTotalHours && data?.length > 0 &&
            <IonCol size="12">
                <div style={{maxWidth: '320px'}}>
                    {/* Total Hours:  */}
                    <SeaInput
                        label="Total Hours"
                        value={''+totalHours}
                        disabled={true}
                    />
                </div>
            </IonCol>
        }
    </>);
};

export default SeaCustomFields;
