import React, { useState, useEffect, useMemo } from 'react';
import { SplittableBatch } from '../../../../lib/firebase';
import { WriteBatch } from "firebase/firestore";
import { formatTextAreaText, formatDate, formatValue, formatInterval, warnDays } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { permissionLevels, canEdit } from '../../../../lib/permissions';
import { renderCategoryName } from '../../../../lib/categories';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SafetyCheckCompleted, useCompletedSafetyCheckItems } from '../../../../shared-state/VesselSafety/completedSafetyCheckItems';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditSafetyCheck from '../EditSafetyCheck/EditSafetyCheck';
import CompleteSafetyCheck from '../CompleteSafetyCheck/CompleteSafetyCheck'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './ViewSafetyCheck.css';

interface ViewSafetyCheckProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: SafetyCheckItem,
    level?: number
}

const ViewSafetyCheck: React.FC<ViewSafetyCheckProps> = ({showModal, setShowModal, selectedItem, level = 2}) => {
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(showModal);
    const completedSafetyCheckItems = useCompletedSafetyCheckItems(showModal ? selectedItem : undefined);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showCompleteItemModal, setShowCompleteItemModal] = useState(false);
    const [historyItemToUpdate, setHistoryItemToUpdate] = useState<SafetyCheckCompleted>();

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onCompleteItem = (item?: SafetyCheckCompleted) => {
        setHistoryItemToUpdate(item)
        setShowCompleteItemModal(true);
    };
    const canEditItem = useMemo(() => {
        return canEdit('safetyEquipmentChecks')
    }, []);

    if (!selectedItem) {
        return null;
    }

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        Safety Check - {renderCategoryName(selectedItem?.itemId, vesselSafetyItems)}
                    </span>
                    {selectedItem?.whenDue &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenDue}
                            warnDays={warnDays.safetyEquipmentChecks[0]}
                            hasFault={selectedItem.hasFault}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            level={level}
        >

            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>

            <SeaGrid>
                <SeaGridCell label="Location" w="50">
                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                </SeaGridCell>
                <SeaGridCell label="Category" w="50">
                    {formatValue(renderCategoryName(selectedItem?.categoryId, vesselSafetyCheckCategories))}
                </SeaGridCell>
                <SeaGridCell label="Critical Equipment" w="50" help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}>
                    {formatValue(vesselSafetyItems?.byId[selectedItem?.itemId]?.isCritical ? 'Yes' : 'No')}
                </SeaGridCell>
                <SeaGridCell label="Interval" w="50">
                    {formatInterval(selectedItem?.interval)}
                </SeaGridCell>
                <SeaGridCell label="Next Check" w="50">
                    {formatDate(selectedItem?.whenDue)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Task">
                    {formatValue(formatTextAreaText(selectedItem?.description))}
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="safetyEquipmentChecks"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Safety Check</SeaButton>
                    <RequirePermissions
                        role="safetyEquipmentChecks"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'safetyCheckItems',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'safetyCheckItems');
                                        setShowModal(false);
                                    },
                                    'item',
                                    renderCategoryName(selectedItem?.itemId, vesselSafetyItems),
                                    vesselId ? [vesselId] : []
                                );
                            }}
                        >
                            Delete Safety Check
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            <div className="end-info-line"></div>

            <div className="columns wrap">
                <h2>Safety Check History</h2>
                <RequirePermissions
                    role="safetyEquipmentChecks"
                    level={permissionLevels.EDIT}
                >
                    <div className="right">
                        <SeaButton zone="white" onClick={(e) => onCompleteItem(undefined)}><SeaIcon icon="tick" slot="start" />Complete Safety Check</SeaButton>
                    </div>
                </RequirePermissions>
            </div>

            <SeaNoData
                dataName="item"
                isHistory={true}
                isLoading={!completedSafetyCheckItems}
                hasNoData={completedSafetyCheckItems && completedSafetyCheckItems.length === 0}
            />

            <div className={`safety-checks-history ${(completedSafetyCheckItems?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>By</div>
                    <div>Notes</div>
                    <div></div>
                </div>
                {mapArrayWithLimit(completedSafetyCheckItems, (item: SafetyCheckCompleted) => {
                    return (
                        <div
                            key={item.id} 
                            className={`sea-card sea-row-history ${item.shouldReportFault && 'fault-reported'} ${canEditItem && 'clickable'}`}
                            onClick={(e) => canEditItem && onCompleteItem(item)}
                        >
                            <div>{formatDate(item.whenCompleted)}</div>
                            <div className="truncate">{renderFullNameForUserId(item.completedBy)}</div>
                            <div className="truncate">{item.shouldReportFault ? 'FAULT REPORTED' : formatValue(formatTextAreaText(item.notes))}</div>
                            <div><SeaFileImage files={item.files} size="tiny"/></div>
                        </div>
                    )
                })}
                {limitTriggerElement}
            </div>
            {showModal &&
                <>
                    <EditSafetyCheck
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={level+1}
                    />
                    <CompleteSafetyCheck
                        showModal={showCompleteItemModal}
                        setShowModal={setShowCompleteItemModal}
                        selectedItem={selectedItem}
                        setShowParentModal={setShowModal}
                        historyItemToUpdate={historyItemToUpdate}
                        completedSafetyCheckItems={completedSafetyCheckItems}
                        level={level+1}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewSafetyCheck;
