import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { sharedState } from "../../shared-state";
import { useEffect, useState } from "react";
import { getDayOffsetMillis, warnDays } from "../../../lib/util";
import { VesselCertificate } from "../../VesselDocuments/vesselCertificates";
import { registerFiles } from "../../../lib/fileSync";

const makeDocuments = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        registerFiles(doc.data().files);
        return {
            id: doc.id,
            ...doc.data()
        } as VesselCertificate;
    });
};
const sortDocuments = (all: VesselCertificate[]) => {
    all.sort((a, b) => {
        return (a.whenExpires ?? 0) - (b.whenExpires ?? 0);
    });
};

export const useReportingVesselCertificates = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedStatuses: string[] | undefined
) => {
    const todayMillis = sharedState.todayMillis.use();
    const [reportingVesselCertificates, setReportingVesselCertificates] = useState<VesselCertificate[]>();

    useEffect(() => {
        if ((selectedStatuses?.length || 0) === 0 || (selectedVesselIds.length || 0) === 0) {
            // Nothing to load
            setReportingVesselCertificates([]);
            return;
        }
        setReportingVesselCertificates(undefined);
        if (isActive && selectedVesselIds?.length && selectedStatuses) {
            const includeOverdue = selectedStatuses.indexOf("overdue") !== -1;
            const includeUpcoming = selectedStatuses.indexOf("upcoming") !== -1;
            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            const baseConstraints = [where("state", "==", "active")];
            if (includeOverdue && includeUpcoming) {
                baseConstraints.push(where("whenExpires", "<", getDayOffsetMillis(warnDays.vesselCertificates[0])));
            } else if (includeOverdue) {
                // Include overdue only
                baseConstraints.push(where("whenExpires", "<", todayMillis));
            } else {
                // Include upcoming only
                baseConstraints.push(where("whenExpires", "<", getDayOffsetMillis(warnDays.vesselCertificates[0])), where("whenExpires", ">=", todayMillis));
            }

            return setupArrayQueryListener(
                "vesselCertificates", // what
                collection(firestore, "vesselCertificates"),
                baseConstraints,
                "vesselId",
                "in",
                selectedVesselIds,
                [orderBy("whenExpires", "asc")],
                (docs: QueryDocumentSnapshot<DocumentData>[], isCombined: boolean) => {
                    // processDocs
                    let all = makeDocuments(docs);
                    if (isCombined) {
                        sortDocuments(all);
                    }
                    setReportingVesselCertificates(all);
                }
            );
        }
    }, [isActive, todayMillis, selectedVesselIds, selectedStatuses]);

    return reportingVesselCertificates;
};
