import React, { useEffect, useState } from 'react';
import { formatDate, formatValue } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaPieGraph from '../../../../components/reporting/SeaPieGraph/SeaPieGraph';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import { incidentWhoInvolvedTypes } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import IncidentPersonnelInvolvedReportPdf from '../../../../pdfs/Reporting/HealthSafety/IncidentPersonnelInvolvedReportPdf';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import './ViewPersonnelInvolvedReport.css';

interface ViewPersonnelInvolvedReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
    vesselName?: string,
}

const ViewPersonnelInvolvedReport: React.FC<ViewPersonnelInvolvedReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
    vesselName,
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const { selectedVesselId } = useReportSettings();

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentTypesReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={onGeneratePdf}
        >
            <SeaReportSettings mode="modal" />

            <SeaPieGraph
                mode="modal"
                data={graphData}
                visible={showModal}
                showAllLabels={true}
            />

            <div className="reporting-data">
                <SeaNoData
                    dataName="incidents"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`incidents by-personnel ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Personnel</div>
                        <div>Name</div>
                        <div>Vessel</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident: Incident) => {
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDate(incident.whenEvent)}
                                </div>
                                <div className="truncate">
                                    {incident.whoInvolvedTypes?.map((type: string) => incidentWhoInvolvedTypes[type]).join(', ')}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
            {showModal &&
                <IncidentPersonnelInvolvedReportPdf
                    generatingPdf={generatingPdf}
                    setGeneratingPdf={setGeneratingPdf}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredIncidents={filteredIncidents}
                    vesselName={vesselName}
                />
            }
        </SeaModal>
    );
};

export default ViewPersonnelInvolvedReport;
