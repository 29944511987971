import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatDate, formatValue, formatInterval, whenDueToClassName, warnDays } from '../../../lib/util';
import { permissionLevels } from '../../../lib/permissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { VesselDocument } from '../../../shared-state/VesselDocuments/vesselDocuments';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditVesselDocument from '../../../modals/VesselDocuments/VesselDocuments/EditVesselDocument/EditVesselDocument';
import ViewVesselDocument from '../../../modals/VesselDocuments/VesselDocuments/ViewVesselDocument/ViewVesselDocument';
import EditVesselDocumentSettings from '../../../modals/VesselDocuments/VesselDocuments/EditVesselDocumentSettings/EditVesselDocumentSettings';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import './VesselDocumentsPage.css';

interface VesselDocumentsPageProps {
    visible: boolean
}

const VesselDocumentsPage: React.FC<VesselDocumentsPageProps> = ({visible}) => {
    const vessel = sharedState.vessel.use(visible ? 1 : 101);
    const vesselDocuments = sharedState.vesselDocuments.use(visible ? 1 : 101);
    const vesselDocumentCategories = sharedState.vesselDocumentCategories.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<VesselDocument>();
    const [showEditVesselDocumentSettings, setShowEditVesselDocumentSettings] = useState(false);
    useEffect(() => {
        if (visible) {
            logPageView('VesselDocuments/VesselDocuments');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && vesselDocuments?.byId[selectedItem.id]) { 
            setSelectedItem(vesselDocuments.byId[selectedItem.id])
        };
    }, [vesselDocuments, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: VesselDocument) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    const onSettings = () => {
        setShowEditVesselDocumentSettings(true);
    };

    return (
        <RequirePermissions
            role="vesselDocuments"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="vessel-documents page-head">
                <div><h2>{vessel?.isShoreFacility ? 'Documents' : 'Vessel Documents'}</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="vesselDocuments"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                        <div className="spacer"></div>
                        <SeaButton zone="grey" shape="circle" onClick={(e) => onSettings()}>
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            <SeaNoData
                dataName={vessel?.isShoreFacility ? 'documents' : 'vessel documents'}
                isLoading={!vesselDocuments}
                hasNoData={vesselDocuments && vesselDocuments.all.length === 0}
                isUsingFilter={false}
            />

            <div className={`vessel-documents has-thumbs has-status ${((vesselDocuments?.all?.length || 0) > 0) ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Name</div>
                    <div>Expiry</div>
                    <div>Interval</div>
                    <div>Status</div>
                    <div>Reminder</div>
                </div>
                <Profiler id="documents.vesselDocuments" onRender={onProfilerRender}>
                {vesselDocuments && vesselDocumentCategories?.ids?.map((categoryId: string) => {
                    if (isLimitReached() || vesselDocuments.byCategoryId[categoryId] === undefined) {
                        return undefined;
                    }
                    return (
                        <React.Fragment key={categoryId}>
                            <div className="category-heading">{renderCategoryName(categoryId, vesselDocumentCategories)}</div>
                            {/* All documents */}
                            {mapArrayWithLimit(vesselDocuments?.byCategoryId[categoryId], (item: VesselDocument) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={`sea-card sea-row ${item.type === 'renewable' ? whenDueToClassName(item.whenExpires, warnDays.vesselDocuments[0]) : 'non-expiring'}`}
                                        onClick={(e) => onViewItem(item)}
                                    >
                                        <div>
                                            <SeaFileImage
                                                files={item.files}
                                                sfdoc={item.sfdoc}
                                                size="tiny"
                                            />
                                        </div>
                                        <div className="bold truncate-2">{item.title}</div>
                                        {
                                            (item.type === 'renewable') &&
                                            <>
                                                <div className="truncate-2">{formatValue(formatDate(item.whenExpires))}</div>
                                                <div className="truncate-2">{formatValue(formatInterval(item.interval))}</div>
                                                <div><SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.vesselDocuments[0]}/></div>
                                                <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                            </>
                                        }
                                    </div>
                                );
                            })}
                            </React.Fragment>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    {vesselDocumentCategories && <EditVesselDocument
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        vesselDocumentCategories={vesselDocumentCategories}
                    />}
                    <EditVesselDocumentSettings
                        showModal={showEditVesselDocumentSettings} 
                        setShowModal={setShowEditVesselDocumentSettings}
                        vesselDocumentCategories={vesselDocumentCategories}
                    />
                    <ViewVesselDocument
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                        vesselDocumentCategories={vesselDocumentCategories}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default VesselDocumentsPage;
