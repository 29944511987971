import React, { useState, useEffect } from 'react';
import { IonSelectOption, IonSpinner } from '@ionic/react';
import { fromByteArray } from 'base64-js';
import { RichTextState, loadSfdoc, makeSfdocSeaFile } from '../../lib/richText';
import { hasArrayChanged } from '../../lib/util';
import { sharedState } from '../../shared-state/shared-state';
import { CompanyDocument, SFDoc } from '../../shared-state/CompanyDocuments/companyDocuments';
import { VesselDocument } from '../../shared-state/VesselDocuments/vesselDocuments';
import { SOP } from '../../shared-state/VesselDocuments/vesselSOPS';
import { makeSeaFiles, SeaFile, seaFilesToValue, sfdocToValue } from '../../lib/files';
import EditRichTextModal from '../../modals/Utilities/EditRichTextModal/EditRichTextModal';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaFileUpload from '../SeaFileUpload/SeaFileUpload';
import SeaButton from '../SeaButton/SeaButton';
import './SeaEditDocumentation.css';

export interface SeaDocumentation {
    type: 'sfdoc' | 'files',
    files: SeaFile[],
    sfdoc: Partial<SFDoc>,
    newSfdocFile?: SeaFile
}

const defaultContent = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Section 1","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"sea-section","version":1,"tag":"h1"},{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
const defaultJson = JSON.parse(defaultContent);

export const makeSeaDocumentation = (data: CompanyDocument | VesselDocument | SOP | undefined) => {
    if (data?.files && data.files.length > 0) {
        return {
            type: 'files',
            files: makeSeaFiles(data.files),
            sfdoc: {}
        } as SeaDocumentation;
    }
    return {
        type: 'sfdoc',
        files: [],
        sfdoc: (data?.sfdoc) ? data.sfdoc : {}
    } as SeaDocumentation;
};

export const hasSeaDocumentationChanged = (documentation: SeaDocumentation, original: SeaDocumentation): boolean => {
    if (documentation.type !== original.type) {
        return true;
    }
    if (documentation.type === 'files') {
        return hasArrayChanged(documentation.files, original.files);
    } else {
        if (documentation.newSfdocFile) {
            return true;
        }
    }
    return false;
};

// If the user is about to lose original files or controlled documentation, confirm that this is OK with them
export const confirmAnyDocumentationLoss = (
    documentation: SeaDocumentation,
    original: SeaDocumentation,
    confirmAction: (header: string, confirmText: string, cancelText: string, subHeader?: string, message?: string) => Promise<any>
): Promise<void> => {
    if (documentation.type === 'files' && original?.sfdoc && Object.keys(original.sfdoc).length > 0) {
        return confirmAction(
            'You are about to lose your controlled document because you\'re switching to use external files instead! Are you sure you want to continue?',
            'Yes, save changes',
            'Cancel'
        );
    } else if (documentation.type === 'sfdoc' && original?.files && original.files.length > 0) {
        return confirmAction(
            `You are about to lose your external file${original.files.length > 1 ? 's' : ''} because you're switching to use controlled documentation instead. Are you sure you want to continue?`,
            'Yes, save changes',
            'Cancel'
        );
    }
    return Promise.resolve();
};

// Returns an array of SeaFile for use with uploadFiles
export const getFilesToUploadForSeaDocumentation = (documentation: SeaDocumentation): SeaFile[] => {
    if (documentation.type === 'files') {
        return documentation.files ? documentation.files : [];
    } else if (documentation.newSfdocFile) {
        return [documentation.newSfdocFile];
    } else { // Nothing to upload
        return [];
    }
};

// Create data value for files. Note: Must be called after uploadFiles!
export const seaDocumentationToFilesValue = (documentation: SeaDocumentation): string[] | undefined => {
    if (documentation.type === 'files') {
        return seaFilesToValue(documentation.files);
    }
    return undefined;
};

// Create data value for sfdoc. Note: Must be called after uploadFiles!
export const seaDocumentationToSfdocValue = (documentation: SeaDocumentation): any | undefined => {
    if (documentation.type === 'sfdoc') {
        const sfdoc: SFDoc | {[key: number]: string} = documentation.sfdoc ? documentation.sfdoc : {};
        if (documentation.newSfdocFile) {
            // We need to create a new version
            const sfdocValue = sfdocToValue(documentation.newSfdocFile);
            if (sfdocValue !== undefined) {
                sfdoc[Date.now()] = sfdocValue;
            }
        }
        return sfdoc;
    }
    return undefined;
};


interface SeaEditDocumentationProps {
    label?: string,
    title: string,
    documentation: SeaDocumentation,
    setDocumentation: React.Dispatch<React.SetStateAction<SeaDocumentation>>,
    collection: string
}

const SeaEditDocumentation: React.FC<SeaEditDocumentationProps> = ({
    label = 'Documentation',
    title,
    documentation,
    setDocumentation,
    collection
}) => {
    const userId = sharedState.userId.use();
    const [sfdocJson, setSfdocJson] = useState<any>(defaultJson);
    const [richTextState, setRichTextState] = useState<RichTextState>({
        versions: undefined,
        loadedVersion: undefined,
        loadedJson: undefined,
        loading: false,
        downloading: false,
        message: undefined
    });
    const [showEditorModal, setShowEditorModal] = useState(false);

    useEffect(() => {
        if (documentation?.sfdoc) {
            return loadSfdoc(
                documentation?.sfdoc as SFDoc,
                setRichTextState,
                () => {
                    return defaultContent;
                }
            );
        } else {
            setSfdocJson(defaultJson);
        }
    }, [documentation?.sfdoc]);

    useEffect(() => {
        if (richTextState?.loadedJson) {
            setSfdocJson(richTextState.loadedJson);
        }
    }, [richTextState?.loadedJson]);

    const onSaveChanges = (json: any): Promise<boolean> => {
        setSfdocJson(json);
        setDocumentation((current) => {
            const encoder = new TextEncoder();
            const utf8Bytes = encoder.encode(JSON.stringify(json));
            const base64 = fromByteArray(utf8Bytes);
            return {
                ...current,
                type: 'sfdoc',
                newSfdocFile: makeSfdocSeaFile(
                    base64,
                    collection,
                    title,
                    userId as string
                )
            };
        });
        return Promise.resolve(true);
    };

    const onEditSfDoc = (e: React.MouseEvent) => {
        setShowEditorModal(true);
    };

    return <>
        <div style={{ maxWidth: '200px', paddingBottom: '4px' }}>
            <SeaSelect
                label="Documentation"
                name="richTextOrFiles"
                value={documentation.type}
                onchange={(e) => {
                    setDocumentation((current) => {
                        return {
                            ...current,
                            type: e.detail.value
                        } as SeaDocumentation;
                    });
                }}
            >
                <IonSelectOption value="sfdoc">Controlled</IonSelectOption>
                <IonSelectOption value="files">External File(s)</IonSelectOption>
            </SeaSelect>
        </div>
        {(documentation.type === 'sfdoc') &&
            <div className="columns">
                <div>
                    <SeaButton
                        onClick={(e: React.MouseEvent) => onEditSfDoc(e)}
                        disabled={(richTextState.loading || richTextState.message) ? true : false}
                    >
                        &nbsp;
                        <SeaIcon slot="start" icon="edit" />
                        Edit Controlled Document
                        &nbsp;
                    </SeaButton>
                </div>
                <div style={{ alignSelf: 'center', paddingLeft: '8px' }}>
                    {richTextState.loading &&
                        <IonSpinner style={{ opacity: 0.25 }}/>
                    }
                    {richTextState.message}
                </div>
            </div>
        }
        {(documentation.type === 'files') &&
            <SeaFileUpload
                files={documentation.files}
                setFiles={(files: SeaFile[]) => {
                    setDocumentation((current) => {
                        return {
                            ...current,
                            files: files
                        } as SeaDocumentation;
                    });
                }}
                collection="SOPs"
                field="files"
            />
        }
        <EditRichTextModal
            showModal={showEditorModal}
            setShowModal={setShowEditorModal}
            title={title ? title : label}
            initialDocumentJson={sfdocJson}
            onSaveChanges={onSaveChanges}
        />
    </>
};

export default SeaEditDocumentation;
