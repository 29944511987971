import * as React from "react";
function SvgCompanyDocs(props) {
  return (
    <svg
      width={19}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M17.948 20.496V7.48c0-.617-.25-1.21-.696-1.647l-3.36-3.295a2.4 2.4 0 00-1.679-.683H3.694c-1.313 0-2.376 1.043-2.376 2.33v16.311c0 1.287 1.063 2.33 2.376 2.33h11.087"
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.948 7.68h-4.751a1.177 1.177 0 01-1.188-1.164v-4.66"
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        d="M5.052 11.466h9.298M5.052 15.328h9.298M5.052 7.604h3.443"
      />
    </svg>
  );
}
export default SvgCompanyDocs;
