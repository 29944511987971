import * as React from "react";
function SvgVesselDocs(props) {
  return (
    <svg
      width={21}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.887 9.896V6.37c0-.59-.235-1.155-.652-1.572l-3.144-3.145A2.225 2.225 0 0011.518 1H3.542a2.223 2.223 0 00-2.224 2.224v15.569c0 1.229.996 2.224 2.224 2.224h4.449"
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.887 6.56H12.44a1.112 1.112 0 01-1.112-1.112V1"
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.75}
        strokeLinecap="round"
        d="M4.87 10.134h8.592M4.87 13.82h5.167M4.87 6.446h3.111"
      />
      <g clipPath="url(#VesselDocs_svg__clip0_5502_202)">
        <path
          d="M15.137 15.391a1.24 1.24 0 100-2.478 1.24 1.24 0 000 2.478zm0 0v5.783m0 0a4.13 4.13 0 01-4.13-4.13h1.238m2.892 4.13a4.13 4.13 0 004.13-4.13h-1.239"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="VesselDocs_svg__clip0_5502_202">
          <path
            fill="#fff"
            transform="translate(10.18 12.087)"
            d="M0 0h9.913v9.913H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgVesselDocs;
