import React, { useCallback, useEffect, useMemo } from 'react';
import { closeModalBox, openModalBox } from '../../../managers/ModalBoxManager/ModalBoxManager';
import SeaIcon from '../../SeaIcon/SeaIcon';
import './SeaSelectRichTextHeading.css';

interface SeaSelectRichTextHeadingProps {
    value: string | undefined,
    setValue: (value: string | undefined) => void,
    sections?: any,
    scrollToTop?: () => void
}

const SeaSelectRichTextHeading: React.FC<SeaSelectRichTextHeadingProps> = ({
    value,
    setValue,
    sections,
    scrollToTop
}) => {

    const selectedSection = useMemo(() => {
        if (value && sections) {
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].key === value) {
                    return sections[i];
                }
            }
        }
        return undefined;
    }, [value, sections]);

    useEffect(() => { // This looks dodgy!
        setValue(value);
    }, [value]);

    const openPopover = useCallback(() => {
        const renderContent = () => {
            return (<>
                {/* <div className="sea-select-multi-title">
                    Sections
                </div> */}
                <div
                    className="sea-select-multi-box"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div style={{ height: '4px' }}></div>
                    {scrollToTop &&
                        <div
                            className={`lex-section-button h1 pushy no-select`}
                            onClick={(e) => {
                                //setValue(section.key);
                                scrollToTop();
                                closeModalBox();
                            }}
                        >
                            <SeaIcon
                                //slot="icon-only"
                                icon="moveUp"
                                marginTop={0}
                                forceFontSize="16px"
                            />
                            <span style={{ verticalAlign: 'top', position: 'relative', left: '4px', top: '0px' }}>Top</span>
                        </div>
                    }
                    {sections?.map((section: any) => {
                        return (
                            <div
                                key={section.key}
                                className={`lex-section-button ${section.tag} pushy no-select`}
                                onClick={(e) => {
                                    setValue(section.key);
                                    closeModalBox();
                                }}
                            >
                                {section.title}
                            </div>
                        );
                    })}
                    <div style={{ height: '8px' }}></div>
                </div>
            </>);
        };
        openModalBox(renderContent(), 'rich-text-sections');
    }, [sections, setValue, scrollToTop]);

    return <>
        <div
            className="sea-input sea-select multi rich-text-headings"
            onClick={openPopover}
        >
            <div className={`select-text no-select ${selectedSection ? selectedSection.tag : ''}`}>
                {selectedSection ? selectedSection.title : ''}
            </div>
            <div className="select-icon">
                <div className="select-icon-inner"></div>
            </div>
        </div>
    </>
};

export default SeaSelectRichTextHeading;
