import { SharedStateConfig, sharedState } from "../shared-state";


export const enableSwipe = (delayMillis = 0) => {
    if (delayMillis > 0) {
        setTimeout(() => {
            sharedState.isSwipeEnabled.set(true);
        }, delayMillis);
    }
    sharedState.isSwipeEnabled.set(true);
};

export const disableSwipe = (delayMillis = 0) => {
    if (delayMillis > 0) {
        setTimeout(() => {
            sharedState.isSwipeEnabled.set(false);
        }, delayMillis);
    }
    sharedState.isSwipeEnabled.set(false);
};

export const isSwipeEnabledConfig: SharedStateConfig<boolean> = {
    isAlwaysActive: true,
    default: true,
    notes: 'Sometimes you don\'t want swipe enabled - like when you\'re in a modal scrolling through tabs.'
};
