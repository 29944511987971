import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { registerFiles, registerRichText } from '../../lib/fileSync';
import { getDayOffsetMillis, warnDays } from '../../lib/util';
import { SFDoc } from '../CompanyDocuments/companyDocuments';

export interface VesselDocument extends CreateableDocument, UpdateableDocument {
    categoryId: string;
    deletedBy?: string;
    emailReminder?: string;
    files?: string[];
    interval?: string;
    sfdoc?: SFDoc;
    state: string;
    title: string;
    type: string;
    vesselId: string;
    whenDeleted?: number;
    whenExpires?: number;
    whenToRemind?: number;
}

export type VesselDocumentsData = { 
    all: VesselDocument[],
    prioritised: VesselDocument[],
    byId: {
        [id: string]: VesselDocument
    },
    byCategoryId: {
        [id: string]: VesselDocument[]
    }
};

export const vesselDocumentsConfig: SharedStateConfig<VesselDocumentsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'todayMillis'], // Depends on todayMillis because we're doing day offset calculations
    countLiveDocs: () => sharedState.vesselDocuments.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('vesselDocuments')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselDocuments'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const documents = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as VesselDocument;
                    });

                    // all is categorised by renewable and nonExpiring
                    const all = [] as VesselDocument[];
                    const byId = {} as {
                        [id: string]: VesselDocument;
                    };
                    documents.forEach((document) => {
                        registerFiles(document.files);
                        registerRichText(document.sfdoc);
                        byId[document.id] = document;
                        all.push(document);
                    });
                    let prioritised = [...documents] as VesselDocument[];
                    prioritised.sort((a, b) => {
                        return (
                            (a.type === 'renewable'
                                ? a.whenExpires ?? Number.MAX_SAFE_INTEGER
                                : Number.MAX_SAFE_INTEGER) -
                            (b.type === 'renewable'
                                ? b.whenExpires ?? Number.MAX_SAFE_INTEGER
                                : Number.MAX_SAFE_INTEGER)
                        );
                    });

                    // prioritised should only contain whenExpires up to warnDays.vesselDocuments days in the future
                    // (and should not contain and nonExpiring either)
                    const maxWhenExpires = getDayOffsetMillis(
                        warnDays.vesselDocuments[warnDays.vesselDocuments.length - 1]
                    );
                    for (let i = 0; i < prioritised.length; i++) {
                        if (
                            prioritised[i].type === 'nonExpiring' ||
                            (prioritised[i].whenExpires && (prioritised[i].whenExpires as number) >= maxWhenExpires)
                        ) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }

                    const byCategoryId = {} as {
                        [id: string]: VesselDocument[]
                    };

                    all.forEach((vesselDocument) => {
                        byId[vesselDocument.id] = vesselDocument;
                        if (byCategoryId[vesselDocument.categoryId] === undefined) {
                            byCategoryId[vesselDocument.categoryId] = [];
                        }
                        byCategoryId[vesselDocument.categoryId].push(vesselDocument);
                    });

                    set({
                        all,
                        prioritised,
                        byId,
                        byCategoryId
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access vesselDocuments for vessel ${vesselId}`,
                        error
                    );
                }
            );
        } else {
            done();
        }
    }
};