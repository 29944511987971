import * as React from "react";

function SvgTick(props) {
  return (
    <svg
      width={19}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.992 13.887a.934.934 0 001.3 0L18.63 3.55a.934.934 0 000-1.301L17.363.984a.891.891 0 00-1.265 0L7.66 9.422 3.687 5.484a.891.891 0 00-1.265 0L1.156 6.75a.934.934 0 000 1.3l5.836 5.837z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTick;
