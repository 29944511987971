import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { arrayUnion, collection, doc, increment, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, formatSeaDatetime, preventMultiTap, toFloat, deepClone, cleanupStringArray, hasArrayChanged, addInterval, formatSeaDate } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { makeCategoryId } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaEquipment from '../../../../components/SeaEquipment/SeaEquipment';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaSparePartsAdjustments from '../../../../components/SeaSparePartsAdjustments/SeaSparePartsAdjustments';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaTagsInput from '../../../../components/SeaTagsInput/SeaTagsInput';

interface EditMaintenanceHistoryProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: MaintenanceTaskCompleted,
    level?: number
}

const EditMaintenanceHistory: React.FC<EditMaintenanceHistoryProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    const maintenanceTasksCompleted = sharedState.maintenanceTasksCompleted.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [equipmentId, setEquipmentId] = useState<string>();
    const [locationId, setLocationId] = useState<string>();
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [maintenanceTags, setMaintenanceTags] = useState<string[]>([]);
    // const [jobTags, setJobTags] = useState<string[]>([]);
    const [sparePartsStock, setSparePartsStock] = useState<{ [key: string]: {used?: number, added?: number}}>({});
    const initialSparePartsStock:{ [key: string]: {used?: number, added?: number}} = useMemo(() => {
        if (itemToUpdate?.spareParts) {
            return deepClone(itemToUpdate.spareParts);
        } else {
            return {};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToUpdate?.id, showModal])

    useEffect(() => {
        if (itemToUpdate?.spareParts) {
            setShowMoreInfo(true);
        } else {
            setShowMoreInfo(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToUpdate?.id]);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
             return {
                task: (itemToUpdate?.task) ? ''+itemToUpdate.task : '',
                description: (itemToUpdate?.description) ? ''+itemToUpdate.description : '',
                whenCompleted: (itemToUpdate?.whenCompleted) ? formatSeaDatetime(itemToUpdate.whenCompleted) : '',
            };
        } else {
            return {
                whenCompleted: formatSeaDatetime(),
                task: '',
                description: ''
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToUpdate?.id, showModal]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setHasSubmitted(false);
        setValues(initialValues);
        setMaintenanceTags((itemToUpdate?.maintenanceTags) ? itemToUpdate.maintenanceTags : []);
        // setJobTags((itemToUpdate?.jobTags) ? itemToUpdate.jobTags : []);
        setEquipmentId((itemToUpdate?.equipmentId) ? itemToUpdate.equipmentId : '');
        setLocationId((itemToUpdate?.locationId) ? itemToUpdate.locationId : '');
        setSparePartsStock(itemToUpdate?.spareParts ? {...itemToUpdate?.spareParts} : {});
        setTimeout(() => {
            // Prevent equipment.locationId overriding itemToUpdate.locationId
            setLocationId((itemToUpdate?.locationId) ? itemToUpdate.locationId : '');
        }, 100);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const onClosed = () => {
        setEquipmentId('');
        setSparePartsStock({});
    };

    const toggleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };
    

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            task: Yup.string().max(5000).required(),
            description: Yup.string().max(5000),
            whenCompleted: Yup.date().required().min(...notTooOld),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('maintenanceHistory')) { return; }
            if (!vesselId) {
                alertMessage('Vessel not found');
                return;
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const action = traceAction('maintenanceTasksCompleted') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
               // Update spare parts stock
                for (const [sparePartId, adjustment] of Object.entries(sparePartsStock)) {
                    const initialAdjustment = initialSparePartsStock[sparePartId] || {used: 0, added: 0};
                    let added = toFloat(adjustment.added) - toFloat(initialAdjustment.added);
                    let used = toFloat(adjustment.used) - toFloat(initialAdjustment.used);
                    let adjustmentFloat = added - used;
                    if (adjustment) {
                        batch.set(
                            doc(firestore, 'spareParts', sparePartId),
                            {
                                quantity: increment(adjustmentFloat),
                                updatedVia: 'completeMaintenanceSchedule',
                                whenUpdated: action.whenAction,
                                updatedBy: userId,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );
                        onCollectionUpdated(batch, 'spareParts');
                    }
                }
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'maintenanceTasksCompleted', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            task: data.task,
                            description: (data.description ? data.description : deleteValue),
                            maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : deleteValue,
                            // jobTags: jobTags ? cleanupStringArray(jobTags) : deleteValue,
                            whenCompleted: data.whenCompleted ? toMillis(data.whenCompleted) : deleteValue,
                            equipmentId: equipmentId ? equipmentId : deleteValue,
                            locationId: makeCategoryId(
                                locationId,
                                vesselLocations,
                                deleteValue,
                                batch,
                                'vesselLocations',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            spareParts: sparePartsStock ? sparePartsStock : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    // Update scheduled maintenance task last service date
                    if (itemToUpdate?.maintenanceTaskId) {
                        const scheduledTask = scheduledMaintenanceTasks?.byId?.[itemToUpdate.maintenanceTaskId];
                        let whenLastService = data.whenCompleted ? toMillis(data.whenCompleted) as number : 0;
                        const completedMaintenanceTasks = maintenanceTasksCompleted?.byMaintenanceTaskId?.[itemToUpdate.maintenanceTaskId];
                        let engineHoursLastService = itemToUpdate.engineHours;
                        const useWeekMonth = (scheduledTask?.intervalType === 'weekMonth' || scheduledTask?.intervalType === 'weekMonthAndHours');
                        const useEngineHours = (scheduledTask?.intervalType === 'engineHours' || scheduledTask?.intervalType === 'weekMonthAndHours');
                    
                        if (completedMaintenanceTasks?.length) {
                            // Find the most recent completed maintenance task
                            for (let task of completedMaintenanceTasks) {
                                // Ignore tasks without a whenCompleted date or the current task
                                if (!task.whenCompleted || task.id === itemToUpdate.id) continue;
                                // Update the latest whenCompleted and engine hours if applicable
                                if (task.whenCompleted > whenLastService) {
                                    whenLastService = task.whenCompleted;
                                    engineHoursLastService = task.engineHours; // Assuming each task has engine hours recorded
                                }
                            }
                        }
                        if (scheduledTask?.id && scheduledTask?.intervalWeekMonth) {
                            batch.set(
                                doc(firestore, 'scheduledMaintenanceTasks', scheduledTask.id),
                                {
                                    whenLastService: whenLastService ? whenLastService : deleteValue,
                                    whenDue: useWeekMonth ? addInterval(toMillis(formatSeaDate(new Date(whenLastService))), scheduledTask?.intervalWeekMonth) : deleteValue,
                                    engineHoursDue: useEngineHours ? (Number(engineHoursLastService) + (scheduledTask?.intervalEngineHours || 0)) : deleteValue,
                                    whenUpdated: action.whenAction,
                                    updatedBy: userId,
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                            onCollectionUpdated(batch, 'scheduledMaintenanceTasks');
                        }
                    }
                    saveFileRefs(batch, files, 'maintenanceTasksCompleted', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'maintenanceTasksCompleted',
                        itemToUpdate.id,
                        data.task,
                        [itemToUpdate.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'maintenanceTasksCompleted'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        completedBy: userId,
                        task: data.task,
                        maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : deleteValue,
                        // jobTags: jobTags ? cleanupStringArray(jobTags) : deleteValue,
                        whenCompleted: data.whenCompleted ? toMillis(data.whenCompleted) : undefined,
                        equipmentId: equipmentId,
                        locationId: makeCategoryId(
                            locationId,
                            vesselLocations,
                            undefined,
                            batch,
                            'vesselLocations',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        addedBy: userId,
                        whenAdded: action.whenAction,
                        type: 'unscheduled',
                        spareParts: sparePartsStock ? sparePartsStock : undefined,
                        state: 'completed',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'maintenanceTasksCompleted', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'maintenanceTasksCompleted',
                        newRef.id,
                        data.task,
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'maintenanceTasksCompleted');

                if (maintenanceTags && vessel) {
                    cleanupStringArray(maintenanceTags)?.forEach((tag) => {
                        if (vessel.possibleMaintenanceTags === undefined || vessel.possibleMaintenanceTags.indexOf(tag) === -1) {
                            batch.set(
                                doc(firestore, 'vessels', vesselId),
                                { possibleMaintenanceTags: arrayUnion(tag) },
                                { merge: true }
                            );
                        }
                    });
                }

                // if (jobTags && vessel) {
                //     cleanupStringArray(jobTags)?.forEach((tag) => {
                //         if (vessel.possibleTags === undefined || vessel.possibleTags.indexOf(tag) === -1) {
                //             batch.set(
                //                 doc(firestore, 'vessels', vesselId),
                //                 { possibleTags: arrayUnion(tag) },
                //                 { merge: true }
                //             );
                //         }
                //     });
                // }
                
                action.data = {
                    data,
                    equipmentId,
                    locationId,
                    files: seaFilesToValue(files),
                    itemToUpdate,
                    maintenanceTags,
                    // jobTags
                };
                action.save(`${itemToUpdate ? 'Update' : 'Add'} completed maintenance task ${data?.task}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Completed Maintenance History files`, error.message, error, {
                        itemToUpdate,
                        files: seaFilesToValue(files),
                        data
                    });
                }
            });
        }
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues) ||
            equipmentId !== ((itemToUpdate?.equipmentId) ? itemToUpdate.equipmentId : '') ||
            locationId !== ((itemToUpdate?.locationId) ? itemToUpdate.locationId : '') ||
            haveFilesChanged(files, itemToUpdate?.files) ||
            hasArrayChanged(maintenanceTags, itemToUpdate?.maintenanceTags)
            // hasArrayChanged(jobTags, itemToUpdate?.jobTags);
    }, [values, initialValues, equipmentId, itemToUpdate?.equipmentId, itemToUpdate?.locationId, itemToUpdate?.files, itemToUpdate?.maintenanceTags, locationId, files, maintenanceTags]);

    const equipmentSpareParts = useMemo(() => 
        spareParts?.all?.filter((part) => {
            return equipmentId && part.equipmentIds?.includes(equipmentId);
        }
    ), [spareParts, equipmentId]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Completed Maintenance Task' : 'Add Completed Maintenance Task'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <SeaEquipment
                            equipmentId={equipmentId}
                            setEquipmentId={setEquipmentId}
                            initialLocationId={(itemToUpdate?.locationId) ? itemToUpdate.locationId : ''}
                            locationId={locationId}
                            setLocationId={setLocationId}
                            hasSubmitted={hasSubmitted}
                            required={false}
                        />
                        <IonCol size="6">
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTagsInput
                                label="Maintenance Tags"
                                tags={maintenanceTags}
                                setTags={setMaintenanceTags}
                                options={vessel?.possibleMaintenanceTags}
                                help={{text: 'Use tags such as "Intermediate survey", "Renewal survey", "External contractor", "Engineer" or "Deckhand"'}}
                            />
                        </IonCol>
                        {/* <IonCol size="6">
                            <SeaTagsInput
                                label="Job Tags"
                                tags={jobTags}
                                setTags={setJobTags}
                                options={vessel?.possibleTags}
                                help={{text: 'Use tags such as "Intermediate survey", "Renewal survey", "External contractor", "Engineer" or "Deckhand"'}}
                            />
                        </IonCol> */}
                        {itemToUpdate?.type === 'job' ?
                            (
                                <>
                                    <IonCol size="12">
                                        <SeaInput
                                            label="Job"
                                            name="task"
                                            value={values.task}
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            zone="white"
                                            inputmode="text"
                                            error={touched.task ? errors.task : ''}
                                        />
                                    </IonCol>
                                    <IonCol size="12">
                                        <SeaTextarea
                                            label="Description"
                                            name="description"
                                            value={values.description}
                                            onchange={handleChange}
                                            onblur={handleBlur}
                                            zone="white"
                                            inputmode="text"
                                            error={touched.description ? errors.description : ''}
                                        />
                                    </IonCol>
                                </>
                            ) : (
                                <IonCol size="12">
                                    <SeaTextarea
                                        label="Maintenance Task"
                                        name="task"
                                        value={values.task}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        inputmode="text"
                                        error={touched.task ? errors.task : ''}
                                    />
                                </IonCol>
                            )
                        }
                        {equipmentSpareParts?.length ?
                            <IonCol size="12" className={`expander ${showMoreInfo ? 'reduce' : ''}`}>
                                <div className="more-info-dropdown no-select" onClick={(e) => toggleShowMoreInfo()}>Spare Parts Used <SeaIcon icon={showMoreInfo ? 'moveUp' : 'moveDown'} /></div>
                            </IonCol>
                        : null}
                        {showMoreInfo ? <IonCol size="12">
                            <SeaSparePartsAdjustments
                                sparePartsStock={sparePartsStock}
                                setSparePartsStock={setSparePartsStock}
                                initialSparePartsStock={initialSparePartsStock}
                                spareParts={equipmentSpareParts}
                            />
                        </IonCol> : null}
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="maintenanceTasksCompleted"
                                field="files"
                            />
                        </IonCol>
                           
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Completed Task' : 'Save Completed Task'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditMaintenanceHistory;
