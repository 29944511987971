import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { canEdit, permissionLevels } from '../../../lib/permissions';
import { formatDate } from '../../../lib/util';
import { renderCategoryName } from '../../../lib/categories';
import { onProfilerRender } from '../../../lib/performance';
import { CustomForm } from '../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { CustomFormTemplate } from '../../../shared-state/CompanyDocuments/CustomForms/customFormTemplates';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import ViewCustomForm from '../../../modals/CompanyDocuments/CustomForms/ViewCustomForm/ViewCustomForm';
import EditCustomForm from '../../../modals/CompanyDocuments/CustomForms/EditCustomForm/EditCustomForm';
import EditCustomFormSettings from '../../../modals/CompanyDocuments/CustomForms/EditCustomFormSettings/EditCustomFormSettings';
import BrowseCustomFormTemplates from '../../../modals/CompanyDocuments/CustomForms/BrowseCustomFormTemplates/BrowseCustomFormTemplates';
import './CustomForms.css';

interface CustomFormsProps {
    visible: boolean
}

const CustomForms: React.FC<CustomFormsProps> = ({visible}) => {
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const customFormCategories = sharedState.customFormCategories.use(visible ? 1 : 101);
    const customForms = sharedState.customForms.use(visible ? 1 : 101);
    sharedState.customFormVersions.use(visible ? 2 : 0); // Prepare for modals to access
    sharedState.customFormTemplates.use(visible ? 3 : 0); // Prepare for modals to access
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [selectedCustomForm, setSelectedCustomForm] = useState<CustomForm>();
    const [templateToUse, setTemplateToUse] = useState<Partial<CustomFormTemplate>>();
    const [showViewCustomFormModal, setShowViewCustomFormModal] = useState(false);
    const [showEditCustomFormModal, setShowEditCustomFormModal] = useState(false);
    const [showEditCustomFormSettings, setShowEditCustomFormSettings] = useState(false);
    const [showBrowseCustomFormTemplates, setShowBrowseCustomFormTemplates] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView('CustomForms');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedCustomForm fresh
    useEffect(() => {
        if (showViewCustomFormModal && selectedCustomForm?.id && customForms?.byId[selectedCustomForm.id]) {
            setSelectedCustomForm(customForms.byId[selectedCustomForm.id])
        };
    }, [customForms, showViewCustomFormModal]); // eslint-disable-line react-hooks/exhaustive-deps

    const onViewCustomForm = (customForm: CustomForm) => {
        console.log(`Viewing customForm ${customForm.id}`);
        setSelectedCustomForm(customForm);
        setShowViewCustomFormModal(true);
    };

    const onSettings = () => {
        setShowEditCustomFormSettings(true);
    };

    const renderForVessels = (customForm: CustomForm) => {
        if (customForm.lastCompletedVesselIds && customForm.lastCompletedVesselIds.length > 0) {
            if (
                customForm.lastCompletedVesselIds.length === 1 &&
                vessels?.byId &&
                vessels.byId[customForm.lastCompletedVesselIds[0]]
            ) {
                return `, for ${vessels.byId[customForm.lastCompletedVesselIds[0]].name}`;
            }
            return `, for ${customForm.lastCompletedVesselIds.length} vessels`;
        }
        return '';
    };

    return (
        <>
            <RequirePermissions
                role="customForms"
                level={permissionLevels.VIEW}
                showDenial={true}
            >
                <div className="sea-custom-forms page-head">
                    <div><h2>Forms/Checklists</h2></div>
                    {canEdit('customForms') &&
                        <div className="actions">
                            <SeaButton zone="grey" onClick={(e) => setShowBrowseCustomFormTemplates(true)}>
                                Browse Templates
                            </SeaButton>
                            <div className="spacer"></div>
                            <SeaButton zone="grey" onClick={(e) => {
                                setSelectedCustomForm(undefined);
                                setShowEditCustomFormModal(true);
                            }}>
                                Create Form/Checklist
                            </SeaButton>
                            <div className="spacer"></div>
                            <SeaButton zone="grey" shape="circle" onClick={(e) => onSettings()}>
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </div>
                    }
                </div>

                <SeaNoData
                    dataName="forms/checklists"
                    isLoading={!customForms}
                    hasNoData={customForms && customForms.all.length === 0}
                />

                <div className={`sea-custom-forms ${(customForms && customForms.all.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row headings">
                        <div>Title</div>
                        <div>Last Completed</div>
                        <div>Linked To</div>
                    </div>
                    <Profiler id="customForms" onRender={onProfilerRender}>
                        {customForms && customFormCategories?.ids?.map((categoryId: string) => {
                            if (isLimitReached() || customForms.byCategoryId[categoryId] === undefined) {
                                return undefined;
                            }
                            return (
                                <React.Fragment key={categoryId}>
                                    <div className="category-heading">{renderCategoryName(categoryId, customFormCategories)}</div>
                                    {mapArrayWithLimit(customForms.byCategoryId[categoryId], (customForm : CustomForm) => {
                                        return (
                                            <div
                                                key={customForm.id}
                                                className={`sea-card sea-row`}
                                                onClick={(e) => onViewCustomForm(customForm)}
                                            >
                                                <div className="bold truncate">{customForm.title}</div>
                                                <div className="truncate">{
                                                    (
                                                        customForm.whenLastCompleted &&
                                                        customForm.lastCompletedBy
                                                    ) ? (
                                                        `${formatDate(customForm.whenLastCompleted)}, by ${renderFullNameForUserId(customForm.lastCompletedBy)}${renderForVessels(customForm)}`
                                                    ) : (
                                                        '-'
                                                    )
                                                }</div>
                                                <div className="truncate">
                                                    {(customForm.forVesselIds && customForm.forVesselIds.length > 0 && customForm.forVesselIds[0] !== 'none') ? (
                                                        customForm.forCrew ? 'Vessels & Personnel' : 'Vessels'
                                                    ) : (
                                                        customForm.forCrew ? 'Personnel' : ''
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                        {limitTriggerElement}
                    </Profiler>
                </div>

                <ViewCustomForm
                    showModal={showViewCustomFormModal} 
                    setShowModal={setShowViewCustomFormModal}
                    selectedCustomForm={selectedCustomForm}
                    setShowEditCustomFormModal={setShowEditCustomFormModal}
                />
            </RequirePermissions>
            {visible && canEdit('customForms') &&
                <>
                    <EditCustomForm
                        showModal={showEditCustomFormModal} 
                        setShowModal={setShowEditCustomFormModal}
                        itemToUpdate={selectedCustomForm}
                        templateToUse={templateToUse}
                        setTemplateToUse={setTemplateToUse}
                        level={2}
                    />
                    <EditCustomFormSettings
                        showModal={showEditCustomFormSettings} 
                        setShowModal={setShowEditCustomFormSettings}
                    />
                    <BrowseCustomFormTemplates
                        showModal={showBrowseCustomFormTemplates}
                        setShowModal={setShowBrowseCustomFormTemplates}
                        setShowEditCustomFormModal={setShowEditCustomFormModal}
                        setEditCustomFormItem={setSelectedCustomForm}
                        setTemplateToUse={setTemplateToUse}
                    />
                </>
            }
        </>
    );
};

export default CustomForms;
