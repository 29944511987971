import { RiskRegisterSettings } from "../../../shared-state/Core/licenseeSettings";

export const defaultRiskRegisterLicenseeSettings: RiskRegisterSettings = {
    version: 2,
    likelihoods: {
        rows: [1, 2, 3, 4, 5],
        L1: {
            name: 'Almost Certain',
            description: 'Common event - Weekly occurrence.'
        },
        L2: {
            name: 'Likely',
            description: 'Known to occur - Monthly event.'
        },
        L3: {
            name: 'Possible',
            description: 'Could occur, heard of it happening - Up to 3 three times a year'
        },
        L4: {
            name: 'Unlikely',
            description: 'Not likely to occur - Once a year'
        },
        L5: {
            name: 'Rare',
            description: 'Practically impossible - Unheard of happening'
        }
    },
    consequences: {
        columns: [1, 2, 3, 4, 5],
        C1: {
            name: 'Negligible',
            description: 'No injuries - Negligible financial loss - No adverse affect on operations - Negligible environmental damage'
        },
        C2: {
            name: 'Minor',
            description: 'First aid treatment for minor cuts/bruises/abrasions - Notable financial loss - Minor disruption to operations - Minor environmental damage'
        },
        C3: {
            name: 'Medium',
            description: 'Disabling injury - Significant financial loss - Significant disruption to operations - Significant environmental damage'
        },
        C4: {
            name: 'High',
            description: 'Fatality - Extensive financial loss - Major disruption to operations - Major environmental damage'
        },
        C5: {
            name: 'Catastrophic',
            description: 'Multiple fatalities - Loss of vessel/income - Reputation damaged - Extensive environmental damege'
        },
    },
    matrix: {
        '1-1': 'fdf054 Moderate',
        '1-2': 'fbbe43 High',
        '1-3': 'fb4322 Extreme',
        '1-4': 'fb4322 Extreme',
        '1-5': 'fb4322 Extreme',
        '2-1': 'fdf054 Moderate',
        '2-2': 'fbbe43 High',
        '2-3': 'fbbe43 High',
        '2-4': 'fb4322 Extreme',
        '2-5': 'fb4322 Extreme',
        '3-1': '92d051 Low',
        '3-2': 'fdf054 Moderate',
        '3-3': 'fbbe43 High',
        '3-4': 'fb4322 Extreme',
        '3-5': 'fb4322 Extreme',
        '4-1': '92d051 Low',
        '4-2': '92d051 Low',
        '4-3': 'fdf054 Moderate',
        '4-4': 'fbbe43 High',
        '4-5': 'fb4322 Extreme',
        '5-1': '92d051 Low',
        '5-2': '92d051 Low',
        '5-3': 'fdf054 Moderate',
        '5-4': 'fdf054 Moderate',
        '5-5': 'fbbe43 High'
    },
    evaluations: [
        {
            name: 'Low',
            color: '92d051',
            description: 'The process or activity in question continues with existing controls'
        },
        {
            name: 'Moderate',
            color: 'fdf054',
            description: 'Actions are to be taken to eliminate or minimise the risk'
        },
        {
            name: 'High',
            color: 'fbbe43',
            description: 'Actions are to be taken to eliminate or minimise the risk'
        },
        {
            name: 'Extreme',
            color: 'fb4322',
            description: 'The process, task or activity in question must not occur or must cease until actions are taken to eliminate the hazard or minimise the risk'
        }
    ],
    riskPrompts: [
        'Asphyxiation',
        'Barotraumas',
        'Burns',
        'Caught in or between',
        'Changes to the program',
        'Chemical / substance spill',
        'Crushing / trapping',
        'Cuts / abrasion',
        'Dehydration',
        'Dropping / falling objects',
        'Drowning',
        'Electrical shock',
        'Entanglement',
        'Equipment failure',
        'Explosive materials',
        'Exposure to radiation',
        'Eye injury',
        'Fall from height',
        'Fire / explosion',
        'Gases / fumes / vapours',
        'Handling / use of chemicals / substances',
        'Hazardous substances',
        'Inexperienced personnel',
        'Insufficient / incorrect material',
        'Manual handling injuries',
        'MOB',
        'New personnel / third party',
        'Noise / vibration',
        'Pinch points (hands & feet)',
        'Pollution of air, land, water-ways',
        'Poor lighting',
        'Repetitive strain injury',
        'Sea state',
        'Shifting loads',
        'Simultaneous operations',
        'Skin irritation',
        'Slips / trips / falls',
        'Sprain, strain injury',
        'Stored energy / pressure',
        'Struck against',
        'Struck by',
        'Temperature extremes',
        'Toxic gas / vapours',
        'Weather',
        'Welding / torch use',
        'Work at heights',
        'Work near water',
        'Work over the side',
        'Wrong equipment',
    ],
    controlPrompts: [
        'Absorbent material',
        'Airline whips checks',
        'All lines pressure tested',
        'Appropriate supervision',
        'Assistance (extra people)',
        'Barrier cream',
        'Barriers around / below worksite',
        'Chemical assessment (SDS)',
        'Communication / radios / signs',
        'Constantly assess changing conditions',
        'Contingency / emergency plan',
        'Correct hand / body posture',
        'Correct tools',
        'Correct lifting procedures',
        'Drive to sea conditions',
        'Emergency procedures',
        'Escape routes / safe access routes',
        'Fall arrestor / harness',
        'Fire extinguisher / fire watch',
        'Forced ventilation',
        'Gas monitoring',
        'Housekeeping',
        'Induction',
        'JSEA - Job safety and environmental analysis',
        'Mechanical lifting aids',
        'Mentoring / coaching',
        'MOB Procedure',
        'Operating manuals',
        'Permit to work (hot, entry, isolation)',
        'PFD',
        'PPE, appropriate',
        'Qualified and competent operators',
        'Review relevant procedures',
        'Review of manuals and documents',
        'Safe storage of tools',
        'Scaffolding (certified)',
        'Site / vessel / work areas induction',
        'Spotter, sentry, or observer',
        'Stowage and security of loose items',
        'Suitable / certified slings, shackles etc.',
        'Sunscreen',
        'SWI - Safe Work Instructions',
        'Tag lines',
        'Training, competency, experience',
        'Work instruction, job flow',
    ]
};
