import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, formatSeaDate, subtractInterval, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CrewCertificate } from '../../../../shared-state/Crew/crewCertificates';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import { UserType } from '../../../../shared-state/Core/user';

interface EditCrewCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: CrewCertificate,
    forcedUserId?: string
}

const EditCrewCertificate: React.FC<EditCrewCertificateProps> = ({showModal, setShowModal, itemToUpdate, level, forcedUserId}) => {
    const userId = sharedState.userId.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const users = sharedState.users.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate?.title as string,
                heldBy: itemToUpdate?.heldBy as string,
                issuedBy: itemToUpdate?.issuedBy as string,
                whenIssued: formatSeaDate(itemToUpdate.whenIssued),
                type: itemToUpdate?.type as string,
                whenExpires: itemToUpdate.whenExpires ? formatSeaDate(itemToUpdate.whenExpires) : '',
                emailReminder: itemToUpdate?.emailReminder as string,
            };
        } else {
            return {
                title: '',
                heldBy: forcedUserId ? forcedUserId : '',
                issuedBy: forcedUserId ? renderFullNameForUserId(forcedUserId) : renderFullName(),
                whenIssued: formatSeaDate(),
                type: 'renewable',
                whenExpires: '',
                emailReminder: '',
            };
        }
    }, [itemToUpdate, forcedUserId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            heldBy: Yup.string().max(500).required(),
            issuedBy: Yup.string().max(500),
            whenIssued: Yup.date().max(formatSeaDate()).required().min(...notTooOld),
            type: Yup.string().max(200).required(),
            whenExpires: Yup.date().when('type', {is: 'renewable', then: (schema) => schema.required().min(...notTooOld)}),
            emailReminder: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(200)}),
            // document 
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                if (preventMultiTap('crewCertificate')) { return; }
                let whenToRemind = undefined;
                if (data.type === 'renewable' && data.whenExpires && data.emailReminder) {
                    whenToRemind = subtractInterval(data.whenExpires, data.emailReminder);
                };

                const action = traceAction('crewCertificates') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'crewCertificates', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            title: data.title,
                            //heldBy: data.heldBy, // (Cant be changed)
                            issuedBy: data.issuedBy ? data.issuedBy : deleteValue,
                            whenIssued: toMillis(data.whenIssued),
                            whenExpires: data.whenExpires ? toMillis(data.whenExpires) : deleteValue,
                            emailReminder: data.emailReminder ? data.emailReminder : deleteValue,
                            whenToRemind: whenToRemind,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'crewCertificates', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'crewCertificates',
                        itemToUpdate.id,
                        data.title,
                        undefined,
                        [itemToUpdate.heldBy]
                    );

                } else {
                    const newRef =  doc(collection(firestore, 'crewCertificates'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        licenseeId: licenseeId,
                        addedBy: userId,
                        heldBy: data.heldBy,
                        whenAdded: action.whenAction,
                        title: data.title,
                        issuedBy: data.issuedBy ? data.issuedBy : undefined,
                        whenIssued: toMillis(data.whenIssued),
                        type: data.type,
                        whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires) : undefined,
                        emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder : undefined,
                        whenToRemind: whenToRemind,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'crewCertificates', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'crewCertificates',
                        newRef.id,
                        data.title,
                        undefined,
                        [data.heldBy]
                    );
                }
                onCollectionUpdated(batch, 'crewCertificates');

                action.data = {
                    data,
                    files: seaFilesToValue(files)
                };
                action.save(`${itemToUpdate ? 'Update' : 'Create'} crew certificate ${data.title}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Crew Certificate files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        itemToUpdate
                    });
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Certificate' : 'Add New Certificate'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label="Certificate Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                disabled={(itemToUpdate || forcedUserId) ? true : false}
                                label="Crew Member"
                                name="heldBy"
                                value={values.heldBy}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.heldBy ? errors.heldBy : ''}
                            >
                                {users?.staff?.map((user: UserType) => {
                                    return (
                                        <IonSelectOption key={user.id} value={user.id}>{renderFullNameForUserId(user.id)}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Issued By"
								name="issuedBy"
								value={values.issuedBy}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.issuedBy ? errors.issuedBy : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
								label="Issue Date"
								name="whenIssued"
								value={values.whenIssued}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								error={touched.whenIssued ? errors.whenIssued : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                disabled={itemToUpdate ? true : false}
                                label="Certificate Type"
                                name="type"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="renewable">Renewable Certificate</IonSelectOption>
                                <IonSelectOption value="nonExpiring">Non-Expiring Certificate</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        {values.type === 'renewable' && 
                            <>
                                <IonCol size="6"></IonCol>
                                <IonCol size="6">
                                    <SeaDate
                                        label="Expiry Date"
                                        name="whenExpires"
                                        value={values.whenExpires}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenExpires ? errors.whenExpires : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectEmailReminder
                                        label="Set Email Reminder"
                                        name="emailReminder"
                                        value={values.emailReminder}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.emailReminder ? errors.emailReminder : ''}
                                    />
                                </IonCol>
                            </>
                        }
                        <IonCol size="6"></IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="crewCertificates"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                 <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Certificate' : 'Save Certificate'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditCrewCertificate;
