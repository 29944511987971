import React, { useState, useEffect, useMemo, Profiler } from "react";
import { logPageView } from "../../../lib/firebase";
import { extractSearchTerms, formatDate, formatValue } from "../../../lib/util";
import { permissionLevels } from "../../../lib/permissions";
import { IonSelectOption } from "@ionic/react";
import { onProfilerRender } from "../../../lib/performance";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { renderCategoryName } from "../../../lib/categories";
import { sharedState } from '../../../shared-state/shared-state';
import { Job, jobPriorities } from "../../../shared-state/VesselMaintenance/jobs";
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import EditJobList from "../../../modals/VesselMaintenance/JobList/EditJobList/EditJobList";
import ViewJobList from "../../../modals/VesselMaintenance/JobList/ViewJobList/ViewJobList";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaSelect from "../../../components/SeaSelect/SeaSelect";
import EditJobListSettings from "../../../modals/VesselMaintenance/JobList/EditJobListSettings/EditJobListSettings";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import JobListPdf from "../../../pdfs/VesselMaintenance/JobListPdf";
import SeaStatusIcon from "../../../components/SeaStatusIcon/SeaStatusIcon";
import "./JobList.css";

interface JobListProps {
    visible: boolean;
}

interface JobCategoryOptions {
    isEmpty: boolean;
    jobs?: {
        [jobId: string]: Job[];
    };
}

const JobList: React.FC<JobListProps> = ({ visible }) => {
    const jobs = sharedState.jobs.use(visible ? 1 : 101);
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    sharedState.contacts.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.vesselLocations.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.safetyCheckItems.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 51 : 0); // Prepare for modals to access
    const {
        limitTriggerElement,
        mapArrayWithLimit,
        resetPageLimit,
        isLimitReached,
    } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showJobListSettings, setShowJobListSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Job>();
    const [searchText, setSearchText] = useState("");
    const [systemFilter, setSystemFilter] = useState("");
    const [equipmentFilter, setEquipmentFilter] = useState("");
    const [assignedToFilter, setAssignedToFilter] = useState("");
    const [categoriesFiltered, setCategoriesFiltered] = useState<JobCategoryOptions>();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [listType, setListType] = useState("all");

    useEffect(() => {
        if (visible) {
            logPageView("VesselMaintenance/JobList");
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [searchText, systemFilter, equipmentFilter, assignedToFilter, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (jobs?.prioritised && selectedItem?.id) {
            for (let i = 0; i < jobs.prioritised.length; i++) {
                if (jobs.prioritised[i].id === selectedItem.id) {
                    setSelectedItem(jobs.prioritised[i]);
                    break;
                }
            }
        }
    }, [jobs, selectedItem?.id]);

    const isUsingFilters = useMemo(() => {
        return searchText || systemFilter || equipmentFilter || assignedToFilter || listType !== 'all'
            ? true
            : false;
    }, [searchText, systemFilter, equipmentFilter, assignedToFilter, listType]);

    useEffect(() => {
        setCategoriesFiltered(undefined);
        if (jobs?.prioritised && Object.keys(jobs?.prioritised).length > 0) {
            const categories: JobCategoryOptions = {
                isEmpty: true,
                jobs: {},
            };
            Object.keys(jobPriorities).forEach((priorityId: string) => {
                categories.jobs![priorityId] = [];
            });
            const terms = extractSearchTerms(searchText, true);
            jobs.prioritised.forEach((job: Job) => {
                if (listType === 'critical' && !job.equipment?.isCritical) {
                    return;
                }
                if (
                    systemFilter &&
                    (job.equipment === undefined || job.equipment.systemId !== systemFilter)
                ) {
                    return;
                }
                if (
                    equipmentFilter &&
                    (job.equipment === undefined || job.equipment.id !== equipmentFilter)
                ) {
                    return;
                }
                if (
                    assignedToFilter &&
                    (job.assignedTo === undefined || job.assignedTo.name !== assignedToFilter)
                ) {
                    return;
                }
                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (job.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        return;
                    }
                }
                categories.jobs![job.priority].push(job);
                categories.isEmpty = false;
            });
            setCategoriesFiltered(categories);
        } else if (jobs?.prioritised) {
            setCategoriesFiltered({ isEmpty: true })
        }
    }, [jobs, searchText, systemFilter, equipmentFilter, assignedToFilter, listType]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };
    const onViewItem = (item: Job) => {
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowJobListSettings(true);
    };


    const equipmentFilterOptions = useMemo(() => {
        const _equipment: string[] = [];
        if (jobs?.filterOptions?.equipmentIds?.length) {
            for (const id of jobs.filterOptions.equipmentIds) {
                if (systemFilter && equipment?.byId[id]?.systemId !== systemFilter) {
                    continue;
                }
                _equipment.push(id);
            }
        }
        return _equipment;
    }, [
        jobs?.filterOptions?.equipmentIds,
        equipment?.byId,
        systemFilter,
    ]);

    return (
        <RequirePermissions
            role="jobList"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="job-list page-head">
                <div>
                    <h2>Job List</h2>
                </div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="all">All</IonSelectOption>
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="jobList"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New Job
                        </SeaButton>
                        <div className="spacer"></div>
                    </RequirePermissions>
                    <SeaButton
                        onClick={(e) => onGeneratePdf()}
                        zone="grey"
                        shape="circle"
                    >
                        <SeaIcon slot="icon-only" icon="pdf" />
                    </SeaButton>
                    <RequirePermissions
                        role="jobList"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            {/* Filters */}
            <div
                className="columns wrap filters reveal"
            >
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {jobs && jobs.filterOptions.systemIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="systemFilter"
                            value={systemFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => {
                                setSystemFilter(e.detail.value);
                                setEquipmentFilter("");
                            }}
                        >
                            <IonSelectOption value="">
                                Filter by System
                            </IonSelectOption>
                            {jobs.filterOptions.systemIds.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {renderCategoryName(
                                                id,
                                                vesselSystems
                                            )}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {jobs && jobs.filterOptions.equipmentIds.length > 0 && (
                    <div>
                        <SeaSelect
                            name="equipmentFilter"
                            value={equipmentFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => 
                                setEquipmentFilter(e.detail.value)
                            }
                        >
                            <IonSelectOption value="">
                                Filter by Equipment
                            </IonSelectOption>
                            {equipmentFilterOptions?.map(
                                (id: string) => {
                                    return (
                                        <IonSelectOption key={id} value={id}>
                                            {equipment?.byId[id].equipment}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                {jobs && jobs.filterOptions.assignedTo.length > 0 && (
                    <div>
                        <SeaSelect
                            name="assignedToFilter"
                            value={assignedToFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => 
                                setAssignedToFilter(e.detail.value)
                            }
                        >
                            <IonSelectOption value="">
                                Filter by Assigned To
                            </IonSelectOption>
                            {jobs?.filterOptions?.assignedTo?.map(
                                (option: string) => {
                                    return (
                                        <IonSelectOption
                                            key={option}
                                            value={option}
                                        >
                                            {option}
                                        </IonSelectOption>
                                    );
                                }
                            )}
                        </SeaSelect>
                    </div>
                )}
                <div>
                    <SeaButton
                        onClick={(e) => {
                            setSearchText("");
                            setSystemFilter("");
                            setEquipmentFilter("");
                            setAssignedToFilter("");
                        }}
                        zone="grey"
                    >
                        Reset
                    </SeaButton>
                </div>
            </div>

            <SeaNoData
                dataName="jobs"
                isLoading={!categoriesFiltered}
                hasNoData={categoriesFiltered?.isEmpty}
                isUsingFilter={isUsingFilters}
            />

            <div
                className={`job-list has-thumbs ${
                    categoriesFiltered && !categoriesFiltered.isEmpty
                        ? "reveal"
                        : "conceal"
                }`}
            >
                <div className="sea-row headings">
                    <div></div>
                    <div>Task</div>
                    <div>Job #</div>
                    <div>Assigned</div>
                    <div>Job Tags</div>
                    <div>Date Added</div>
                    <div>Due Date</div>
                    <div>Critical</div>
                </div>
                <Profiler id="maintenance.jobs" onRender={onProfilerRender}>
                    {Object.keys(jobPriorities).map((priorityId) => {
                        if (
                            !isLimitReached() &&
                            categoriesFiltered &&
                            categoriesFiltered.jobs &&
                            categoriesFiltered.jobs[priorityId].length > 0
                        ) {
                            let rowClass = "";
                            switch (priorityId) {
                                case "8urgent":
                                    rowClass = "fail fault";
                                    break;
                                case "6high":
                                    rowClass = "fail";
                                    break;
                                case "4medium":
                                    rowClass = "warn-medium";
                                    break;
                                case "2low":
                                    rowClass = "warn-low";
                                    break;
                                case "0shipyard":
                                    rowClass = "warn-alt";
                                    break;
                            }
                            return (
                                <React.Fragment key={priorityId}>
                                    <div className="category-heading">
                                        {jobPriorities[priorityId as keyof typeof jobPriorities]}
                                    </div>
                                    {mapArrayWithLimit(
                                        categoriesFiltered.jobs[priorityId],
                                        (job: Job) => {
                                            return (
                                                <div
                                                    key={job.id}
                                                    className={`sea-card sea-row ${rowClass}`}
                                                    onClick={(e) =>
                                                        onViewItem(job)
                                                    }
                                                >
                                                    <div>
                                                        <SeaFileImage
                                                            files={job.files}
                                                            size="tiny"
                                                        />
                                                    </div>
                                                    <div className={`bold truncate-2 ${job.equipment?.isCritical ? "has-icon" : ""}`}>
                                                        {job.task}
                                                    </div>
                                                    <div>
                                                        {formatValue(
                                                            job.jobNum
                                                        )}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(
                                                            job.assignedTo?.name
                                                        )}
                                                        {job.assignedTo
                                                            ?.contactId &&
                                                            " (Contact)"}
                                                    </div>
                                                    <div className="truncate-2">
                                                        {formatValue(
                                                            job.tags &&
                                                                job.tags.join(
                                                                    ", "
                                                                )
                                                        )}
                                                    </div>
                                                    <div>
                                                        {formatDate(
                                                            job.whenAdded
                                                        )}
                                                    </div>
                                                    <div>
                                                        {job.whenDue
                                                            ? formatDate(
                                                                  job.whenDue
                                                              )
                                                            : "-"}
                                                    </div>
                                                    <div>
                                                        {job.equipment?.isCritical && (
                                                            <SeaStatusIcon
                                                                icon="flag"
                                                                className="critical"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            );
                        }
                        return undefined;
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditJobList
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <ViewJobList
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />
                    <JobListPdf
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        categoriesFiltered={categoriesFiltered?.jobs || {}}
                    />
                    <EditJobListSettings
                        showModal={showJobListSettings}
                        setShowModal={setShowJobListSettings}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default JobList;
