import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { ArchivableDocument, CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";

//
// Loads crew certificates (active)
//

export interface Engine extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    hours: number;
    name: string;
    state: string;
    updatedVia?: string;
    vesselId: string;
}

export type EnginesData = {
    all: Engine[], // All active sorted by name
    byId: {
        [id: string]: Engine
    },
    whenLastUpdated: number
};

export const enginesConfig: SharedStateConfig<EnginesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.engines.current?.all.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId) {
            return onSnapshot(
                query(
                    collection(firestore, 'engines'),
                    where('vesselId', '==', vesselId),
                    //where('state', '==', 'active')
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    done();
                    const all: Engine[] = [];
                    const byId = {} as {
                        [id: string]: Engine
                    };
                    let whenLastUpdated: number = 0;
                    snap.docs.forEach((doc) => {
                        const engine = {
                            id: doc.id,
                            ...doc.data(),
                        } as Engine;
                        byId[engine.id] = engine;
                        if (engine.state === 'active') {
                            all.push(engine);
                            if (engine.whenUpdated && engine.whenUpdated > whenLastUpdated) {
                                whenLastUpdated = engine.whenUpdated;
                            }
                        }
                    });
                    set({
                        all: all,
                        byId,
                        whenLastUpdated,
                    });
                },
                (error) => {
                    // This should be very rare
                    done();
                    console.log(
                        `Failed to access engines for ${vesselId}`,
                        error
                    );
                }
            );
        } else {
            done();
        }
    },

};
