import React, { ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import './SeaScrollableArea.css';

interface SeaScrollableAreaProps {
    children: ReactNode,
    className?: string,
    scrollX?: boolean,
    scrollEvents?: boolean,
    onScroll?: (event: Event) => void,
    onScrollEnd?: (event: Event) => void,
    onClick?: React.MouseEventHandler<HTMLDivElement>
};

export interface SeaScrollable {
    x: () => number,
    y: () => number,
    scrollToTop: (millis: number) => void,
    scrollByPoint: (x: number, y: number, millis: number) => void
};

const SeaScrollableArea = React.forwardRef<SeaScrollable, SeaScrollableAreaProps>(({
    children,
    className,
    scrollX,
    scrollEvents,
    onScroll,
    onScrollEnd,
    onClick
}, forwardedRef: any) => {
    const divRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(forwardedRef, () => {
        return {
            x: () => {
                return divRef.current?.scrollLeft as number;
            },
            y: () => {
                return divRef.current?.scrollTop as number;
            },
            scrollToTop: (millis: number) => {
                divRef.current?.scrollTo({
                    top: 0,
                    behavior: (millis ? 'smooth' : 'instant')
                } as any);
            },
            scrollByPoint: (x: number, y: number, millis: number) => {
                divRef.current?.scrollBy({
                    top: y,
                    left: x,
                    behavior: (millis ? 'smooth' : 'instant')
                } as any);
            },
        };
    }, []);

    useEffect(() => {
        if (divRef.current && scrollEvents && (onScroll || onScrollEnd)) {
            const div = divRef.current;
            let scrollListener: (e: Event) => void;
            let scrollEndListener: (e: Event) => void;
            if (onScroll) {
                scrollListener = (e: Event) => {
                    //console.log('scroll! e', e);
                    onScroll(e);
                };
                div.addEventListener('scroll', scrollListener);
            }
            if (onScrollEnd) {
                scrollEndListener = (e: Event) => {
                    //console.log('scrollEnd! e', e);
                    onScrollEnd(e);
                };
                div.addEventListener('scrollend', scrollEndListener);
            }
            return () => {
                if (scrollListener) {
                    div.removeEventListener('scroll', scrollListener);
                }
                if (scrollEndListener) {
                    div.removeEventListener('scrollend', scrollEndListener);
                }
            };
        }
    }, [divRef, scrollEvents, onScroll, onScrollEnd]);

    return (
        <div
            ref={divRef}
            className={`sea-scrollable ${className ? className : ''}`}
            style={{
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                overflowX: scrollX ? 'auto' : 'hidden'
            }}
            onClick={onClick}
        >
            {children}
        </div>
    );
});

export default SeaScrollableArea;
