import { Timestamp, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { cachedDataInfo } from "./cachedDataInfo";
import { VesselDataSyncCollection, onFreshVesselData } from "./dataSyncTasks";

//
// Loads whenVesselTouched. 
// Used to trigger updates to the local cache if we find out there's something new on the server which we are not already actively listening to
//

export type WhenVesselTouchedBase = {
    id: string;
    touched: Timestamp;
    licenseeId: string;
    count: number;
};

export type WhenVesselTouchedCollections = {
    [K in VesselDataSyncCollection]: Timestamp;
};

export type WhenVesselTouched = WhenVesselTouchedBase & WhenVesselTouchedCollections;

export type WhenVesselTouchedData = WhenVesselTouched[];

export const whenVesselTouchedConfig: SharedStateConfig<WhenVesselTouchedData> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId', 'vesselIds', 'isDataSyncActive'],
    countLiveDocs: () => sharedState.whenVesselTouched.current?.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const isDataSyncActive = sharedState.isDataSyncActive.current;
        const vesselIds = sharedState.vesselIds.current;
        if (licenseeId && vesselIds && isDataSyncActive) {
            return onSnapshot(
                query(
                    collection(firestore, 'whenVesselTouched'),
                    where('licenseeId', '==', licenseeId)
                ),
                (snap) => {
                    done();
                    // Check touched values against cachedDataInfo for all whenVesselTouched documents that have changed

                    snap.docChanges().forEach((change) => {
                        if (change.type === 'added' || change.type === 'modified') {
                            const whenVesselTouched = {
                                id: change.doc.id,
                                ...change.doc.data()
                            } as WhenVesselTouched;
                            if (
                                whenVesselTouched.touched &&
                                vesselIds.includes(whenVesselTouched.id)
                            ) {
                                const props = Object.getOwnPropertyNames(whenVesselTouched);
                                for (var i = 0; i < props.length; i++) {
                                    const prop = props[i];
                                    if (prop === 'id' || prop === 'licenseeId' || prop === 'touched') {
                                        continue;
                                    }
                                    if (
                                        cachedDataInfo.vessels[whenVesselTouched.id] &&
                                        cachedDataInfo.vessels[whenVesselTouched.id][prop as VesselDataSyncCollection] &&
                                        cachedDataInfo.vessels[whenVesselTouched.id][prop as VesselDataSyncCollection] as number >= (whenVesselTouched[prop as VesselDataSyncCollection] as Timestamp).toMillis()
                                    ) {
                                        // We already have the latest data.
                                        // Therefore we wont need to cache anything.
                                    } else {
                                        // cachedDataInfo.vessels[vesselId][prop] is behind
                                        onFreshVesselData(
                                            whenVesselTouched.id, // vesselId
                                            prop as VesselDataSyncCollection,
                                            (whenVesselTouched[prop as VesselDataSyncCollection] as Timestamp).toMillis()
                                        );
                                    }
                                }
                            }
                        }
                    });
                    // Update sharedState.whenVesselTouched
                    set(snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as WhenVesselTouched;
                    }));
                }, (error) => { // onError
                    done();
                }
            );
        } else {
            done(); // Not sure about this...
        }
    },
    notes: 'Indicates when something was last touched on the server for various vessel collections'
};
