import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../lib/pdf';
import { formatDateSimplify, formatTime } from '../../lib/util';
import { CreateableDocument, UpdateableDocument } from '../../shared-state/shared-state';

interface Authorship extends CreateableDocument, UpdateableDocument {
    completedBy?: string;
    whenCompleted?: number;
}

interface SeaAuthorshipProps {
    item?: Authorship,
    creationText?: string,
    completeText?: string,
    updateText?: string,
    includeTime?: boolean
    addedBy?: string,
    completedBy?: string,
    updatedBy?: string
}
const SeaAuthorshipPDF = ({ 
    item, 
    creationText = 'Created', 
    completeText = 'Completed', 
    updateText = 'Last updated', 
    includeTime = true,
    addedBy,
    completedBy,
    updatedBy
    } : SeaAuthorshipProps) => {

    if (!item) {
      return <></>;
    }

    if (item?.completedBy) {
        if (item.addedBy && item.whenAdded && item.addedBy !== item.completedBy) {
            return (
                <View style={[pdfStyles.row, pdfStyles.authorship]}>
                    <View style={pdfStyles.column1}>
                        <Text>
                            {`${creationText} by ${addedBy}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                        </Text>
                        <br />
                        <Text>
                            {`${completeText}${(item.completedBy === item.addedBy) ? '' : ` by ${completedBy},`} ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                        </Text>
                        <br />
                        {item.updatedBy && item.whenUpdated !== item.whenCompleted && (
                            <Text>
                                {`${updateText}${(item.updatedBy === item.completedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                            </Text>
                        )}
                    </View>
                </View>
            );
        }
        if (item?.updatedBy && item.whenUpdated) {
            return (
                <View style={[pdfStyles.row, pdfStyles.authorship]}>
                    <View style={pdfStyles.column1}>
                        <Text>
                            {`${completeText} by ${completedBy}, ${item.whenCompleted && formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                        </Text>
                        <br />
                        <Text>
                            {`${updateText}${(updatedBy === completedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                        </Text>
                    </View>
                </View>
            );
        }
        return (
            <View style={[pdfStyles.row, pdfStyles.authorship]}>
                <View style={pdfStyles.column1}>
                    <Text>
                        {`${completeText} by ${completedBy}, ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                    </Text>
                </View>
            </View>
        );  
    }
    if (item?.updatedBy && item.whenUpdated) {
        return (
            <View style={[pdfStyles.row, pdfStyles.authorship]}>
                <View style={pdfStyles.column1}>
                    <Text>
                        {`${creationText} by ${addedBy}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                    </Text>
                    <br />
                    <Text>
                        {`${updateText}${(updatedBy === addedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                    </Text>
                </View>
            </View>
        );
    }
    if (item?.addedBy && item.whenAdded) {
        return (
            <View style={[pdfStyles.row, pdfStyles.authorship]}>
                <View style={pdfStyles.column1}>
                    <Text>
                        {`${creationText} by ${addedBy}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                    </Text>
                </View>
            </View>
        );
    }

    return (
        <View style={[pdfStyles.row, pdfStyles.authorship]}>
            <View style={pdfStyles.column1}>
                {completedBy ? (
                    <Text>
                        {`${creationText} by ${addedBy}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                        <Text>
                            {`${completeText}${(item.completedBy === item.addedBy) ? '' : ` by ${completedBy},`} ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                            {item.updatedBy && item.whenUpdated !== item.whenCompleted && (
                                <Text>
                                    {`${updateText}${(item.updatedBy === item.completedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                                </Text>
                            )}
                        </Text>
                    </Text>
                ) : (
                  <></>
                )}
                {!completedBy && updatedBy ? (
                  <Text>
                      {`${completeText} by ${completedBy}, ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                      <Text>
                          {`${updateText}${(updatedBy === completedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                      </Text>
                  </Text>
                ) : (
                  <></>
                )}
                {addedBy ? (
                  <Text>
                      {`${creationText} by ${addedBy}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                      {item.updatedBy && (
                          <Text>
                            {`${updateText}${(updatedBy === addedBy) ? '' : ` by ${updatedBy},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                          </Text>
                      )}
                  </Text>
                ) : (
                  <></>
                )}
            </View>
        </View>
    );
};

export default SeaAuthorshipPDF;

