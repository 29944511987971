import React, { ReactNode } from 'react';
import { IonButton } from '@ionic/react';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { PermissionRole } from '../../lib/permissions';
import './SeaTab.css';
import { onUserAction } from '../../shared-state/General/appActivity';
import { jsxToText } from '../../lib/util';

interface SeaTabProps {
    children: ReactNode,
    tab: string,
    setTab?: (tab: string) => void,
    requireRole?: PermissionRole,
    disabled?: boolean,
    disabledMessage?: string,
    mode?: 'forms',
    hasErrors?: boolean,
    hide?: boolean,
    notificationNumber?: number,
    width?: string
}

const SeaTab: React.FC<SeaTabProps> = ({
    children,
    tab,
    setTab,
    disabled,
    disabledMessage,
    mode,
    hasErrors,
    hide,
    notificationNumber,
    width
}) => {

    const onClick = () => {
        if (disabled) {
            if (disabledMessage) {
                alertMessage(disabledMessage);
            }
            return;
        }
        if (setTab) {
            onUserAction(`Tabbed to "${jsxToText(children)}"`);
            setTab(tab);
        }
    };

    return (
        <IonButton
            className={`sea-button sea-tab ${mode ? mode : ''} ${hasErrors ? 'has-errors' : ''} ${disabled ? 'disabled' : ''}`}
            style={{
                width: width
            }}
            disabled={disabled}
            onClick={(e) => onClick()}
        >
            {children}
            {(notificationNumber && notificationNumber > 0) ?
                (
                    <div className="notification-number">{notificationNumber}</div>
                ) : ''
            }
        </IonButton>
    );
};

export default SeaTab;
