import React from 'react';
import SeaIcon, { SeaIconOptions } from '../SeaIcon/SeaIcon';
import './SeaStatusIcon.css';

interface SeaStatusProps {
    icon: SeaIconOptions,
    className?: string
}

const SeaStatus: React.FC<SeaStatusProps> = ({ icon, className }) => {
    return (
        <div className={`sea-status icon ${className ? className : ''}`}>
            <SeaIcon icon={icon} />
        </div>
    );
};

export default SeaStatus;
