import React, { useEffect, useMemo, useState } from 'react';
import { LicenseesData } from '../../../../shared-state/Admin/licensees';
import { IonSelectOption, IonSpinner } from '@ionic/react';
import { FileInfoData, useFileInfo } from '../../../../shared-state/Admin/fileInfo';
import { formatDate, formatDatetime, formatValue, isImage, makeDateTime } from '../../../../lib/util';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { doc, getDoc } from 'firebase/firestore';
import { firestore, functions } from '../../../../lib/firebase';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaHorizontalBarGraph from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import './FilesAdmin.css';
import { httpsCallable } from 'firebase/functions';


interface FilesAdminProps {
    visible: boolean,
    licensees: LicenseesData | undefined
}

const tabs = ['Listing', 'Collections', 'File Extensions', 'File Types', 'File Versions', 'Monthly', 'Licensees', 'Problems' ];

const types = ['File', 'Image', 'Signature', 'Rich Text', '?'];
const typeOptions = types.map((type) => {
    return {id: type, name: type};
});

//const problems = ['noProblem', 'badLicenseeIds', 'notUsed', 'noFile', 'noStorage', 'badStatePropagation', 'trappedOnDevice', 'incorrectState0', 'failedToOptimise', 'missingStorage'];
//const problems = ['noProblem', 'badLicenseeIds', 'notUsed', 'trappedOnDevice', 'lostForever', 'storageStateAheadFile', 'storageStateBehindFile', 'storageWithNoFile', 'storageStateAheadRefs', 'storageStateBehindRefs', 'inconsistentRefStates'];
const problems = ['noProblem', 'badLicenseeIds', 'notUsed', 'trappedOnDevice', 'lostForever', 'storageStateAhead', 'storageStateBehind', 'storageWithNoFile', 'undefinedRefState', 'inconsistentRefStates', 'badStatePropagation', 'failedToOptimise'];

const problemOptions = problems.map((problem) => {
    return {id: problem, name: problem};
});

const fixes = [{
    id: 'deleteUnusedFiles',
    notes: 'Not sure how these come about as the original referencing documents should never be deleted for real. But whatever the case, these should be safe to delete.',
    solution: '[Not implemented] Deletes file documents and storage objects that no one cares about anymore.'
}, {
    id: 'fixBadLicenseeIds',
    notes: `Best guess for why this happens: something goes wrong during section replication. (WIP)`,
    solution: `[Not implemented] Give file document and storage objects the maximal array of licenseeIds possible (to prevent possible security violation red screens).`
}, {
    id: 'clearLostForever',
    notes: `There are no matching Storage files to be found even though a succesful upload should've occurred.`,
    solution: `Remove refs that point to lost files.`
}];

const orderBys = {
    "File Id": (a: FileInfoData, b: FileInfoData) => {
        return a.id.localeCompare(b.id);
    },
    "Newest": (a: FileInfoData, b: FileInfoData) => {
        return Number(b.when ?? 0) - Number(a.when ?? 0);
    },
    "Oldest": (a: FileInfoData, b: FileInfoData) => {
        return Number(a.when ?? 0) - Number(b.when ?? 0);
    },
    "Collection": (a: FileInfoData, b: FileInfoData) => {
        return (a.collection ?? '').localeCompare(b.collection ?? '');
    },
    "Licensee": (a: FileInfoData, b: FileInfoData) => {
        return (a.licensees?.[0] ?? '?').localeCompare(b.licensees?.[0] ?? '?');
    },
    "Ext": (a: FileInfoData, b: FileInfoData) => {
        return (a.extMooshed ?? '').localeCompare(b.extMooshed ?? '');
    },
    "Type": (a: FileInfoData, b: FileInfoData) => {
        return (a.type ?? '').localeCompare(b.type ?? '');
    },
    "Largest": (a: FileInfoData, b: FileInfoData) => {
        return getSumOfSizes(b) - getSumOfSizes(a);
    },
    "Smallest": (a: FileInfoData, b: FileInfoData) => {
        return getSumOfSizes(a) - getSumOfSizes(b);
    },
    "Problems": (a: FileInfoData, b: FileInfoData) => {
        return (a.problemText ? a.problemText : 'zzz').localeCompare(b.problemText ? b.problemText : 'zzz');
    },
};

const processSize = (value: number) => {
    return Math.round(value / 1048576); // Megabytes
    //return Math.round((value * 100) / 1073741824) / 100; // Gigabytes
};

const formatFileSize = (bytes: number | undefined) => {
    if (bytes !== undefined) {
        return bytes.toLocaleString();
    }
    return '';
};

const getSumOfSizes = (data: FileInfoData) => {
    let sum = 0;
    if (data.sizes) {
        Object.values(data.sizes).forEach((size) => {
            sum += size;
        });
    }
    return sum;
};

const FilesAdmin: React.FC<FilesAdminProps> = ({ visible, licensees}) => {
    const [selectedLicenseeId, setSelectedLicenseeId] = useState<string>('all');
    const [tab, setTab] = useState('Listing');
    const [showFileInfoModal, setShowFileInfoModal] = useState(false);
    const [fileId, setFileId] = useState('');
    const [selectedFileInfo, setSelectedFileInfo] = useState<FileInfoData>();
    const [fullFileInfo, setFullFileInfo] = useState<any>();
    const [selectedTypes, setSelectedTypes] = useState<string[]>([...types]);
    const [selectedProblems, setSelectedProblems] = useState<string[]>([...problems]);
    const [orderBy, setOrderBy] = useState('File Id');
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const fileInfo = useFileInfo();
    const [problemsToFix, setProblemsToFix] = useState<string[]>([]);
    //console.log(`<<< licensees`, licensees);
    //console.log(`<<< fileInfo`, fileInfo);

    useEffect(() => {
        setFullFileInfo(undefined);
        if (selectedFileInfo?.id) {
            getDoc(
                doc(firestore, '_fileInfo', selectedFileInfo.id)
            ).then((doc) => {
                if (doc.exists()) {
                    setFullFileInfo(doc.data());
                }
            });
        }
    }, [selectedFileInfo]);

    const filteredFileInfo = useMemo(() => {
        resetPageLimit();
        const filtered = [] as FileInfoData[];
        fileInfo?.all?.forEach((data: FileInfoData) => {
            
            if (selectedLicenseeId !== 'all') {
                if (data.licenseeIds === undefined || !data.licenseeIds.includes(selectedLicenseeId)) {
                    return;
                }
            }
            if (fileId && data.id !== fileId) {
                return;
            }

            if (data.problems) {
                let hasProblem = false;
                selectedProblems.forEach((problem) => {
                    if ((data.problems as any)[problem]) {
                        hasProblem = true;
                    }
                });
                if (!hasProblem) return;
            } else if (!selectedProblems.includes('noProblem')) {
                return;
            }

            if (data.type && !selectedTypes.includes(data.type)) {
                return;
            }

            // Decorate data with licensee name
            data.licensees = data.licenseeIds.map((id) => {
                const licensee = licensees?.byId[id];
                if (licensee) {
                    return `${licensee.firstName} ${licensee.lastName}`;
                }
                return `??? ${id}`;
            });
            // Decorate with type
            data.type = '?';
            if (data.sizes) {
                if (data.extMooshed === 'sfdoc') {
                    data.type = 'Rich Text';
                } else if (data.sizes.F || data.sizes.M || data.sizes.T) {
                    data.type = 'Image';
                } else if (data.extMooshed === 'png') {
                    if (data.sizes.S) {
                        data.type = 'Signature';
                    } else {
                        data.type = 'Image';
                    }
                } else if (data.extMooshed && isImage(data.extMooshed)) {
                    data.type = 'Image';
                } else {
                    data.type = 'File'
                }
            }
            // Decorate problemText
            if (data.problems) {
                data.problemText = Object.keys(data.problems).sort().join(', ')
            }

            filtered.push(data);
        });

        // Sort using orderBy
        filtered.sort((orderBys as any)[orderBy]);

        return filtered;
    }, [fileInfo, licensees, fileId, selectedLicenseeId, selectedTypes, selectedProblems, orderBy, resetPageLimit]);

    const stats = useMemo(() => {
        const getOne = (data: FileInfoData) => {
            return 1;
        };
        const makeGraphData = (stats: any, processValue?: (value: number) => number) => {
            return Object.keys(stats).sort().map((groupName: string) => {
                return {
                    name: groupName,
                    value: processValue ? processValue(stats[groupName]) : stats[groupName]
                };
            });
        };
        const reverseAlphabeticalOrder = (a: any, b: any) => {
            return String(b.name).localeCompare(a.name);
        };
        const highestOrder = (a: any, b: any) => {
            return b.value - a.value;
        };

        const processGroup = (
            getGroupNames: (data: FileInfoData) => string[] | undefined,
            getValue: (data: FileInfoData, groupName?: string) => number,
            processValue?: (value: number) => number,
            compare: (a: any, b: any) => number = highestOrder
        ) => {
            const byGroupName = {} as any;
            filteredFileInfo?.forEach((data: FileInfoData) => {
                const groupNames = getGroupNames(data);
                groupNames?.forEach((groupName) => {
                    if (byGroupName[groupName] === undefined) {
                        byGroupName[groupName] = 0;
                    }
                    byGroupName[groupName] += getValue(data, groupName);
                });
            });

            const graphData = makeGraphData(
                byGroupName,
                processValue
            );

            if (compare) {
                graphData.sort(compare);
            }

            return graphData;
        };

        return {
            collectionsByCount: processGroup(
                (data) => [data.collection ?? 'Unknown'],
                getOne
            ),
            collectionsBySize: processGroup(
                (data) => [data.collection ?? 'Unknown'],
                getSumOfSizes,
                processSize
            ),
            fileTypesByCount: processGroup(
                (data) => [data.type ?? '?'],
                getOne
            ),
            fileTypesBySize: processGroup(
                (data) => [data.type ?? '?'],
                getSumOfSizes,
                processSize
            ),
            fileVersionsByCount: processGroup(
                (data) => data.sizes ? Object.keys(data.sizes) : undefined,
                getOne
            ),
            fileVersionsBySize: processGroup(
                (data) => data.sizes ? Object.keys(data.sizes) : undefined,
                (data, groupName) => data.sizes![groupName!] ?? 0,
                processSize
            ),
            extByCount: processGroup(
                (data) => data.extMooshed ? [data.extMooshed] : undefined,
                getOne
            ),
            extBySize: processGroup(
                (data) => data.extMooshed ? [data.extMooshed] : undefined,
                getSumOfSizes,
                processSize
            ),
            monthsByCount: processGroup(
                (data) => data.when ? [makeDateTime(data.when).toFormat('yyyy-MM MMM')] : undefined,
                getOne,
                undefined,
                reverseAlphabeticalOrder
            ),
            monthsBySize: processGroup(
                (data) => data.when ? [makeDateTime(data.when).toFormat('yyyy-MM MMM')] : undefined,
                getSumOfSizes,
                processSize,
                reverseAlphabeticalOrder
            ),
            licenseesByCount: processGroup(
                (data) => data.licensees,
                getOne
            ),
            licenseesBySize: processGroup(
                (data) => data.licensees,
                getSumOfSizes,
                processSize
            ),
            problemsByCount: processGroup(
                (data) => data.problems ? Object.keys(data.problems) : undefined,
                getOne
            ),
            problemsBySize: processGroup(
                (data) => data.problems ? Object.keys(data.problems) : undefined,
                getSumOfSizes,
                processSize
            )
        };
    }, [filteredFileInfo]);

    const listingSize = useMemo(() => {
        let sum = 0;
        filteredFileInfo?.forEach((data: FileInfoData) => {
            sum += getSumOfSizes(data);
        });
        return sum;
    }, [filteredFileInfo]);

    const onViewFileInfo = (info: FileInfoData) => {
        console.log(`View file info ${info.id}`, info);
        setSelectedFileInfo(info);
        setShowFileInfoModal(true);
    };

    console.log(`<<< stats`, stats);

    if (fileInfo === undefined) {
        return (
            <div>
                <i>Loading... (Please wait, this can take a while)</i>
                <IonSpinner name="crescent" className="sea-spinner"/>
            </div>
        );
    }

    return (
        <>
            <div style={{ paddingBottom: '8px' }}>
                {filteredFileInfo.length?.toLocaleString()} records loaded. Data represents files uploaded before <b>{formatDate(fileInfo.when)}</b>.
            </div>
            <div className="files-admin">
                <div className="columns">
                <div style={{ flex: '0 1 200px' }}>
                        <SeaInput
                            label="File Id"
                            value={fileId}
                            onchange={(e) => setFileId(e.detail.value)}
                        />
                    </div>
                    <div style={{ flex: '0 1 200px' }}>
                        <SeaSelect
                            label="Licensee"
                            value={selectedLicenseeId}
                            onchange={(e) => {
                                setSelectedLicenseeId(e.detail.value);
                            }}
                        >
                            <IonSelectOption value="all">All Licensees</IonSelectOption>
                            {licensees?.all?.map((licensee) => {
                                return (
                                    <IonSelectOption key={licensee.id} value={licensee.id}>
                                        {licensee?.firstName} {licensee?.lastName}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                    <div style={{ flex: '0 1 260px' }}>
                        <SeaMultiSelect
                            label="Types"
                            values={selectedTypes}
                            setValues={setSelectedTypes}
                            options={typeOptions}
                            useAllOption={true}
                            mode="popover"
                        />
                    </div>
                    <div style={{ flex: '0 1 260px' }}>
                        <SeaMultiSelect
                            label="Problems"
                            values={selectedProblems}
                            setValues={setSelectedProblems}
                            options={problemOptions}
                            useAllOption={true}
                            mode="popover"
                        />
                    </div>
                    <div style={{ flex: '0 1 200px' }}>
                        <SeaSelect
                            label="Order By"
                            value={orderBy}
                            onchange={(e) => {
                                setOrderBy(e.detail.value);
                            }}
                        >
                            {Object.keys(orderBys).map((orderBy) => {
                                return (
                                    <IonSelectOption key={orderBy} value={orderBy}>
                                        {orderBy}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                </div>
                {filteredFileInfo === undefined &&
                    <IonSpinner name="crescent" className="sea-spinner"/>
                }
                <div>
                    <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini={true}>
                        {tabs.map((tabName) => {
                            return (
                                <SeaTab key={tabName} tab={tabName} setTab={setTab}>{tabName}</SeaTab>
                            );
                        })}
                    </SeaTabsGroup>
                </div>
                {filteredFileInfo && tab === 'Listing' &&
                    <>
                        {filteredFileInfo.length === 0 &&
                            `No matching files found`
                        }
                        {filteredFileInfo.length > 0 &&
                            <div className={`file-info`}>
                                <div>
                                    {filteredFileInfo.length.toLocaleString()} matching files found ({formatFileSize(processSize(listingSize))}MB).
                                </div>
                                <div className="sea-row headings">
                                    <div>Id</div>
                                    <div>When</div>
                                    <div>Name</div>
                                    <div>Collection</div>
                                    <div>Licensee</div>
                                    <div>State File/Refs/Storage</div>
                                    <div>Ext</div>
                                    <div>Type</div>
                                    <div>Original Bytes</div>
                                    <div>Optimised Bytes</div>
                                    <div>Thumbnail Bytes</div>
                                    <div>Problems</div>
                                </div>
                                {mapArrayWithLimit(filteredFileInfo, (info: FileInfoData) => {
                                    return (
                                        <div key={info.id} className={`sea-card sea-row`} onClick={(e) => onViewFileInfo(info)}>
                                            <div className="truncate">{info.id}</div>
                                            <div className="truncate">{info.when ? formatDatetime(info.when) : '?'}</div>
                                            <div className="truncate">{info.name}</div>
                                            <div className="truncate">{info.collection ?? '?'}</div>
                                            <div className="truncate">{info.licensees?.join(', ')}</div>
                                            <div className="truncate">
                                                {info.fileState ?? '?'} &nbsp; {info.refsState ?? '?'} &nbsp; {info.storageState ?? '?'}
                                            </div>
                                            <div className="truncate">{info.extMooshed}</div>
                                            <div className="truncate">{info.type}</div>
                                            <div className="truncate">{formatFileSize(info.sizes?.O)}</div>
                                            <div className="truncate">{formatFileSize(info.sizes?.F || info.sizes?.S || info.sizes?.R)}</div>
                                            <div className="truncate">{formatFileSize(info.sizes?.T)}</div>
                                            <div className="truncate">{info.problemText}</div>
                                        </div>
                                    );
                                })}
                                {limitTriggerElement}
                            </div>
                        }
                        <SeaModal
                            title={selectedFileInfo?.name}
                            showModal={showFileInfoModal}
                            setShowModal={setShowFileInfoModal}
                            size="wide"
                        >
                            {selectedFileInfo &&
                                <>
                                    <div className="info-image">
                                        {selectedFileInfo.type && selectedFileInfo.type !== 'Signature' &&
                                            <SeaFileImage
                                                files={[`${selectedFileInfo.storageState}${selectedFileInfo.id}.${selectedFileInfo.ext}`]}
                                                size="medium"
                                                showOthers
                                            />
                                        }
                                        {selectedFileInfo.type && selectedFileInfo.type === 'Signature' &&
                                            <SeaSignatureImage file={`${selectedFileInfo.storageState}${selectedFileInfo.id}.${selectedFileInfo.ext}`}/>
                                        }
                                    </div>
                                    <SeaGrid>
                                        <SeaGridCell label="Id" w="50">
                                            {formatValue(selectedFileInfo.id)}
                                        </SeaGridCell>
                                        <SeaGridCell label="Name" w="50">
                                            {formatValue(selectedFileInfo.name)}
                                        </SeaGridCell>
                                        <SeaGridCell label="Type" w="50">
                                            {formatValue(selectedFileInfo.type)}
                                        </SeaGridCell>
                                        <SeaGridCell label="Versions" w="50">
                                            {selectedFileInfo.sizes && Object.keys(selectedFileInfo.sizes).join(',')}
                                        </SeaGridCell>
                                        <SeaGridCell label="States" w="50">
                                            File={formatValue(selectedFileInfo.fileState)}
                                            Storage={formatValue(selectedFileInfo.storageState)}
                                            Refs={formatValue(selectedFileInfo.refsState)}
                                        </SeaGridCell>
                                        <SeaGridCell label="Example Value (reconstructed)" w="50">
                                            {`${selectedFileInfo.storageState}${selectedFileInfo.id}.${selectedFileInfo.ext}`}
                                        </SeaGridCell>
                                    </SeaGrid>
                                </>
                            }
                            <div className="end-info-line"></div>
                            <div>
                                <pre style={{ whiteSpace: 'pre-wrap' }}>
                                    {JSON.stringify(fullFileInfo, undefined, 4)}
                                </pre>
                            </div>
                            <div className="end-info-line"></div>
                            <div>
                                <pre style={{ whiteSpace: 'pre-wrap' }}>
                                    {JSON.stringify(selectedFileInfo, undefined, 4)}
                                </pre>
                            </div>
                        </SeaModal>
                    </>
                }
                {stats && tab !== 'Listing' &&
                    <div className={`reporting-grid max-2-graphs`}>
                        {tab === 'Collections' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="Collection Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.collectionsByCount}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                        {tab === 'Collections' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="Collection MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.collectionsBySize}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                        {tab === 'File Extensions' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="File Extension Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.extByCount}
                                hashNamesForColours={true}
                            />
                        }
                        {tab === 'File Extensions' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="File Extension MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.extBySize}
                                hashNamesForColours={true}
                            />
                        }
                        {tab === 'File Types' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="File Type Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.fileTypesByCount}
                                hashNamesForColours={true}
                                yLabelWidth={75}
                            />
                        }
                        {tab === 'File Types' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="File Type MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.fileTypesBySize}
                                hashNamesForColours={true}
                                yLabelWidth={75}
                            />
                        }
                        {tab === 'File Versions' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="File Version Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.fileVersionsByCount}
                                hashNamesForColours={true}
                                yLabelWidth={25}
                            />
                        }
                        {tab === 'File Versions' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="File Version MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.fileVersionsBySize}
                                hashNamesForColours={true}
                                yLabelWidth={25}
                            />
                        }
                        {tab === 'Monthly' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="Monthly Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.monthsByCount}
                                hashNamesForColours={true}
                            />
                        }
                        {tab === 'Monthly' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="Monthly MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.monthsBySize}
                                hashNamesForColours={true}
                            />
                        }
                        {tab === 'Licensees' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="Licensee Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.licenseesByCount}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                        {tab === 'Licensees' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="Licensee MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.licenseesBySize}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                        {tab === 'Problems' &&
                            <SeaHorizontalBarGraph
                                n={1}
                                title="Problem Files"
                                mode="modal"
                                forAdmin={true}
                                data={stats.problemsByCount}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                        {tab === 'Problems' &&
                            <SeaHorizontalBarGraph
                                n={2}
                                title="Problem MB"
                                mode="modal"
                                forAdmin={true}
                                data={stats.problemsBySize}
                                hashNamesForColours={true}
                                yLabelWidth={160}
                            />
                        }
                    </div>
                }
                {stats && tab === 'Problems' &&
                    <>
                        <div style={{ paddingTop: '24px', fontSize: '16px' }}>
                            <b>Problem counts</b> <i>(Copy and paste into monthly audit)</i>
                        </div>
                        <div>
                            <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {stats.problemsByCount.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                                    return `${item.name}: ${item.value?.toLocaleString()}\n`;
                                })}
                            </pre>
                        </div>
                        <div style={{ padding: '16px 0px 12px', fontSize: '16px' }}>
                            <b>adminFixFiles</b>
                        </div>
                        <div>
                            {fixes.map((fix) => {
                                return (
                                    <div key={fix.id} className="file-problems columns">
                                        <div>
                                            <SeaCheckbox
                                                checked={problemsToFix.includes(fix.id)}
                                                setChecked={(checked) => {
                                                    setProblemsToFix((current) => {
                                                        const newArray = [...current];
                                                        for (let i = 0; i < newArray.length; i++) {
                                                            if (newArray[i] === fix.id) {
                                                                newArray.splice(i, 1);
                                                                break;
                                                            }
                                                        }
                                                        if (checked) {
                                                            newArray.push(fix.id);
                                                        }
                                                        return newArray;
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div>{fix.id}</div>
                                        <div>{fix.notes}</div>
                                        <div>{fix.solution}</div>
                                    </div>
                                );
                            })}
                            <div style={{ paddingTop: '8px' }}>
                                <SeaButton
                                    onClick={(e) => {
                                        const startTime = Date.now();
                                        return httpsCallable(
                                            functions,
                                            'adminFixFileProblems',
                                            { timeout: 10 * 60 * 1000 }
                                        )({
                                            problemsToFix: problemsToFix
                                        }).then((result: any) => {
                                            console.log('adminFixFileProblems result', result);
                                            return Promise.resolve(result);
                                        }).catch((error) => {
                                            console.error('adminFixFileProblems error', error);
                                        }).finally(() => {
                                            console.log(`Took ${Math.round((Date.now() - startTime) / 1000)}s`);
                                        });
                                    }}
                                >
                                    Fix Problems!
                                </SeaButton>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default FilesAdmin;
