import * as React from "react";

function SvgBackSlash(props) {
  return (
    <svg
      width={24}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.543 18.778c.246.211.598.14.773-.105l.703-.88a.526.526 0 00-.105-.773L2.242 1.024a.526.526 0 00-.773.106l-.704.879a.526.526 0 00.106.773l20.672 15.996z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBackSlash;
