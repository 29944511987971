import React, { useState, useMemo } from 'react';
import { formatValue, } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../lib/permissions';
import { WriteBatch } from "firebase/firestore";
import { renderCategoryName } from '../../../../lib/categories';
import { SplittableBatch } from '../../../../lib/firebase';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SparePart } from '../../../../shared-state/VesselMaintenance/spareParts';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import EditSparePart from '../EditSparePart/EditSparePart';

interface ViewSparePartProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: SparePart,
    level: number,
}

const ViewSparePart: React.FC<ViewSparePartProps> = ({showModal, setShowModal, selectedItem, level}) => {
    const contacts = sharedState.contacts.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const [editSparePartModal, setEditSparePartModal] = useState<{
        show: boolean,
        itemToUpdate: SparePart | undefined,
        level: number
    }>({
        show: false, itemToUpdate: undefined, level: 1
    });
    const [criticalEquipmentList, setCriticalEquipmentList] = useState<string>('');

    const onEditItem = () => {
        setEditSparePartModal({
            show: true,
            itemToUpdate: selectedItem,
            level: level+1
        });
    };

    const equipmentList = useMemo(() => {
        if (selectedItem?.equipmentIds) {
            let s = '';
            let sc = '';
            selectedItem.equipmentIds.forEach((id: string) => {
                if (s.length > 0) {
                    s += ', ';
                }
                const item = equipment?.byId[id];
                if (item === undefined) {
                    return;
                }
                s += item.equipment;
                if (item.state === 'deleted') {
                    s += ' (deleted)';
                }
                if (item.isCritical) {
                    if (sc.length > 0) {
                    sc += ', ';
                }
                    sc += item.equipment;
                }
            });
            setCriticalEquipmentList(sc);
            return s;
        }
        return '';
    }, [selectedItem, equipment]);

    return (
        <SeaModal
            title={`Item - ${selectedItem?.item}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
            level={level}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Quantity" w="33">
                    {formatValue(selectedItem?.quantity)}
                </SeaGridCell>
                <SeaGridCell label="Minimum Quantity" w="33">
                    {formatValue(selectedItem?.minQuantity)}
                </SeaGridCell>
                <SeaGridCell label="Order Quantity" w="33">
                    {formatValue(selectedItem?.orderQuantity)}
                </SeaGridCell>
                <SeaGridCell label="Part Number" w="33">
                    {formatValue(selectedItem?.partNum)}
                </SeaGridCell>
                <SeaGridCell label="System" w="33">
                    {formatValue(renderCategoryName(selectedItem?.systemId, vesselSystems))}
                </SeaGridCell>
                <SeaGridCell label="Equipment" w="33">
                    {formatValue(equipmentList)}
                </SeaGridCell>
                <SeaGridCell label="Location" w="33">
                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                </SeaGridCell>
                <SeaGridCell label="Critical Equipment" w="50" help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}>
                    {formatValue(criticalEquipmentList)}
                </SeaGridCell>
                <SeaGridCell label="Model" w="50">
                    {formatValue(selectedItem?.model)}
                </SeaGridCell>
                <SeaGridCell label="Manufacturer" w="33">
                    {formatValue(selectedItem?.manufacturer)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Location Description" w="100">
                    {formatValue(selectedItem?.locationDescription)}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="Supplier Contact" w="100">
                    <div style={{whiteSpace: 'pre-line'}}>
                        {(contacts?.byId && selectedItem?.contactIds && selectedItem?.contactIds?.length > 0) ? (
                            selectedItem.contactIds.map((contactId: string) => {
                                const contact = contacts.byId[contactId];
                                return `${contact.name}${contact.company ? ` (${contact.company})` : ''}${contact.number ? `, ${contact.number}` : ''}${contact.email ? `, ${contact.email}` : ''}`
                            }).join('\n \n')
                        ) : (
                            '-'
                        )}
                    </div>
                </SeaGridCell>
            </SeaGrid>
            
            <div className="view-modal-buttons">
                <RequirePermissions
                    role="sparePartsList"
                    level={permissionLevels.EDIT}
                >
                    <div>
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit {vessel?.isShoreFacility ? 'Item' : 'Part'}</SeaButton>
                    </div>
                    <RequirePermissions
                    role="sparePartsList"
                    level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    deleteIfConfirmed(
                                        'spareParts',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'spareParts');
                                            setShowModal(false);
                                        },
                                        'spare part',
                                        `${selectedItem.item}${ selectedItem.quantity ? ` (${selectedItem.quantity})` : '' }`,
                                        [selectedItem.vesselId]
                                    );
                                }}
                            >
                                Delete {vessel?.isShoreFacility ? 'Item' : 'Part'}
                            </SeaLinkButton>
                        </div>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {showModal && editSparePartModal.itemToUpdate &&
                <EditSparePart
                    showModal={editSparePartModal.show}
                    setShowModal={(showModal: boolean) => setEditSparePartModal({
                        ...editSparePartModal,
                        show: false
                    })}
                    level={editSparePartModal.level}
                    itemToUpdate={editSparePartModal.itemToUpdate}
                />
            }
        </SeaModal>
    );
};

export default ViewSparePart;
