import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { Equipment } from "../../VesselMaintenance/equipment";

export type ReportingEquipmentByIdData = {
    [id: string]: Equipment
};

export const useReportingEquipmentByIds = (
    isActive: boolean,
    selectedVesselIds: string[]
) => {
    const [reportingEquipmentById, setReportingEquipmentById] = useState<ReportingEquipmentByIdData>();

    useEffect(() => {
        setReportingEquipmentById(undefined);
        if (
            isActive &&
            selectedVesselIds?.length
        ) {
            return setupArrayQueryListener(
                'equipment', // what
                collection(firestore, 'equipment'),
                [where('state', 'in', ['active', 'deleted'])], // baseConstraints
                'vesselId',
                'in',
                selectedVesselIds,
                [], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const byId = {} as {
                        [id: string]: Equipment
                    };
                    docs.forEach((doc) => {
                        byId[doc.id] = doc.data() as Equipment;
                    });
                    setReportingEquipmentById(byId);
                }
            );
        }
    }, [isActive, selectedVesselIds]);

    return reportingEquipmentById;

};
