import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";

//
// Loads errorReports for analysis.
// This can only be used by superAdmin.
//

export type ErrorReport = any;

export const useErrorReports = (whenFrom: number, whenTo: number) => {
    const [errorReports, setErrorReports] = useState<ErrorReport[]>();

    useEffect(() => {
        setErrorReports(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'errorReports'),
                where('whenReported', '>=', whenFrom),
                where('whenReported', '<=', whenTo),
                orderBy('whenReported', 'desc')
            ),
            (snap) => {
                const reports = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    };
                });
                setErrorReports(reports);
            },
            (error) => {
                console.log(`error getting errorReports`, error);
            }
        );
    }, [whenFrom, whenTo]);

    return errorReports;
};
