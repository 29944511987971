import React, { useState, useRef, useCallback } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc } from "firebase/firestore";
import { cleanupStringArray, hasArrayChanged, preventMultiTap } from '../../../../lib/util';
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { LicenseeSettings } from '../../../../shared-state/Core/licenseeSettings';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';

interface EditRiskRegisterSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditRiskRegisterSettings: React.FC<EditRiskRegisterSettingsProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const riskCategories = sharedState.riskCategories.use(showModal);
    const formRef = useRef<HTMLFormElement>(null);

    const [tab, setTab] = useState("riskCategories");
    const [riskCategoryIds, setRiskCategoryIds] = useState<string[]>();
    const [riskCategoryNames, setRiskCategoryNames] = useState<string[]>();
    const [riskPrompts, setRiskPrompts] = useState<string[]>();
    const [controlPrompts, setControlPrompts] = useState<string[]>();

    const onOpened = () => {
        if (riskCategories) {
            setRiskCategoryIds(makeCategoryIdsForEditing(riskCategories));
            setRiskCategoryNames(makeCategoryNamesForEditing(riskCategories));
        }
        setRiskPrompts(licenseeSettings?.riskRegister?.riskPrompts ? licenseeSettings.riskRegister.riskPrompts : []);
        setControlPrompts(licenseeSettings?.riskRegister?.controlPrompts ? licenseeSettings.riskRegister.controlPrompts : []);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (preventMultiTap('editRiskRegister')) { return; }
        if (!licenseeId) {
            showToast('Licensee not found');
            return;
        }

        const action = traceAction('licenseeSettings', 'update') as Action;
        action.docId = licenseeId;
        const batch = splittableBatch(firestore, 20 - 0);

        saveCategoryChanges(
            batch,
            riskCategoryIds,
            riskCategoryNames,
            undefined,
            riskCategories,
            'riskCategories',
            'licenseeId',
            licenseeId,
            action
        );

        batch.set(
            doc(firestore, 'licenseeSettings', licenseeId),
            {
                riskRegister: {
                    riskPrompts: cleanupStringArray(riskPrompts)?.sort(),
                    controlPrompts: cleanupStringArray(controlPrompts)?.sort(),
                },
                whenUpdated: action.whenAction,
                updatedBy: userId
            } as Pick<LicenseeSettings, 'riskRegister' | 'whenUpdated' | 'updatedBy'>,
            { merge: true }
        );

        action.data = {
            riskCategoryIds,
            riskCategoryNames,
            riskPrompts,
            controlPrompts
        };
        action.save('Update risk assessments settings', batch);

        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error: any) => {
            action.reportError(error.message, error);
        });
        setShowModal(false);
        showToast(`Risk assessment settings updated`);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveCategoriesChanged(riskCategoryIds, riskCategoryNames, riskCategories) ||
            hasArrayChanged(riskPrompts, licenseeSettings?.riskRegister?.riskPrompts) ||
            hasArrayChanged(controlPrompts, licenseeSettings?.riskRegister?.controlPrompts)
        );
    }, [riskCategoryIds, riskCategoryNames, riskCategories, riskPrompts, controlPrompts, licenseeSettings]);

    return (
        <SeaModal
            title="Risk Assessment Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="riskCategories" mode="forms">Risk Categories</SeaTab>
                    <SeaTab tab="riskPrompts" mode="forms">Risk Prompt Words</SeaTab>
                    <SeaTab tab="controlPrompts" mode="forms">Control Prompt Words</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Risk Assessment Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="riskCategories" selectedTab={tab}>
                    <SeaInputList
                        name="riskCategories"
                        label="Risk Categories"
                        maxWidth="500px"
                        ids={riskCategoryIds}
                        setIds={setRiskCategoryIds}
                        values={riskCategoryNames}
                        setValues={setRiskCategoryNames}
                        addNewText="Add Category"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="riskPrompts" selectedTab={tab}>
                    <SeaInputList
                        name="riskPrompts"
                        label="Risk Prompt Words"
                        maxWidth="500px"
                        values={riskPrompts}
                        setValues={setRiskPrompts}
                        addNewText="Add Prompt"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="controlPrompts" selectedTab={tab}>
                    <SeaInputList
                        name="controlPrompts"
                        label="Control Prompt Words"
                        maxWidth="500px"
                        values={controlPrompts}
                        setValues={setControlPrompts}
                        addNewText="Add Prompt"
                        confirmDelete={true}
                    />
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditRiskRegisterSettings;
