import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, addInterval, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { TrainingTask } from '../../../../shared-state/Crew/trainingTasks';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import { TrainingTaskReport } from '../../../../shared-state/Crew/trainingTaskReports';

interface EditTrainingTaskProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: TrainingTask,
    vesselId?: string, // default vessel in form
    taskHistory?: TrainingTaskReport[]
}

const EditTrainingTask: React.FC<EditTrainingTaskProps> = ({showModal, setShowModal, itemToUpdate, level, vesselId, taskHistory}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (!itemToUpdate) {
            return {
                vesselId: vesselId ? vesselId : '',
                task: '',
                interval: ''
            };
        } else {
            return {
                vesselId: itemToUpdate?.vesselId ? ''+itemToUpdate.vesselId : '',
                task: itemToUpdate?.task ? ''+itemToUpdate.task : '',
                interval: itemToUpdate?.interval ? ''+itemToUpdate.interval : '',
            };
        }
    }, [itemToUpdate, vesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().max(200).required(),
            task: Yup.string().max(500).required(),
            interval: Yup.string().max(200).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('trainingTask')) { return; }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const action = traceAction('trainingTasks') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'trainingTasks', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            task: data.task,
                            interval: data.interval,
                            whenDue: itemToUpdate.whenLastCompleted ? addInterval(itemToUpdate.whenLastCompleted, data.interval) : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'trainingTasks', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'trainingTasks',
                        itemToUpdate.id,
                        data.task,
                        [itemToUpdate.vesselId]
                    );

                    // If interval changed - Need to recalculate whenDue fir relevant reports
                    if (itemToUpdate.interval !== data.interval) {
                        taskHistory?.forEach((report, index) => {
                            batch.set(
                                doc(firestore, 'trainingTaskReports', report.id),
                                {
                                    whenDue: addInterval(report.whenCompleted, data.interval),
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                        });
                        onCollectionUpdated(batch, 'trainingTaskReports');
                    };

                } else {
                    const newRef = doc(collection(firestore, 'trainingTasks'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(
                        newRef,
                        {
                            licenseeId: licenseeId,
                            addedBy: userId,
                            whenAdded: action.whenAction,
                            vesselId: data.vesselId,
                            task: data.task,
                            interval: data.interval,
                            state: 'active',
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        }
                    );

                    saveFileRefs(batch, files, 'trainingTasks', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'trainingTasks',
                        newRef.id,
                        data.task,
                        [data.vesselId]
                    );
                }
                
                onCollectionUpdated(batch, 'trainingTasks');

                action.data = {
                    data,
                    files: seaFilesToValue(files)
                };
                action.save(`${itemToUpdate ? 'Update' : 'Add'} training task ${data?.task} for vessel ${data?.vesselId}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload training task file(s)`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        itemToUpdate
                    });
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files]);


	useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Task' : 'Add New Task'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="thin"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelect
                                disabled={itemToUpdate ? true : false}
                                label="Vessel"
                                name="vesselId"
                                value={values.vesselId}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="12">
                          	<SeaInput
								label="Task"
								name="task"
								value={values.task}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.task ? errors.task : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectInterval
                                label="Retraining Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval ? errors.interval : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="trainingTasks"
                                field="files"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Training Task' : 'Save New Training Task'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditTrainingTask;
