import React from 'react';
import './LexButtonDivider.css';

interface LexButtonDividerProps {
}

const LexButtonDivider: React.FC<LexButtonDividerProps> = ({}) => {
    return (
        <div className="lex-button-divider"></div>
    );
};

export default LexButtonDivider;
