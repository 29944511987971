import React, { useState, useEffect, useMemo } from 'react';
import { SplittableBatch } from '../../../../lib/firebase';
import { WriteBatch } from "firebase/firestore";
import { formatTextAreaText, formatDate, formatInterval, formatValue, formatEmailReminder, warnDays } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { permissionLevels, canEdit } from '../../../../lib/permissions';
import { renderCategoryName } from '../../../../lib/categories';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { SafetyEquipmentTaskCompleted, useCompletedSafetyEquipmentItems } from '../../../../shared-state/VesselSafety/completedSafetyEquipmentItems';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SafetyEquipmentItem } from '../../../../shared-state/VesselSafety/safetyEquipmentItems';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditSafetyEquipment from '../EditSafetyEquipmentExpiry/EditSafetyEquipmentExpiry';
import CompleteSafetyEquipment from '../CompleteSafetyEquipmentExpiry/CompleteSafetyEquipmentExpiry';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import './ViewSafetyEquipmentExpiry.css';


interface ViewSafetyEquipmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: SafetyEquipmentItem,
}

const ViewSafetyEquipment: React.FC<ViewSafetyEquipmentProps> = ({showModal, setShowModal, selectedItem}) => {
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const completedSafetyItems = useCompletedSafetyEquipmentItems(showModal ? selectedItem : undefined);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showCompleteItemModal, setShowCompleteItemModal] = useState(false);
    const [historyItemToUpdate, setHistoryItemToUpdate] = useState<SafetyEquipmentTaskCompleted>();

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onCompleteItem = (item?: SafetyEquipmentTaskCompleted) => {
        setHistoryItemToUpdate(item)
        setShowCompleteItemModal(true);
    };
    const canEditItem = useMemo(() => {
        return canEdit('safetyEquipmentList')
    }, []);

    if (!selectedItem) {
        return null;
    }
    
    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        Safety Check Expiry - {renderCategoryName(selectedItem?.itemId, vesselSafetyItems)}
                    </span>
                    {selectedItem?.whenDue &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenDue}
                            warnDays={warnDays.safetyEquipmentExpiries[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
        >

            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>

            <SeaGrid>
                {selectedItem?.type !== 'nonExpiring' &&
                    <SeaGridCell label={`${selectedItem?.type === 'servicable' ? 'Service' : 'Expiry'} task`} w="100">
                        {formatValue(formatTextAreaText(selectedItem?.description))}
                    </SeaGridCell>
                }
                <SeaGridCell label="Type" w="50" capitalize>
                    {selectedItem?.type === 'nonExpiring' ? 'Non Expiring' : selectedItem?.type}
                </SeaGridCell>
                <SeaGridCell label="Quantity" w="50">
                    {formatValue(selectedItem?.quantity)}
                </SeaGridCell>
                <SeaGridCell label="Location" w="50">
                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                </SeaGridCell>
                 <SeaGridCell label="Critical Equipment" w="50" help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}>
                    {formatValue(vesselSafetyItems?.byId[selectedItem?.itemId]?.isCritical ? 'Yes' : 'No')}
                </SeaGridCell>
                {selectedItem?.interval &&
                    <SeaGridCell label="Interval" w="50">
                        {formatInterval(selectedItem.interval)}
                    </SeaGridCell>
                }
                {selectedItem?.type !== 'nonExpiring' &&
                    <>
                        <SeaGridCell label="Notification" w="50">
                        {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                        </SeaGridCell>
                        <SeaGridCell label={selectedItem?.type === 'servicable' ? 'Service Due' : 'Expiry'} w="50">
                            {formatValue(formatDate(selectedItem?.whenDue))}
                        </SeaGridCell>
                    </>
                }
            </SeaGrid>
                                
            <div className="view-modal-buttons">
                <RequirePermissions
                    role="safetyEquipmentList"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}>
                        <SeaIcon icon="edit" slot="start" />Edit Safety Check Expiry
                    </SeaButton>
                    <RequirePermissions
                        role="safetyEquipmentList"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'safetyEquipmentItems',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'safetyEquipmentItems');
                                        setShowModal(false);
                                    },
                                    'equipment',
                                    renderCategoryName(selectedItem?.itemId, vesselSafetyItems),
                                    [selectedItem.vesselId]
                                );
                            }}
                        >
                            Delete Safety Check Expiry
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {selectedItem?.type !== 'nonExpiring' && (
                <>
                    <div className="end-info-line"></div>
                    <div className="columns wrap">
                        <h2>{selectedItem?.type === 'servicable' ? 'Service History' : 'Expiry Replacement History'}</h2>
                        <RequirePermissions
                            role="safetyEquipmentList"
                            level={permissionLevels.EDIT}
                        >
                            <div className="right">
                                <SeaButton zone="white" onClick={(e) => onCompleteItem()}><SeaIcon icon="tick" slot="start" />Complete Safety Check</SeaButton>
                            </div>
                        </RequirePermissions>
                    </div>

                    <SeaNoData
                        dataName="item"
                        isHistory={true}
                        isLoading={!completedSafetyItems}
                        hasNoData={completedSafetyItems && completedSafetyItems.length === 0}
                    />
                    <div className={`safety-equipment-history ${(completedSafetyItems?.length || 0) > 0 ? 'reveal' : 'conceal'}`}>
                        <div className="sea-row-history headings-history">
                            <div>Date</div>
                            <div>By</div>
                            <div>Notes</div>
                            <div></div>
                        </div>
                        {mapArrayWithLimit(completedSafetyItems, (item: SafetyEquipmentTaskCompleted) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row-history ${canEditItem && 'clickable'}`} onClick={(e) => canEditItem &&onCompleteItem(item)}>
                                    <div>{formatDate(item.whenCompleted)}</div>
                                    <div className="truncate">{renderFullNameForUserId(item.completedBy)}</div>
                                    <div className="truncate">{formatValue(formatTextAreaText(item.notes))}</div>
                                    <div><SeaFileImage files={item.files} size="tiny"/></div>
                                </div>
                            )
                        })}
                        {limitTriggerElement}
                    </div>

                </>
            )}
            
            {showModal &&
                <>
                    <EditSafetyEquipment
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <CompleteSafetyEquipment
                        showModal={showCompleteItemModal}
                        setShowModal={setShowCompleteItemModal}
                        setShowParentModal={setShowModal}
                        selectedItem={selectedItem}
                        historyItemToUpdate={historyItemToUpdate}
                        completedSafetyItems={completedSafetyItems}
                        level={2}
                    />
                </>
            }

        </SeaModal>
    );
};

export default ViewSafetyEquipment;
