import * as React from "react";

function SvgHealthSafety(props) {
  return (
    <svg
      width={20}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#HealthSafety_svg__clip0_5010_21)" fill="currentColor">
        <path d="M4.947 14.855h2.031v1.95c0 .68.576 1.234 1.285 1.234h3.512c.708 0 1.284-.553 1.284-1.234v-1.95h2.03c.71 0 1.285-.554 1.285-1.234v-3.373c0-.68-.576-1.234-1.284-1.234h-2.031v-1.95c0-.68-.576-1.234-1.284-1.234H8.263c-.709 0-1.285.553-1.285 1.234v1.95h-2.03c-.709 0-1.285.554-1.285 1.234v3.373c0 .68.576 1.234 1.284 1.234zm7.459-3.942h2.03v2.082h-2.03c-.708 0-1.284.553-1.284 1.233v1.95H8.954v-1.95c0-.68-.576-1.233-1.284-1.233H5.639v-2.082h2.03c.709 0 1.285-.554 1.285-1.234V7.73h2.168v1.95c0 .68.576 1.234 1.284 1.234z" />
        <path d="M19.25 1.95h-.001L10.209.022 10.204.02a1.05 1.05 0 00-.406 0L9.79.02.75 1.951a.935.935 0 00-.75.917v11.287a9.323 9.323 0 001.383 4.865 9.834 9.834 0 003.759 3.521l4.38 2.334.001.002a.991.991 0 00.958.002l4.375-2.338a9.834 9.834 0 003.76-3.521 9.323 9.323 0 001.382-4.866V2.868a.934.934 0 00-.75-.917h.002zm-1.172 1.652v10.552a7.53 7.53 0 01-1.116 3.933 7.947 7.947 0 01-3.037 2.849L10 23.03l-3.925-2.095-.011-.006a7.943 7.943 0 01-3.027-2.841 7.535 7.535 0 01-1.115-3.934V3.603l8.077-1.734 8.077 1.734h.002z" />
      </g>
      <defs>
        <clipPath id="HealthSafety_svg__clip0_5010_21">
          <path fill="#fff" d="M0 0h20v25H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgHealthSafety;
