import React, { useState, useEffect } from 'react';
import { useCamera } from '@ionic/react-hooks/camera';
import { useFilePicker } from 'use-file-picker';
import { IonItem, IonList, IonPopover, isPlatform } from '@ionic/react';
import { formatDatetimeISO } from '../../lib/util';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { SeaFile } from '../../lib/files';

interface SeaFileSelectorProps {
    clickEvent?: React.MouseEvent, // How the selector is triggered
    onFilesSelected: (files: SeaFile[]) => void,
    collection?: string, // collection this file will be attached to
    field?: string,      // collection.field this will be attached to
    allowMultiple?: boolean,
    onlyAllowImages?: boolean,
    maxUploadMB?: number
}

const SeaFileSelector: React.FC<SeaFileSelectorProps> = ({
    clickEvent,
    onFilesSelected,
    collection,
    field,
    allowMultiple = true,
    onlyAllowImages = false,
    maxUploadMB = 100
}) => { // eslint-disable-line no-unused-vars
    const { getPhoto } = useCamera();
    const [popoverState, setPopoverState] = useState({ showPopover: false, event: undefined });

    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        multiple: allowMultiple,
        readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        accept: onlyAllowImages ? ['image/*'] : '*',
        //accept: ['.jpeg', '.jpg', '.pdf', '.txt'],
        limitFilesConfig: { min: 1, max: 20 },
        // minFileSize: 1, // in megabytes
        maxFileSize: maxUploadMB, // in megabytes
        // maxImageHeight: 1024, // in pixels
        // minImageHeight: 1024,
        // maxImageWidth: 768,
        // minImageWidth: 768
        // readFilesContent: false, // ignores file content
    });

    const closePopover = () => {
        setPopoverState({ showPopover: false, event: undefined });
    }
    const openPopover = (e: any) => {
        // e.persist(); // No longer needed in React 17+
        setPopoverState({ showPopover: true, event: e });
    }

    //const onAdd = (e: React.MouseEvent) => {
    useEffect(() => {
        if (clickEvent) {
            if (isPlatform('hybrid')) {
                openPopover(clickEvent);
            } else {
                openFileSelector();
            }
        }
    }, [clickEvent]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle results of useFilePicker
    useEffect(() => {
        if (filesContent?.length > 0 && !loading) {
            //const _files = [...files];
            const _files = [];
            for (let i = 0; i < filesContent.length; i++) {
                const fileContent = filesContent[i];
                const dotIndex = fileContent.name.lastIndexOf('.');

                if (dotIndex === -1 || dotIndex >= fileContent.name.length - 1) {
                    alertMessage(`${fileContent.name} is an unusual file name. Needs to be something like *.*`);
                    return;
                }
                const ext = fileContent.name.substring(dotIndex + 1);
                const contentType = fileContent.content.substring(5, fileContent.content.indexOf(';'));
                const file: SeaFile = {
                    collection: collection,
                    field: field,
                    name: fileContent.name,
                    ext: ext,
                    contentType: contentType,
                    lastModified: fileContent.lastModified,
                    unique: (''+Math.random()).substring(2,12),
                    base64: fileContent.content.substring(fileContent.content.indexOf(',') + 1)
                };

                _files.push(file);
            }
            filesContent.splice(0, filesContent.length);
            // setFiles(_files);
            onFilesSelected(_files);
        }
    }, [filesContent, loading, collection, field]);

    // Handle errors
    useEffect(() => {
        if (errors?.length > 0) {
            console.log('Errors', errors);
            let s = '';
            errors.forEach((error) => {
                if (error.fileSizeToolarge) s += `${error.name} is too large. Max size is ${maxUploadMB}MB.<br>`;
                if (error.maxLimitExceeded) s += `Too many files!<br>`;
            });
            if (s.length > 4) s = s.substring(0, s.length - 4);
            alertMessage(s);
        }
    }, [errors, maxUploadMB]);

    const getFile = (takePhoto: boolean) => {
        // if only allow images then could just use
        getPhoto({
            //allowEditing: true,
            resultType: 'base64',
            source: takePhoto ? 'CAMERA' : 'PHOTOS',
            quality: 80,
            width: 2048,
            height: 2048,
            preserveAspectRatio: true
        }).then((result) => {
            const file: SeaFile = {
                collection: collection,
                field: field,
                ext: result.format
            };
            if (result.dataUrl) { // Todo: Why is this even here?
                file.src = result.dataUrl;
            } else if (result.webPath) { // Todo: Why is this even here?
                file.src = result.webPath;
            } else if (result.base64String) {
                file.base64 = result.base64String;
                // file.src = 'data:image/jpeg;base64, ' + result.base64String;
            }
            file.name = `Photo${formatDatetimeISO()}`;
            file.unique = (''+Math.random()).substring(2,12);
            const _files = []; // [...files];
            _files.push(file);
            //setFiles(_files);
            onFilesSelected(_files);
        }).catch((error) => {
            console.log('error getPhoto', error)
        })
    };

    return (<>
        <IonPopover
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={() => closePopover()}
        >
            <IonList lines="none">
                <IonItem button onClick={(e) => {openFileSelector(); closePopover()}}>Browse Files</IonItem>
                {isPlatform('android') &&
                    <IonItem button onClick={(e) => {getFile(false); closePopover()}}>Browse Photos / Albums</IonItem>
                }
                <IonItem button onClick={(e) => {getFile(true); closePopover()}}>Take a Photo</IonItem>
            </IonList>
        </IonPopover>
    </>);
};

export default SeaFileSelector;
