import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import InfoCirclePDF from '../../assets/svg/InfoCirclePDF';
import { pdfColours, pdfStyles } from '../../lib/pdf';

interface SeaNoDataProps {
    dataName: string,
    hasNoData?: boolean
    isUsingFilter?: boolean,
    isHistory?: boolean
}


const SeaNoDataPDF: React.FC<SeaNoDataProps> = ({ dataName, hasNoData, isUsingFilter, isHistory }) => {
    if (hasNoData) {
        return (
            <View style={[pdfStyles.row, { alignItems: 'center' }]}>
                <InfoCirclePDF color={pdfColours.noData} />
                <Text style={pdfStyles.noDataText}>
                    {
                        isUsingFilter ? 
                            `No matching ${dataName} were found`
                        : isHistory ?
                            `This ${dataName} has no history`
                        : `You currently have no ${dataName}`
                    }
                </Text>
            </View>
        );
    }
    return null;
}

export default SeaNoDataPDF;