import React, { useMemo, useState } from 'react';
import { formatDatetime, formatDatetimeISO, getDayOffsetMillis, toMillis } from '../../../../lib/util';
import { ErrorReport, useErrorReports } from '../../../../shared-state/Admin/errorReports';
import { useAllUsers } from '../../../../shared-state/Admin/allUsers';
import { IonSelectOption, IonSpinner } from '@ionic/react';
import { useAllVessels } from '../../../../shared-state/Admin/allVessels';
import SeaSearchbar from '../../../../components/SeaSearchbar/SeaSearchbar';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import ViewErrorReportModal from '../../../../modals/Admin/ViewErrorReportModal/ViewErrorReportModal';
import './ErrorReports.css';

const reportTypes = [
    'Unknown',
    'Fake Firestore Error (Seen)',
    'Fake Firestore Error (Ignored)',
    'Firestore Error',
    'Basic Error',
    'Crash',
    '?'
];
const defaultSelectedTypes = [
    'Unknown',
    // 'Fake Firestore Error (Seen)',
    // 'Fake Firestore Error (Ignored)',
    'Firestore Error',
    'Basic Error',
    'Crash',
    '?'
];

const orderBys = [
    'When Reported',
    'What Failed',
    'Reason'
];

const ErrorReports: React.FC<{visible: boolean}> = ({visible: boolean}) => {
    const [searchText, setSearchText] = useState('');
    const [whenFrom, setWhenFrom] = useState(formatDatetimeISO(getDayOffsetMillis(-7)));
    const [whenTo, setWhenTo] = useState(formatDatetimeISO());
    const [selectedTypes, setSelectedTypes] = useState(defaultSelectedTypes);
    const [orderBy, setOrderBy] = useState('When Reported');
    const [showErrorReportModal, setShowErrorReportModal] = useState(false);
    const [selectedErrorReport, setSelectedErrorReport] = useState<ErrorReport>();

    const errorReports = useErrorReports(
        toMillis(whenFrom),
        toMillis(whenTo)
    );

    const allUsers = useAllUsers();
    const allVessels = useAllVessels();

    const selectedTypeOptions = useMemo(() => {
        return reportTypes.map((type) => {
            return {
                id: type,
                name: type
            };
        });
    }, []);

    const filteredErrorReports = useMemo(() => {
        const filtered = [] as any[];
        errorReports?.forEach((report) => {
            const o = {
                ...report
            } as any;

            // Work out type, bgColor & screenColor
            switch (report.state) {
                case 'unknown':
                    o.type = 'Unknown';
                    o.bgColor = '#c4e5e8';
                    o.screenColor = '#ffffff';
                    break;
                case 'tracedFakeError':
                    o.type = report.shownToUser ? 'Fake Firestore Error (Seen)' : 'Fake Firestore Error (Ignored)';
                    o.bgColor = report.shownToUser ? '#f6ddc5' : '#e8e8e8';
                    o.screenColor = report.shownToUser ? '#ff0000' : 'transparent';
                    break;
                case 'tracedError':
                    o.type = 'Firestore Error';
                    o.bgColor = '#ffe3e3';
                    o.screenColor = '#ff0000';
                    break;
                case 'untracedError':
                    o.type = 'Basic Error'
                    o.bgColor = '#e7dbf8';
                    o.screenColor = '#ff0000';
                    break;
                case 'unexpectedCrash':
                    o.type = 'Crash';
                    o.bgColor = '#fffacc';
                    o.screenColor = '#ffe400';
                    break;
                default:
                    o.type = '?';
                    o.bgColor = '#d3f1f4';
                    o.screenColor = 'transparent';
            }

            o.licensee = '';
            if (allUsers?.byId[report.licenseeId]) {
                o.licensee = `${allUsers?.byId[report.licenseeId]?.firstName} ${allUsers?.byId[report.licenseeId]?.lastName}`;
            }
            o.user = '';
            if (allUsers?.byId[report.userId]) {
                o.user = `${allUsers?.byId[report.userId]?.firstName} ${allUsers?.byId[report.userId]?.lastName}`;
            }
            o.vessel = '';
            if (allVessels?.byId[report.vesselId]) {
                o.vessel = allVessels.byId[report.vesselId]?.name;
            }

            // Filter out unwanted reports
            const terms = searchText.trim().toLowerCase();
            if (
                selectedTypes.includes(o.type) && (
                    terms === '' || (
                        o.type.toLowerCase().indexOf(terms) !== -1 ||
                        o.whatFailed.toLowerCase().indexOf(terms) !== -1 ||
                        o.reason.toLowerCase().indexOf(terms) !== -1 ||
                        o.user.toLowerCase().indexOf(terms) !== -1 ||
                        o.licensee.toLowerCase().indexOf(terms) !== -1 ||
                        o.version.indexOf(terms) !== -1 ||
                        o.build.indexOf(terms) !== -1 ||
                        (o.device?.platform && o.device.platform.toLowerCase().indexOf(terms) !== -1) ||
                        (o.device?.operatingSystem && o.device.operatingSystem.toLowerCase().indexOf(terms) !== -1) ||
                        (o.device?.osVersion && o.device.osVersion.toLowerCase().indexOf(terms) !== -1) ||
                        (o.vessel && o.vessel.toLowerCase().indexOf(terms) !== -1) ||
                        (o.reference?.toLowerCase() && o.reference.toLowerCase().indexOf(terms) !== -1)
                    )
                )
            ) {
                filtered.push(o);
            }
        });

        // Order by
        if (orderBy === 'What Failed') {
            filtered.sort((a, b) => {
                if (a.whatFailed && b.whatFailed) {
                    return a.whatFailed.localeCompare(b.whatFailed);
                } else if (a.whatFailed === undefined && b.whatFailed === undefined) {
                    return 0;
                } else if (a.whatField === undefined) {
                    return -1;
                } else {
                    return 1;
                }
            });
        } else if (orderBy === 'Reason') {
            filtered.sort((a, b) => {
                if (a.reason && b.reason) {
                    return a.reason.localeCompare(b.reason);
                } else if (a.reason === undefined && b.reason === undefined) {
                    return 0;
                } else if (a.reason === undefined) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        console.log('filteredErrorReports', filtered);
        return filtered;
    }, [errorReports, selectedTypes, orderBy, searchText, allUsers, allVessels]);

    return (
        <>
            <div className="error-reports columns">
                <div>
                    <SeaSearchbar
                        label="Search"
                        value={searchText}
                        setValue={setSearchText}
                    />
                </div>
                <div style={{ flex: '0 1 200px' }}>
                    <SeaDatetime
                        label="From"
                        value={whenFrom}
                        onchange={(e) => {
                            setWhenFrom(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 200px' }}>
                    <SeaDatetime
                        label="To"
                        value={whenTo}
                        onchange={(e) => {
                            setWhenTo(e.detail.value);
                        }}
                    />
                </div>
                <div style={{ flex: '0 1 200px' }}>
                    <SeaMultiSelect
                        label="Types"
                        values={selectedTypes}
                        setValues={setSelectedTypes}
                        //setValues={setForVesselIds}
                        options={selectedTypeOptions}
                        useAllOption={true}
                        mode="popover"
                    />
                </div>
                <div style={{ flex: '0 1 200px' }}>
                    <SeaSelect
                        label="Order By"
                        value={orderBy}
                        onchange={(e) => {
                            setOrderBy(e.detail.value);
                        }}
                    >
                        {orderBys.map((value) => {
                            return <IonSelectOption key={value} value={value}>{value}</IonSelectOption>;
                        })}
                    </SeaSelect>
                </div>
            </div>
            {errorReports === undefined &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            }
            {errorReports && errorReports.length === 0 &&
                <h2>
                    No results.
                </h2>
            }
            {errorReports && errorReports.length > 0 &&
                <div className="error-reports">
                    <div className="sea-row headings">
                        <div>Reported</div>
                        <div></div>
                        <div>Type</div>
                        <div>What Failed</div>
                        <div>Reason</div>
                        <div>App Build</div>
                        <div>Platform</div>
                        <div>OS</div>
                        <div></div>
                        <div>Licensee / User</div>
                        <div>Vessel</div>
                        <div>Reference</div>
                    </div>
                    {filteredErrorReports?.map((report) => {
                        return (
                            <div
                                key={report.id}
                                className="sea-card sea-row"
                                style={{ backgroundColor: report.bgColor }}
                                onClick={(e) => {
                                    setSelectedErrorReport(report);
                                    setShowErrorReportModal(true);
                                }}
                            >
                                <div className="truncate-2">{formatDatetime(report.whenReported)}</div>
                                <div>
                                    <div className="screen-indicator" style={{ backgroundColor: report.screenColor }}></div>
                                </div>
                                <div className="truncate-2">{report.type}</div>
                                <div className="truncate-2">{report.whatFailed}</div>
                                <div className="truncate-2">{report.reason}</div>
                                <div className="truncate-2">{report.version} {report.build}</div>
                                <div className="truncate-2">{report.device?.platform}</div>
                                <div className="truncate-2">{report.device?.operatingSystem}</div>
                                <div className="truncate-2">{report.device?.osVersion}</div>
                                <div className="truncate-2">
                                    {report.user} /
                                    <br/>{report.licensee}
                                </div>
                                <div className="truncate-2">
                                    {report.vessel ? report.vessel : '?'}
                                </div>
                                <div>{report.reference}</div>
                            </div>
                        );
                    })}
                </div>
            }
            <ViewErrorReportModal
                showModal={showErrorReportModal}
                setShowModal={setShowErrorReportModal}
                errorReport={selectedErrorReport}
            />
        </>
    );
};

export default ErrorReports;
