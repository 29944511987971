import React, { useState } from 'react';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../lib/permissions';
import { SplittableBatch, firestore } from '../../../../lib/firebase';
import { doc, WriteBatch, arrayRemove, serverTimestamp } from "firebase/firestore";
import { renderCategoryName } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { EquipmentManualDocument } from '../../../../shared-state/VesselDocuments/equipmentManualDocuments';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditEquipmentManualDocument from '../EditEquipmentManualDocument/EditEquipmentManualDocument'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaToast from '../../../../components/SeaToast/SeaToast';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';

interface ViewEquipmentManualDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: EquipmentManualDocument,
}

const ViewEquipmentManualDocument: React.FC<ViewEquipmentManualDocumentProps> = ({showModal, setShowModal, selectedItem}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    
    return (
        <SeaModal
            title={selectedItem?.title}
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="System" w="100">
                    {
                        (selectedItem?.systemIds) ? (
                            selectedItem.systemIds.map((id: string) => {
                                return renderCategoryName(id, vesselSystems);
                            }).join(', ')
                        ) : '-'
                    }
                </SeaGridCell>
                <SeaGridCell label="Equipment" w="100">
                    {
                        (equipment?.byId && selectedItem?.equipmentIds) ? (
                            selectedItem.equipmentIds.map((id: string) => {
                                return `${equipment.byId[id]?.equipment}${equipment.byId[id]?.state === 'deleted' ? ' (deleted)' : ''}`;
                            }).join(', ')
                        ) : '-'
                    }
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="equipmentManualDocuments"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Document</SeaButton>
                    <RequirePermissions
                        role="equipmentManualDocuments"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'equipmentManualDocuments',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'equipmentManualDocuments');
                                        // Remove from any equipment that refers to this manual
                                        const now = Date.now();
                                        let haveModifiedEquipment = false;
                                        equipment?.all?.forEach((item) => {
                                            if (item.equipmentDocumentIds && item.equipmentDocumentIds.indexOf(selectedItem.id) !== -1) {
                                                batch.set(
                                                    doc(firestore, 'equipment', item.id),
                                                    {
                                                        equipmentDocumentIds: arrayRemove(selectedItem.id),
                                                        whenUpdated: now,
                                                        updatedBy: userId,
                                                        touched: serverTimestamp(),
                                                    },
                                                    { merge: true }
                                                );
                                                haveModifiedEquipment = true;
                                            }
                                        });
                                        if (haveModifiedEquipment) {
                                            onCollectionUpdated(batch, 'equipment');
                                        }
                                        setShowModal(false);
                                    },
                                    'document',
                                    selectedItem?.title,
                                    vesselId ? [vesselId] : []
                                );
                            }}
                        >
                            Delete Document
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {showModal &&
                <>
                    <EditEquipmentManualDocument
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <SeaToast
                        message={toastMessage}
                        setMessage={setToastMessage}
                    />
                </>
            }
        </SeaModal>
    );
};

export default ViewEquipmentManualDocument;
