import React from 'react';
import SeaStatus, { SeaStatusOption } from '../SeaStatus/SeaStatus';
import { pdfStyles } from '../../lib/pdf';
import { View, Text } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { formatSeaDate } from '../../lib/util';
import './SeaStatusDueDate.css';

interface SeaStatusDueDateProps {
    whenDue?: number | string,
    warnDays?: number, // For how many days left should we then start warning?
    hasFault?: boolean,
    forPdf?: boolean,
    inline?: boolean
}

const SeaStatusDueDate: React.FC<SeaStatusDueDateProps> = ({
    whenDue,
    warnDays = 6,
    hasFault = false,
    forPdf = false,
    inline = false
}) => {

    let status = 'ok';
    let text = '';
    const due = DateTime.fromISO(formatSeaDate(whenDue)); // Crunch day to match local timezone
    const today = DateTime.fromISO(formatSeaDate());
    const days = due.diff(today, ['days']).days;

    if (hasFault) {
        status = 'fail';
        text = 'FAULT';
    } else if (days === 0) {
        status = 'now';
        text = 'Due Today';
    } else if (days < 0) {
        status = 'fail';
        text = `${-days} day${(days < -1)?'s':''} OD`;
    } else if (days < warnDays) {
        status = 'warn';
        text = `${days} day${(days > 1)?'s':''}`;
    } else {
        status = 'ok';
        text = `${days} day${(days > 1)?'s':''}`;
    }

    if (forPdf) {
        //return <Text style={{textAlign: 'center'}}>{text}</Text>;
        // default colours for good status
        let bgColor = '#2dd36f';
        let fontColor = '#ffffff';
        if (status === 'fail') {
            bgColor = '#e9455a';
        } else if (status === 'warn') {
            bgColor = '#f99e1b';
        } else if (status === 'now') {
            bgColor = '#f5f6f8';
            fontColor = '#0369d9';
        }
        console.log('status', status, 'bgColor', bgColor, 'fontColor', fontColor)
        return (
            <View style={{
                ...pdfStyles.status,
                backgroundColor: bgColor,
                color: fontColor
            }}>
                <Text>{text}</Text>
            </View>
        );
    }

    return (
        <SeaStatus status={status as SeaStatusOption} inline={inline}>
            {text}
        </SeaStatus>
    );
};

export default SeaStatusDueDate;
