import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, formatSeaDate, toMillis, subtractInterval } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CrewCertificate } from '../../../../shared-state/Crew/crewCertificates';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface RenewCrewCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: CrewCertificate,
    level?: number,
    setShowParentModal: (showModal: boolean) => void,
}

const RenewCrewCertificate: React.FC<RenewCrewCertificateProps> = ({showModal, setShowModal, selectedItem, level, setShowParentModal}) => {
    const isMounted = useRef(false);
    const userId = sharedState.userId.use();
    const licenseeId = sharedState.licenseeId.use();
    const todayMillis = sharedState.todayMillis.use();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    const initialValues = useMemo(() => {
        return {
            whenIssued: formatSeaDate(),
            whenExpires: '',
        };
    }, [todayMillis]); // eslint-disable-line

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            whenIssued: Yup.date().required().min(...notTooOld),
            whenExpires: Yup.date().required().min(...notTooOld),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                let whenToRemind = undefined;
                if (selectedItem.type === 'renewable' && data.whenExpires && selectedItem.emailReminder) {
                    whenToRemind = subtractInterval(data.whenExpires, selectedItem.emailReminder);
                };

                const action = traceAction('crewCertificates', 'create') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                const newCertRef = doc(collection(firestore, 'crewCertificates'));
                action.docId = newCertRef.id;

                batch.set(
                    doc(firestore, 'crewCertificates', selectedItem.id),
                    {
                        archivedBy: userId,
                        whenArchived: action.whenAction,
                        state: 'archived',
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );

                batch.set(newCertRef, {
                    licenseeId: licenseeId,
                    addedBy: userId,
                    heldBy: selectedItem.heldBy,
                    whenAdded: action.whenAction,
                    title: selectedItem.title,
                    issuedBy: selectedItem.issuedBy ? selectedItem.issuedBy : undefined,
                    whenIssued: toMillis(data.whenIssued),
                    type: selectedItem.type,
                    whenExpires: data.whenExpires ? toMillis(data.whenExpires) : undefined,
                    emailReminder: selectedItem.emailReminder ? selectedItem.emailReminder : undefined,
                    whenToRemind: whenToRemind,
                    state: 'active',
                    wasRenewed: true,
                    files: seaFilesToValue(files),
                    touched: serverTimestamp()
                });

                onCollectionUpdated(batch, 'crewCertificates');
                saveFileRefs(batch, files, 'crewCertificates', newCertRef.id);
                logAction(
                    batch,
                    'Renew',
                    'crewCertificates',
                    newCertRef.id,
                    selectedItem.title,
                    undefined,
                    [selectedItem.heldBy]
                );

                action.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedItem
                };
                action.save(`Renew crew certificate ${selectedItem?.title}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
                setTimeout(() => {
                    if (!isMounted.current) return;
                    setShowParentModal(false);
                }, 250);

                showToast('Certificate has now been renewed')
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to renew crew certificate`, error.message, error, {
                        selectedItem,
                        data,
                        files: seaFilesToValue(files)
                    });
                }
            });

        }
    });
    
    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [values, initialValues]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title='Renew Certificate'
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDate
                                label="New Issue Date"
                                name="whenIssued"
                                value={values.whenIssued}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenIssued ? errors.whenIssued : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="New Expiry Date"
                                name="whenExpires"
                                value={values.whenExpires}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenExpires ? errors.whenExpires : ''}
                            />
                        </IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="crewCertificates"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">Renew Certificate</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default RenewCrewCertificate;
