import React, { useState } from 'react';
import { IonSelectOption } from '@ionic/react';
import { renderStats, queryStats } from '../../../lib/performance';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import './PerformanceStatsModal.css';

interface PerformanceStatsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const renderStat = (x: number) => {
    if (isNaN(x)) {
        return '-';
    }
    x = Math.round(x * 100) / 100;
    let s = ''+x;
    if (s.indexOf('.') === -1) {
        s += '.00';
    } else {
        while (s.length - s.indexOf('.') <= 2) {
            s += '0';
        }
    }
    return s;
};

const PerformanceStatsModal: React.FC<PerformanceStatsModalProps> = ({showModal, setShowModal}) => {
    const [sortBy, setSortBy] = useState('alpha');

    let renderIds = [] as string[];
    let queryIds = [] as string[];
    if (showModal) {
        renderIds = Object.keys(renderStats);
        queryIds = Object.keys(queryStats);
        if (sortBy === 'slowest') {
            renderIds.sort((a, b) => {
                return renderStats[b].allMaxTime - renderStats[a].allMaxTime;
            });
            queryIds.sort((a, b) => {
                return (queryStats[b]?.freshTime ?? 0) - (queryStats[a]?.freshTime ?? 0);
            });
        } else {
            renderIds.sort();
            queryIds.sort();
        }
    }
    //console.log('renderIds', renderIds);

    return (
        <SeaModal
            title="Performance Stats"
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
        >
            <div style={{ padding: '8px 0px' }}>
                <SeaSelect
                    label="Sort By"
                    value={sortBy}
                    width="200px"
                    onchange={(e) => setSortBy(e.detail.value)}
                >
                    <IonSelectOption value="alpha">Alphabetical</IonSelectOption>
                    <IonSelectOption value="slowest">Slowest</IonSelectOption>
                </SeaSelect>
            </div>
            <h2>Render performance</h2>
            <table className="perf-table">
                <tbody>
                    <tr>
                        <td>Id</td>
                        <td>ALL Max (ms)</td>
                        <td>Updates</td>
                        <td>Mount (ms)</td>
                        <td>Max (ms)</td>
                        <td>Average (ms)</td>
                    </tr>
                    {renderIds.map((id) => {
                        const stats = renderStats[id];
                        return <tr key={id}>
                            <td>{id}</td>
                            <td>{renderStat(stats.allMaxTime)}</td>
                            <td>{stats.updates}</td>
                            <td>{renderStat(stats.maxMountTime)}</td>
                            <td>{renderStat(stats.maxUpdateTime)}</td>
                            <td>{renderStat(stats.averageUpdateTime)}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
            <div style={{height: '20px'}}></div>
            <h2>Query performance</h2>
            <table className="perf-table">
                <tbody>
                    <tr>
                        <td>Id</td>
                        <td>Fresh time (ms)</td>
                        <td>Cache time (ms)</td>
                        <td>Fresh</td>
                        <td>Cache</td>
                        <td>Size</td>
                    </tr>
                    {queryIds.map((id) => {
                        const stats = queryStats[id];
                        return <tr key={id}>
                            <td>{id}</td>
                            <td>{renderStat(stats.freshTime ?? 0)}</td>
                            <td>{renderStat(stats.cacheTime ?? 0)}</td>
                            <td>{stats.countFresh}</td>
                            <td>{stats.countCache}</td>
                            <td>{stats.size}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </SeaModal>
    );
};

export default PerformanceStatsModal;
