import React, { ReactNode } from 'react';
import SeaInputError from '../SeaInputError/SeaInputError';
import TickIcon from '../../assets/svg/Tick';
import XIcon from '../../assets/svg/X';
import './SeaYesNo.css';

/*

    0=undecided
    1=yes
    -1=no

    USAGE with Formik:
    * Pass in setFieldValue from Formik and SeaYesNo will call it using name and the new boolean value
    * Set checked to a boolean value in the same way you would usually use value

    For example:
        <SeaYesNo
            label="A label if you want"
            name="myYesNo"
            checked={values.myYesNo}
            setFieldValue={setFieldValue}
            error={touched.myYesNo ? errors.myYesNo : ''}
        >
            My YesNo
        </SeaYesNo>

*/

interface SeaYesNoProps {
    name: string,
    children?: ReactNode,
    label?: string,
    value?: -1|0|1,
    error?: string,
    disabled?: boolean,
    viewOnly?: boolean
    setFieldValue?: (name: string, value: -1|0|1) => void
}

const SeaYesNo: React.FC<SeaYesNoProps> = ({ name, children, label, value, error, disabled, viewOnly, setFieldValue }) => {

    const toggleValue = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, _value: -1|0|1) => {
        if (setFieldValue && !disabled) {
            if (value === _value) {
                setFieldValue(name, 0);
            } else {
                setFieldValue(name, _value);
            }
        }
    };

    return (
        <div className={`sea-checks no-select ${(viewOnly || disabled) ? 'view-only' : ''}`}>
            {label &&
                <div className="columns">
                    <div className="sea-label checks-label">
                        {label}
                    </div>
                </div>
            }
            <div className="row">
                <div className="sea-label option">YES</div>
                <div className="sea-label option">NO</div>
            </div>

            <div className="row">
                <div>
                    <div
                        className={`sea-checkbox pass ${value === 1 ? 'on' : ''}`}
                        onClick={(e) => toggleValue(e, 1)}
                    >
                        <TickIcon className="check-icon" style={{ margin: '12px 0px 0px 10px' }} />
                    </div>
                </div>
                <div>
                    <div
                        className={`sea-checkbox fail ${value === -1 ? 'on' : ''}`}
                        onClick={(e) => toggleValue(e, -1)}
                    >
                        <XIcon className="check-icon" style={{ margin: '13px 0px 0px 13px' }} />
                    </div>
                </div>
                <div className="margin">
                    {children}
                </div>
            </div>
            <SeaInputError>{error}</SeaInputError>
        </div>
    );
};

export default SeaYesNo;
