import React from 'react';
import { formatValue } from '../../../../lib/util';
import { renderCategoryNames } from '../../../../lib/categories';
import { addedToRiskRegister, authoritiesNotifiedForReview, controlStrategies, injuryConclusions, lostTimes, yesNoNa } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { sharedState } from '../../../../shared-state/shared-state';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import { IncidentReview } from '../../../../shared-state/HealthSafety/incidentReviews';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaAuthorship from '../../../../components/SeaAuthorship/SeaAuthorship';


interface IncidentReviewContentProps {
    showModal: boolean,
    incident: Incident,
    review: IncidentReview
}

const IncidentReviewContent: React.FC<IncidentReviewContentProps> = ({showModal, incident, review}) => {
    const incidentCauses = sharedState.incidentCauses.use(showModal);

    return (
        <>
            <div className="info-image">
                <SeaFileImage
                    files={review?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaAuthorship
                item={review}
                creationText="Review created"
            />
            <SeaGrid>
                <SeaGridCell label="Severity of Event" w="50">
                    {formatValue(review?.severity)}
                </SeaGridCell>
                <SeaGridCell label="Notified to Authorities?" w="50">
                    {formatValue(review?.notifiedAuthorities && authoritiesNotifiedForReview[review?.notifiedAuthorities])}
                </SeaGridCell>
                <SeaGridCell label="Causes of this incident / event" w="50">
                    {formatValue(renderCategoryNames(review?.causeIds, incidentCauses))}
                </SeaGridCell>
                <SeaGridCell label="Control Strategies" w="50">
                    {formatValue(
                        review?.strategies?.map((strategy: string) => {
                            return controlStrategies[strategy];
                        }).join(', ')
                    )}
                </SeaGridCell>
                <SeaGridCell label="Added to risk assessments?" w="50">
                    {formatValue(review?.addedToRiskRegister && addedToRiskRegister[review?.addedToRiskRegister])}
                </SeaGridCell>
                <SeaGridCell label="External investigation required?" w="50">
                    {formatValue(review?.externalRequired && yesNoNa[review?.externalRequired])}
                </SeaGridCell>
                {(incident?.type === 'injury') && (
                    <>
                        <SeaGridCell label="Injury Conclusion" w="50">
                            {formatValue(
                                review?.injuryConclusions?.map((conclusion: string) => {
                                    return injuryConclusions[conclusion];
                                }).join(', ')
                            )}
                        </SeaGridCell>
                        <SeaGridCell label="Time Lost to Injury" w="50">
                            {formatValue(review?.lostTime && lostTimes[review?.lostTime])}
                        </SeaGridCell>
                    </>
                )}
                <SeaGridCell label="Corrective actions or tasks required" w="100">
                    {formatValue(review?.prevention)}
                </SeaGridCell>
                <SeaGridCell label="Analysis of Review" w="100">
                    {formatValue(review?.analysis)}
                </SeaGridCell>
                <SeaGridCell label="Signature" w="100">
                    {review?.signature ? (
                        <SeaSignatureImage file={review?.signature}/>
                    ) : (
                        '-'
                    )}
                </SeaGridCell>
            </SeaGrid>
        </>
    );
};

export default IncidentReviewContent;
