import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { ReportingEquipmentByIdData } from './reportingEquipmentById';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { MaintenanceTaskCompleted } from '../../VesselMaintenance/maintenanceTasksCompleted';

export const useReportingCompletedMaintenanceTasks = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedCompletedTaskTypes: string[] | undefined,
    equipmentById: ReportingEquipmentByIdData | undefined,
    equipmentCriticality: string
) => {
    const {dateRange} = useReportSettings();
    const [reportingCompletedMaintenanceTasks, setReportingCompletedMaintenanceTasks] = useState<MaintenanceTaskCompleted[]>();

    useEffect(() => {
        if (selectedVesselIds.length === 0) {
            // Nothing to load
            setReportingCompletedMaintenanceTasks([]);
            return;
        }
        setReportingCompletedMaintenanceTasks(undefined);
        if (
            isActive &&
            selectedVesselIds?.length &&
            equipmentById &&
            selectedCompletedTaskTypes
        ) {
            const includeScheduled = (selectedCompletedTaskTypes.indexOf('scheduled') !== -1);
            const includeUnscheduled = (selectedCompletedTaskTypes.indexOf('unscheduled') !== -1);
            if (!includeScheduled && !includeUnscheduled) {
                setReportingCompletedMaintenanceTasks([]);
                return;
            }

            const baseConstraints = [
                where('state', '==', 'completed')
            ];
            if (includeScheduled && !includeUnscheduled) {
                baseConstraints.push(
                    where('type', '==', 'scheduled')
                );
            } else if (includeUnscheduled && !includeScheduled) {
                baseConstraints.push(
                    where('type', 'in', ['job', 'unscheduled'])
                );
            }
            baseConstraints.push(
                where('whenCompleted', '>=', dateRange.from)
            );
            baseConstraints.push(
                where('whenCompleted', '<', dateRange.to + (24*60*60*1000))
            );

            let isCriticalityOk = (item: MaintenanceTaskCompleted) => true;
            if (equipmentCriticality === 'critical') {
                isCriticalityOk = (item: MaintenanceTaskCompleted) => {
                    return (item.equipmentId && equipmentById[item.equipmentId]?.isCritical ? true : false);
                };
            } else if (equipmentCriticality === 'nonCritical') {
                isCriticalityOk = (item: MaintenanceTaskCompleted) => {
                    return !(item.equipmentId && equipmentById[item.equipmentId]?.isCritical);
                };
            }

            return setupArrayQueryListener(
                'maintenanceTasksCompleted', // what
                collection(firestore, 'maintenanceTasksCompleted'),
                baseConstraints,
                'vesselId',
                'in',
                selectedVesselIds,
                [orderBy('whenCompleted', 'desc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const list = [] as MaintenanceTaskCompleted[];
                    
                    const scheduledTaskById = {} as {
                        [id: string]: MaintenanceTaskCompleted;
                    };

                    docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                            timesCompleted: 1
                        } as MaintenanceTaskCompleted;
                        if (isCriticalityOk(item)) {
                            if (item.type === 'scheduled' && item.maintenanceTaskId) {
                                if (scheduledTaskById[item.maintenanceTaskId]?.timesCompleted !== undefined) {
                                    (scheduledTaskById[item.maintenanceTaskId].timesCompleted as number)++;
                                    if (item.whenCompleted > scheduledTaskById[item.maintenanceTaskId].whenCompleted) {
                                        scheduledTaskById[item.maintenanceTaskId].whenCompleted = item.whenCompleted;
                                    }
                                } else {
                                    scheduledTaskById[item.maintenanceTaskId] = item;
                                    list.push(item);
                                }
                            } else {
                                list.push(item);
                            }
                        }
                    });

                    if (isCombined) {
                        list.sort((a, b) => {
                            return b.whenCompleted - a.whenCompleted;
                        });
                    }

                    setReportingCompletedMaintenanceTasks(list);
                }
            );
        }
    }, [isActive, selectedVesselIds, equipmentById, selectedCompletedTaskTypes, equipmentCriticality, dateRange]);

    return reportingCompletedMaintenanceTasks;

}
