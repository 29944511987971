import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import { logPageView, functions } from '../../../lib/firebase';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";
import Yup from '../../../lib/yup';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaFormError from '../../../components/SeaFormError/SeaFormError';
import SeaButton from '../../../components/SeaButton/SeaButton';
import './ForgotPassword.css';

const ForgotPassword: React.FC = () => {
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        logPageView('ForgotPassword');
    }, []);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched} = useFormik({
        initialValues: {
            email: ''
        }, validationSchema: Yup.object({
            email: Yup.string().min(3).max(64).email().required()
        }), onSubmit: (data) => {
            setLoading(true);
            setFormError('');

            httpsCallable(functions, 'requestPasswordReset')({
                email: data.email
                //email: selectedUser.email
            }).then((result: any) => {
                console.log('requestPasswordReset result', result);
                if (result.data.error) {
                    //reportError(`Failed to reset login for ${selectedUser.email}`, result.data.error);
                    // ...
                    setFormError(result.data.error);
                } else {
                    setSuccessMessage(`An email has been sent to ${data.email} with further instructions.`);
                    setShowSuccess(true);
                }
            }).catch((error) => {
                console.log('requestPasswordReset error', error);
            }).finally(() => {
                setLoading(false);
            });

            // resetPassword(data.email).then(() => {
            //     setSuccessMessage(`An email has been sent to ${data.email} with further instructions.`);
            //     setShowSuccess(true);
            // }).catch((error: any) => {
            //     switch (error.code) {
            //         case 'auth/invalid-email':
            //         case 'auth/user-not-found':
            //             setFieldError('email', error.message);
            //             break;
            //         default:
            //             setFormError(error.message);
            //             return;
            //     }
            // }).finally(() => {
            //     setLoading(false);
            // });
        }
    });

    return (
        <CenteredBoxPageLayout>
            <form onSubmit={handleSubmit}>
                <IonGrid className="auth-form-grid" style={{paddingTop: '8px'}}>
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Email"
                                name="email"
                                value={values.email}
                                autocomplete="email"
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="email"
                                inputmode="email"
                                disabled={loading}
                                error={touched.email ? errors.email : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="form-spacer"></div>
                <SeaFormError message={formError}/>
                <SeaButton zone="white" disabled={loading} type="submit" size="wide">Reset Password</SeaButton>
                <p>
                    <Link to="/login">Log In</Link>
                </p>
                <input type="submit" className="submit-enter"/>
            </form>
            <IonAlert
                isOpen={showSuccess}
                header="Forgot Password"
                message={successMessage}
                buttons={['OK']}
                onDidDismiss={(e) => setShowSuccess(false)}
            />
        </CenteredBoxPageLayout>
    );
};

export default ForgotPassword;
