import { SharedStateConfig, sharedState } from "../../shared-state";
import { CategoriesData, onCategoriesSnapshot } from "../../../lib/categories";

//
// Loads customFormCategories
//

export const customFormCategoriesConfig: SharedStateConfig<CategoriesData> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.customFormCategories.current?.ids.length ?? 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onCategoriesSnapshot(
                'customFormCategories',
                'licenseeId',
                licenseeId,
                (data: CategoriesData) => { // onLoaded
                    done();
                    set(data);
                },
                (error) => { // onError
                    done();
                    console.log(`Error getting customFormCategories`, error);
                }
            );
        } else {
            done();
        }
    }
};
