import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import { IonItem, IonList } from '@ionic/react';
import { closeModalBox, openModalBox, updateModalBox } from '../../managers/ModalBoxManager/ModalBoxManager';
import SeaInput from '../SeaInput/SeaInput';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaButton from '../SeaButton/SeaButton';
import './SeaTagsInput.css';

interface SeaTagsInputProps {
    options?: string[], // List of possible options
    sortOptions?: boolean,
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    tags: string[],
    setTags: (tags: string[]) => void,
    addTagText?: string,
    editTagText?: string,
    newTagPlaceholder?: string
    help?: { text: string }
}

const SeaTagsInput: React.FC<SeaTagsInputProps> = ({
    options,
    sortOptions,
    zone,
    name,
    label,
    tags,
    setTags,
    addTagText = 'Add Tag',
    editTagText = 'Edit Tag',
    newTagPlaceholder = 'New tag...',
    help
}) => {
    const isMounted = useRef(false);
    const inputRef = useRef(null);
    const editTagRef = useRef('');
    const initialTagRef = useRef('');
    const filterRef = useRef('');

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    const optionsSorted = useMemo(() => {
        if (sortOptions && options && options.length > 0) {
            return [...options].sort();
        }
        return options
    }, [options, sortOptions]);

    const addTag = useCallback((tag: string) => {
        const _tags = [...tags];
        for (let i = _tags.length - 1; i >= 0; i--) {
            if (_tags[i] === tag) {
                _tags.splice(i, 1);
                break;
            }
        }
        _tags.push(tag);
        setTags(_tags);
    }, [tags, setTags]);

    const deleteTag = useCallback((tag: string) => {
        const _tags = [...tags];
        for (let i = _tags.length; i >= 0; i--) {
            if (_tags[i] === tag) {
                _tags.splice(i, 1);
            }
        }
        setTags(_tags);
    }, [tags, setTags]);

    const replaceTag = useCallback((oldTag: string, newTag: string) => {
        const _tags = [...tags];
        for (let i = _tags.length; i >= 0; i--) {
            if (_tags[i] === oldTag) {
                _tags.splice(i, 1, newTag);
            }
        }
        setTags(_tags);
    }, [tags, setTags]);

    const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => {
        e.preventDefault();
        e.stopPropagation();
        deleteTag(tag);
    };

    const onTagClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => {
        e.preventDefault();
        e.stopPropagation();
        openPopover(tag);
    }

    const openPopover = useCallback((value: string) => {
        const stopClick = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isMounted.current) return;
        };
        const submitForm = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isMounted.current) return;
            editTagRef.current = editTagRef.current.trim();
            if (initialTagRef.current) {
                if (editTagRef.current) {
                    replaceTag(initialTagRef.current, editTagRef.current);
                } else {
                    deleteTag(initialTagRef.current);
                }
            } else if (editTagRef.current) {
                addTag(editTagRef.current);
            }
            closeModalBox();
        };
        const renderContent = () => {
            if (!isMounted.current) return;
            return (<>
                {label &&
                    <div className="sea-select-multi-title" onClick={stopClick}>
                        {initialTagRef.current ? editTagText : addTagText}
                        
                    </div>
                }
                <div className="sea-select-multi-links" onClick={stopClick}>
                    <form onSubmit={submitForm} style={{
                        padding: '8px 10px 4px 12px',
                        width: '100%'
                    }}>
                        <div className="columns">
                            <SeaInput
                                ref={inputRef}
                                value={editTagRef.current}
                                placeholder={newTagPlaceholder}
                                onchange={(e) => {
                                    editTagRef.current = e.detail.value;
                                    filterRef.current = e.detail.value.trim().toLowerCase();
                                    updateModalBox(renderContent());
                                }}
                            />
                            <div>
                                <SeaButton
                                    shape="circle"
                                    onClick={submitForm}
                                    disabled={editTagRef.current.trim() === ''}
                                >
                                    <SeaIcon slot="icon-only" icon="tick" forceFontSize="30px"/>
                                </SeaButton>
                            </div>
                        </div>
                    </form>
                </div>
                <div
                    className="sea-select-multi-box tags"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <IonList mode="md">
                        {optionsSorted?.map((option) => {
                            if (filterRef.current && option.toLowerCase().indexOf(filterRef.current) === -1) {
                                return undefined;
                            }
                            return (
                                <IonItem
                                    key={option}
                                    button
                                    onClick={(e) => {
                                        if (initialTagRef.current) {
                                            replaceTag(initialTagRef.current, option);
                                        } else {
                                            addTag(option);
                                        }
                                        closeModalBox();
                                    }}
                                >
                                    {option}
                                </IonItem>
                            );
                        })}
                    </IonList>
                </div>
            </>);
        };
        editTagRef.current = value;
        initialTagRef.current = value;
        filterRef.current = '';
        openModalBox(renderContent(), 'crew-or-contact');
        //if (value || isPlatform('desktop')) {
        setTimeout(() => {
            if (!isMounted.current) return;
            if (inputRef.current) {
                (inputRef.current as any).focus();
            }
        }, 1);
        //}
    }, [optionsSorted, addTag, addTagText, deleteTag, editTagText, label, newTagPlaceholder, replaceTag]);

    return (
        <>
            {label &&
                <SeaLabel help={help}>{label}</SeaLabel>
            }
            <div className={`sea-tags sea-input ${zone}-zone no-select`} onClick={(e) => openPopover('')}>
                {tags?.map((tag) => {
                    return (
                        <div
                            key={tag}
                            className="sea-tag pushy"
                            onClick={(e) => onTagClick(e, tag)}
                        >
                            {tag}
                            <div
                                className="delete pushy"
                                onClick={(e) => onDelete(e, tag)}
                            >
                                <SeaIcon icon="close" forceFontSize="22px"/>
                            </div>
                        </div>
                    );
                })}
                <div className="room"></div>
            </div>
        </>
    );
};

export default SeaTagsInput;
