import React, { ReactNode } from 'react';
import { IonCheckbox } from '@ionic/react';
import { CheckboxChangeEventDetail } from '@ionic/core';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaContextualHelp, { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaLabel from '../SeaLabel/SeaLabel';
import './SeaCheckbox.css';

/*

    USAGE with Formik:
    * Pass in setFieldValue from Formik and SeaCheckbox will call it using name and the new boolean value
    * Set checked to a boolean value in the same way you would usually use value

    For example:
        <SeaCheckbox
            label="A label if you want"
            name="myBoolean"
            checked={values.myBoolean}
            setFieldValue={setFieldValue}
            error={touched.myBoolean ? errors.myBoolean : ''}
        >
            My Boolean
        </SeaCheckbox>

    NOTE: This was done because using Formik's handleChange with IonCheckbox is very problematic!

*/

interface SeaCheckboxProps {
    name?: string,
    children?: ReactNode,
    label?: string,
    help?: SeaHelp,
    checked?: boolean,
    setChecked?: (checked: boolean) => void,
    error?: string,
    disabled?: boolean,
    viewOnly?: boolean,
    checkedColor?: 'primary' | 'secondard',
    //onchange?: (e: CustomEvent) => void,
    setFieldValue?: (name: string, value: boolean) => void,
    mode?: 'beside-input'
}

const SeaCheckbox = React.forwardRef<HTMLInputElement, SeaCheckboxProps>(({ name, children, label, help, checked, setChecked, error, disabled, viewOnly, checkedColor, setFieldValue, mode }, forwardedRef:any) => {

    const onIonChange = (e: CustomEvent<CheckboxChangeEventDetail<any>>) => {
        if (setFieldValue && name) {
            setFieldValue(name, e.detail.checked);
        }
        if (setChecked) {
            setChecked(e.detail.checked);
        }
    }
    const toggle = () => {
        if (setFieldValue && name && !disabled) {
            setFieldValue(name, !checked);
        }
        if (setChecked && !disabled) {
            setChecked(!checked);
        }
    }

    if (children) {
        return (
            <>
                {label && <SeaLabel>{label}</SeaLabel>}
                <div className={`sea-checkbox-container ${mode ? mode : ''}`}>
                    <div>
                        <IonCheckbox
                            className={`sea-checkbox ${error ? 'has-error' : ''} ${viewOnly ? 'view-only' : ''}`}
                            ref={forwardedRef}
                            color={checkedColor ? checkedColor : `secondary`}
                            id={name}
                            name={name}
                            checked={checked}
                            disabled={disabled}
                            onIonChange={onIonChange}
                        />
                    </div>
                    <div className={`description no-select ${disabled ? 'disabled' : ''}`} onClick={toggle}>
                        {children}
                        {help &&
                            <SeaContextualHelp
                                help={help}
                                mode="checkbox"
                            />
                        }
                    </div>
                </div>
                <SeaInputError>{error}</SeaInputError>
            </>
        );
    }
    return (
        <>
            {label && <SeaLabel>{label}</SeaLabel>}
            <IonCheckbox
                className={`sea-checkbox ${error ? 'has-error' : ''} ${viewOnly ? 'view-only' : ''}`}
                ref={forwardedRef}
                color={checkedColor ? checkedColor : `secondary`}
                mode="ios"
                name={name}
                checked={checked}
                disabled={disabled}
                onIonChange={onIonChange}
            />
            <SeaInputError>{error}</SeaInputError>
        </>
    );
});

export default SeaCheckbox;
