function SvgReporting(props) {
    return (
        <svg width={27} height={27} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M23.724 23.15H7.69c-1.213 0-1.82 0-2.283-.237a2.167 2.167 0 01-.947-.947c-.236-.463-.236-1.07-.236-2.283V3.649m4.333 12.459v3.25m4.875-6.5v6.5m4.875-9.75v9.75m4.875-13v13"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default SvgReporting;
