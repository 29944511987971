import { SharedStateConfig, sharedState } from "../shared-state";
import { logoutUser } from "./user";

//
// Handles session timeout for those licensees that session timeout enabled
// This is true if securityClaims.sfmss > 0 (Sea Flux Maximum Session Seconds)
//

export const handleSessionTimeout: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['todayMillis'],
    default: 'Session timeout not checked',
    notes: '',
    run: (done, set, clear) => {
        done();
        checkSessionTimeout();
        set(`Session timeout checked for today`);
    }
};

export const checkSessionTimeout = () => {
    const onSessionTimeout = () => {
        return logoutUser().then(() => {
            console.log('Logged out due to session timeout!');
        }).catch((error) => {
            console.log('`Failed to log out', error);
        }).finally(() => {
            window.location.href = '/restart?reason=sessionTimeout';
        });
    };
    if (
        sharedState.securityClaims.current &&
        sharedState.securityClaims.current.sfmss > 0
    ) {
        const whenSessionTimeout = (sharedState.securityClaims.current.auth_time + sharedState.securityClaims.current.sfmss) * 1000;
        if (Date.now() >= whenSessionTimeout) {
            console.log('Session timeout. Need to to logout...');
            onSessionTimeout();
        } else if (Date.now() >= whenSessionTimeout - (24 * 60 * 60 * 1000)) {
            console.log('Session will timeout out within the next 24 hours');
            setTimeout(() => {
                console.log('Session timeout. Need to logout now...');
                onSessionTimeout();
            }, whenSessionTimeout - Date.now());
        }
    }
};
