import { DocumentData, DocumentReference, DocumentSnapshot, QueryConstraint, QueryDocumentSnapshot, QueryOrderByConstraint, collection, doc, getDoc, getDocs, orderBy, where, query, Timestamp } from "firebase/firestore";
import { canArchive, canDelete, canEdit, canView } from "../../lib/permissions";
import { SharedStateConfig, SharedStateType, sharedState } from "../shared-state";
import { ArrayComparison, firestore, getArrayQueryResults } from "../../lib/firebase";
import { getDayOffsetMillis } from "../../lib/util";
import { cachedDataInfo, updateLicenseeCachedDataInfo, updateVesselCachedDataInfo } from "./cachedDataInfo";
import { registerFiles, registerRichText, registerSignature } from "../../lib/fileSync";

//
// Data Sync System
// The goal is to make sure we have all the latest data for when we go offline.
// To do this, we will will query any new data we become aware of (thanks to whenLicenseeTouched & whenVesselTouched),
// that is, if we don't already have it, i.e. if its not already being received within sharedState snapshot listeners
//

export type DataSyncStatus = {
    totalTasks: number;
    tasksLeft: number;
};

export const dataSyncStatusConfig: SharedStateConfig<DataSyncStatus> = {
    isAlwaysActive: true,
    default: {
        totalTasks: 0,
        tasksLeft: 0,
    },
    notes: "Source: dataSync.processDataSyncTasks",
};

// Important! All these values need to match firestore.rules/whenLicenseeTouched AND licenseeCollectionsToDataSync in firebase/functions/common/util.js
export const licenseeCollectionsToDataSync = ['actionLog','companyPlans','contactCategories','contacts','companyDocumentCategories','companyDocuments','crewCertificates','customFormCategories','customForms','customFormsCompleted','dangerousGoods','hazards','incidentCategories','incidentCauses','incidents','injuryLocations','injuryTypes','incidentReviews','riskCategories','risks','risksReviewed','safetyMeetingReports','seaTimeRecords','trainingTasks','trainingTaskReports','userDetails','userDocuments','userRoles'] as const;

// Important! All these values need to match firestore.rules/whenVesselTouched AND vesselCollectionsToDataSync in firebase/functions/common/util.js
export const vesselCollectionsToDataSync = ['drills','drillReports','engines','equipment','equipmentManualDocuments','jobs','maintenanceTasksCompleted','safetyCheckCompleted','safetyCheckItems', 'safetyCheckCategories','safetyEquipmentItems','safetyEquipmentTaskCompleted','scheduledMaintenanceTasks','SOPs','SOPsCompleted','spareParts','surveyReports','trainingTasks','trainingTaskReports','vesselCertificates','vesselDocuments','vesselLocations','vesselSafetyItems','vesselSopCategories', 'vesselDocumentCategories','vesselSystems','voyages', 'voyageDocuments'] as const;

export type LicenseeDataSyncCollection = (typeof licenseeCollectionsToDataSync)[number];
export type VesselDataSyncCollection = (typeof vesselCollectionsToDataSync)[number];

type LicenseeDataSyncTaskConfig = {
    hasPermission: () => boolean; // A function that will determine if the data is at all accessible by the logged in user (if undefined, we assume true)
    isDataLive: () => boolean; // A function that will determine if the data is already actively being listened and therefore the task wont need to be processed. (if undefined, we assume false)
    fileFields?: string[]; // List if fields that contain references to files like signature, files, sfdoc etc.
    getAll: {
        what: string; // Description of what exactly this query is gettings
        shouldGet?: () => boolean; // A function that will be called to see if this get should be called. (Otherwise it is assumed it should)
        arrayQuery?: () => [string, ArrayComparison, any[]];
        constraints?: () => QueryConstraint[];
        singleDocument?: () => DocumentReference; // for when there is only a single document retrieved using its docId
        orderBy?: QueryOrderByConstraint[];
    }[];
    getLatest: {
        // Should not include constraints with things like state (for example, if an item's state goes from active to deleted the normal query will still be including that item)
        what: string;
        shouldGet?: () => boolean;
        arrayQuery?: () => [string, ArrayComparison, any[]];
        constraints?: (touched?: number) => QueryConstraint[]; // Should probably use touched and avoid including a state constraint
        singleDocument?: (touched?: number) => DocumentReference; // for when there is only a single document retrieved using its docId
        orderBy?: QueryOrderByConstraint[]; // getLatest will not usually use orderBy - except for special situations where touched is not used (i.e. where a constraint can change AND is used for security)
    }[];
};
type VesselDataSyncTaskConfig = {
    // The same as LicenseeDataSyncTaskConfig except hasPermission, isDataLive, constraints and singleDocument functions get passed a vesselId
    hasPermission: (vesselId: string) => boolean;
    isDataLive: (vesselId: string) => boolean;
    fileFields?: string[];
    getAll: {
        what: string;
        shouldGet?: () => boolean;
        arrayQuery?: () => [string, ArrayComparison, any[]];
        constraints?: (vesselId?: string) => QueryConstraint[];
        singleDocument?: (vesselId?: string) => DocumentReference;
        orderBy?: QueryOrderByConstraint[];
    }[];
    getLatest: {
        what: string;
        shouldGet?: () => boolean;
        arrayQuery?: () => [string, ArrayComparison, any[]];
        constraints?: (touched?: number, vesselId?: string) => QueryConstraint[];
        singleDocument?: (touched?: number, vesselId?: string) => DocumentReference;
        orderBy?: QueryOrderByConstraint[];
    }[];
};

const isVesselActive = (vesselId: string) => {
    return sharedState.vesselId.current && sharedState.vesselId.current === vesselId;
};

const makeLicenseeCategoryDataSyncTaskConfig = (collection: string, sharedStateType?: SharedStateType) => {
    const config = {
        hasPermission: () => true,
        isDataLive: () => false,
        getAll: [
            {
                what: `All ${collection}`,
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current)],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: `The latest ${collection}`,
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    } as LicenseeDataSyncTaskConfig;
    if (sharedStateType) {
        // We can test if the data is already live
        config.isDataLive = () => {
            return sharedState[sharedStateType].isActive ? true : false;
        };
    }
    return config;
};

export const licenseeDataSyncTaskConfigs = {
    actionLog: {
        hasPermission: () => true,
        isDataLive: () => sharedState.customForms.isActive,
        getAll: [
            {
                what: "All actionLog entries (from up to 90 days ago)",
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("when", ">=", getDayOffsetMillis(-90))],
                orderBy: [orderBy("when", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest actionLog entries",
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    },
    companyPlans: {
        hasPermission: () => canView("companyPlan"),
        isDataLive: () => sharedState.companyPlan.isActive,
        fileFields: ["sfdoc"],
        getAll: [
            {
                what: "The company plan (for getAll)",
                singleDocument: () => doc(firestore, "companyPlans", sharedState.licenseeId.current as string),
            },
        ],
        getLatest: [
            {
                what: "The company plan (for getLatest)",
                singleDocument: () => doc(firestore, "companyPlans", sharedState.licenseeId.current as string),
            },
        ],
    },
    contactCategories: makeLicenseeCategoryDataSyncTaskConfig("contactCategories", "contactCategories"),
    contacts: {
        hasPermission: () => true,
        isDataLive: () => sharedState.contacts.isActive,
        getAll: [
            {
                what: "All contacts",
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current)],
                orderBy: [orderBy("company", "asc"), orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest contacts",
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    },
    companyDocumentCategories: makeLicenseeCategoryDataSyncTaskConfig("companyDocumentCategories", "companyDocumentCategories"),
    companyDocuments: {
        hasPermission: () => canView("companyDocuments"),
        isDataLive: () => sharedState.companyDocuments.isActive,
        fileFields: ["files", "sfdoc"],
        getAll: [
            {
                what: "All active companyDocuments",
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest companyDocuments",
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    },
    crewCertificates: {
        hasPermission: () => true,
        isDataLive: () => sharedState.crewCertificates.isActive && sharedState.archivedCrewCertificates.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active crewCertificates",
                shouldGet: () => canView("crewCertificates"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
            {
                what: "All archived crewCertificates",
                shouldGet: () => canView("crewCertificates"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "archived")],
                orderBy: [orderBy("whenIssued", "desc")],
            },
            {
                what: "All my active crewCertificates",
                shouldGet: () => !canView("crewCertificates"),
                constraints: () => [where("heldBy", "==", sharedState.userId.current), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest crewCertificates",
                shouldGet: () => canView("crewCertificates"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                what: "My latest active crewCertificates",
                shouldGet: () => !canView("crewCertificates"),
                constraints: (touched) => [where("heldBy", "==", sharedState.userId.current), where("touched", ">", touched)],
            },
        ],
    },
    customFormCategories: makeLicenseeCategoryDataSyncTaskConfig("customFormCategories", "customFormCategories"),
    customForms: {
        hasPermission: () => true,
        isDataLive: () => sharedState.customForms.isActive,
        getAll: [
            {
                what: "All active customForms for vessels I have access to as well as forms that aren't for vessels",
                arrayQuery: () => ["forVesselIds", "array-contains-any", ["none", ...(sharedState.vesselIds.current ?? [])]],
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest customForms for vessels I have access to as well as forms that aren't for vessels",
                arrayQuery: () => ["forVesselIds", "array-contains-any", ["none", ...(sharedState.vesselIds.current ?? [])]],
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    },
    // customFormVersions: // Doesn't need to be synced because they are alwaysActive
    customFormsCompleted: {
        hasPermission: () => true,
        isDataLive: () => false,
        fileFields: ["data..."], // This is a special case because we'll need to search through the completed custom form for file containing elements
        getAll: [
            {
                what: "All active customFormsCompleted for vessels I have access to",
                shouldGet: () => canView("customForms"),
                arrayQuery: () => ["vesselIds", "array-contains-any", ["none", ...(sharedState.vesselIds.current ?? [])]],
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
            {
                what: "All active customFormsCompleted for voyages",
                shouldGet: () => !canView("customForms") && canView("logbook"),
                arrayQuery: () => ["attachToVesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("attachTo", "==", "voyage"), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
            {
                what: "All active customFormsCompleted for crew forms/documents",
                shouldGet: () => canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("personnelIds", "!=", null), where("state", "==", "active")],
                //orderBy: [orderBy('whenAdded', 'desc')] // Can't have this sorting due to not matching the inequality filter used with personnelIds
            },
            {
                what: "All active customFormsCompleted for my forms/documents",
                shouldGet: () => !canView("crewParticulars"),
                arrayQuery: () => ["personnelIds", "array-contains-any", sharedState.userId.current],
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
        ],
        getLatest: [
            {
                // Because vesselIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) active customFormsCompleted for vessels I have access to",
                shouldGet: () => canView("customForms"),
                arrayQuery: () => ["vesselIds", "array-contains-any", ["none", ...(sharedState.vesselIds.current ?? [])]], // This could change AND it is used for security check!
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
            {
                what: "The latest customFormsCompleted for voyages",
                shouldGet: () => !canView("customForms") && canView("logbook"),
                arrayQuery: () => {
                    return ["attachToVesselId", "in", sharedState.vesselIds.current];
                },
                constraints: (touched) => [
                    where("attachTo", "==", "voyage"),
                    //where('touched', '>', touched)
                ],
            },
            {
                // Because personnelIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) active customFormsCompleted for crew forms/documents",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [
                    where("licenseeId", "==", sharedState.licenseeId.current),
                    where("personnelIds", "!=", null), // Alert: This could change AND it is used for security check!
                    where("state", "==", "active"),
                ],
                //orderBy: [orderBy('whenAdded', 'desc')] // Can't have this sorting due to not matching the inequality filter used with personnelIds
            },
            {
                // Because personnelIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) active customFormsCompleted for my forms/documents",
                shouldGet: () => !canView("crewParticulars"),
                arrayQuery: () => ["personnelIds", "array-contains-any", sharedState.userId.current], // This could change AND it is used for security check!
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
        ],
    },
    dangerousGoods: {
        hasPermission: () => canView("dangerousGoodsRegister"),
        isDataLive: () => sharedState.dangerousGoods.isActive,
        fileFields: ["imageFiles", "msdsFiles"],
        getAll: [
            {
                what: "All active dangerousGoods for vessels I have access to",
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest dangerousGoods for vessels I have access to",
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    hazards: {
        // (deprecated)
        hasPermission: () => canView("hazardRegister") && sharedState.licenseeSettings.current?.riskRegister?.version === 1,
        isDataLive: () => sharedState.hazardRegistry.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active hazards (deprecated) for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest hazards (deprecated) for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    incidentCategories: makeLicenseeCategoryDataSyncTaskConfig("incidentCategories", "incidentCategories"),
    incidentCauses: makeLicenseeCategoryDataSyncTaskConfig("incidentCauses", "incidentCauses"),
    incidents: {
        hasPermission: () => true,
        isDataLive: () => sharedState.incidents.isActive,
        fileFields: ["files", "signature"],
        getAll: [
            {
                what: "All incidents (draft, forReview, inReview, completed) for vessels I have access to",
                shouldGet: () => canDelete("incidentAccidentMedicalRegister"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "in", ["draft", "forReview", "inReview", "completed"])],
                orderBy: [orderBy("whenEvent", "desc")],
            },
            {
                what: "All incidents (forReview, inReview, completed) for vessels I have access to",
                shouldGet: () => canView("incidentAccidentMedicalRegister") && !canDelete("incidentAccidentMedicalRegister"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "in", ["forReview", "inReview", "completed"])],
                orderBy: [orderBy("whenEvent", "desc")],
            },
            {
                // (This pairs with the above query)
                what: "All my draft incidents",
                shouldGet: () => canView("incidentAccidentMedicalRegister") && !canDelete("incidentAccidentMedicalRegister"),
                constraints: () => [where("addedBy", "==", sharedState.userId.current), where("state", "==", "draft")],
                orderBy: [orderBy("whenEvent", "desc")],
            },
            {
                what: "All my incidents (draft, forReview, inReview, completed)",
                shouldGet: () => !canView("incidentAccidentMedicalRegister"),
                constraints: () => [where("addedBy", "==", sharedState.userId.current), where("state", "in", ["draft", "forReview", "inReview", "completed"])],
                orderBy: [orderBy("whenEvent", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest incidents for vessels I have access to",
                shouldGet: () => canDelete("incidentAccidentMedicalRegister"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
            {
                // Because state is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) incidents (forReview, inReview, completed) for vessels I have access to",
                shouldGet: () => canView("incidentAccidentMedicalRegister") && !canDelete("incidentAccidentMedicalRegister"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "in", ["forReview", "inReview", "completed"])],
                orderBy: [orderBy("whenEvent", "desc")],
            },
            {
                // My reports
                what: "My latest incidents",
                shouldGet: () => !canDelete("incidentAccidentMedicalRegister"),
                constraints: (touched) => [where("addedBy", "==", sharedState.userId.current), where("touched", ">", touched)],
            },
        ],
    },
    injuryLocations: makeLicenseeCategoryDataSyncTaskConfig("injuryLocations", "injuryLocations"),
    injuryTypes: makeLicenseeCategoryDataSyncTaskConfig("injuryTypes", "injuryTypes"),
    incidentReviews: {
        hasPermission: () => canView("incidentAccidentMedicalRegister"),
        isDataLive: () => sharedState.incidentReviews.isActive,
        fileFields: ["files", "signature"],
        getAll: [
            {
                what: "All draft and completed incidentReviews",
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "in", ["draft", "completed"])],
            },
        ],
        getLatest: [
            {
                what: "The latest incidentReviews",
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    riskCategories: makeLicenseeCategoryDataSyncTaskConfig("riskCategories", "riskCategories"),
    risks: {
        hasPermission: () => canView("hazardRegister") && sharedState.licenseeSettings.current?.riskRegister?.version === 2,
        isDataLive: () => sharedState.risks.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active risks for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest risks for vessels I have access to!",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    risksReviewed: {
        hasPermission: () => canView("hazardRegister") && sharedState.licenseeSettings.current?.riskRegister?.version === 2,
        isDataLive: () => false,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active risksReviewed for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("whenReviewed", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest risksReviewed for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    safetyMeetingReports: {
        hasPermission: () => canView("healthSafetyMeetings"),
        isDataLive: () => sharedState.safetyMeetingReports.isActive,
        fileFields: ["files", "signature"],
        getAll: [
            {
                what: "All active safetyMeetingReports for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
            },
        ],
        getLatest: [
            {
                what: "The latest safetyMeetingReports for vessels I have access to",
                arrayQuery: () => ["vesselIds", "array-contains-any", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    seaTimeRecords: {
        hasPermission: () => true,
        isDataLive: () => false,
        fileFields: [],
        getAll: [
            {
                what: "All active seaTimeRecords",
                shouldGet: () => canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenVoyage", "desc")],
            },
            {
                what: "All my active crew seaTimeRecords",
                shouldGet: () => !canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active"), where("crewIds", "array-contains", sharedState.userId.current)],
                orderBy: [orderBy("whenVoyage", "desc")],
            },
            {
                what: "All my active skipper seaTimeRecords",
                shouldGet: () => !canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active"), where("masterIds", "array-contains", sharedState.userId.current)],
                orderBy: [orderBy("whenVoyage", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest seaTimeRecords",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                // Because crewIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) my seaTimeRecords",
                shouldGet: () => !canView("crewParticulars"),
                constraints: (touched) => [
                    where("licenseeId", "==", sharedState.licenseeId.current),
                    where("crewIds", "array-contains", sharedState.userId.current), // This could change AND it is used for security check!
                ],
                orderBy: [orderBy("whenVoyage", "desc")],
            },
            {
                // Because crewIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) my skipper seaTimeRecords",
                shouldGet: () => !canView("crewParticulars"),
                constraints: (touched) => [
                    where("licenseeId", "==", sharedState.licenseeId.current),
                    where("masterIds", "array-contains", sharedState.userId.current), // This could change AND it is used for security check!
                ],
                orderBy: [orderBy("whenVoyage", "desc")],
            },
        ],
    },
    trainingTasks: {
        hasPermission: () => canView("crewParticulars") || canView("crewTraining"),
        isDataLive: () => sharedState.trainingTasks.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active trainingTasks",
                shouldGet: () => canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("task", "asc")],
            },
            {
                what: "All active trainingTasks for vessels I have access to",
                shouldGet: () => !canView("crewParticulars"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("task", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest trainingTasks",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                what: "The latest trainingTasks for vessels I have access to",
                shouldGet: () => !canView("crewParticulars"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
        ],
    },
    trainingTaskReports: {
        hasPermission: () => true, // (true because you can always see your own training reports)
        isDataLive: () => sharedState.trainingTasks.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active trainingTaskReports",
                shouldGet: () => canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenDue", "desc")],
            },
            {
                what: "All active trainingTaskReports for vessels I have access to",
                shouldGet: () => !canView("crewParticulars") && canView("crewTraining"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: () => [where("state", "==", "active")],
                orderBy: [orderBy("whenDue", "desc")],
            },
            {
                what: "All my active trainingTaskReports",
                shouldGet: () => !canView("crewParticulars") && !canView("crewTraining"),
                constraints: () => [where("state", "==", "active"), where("completedBy", "array-contains", sharedState.userId.current)],
                orderBy: [orderBy("whenDue", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest active trainingTaskReports",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                what: "The latest trainingTaskReports for vessels I have access to",
                shouldGet: () => !canView("crewParticulars") && canView("crewTraining"),
                arrayQuery: () => ["vesselId", "in", sharedState.vesselIds.current],
                constraints: (touched) => [where("touched", ">", touched)],
            },
            {
                what: "All my latest active trainingTaskReports",
                shouldGet: () => !canView("crewParticulars") && !canView("crewTraining"),
                constraints: (touched) => [where("completedBy", "array-contains", sharedState.userId.current), where("touched", ">", touched)],
            },
        ],
    },
    userDetails: {
        hasPermission: () => true,
        isDataLive: () => sharedState.userDetails.isActive,
        fileFields: [],
        getAll: [
            {
                what: "All active and archived userDetails",
                shouldGet: () => canArchive("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "in", ["active", "archived"])],
            },
            {
                what: "All active userDetails",
                shouldGet: () => !canArchive("crewParticulars") && canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
            },
            {
                what: "My userDetails (for getAll)",
                shouldGet: () => !canView("crewParticulars"),
                singleDocument: () => doc(firestore, "userDetails", sharedState.userId.current as string),
            },
        ],
        getLatest: [
            // Note: firestore.rules doesn't block based on state - you just need view permission to get all crewDetails with all states
            {
                what: "The latest userDetails",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                what: "My userDetails (for getLatest)",
                shouldGet: () => !canView("crewParticulars"),
                singleDocument: (touched) => doc(firestore, "userDetails", sharedState.userId.current as string),
            },
        ],
    },
    userDocuments: {
        hasPermission: () => true, // (true because I can always at least access my own userDocuments)
        isDataLive: () => false,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active userDocuments",
                shouldGet: () => canView("crewParticulars"),
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
            {
                what: "All my active userDocuments",
                shouldGet: () => !canView("crewParticulars"),
                constraints: () => [where("userId", "==", sharedState.userId.current), where("state", "==", "active")],
            },
        ],
        getLatest: [
            {
                what: "The latest userDocuments",
                shouldGet: () => canView("crewParticulars"),
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
            {
                what: "All my latest userDocuments",
                shouldGet: () => !canView("crewParticulars"),
                constraints: (touched) => [where("userId", "==", sharedState.userId.current), where("touched", ">", touched)],
            },
        ],
    },
    userRoles: {
        hasPermission: () => canEdit("crewParticulars"),
        isDataLive: () => sharedState.userRoles.isActive,
        getAll: [
            {
                what: "All active userRoles",
                constraints: () => [where("licenseeId", "==", sharedState.licenseeId.current), where("state", "==", "active")],
                orderBy: [orderBy("state"), orderBy("name")],
            },
        ],
        getLatest: [
            {
                what: "The latest userRoles",
                constraints: (touched) => [where("licenseeId", "==", sharedState.licenseeId.current), where("touched", ">", touched)],
            },
        ],
    },
    somethingElse: {
        hello: "ben",
    },
} as {
    [collection in LicenseeDataSyncCollection]: LicenseeDataSyncTaskConfig;
};

const makeVesselCategoryDataSyncTaskConfig = (collection: string, sharedStateType?: SharedStateType) => {
    const config = {
        hasPermission: (vesselId) => true,
        isDataLive: (vesselId) => false,
        getAll: [
            {
                what: `All ${collection} for a vessel I have access to`,
                constraints: (vesselId) => [where("vesselId", "==", vesselId)],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: `The latest ${collection} for a vessel I have access to`,
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    } as VesselDataSyncTaskConfig;
    if (sharedStateType) {
        // We can test if the data is already live
        config.isDataLive = (vesselId) => {
            return isVesselActive(vesselId) && sharedState[sharedStateType].isActive ? true : false;
        };
    }
    return config;
};

export const vesselDataSyncTaskConfigs = {
    voyages: {
        hasPermission: (vesselId) => canView("logbook"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.voyages.isActive,
        fileFields: ["signature"],
        getAll: [
            {
                what: "All started and completed voyages for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "in", ["started", "completed"])],
                orderBy: [orderBy("whenDeparted", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest voyages for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    voyageDocuments: {
        hasPermission: (vesselId) => canView("logbook"),
        isDataLive: (vesselId) => false,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active voyageDocuments for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenAdded", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest voyageDocuments for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    safetyCheckItems: {
        hasPermission: (vesselId) => canView("safetyEquipmentChecks"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.safetyCheckItems.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active safetyCheckItems for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenDue", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest safetyCheckItems for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    safetyCheckCategories: makeVesselCategoryDataSyncTaskConfig("safetyCheckCategories", "safetyCheckItems"),
    safetyCheckCompleted: {
        hasPermission: (vesselId) => canView("safetyEquipmentChecks"),
        isDataLive: (vesselId) => false,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active safetyCheckCompleted for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenCompleted", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest safetyCheckCompleted for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    drills: {
        hasPermission: (vesselId) => true, // (No permission required because we need drills for userModal drills tab if me)
        isDataLive: (vesselId) => sharedState.drills.isActive || (isVesselActive(vesselId) && canView("drills") && sharedState.vesselDrills.isActive),
        getAll: [
            {
                what: "All active drills for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenDue", "asc")], // (for vessel dashboard query)
            },
        ],
        getLatest: [
            {
                what: "The latest drills for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    drillReports: {
        hasPermission: (vesselId) => true,
        isDataLive: (vesselId) => false,
        fileFields: ["files", "signature"],
        getAll: [
            {
                what: "All active drillReports for a vessel I have access to",
                shouldGet: () => canView("drills"),
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenCompleted", "desc")],
            },
            {
                // My drillReports only
                what: "All my active drillReports for a vessel I have access to",
                shouldGet: () => !canView("drills"),
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("crewInvolvedIds", "array-contains", sharedState.userId.current), where("state", "==", "active")],
                orderBy: [orderBy("whenCompleted", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest drillReports for a vessel I have access to",
                shouldGet: () => canView("drills"),
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
            {
                // Because crewInvolvedIds is BOTH required for security AND can change, we'll always need to get all docs for this rather than just the latest
                what: "All (forced!) my active drillReports for a vessel I have access to",
                shouldGet: () => !canView("drills"),
                constraints: (touched, vesselId) => [
                    where("vesselId", "==", vesselId),
                    where("crewInvolvedIds", "array-contains", sharedState.userId.current), // This could change AND it is used for security check!
                    where("state", "==", "active"),
                ],
                orderBy: [orderBy("whenCompleted", "desc")],
            },
        ],
    },
    vesselCertificates: {
        hasPermission: (vesselId) => canView("vesselCertificates"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.vesselCertificates.isActive && sharedState.archivedVesselCertificates.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active vesselCertificates for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
            {
                // All archived
                what: "All archived vesselCertificates for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "archived")],
                orderBy: [orderBy("whenArchived", "desc")],
            },
        ],
        getLatest: [
            {
                // This only needs one query because by not constraining by state we get docs for both queries within getAll above
                what: "The latest vesselCertificates for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    engines: {
        hasPermission: (vesselId) => true,
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.engines.isActive,
        getAll: [
            {
                what: "All engines for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId)],
                orderBy: [orderBy("name", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest engines for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    vesselSystems: makeVesselCategoryDataSyncTaskConfig("vesselSystems", "vesselSystems"),
    vesselLocations: makeVesselCategoryDataSyncTaskConfig("vesselLocations", "vesselLocations"),
    vesselDocumentCategories: makeVesselCategoryDataSyncTaskConfig("vesselDocumentCategories", undefined),
    vesselSafetyItems: makeVesselCategoryDataSyncTaskConfig("vesselSafetyItems", "vesselSafetyItems"),

    safetyEquipmentItems: {
        hasPermission: (vesselId) => canView("safetyEquipmentList"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.safetyEquipmentItems.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active safetyEquipmentItems for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenDue", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest safetyEquipmentItems for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    safetyEquipmentTaskCompleted: {
        hasPermission: (vesselId) => canView("safetyEquipmentList"),
        isDataLive: (vesselId) => false,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active safetyEquipmentTaskCompleted for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("whenCompleted", "==", "desc")],
                orderBy: [orderBy("whenDue", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest safetyEquipmentTaskCompleted for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    equipment: {
        hasPermission: (vesselId) => canView("maintenanceSchedule") || canView("jobList") || canView("maintenanceHistory") || canView("sparePartsList") || canView("equipmentManualDocuments"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.equipment.isActive,
        getAll: [
            {
                what: "All active or deleted equipment for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "in", ["active", "deleted"])],
                orderBy: [orderBy("equipment", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest equipment for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    scheduledMaintenanceTasks: {
        hasPermission: (vesselId) => canView("maintenanceSchedule"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.scheduledMaintenanceTasks.isActive,
        getAll: [
            {
                what: "All active scheduledMaintenanceTasks for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
            },
        ],
        getLatest: [
            {
                what: "The latest scheduledMaintenanceTasks for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    equipmentManualDocuments: {
        hasPermission: (vesselId) => canView("equipmentManualDocuments"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.equipmentManualDocuments.isActive,
        getAll: [
            {
                what: "All active equipmentManualDocuments for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest equipmentManualDocuments for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    maintenanceTasksCompleted: {
        hasPermission: (vesselId) => canView("maintenanceHistory"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.maintenanceTasksCompleted.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All completed maintenanceHistory for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "completed")],
                orderBy: [orderBy("whenCompleted", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest maintenanceHistory for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    jobs: {
        hasPermission: (vesselId) => canView("jobList") || canView("healthSafetyMeetings"),
        isDataLive: (vesselId) => false, // We can't assume we have the latest with sharedState.jobs.isActive because safetyMeetingJobs also requires completed jobs
        fileFields: ["files"],
        getAll: [
            {
                what: "All active jobs for a vessel I have access to",
                shouldGet: () => canView("jobList"),
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("priority", "desc"), orderBy("task", "asc")],
            },
            {
                what: "All active and completed safety meeting jobs for a vessel I have access to",
                shouldGet: () => canView("healthSafetyMeetings"),
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "in", ["active", "completed"]), where("addedFromMeetingId", "!=", null)],
            },
        ],
        getLatest: [
            {
                what: "The latest jobs for a vessel I have access to",
                shouldGet: () => canView("jobList"),
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
            {
                // Because we can't have are 2 inequality constraints, we're forced to getAll
                what: "All (forced!) active and completed safety meeting jobs for a vessel I have access to",
                shouldGet: () => !canView("jobList"),
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("state", "in", ["active", "completed"]), where("addedFromMeetingId", "!=", null)],
            },
        ],
    },
    spareParts: {
        hasPermission: (vesselId) => canView("sparePartsList"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.jobs.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active spareParts for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("item", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest spareParts for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },

    vesselDocuments: {
        hasPermission: (vesselId) => canView("vesselDocuments"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.vesselDocuments.isActive,
        fileFields: ["files", "sfdoc"],
        getAll: [
            {
                what: "All active vesselDocuments for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest vesselDocuments for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    surveyReports: {
        hasPermission: (vesselId) => canView("survey"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.vesselSurveyReports.isActive,
        fileFields: ["files"],
        getAll: [
            {
                what: "All active surveyReports for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("whenSurveyed", "desc")],
            },
        ],
        getLatest: [
            {
                what: "The latest surveyReports for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
    vesselSopCategories: makeVesselCategoryDataSyncTaskConfig("vesselSopCategories", undefined),
    SOPs: {
        hasPermission: (vesselId) => canView("standardOperatingProcedures"),
        isDataLive: (vesselId) => isVesselActive(vesselId) && sharedState.vesselSOPs.isActive,
        fileFields: ["files", "sfdoc"],
        getAll: [
            {
                what: "All active SOPs for a vessel I have access to",
                constraints: (vesselId) => [where("vesselId", "==", vesselId), where("state", "==", "active")],
                orderBy: [orderBy("title", "asc")],
            },
        ],
        getLatest: [
            {
                what: "The latest SOPs for a vessel I have access to",
                constraints: (touched, vesselId) => [where("vesselId", "==", vesselId), where("touched", ">", touched)],
            },
        ],
    },
} as {
    [collection in VesselDataSyncCollection]: VesselDataSyncTaskConfig;
};

export type DataSyncTask = {
    type: "licensee" | "vessel";
    collection: LicenseeDataSyncCollection | VesselDataSyncCollection;
    vesselId?: string; // Needed for VesselDataSyncCollections
    touched: number;
};

export const dataSyncTasksQueueConfig: SharedStateConfig<DataSyncTask[]> = {
    isAlwaysActive: true,
    default: [],
    notes: "Data sync tasks that are waiting to be processed",
};

export const handleDataSyncTasks: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ["dataSyncTasksQueue", "onlineStatus"],
    default: "Not run.",
    run: (done, set, clear) => {
        done();
        const dataSyncTasksQueue = sharedState.dataSyncTasksQueue.current;
        const onlineStatus = sharedState.onlineStatus.current;
        if (!onlineStatus?.isOnline) {
            set(`Current offline.`);
            return;
        }
        if (dataSyncTasksQueue?.length) {
            // Make sure data sync tasks are being processed if they're not already
            set(`Processing queue...`);
            processDataSyncTasks();
        } else {
            set(`Queue is currently empty.`);
        }
    },
    notes: "Triggers data sync tasks to be processed if there's a queue or if we've just come online.",
};

let isProcessingTasks = false;
const processDataSyncTasks = () => {
    if (isProcessingTasks || !sharedState.onlineStatus.current?.isOnline || (sharedState.dataSyncTasksQueue.current as DataSyncTask[]).length === 0) {
        return;
    }
    isProcessingTasks = true; // (only one processDataSyncTasks should run at a time)
    const dataSyncTasksQueue = sharedState.dataSyncTasksQueue.current as DataSyncTask[];
    // Grab a task from the queue (removing it from dataSyncTasksQueue)
    const newQueue = [...dataSyncTasksQueue];
    const task = newQueue.shift() as DataSyncTask;
    sharedState.dataSyncTasksQueue.set(newQueue);
    // Process task
    setTimeout(() => {
        processTask(task)
            .then((maxTouchedFound: number) => {
                console.log(`processDataSyncTasks: Successfully processed dataSync task (maxTouchedFound=${maxTouchedFound})`, task);
                // Update cachedDataInto accordingly
                const touched = Math.max(maxTouchedFound, task.touched);
                if (task.type === "licensee") {
                    updateLicenseeCachedDataInfo(task.collection as LicenseeDataSyncCollection, touched);
                } else {
                    updateVesselCachedDataInfo(task.vesselId as string, task.collection as VesselDataSyncCollection, touched);
                }
                sharedState.dataSyncStatus.set((current: any) => {
                    return {
                        ...current,
                        tasksLeft: (current?.tasksLeft ?? 0) - 1 ?? 0,
                        totalTasks: sharedState.dataSyncTasksQueue.current?.length ? current.totalTasks : 0,
                    };
                });
                isProcessingTasks = false;
                processDataSyncTasks();
            })
            .catch((error: any) => {
                console.error(`processDataSyncTasks: Error processing task!`, task, error);
                // Since it failed, we should try again after a delay
                setTimeout(() => {
                    isProcessingTasks = false;
                    if (task.type === "licensee") {
                        onFreshLicenseeData(task.collection as LicenseeDataSyncCollection, task.touched);
                    } else {
                        onFreshVesselData(task.vesselId as string, task.collection as VesselDataSyncCollection, task.touched);
                    }
                }, 2000);
            });
        // Maybe we should have a timeout here?
    }, 100);
};

const processTask = (task: DataSyncTask) => {
    let maxTouched = task.touched;
    const taskConfig = (task.type === "licensee" ? licenseeDataSyncTaskConfigs[task.collection as LicenseeDataSyncCollection] : vesselDataSyncTaskConfigs[task.collection as VesselDataSyncCollection]) as
        | LicenseeDataSyncTaskConfig
        | VesselDataSyncTaskConfig;
    // Let's assume we'll just use getAll for now
    const processDocs = (docs: void | QueryDocumentSnapshot<DocumentData, DocumentData>[] | DocumentSnapshot<DocumentData, DocumentData>[]) => {
        const _maxTouched = processTaskQueryResults(task, taskConfig, docs);
        if (_maxTouched > maxTouched) {
            maxTouched = _maxTouched;
        }
    };
    const promises = [] as Promise<any>[];
    let count = 0;
    let countDocs = 0;

    const cacheTouched = task.type === "licensee" ? cachedDataInfo.licensee[task.collection as LicenseeDataSyncCollection] : task.vesselId && cachedDataInfo.vessels[task.vesselId]?.[task.collection as VesselDataSyncCollection];
    const getType = cacheTouched ? "getLatest" : "getAll";

    const constraintParams = [] as (string | Timestamp)[];
    if (cacheTouched) {
        // getLatest constraints use cacheTouched
        constraintParams.push(Timestamp.fromMillis(cacheTouched));
    }
    if (task.vesselId) {
        constraintParams.push(task.vesselId);
    }

    taskConfig[getType].forEach((q) => {
        if (!q.shouldGet || q.shouldGet()) {
            console.log(`[<>] DataSync: processTask ${getType} query: ${q.what}`);
            if (q.arrayQuery) {
                // Array query
                const [arrayField, arrayComparison, array] = q.arrayQuery();
                promises.push(
                    getArrayQueryResults(
                        task.collection,
                        collection(firestore, task.collection),
                        q.constraints ? (q as any).constraints(...constraintParams) : [], // baseConstraints
                        arrayField,
                        arrayComparison,
                        array,
                        q.orderBy ? q.orderBy : []
                    ).then((docs) => {
                        processDocs(docs);
                        count++;
                        countDocs += docs ? docs.length : 0;
                    })
                );
            } else if (q.singleDocument) {
                // Single document query
                promises.push(
                    getDoc((q as any).singleDocument(...constraintParams)).then((doc) => {
                        if (doc.exists()) {
                            processDocs([doc as DocumentSnapshot<DocumentData, DocumentData>]);
                            count++;
                            countDocs++;
                        }
                    })
                );
            } else {
                // Standard query
                promises.push(
                    getDocs(query(collection(firestore, task.collection), ...(q.constraints ? (q as any).constraints(...constraintParams) : []), ...(q.orderBy ?? []))).then((snap) => {
                        processDocs(snap.docs);
                        count++;
                        countDocs += snap.docs.length;
                    })
                );
            }
        }
    });
    return Promise.all(promises).then(() => {
        console.log(`[<>] processTask: processed all queries (${count}). docs=${countDocs} maxTouched=${maxTouched}`);
        return Promise.resolve(maxTouched);
    });
};

// Registers any file references found to fileSync.
// Returns the maximum touched value found.
const processTaskQueryResults = (task: DataSyncTask, taskConfig: LicenseeDataSyncTaskConfig | VesselDataSyncTaskConfig, docs: void | QueryDocumentSnapshot<DocumentData, DocumentData>[] | DocumentSnapshot<DocumentData, DocumentData>[]) => {
    const fileFields = taskConfig.fileFields ? taskConfig.fileFields : [];
    let maxTouched = 0;
    if (docs) {
        docs.forEach((doc) => {
            const data = doc.data() as any;
            if (data.touched && (data.touched as Timestamp).toMillis() > maxTouched) {
                maxTouched = (data.touched as Timestamp).toMillis();
            }
            // Register any files included in results
            fileFields.forEach((field) => {
                if (field === "signature") {
                    registerSignature(data.signature);
                } else if (field === "sfdoc") {
                    registerRichText(data.sfdoc);
                } else if (field === "data...") {
                    // customFormsCompleted elements with file content
                    if (sharedState.customFormVersions.current?.byFormIdAndVersion[data.customFormId]) {
                        const formVersion = sharedState.customFormVersions.current.byFormIdAndVersion[data.customFormId][data.version];
                        Object.keys(formVersion.form).forEach((key) => {
                            if (formVersion.form[key].id === "signature") {
                                registerSignature(data.data[key]);
                            } else if (formVersion.form[key].id === "files") {
                                registerFiles(data.data[key]);
                            }
                        });
                    }
                } else if (field === "form...") {
                    // customFormVersions help files
                    if (data.form) {
                        Object.keys(data.form).forEach((key) => {
                            const element = data.form[key];
                            if (element.help && element.help.files && element.help.files.length > 0) {
                                registerFiles(element.help.files);
                            }
                        });
                    }
                } else {
                    registerFiles(data[field]);
                }
            });
        });
    }
    return maxTouched;
};

// This is called from within whenLicenseeTouched when it knows there is new data we may need to cache
export const onFreshLicenseeData = (
    collection: LicenseeDataSyncCollection,
    touched: number // when latest fresh data changed (originates from a FieldValue.serverTimestamp)
) => {
    // Queue getting fresh data
    const licenseeId = sharedState.licenseeId.current;
    const dataSyncTasksQueue = sharedState.dataSyncTasksQueue.current;
    if (!licenseeId || !dataSyncTasksQueue) {
        return;
    }
    if (licenseeDataSyncTaskConfigs[collection]) {
        const taskConfig = licenseeDataSyncTaskConfigs[collection] as LicenseeDataSyncTaskConfig;
        // Attempt to find a matching task that is already in the queue
        let index = -1;
        for (let i = 0; i < dataSyncTasksQueue.length; i++) {
            if (dataSyncTasksQueue[i].collection === collection && dataSyncTasksQueue[i].type === "licensee") {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            // A task for this collection already exists, therefore we can ignore this.
            // Let's just update what the touched value is if it is newer.
            //console.log(`[<>] onFreshLicenseeData: A licensee task already exists for collection=${collection}`);
            if (touched > dataSyncTasksQueue[index].touched) {
                const newQueue = [...dataSyncTasksQueue];
                newQueue[index] = {
                    ...newQueue[index],
                    touched: touched,
                };
                sharedState.dataSyncTasksQueue.set(newQueue);
            }
        } else if (!taskConfig.hasPermission()) {
            // We don't have permission to sync this collection.
            // As permissions could change in the future, we don't want to record any touched values to the cachedDataInfo
            //console.log(`[<>] onFreshLicenseeData: We don't have permission to sync collection=${collection}`);
        } else if (taskConfig.isDataLive()) {
            // The data we are considering syncing is already being actively listened to.
            // Therefore, we don't need to process this task - but we should record the latest touched value to cachedDataInfo (because we can assume we'll already have it)
            //console.log(`[<>] onFreshLicenseeData: Data is already actively being listened to for collection=${collection}`);
            updateLicenseeCachedDataInfo(collection, touched);
        } else {
            // This collection needs updating to the local cache
            //console.log(`[<>] onFreshLicenseeData: Adding licensee task to the queue for collection=${collection}`);
            sharedState.dataSyncTasksQueue.set([
                ...dataSyncTasksQueue,
                {
                    // New licensee task added to the end of the queue
                    type: "licensee",
                    collection: collection,
                    touched: touched,
                },
            ]);
            sharedState.dataSyncStatus.set((current: any) => {
                return {
                    ...current,
                    totalTasks: (current?.totalTasks ?? 0) + 1,
                    tasksLeft: (sharedState.dataSyncTasksQueue.current?.length ?? 0) + 1,
                };
            });
        }
    } else {
        console.log(`onFreshLicenseeData: no config found for collection=${collection}!`);
    }
};

// This is called from within whenVesselTouched when it knows there is new data we may need to cache
export const onFreshVesselData = (
    vesselId: string,
    collection: VesselDataSyncCollection,
    touched: number // when latest fresh data changed (originates from a FieldValue.serverTimestamp)
) => {
    // Queue getting fresh data
    const dataSyncTasksQueue = sharedState.dataSyncTasksQueue.current;
    if (!vesselId || !dataSyncTasksQueue) {
        return;
    }
    if (vesselDataSyncTaskConfigs[collection]) {
        const taskConfig = vesselDataSyncTaskConfigs[collection] as VesselDataSyncTaskConfig;
        //console.log(`[<>] onFreshVesselData vesselId=${vesselId} collection=${collection} touched=${formatDatetime(touched)}`);
        // Attempt to find a matching task that is already in the queue
        let index = -1;
        for (let i = 0; i < dataSyncTasksQueue.length; i++) {
            if (dataSyncTasksQueue[i].collection === collection && dataSyncTasksQueue[i].type === "vessel" && dataSyncTasksQueue[i].vesselId === vesselId) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            // A task for this vessel collection already exists, therefore we can ignore this.
            // Let's just update what the touched value is if it is newer.
            //console.log(`[<>] onFreshVesselData: A vessel task already exists for collection=${collection} vesselId=${vesselId}`);
            if (touched > dataSyncTasksQueue[index].touched) {
                const newQueue = [...dataSyncTasksQueue];
                newQueue[index] = {
                    ...newQueue[index],
                    touched: touched,
                };
                sharedState.dataSyncTasksQueue.set(newQueue);
            }
        } else if (!taskConfig.hasPermission(vesselId)) {
            // We don't have permission to sync this collection.
            // As permissions could change in the future, we don't want to record any touched values to the cachedDataInfo
            //console.log(`[<>] onFreshVesselData: We don't have permission to sync collection=${collection} for vesselId=${vesselId}`);
        } else if (taskConfig.isDataLive(vesselId)) {
            // The data we are considering syncing is already being actively listened to.
            // Therefore, we don't need to process this task - but we should record the latest touched value to cachedDataInfo (because we can assume we'll already have it)
            //console.log(`[<>] onFreshVesselData: Data is already actively being listened to for collection=${collection} for vesselId=${vesselId}`);
            updateVesselCachedDataInfo(vesselId, collection, touched);
        } else {
            // This collection needs updating to the local cache
            //console.log(`[<>] onFreshVesselData: Adding vessel task to the queue for collection=${collection} and vesselId=${vesselId}`);
            sharedState.dataSyncTasksQueue.set([
                ...dataSyncTasksQueue,
                {
                    // New vessel task added to the end of the queue
                    type: "vessel",
                    vesselId: vesselId,
                    collection: collection,
                    touched: touched,
                },
            ]);
            sharedState.dataSyncStatus.set((current: any) => {
                return {
                    ...current,
                    totalTasks: (current?.totalTasks ?? 0) + 1,
                    tasksLeft: (sharedState.dataSyncTasksQueue.current?.length ?? 0) + 1,
                };
            });
        }
    } else {
        console.log(`onFreshVesselData: no config found for collection=${collection}!`);
    }
};
