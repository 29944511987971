import React, { useEffect, useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { IonAlert, isPlatform } from '@ionic/react';
import { refreshApp } from '../../App';
import { toInt } from '../../lib/util';
import packageJson from '../../../package.json';
import SeaButton from '../../components/SeaButton/SeaButton';
import './ServerInfoManager.css';

//
// Handles serverInfo interrupting the app when a newer build version is available.
//

const openAppStore = () => {
    if (isPlatform('ios')) {
        window.open(
            'itms-apps://itunes.apple.com/app/apple-store/id1560908960?mt=8',
            '_system',
            'location=yes'
        );
    } else {
        window.open(
            'https://play.google.com/store/apps/details?id=nz.co.orchid.seaflux',
            '_system',
            'location=yes'
        );
    }
};

const ServerInfoManager: React.FC = () => {
    const serverInfo = sharedState.serverInfo.use();
    const [newBuildMessage, setNewBuildMessage] = useState({
        show: false,
        showRequired: false,
        text: '',
        buttons: [] as any[]
    });

    useEffect(() => {
        if (serverInfo) {
            const appBuild = toInt(packageJson.build, 0);
            if (isPlatform('hybrid')) {
                if (isPlatform('ios')) {
                    if (appBuild < serverInfo.iosBuildAvailable) {
                        setNewBuildMessage({
                            show: true,
                            showRequired: (appBuild < serverInfo.iosBuildRequired),
                            text: 'A new version of Sea Flux is now available! Would you like to install the update? (Recommended)',
                            buttons: [
                                'Not right now',
                                {
                                    text: 'Yes (Open App Store)',
                                    handler: (e: any) => {
                                        openAppStore();
                                    }
                                }
                            ]
                        });
                    }
                } else {
                    if (appBuild < serverInfo.androidBuildAvailable) {
                        setNewBuildMessage({
                            show: true,
                            showRequired: (appBuild < serverInfo.androidBuildRequired),
                            text: 'A new version of Sea Flux is now available! Would you like to install the update? (Recommended)',
                            buttons: [
                                'Not right now',
                                {
                                    text: 'Yes (Open Google Play)',
                                    handler: (e: any) => {
                                        openAppStore();
                                    }
                                }
                            ]
                        });
                    }
                }
            } else {
                if (appBuild < serverInfo.webBuildRequired) {
                    // Web is obsolete... force a restart ASAP!
                    refreshApp(`Web is obsolete... force a restart ASAP! appBuild=${appBuild} serverInfo.webBuildRequired=${serverInfo.webBuildRequired}`);
                } else if (appBuild < serverInfo.webBuildAvailable) {
                    setNewBuildMessage({
                        show: true,
                        showRequired: false,
                        text: 'A new version of Sea Flux has just become available! Would you like to start using it now? (Recommended)',
                        buttons: [
                            'Not right now',
                            {
                                text: 'Yes (will refresh page)',
                                handler: (e: any) => {
                                    window.location = window.location; // eslint-disable-line no-self-assign
                                }
                            }
                        ]
                    });
                }
            }
        }
    }, [serverInfo]);

    return <>
        <IonAlert
            isOpen={newBuildMessage.show}
            message={newBuildMessage.text}
            buttons={newBuildMessage.buttons}
            onDidDismiss={(e) => setNewBuildMessage((msg) => {
                return {
                    ...msg,
                    show: false
                };
            })}
            backdropDismiss={false}
        />
        {newBuildMessage.showRequired &&
            <div className="sea-error-report-backdrop" style={{ backgroundColor: '#373946' }}>
                <div className="sea-error-report" style={{ textAlign: 'center' }}>
                    <h1>Your Sea Flux app is out of date!</h1>
                    <br/>
                    <p>
                        Please download and install the latest version of Sea Flux from {isPlatform('ios') ? 'The Apple App Store' : 'Google Play'}.
                    </p>
                    <div style={{ padding: '32px 16px 16px' }}>
                        <SeaButton onClick={(e) => openAppStore()}>
                            Open {isPlatform('ios') ? ' App Store' : 'Google Play'}...
                        </SeaButton>
                    </div>
                </div>
            </div>
        }
        {serverInfo?.underMaintenance &&
            <div className="sea-error-report-backdrop" style={{ backgroundColor: '#373946', color: 'white', textAlign: 'center' }}>
                <div className="cbl-centered">
                    <div className="cbl-logo">
                        <img src="/assets/seaflux.png" alt="Sea Flux Logo"/>
                    </div>
                    <div className={`cbl-box`}>
                        <h2>Sea Flux is currently down for planned maintenance.</h2>
                        <p>
                            We expect to be back within 2 hours. Thanks for your patience.
                        </p>
                    </div>
                </div>
            </div>
        }
    </>;
};

export default ServerInfoManager;
