import React, { useCallback } from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn } from "../../lib/pdf";
import { formatDate, warnDays, formatInterval } from "../../lib/util";
import { renderCategoryName } from "../../lib/categories";
import { renderFullName } from "../../shared-state/Core/users";
import { sharedState } from '../../shared-state/shared-state';
import { ScheduledMaintenanceTask } from '../../shared-state/VesselMaintenance/maintenanceSchedule';
import SeaPdfGenerator from "../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../components/PdfItemsHeader/PdfItemsHeader";
import SeaStatusDueDate from "../../components/SeaStatusDueDate/SeaStatusDueDate";
import SeaStatusDueHours from "../../components/SeaStatusDueHours/SeaStatusDueHours";
import CriticalFlagPDF from "../../assets/svg/CriticalFlagPDF";

interface MaintenanceSchedulePdfProps {
    generatingPdf: boolean;
    setGeneratingPdf: (generatingPdf: boolean) => void;
    listType: string;
    maintenanceTasks: ScheduledMaintenanceTask[];
}

const MaintenanceSchedulePdf: React.FC<MaintenanceSchedulePdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    listType,
    maintenanceTasks,
}) => {
    const vessel = sharedState.vessel.use(generatingPdf);
    const vesselSystems = sharedState.vesselSystems.use(generatingPdf);
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(generatingPdf);
    const title = `Maintenance Schedule - ${vessel?.name}`;

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={
                            listType !== "all"
                                ? `Overdue & Due Within ${listType} Days`
                                : undefined
                        }
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={styleItemColumn(1)}>
                            <Text>Equipment</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Maintenance Task</Text>
                        </View>
                        <View style={pdfStyles.intervalColumn}>
                            <Text>Interval</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Next Due</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                    </View>
                    <View
                        wrap={false}
                        style={pdfStyles.itemHeadingsLine}
                    ></View>
                    {maintenanceTasks || scheduledMaintenanceTasks?.bySystemId ? (
                        listType === "all" ? (
                            vesselSystems?.ids?.map((systemId: string, i: number) => {
                                if ( scheduledMaintenanceTasks?.bySystemId[systemId] === undefined ) {
                                    return null;
                                }
                                return (
                                    <React.Fragment key={systemId}>
                                        {/* Render System Text */}
                                        <View style={pdfStyles.labelHeading}>
                                            <Text style={pdfStyles.itemsCategory}>
                                                {renderCategoryName(
                                                        systemId,
                                                        vesselSystems
                                                    )}
                                            </Text>
                                        </View>
                                        {/* Map each item in this system */}
                                        {scheduledMaintenanceTasks.bySystemId[systemId]?.map(
                                        (item: ScheduledMaintenanceTask, index: number) => {
                                            const isLast = index === scheduledMaintenanceTasks.bySystemId[systemId].length - 1;
                                            return (
                                                <React.Fragment key={item.id}>
                                                    {index > 0 && (
                                                        <View
                                                            style={pdfStyles.itemsRowSpacer}
                                                        ></View>
                                                    )}
                                                    <View
                                                        wrap={false}
                                                        style={pdfStyles.itemsRow}
                                                    >
                                                        <View style={styleItemColumn(1)}>
                                                            <Text>
                                                                {item.equipment?.equipment}
                                                            </Text>
                                                        </View>
                                                        <View style={styleItemColumn(1)}>
                                                            <Text>
                                                                {item.task}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={pdfStyles.intervalColumn}
                                                        >
                                                            <Text>
                                                                {(item.intervalType ===
                                                                    "weekMonth" ||
                                                                    item.intervalType ===
                                                                        "weekMonthAndHours") &&
                                                                    formatInterval(
                                                                        item.intervalWeekMonth
                                                                    )}
                                                                {item.intervalType ===
                                                                    "weekMonthAndHours" && (
                                                                    <br />
                                                                )}
                                                                {(item.intervalType ===
                                                                    "engineHours" ||
                                                                    item.intervalType ===
                                                                        "weekMonthAndHours") &&
                                                                    `${item.intervalEngineHours} Hours`
                                                                }
                                                            </Text>
                                                        </View>
                                                        <View style={pdfStyles.dateColumn}>
                                                            <Text>
                                                                {formatDate(item.whenDue)}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={pdfStyles.statusColumn}
                                                        >
                                                            {item.useHours ?
                                                                <SeaStatusDueHours
                                                                    engineHoursLeft={item.engineHoursLeft as number}
                                                                    inline={true}
                                                                    forPdf={true}
                                                                />
                                                            :
                                                                <SeaStatusDueDate
                                                                    whenDue={item.whenDue as number}
                                                                    warnDays={warnDays.maintenanceSchedule[0]}
                                                                    hasFault={item.hasFault}
                                                                    forPdf={true}
                                                                />
                                                            }
                                                        </View>
                                                        <View style={pdfStyles.criticalColumn}>
                                                            {item.isCritical && (
                                                                <CriticalFlagPDF />
                                                            )}
                                                        </View>
                                                    </View>
                                                    {isLast && (
                                                        <View style={pdfStyles.itemsCategorySpacer} />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })
                        ) : ( // listType !== 'all'
                            maintenanceTasks?.map((item: ScheduledMaintenanceTask, index: number) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {index > 0 && (
                                            <View
                                                style={pdfStyles.itemsRowSpacer}
                                            ></View>
                                        )}
                                        <View
                                            wrap={false}
                                            style={pdfStyles.itemsRow}
                                        >
                                            <View style={styleItemColumn(1)}>
                                                <Text>
                                                    {item.equipment?.equipment}
                                                </Text>
                                            </View>
                                            <View style={styleItemColumn(1)}>
                                                <Text>
                                                    {item.task}
                                                </Text>
                                            </View>
                                            <View
                                                style={pdfStyles.intervalColumn}
                                            >
                                                <Text>
                                                    {(item.intervalType ===
                                                        "weekMonth" ||
                                                        item.intervalType ===
                                                            "weekMonthAndHours") &&
                                                        formatInterval(
                                                            item.intervalWeekMonth
                                                        )}
                                                    {item.intervalType ===
                                                        "weekMonthAndHours" && (
                                                        <br />
                                                    )}
                                                    {(item.intervalType ===
                                                        "engineHours" ||
                                                        item.intervalType ===
                                                            "weekMonthAndHours") &&
                                                        `${item.intervalEngineHours} Hours`
                                                    }
                                                </Text>
                                            </View>
                                            <View style={pdfStyles.dateColumn}>
                                                <Text>
                                                    {formatDate(item.whenDue)}
                                                </Text>
                                            </View>
                                            <View
                                                style={pdfStyles.statusColumn}
                                            >
                                                {item.useHours && item.engineHoursLeft ?
                                                    <SeaStatusDueHours
                                                        engineHoursLeft={item.engineHoursLeft}
                                                        inline={true}
                                                        forPdf={true}
                                                    />
                                                :
                                                    <SeaStatusDueDate
                                                        whenDue={item.whenDue as number}
                                                        warnDays={warnDays.maintenanceSchedule[0]}
                                                        hasFault={item.hasFault}
                                                        forPdf={true}
                                                    />
                                                }
                                            </View>
                                            <View style={pdfStyles.criticalColumn}>
                                                {item.equipment?.isCritical && (
                                                    <CriticalFlagPDF />
                                                )}
                                            </View>
                                        </View>
                                    </React.Fragment>
                                );
                            })
                        )
                    ) : (
                        <></>
                    )}
                </Page>
            </Document>
        );
    }, [title, listType, maintenanceTasks, vesselSystems, scheduledMaintenanceTasks?.bySystemId]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="MaintenanceSchedule"
        />
    );
};

export default MaintenanceSchedulePdf;
