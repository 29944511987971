import React, {useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IonSelectOption } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { Vessel } from '../../shared-state/Core/vessel';
import SeaSelect from '../SeaSelect/SeaSelect';
import QueryString from 'query-string';
import './SeaSelectVesselFilter.css';

interface SeaSelectVesselFilterProps {
    filterVesselId?: string | 'all',
    setFilterVesselId: React.Dispatch<React.SetStateAction<string | undefined>>,
    includeAll?: boolean
}

const SeaSelectVesselFilter: React.FC<SeaSelectVesselFilterProps> = ({ filterVesselId, setFilterVesselId, includeAll = true }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const vessels = sharedState.vessels.use();
    const vesselId = sharedState.vesselId.use();
    // Run once on init
    // If we don't have a vesselId AND if query string contains vesselId, use query string's value
    useEffect(() => {
        setFilterVesselId((previous: string | undefined) => {
            if (previous) {
                return previous;
            }
            if (location?.search) { // Process query string
                const qs = QueryString.parse(location.search);
                if (qs.vesselId) {
                    // if (vessels) {
                    //     if (vessels.byId[''+qs.vesselId]) {
                            return qs.vesselId as string;
                    //     }
                    // } else {
                    //     waitingForVessels.current = true;
                    // }
                }
            }
            return vesselId ? vesselId : (includeAll ? 'all' : undefined);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSetFilterVesselId = useCallback((vesselId: string) => {
        if (location?.search) {
            const params = QueryString.parse(location.search)
            params.vesselId = vesselId;
            // Update Url to use tab in query string
            navigate(`${location.pathname}?${QueryString.stringify(params)}`, { replace: true });
            setFilterVesselId(vesselId);
        }
    }, [location?.pathname, location?.search, navigate, setFilterVesselId]);

    return (
        <SeaSelect
            value={filterVesselId}
            width="200px"
            zone="grey"
            onchange={(e) => onSetFilterVesselId(e.detail.value)}
        >
            {includeAll &&
                <IonSelectOption value="all">All Vessels</IonSelectOption>
            }
            {vessels?.all?.map((vessel: Vessel) => {
                return (
                    <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                );
            })}
        </SeaSelect>
    );
};

export default SeaSelectVesselFilter;
