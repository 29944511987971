import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { canArchive, canView } from "../../lib/permissions";
import { renderFullNameForUserId } from '../Core/users';

//
// Loads userDetails for users the logged in user has permission to see.
// At minimum, we always load the user's own userDetails.
//

export interface UserDetails {
    id: string;
    address?: string;
    allergies?: string;
    bankAccountNumber?: string;
    bloodType?: string;
    companyAddress?: string;
    companyName?: string;
    contactNumber?: string;
    contractFiles?: string[];
    crewInductionFiles?: string[];
    currentMedicalIssues?: string;
    currentMedication?: string;
    dateOfBirth?: number;
    email: string;
    emailMe?: any;
    emailMeVesselIds?: any;
    inductionFiles?: any;
    irdNumber?: string;
    licenseeId: string;
    medicalDoctor?: any;
    name?: string;
    nextOfKin?: any;
    numVessels?: number;
    passportNumber?: string;
    previousInjuryOrSurgery?: string;
    role?: string;
    searchText?: string;
    skipperInductionFiles?: any;
    state: string;
    weeklyReport?: any;
    weeklyReportVesselIds?: any;
}

export type UserDetailsData = {
    byId: {
        [userId: string]: UserDetails
    }
};

export const userDetailsConfig: SharedStateConfig<UserDetailsData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'licenseeId'],
    countLiveDocs: () => Object.keys(sharedState.userDetails.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        const licenseeId = sharedState.licenseeId.current;
        if (userId && licenseeId) {
            if (canView('crewParticulars')) {
                // Load all userDetails under licensee that are active
                // If has permission to archive, also load archived
                return onSnapshot(
                    query(
                        collection(firestore, 'userDetails'),
                        where('licenseeId', '==', licenseeId),
                        canArchive('crewParticulars') ? (
                            where('state', 'in', ['active', 'archived'])
                        ) : (
                            where('state', '==', 'active')
                        )
                    ),
                    (snap) => {
                        done();
                        const byId = {} as {
                            [userId: string]: UserDetails
                        };
                        snap.docs.forEach((doc) => {
                            const details = {
                                id: doc.id,
                                ...doc.data()
                            } as UserDetails;
                            let searchText = renderFullNameForUserId(details.id).toLowerCase();

                            if (details.email) {
                                searchText += details.email;
                            }
                            if (details.contactNumber) {
                                searchText += details.role;
                            }
                            if (details.address) {
                                searchText += details.email;
                            }
                            if (details.contactNumber) {
                                searchText += details.contactNumber;
                            }
                            byId[doc.id] = {
                                ...details,
                                searchText
                            }
                        });
                        set({
                            byId
                        });
                    }, (error) => {
                        done();
                        console.log(`Failed to access userDetails for licenseeId=${licenseeId}`, error);
                    }
                );
            } else {
                // Just load this user's userDetails
                return onSnapshot(
                    doc(firestore, 'userDetails', userId),
                    (doc) => {
                        done();
                        const byId = {} as {
                            [userId: string]: UserDetails
                        };
                        if (doc.exists()) {
                            const details = {
                                id: doc.id,
                                ...doc.data()
                            } as UserDetails;
                            byId[userId] = details;
                        }
                        set({
                            byId
                        });
                    }, (error) => {
                        done();
                        console.log(`Failed to access userDetails for userId=${userId}`, error);
                    }
                );
            }
        }
    }
};
