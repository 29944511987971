import { EditorConfig } from "lexical";
import { HeadingNode, HeadingTagType } from "@lexical/rich-text";


export class SeaSectionNode extends HeadingNode {
    static getType() {
        return "sea-section";
    }

    static clone(node: SeaSectionNode) {
        return new SeaSectionNode(node.__tag, node.__key);
    }

    createDOM(config: EditorConfig) {
        const dom = super.createDOM(config);
        //dom.style.backgroundColor = 'rgba(235,235,0,0.5)';
        dom.id = `${this.__tag}_${this.__key}`;

        // Show page breaker style if h1 & parent is root
        if (this.__tag === 'h1' && this.__parent === 'root' && this.__prev !== null) {
            dom.classList.add('lex-page-breaker');
        }
        // dom.onclick = () => {
        //     dom.style.color = 'red'; // DELETE ME. just testing
        // };
        //dom.style.color = 'green';
        return dom;
    }

    exportJSON() {
        return { ...super.exportJSON(),
            tag: this.getTag(), // 'h1',
            type: 'sea-section'
            //version: 1
        } as any;
    } // Mutation

}
