import React from 'react';
import SeaStatus, { SeaStatusOption } from '../SeaStatus/SeaStatus';
import { View, Text } from '@react-pdf/renderer';
import { pdfStyles } from '../../lib/pdf';
import './SeaStatusDueHours.css';

interface SeaStatusDueHoursProps {
    engineHoursLeft?: number,
    warnHours?: number, // For how many engine hours left should we then start warning?
    inline?: boolean,
    forPdf?: boolean
}

const SeaStatusDueHours: React.FC<SeaStatusDueHoursProps> = ({ engineHoursLeft = 0, warnHours = 50, inline, forPdf }) => {
    let status = 'ok';
    let text = '';

    if (engineHoursLeft === 0) {
        status = 'now';
        text = 'Due Now';
    } else if (engineHoursLeft < 0) {
        status = 'fail';
        text = `${-engineHoursLeft} hour${(engineHoursLeft < -1)?'s':''} OD`;
    } else if (engineHoursLeft < warnHours) {
        status = 'warn';
        text = `${engineHoursLeft} hour${(engineHoursLeft > 1)?'s':''}`;
    } else {
        status = 'ok';
        text = `${engineHoursLeft} hour${(engineHoursLeft > 1)?'s':''}`;
    }

    if (forPdf) {
        //return <Text style={{textAlign: 'center'}}>{text}</Text>;
        // default colours for good status
        let bgColor = '#2dd36f';
        let fontColor = '#ffffff';
        if (status === 'fail') {
            bgColor = '#eb445a';
        } else if (status === 'warn') {
            bgColor = '#f99e1b';
        } else if (status === 'now') {
            bgColor = '#dddddd';
            fontColor = '#0369d9';
        }
        return (
            <View style={{
                ...pdfStyles.status,
                backgroundColor: bgColor,
                color: fontColor
            }}>
                <Text>{text}</Text>
            </View>
        );
    }

    return (
        <SeaStatus status={status as SeaStatusOption} inline={inline}>
            {text}
        </SeaStatus>
    );
};

export default SeaStatusDueHours;
