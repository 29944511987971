import React, { ReactNode, useEffect } from 'react';
import { useLexDropDown } from '../LexDropDown/LexDropDown';
import LexButton from '../LexButton/LexButton';
import './LexDropDownOption.css';

interface LexDropDownOptionProps {
    children: ReactNode,
    title?: string,
    value?: any,
    disabled?: boolean,
    active?: boolean // Used for toggles
}

const LexDropDownOption: React.FC<LexDropDownOptionProps> = ({
    children,
    title,
    value,
    disabled,
    active
}) => {

    const { selectedValue, setSelectedValue, setSelectedContent, onChange } = useLexDropDown();

    useEffect(() => {
        if (value === selectedValue) {
            setSelectedContent(children);
        }
    }, [value, selectedValue]);

    return (
        <LexButton
            title={title}
            onClick={() => {
                onChange(value);
                if (setSelectedValue) {
                    setSelectedValue(value);
                }
                if (setSelectedContent) {
                    setSelectedContent(children);
                }
            }}
            disabled={disabled}
        >
            {children}
        </LexButton>
    );
};

export default LexDropDownOption;
