import React from 'react';
import {
  Svg,
  Path,
} from '@react-pdf/renderer';

const InfoCirclePDF = ({width = 18, height = 18, color = 'currentColor'}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 512 512">
    <Path fill={color} d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200s200-89.72 200-200S366.28 56 256 56Zm0 82a26 26 0 1 1-26 26a26 26 0 0 1 26-26Zm48 226h-88a16 16 0 0 1 0-32h28v-88h-16a16 16 0 0 1 0-32h32a16 16 0 0 1 16 16v104h28a16 16 0 0 1 0 32Z"/>
  </Svg>
);

export default InfoCirclePDF;