import React, { ReactNode, useMemo, useState } from 'react';
import './SvgHoverInfo.css';

export type HoverInfo = {
    text: string,
    x: number,
    y: number
}

interface BConstraints {
    x: number,
    y: number,
    width: number,
    height: number,
    offset: {
        x: number,
        y: number
    },
    nib: {
        x: number,
        y: number
    }

}

interface SvgHoverInfoProps {
    children: ReactNode,
    graphWidth: number,
    graphHeight: number,
    x: number,
    y: number,
    paddingX?: number,
    paddingY?: number
}

const SvgHoverInfo: React.FC<SvgHoverInfoProps> = ({
    children,
    graphWidth,
    graphHeight,
    x,
    y,
    paddingX = 12,
    paddingY = 10
}) => {

    const [contentSize, setContentSize] = useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });

    const box = useMemo(() => {
        const b = {
            x: contentSize.x - paddingX,
            y: contentSize.y - paddingY,
            width: contentSize.width + paddingX + paddingX,
            height: contentSize.height + paddingY + paddingY,
        } as BConstraints;

        const y2 = Math.max(y, b.height + 20); // Prevent going above top of view port

        b.offset = {
            x: x - b.x - (b.width / 2),
            y: y2 - b.y - b.height - 12
        };
        b.nib = {
            x: b.x + (b.width / 2),
            y: b.y + b.height + 6
        }
        const right = x + (b.width / 2) + paddingX;
        const left = x - (b.width / 2) - paddingX;
        if (right > graphWidth) {
            b.offset.x -= right - graphWidth;
            b.nib.x += right - graphWidth; // Offset nib so it stays in the same place
        } else if (left < 0) {
            b.offset.x -= left;
            b.nib.x += left; // Offset nib so it stays in the same place
        }
        return b;
    }, [contentSize, x, y, paddingX, paddingY, graphWidth]);

    return (
        <g
            className="hover-info"
            style={{
                transform: `translate(${box.offset.x}px, ${box.offset.y}px)`,
                pointerEvents: 'none'
            }}
        >
            <rect
                x={box.x}
                y={box.y}
                width={box.width}
                height={box.height}
                //fill="#F5F5F5"
                //fill="#373946"
                fill="#545666"
                //fill="#f4f5f7"
                rx={5}
                ry={5}
                className="box animate"
            />
            <path
                d={`M ${box.nib.x} ${box.nib.y} l -6 -8 l 12 0 Z`}
                //fill="#F5F5F5"
                //fill="#373946"
                fill="#545666"
                //fill="#f4f5f7"
                strokeWidth={5}
                strokeOpacity={1}
                className="animate"
            />
            <g
                className="animate"
                ref={((element) => {
                    if (element) {
                        if (element.getBBox !== undefined) {
                            const bbox = element.getBBox();
                            setContentSize((current) => {
                                if (
                                    current.width !== bbox.width ||
                                    current.height !== bbox.height
                                ) {
                                    return {
                                        x: bbox.x,
                                        y: bbox.y,
                                        width: bbox.width,
                                        height: bbox.height
                                    };
                                }
                                return current;
                            });
                        }
                    }
                })}
            >
                {children}
            </g>
        </g>
    );
};

export default SvgHoverInfo;
