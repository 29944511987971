import * as React from "react";

function SvgSafetyFacility(props) {
  return (
    <svg
      width={27}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.563 2.223a2.776 2.776 0 00-4.809 0L.53 19.931a2.776 2.776 0 002.404 4.164h20.448a2.776 2.776 0 002.404-4.164L15.562 2.223zm8.722 19.617a1.023 1.023 0 01-.903.522H2.934a1.042 1.042 0 01-.902-1.564L12.256 3.09a1.042 1.042 0 011.805 0l10.224 17.708a1.023 1.023 0 010 1.043z"
        fill="currentColor"
      />
      <path
        d="M13.158 16.478a.866.866 0 00.867-.867V7.965a.867.867 0 00-1.734 0v7.646a.867.867 0 00.867.867v0z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.3}
      />
      <path
        d="M13.158 18.22a.918.918 0 100 1.836.918.918 0 000-1.835v0z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default SvgSafetyFacility;
