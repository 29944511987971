import React, { useCallback } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../lib/pdf';
import { formatDate, formatValue, warnDays, formatInterval } from '../../lib/util';
import { renderCategoryName } from '../../lib/categories';
import { renderFullName } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { SafetyCheckItem } from '../../shared-state/VesselSafety/safetyCheckItems';
import { getImgSrcFromString } from '../../lib/files';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../components/SeaStatusDueDate/SeaStatusDueDate';
import CriticalFlagPDF from '../../assets/svg/CriticalFlagPDF';

interface SafetyEquipmentChecksPdfProps {
    generatingPdf: boolean,
    setGeneratingPdf: (generatingPdf: boolean) => void, 
    listType: string;
    safetyChecks: {
        [key: string]: SafetyCheckItem[];
    } | SafetyCheckItem[];
}

const SafetyEquipmentChecksPdf: React.FC<SafetyEquipmentChecksPdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    listType,
    safetyChecks
}) => {
    const vessel = sharedState.vessel.use(generatingPdf);
    const vesselLocations = sharedState.vesselLocations.use(generatingPdf);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(generatingPdf);
    const vesselSafetyCheckCategories = sharedState.safetyCheckCategories.use(generatingPdf);
    const title = `Safety Checks - ${vessel?.name}`;

    const isCritical = useCallback((id: string) => {
        if (vesselSafetyItems?.byId[id]?.isCritical) {
            return true;
        }
        return false;
    }, [vesselSafetyItems]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={(listType !== 'all') ? `Overdue & Due Within ${listType} Days` : undefined}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(1.6)}>
                            <Text>Safety Item</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Location</Text>
                        </View>
                        <View style={pdfStyles.intervalColumn}>
                            <Text>Interval</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Next Check</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                        <View style={pdfStyles.criticalColumn}>
                            <Text>Critical</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {listType === 'all' ? vesselSafetyCheckCategories?.ids?.map((categoryId : string, index: number) => {
                        const vesselSafetyChecks = safetyChecks as { [categoryId: string]: SafetyCheckItem[] }
                         if (vesselSafetyChecks[categoryId] === undefined ) {
                            return null;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                {/* Render System Text */}
                                <View style={pdfStyles.labelHeading}>
                                    <Text style={pdfStyles.itemsCategory}>
                                        {renderCategoryName(
                                                categoryId,
                                                vesselSafetyCheckCategories
                                            )}
                                    </Text>
                                </View>
                                {vesselSafetyChecks[categoryId].map((item : SafetyCheckItem, index: number) => {   
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    const isLast = index === vesselSafetyChecks[categoryId].length - 1;
                                    return (
                                        <React.Fragment key={item.id}>
                                            {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            }
                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                <View style={pdfStyles.thumbColumn}>
                                                    {imgSrc && 
                                                        <Image
                                                            src={imgSrc}
                                                            style={pdfStyles.thumbImg}
                                                        />
                                                    }
                                                </View>
                                                <View style={styleItemColumn(1.6)}>
                                                    <Text>{renderCategoryName(item.itemId, vesselSafetyItems)}</Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>{formatValue(renderCategoryName(item.locationId, vesselLocations))}</Text>
                                                </View>
                                                <View style={pdfStyles.intervalColumn}>
                                                    <Text>{formatInterval(item.interval)}</Text>
                                                </View>
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>{formatDate(item.whenDue)}</Text>
                                                </View>
                                                <View style={pdfStyles.statusColumn}>
                                                    <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault} forPdf={true}/>
                                                </View>
                                                <View style={pdfStyles.criticalColumn}>
                                                    {isCritical(item.itemId) && (
                                                        <CriticalFlagPDF />
                                                    )}
                                                </View>
                                            </View>
                                            {isLast && (
                                                <View style={pdfStyles.itemsCategorySpacer} />
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                        );
                    }) : (safetyChecks as SafetyCheckItem[]) ? (safetyChecks as SafetyCheckItem[]).map((item, index) => {
                        const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                        return (
                            <React.Fragment key={item.id}>
                                {index > 0 &&
                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                }
                                <View wrap={false} style={pdfStyles.itemsRow}>
                                    <View style={pdfStyles.thumbColumn}>
                                        {imgSrc && 
                                            <Image
                                                src={imgSrc}
                                                style={pdfStyles.thumbImg}
                                            />
                                        }
                                    </View>
                                    <View style={styleItemColumn(1.6)}>
                                        <Text>{renderCategoryName(item.itemId, vesselSafetyItems)}</Text>
                                    </View>
                                    <View style={styleItemColumn(1)}>
                                        <Text>{formatValue(renderCategoryName(item.locationId, vesselLocations))}</Text>
                                    </View>
                                    <View style={pdfStyles.intervalColumn}>
                                        <Text>{formatInterval(item.interval)}</Text>
                                    </View>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>{formatDate(item.whenDue)}</Text>
                                    </View>
                                    <View style={pdfStyles.statusColumn}>
                                        <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentChecks[0]} hasFault={item.hasFault} forPdf={true}/>
                                    </View>
                                    <View style={pdfStyles.criticalColumn}>
                                        {isCritical(item.itemId) && (
                                            <CriticalFlagPDF />
                                        )}
                                    </View>
                                </View>
                            </React.Fragment>
                        );
                    }) : <></>}
                </Page>
            </Document>
        );
    }, [title, listType, vesselSafetyCheckCategories, safetyChecks, vesselSafetyItems, vesselLocations, isCritical]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="SafetyEquipmentChecks"
        />
    );
};

export default SafetyEquipmentChecksPdf;
