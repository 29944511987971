import { DocumentData, QueryDocumentSnapshot, collection } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { SafetyEquipmentItem } from '../../VesselSafety/safetyEquipmentItems';

export type ReportingSafetyItemById = {
    [id: string]: {
        name: string
        isCritical?: boolean
    }
};

export const useReportingSafetyItemsByIds = (
    isActive: boolean,
    selectedVesselIds: string[]
) => {
    const [reportingSafetyItemById, setReportingSafetyItemById] = useState<ReportingSafetyItemById>();

    useEffect(() => {
        setReportingSafetyItemById(undefined);
        if (
            isActive && // (required for any of the graphs)
            selectedVesselIds?.length
        ) {
            return setupArrayQueryListener(
                'vesselSafetyItems', // what
                collection(firestore, 'vesselSafetyItems'),
                [], // baseConstraints
                'vesselId',
                'in',
                selectedVesselIds,
                [], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const byId = {} as {
                        [id: string]: {
                            name: string
                            isCritical?: boolean
                        }
                    };
                    docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data()
                        } as SafetyEquipmentItem;
                        byId[item.id] = {
                            name: item.name
                        };
                        if (item.isCritical) {
                            byId[doc.id].isCritical = true;
                        }
                    })
                    setReportingSafetyItemById(byId);
                }
            );
        }
    }, [isActive, selectedVesselIds]);

    return reportingSafetyItemById;

}
