import React, { ReactNode, useEffect, useCallback, useRef } from 'react';
import { IonModal, createAnimation, IonSpinner, isPlatform } from '@ionic/react';
import { createGesture } from '@ionic/core';
import { confirmAction } from '../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { sharedState } from '../../shared-state/shared-state';
import { onModalOpened, onUserAction } from '../../shared-state/General/appActivity';
import SeaScrollableArea, { SeaScrollable } from '../SeaScrollableArea/SeaScrollableArea';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaButton from '../SeaButton/SeaButton';
import './SeaModal.css';
import { jsxToText } from '../../lib/util';

interface SeaModalProps {
    children?: ReactNode,
    alternativeContent?: ReactNode,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    isDirty?: () => boolean, // function that returns if dirty. Returning true it means we should ask before dismissing modal
    confirmDismissDirty?: { title: string, yes: string, no: string },
    size?: 'thin' | 'semi-thin' | 'standard' | 'semi-wide' | 'wide' | 'extra-wide' | 'edit-rich-text' | 'rich-text',
    title?: string | JSX.Element,
    disabled?: boolean, // reduces opacity and makes unclickable
    onOpened?: () => void,
    onFullyOpened?: () => void,
    onClosed?: () => void,
    level?: number,
    renderWhenHidden?: boolean,
    tabsPanel?: ReactNode,
    actionPanel?: ReactNode,
    noContentPadding?: boolean,
    onPdf?: () => void, // function to run if PDF button is pressed
    noTitleOverflow?: boolean,
    scrollEvents?: boolean, // Must be set to true to enable onScroll
    onScroll?: (event: Event) => void,
    setModalContentRef?: (ref: React.RefObject<SeaScrollable>) => void
}

const SeaModal: React.FC<SeaModalProps> = ({
    children,
    alternativeContent,
    showModal,
    setShowModal,
    isDirty,
    confirmDismissDirty = {
        title: 'Are you sure you want to leave this form?',
        yes: 'Yes, leave form',
        no: 'Cancel'
    },
    size = 'standard',
    title,
    disabled,
    onOpened,
    onFullyOpened,
    onClosed,
    level = 1,
    renderWhenHidden,
    tabsPanel,
    actionPanel,
    noContentPadding,
    onPdf,
    noTitleOverflow,
    scrollEvents = false,
    onScroll,
    setModalContentRef
}) => {
    const gestureRef = useRef<any>();
    const modalRef = useRef<HTMLIonModalElement>(null);
    const isDirtyRef = useRef<() => boolean>();
    const modalContentRef = useRef<SeaScrollable>(null);

    useEffect(() => {
        if (setModalContentRef) {
            setModalContentRef(modalContentRef);
        }
    }, [setModalContentRef]);

    useEffect(() => {
        isDirtyRef.current = isDirty;
    }, [isDirty]);

    useEffect(() => {
        if (showModal) {
            // Modal opened, therefore make sure form is reset
            if (title) {
                onModalOpened(jsxToText(title, 'Unknown'));
            }
            if (onOpened) {
                onOpened();
            }
        } else {
            if (onClosed) {
                onClosed();
            }
        }
    }, [showModal]); // Unfortuantely this cause a lint warning... but if onOpened is added, it will infinitely rerender

    const onDidPresent = useCallback(() => {
        // Create bg div that we can tap and be in control of for conditional dismissal
        try {
            const bg = document.createElement('div');
            //bg.style.backgroundColor = 'rgba(0,250,0,0.75)';
            bg.style.position = 'fixed';
            bg.style.width = '100%';
            bg.style.height = '100%';
            bg.style.zIndex = ''+(level * 10 - 1);

            bg.onclick = attemptDismiss;
            if (modalRef.current) {
                modalRef.current.appendChild(bg);
            }
        } catch (e) {}

        gestureRef.current = createGesture({
            gestureName: 'Swipe Right',
            el: modalRef.current as any,
            direction: 'x',
            gesturePriority: 50, // we awnt to be higher than back and foward swiping with priority=40
            onEnd: (detail) => {
                if (
                    detail.deltaX > 100 // must have swiped at least this amount to the right
                    && (detail.currentTime - detail.startTime) < 500 // must be fast enought
                    && !isPlatform('desktop') // must not be desktop i.e. no mouse, just touch
                    && sharedState.isSwipeEnabled.current
                ) {
                    // SWIPE RIGHT!
                    attemptDismiss();
                }
            }
        });
        gestureRef.current.enable(true);

        if (onFullyOpened) {
            onFullyOpened();
        }
    }, [level]);

    const attemptDismiss = useCallback(() => {
        if (isDirtyRef.current && isDirtyRef.current()) {
            confirmAction(
                confirmDismissDirty.title,
                confirmDismissDirty.yes,
                confirmDismissDirty.no,
                undefined,
                'Leaving this form will mean losing any information you have entered so far.'
            ).then(() => {
                onUserAction(`Closed Modal (Confirmed) ${jsxToText(title, 'Unknown')}`);
                setShowModal(false);
            }).catch(() => {});
        } else {
            onUserAction(`Closed Modal ${jsxToText(title, 'Unknown')}`);
            setShowModal(false);
        }
    }, []);

    const enterAnimation = useCallback((baseEl: any) => {
        const backdropAnimation = createAnimation()
            .addElement(baseEl.querySelector('ion-backdrop')!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(baseEl.querySelector('.modal-wrapper')!)
            .keyframes([
                { offset: 0, opacity: '0', transform: 'scale(1)', right: '-200px' },
                { offset: 1, opacity: '0.99', transform: 'scale(1)', right: '0px' }
            ]);
    
        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(150)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    }, []);
    const leaveAnimation = useCallback((baseEl: any) => {
        return enterAnimation(baseEl).direction('reverse');
    }, []);

    const onDidDismiss = (e: any) => {
        setShowModal(false);
        if (gestureRef.current) {
            gestureRef.current.destroy();
        }
    }

    return (
        <IonModal
            ref={modalRef}
            isOpen={showModal}
            mode="ios"
            showBackdrop={true}
            backdropDismiss={false}
            cssClass={`sea-modal ${size} level-${level}`}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            onDidDismiss={onDidDismiss}
            onDidPresent={onDidPresent}
        >
            <div className={`disable-sea-modal ${disabled ? 'active' : ''}`}> {/* Should only show this if desktop */}
                <IonSpinner name="crescent" className="sea-spinner"/>
            </div>
            <div className="columns">
                <div className={`title${onPdf ? ' has-pdf-button' : ''}${noTitleOverflow ? ' no-title-overflow' : ''}`}>
                    <h2>{title}</h2>
                </div>
                <div className="right">
                    {onPdf &&
                        <div className="sea-modal-pdf">
                            <SeaButton zone="white" shape="circle" onClick={(e) => onPdf()}>
                                <SeaIcon slot="icon-only" icon="pdf" />
                            </SeaButton>
                        </div>
                    }
                    <div className="sea-modal-x" onClick={(e) => attemptDismiss()}>
                        <SeaIcon icon="close"/>
                    </div>
                </div>
            </div>
            {tabsPanel && 
                <div className="sea-modal-tabs-panel">
                    {tabsPanel}
                </div>
            }
            {children &&
                <SeaScrollableArea
                    scrollEvents={scrollEvents}
                    onScroll={onScroll}
                    ref={modalContentRef}
                >
                    <div className={`sea-modal-content ${noContentPadding ? 'no-padding' : ''}`}>
                        {(showModal || renderWhenHidden) && children}
                    </div>
                </SeaScrollableArea>
            }
            {alternativeContent}
            {actionPanel &&
                <div className="sea-modal-action-panel">
                    <div className="top-line"></div>
                    <div className="actions">
                        {actionPanel}
                    </div>
                </div>
            }
        </IonModal>
    );
};

export default SeaModal;
