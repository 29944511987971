import React, { useCallback, useMemo } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption, isPlatform } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc } from "firebase/firestore";
import { haveValuesChanged, maxSessionOptions } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import Yup from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';

interface EditLicenseeSettingsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditLicenseeSettingsModal: React.FC<EditLicenseeSettingsModalProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);

    const initialValues = useMemo(() => {
        if (licenseeSettings) {
            return {
                maxSessionSeconds: licenseeSettings.maxSessionSeconds ? (''+licenseeSettings.maxSessionSeconds) : '0'
            };
        } else {
            return {
                maxSessionSeconds: '0'
            };
        };
    }, [licenseeSettings]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            maxSessionSeconds: Yup.string().required().max(12),
        }), onSubmit: (data) => {
            if (!licenseeId) {
                throw new Error('No licenseeId');
            }
            const action = traceAction('licenseeSettings', 'update') as Action;
            action.docId = licenseeId;
            action.data = {data, licenseeSettings};
            const batch = splittableBatch(firestore, 20 - 0);

            batch.set(
                doc(firestore, 'licenseeSettings', licenseeId),
                {
                    maxSessionSeconds: parseInt(data.maxSessionSeconds),
                    whenUpdated: action.whenAction,
                    updatedBy: userId
                },
                { merge: true }
            );

            action.save(`Update licensee settings (maxSessionSeconds)`, batch);
            batch.commit().then(() => {
                action.reportSuccess();
            }).catch((error) => {
                action.reportError(error.message, error);
            });
            setShowModal(false);
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [values, initialValues]);

    return (
        <SeaModal
            title="Edit Licensee Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={2}
            size="thin"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaSelect
                                label="Maximum Session Time"
                                help={{text: 'This is the amount of time a user is allowed to remain logged in for before being required to login again.'}}
                                name="maxSessionSeconds"
                                zone="white"
                                value={values.maxSessionSeconds}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.maxSessionSeconds ? errors.maxSessionSeconds : ''}
                            >
                                {maxSessionOptions.map((option) => {
                                    return (
                                        <IonSelectOption
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.description}
                                        </IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <div className="view-modal-buttons">
                    <SeaButton
                        zone="white"
                        type="submit"
                        size="wide"
                        disabled={isSubmitting}
                    >
                        Save Licensee Settings
                    </SeaButton>
                    <div style={{ height: '60px' }}></div>
                    <p>
                        Note: Submitting this form may cause {isPlatform('hybrid') ? 'your app to restart' : 'this page to refresh'} to apply the new settings.
                    </p>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditLicenseeSettingsModal;
