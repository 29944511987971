import React, { useMemo } from 'react';
import { renderCategoryName } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import './BrowseCustomForms.css'

interface BrowseCustomFormsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    filter?: any,
    onCustomFormSelected?: (customForm: CustomForm) => void
}

const BrowseCustomForms: React.FC<BrowseCustomFormsProps> = ({
    showModal,
    setShowModal,
    level,
    filter,
    onCustomFormSelected
}) => {
    const customForms = sharedState.customForms.use(showModal);
    const customFormCategories = sharedState.customFormCategories.use(showModal);

    const filteredCustomForms = useMemo(() => {
        if (customForms?.all && filter) {
            const byCategoryId = {} as {
                [categoryId: string]: CustomForm[]
            };
            for (let i = 0; i < customForms.all.length; i++) {
                const form = customForms.all[i];
                if (filter?.forCrew && !form.forCrew) {
                    continue;
                }
                if (filter?.forVessels && form.forVesselIds[0] === 'none') {
                    continue;
                }
                if (filter?.forVesselId && !form.forVesselIds.includes(filter.forVesselId)) {
                    continue;
                }
                if (byCategoryId[form.categoryId] === undefined) {
                    byCategoryId[form.categoryId] = [];
                }
                byCategoryId[form.categoryId].push(form);
                //all.push(form);
            }
            const categoryIds = [] as string[];
            customFormCategories?.ids?.forEach((categoryId: string) => {
                if (byCategoryId[categoryId]) {
                    categoryIds.push(categoryId);
                }
            });

            return {
                byCategoryId,
                categoryIds
            };
        }
        return undefined;
    }, [customForms, filter, customFormCategories]);

    return (
        <SeaModal
            title="Select Form/Checklist"
            showModal={showModal}
            setShowModal={setShowModal}
            level={level}
            size="standard"
        >
            <SeaNoData
                dataName="form/checklist"
                isHistory={true}
                isLoading={!filteredCustomForms}
                hasNoData={filteredCustomForms?.categoryIds && filteredCustomForms.categoryIds.length === 0}
                //isUsingFilter={true}
            />

            <div className={`custom-form-templates ${(filteredCustomForms?.categoryIds && filteredCustomForms.categoryIds.length > 0) ? 'reveal' : 'conceal'}`}>
                {filteredCustomForms?.categoryIds?.map((categoryId: string) => {
                    return (<React.Fragment key={categoryId}>
                        <div className="category-heading">
                            <div>{renderCategoryName(categoryId, customFormCategories)}</div>
                        </div>
                        {filteredCustomForms.byCategoryId[categoryId]?.map((customForm) => {
                            return (
                                <div
                                    key={customForm.id}
                                    className={`sea-card sea-row-history clickable`}
                                    onClick={(e) => {
                                        if (onCustomFormSelected) {
                                            onCustomFormSelected(customForm);
                                        }
                                    }}
                                >
                                    <div>{customForm.title}</div>
                                </div>
                            );
                        })}
                    </React.Fragment>);
                })}
            </div>
        </SeaModal>
    );
};

export default BrowseCustomForms;
