import { makeSeaFiles, makeSignature } from './files';
import { formatSeaDate, formatSeaDatetime } from './util';
import { CustomFormVersion } from '../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import { CustomFormCompleted } from '../shared-state/CompanyDocuments/CustomForms/customFormsCompleted';
import { CustomForm } from '../shared-state/CompanyDocuments/CustomForms/customForms';
import { CustomFormElementType } from '../components/CustomFormElement/CustomFormElement';

export const formElementsToArray = (elements: CustomForm): CustomFormElementType[] => {
    const array = [];
    for (let key in elements) {
        if (elements.hasOwnProperty(key)) {
            array.push(elements[key]);
        }
    }
    array.sort((a, b) => {
        return a.o - b.o;
    });
    return array;
};

export const formArrayToElements = (array: CustomFormElementType[]): {
    [key: string]: CustomFormElementType;
} => {
    // Set o values to establish order
    const form = {} as {
        [key: string]: CustomFormElementType
    }
    array.forEach((element: CustomFormElementType, index: number) => {
        element.o = index;
        form[`e${element.n}`] = element;
    });
    return form;
};

export const makeFormElements = (
    customFormVersion?: CustomFormVersion,
    customFormCompleted?: CustomFormCompleted | CustomForm
) => {
    if (!customFormVersion) { 
        console.log('customFormVersion is undefined');
        return [];
    }
    const formElements = [] as CustomFormElementType[];
    const byN = {} as {
        [n: number]: CustomFormElementType
    };
    const formArray = formElementsToArray(customFormVersion.form);
    formArray.forEach((_element: CustomFormElementType) => {
        const element = {..._element};
        switch (element.id) {
            case 'checkbox':
                element.initialValue = element.value = false; break;
            case 'date':
                element.initialValue = element.value = formatSeaDate(); break;
            case 'datetime':
                element.initialValue = element.value = formatSeaDatetime(); break;
            case 'files':
                element.initialValue = element.value = []; break;
            case 'checks':
                element.initialValue = element.value = ['']; break;
            default:
                element.initialValue = element.value = '';
        }
        if (element.n) {
            byN[element.n] = element;
        }
        formElements.push(element);
    });
    if (customFormCompleted?.data) {
        const fields = Object.getOwnPropertyNames(customFormCompleted.data);
        fields.forEach((field) => {
            const n = parseInt(field.substring(1));
            if (byN[n] !== undefined) {
                switch (byN[n].id) {
                    case 'date':
                        byN[n].value = formatSeaDate(customFormCompleted.data[field]);
                        break;
                    case 'datetime':
                        byN[n].value = formatSeaDatetime(customFormCompleted.data[field]);
                        break;
                    case 'files':
                        byN[n].value = makeSeaFiles(customFormCompleted.data[field]);
                        break;
                    case 'signature':
                        byN[n].value = makeSignature(customFormCompleted.data[field]);
                        break;
                    default:
                        byN[n].value = customFormCompleted.data[field];
                }
            }
            byN[n].initialValue = byN[n].value;
        });
    }
    return formElements;
};
