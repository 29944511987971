import React, { useState, useMemo, useRef, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { canEdit } from '../../../lib/permissions'
import { firestore, deleteValue, splittableBatch } from '../../../lib/firebase';
import { collection, doc, arrayUnion, increment, serverTimestamp } from "firebase/firestore";
import { regions, haveValuesChanged, formatSeaDate, toMillis, preventMultiTap } from '../../../lib/util';
import { logAction } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Action, reportError, traceAction } from '../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../lib/files';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup'
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaDate from '../../../components/SeaDate/SeaDate';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../components/SeaInputList/SeaInputList';
import SeaFileUpload from '../../../components/SeaFileUpload/SeaFileUpload';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';

const defaultMaintenanceTags = [
    'Routine Maintenance',
    'Annual Survey',
    'Intermediate Survey',
    'Anniversary Survey',
];

interface EditVesselModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    licenseeUserId?: string | undefined,
    fromAddShoreFacility?: boolean | undefined,
}

const EditVesselModal: React.FC<EditVesselModalProps> = ({showModal, setShowModal, licenseeUserId, fromAddShoreFacility}) => {
    const userId = sharedState.userId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const formRef = useRef<HTMLFormElement>(null);

    const [files, setFiles] = useState<SeaFile[]>([]);

    const [tab, setTab] = useState("vesselParticulars");
    const [engineIds, setEngineIds] = useState<string[]>(['']);
    const [engineNames, setEngineNames] = useState<string[]>(['']);

    const isShoreFacilityCheck = fromAddShoreFacility ? true : vessel?.isShoreFacility ? true : false;

    const initialValues = useMemo(() => {
        return {
            name: vessel?.name ? ''+vessel.name : '',
            callsign: vessel?.callsign ? ''+vessel.callsign : '',
            mnz: vessel?.mnz ? ''+vessel.mnz : '',
            mmsi: vessel?.mmsi ? ''+vessel.mmsi : '',
            portOfRegistry: vessel?.portOfRegistry ? ''+vessel.portOfRegistry : '',
            length: vessel?.length ? ''+vessel.length : '',
            breadth: vessel?.breadth ? ''+vessel.breadth : '',
            grossTonnage: vessel?.grossTonnage ? ''+vessel.grossTonnage : '',
            driveType: vessel?.driveType ? ''+vessel.driveType : '',
            whenLaunched: vessel?.whenLaunched ? formatSeaDate(vessel.whenLaunched) : '',
            enginesDescription: vessel?.enginesDescription ? ''+vessel.enginesDescription : '',
            construction: vessel?.construction ? ''+vessel.construction : '',
            areaOfOperation: vessel?.areaOfOperation ? ''+vessel.areaOfOperation : '',
            fuelRange: vessel?.fuelRange ? ''+vessel.fuelRange : '',
            marineTrafficLink: vessel?.marineTrafficLink ? ''+vessel.marineTrafficLink : '',
            master: vessel?.master ? ''+vessel.master : '',
            //engineNames: engineNames,
        };
    }, [vessel]);

    const initEngines = () => {
        const _engineIds = [];
        const _engineNames = [];
        engines?.all?.forEach((engine) => {
            _engineIds.push(engine.id);
            _engineNames.push(engine.name);
        });
        if (_engineIds.length === 0) {
            _engineIds.push('');
            _engineNames.push('');
        }
        setEngineIds(_engineIds);
        setEngineNames(_engineNames);
    };

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
        initEngines();
        setFiles(makeSeaFiles(vessel?.images || []));;
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            callsign: Yup.string().max(500),
            mnz: Yup.string().max(500),
            mmsi: Yup.string().max(500),
            portOfRegistry: Yup.string().max(500),
            length: Yup.string().max(500),
            breadth: Yup.string().max(500),
            grossTonnage: Yup.string().max(500),
            driveType: Yup.string().max(500),
            whenLaunched: Yup.date(),
            enginesDescription: Yup.string().max(500),
            construction: Yup.string().max(500),
            areaOfOperation: Yup.string().max(500),
            fuelRange: Yup.string().max(500),
            marineTrafficLink: Yup.string().min(3).max(500).url(),
            master: Yup.string().max(500)
        }), onSubmit: (data) => { /* Process form */
            if (preventMultiTap('vessel')) { return; }
            // Currently, the engine names are not checked
            // If they were to be checked, it would be here
            uploadFiles(files, licenseeUserId).then(() => {
                // Confirm any engine/category archives/deletions
                let enginesArchived = 0;
                engines?.all?.forEach((engine) => {
                    if (!engineIds.includes(engine.id)) {
                        enginesArchived++;
                    }
                });
                if (enginesArchived) {
                    const message = `You are about to remove ${enginesArchived > 1 ? 'engines / generators' : 'an engine / generator'}. Note: Any tasks associated with them will be lost! Are you sure you want to continue?`;
                    return confirmAction(message, `Yes, continue`).catch(() => {
                        return Promise.reject({ code: 'stoppedDeletion' });
                    });
                }
                return Promise.resolve();
            }).then(() => {
                const action = traceAction('vessels') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                const vesselRef = vessel ?
                    doc(firestore, 'vessels', vesselId as string)
                    : doc(collection(firestore, 'vessels'));
                action.docId = vesselRef.id;

                if (canEdit('engineHours')) {
                    engineIds?.forEach((engineId, index) => {
                        if (engineNames[index].trim().length > 0) {
                            const engineName = engineNames[index].trim();
                            if (engineId === '') {
                                // New engine added
                                const engineRef = doc(collection(firestore, 'engines'));
                                batch.set(engineRef, {
                                    name: engineName,
                                    hours: 0,
                                    state: 'active',
                                    vesselId: vesselRef.id,
                                    addedBy: userId ?? superAdmin?.id,
                                    whenAdded: action.whenAction,
                                    touched: serverTimestamp(),
                                });
                                logAction(
                                    batch,
                                    'Add',
                                    'engines',
                                    engineRef.id,
                                    engineName,
                                    [vesselRef.id]
                                );
                            } else if (engines?.byId[engineId]?.name !== engineName) {
                                // Update existing engine
                                batch.set(
                                    doc(firestore, 'engines', engineId),
                                    {
                                        name: engineName,
                                        state: 'active',
                                        updatedBy: userId ?? superAdmin?.id,
                                        updatedVia: 'editVessel',
                                        whenUpdated: action.whenAction,
                                        touched: serverTimestamp(),
                                    },
                                    { merge: true }
                                );
                                logAction(
                                    batch,
                                    'Update',
                                    'engines',
                                    engineId,
                                    engineName,
                                    [vesselRef.id]
                                );
                            }
                        }
                    });
                    // Find existing engines that missing in the new list and so should therefore be archived
                    engines?.all?.forEach((engine) => {
                        if (!engineIds.includes(engine.id)) {
                            // Archive engine
                            batch.set(
                                doc(firestore, 'engines', engine.id),
                                {
                                    state: 'archived',
                                    archivedBy: userId ?? superAdmin?.id,
                                    whenArchived: action.whenAction,
                                    touched: serverTimestamp(),
                                },
                                { merge: true }
                            );
                            logAction(
                                batch,
                                'Archive',
                                'engines',
                                engine.id,
                                engine.name,
                                [vesselRef.id]
                            );
                        }
                    });

                    onCollectionUpdated(batch, 'engines');
                }

                if (vessel) {
                    // Update existing vessel
                    action.type = 'update';
                    batch.set(vesselRef, {
                        updatedBy: userId ?? superAdmin?.id,
                        whenUpdated: action.whenAction,
                        name: data.name,
                        callsign: data.callsign ? data.callsign : deleteValue,
                        mnz: data.mnz ? data.mnz : deleteValue,
                        mmsi: data.mmsi ? data.mmsi : deleteValue,
                        portOfRegistry: data.portOfRegistry ? data.portOfRegistry : deleteValue,
                        length: data.length ? data.length : deleteValue,
                        breadth: data.breadth ? data.breadth : deleteValue,
                        grossTonnage: data.grossTonnage ? data.grossTonnage : deleteValue,
                        driveType: data.driveType ? data.driveType : deleteValue,
                        whenLaunched: data.whenLaunched ? toMillis(data.whenLaunched) : deleteValue,
                        enginesDescription: data.enginesDescription ? data.enginesDescription : deleteValue,
                        construction: data.construction ? data.construction : deleteValue,
                        areaOfOperation: data.areaOfOperation ? data.areaOfOperation : deleteValue,
                        fuelRange: data.fuelRange ? data.fuelRange : deleteValue,
                        marineTrafficLink: data.marineTrafficLink ? data.marineTrafficLink : deleteValue,
                        master: data.master ? data.master : deleteValue,
                        images: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    }, { merge: true });

                    onCollectionUpdated(batch, 'vessels');
                    saveFileRefs(batch, files, 'vessels', vesselRef.id);
                    logAction(
                        batch,
                        'Update',
                        'vessels',
                        vesselRef.id,
                        data.name,
                        [vesselRef.id]
                    );

                } else {
                    // Create new vessel
                    action.type = 'create';
                    const licenseeUserIdRef = doc(firestore, 'users', licenseeUserId as string);
                    batch.set(vesselRef, {
                        isShoreFacility: isShoreFacilityCheck ? true : undefined,
                        name: data.name,
                        licenseeId: licenseeUserId, 
                        callsign: data.callsign ? data.callsign : undefined,
                        mnz: data.mnz ? data.mnz : undefined,
                        mmsi: data.mmsi ? data.mmsi : undefined,
                        portOfRegistry: data.portOfRegistry ? data.portOfRegistry : undefined,
                        length: data.length ? data.length : undefined,
                        breadth: data.breadth ? data.breadth : undefined,
                        grossTonnage: data.grossTonnage ? data.grossTonnage : undefined,
                        driveType: data.driveType ? data.driveType : undefined,
                        whenLaunched: data.whenLaunched ? toMillis(data.whenLaunched) : undefined,
                        enginesDescription: data.enginesDescription ? data.enginesDescription : undefined,
                        construction: data.construction ? data.construction : undefined,
                        areaOfOperation: data.areaOfOperation ? data.areaOfOperation : undefined,
                        fuelRange: data.fuelRange ? data.fuelRange : undefined,
                        marineTrafficLink: data.marineTrafficLink ? data.marineTrafficLink : undefined,
                        master: data.master ? data.master : undefined,
                        whenAdded: action.whenAction,
                        possibleMaintenanceTags: defaultMaintenanceTags,
                        images: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp(),
                    });
                    batch.set(licenseeUserIdRef, {
                        vesselIds: arrayUnion(vesselRef.id)
                    }, { merge: true });
                    batch.set(
                        doc(firestore, 'userDetails', licenseeUserId as string),
                        {
                            numVessels: increment(1),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    onCollectionUpdated(batch, 'userDetails');
                    onCollectionUpdated(batch, 'users');
                    onCollectionUpdated(batch, 'vessels');

                    // Create default SOP categories
                    [
                        'Operational Procedures',
                        'Emergency',
                        'Company Policy'
                    ].forEach((categoryName) => {
                        batch.set(
                            doc(collection(firestore, 'vesselSopCategories')),
                            {
                                vesselId: vesselRef.id,
                                name: categoryName,
                                state: 'active',
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );
                    });

                    saveFileRefs(batch, files, 'vessels', vesselRef.id);
                }
                action.data = {
                    data,
                    images: seaFilesToValue(files),
                    engineIds,
                    engineNames
                };
                action.save(`${vessel  ? 'Update' : 'Add'} vessel ${data.name}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    if (error?.code === 'stoppedDeletion') {
                        // ignore
                    } else {
                        reportError(`Failed to upload Vessel images`, error.message, error, {
                            data,
                            files: seaFilesToValue(files)
                        });
                    }
                }
            });
        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, (vessel?.images || []))
        );
    }, [initialValues, values, files, vessel?.images]);

    const particularsTabHasErrors = () => {
        if (
            (touched.name && errors.name) 
            || (touched.callsign && errors.callsign) 
            || (touched.mnz && errors.mnz) 
            || (touched.mmsi && errors.mmsi)
            || (touched.portOfRegistry && errors.portOfRegistry)
            || (touched.length && errors.length)
            || (touched.breadth && errors.breadth)
            || (touched.grossTonnage && errors.grossTonnage)
            || (touched.driveType && errors.driveType)
            || (touched.whenLaunched && errors.whenLaunched)
            || (touched.enginesDescription && errors.enginesDescription)
            || (touched.construction && errors.construction)
            || (touched.areaOfOperation && errors.areaOfOperation)
            || (touched.fuelRange && errors.fuelRange)
            || (touched.marineTrafficLink && errors.marineTrafficLink)
            || (touched.master && errors.master)
        ) {
            return true;
        }
        return false;
    }

    return (
        <SeaModal
            title={(vessel ? 'Edit ' : 'Add New ') + (isShoreFacilityCheck ? 'Shore Based Facility' : 'Vessel')}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="vesselParticulars" mode="forms" hasErrors={particularsTabHasErrors()}>{isShoreFacilityCheck ? 'Facility Particulars' : 'Vessel Particulars'}</SeaTab>
                    <SeaTab hide={isShoreFacilityCheck || !canEdit('engineHours')} tab="engines" mode="forms">Engines / Generators</SeaTab>
                    {superAdmin &&
                        <SeaTab tab="admin" mode="forms">Admin</SeaTab>
                    }
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Vessel Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="vesselParticulars" selectedTab={tab}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size={isShoreFacilityCheck ? '12' : '6'}>
                                <SeaInput
                                    label={isShoreFacilityCheck ? 'Facility Name' : 'Boat name'}
                                    name="name"
                                    value={values.name}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    type="text"
                                    inputmode="text"
                                    error={touched.name ? errors.name : ''}
                                />
                            </IonCol>
                            {!isShoreFacilityCheck && <>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Callsign"
                                        name="callsign"
                                        value={values.callsign}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.callsign ? errors.callsign : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label={(licenseeSettings && regions[licenseeSettings?.region]?.vesselReg) ?? regions.default.vesselReg}
                                        name="mnz" 
                                        value={values.mnz}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.mnz ? errors.mnz : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="MMSI"
                                        name="mmsi"
                                        value={values.mmsi}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.mmsi ? errors.mmsi : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Port of registry"
                                        name="portOfRegistry"
                                        value={values.portOfRegistry}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.portOfRegistry ? errors.portOfRegistry : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Length"
                                        name="length"
                                        value={values.length}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.length ? errors.length : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Breadth"
                                        name="breadth"
                                        value={values.breadth}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.breadth ? errors.breadth : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Gross tonnage"
                                        name="grossTonnage"
                                        value={values.grossTonnage}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.grossTonnage ? errors.grossTonnage : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Drive type"
                                        name="driveType"
                                        value={values.driveType}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.driveType ? errors.driveType : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaDate
                                        label="Date launched"
                                        name="whenLaunched"
                                        value={values.whenLaunched}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenLaunched ? errors.whenLaunched : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Engines"
                                        name="enginesDescription"
                                        value={values.enginesDescription}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.enginesDescription ? errors.enginesDescription : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Construction"
                                        name="construction"
                                        value={values.construction}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.construction ? errors.construction : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Area of operation"
                                        name="areaOfOperation"
                                        value={values.areaOfOperation}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.areaOfOperation ? errors.areaOfOperation : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Fuel range"
                                        name="fuelRange"
                                        value={values.fuelRange}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.fuelRange ? errors.fuelRange : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="marine traffic link location url"
                                        name="marineTrafficLink"
                                        value={values.marineTrafficLink}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="url"
                                        inputmode="url"
                                        error={touched.marineTrafficLink ? errors.marineTrafficLink : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaInput
                                        label="Master's name"
                                        name="master"
                                        value={values.master}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        type="text"
                                        inputmode="text"
                                        error={touched.master ? errors.master : ''}
                                    />
                                </IonCol>
                                <IonCol size="6"></IonCol>
                            </>}
                            <IonCol size="12">

                                <SeaFileUpload
                                    label={isShoreFacilityCheck ? 'Facility Image' : 'Vessel Image'}
                                    files={files}
                                    setFiles={setFiles}
                                    collection="vessels"
                                    field="images"
                                />

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>
                <SeaTabContent tab="engines" selectedTab={tab}>
                    <SeaInputList
                        name="engineNames"
                        label="Engine / Generator"
                        maxWidth="500px"
                        values={engineNames}
                        setValues={setEngineNames}
                        ids={engineIds}
                        setIds={setEngineIds}
                        addNewText="Add Engine / Generator"
                        confirmDelete={true}
                    />
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditVesselModal;
