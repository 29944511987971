import { StyleSheet, Font, Text, View } from '@react-pdf/renderer';
import React, { useCallback } from 'react';

Font.register({ family: 'Montserrat', fonts: [
    { src: '/assets/fonts/Montserrat-Regular.ttf', fontWeight: 400 },
    { src: '/assets/fonts/Montserrat-Medium.ttf', fontWeight: 500 },
    { src: '/assets/fonts/Montserrat-Bold.ttf', fontWeight: 700 },
    { src: '/assets/fonts/Montserrat-Italic-Regular.ttf', fontWeight: 400, fontStyle: 'italic' },
    { src: '/assets/fonts/Montserrat-Italic-Bold.ttf', fontWeight: 700, fontStyle: 'italic' },
]});

// These need to be kept in sync with global css variables
export const pdfColours = {
    bgHeader: '#373946', // --bg-header
    ionColorPrimary: '#004896', // --ion-color-primary
    ionColorLight: '#f5f6f8', // --ion-color-light
    ionColorSecondary: '#0369d9', // --ion-color-secondary
    inputBorderColor: '#DCDDE1', // --input-border-color
    labelText: '#7f818c', // --label-text
    bgViewOnly: '#eaedf0', // --bg-view-only
    bgDraft: '#cef2fe', // --bg-draft
    textColor: '#333333',
    textOnDraft: '#666666',
    bgFaultColor: '#eb445a',
    failColor: '#e93232',
    bgFailColor: 'rgb(245, 215, 215)',
    warnColor: '#ffc107', // --ion-color-warning
    bgWarnColor: 'rgb(253, 243, 209)',
    successColor: '#3caf6a', // --bg-completed
    ionColorDanger: '#eb445a', // --ion-color-danger
    rowSpacerBg: '#cccccc',
    tableSubheadingBg: '#3c9797',
    tableBorderColor: '#999999',
    tableCellBg: '#f5f5f5',
    noData: '#888',
};

export const pieColours = [
    '#0099fa',
    '#f5009b',
    '#f7b422',
    '#b100f8',
    '#00e3ea',
    '#d29ce8',
    '#f5ea00',
    '#4a42fa',
    '#42fabb',
    '#4de83d',
    '#e8793d',
    '#e83a3a',
    '#6197ba',
    '#b76097',
    '#b7b360',
    '#8c89cb',
    '#88bf82',
    '#0053ae',
    '#990054',
    '#a06015',
    '#007f86',
    '#998600',
    '#27ae64',
    '#2d8325',
    '#834425',
    '#832323'
];

export const formWidth = 739;
export const usuableWidthCm = 19;
export const pixelsToCm = (pixels: number) => {
    // A4 = 21cm
    // usuable width = 19cm
    // form width = 739 pixels
    // cm per pixel = 19 / 739
    const cm = pixels * usuableWidthCm / formWidth;
    return Math.round(cm * 1000) / 1000; // Round to 4 dp
};

// Layout is based on A4 being 21cm wide
export const pdfStyles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        fontSize: '10pt',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        color: pdfColours.bgHeader,
        padding: '1.25cm 0 1cm 0',
        position: 'relative'
    },
    header: {
        marginBottom: '1cm'
    },
    heading: {
        fontSize: '18pt',
        fontWeight: 700,
        color: pdfColours.ionColorPrimary,
        margin: '0 1cm 0.15cm',
    },
    subHeading: {
        fontSize: '10pt',
        fontWeight: 700,
        color: pdfColours.ionColorPrimary,
        margin: '0 1cm 0.05cm'
    },
    headingText: {
        //color: pdfColours.ionColorPrimary,
        fontSize: '9pt',
        color: '#6b6d79',
        margin: '0 1cm 0.05cm'
    },
    spacer: {
        height: '1cm'
    },
    semiBold: {
        fontWeight: 500
    },
    bold: {
        fontWeight: 700
    },
    greyLine: {
        backgroundColor: '#e0e1e5',
        height: '1pt',
        margin: '0 1cm 0.9cm'
    },
    endInfoLineSub: {
        height: '1pt',
        margin: '0 1cm 0.9cm',
        backgroundColor: 'rgba(255,255,255,0)',
        borderTopWidth: '1pt',
        borderStyle: 'dashed',
        borderColor: pdfColours.inputBorderColor,
    },
    blueLine: {
        backgroundColor: pdfColours.ionColorPrimary,
        height: '1pt',
        margin: '0 1cm 0.4cm'
    },
    row: {
        flexDirection: 'row',
        maxWidth: '19.6cm',
        margin: '0 0.8cm 0.9cm 0.8cm'
    },
    rowInBetween: {
        flexDirection: 'row',
        maxWidth: '19.6cm',
        margin: '0 0.8cm 0.4cm 0.8cm'
    },
    rowNoSpace: {
        flexDirection: 'row',
        maxWidth: '19.6cm',
        margin: '0 0.8cm 0 0.8cm'
    },
    column1: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0 0.2cm 0 0.2cm',
    },
    column2: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0 0.2cm 0 0.2cm',
        maxWidth: '9.7cm'
    },
    column3: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0 0.2cm 0 0.2cm',
        maxWidth: '6.4666cm'
    },
    column2x3: {
        flex: '2 0 1', // (grow shrink basis)
        padding: '0 0.2cm 0 0.2cm',
        maxWidth: '12.9332cm'
    },
    column4: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0 0.2cm 0 0.2cm',
        maxWidth: '4.85cm'
    },
    label: {
        fontSize: '9pt',
        fontWeight: 400,
        color: pdfColours.labelText,
        minHeight: '0.75cm',
        textTransform: 'uppercase'
    },
    labelHeading: {
        fontSize: '12pt',
        fontWeight: 600,
        color: pdfColours.labelText
    },
    labelNoUC: {
        fontSize: '9pt',
        fontWeight: 400,
        color: '#575860',
        minHeight: '0.6cm'
    },
    field: {
        fontWeight: 700
    },
    pie: {
        width: '100%',
        // height: 200
        height: `${pixelsToCm(400)}cm`,
    },
    pieKeys: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '0.6cm 0 1cm 0'
    },
    key: {
        flexDirection: 'row',
        margin: '0cm 0.22cm 0.2cm'
    },
    keyIcon: {
        width: '0.4cm',
        height: '0.4cm',
        marginRight: '0.16cm',
        marginTop: '0.025cm'
    },
    keyText: {
        alignSelf: 'center'
    },
    fail: {
        color: pdfColours.failColor
    },
    warn: {
        color: pdfColours.warnColor
    },
    //
    // Specific to Custom Forms
    //
    customFormRow: {
        // 1 - 0.1543 = 0.8457
        flexDirection: 'row',
        maxWidth: '19.6cm',
        margin: '0 0.8457cm 0 0.8457cm'
    },
    element: {
        // 10px 6px 8px 6px
        // 
        flex: '1 0 1',
        padding: '0.2571cm 0.1543cm 0.2057cm 0.1543cm'
    },
    input: {
        backgroundColor: pdfColours.bgViewOnly,
        borderRadius: '0.14cm',
        padding: '0.46cm 0.45cm 0.45cm 0.45cm'
    },
    checkbox: {
        borderRadius: '0.14cm',
        width: '1.0284cm',
        height: '1.0284cm',
        minWidth: '1.0284cm'
    },
    //
    // Lists of items
    //
    itemsHeader: {
        marginBottom: '0.7cm',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    itemsHeaderRight: {
        textAlign: 'right',
        paddingRight: '1cm',
        fontSize: '9pt',
        color: '#444444',
        alignItems: 'flex-end',
    },
    itemHeadingsRow: {
        flexDirection: 'row',
        //maxWidth: '19.6cm',
        margin: '0 0.8cm 0cm 0.8cm',
        alignItems: 'flex-end',
        fontSize: '8pt',
        textTransform: 'uppercase',
        color: pdfColours.textColor
    },
    itemsRow: {
        flexDirection: 'row',
        //maxWidth: '19.6cm',
        margin: '0 0.8cm 0cm 0.8cm',
        alignItems: 'center',
        fontSize: '9pt',
        color: pdfColours.textColor,
        maxHeight: '1.2cm',
        minHeight: '1cm'
    },
    itemHeadingsLine: {
        backgroundColor: pdfColours.tableBorderColor,
        height: '1pt',
        margin: '0.1cm 1cm 0.1cm'
    },
    tableHeadingsRow: {
        flexDirection: 'row',
        //maxWidth: '28.1cm',
        margin: '0 1cm 0cm 1cm',
        alignItems: 'flex-end',
        fontSize: '8pt',
        textTransform: 'uppercase',
        color: pdfColours.textColor
    },
    tableRow: {
        flexDirection: 'row',
        //maxWidth: '28.1cm',
        margin: '0 1cm 0cm 1cm',
        alignItems: 'center',
        fontSize: '9pt',
        color: pdfColours.textColor,
        //maxHeight: '1.2cm',
        minHeight: '1cm'
    },
    tableCell: {
        backgroundColor: pdfColours.tableCellBg,
        height: '100%',
        padding: '0.2cm 0.2cm 0.2cm 0.2cm',
        borderLeftWidth: '1pt',
        borderLeftStyle: 'solid',
        borderLeftColor: pdfColours.tableBorderColor,
        borderTopWidth: '1pt',
        borderTopStyle: 'solid',
        borderTopColor: pdfColours.tableBorderColor,
    },
    itemsCategory: {
        // backgroundColor: pdfColours.tableBorderColor,
        // color: '#FFFFFF',
        backgroundColor: '#DDDDDD',
        //margin: '-0.11cm 1cm 0.1cm 1cm',
        margin: '-0.11cm 1cm 0.1cm 1cm',
        padding: '0.25cm 0.25cm 0.25cm 0.25cm',
        textTransform: 'uppercase',
        fontWeight: 'semibold',
        fontSize: '8pt'
    },
    itemsCategorySpacer: {
        height: '1cm',
    },
    itemsRowSpacer: {
        backgroundColor: pdfColours.rowSpacerBg,
        height: '0.5pt',
        margin: '0.1cm 1cm 0.1cm'
    },
    itemsRowSpacerNoLine: {
        height: '0.2cm'
    },
    status: {
        textTransform: 'uppercase',
        fontSize: '8pt',
        fontWeight: 'semibold',
        margin: '0cm 0.2cm',
        minHeight: `${pixelsToCm(20)}cm`,
        padding: `0.02cm ${pixelsToCm(5)}`,
        borderRadius: `${pixelsToCm(10)}cm`,
        justifyContent: 'center'
    },
    thumbImg: {
        borderRadius: '0.05cm',
        width: '1cm',
        height: '1cm',
        minWidth: '1cm',
        objectFit: 'cover'
    },
    thumbImgRound: {
        borderRadius: '0.15cm',
        width: '1cm',
        height: '1cm',
        minWidth: '1cm',
        objectFit: 'cover'
    },
    thumbColumn: {
        width: '1.4cm',
        padding: '0 0.2cm 0 0.2cm'
    },
    thumbSeriesColumn: {
        width: '1.2cm',
        padding: '0 0 0 0.2cm'
    },
    criticalColumn: {
        width: '2cm',
        padding: '0 0.2cm 0 0.2cm',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    criticalImage: {
        width: '1cm',
        height: '1cm',
        minWidth: '1cm',
        objectFit: 'cover'
    },
    dateColumn: {
        width: '2.25cm',
        padding: '0 0.2cm 0 0.2cm'
    },
    numColumn: {
        width: '1.8cm',
        alignItems: 'center'
    },
    statusColumn: {
        width: '3.75cm',
        textAlign: 'center'
    },
    intervalColumn: {
        width: '1.9cm'
    },
    typeColumn: {
        width: '2.5cm'
    },
    timesCompletedColumn: {
        width: '2.75cm',
        whiteSpace: 'break-spaces'
    },
    // Windy.com
    windyColumn: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0 0.1cm 0 0.1cm',
        textAlign: 'center'
        // maxWidth: '4.85cm'
    },
    windyLabelColumn: {
        flex: '1 0 1', // (grow shrink basis)
        padding: '0.04cm 0.2cm 0 0.6cm'
    },
    windyMore: {
        padding: '0.2cm 0cm 0.2cm 0cm'
    },
    windyUnits: {
        fontSize: '8pt'
    },
    incidentStatus: {
        height: `${pixelsToCm(24)}cm`,
        fontSize: `${pixelsToCm(11)}cm`,
        fontWeight: 700,
        color: '#ffffff',
        textAlign: 'center',
        textTransform: 'uppercase',
        borderRadius: `${pixelsToCm(12)}cm`,
        maxWidth: `${pixelsToCm(150)}cm`,
        overflow: 'hidden',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    },
    okStatus:{
        backgroundColor: '#2dd36f',
    },
    warnStatus: {
        backgroundColor: pdfColours.warnColor,
    },
    failStatus: {
        color: pdfColours.failColor
    },
    nowStatus: {
        backgroundColor: pdfColours.ionColorLight,
        color: pdfColours.ionColorSecondary,
    },
    forReviewStatus: {
        color: 'white',
        backgroundColor: pdfColours.ionColorDanger,
    },
    inReviewStatus: {
        color: 'white',
        backgroundColor: pdfColours.warnColor,
    },
    completedStatus: {
        color: pdfColours.successColor,
        fontWeight: 500,
        fontSize: `${pixelsToCm(11)}cm`,
    },
    draftStatus: {
        fontSize: `${pixelsToCm(11)}cm`,
        fontWeight: 700,
        // backgroundColor: '#e5e5e5',
        backgroundColor: pdfColours.bgDraft,
        color: pdfColours.textOnDraft,
        fontStyle: 'italic',
    },
    authorship: {
        fontStyle: 'italic',
        color: pdfColours.labelText,
        fontWeight: 400,
    },
    infoImgContainer: {
        position: 'absolute',
        top: '3cm',
        right: '1.4cm',
        // paddingRight: `${pixelsToCm(32)}cm`,
    },
    infoImg: {
        maxHeight: `${pixelsToCm(350)}cm`,
        maxWidth: `${pixelsToCm(350)}cm`,
    },
    btmRightImg: {
        position: 'absolute',
        bottom: '0cm',
        right: '0cm',
    }, 
    topRightImg: {
        position: 'absolute',
        top: '2cm',
        right: '0cm',
    },
    dataContainer: {
        flexDirection: 'column',
        maxWidth: '19.6cm',
        margin: '-0.3cm 0.8cm 0.9cm 0.8cm'
    },
    dataHeaderRow: {
        flexDirection: 'row',
        width: '100%',
        padding: '0.1cm 0.1cm 0.1cm 0.1cm',
    },
    dataHeaderText: {
        fontWeight: 500,
        overflowX: 'hidden',
        fontSize: `${pixelsToCm(13)}cm`,
        textTransform: 'uppercase',
        alignSelf: 'flex-end',
    },
    categoryHeading: {
        textTransform: 'uppercase',
        fontSize: `${pixelsToCm(10)}cm`,
        fontWeight: 'bold',
        letterSpacing: `${pixelsToCm(2)}cm`,
        backgroundColor: '#9395a5',
        marginTop: `${pixelsToCm(32)}cm`,
        marginBottom: '0.1cm',
        padding: `${pixelsToCm(6)}cm ${pixelsToCm(12)}cm `,
        color: '#fff',
    },
    historyGroupHeaderText: {
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'semibold',
        fontSize: '8pt'
    },
    greyDataRow: {
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#e8ebee',
        padding: '0.1cm',
        height: `${pixelsToCm(50)}cm`,
        borderRadius: '0.14cm',
        margin: '0.1cm 0cm 0.1cm 0cm',
        alignItems: 'center',
    },
    greyDataRowSmall: {
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#e8ebee',
        padding: '0.1cm',
        height: `${pixelsToCm(40)}cm`,
        borderRadius: '0.14cm',
        margin: '0.05cm 0cm 0.05cm 0cm',
        alignItems: 'center',
    },
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    noDataText: {
        color: pdfColours.noData,
        fontSize: `${pixelsToCm(13)}cm`,
        fontStyle: 'italic',
        marginLeft: '0.2cm'
    },

});

export const styleItemColumn = (weight: number) => {
    return {
        flex: `${weight} 0 0`,
        padding: '0 0.2cm 0 0.2cm'
    };
};

// Concerts an array into an array of arrays to match columns
export const columnifyData = (array: any[] | undefined, columns = 4) => {
    if (array === undefined || array.length === 0) {
        return undefined;
    }
    const data = [] as any[][]
    for (let i = 0; i < array.length; i++) {
        if (i % columns === 0) {
            data[Math.floor(i / columns)] = [];
        }
        data[Math.floor(i / columns)][i % columns] = array[i];
    }
    return data;
};

// Sometimes pdf renderer doesn't like an empty string.
// This function insures that it will return the content or undefined.
export const renderIf = (check: boolean, content: JSX.Element) => {
    if (check) {
        return content;
    }
    return undefined;
}

export const limitPdfText = (text: string, maxCharacters = 50) => {
    if (text) {
        const s = text.replace(/\n/g, ' ');
        if (s.length > maxCharacters) {
            return s.substring(0, maxCharacters)+'...';
        }
        return s;
    }
    return '';
};

export const PdfPageLimiter = (_counterRef?: React.MutableRefObject<number>, limit = 200) => {
    const defaultcounterRef = React.useRef(0);
    const counterRef = _counterRef || defaultcounterRef;
    
    const isLimitReached = useCallback(() => {
        return counterRef.current >= limit;
    }, [counterRef, limit]);

    const mapPdfArrayWithLimit = (array: any[] | undefined, func: (item: any, index: number) => JSX.Element | undefined) => {
        if (array && array.length) {
            const output = [] as JSX.Element[];
            for (let i = 0; i < array.length; i++) {
                if (isLimitReached()) {
                    break;
                }
                const element = func(array[i], i);
                if (element !== undefined) {
                    output.push(element);
                    counterRef.current++;
                }
            }
            return output;
        }
        return null;
    };


    return {
        mapPdfArrayWithLimit,
        isLimitReached
    };
}

export const UnloadedPdfRows = ({length = 0, limit = 200}) => {
    if (!length || length <= limit) {
        return null;
    }
    return (
        <View>
            <View style={pdfStyles.spacer} />
            <Text style={pdfStyles.noDataText}>This document only contains the first 200 entries.{'\n'}There are {(length - limit).toLocaleString()} more entries that can be viewed using the Sea Flux app.</Text>
        </View>
    );
}
