import { QuerySnapshot } from "firebase/firestore";

export interface RenderStats {
    mounts: number;
    maxMountTime: number;
    totalMountTime: number;
    averageMountTime: number;
    updates: number;
    maxUpdateTime: number;
    totalUpdateTime: number;
    averageUpdateTime: number;
    allMaxTime: number;
}

export interface QueryStats {
    countCache: number;
    countFresh: number;
    size: number;
    countUpdates: number;
    cacheTime?: number;
    freshTime?: number;
}

export const measureRendering = false;
export const measureQueries = false;
export const renderStats = {} as {
    [key: string]: RenderStats;
};
export const queryStats = {} as {
    [key: string]: QueryStats;
};

export const onProfilerRender = (
    profilerId: string,
    mode: string,
    actualTime: number,
    baseTime: number,
    startTime: number,
    commitTime: number
) => {
    if (!measureRendering) return;
    //console.log('[PERF] ', {profilerId, mode, actualTime, baseTime, startTime, commitTime});
    if (renderStats[profilerId] === undefined) {
        renderStats[profilerId] = {
            mounts: 0,
            maxMountTime: 0,
            totalMountTime: 0,
            averageMountTime: 0,
            updates: 0,
            maxUpdateTime: 0,
            totalUpdateTime: 0,
            averageUpdateTime: 0,
            allMaxTime: 0
        };
    }
    const stats = renderStats[profilerId];
    if (mode === 'mount') {
        stats.mounts++;
        stats.maxMountTime = Math.max(stats.maxMountTime, actualTime);
        stats.totalMountTime += actualTime;
        stats.averageMountTime = stats.totalMountTime / stats.mounts;
    } else {
        stats.updates++;
        stats.maxUpdateTime = Math.max(stats.maxUpdateTime, actualTime);
        stats.totalUpdateTime += actualTime;
        stats.averageUpdateTime = stats.totalUpdateTime / stats.updates;
    }
    stats.allMaxTime = Math.max(stats.allMaxTime, actualTime);
    //console.log('[] performanceStats', performanceStats);
};

export const profileQuery = (id: string) => {
    if (measureQueries) {
        const startTime = Date.now();
        if (queryStats[id] === undefined) {
            queryStats[id] = {
                countCache: 0,
                countFresh: 0,
                size: 0,
                countUpdates: 0
            };
        }
        return {
            record: (snapshot: QuerySnapshot) => {
                const stats = queryStats[id];
                if (snapshot.metadata.fromCache) {
                    if (stats.cacheTime === undefined) {
                        stats.cacheTime = Date.now() - startTime;
                        stats.size = snapshot.size;
                    }
                    stats.countCache++;
                } else {
                    if (stats.freshTime === undefined) {
                        stats.freshTime = Date.now() - startTime;
                        stats.size = snapshot.size;
                    }
                    stats.countFresh++;
                }
            }
        };
    }
    return {
        record: () => {}
    };
};

