import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { useEffect, useState } from 'react';


export type RiskRegisterSettings = {
    version: 1 | 2,
    likelihoods: {
        rows: number[],
        [Ln: string]: {
            name: string,
            description: string
        } | number[],
    },
    consequences: {
        columns: number[],
        [Cn: string]: {
            name: string,
            description: string
        } | number[]
    },
    matrix: {
        [cell: string]: string
    },
    evaluations: {
        name: string,
        color: string,
        description: string
    }[],
    riskPrompts: string[],
    controlPrompts: string[]
};

export interface LicenseeSettings extends UpdateableDocument {
    hasMfaEmail: boolean;
    hasOffline: boolean;
    maxSessionSeconds: number;
    region: string;
    riskRegister: RiskRegisterSettings;
}
export const licenseeSettingsConfig: SharedStateConfig<LicenseeSettings> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.licenseeSettings.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onSnapshot(
                doc(firestore, 'licenseeSettings', licenseeId),
                (doc) => {
                    done();
                    if (doc.exists()) {
                        set({
                            ...doc.data()
                        } as LicenseeSettings);
                    }
                }, (error) => {
                    done();
                    console.log(`Error getting licenseeSettings for licensee ${licenseeId}`, error);
                }
            );
        }
    },
};

export const useLicenseeSettingsById = () => {
    const [licenseeSettingsById, setLicenseeSettingsById] = useState<{
        [ licenseeId: string ]: LicenseeSettings
    }>();

    useEffect(() => {
        return onSnapshot(
            query(
                collection(firestore, 'licenseeSettings')
            ),
            (snapshot) => {
                const byId = {} as {
                    [ licenseeId: string ]: LicenseeSettings
                };
                snapshot.docs.forEach((doc) => {
                    byId[doc.id] = doc.data() as LicenseeSettings;
                });
                setLicenseeSettingsById(byId);
            }, (error) => {
                // This should be very rare
                console.log('Failed to access licensee data', error.message, error);
            }
        );
    }, []);

    return licenseeSettingsById;
}
