import React, { useState, useEffect, useContext, useMemo } from 'react';
import { IonSpinner } from '@ionic/react';
import { canView } from '../../lib/permissions';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import MaintenanceReporting from './MaintenanceReporting/MaintenanceReporting';
import SafetyReporting from './SafetyReporting/SafetyReporting';
import HealthSafetyReporting from './HealthSafetyReporting/HealthSafetyReporting';
import SeaReportSettings from '../../components/SeaReportSettings/SeaReportSettings';
import DocumentReporting from './DocumentReporting/DocumentReporting';
import CrewReporting from './CrewReporting/CrewReporting';
import './Reporting.css';

const ReportSettingsContext = React.createContext({});

export function useReportSettings(): any {
    return useContext(ReportSettingsContext);
}

const Reporting: React.FC = () => {
    const vesselIds = sharedState.vesselIds.use();
    const vessels = sharedState.vessels.use();
    const todayMillis = sharedState.todayMillis.use()!
    const selectedSectionTab = sharedState.selectedSectionTab.use();
    const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState({
        from: todayMillis - (365 * 24 * 60 * 60 * 1000), // default 12 months
        // to: todayMillis,
        //from: 0, // TMP: for testing, use full history by default
        to: todayMillis
    });
    const [dateRangeDescription, setDateRangeDescription] = useState("");

    useEffect(() => {
        if (vesselIds) {
            setSelectedVesselIds([...vesselIds]);
        }
    }, [vesselIds]);

    // Per section, prepare graph availability according to userPermissions
    // including a count for number of graphs available
    const graphAvailabilitySections = useMemo(() => {
        const o = {
            safety: {
                count: 0,
                safetyChecks: false,
                completedSafetyChecks: false,
                safetyEquipment: false
            },
            maintenance: {
                count: 0,
                maintenanceTaskStatus: false,
                completedTasks: false,
                jobPriorities: false
            },
            healthSafety: {
                count: 0,
                incidentStatus: false,
                incidentTypes: false,
                incidentCategories: false,
                contributingFactors: false,
                injuryTypes: false,
                injuryLocations: false,
                incidentsNotified: false,
                incidentPersonnelInvolved: false,
                incidentInjuryOutcomes: false
            },
            documents: {
                count: 0,
                vesselCertificateStatus: false
            },
            crew: {
                count: 0,
                crewCertificateStatus: false,
                crewActionsLogged: false
            }
        };
        if (canView('safetyEquipmentChecks')) {
            o.safety.safetyChecks = true;
            o.safety.completedSafetyChecks = true;
            o.safety.count += 2;
        }
        if (canView('safetyEquipmentList')) {
            o.safety.safetyEquipment = true;
            o.safety.count++;
        }
        if (canView('maintenanceSchedule')) {
            o.maintenance.maintenanceTaskStatus = true;
            o.maintenance.count++;
        }
        if (canView('maintenanceHistory')) {
            o.maintenance.completedTasks = true;
            o.maintenance.count++;
        }
        if (canView('jobList')) {
            o.maintenance.jobPriorities = true;
            o.maintenance.count++;
        }
        if (canView('incidentAccidentMedicalRegister')) {
            o.healthSafety.incidentStatus = true;
            o.healthSafety.incidentTypes = true;
            o.healthSafety.incidentCategories = true;
            o.healthSafety.contributingFactors = true;
            o.healthSafety.injuryTypes = true;
            o.healthSafety.injuryLocations = true;
            o.healthSafety.incidentsNotified = true;
            o.healthSafety.incidentPersonnelInvolved = true;
            o.healthSafety.incidentInjuryOutcomes = true;
            o.healthSafety.count += 9;
        }
        if (canView('vesselCertificates')) {
            o.documents.vesselCertificateStatus = true;
            o.documents.count++;
        }
        if (canView('crewParticulars')) {
            o.crew.crewCertificateStatus = true;
            o.crew.crewActionsLogged = true;
            o.crew.count += 2;
        }
        return o;
    }, []);

    const showDateRange = useMemo(() => {
        return !(
            selectedSectionTab === 'Documents'
        );
    }, [selectedSectionTab]);

    const selectedVesselId = useMemo(() => {
        if (selectedVesselIds.length === 1) {
            return selectedVesselIds[0];
        }
        return undefined;
    }, [selectedVesselIds]);

    const value = {
        selectedVesselIds,
        setSelectedVesselIds,
        dateRange,
        setDateRange,
        dateRangeDescription,
        setDateRangeDescription,
        selectedVesselId
    };

    return (
        <StandardPageLayout section="reporting">
            <ReportSettingsContext.Provider value={value}>

                <SeaBreadcrumb>
                    <SeaLink to="/reporting">Reporting</SeaLink>
                </SeaBreadcrumb>

                <SeaTabsGroup
                    id="Reporting"
                    selectedTab={selectedSectionTab}
                    setTab={sharedState.selectedSectionTab.set}
                    mode="page-never-hide"
                >
                    <SeaTab
                        tab="Safety"
                        setTab={sharedState.selectedSectionTab.set}
                        hide={graphAvailabilitySections.safety.count === 0}
                    >
                        Safety
                    </SeaTab>
                    <SeaTab
                        tab="Maintenance"
                        setTab={sharedState.selectedSectionTab.set}
                        hide={graphAvailabilitySections.maintenance.count === 0}
                    >
                        Maintenance
                    </SeaTab>
                    <SeaTab
                        tab="HealthSafety"
                        setTab={sharedState.selectedSectionTab.set}
                        hide={graphAvailabilitySections.healthSafety.count === 0}
                    >
                        Health & Safety
                    </SeaTab>
                    <SeaTab
                        tab="Documents"
                        setTab={sharedState.selectedSectionTab.set}
                        hide={graphAvailabilitySections.documents.count === 0}
                    >
                        Documents
                    </SeaTab>
                    <SeaTab
                        tab="Crew"
                        setTab={sharedState.selectedSectionTab.set}
                        hide={graphAvailabilitySections.crew.count === 0}
                    >
                        Crew
                    </SeaTab>
                </SeaTabsGroup>

                <SeaReportSettings
                    mode="dashboard"
                    showDateRange={showDateRange}
                />

                {(vesselIds && vessels) ? (
                    <>
                        <SeaTabContent tab="Safety" selectedTab={selectedSectionTab}>
                            <SafetyReporting
                                visible={selectedSectionTab === 'Safety'}
                                graphAvailability={graphAvailabilitySections.safety}
                            />
                        </SeaTabContent>
                        <SeaTabContent tab="Maintenance" selectedTab={selectedSectionTab}>
                            <MaintenanceReporting
                                visible={selectedSectionTab === 'Maintenance'}
                                graphAvailability={graphAvailabilitySections.maintenance}
                            />
                        </SeaTabContent>
                        <SeaTabContent tab="HealthSafety" selectedTab={selectedSectionTab}>
                            <HealthSafetyReporting
                                visible={selectedSectionTab === 'HealthSafety'}
                                graphAvailability={graphAvailabilitySections.healthSafety}
                            />
                        </SeaTabContent>
                        <SeaTabContent tab="Documents" selectedTab={selectedSectionTab}>
                            <DocumentReporting
                                visible={selectedSectionTab === 'Documents'}
                                graphAvailability={graphAvailabilitySections.documents}
                            />
                        </SeaTabContent>
                        <SeaTabContent tab="Crew" selectedTab={selectedSectionTab}>
                            <CrewReporting
                                visible={selectedSectionTab === 'Crew'}
                                graphAvailability={graphAvailabilitySections.crew}
                            />
                        </SeaTabContent>
                    </>
                ) : (
                    <IonSpinner name="crescent" className="sea-spinner"/>
                )}
            </ReportSettingsContext.Provider>
        </StandardPageLayout>
    );
};

export default Reporting;
