import React from 'react';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaContextualHelp.css';

export interface SeaHelp {
    text?: string | undefined,
    files?: string[]
}

interface SeaContextualHelpProps {
    help: SeaHelp,
    mode?: 'label' | 'checkbox' | 'text',
}

const SeaContextualHelp: React.FC<SeaContextualHelpProps> = ({ help, mode }) => {
    let className = 'sea-help';
    switch (mode) {
        case 'text':
            className = 'text sea-help'; break;
        case 'checkbox':
            className = 'check text sea-help'; break;
    }

    if (!help || (!help.text && (!help.files || help.files.length === 0))) {
        return null;
    }

    return (
        <div
            className={className}
            style={{ paddingRight: '8px' }}
            onClick={(e) => {
                openContextualHelp(e, help);
            }}
        >
            <SeaIcon icon="help"/>
        </div>
    );
};

export default SeaContextualHelp;
