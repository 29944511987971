import React, { useState } from 'react';
import { useLicensees } from '../../../shared-state/Admin/licensees';
import SuperAdminPageLayout from '../../../layouts/SuperAdminPageLayout/SuperAdminPageLayout';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import LicenseeAccounts from './LicenseeAccounts/LicenseeAccounts';
import ErrorReports from './ErrorReports/ErrorReports';
import FilesAdmin from './FilesAdmin/FilesAdmin';
import './SuperAdmin.css';

const SuperAdmin: React.FC = () => {
    const licensees = useLicensees();
    const [tab, setTab] = useState('LicenseeAccounts');

    return (
        <SuperAdminPageLayout>
            <SeaTabsGroup id="SuperAdmin" selectedTab={tab} setTab={setTab} mode="forms">
                <SeaTab tab="LicenseeAccounts" setTab={setTab}>Licensee Accounts</SeaTab>
                <SeaTab tab="ErrorReports" setTab={setTab}>Error Reports</SeaTab>
                <SeaTab tab="Files" setTab={setTab}>Files</SeaTab>
            </SeaTabsGroup>

            <SeaTabContent tab="LicenseeAccounts" selectedTab={tab}>
                <LicenseeAccounts visible={tab === 'LicenseeAccounts'} licensees={licensees}/>
            </SeaTabContent>
            <SeaTabContent tab="ErrorReports" selectedTab={tab}>
                {tab === 'ErrorReports' &&
                    <ErrorReports visible={true}/>
                }
            </SeaTabContent>
            <SeaTabContent tab="Files" selectedTab={tab}>
                {tab === 'Files' &&
                    <FilesAdmin visible={true} licensees={licensees}/>
                }
            </SeaTabContent>
        </SuperAdminPageLayout>
    );
};

export default SuperAdmin;
