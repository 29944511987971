import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore } from '../../../../lib/firebase';
import { writeBatch } from "firebase/firestore";
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditContactSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditContactSettings: React.FC<EditContactSettingsProps> = ({showModal, setShowModal}) => {
    const licenceeId = sharedState.licenseeId.use(showModal);
    const contactCategories = sharedState.contactCategories.use(showModal);
    const [categoryIds, setCategoryIds] = useState<string[]>();
    const [categoryNames, setCategoryNames] = useState<string[]>();

    const onOpened = () => {
        if (!contactCategories) {
            console.log('No contact categories');
            return;
        }
        setCategoryIds(makeCategoryIdsForEditing(contactCategories));
        setCategoryNames(makeCategoryNamesForEditing(contactCategories));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const action = traceAction('contactCategories', 'update') as Action;
        action.docId = licenceeId;
        const batch = writeBatch(firestore);
        saveCategoryChanges(
            batch,
            categoryIds,
            categoryNames,
            undefined,
            contactCategories,
            'contactCategories',
            'licenseeId',
            licenceeId,
            action
        );

        action.save(`Update contact/supplier categories`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(categoryIds, categoryNames, contactCategories);
    }, [categoryIds, categoryNames, contactCategories]);

    return (
        <SeaModal
            title={`Contact/Supplier Settings`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInputList
                                name="contactCategories"
                                label="Contact/Supplier Categories"
                                maxWidth="350px"
                                ids={categoryIds}
                                setIds={setCategoryIds}
                                values={categoryNames}
                                setValues={setCategoryNames}
                                addNewText="Add Contact/Supplier Category"
                                confirmDelete={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{`Save Settings`}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditContactSettings;
