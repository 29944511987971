import React, { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../../../lib/firebase';
import QueryString from 'query-string';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';
import SeaButton from '../../../components/SeaButton/SeaButton';
import './Login.css';

const RestartRequired: React.FC = () => {
    const location = useLocation();
    const [restartReason, setRestartReason] = useState<string>();

    useEffect(() => {
        if (location?.search) { // Process query string
            const qs = QueryString.parse(location.search);
            if (qs.reason) {
                setRestartReason(qs.reason as string);
            }
        }
        logPageView('RestartRequired');
        // Make sure this page is always on top
        if (document.getElementsByClassName('ion-page')[0]) {
            (document.getElementsByClassName('ion-page')[0] as any).style.zIndex = 100000;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CenteredBoxPageLayout boxMode="message">
            {restartReason === 'loggedOut' &&
                <>
                    You have successfully logged out of Sea Flux.
                    <br/>
                    Please restart the app to log back in.
                </>
            }
            {restartReason === 'offlineFail' &&
                <>
                    There was a problem authenticating your account while offline.
                    <br/>
                    Please restart the app to try again.
                </>
            }
            {restartReason === 'sessionTimeout' &&
                <>
                    Your current Sea Flux session has expired.
                    <br/>
                    {(isPlatform('ios') && isPlatform('hybrid')) ?
                        'Please restart the app to log back in.'
                        :
                        <div style={{marginTop: '50px'}}>
                            <SeaButton
                                onClick={(e) => {
                                    window.location.href = '/login';
                                }}
                                zone="grey"
                            >
                                Log Back In
                            </SeaButton>
                        </div>
                    }
                </>
            }
            {restartReason === 'indexedDbError' &&
                <>
                    <p>
                        Unfortunately, a critical error has occurred preventing Sea Flux from operating.
                        This has most likely been caused by indexedDb failing which is a known issue with older versions of iOS.
                    </p>
                    <p>
                        To prevent this from happening in the future, we recommend upgrading your device to at least iOS 17 or higher (if your device supports it).
                    </p>
                    <p>
                        Fow now, restarting the app should resolve the problem. However, if this issue persists, you might need to restart your entire device.
                    </p>
                </>
            }
        </CenteredBoxPageLayout>
    );
};

export default RestartRequired;
