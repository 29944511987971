import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { UserType } from '../Core/user';
import { CreateableDocument, DeleteableDocument, UpdateableDocument } from '../shared-state';

export interface SeaTimeRecord extends CreateableDocument, UpdateableDocument, DeleteableDocument {
  licenseeId: string;
  vesselId: string;
  voyageId: string;
  skipperIds: string[];
  crewIds: string[];
  fromVoyage?: boolean;
  state: 'active' | 'deleted';
  whenVoyage: number;
  fromLocation?: string;
  toLocation?: string;
  masterIds?: string[];
  days?: number;
  hours?: {
    [userId: string]: number[]
  }
}

// export interface SeaTimeRecord extends CreateableDocument, UpdateableDocument, DeleteableDocument {
//         id: string;
//         whenDeparted: Date | string;
//         whenArrived?: Date | string;
//         departureFrom: string;
//         destinationTo?: string;
//         operation: string;
//         tripType: 'singleDay' | 'multiDay' | 'multiTrip';
//         personnelInvolved?: string[];
//         masterIds?: string[];
//         skipper?: string;
//         pax?: number;
//         tidalData?: {
//           stationName: string;
//           tides: Array<{
//             stage: string;
//             time: string;
//             height: number;
//           }>;
//         };
//         weatherForecast?: string;
//         weatherObservation?: {
//           windDirection?: string;
//           windStrength?: string;
//           seaState?: string;
//           visibility?: string;
//         };
//         tripReported?: boolean;
//         tripReportedTo?: string;
//         whenTripReported?: Date | string;
//         showSafetyBriefing?: boolean;
//         additionalPassengersOrDetails?: string;
//         safetyBriefCompleted?: boolean;
//         forecast?: {
//           lat?: number;
//           lon?: number;
//           data?: any;
//         };
//         startupChecks?: Array<{
//           check: string;
//           value: 'pass' | 'fail' | 'n/a';
//         }>;
//         shutdownChecks?: Array<{
//           check: string;
//           value: 'pass' | 'fail' | 'n/a';
//         }>;
//         startupNotes?: string;
//         shutdownNotes?: string;
//         voyageNotes?: string;
//         customTextFields?: Array<{
//           name: string;
//           value: string;
//         }>;
//         customHourFields?: Array<{
//           name: string;
//           value: number;
//         }>;
//         trips?: Array<{
//           masterId: string;
//           crewInvolved: string[];
//           departureFrom: string;
//           whenDeparted: Date | string;
//           paxOn: number;
//           stops: Array<{
//             location: string;
//             whenArrived: Date | string;
//             paxOff: number;
//             paxOn: number;
//             whenDeparted: Date | string;
//           }>;
//           destinationTo: string;
//           whenArrived: Date | string;
//         }>;
//         fuelStart?: number;
//         fuelBunkered?: number;
//         fuelBunkeredCost?: number;
//         fuelEnd?: number;
//         engineHours?: Array<{
//           id: string;
//           hours: number;
//         }>;
//         signature?: string;
//         days?: number;
//         vesselId: string;
//         hours?: {
//           [userId: string]: number[];
//         }
//         // days?: Array<{
//         //   notes: string;
//         //   customText: Array<{
//         //     name: string;
//         //     value: string;
//         //   }>;
//         //   customHours: Array<{
//         //     name: string;
//         //     value: number;
//         //   }>;
//         //   crewHours: Array<{
//         //     id: string;
//         //     hours: number;
//         //   }>;
//         // }>;
//       }

export const useSkipperSeaTimeRecords = (selectedUser?: UserType) => {
    const [skipperSeaTimeRecords, setSkipperSeaTimeRecords] = useState<SeaTimeRecord[]>();

    useEffect(() => {
        setSkipperSeaTimeRecords(undefined);
        if (selectedUser) {
            return onSnapshot(
                query(
                    collection(firestore, 'seaTimeRecords'),
                    where('licenseeId', '==', selectedUser.licenseeId),
                    where('state', '==', 'active'),
                    where('masterIds', 'array-contains', selectedUser.id),
                    orderBy('whenVoyage', 'desc')
                ),
                (snap) => {
                    const items = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as SeaTimeRecord;
                    })
                    setSkipperSeaTimeRecords(items);
    
                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access skipper sea time records for user ${selectedUser.id} `, error);
                }
            );
        }
    }, [selectedUser]);

    return skipperSeaTimeRecords;
}