import { database } from "../../lib/firebase";
import { ref as databaseRef, onValue as databaseOnValue } from "firebase/database";
import { SharedStateConfig, sharedState } from "../shared-state";
import { onOnlineStatusChangedForFileSync } from "../../lib/fileSync";

//
// Handles online status
// Also handles logic to hide fake errors that can occur within iOS devices when just reconnecting online
//

export type OnlineStatus = {
    isOnline: boolean,
    lastWentOnline: number,
    lastWentOffline: number,
    history: number[]
};

// sharedState.allowShowErrors is used to trigger when ReportingContext.errorsOnHold are to be checked if they're fake or real.
// According to my analysis, over 95% of fakeErrors will be ignored if we wait 15 minutes of being online before checking for fakeness.
// Note: This could be increased up to 20 minutes if too many fake errors are being seen and the tradeoff seems worth it.
const fakeErrorWaitMillis = 15 * 60 * 1000; // 15 minutes

export const onlineStatusConfig: SharedStateConfig<OnlineStatus> = {
    isAlwaysActive: true,
    default: {
        isOnline: false,
        lastWentOnline: Date.now(),
        lastWentOffline: Date.now(),
        history: []
    }
};

export const initOnlineStatus = () => {
    return databaseOnValue(
        databaseRef(database, '.info/connected'),
        (snap) => {
            let interval: any;
            const onlineStatus = {
                ...sharedState.onlineStatus.current
            } as OnlineStatus;
            onlineStatus.isOnline = snap.val() ? true : false;
            
            const now = Date.now();
            onlineStatus.history.unshift(now);
            if (onlineStatus.history.length > 10) {
                onlineStatus.history.pop();
            }

            sharedState.allowShowErrors.set(false);
            if (onlineStatus.isOnline) {
                // Coming online
                onlineStatus.lastWentOnline = now;
                interval = setTimeout(() => {
                    if (
                        sharedState.onlineStatus.current?.isOnline &&
                        (Date.now() - sharedState.onlineStatus.current.lastWentOnline) >= (fakeErrorWaitMillis - 1000) // fakeErrorWaitMillis - 1 second for leniency
                    ) {
                        console.log('Allowing all errors to be shown');
                        sharedState.allowShowErrors.set(true);
                    }
                }, fakeErrorWaitMillis);
            } else {
                // Going offline
                onlineStatus.lastWentOffline = now;
            }

            sharedState.onlineStatus.set(onlineStatus as OnlineStatus);

            // Notify fileSync system
            onOnlineStatusChangedForFileSync();

            return () => {
                clearInterval(interval);
            }
        }
    );
};

