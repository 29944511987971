import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { LicenseeUserType } from "../Core/user";
import { useEffect, useState } from "react";

//
// Loads all active licensees.
// This can only be used by superAdmin.
//

export type LicenseesData = {
    all: LicenseeUserType[];
    byId: {
        [id: string]: LicenseeUserType
    };
    totalActive: number;
    totalVessels: number;
};

export const useLicensees = () => {
    const [licensees, setLicensees] = useState<LicenseesData>();
    
    useEffect(() => {
        setLicensees(undefined);
        return onSnapshot(
            query(
                collection(firestore, 'users'),
                where('isLicensee', '==', true),
                where('state', '==', 'active'),
                orderBy('firstName'),
                orderBy('lastName')
            ),
            (snap) => {
                // if (isDesktop && snapshot.metadata.hasPendingWrites) {
                //     return; // For desktop, we don't want to show data not yet saved to the cloud
                // }
                const all = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    };
                });
                const byId = {} as {
                    [id: string]: LicenseeUserType
                };
                // Calculate summary stats
                let totalActive = 0;
                let totalVessels = 0;
                all.forEach((user: LicenseeUserType) => {
                    totalActive++;
                    if (user.numVessels) {
                        totalVessels += user.numVessels;
                    }
                    byId[user.id] = user;
                });
                setLicensees({
                    all,
                    byId,
                    totalActive,
                    totalVessels
                });
            },
            (error) => {
                console.log(`error getting licensees`, error);
            }
        );
    }, []);
    return licensees;
};
