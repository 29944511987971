import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { logPageView } from '../../../lib/firebase';
import { useReportSettings } from '../Reporting';
import { faultableTaskStatuses, taskStatuses } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import { useReportingDueSafetyChecks } from '../../../shared-state/Reporting/SafetyReporting/reportingDueSafetyChecks';
import { useReportingCompletedSafetyChecksMap } from '../../../shared-state/Reporting/SafetyReporting/reportingCompletedSafetyChecksMap';
import { useReportingCompletedSafetyChecks } from '../../../shared-state/Reporting/SafetyReporting/reportingCompletedSafetyChecks';
import { useReportingSafetyItemsByIds } from '../../../shared-state/Reporting/SafetyReporting/reportingSafetyItemsById';
import { useReportingFaultedSafetyChecks } from '../../../shared-state/Reporting/SafetyReporting/reportingFaultedSafetyChecks';
import { useReportingSafetyEquipments } from '../../../shared-state/Reporting/SafetyReporting/reportingSafetyEquipment';
import { SafetyCheckItem } from '../../../shared-state/VesselSafety/safetyCheckItems';
import reporting, { colours } from '../../../lib/reporting';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import ViewSafetyCheckStatusReport from '../../../modals/Reporting/Safety/ViewSafetyCheckStatusReport/ViewSafetyCheckStatusReport';
import ViewSafetyEquipmentStatusReport from '../../../modals/Reporting/Safety/ViewSafetyEquipmentStatusReport/ViewSafetyEquipmentStatusReport';
import ViewCompletedSafetyChecksReport from '../../../modals/Reporting/Safety/ViewCompletedSafetyChecksReport/ViewCompletedSafetyChecksReport';
import './SafetyReporting.css';

interface SafetyReportingProps {
    visible: boolean,
    graphAvailability: {
        count: number,
        safetyChecks: boolean,
        completedSafetyChecks: boolean,
        safetyEquipment: boolean
    }
}

const SafetyReporting: React.FC<SafetyReportingProps> = ({
    visible,
    graphAvailability
}) => {
    const todayMillis = sharedState.todayMillis.use(visible ? 1 : 101) as number;
    const { selectedVesselIds, selectedVesselId, dateRangeDescription } = useReportSettings();
    const [modalToShow, setModalToShow] = useState<string>();
    const [filteredSafetyChecks, setFilteredSafetyChecks] = useState<SafetyCheckItem[]>();
    const [selectedSafetyCheckStatuses, setSelectedSafetyCheckStatuses] = useState(Object.keys(faultableTaskStatuses));
    const [safetyCriticality, setSafetyCriticality] = useState('both');
    const [selectedSafetyEquipmentStatuses, setSelectedSafetyEquipmentStatuses] = useState(Object.keys(taskStatuses));
    const vesselSafetyItemsById = useReportingSafetyItemsByIds(
        visible && graphAvailability.count > 0,
        selectedVesselIds
    ); // vesselSafetyItems using selectedVesselIds
    const dueSafetyChecks = useReportingDueSafetyChecks(
        visible && graphAvailability.safetyChecks,
        selectedVesselIds,
        selectedSafetyCheckStatuses
    );
    const faultedSafetyChecks = useReportingFaultedSafetyChecks(
        visible && graphAvailability.safetyChecks,
        selectedVesselIds,
        selectedSafetyCheckStatuses
    );
    const completedSafetyChecksMap = useReportingCompletedSafetyChecksMap(
        visible && graphAvailability.completedSafetyChecks,
        selectedVesselIds
    );
    const completedSafetyChecks = useReportingCompletedSafetyChecks(
        visible && graphAvailability.completedSafetyChecks,
        selectedVesselIds,
        completedSafetyChecksMap,
        vesselSafetyItemsById,
        safetyCriticality
    );
    const filteredSafetyEquipment = useReportingSafetyEquipments(
        visible && graphAvailability.safetyEquipment,
        selectedVesselIds,
        selectedSafetyEquipmentStatuses,
        vesselSafetyItemsById,
        safetyCriticality
    );


    useEffect(() => {
        if (visible) {
            logPageView('Reporting/SafetyReporting');
        }
    }, [visible]);

    // filteredSafetyChecks (dueSafetyChecks & faultedSafetyChecks combined)
    useEffect(() => {
        if (selectedSafetyCheckStatuses.length === 0 || selectedVesselIds.length === 0) {
            // Nothing to load
            setFilteredSafetyChecks([]);
            return;
        }
        setFilteredSafetyChecks(undefined);
        if (dueSafetyChecks || faultedSafetyChecks) {
            let array: SafetyCheckItem[] = [];
            if (dueSafetyChecks && faultedSafetyChecks) {
                array = [...faultedSafetyChecks, ...dueSafetyChecks];
            } else if (dueSafetyChecks) {
                array = dueSafetyChecks;
            } else if (faultedSafetyChecks) {
                array = faultedSafetyChecks;
            }

            if (safetyCriticality === 'both') {
                setFilteredSafetyChecks(array);
                return;
            }
            if (vesselSafetyItemsById) {
                if (safetyCriticality === 'critical') {
                    setFilteredSafetyChecks(
                        array.filter((item) => vesselSafetyItemsById[item.itemId]?.isCritical)
                    );
                } else {
                    setFilteredSafetyChecks(
                        array.filter((item) => !vesselSafetyItemsById[item.itemId]?.isCritical)
                    );
                }
            }
        }
    }, [selectedVesselIds.length, dueSafetyChecks, faultedSafetyChecks, selectedSafetyCheckStatuses, safetyCriticality, vesselSafetyItemsById]);

    const safetyChecksGraphData = useMemo(() => {
        if (filteredSafetyChecks && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByStatus = {
                'faulted': 0,
                'overdue': 1,
                'upcoming': 2
            };

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0, 0]
                }
                byVesselId[vesselId] = item;
                array.push(item);
            });

            filteredSafetyChecks.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    if (item.hasFault) { // Faulted item
                        byVesselId[item.vesselId].values![indexByStatus.faulted]++;
                    } else if (item.whenDue && item.whenDue < todayMillis) { // Overdue item
                        byVesselId[item.vesselId].values![indexByStatus.overdue]++;
                    } else { // Upcoming item
                        byVesselId[item.vesselId].values![indexByStatus.upcoming]++;
                    }
                }
            });
            return array;
        }
        return undefined;
    }, [todayMillis, filteredSafetyChecks, selectedVesselIds]);

    const selectedSafetyCheckStatusNames = useMemo(() => {
        return Object.keys(faultableTaskStatuses).map((statusId: string) => {
            if (selectedSafetyCheckStatuses.indexOf(statusId) !== -1) {
                return faultableTaskStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedSafetyCheckStatuses]);

    const completedSafetyChecksGraphData = useMemo(() => {
        if (selectedVesselIds.length === 0) {
            // Nothing to show
            return [];
        }
        if (completedSafetyChecks) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    value: 0
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });

            completedSafetyChecks.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    byVesselId[item.vesselId].value! += (item.timesCompleted || 0);
                }
            });

            return array;
        }
        return undefined;
    }, [completedSafetyChecks, selectedVesselIds]);

    const safetyEquipmentGraphData = useMemo(() => {
        if (filteredSafetyEquipment && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByStatus = {
                'overdue': 0,
                'upcoming': 1
            };

            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0]
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });

            filteredSafetyEquipment.forEach((item) => {
                if (byVesselId[item.vesselId]) {
                    if (item.whenDue && item.whenDue < todayMillis) { // Overdue item
                        byVesselId[item.vesselId].values![indexByStatus.overdue]++;
                    } else { // Upcoming item
                        byVesselId[item.vesselId].values![indexByStatus.upcoming]++;
                    }
                }
            });
            return array;
        }
        return undefined;
    }, [todayMillis, filteredSafetyEquipment, selectedVesselIds]);

    const selectedSafetyEquipmentStatusNames = useMemo(() => {
        return Object.keys(taskStatuses).map((statusId: string) => {
            if (selectedSafetyEquipmentStatuses.indexOf(statusId) !== -1) {
                return taskStatuses[statusId];
            } else {
                return undefined; // Store undefined in array so it can be skipped
            }
        });
    }, [selectedSafetyEquipmentStatuses]);

    const renderSafetyTitle = useCallback((title: string) => {
        switch (safetyCriticality) {
            case 'nonCritical':
                return `Non-Critical ${title}`;
            case 'critical':
                return `Critical ${title}`;
            default:
                return title;
        }
    }, [safetyCriticality]);

    const completedSafetyChecksTitle = useMemo(() => {
        switch (safetyCriticality) {
            case 'nonCritical':
                return "Completed Non-Critical Safety Check Tasks";
            case 'critical':
                return "Completed Critical Safety Check Tasks";
            default:
                return "Completed Safety Check Tasks";
        }
    }, [safetyCriticality]);

    const dateRangeSubTitle = useMemo(() => {
        return `(${dateRangeDescription})`;
    }, [dateRangeDescription]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            <div className={`reporting-grid max-${graphAvailability.count}-graphs`}>
                {graphAvailability.safetyChecks &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={renderSafetyTitle('Safety Check Tasks by Vessel')}
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('safetyCheckStatus');
                        }}
                        data={safetyChecksGraphData}
                        units="Safety Checks"
                        categories={selectedSafetyCheckStatusNames}
                        colourPalette={reporting.colours.faultableTaskStatuses}
                    />
                }
                {graphAvailability.completedSafetyChecks &&
                    <SeaHorizontalBarGraph
                        n={n++}
                        title={completedSafetyChecksTitle}
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('completedSafetyChecks');
                        }}
                        data={completedSafetyChecksGraphData}
                        sortData={true}
                        units="Tasks Completed"
                        colourPalette={[colours.mint]}
                        hashNamesForColours={false}
                    />
                }
                {graphAvailability.safetyEquipment &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={renderSafetyTitle('Safety Equipment Expiry Tasks by Vessel')}
                        subTitle="(Live Status)"
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('safetyEquipmentStatus');
                        }}
                        data={safetyEquipmentGraphData}
                        units="Safety Equipment Items"
                        categories={selectedSafetyEquipmentStatusNames}
                        colourPalette={reporting.colours.taskStatuses}
                    />
                }
            </div>
            {visible &&
                <>
                    {graphAvailability.safetyChecks &&
                        <ViewSafetyCheckStatusReport
                            title={renderSafetyTitle('Safety Check Tasks by Vessel')}
                            subTitle={selectedVesselId ? `(${renderVesselName(selectedVesselId)})` : ''}
                            showModal={modalToShow === 'safetyCheckStatus'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'safetyCheckStatus' : undefined);
                            }}
                            graphData={safetyChecksGraphData}
                            filteredSafetyChecks={filteredSafetyChecks}
                            categories={selectedSafetyCheckStatusNames}
                            selectedSafetyCheckStatuses={selectedSafetyCheckStatuses}
                            setSelectedSafetyCheckStatuses={setSelectedSafetyCheckStatuses}
                            safetyCriticality={safetyCriticality}
                            setSafetyCriticality={setSafetyCriticality}
                            vesselSafetyItemsById={vesselSafetyItemsById}
                        />
                    }
                    {graphAvailability.completedSafetyChecks &&
                        <ViewCompletedSafetyChecksReport
                            title={completedSafetyChecksTitle}
                            subTitle={dateRangeSubTitle}
                            showModal={modalToShow === 'completedSafetyChecks'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'completedSafetyChecks' : undefined);
                            }}
                            graphData={completedSafetyChecksGraphData}
                            completedSafetyChecks={completedSafetyChecks}
                            safetyCriticality={safetyCriticality}
                            setSafetyCriticality={setSafetyCriticality}
                        />
                    }
                    {graphAvailability.safetyEquipment &&
                        <ViewSafetyEquipmentStatusReport
                            title={renderSafetyTitle('Safety Equipment Expiry Tasks by Vessel')}
                            subTitle={selectedVesselId ? `(${renderVesselName(selectedVesselId)})` : ''}
                            showModal={modalToShow === 'safetyEquipmentStatus'}
                            setShowModal={(showModal) => {
                                setModalToShow(showModal ? 'safetyEquipmentStatus' : undefined);
                            }}
                            graphData={safetyEquipmentGraphData}
                            filteredSafetyEquipment={filteredSafetyEquipment}
                            categories={selectedSafetyEquipmentStatusNames}
                            selectedSafetyEquipmentStatuses={selectedSafetyEquipmentStatuses}
                            setSelectedSafetyEquipmentStatuses={setSelectedSafetyEquipmentStatuses}
                            safetyCriticality={safetyCriticality}
                            setSafetyCriticality={setSafetyCriticality}
                            vesselSafetyItemsById={vesselSafetyItemsById}
                        />
                    }
                </>
            }
        </>
    );
};

export default SafetyReporting;
