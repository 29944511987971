import React, { useEffect, useCallback } from 'react';
import { IonCol } from '@ionic/react';
import { toFloat } from '../../lib/util';
import SeaInput from '../SeaInput/SeaInput';
import './SeaCrewHours.css';

/*
    Will maintain a list of ALL crew hours, but only allow editing the one's selected by personnelInvolved
    {
        <id>: {
            name: (first + last)
            initialHours:
            hours:
            error:
            isEdited: (if true, we shouldn't auto update with totalHours)
        }
        ...
    }
*/

export interface SeaCrewHourData {
    [key: string]: SeaCrewHour
}

export interface SeaCrewHour {
    isEdited?: boolean;
    name?: string;
    id: string
    hours: number;
}

interface SeaCrewHoursProps {
    name: string,
    crewHours?: SeaCrewHourData,
    setCrewHours: (crewHours: SeaCrewHourData) => void,
    personnelInvolved?: string[],
    totalHours: number,
    colSize?: string,
    isRequired?: boolean
}

const SeaCrewHours: React.FC<SeaCrewHoursProps> = ({
    name,
    crewHours,
    setCrewHours,
    personnelInvolved,
    totalHours,
    colSize="4",
    isRequired
}) => {

    useEffect(() => {
    }, [crewHours, personnelInvolved]);

    useEffect(() => {
        if (crewHours) {
            const _crewHours = {} as SeaCrewHourData;
            const props = Object.getOwnPropertyNames(crewHours);
            for (var i = 0; i < props.length; i++) {
                const userId = props[i];
                _crewHours[userId] = Object.assign({}, crewHours[userId], {
                    hours: ''+(crewHours[userId].isEdited ? crewHours[userId].hours : totalHours)
                });
            }
            setCrewHours(_crewHours);
        }
    }, [totalHours]);

    const onHoursChange = useCallback((e: CustomEvent<any>, userId: string) => {
        const _crewHours = {...crewHours};
        if (!_crewHours[userId].isEdited) {
            _crewHours[userId].isEdited = (e.detail.value !== (''+totalHours));
        }
        _crewHours[userId].hours = e.detail.value;
        // if (_crewHours[userId].hours === undefined || _crewHours[userId].hours.trim().length === 0) {
        //     _crewHours[userId].error = 'This value is required';
        // } else if (toInt(_crewHours[userId].hours, 0) > 10000) {
        //     _crewHours[userId].error = 'Value is too large';
        // } else {
        //     _crewHours[userId].error = '';
        // }
        setCrewHours(_crewHours);
    }, [crewHours, totalHours]);

    return (<>
        {crewHours && personnelInvolved?.filter(
            (userId: string) => {
                return crewHours[userId] !== undefined
            }
        ).map((userId: string) => {
            return (
                <IonCol size={colSize} key={`${name}${userId}`}>
                    <SeaInput
                        label={crewHours[userId].name}
                        //name="name"
                        value={crewHours[userId].hours}
                        onchange={(e) => {onHoursChange(e, userId)}}
                        //onblur={(e) => hasFormErrors()}
                        zone="white"
                        type="number"
                        inputmode="numeric"
                        error={
                            isRequired && (
                                toFloat(crewHours[userId].hours, 0) <= 0
                            ) ? 'This value is required' : ''
                        }
                    />
                </IonCol>
            );
        })}
    </>);
};

export default SeaCrewHours;
