import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { registerFiles } from "../../lib/fileSync";
import { Vessel } from './vessel';

//
// Loads vessel data based on vesselId.
// vesselId represents the currently selected vessel within the app.
//

export interface VesselOptions{
    id: string;
    name: string;
    isShoreFacility?: boolean;
    images?: string[];
}

export type VesselsData = {
    all: Vessel[], // Only includes vessels the user has access to
    categorised: { // Only includes vessels the user has access to
        vessels: Vessel[],
        shoreFacilities: Vessel[]
    },
    byId: { // Contains licensee-wide vessels
        [vesselId: string]: Vessel
    }
};

export const renderVesselName = (vesselId: string) => {
    if (vesselId && sharedState.vessels.current?.byId[vesselId]) {
        return sharedState.vessels.current.byId[vesselId].name;
    }
    return '';
};

export const renderVesselsList = (vesselIds: string[], defaultValue = '', separator = ', ') => {
    let s = '';
    const vessels = sharedState.vessels.current;
    if (vesselIds && vesselIds.length > 0 && vessels?.byId) {
        vesselIds.forEach((vesselId: string) => {
            if (vessels.byId[vesselId]) {
                if (s.length > 0) {
                    s += separator;
                }
                s += vessels.byId[vesselId].name;
            }
        });
    }
    if (s === '') {
        return defaultValue;
    }
    return s;
};

export const vesselsConfig: SharedStateConfig<VesselsData> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId', 'vesselIds'],
    countLiveDocs: () => sharedState.vessels.current ? Object.keys(sharedState.vessels.current.byId).length : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (licenseeId && vesselIds) {
            return onSnapshot(
                query(
                    collection(firestore, 'vessels'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    orderBy('name', 'asc')
                ),
                (snap) => {
                    done();
                    let byId = {} as {
                        [vesselId: string]: Vessel
                    };
                    let all = [] as Vessel[];
                    const categorised = {
                        vessels: [] as Vessel[],
                        shoreFacilities: [] as Vessel[]
                    };

                    snap.docs.forEach((doc) => {
                        const vessel = {
                            id: doc.id,
                            ...doc.data()
                        } as Vessel;
                        byId[vessel.id] = vessel;
                        if (vesselIds.includes(vessel.id)) {
                            all.push(vessel);
                            registerFiles(vessel.images);
                            if (vessel.isShoreFacility) {
                                categorised.shoreFacilities.push(vessel);
                            } else {
                                categorised.vessels.push(vessel);
                            }
                        }
                    });

                    set({
                        all,
                        categorised,
                        byId
                    });
                }, (error) => {
                    done();
                    console.log(`Error getting vessels for licensee ${licenseeId}`, error);
                }
            );
        }
    }
};
