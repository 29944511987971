import { QueryConstraint, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { Voyage } from "./voyages";
import { useState, useEffect } from "react";
import { registerFiles } from "../../lib/fileSync";
import { MaintenanceTaskCompleted } from '../VesselMaintenance/maintenanceTasksCompleted';

const voyageTimeLeeway = 5 * 60 * 1000; // Allow 5 minutes either side of voyage to match completed checks and tasks

// This returns the MaintenanceTaskCompleted that were completed during a voyage
export const useCompletedMaintenanceTasksDuringVoyage = (selectedVoyage: Voyage | undefined, includeLeeway?: boolean) => {
    const [completedMaintenanceTasksDuringVoyage, setCompletedMaintenanceTasksDuringVoyage] = useState<MaintenanceTaskCompleted[]>();

    useEffect(() => {
        if (selectedVoyage) {
            const queries: QueryConstraint[] = [where("vesselId", "==", selectedVoyage.vesselId), where("state", "==", "completed"), where("type", "==", "scheduled")];

            if (includeLeeway) {
                queries.push(where("whenCompleted", ">=", selectedVoyage.whenDeparted - voyageTimeLeeway));
                queries.push(where("whenCompleted", "<=", (selectedVoyage.whenArrived || 0) + voyageTimeLeeway));
            } else {
                queries.push(where("whenCompleted", ">=", selectedVoyage.whenDeparted));
            }

            return onSnapshot(
                query(collection(firestore, "maintenanceTasksCompleted"), ...queries),
                (snap) => {
                    const items = [] as MaintenanceTaskCompleted[];
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as MaintenanceTaskCompleted;
                        registerFiles(item.files);
                        items.push(item);
                    });
                    setCompletedMaintenanceTasksDuringVoyage(items);
                }, (error) => {
                    console.log(`Failed to access maintenanceTasksCompleted for voyageId=${selectedVoyage?.id} (tab)`, error.message, error, {
                        selectedVoyage,
                    });
                }
            );
        }
    }, [includeLeeway, selectedVoyage]);

    return completedMaintenanceTasksDuringVoyage;
};
