import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { CategoriesData, haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';

interface EditSopSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    vesselSopCategories: CategoriesData
}

const EditSopSettings: React.FC<EditSopSettingsProps> = ({showModal, setShowModal, vesselSopCategories}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [sopCategoryIds, setSopCategoryIds] = useState<string[]>();
    const [sopCategoryNames, setSopCategoryNames] = useState<string[]>();

    const onOpened = () => {
        setSopCategoryIds(makeCategoryIdsForEditing(vesselSopCategories));
        setSopCategoryNames(makeCategoryNamesForEditing(vesselSopCategories));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const action = traceAction('vesselSopCategories', 'update') as Action;
        action.docId = licenseeId;
        const batch = splittableBatch(firestore, 20 - 0);
        saveCategoryChanges(
            batch,
            sopCategoryIds,
            sopCategoryNames,
            undefined,
            vesselSopCategories,
            'vesselSopCategories',
            'vesselId',
            vesselId,
            action
        );

        action.save(`Update SOP categories`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error) => {
            action.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        return haveCategoriesChanged(sopCategoryIds, sopCategoryNames, vesselSopCategories);
    }, [sopCategoryIds, sopCategoryNames, vesselSopCategories]);

    return (
        <SeaModal
            title={`Standard Operating Procedure Settings`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaInputList
                                name="sopCategories"
                                label="Categories"
                                maxWidth="350px"
                                ids={sopCategoryIds}
                                setIds={setSopCategoryIds}
                                values={sopCategoryNames}
                                setValues={setSopCategoryNames}
                                addNewText="Add Category"
                                confirmDelete={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">{`Save Settings`}</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditSopSettings;
