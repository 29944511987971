import React from 'react';
import { Page, Text, View, Image  } from '@react-pdf/renderer';
import { pdfStyles, pixelsToCm } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { addedToRiskRegister, authoritiesNotifiedForReview, controlStrategies, injuryConclusions, lostTimes, yesNoNa } from '../../../pages/HealthSafety/Incidents/incidents-util';
import { renderCategoryNames } from '../../../lib/categories';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import { IncidentReview } from '../../../shared-state/HealthSafety/incidentReviews';
import SeaAuthorshipPDF from '../../Components/SeaAuthorshipPdf';
interface IncidentReportReviewPageProps {
    selectedItem: Incident,
    review: IncidentReview,
    auth: any,
    addedBy?: string,
    completedBy?: string,
    updatedBy?: string,
    signature?: any,
    images?: any[],
}

const IncidentReportReviewPage: React.FC<IncidentReportReviewPageProps> = ({selectedItem, review, auth, addedBy, completedBy, updatedBy, signature, images}) => {
    const { incidentCauses } = auth;

    return (
        <Page
            style={pdfStyles.page}
            orientation="portrait"
            size="A4" // 21cm x 29.7cm
            wrap={true}
        >
             
            {/* Header */}
            <View wrap={false} style={pdfStyles.header}>
                <Text style={pdfStyles.heading}>Review</Text>
            </View>

            {/* Authorship */}
            <SeaAuthorshipPDF item={selectedItem} addedBy={addedBy} completedBy={completedBy} updatedBy={updatedBy} />

            {/* Content */}
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Severity of Event</Text>
                    <Text>{formatValue(review?.severity)}</Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Notified to Authorities</Text>
                    <Text>
                        {formatValue(review?.notifiedAuthorities && authoritiesNotifiedForReview[review?.notifiedAuthorities])}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Causes of this incident / event</Text>
                    <Text>
                        {formatValue(renderCategoryNames(review?.causeIds, incidentCauses))}
                    </Text>
                </View>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Control Strategies</Text>
                    <Text>
                        {formatValue(
                            review?.strategies?.map((strategy: string) => {
                                return controlStrategies[strategy];
                            }).join(', ')
                        )}
                    </Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Added to risk assesments?</Text>
                    <Text>
                        {formatValue(review?.addedToRiskRegister && addedToRiskRegister[review?.addedToRiskRegister])}
                    </Text>
                </View>
                    <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>External investigation required?</Text>
                    <Text>
                         {formatValue(review?.externalRequired && yesNoNa[review?.externalRequired])}
                    </Text>
                </View>
            </View>
            {(selectedItem?.type === 'injury') && (
                <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>Injury Conclusion</Text>
                        <Text>
                             {formatValue(
                                review?.injuryConclusions?.map((conclusion: string) => {
                                    return injuryConclusions[conclusion];
                                }).join(', ')
                            )}
                        </Text>
                    </View>
                        <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>Time Lost to Injury</Text>
                        <Text>
                             {formatValue(review?.lostTime && lostTimes[review?.lostTime])}
                        </Text>
                    </View>
                </View>
            )}

            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Corrective actions or tasks required</Text>
                    <Text>
                         {formatValue(review?.prevention)}
                    </Text>
                </View>
            </View>

            <View wrap={false} style={pdfStyles.row}>
                <View style={pdfStyles.column2}>
                    <Text style={pdfStyles.label}>Analysis of Review</Text>
                    <Text>
                        {formatValue(review?.analysis)}
                    </Text>
                </View>
            </View>
            
            <View style={pdfStyles.greyLine}></View>
            <View wrap={false} style={pdfStyles.rowNoSpace}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.label}>Images / Documents</Text>
                </View>
            </View>
            <View wrap={false} style={pdfStyles.row}>
                <View style={[pdfStyles.column1, { flexDirection: 'column' }]}>
                    {images?.length ? (
                        images.map((image, index) => image.element)
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>

            {/* Signature */}
            <View style={pdfStyles.greyLine} />
            <View wrap={false} style={pdfStyles.rowNoSpace}>
                <View style={pdfStyles.column1}>
                    <Text style={pdfStyles.label}>Signature</Text>
                </View>
            </View>
                {review.signature ? (
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View
                        style={{
                            padding: '0 0 0 0',
                            width: pixelsToCm(352)+'cm',
                            height: pixelsToCm(202)+'cm'
                        }}
                        wrap={false}
                    >
                        <Image
                            src={signature}
                            style={{
                                width: pixelsToCm(350)+'cm',
                                height: pixelsToCm(200)+'cm'
                            }}
                        />
                    </View>
                </View>
            ) : (
                <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column1}>
                        <Text>-</Text>
                    </View>
                </View>
            )}
        </Page>
    );
};

export default IncidentReportReviewPage;
