import React, {useState} from 'react';
import {IonRadioGroup, IonRadio, IonSelectOption} from '@ionic/react';
import { PermissionRole, permissionLevels, permissions, permissionsList } from '../../lib/permissions';
import { regions } from '../../lib/util';
import { sharedState } from '../../shared-state/shared-state';
import { UserRole } from '../../shared-state/Crew/userRoles';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaUserPermissions.css';

interface SeaUserPermissionsProps {
    error?: string
    data?: UserRole,
    setData?: (data: UserRole) => void,
    setFieldData?: (field: keyof UserRole, value: any) => void,
    currentTab?: string,
    disable?: boolean
}

const SeaUserPermissions: React.FC<SeaUserPermissionsProps> = ({error, data, setData, setFieldData, disable, currentTab }) => {
    const licenseeSettings = sharedState.licenseeSettings.use();
    const userPermissions = sharedState.userPermissions.use();
    const [isCrewParticularsValueHigh, setIsCrewParticularsValueHigh] = useState(false);

    const handleChange = (e: any, permissionRole: PermissionRole) => {
        setIsCrewParticularsValueHigh(false);
        if (permissionRole === 'crewParticulars' && (e.detail.value === 4 || e.detail.value === 6)) {
            setIsCrewParticularsValueHigh(true);
        };
        if (setData) {
            const newValue = e.detail.value;
            const newState = { ...data, [permissionRole]: newValue } as UserRole;
            setData(newState);
        }
        if (setFieldData && e.detail.value !== data?.[permissionRole]) {
            setFieldData(permissionRole, e.detail.value);
        }
    };
    
    const isDisabled = (permissionRole: PermissionRole, level: number) => {
        if (!disable) return false;
        if (!userPermissions || !userPermissions[permissionRole] || userPermissions[permissionRole] < level) {
            return true;
        }
        return disable;
    }

    return (
        <div className="sea-user-permissions">
            <div className="row">
                <div className="sea-label">User permissions</div>
                <div className="sea-label">Hidden</div>
                <div className="sea-label">View Only</div>
                <div className="sea-label">View, Create &amp; Edit</div>
                <div className="sea-label">View, Create, Edit &amp; Archive</div>
                <div className="drop-down"></div>
            </div>

            {data && permissionsList?.map((permissionRole: PermissionRole, index: number) => {
                return (
                    <IonRadioGroup 
                        className="row radio-group"
                        value={data[permissionRole]} 
                        onIonChange={e => handleChange(e, permissionRole)}
                        allowEmptySelection={false}
                        key={currentTab ? permissionRole + currentTab : permissionRole}
                    >
                        {permissions[permissionRole].help ? (
                            <div
                                className="columns"
                                style={{ alignItems: 'center', cursor: 'pointer' }}
                                onClick={(e) => openContextualHelp(e, permissions[permissionRole].help!)}
                            >
                                <div>
                                    {permissions[permissionRole].name}
                                </div>
                                {/* <div style={{ padding: '2px 0px 0px 4px', color: '#ccc' }}> */}
                                <div className="sea-help" style={{ top: '0px', left: '0px', padding: '4px 0px 0px 4px' }}>
                                    <SeaIcon icon="help"/>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {(permissionRole === 'companyPlan') ? (
                                    regions[licenseeSettings?.region as string]?.companyPlan
                                ) : (
                                    permissions[permissionRole].name
                                )}
                            </div>
                        )}
                        <div>
                            <IonRadio 
                                className="sea-user-permissions-radio"
                                value={permissionLevels.NONE} 
                                color="primary" 
                                mode="md"
                            />
                        </div>
                        <div>
                            <IonRadio 
                                className="sea-user-permissions-radio"
                                value={permissionLevels.VIEW} 
                                color="primary" 
                                mode="md"
                                disabled={isDisabled(permissionRole, permissionLevels.VIEW)}
                            />
                        </div>
                        <div>
                            <IonRadio 
                                className="sea-user-permissions-radio"
                                value={permissionLevels.EDIT} 
                                color="primary" 
                                mode="md"
                                disabled={isDisabled(permissionRole, permissionLevels.EDIT)}
                            />
                            {(permissionRole === 'crewParticulars' && isCrewParticularsValueHigh) && <div className="warning-space"></div> }
                        </div>
                        <div>
                            <IonRadio 
                                className="sea-user-permissions-radio"
                                value={permissionLevels.FULL} 
                                color="primary" 
                                mode="md"
                                disabled={isDisabled(permissionRole, permissionLevels.FULL)}
                            />
                        </div>
                        <div className="drop-down">
                            <SeaSelect
                                name={`dd${permissionRole}`}
                                value={data[permissionRole]}
                                onchange={(e) => {
                                    handleChange(e, permissionRole);
                                }}
                            >
                                <IonSelectOption value={permissionLevels.NONE}>Hidden</IonSelectOption>
                                <IonSelectOption value={permissionLevels.VIEW}>View Only</IonSelectOption>
                                <IonSelectOption value={permissionLevels.EDIT}>View, Create &amp; Edit</IonSelectOption>
                                <IonSelectOption value={permissionLevels.FULL}>View, Create, Edit &amp; Archive</IonSelectOption>
                            </SeaSelect>
                        </div>
                        {(permissionRole === 'crewParticulars' && isCrewParticularsValueHigh) && <div className="warning">Warning - This will allow this person to edit other user's accounts (disable logins, change permissions etc.)</div>}
                    </IonRadioGroup>
                )
            })}

            <SeaInputError>{error}</SeaInputError>
        </div>
    );
};

export default SeaUserPermissions;
