import React, { useState, useMemo } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption, IonSpinner } from '@ionic/react';
import { Filesystem } from '@capacitor/filesystem';
import { filesDirectory, filesFolder, cachedFiles, setSimultaneousLoads } from '../../../lib/fileSync';
import { useFormik } from 'formik';
import { toInt } from '../../../lib/util';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../components/SeaSignatureImage/SeaSignatureImage';
import './FilesTest.css';

interface FormData {
    fileType: string;
    maxCount: string;
    minSize: string;
    maxSize: string;
    simultaneousLoads: string;
    order: string;
}

interface FileLists {
    images: TestFile[];
    thumbnails: TestFile[];
    signatures: TestFile[];
    files: TestFile[];
    all: TestFile[];
}

interface TestFile {
    files: string[];
    fileType: string;
    mode: 'full' | 'medium' | 'signature' | 'tiny';
    key: string;
    size: number;
}

interface Stats {
    images: StatDetails;
    thumbnails: StatDetails;
    signatures: StatDetails;
    files: StatDetails;
    all: StatDetails;
    testing?: StatDetails;
}

interface StatDetails {
    min: string;
    lq: string;
    lowerQuartile: string;
    median: string;
    upperQuartile: string;
    max: string;
    sum: string;
    average: string;
    count: number;
}

interface FilesTestProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const FilesTest: React.FC<FilesTestProps> = ({showModal, setShowModal}) => {
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState<Stats>();
    const [fileLists, setFileLists] = useState<FileLists>();
    const [testFiles, setTestFiles] = useState<TestFile[]>();

    const initialValues = useMemo(() => {
        return {
            fileType: 'images',
            maxCount: '1',
            minSize: '0',
            maxSize: '100000000',
            simultaneousLoads: '1',
            order: 'desc'
        };
    }, []);

    const onOpened = () => {
        initFileStats();
        setValues(initialValues);
        setStats(undefined);
        setFileLists(undefined);
        setTestFiles(undefined);
    };

    const initFileStats = (): Promise<string> => {
        const filesToStat = [];
        const props = Object.getOwnPropertyNames(cachedFiles);
        for (var i = 0; i < props.length; i++) {
            filesToStat.push(cachedFiles[props[i]]);
        }
        const imageStats = [] as TestFile[];
        const thumbnailStats = [] as TestFile[];
        const signatureStats = [] as TestFile[];
        const fileStats = [] as TestFile[];
        const allStats = [] as TestFile[];

        const getFileStats = (files: string[]): Promise<any> => {
            if (files.length === 0) {
                return Promise.resolve();
            }
            const file = files.shift()!;
            return Filesystem.stat({
                path: `${filesFolder}/${file}`,
                directory: filesDirectory
            }).then((result) => {
                const state = file[0];
                const ext = file?.substring(file.lastIndexOf('.')+1);
                let id;
                if (state === '2') {
                    id = file.substring(2,22);
                } else {
                    id = file.substring(1,21);
                }
                const fileType = ((state === '2') ? file[1] : '');
                allStats.push({
                    files: [`${state}${id}.${ext}`],
                    fileType,
                    mode: (fileType === 'F') ? 'medium' : ((fileType === 'S') ? 'signature' : 'tiny'),
                    key: `${fileType}${id}`,
                    size: result.size
                });
                return getFileStats(files);
            });
        };

        return getFileStats(filesToStat).then(() => {
            allStats.sort((a, b) => {
                return a.size - b.size;
            });
            allStats.forEach((file) => {
                if (file.fileType === 'S') {
                    signatureStats.push(file);
                } else if (file.fileType === 'F') {
                    imageStats.push(file);
                } else if (file.fileType === 'T') {
                    thumbnailStats.push(file);
                } else {
                    fileStats.push(file);
                }
            });
            setStats({
                images: makeStats(imageStats),
                thumbnails: makeStats(thumbnailStats),
                signatures: makeStats(signatureStats),
                files: makeStats(fileStats),
                all: makeStats(allStats)
            });
            setFileLists({
                images: imageStats,
                thumbnails: thumbnailStats,
                signatures: signatureStats,
                files: fileStats,
                all: allStats
            });
            setLoading(false);
            return Promise.resolve('done');
        });
    };

    const makeStats = (files: TestFile[]): StatDetails => {
        let sum = 0;
        files.forEach((file) => {
            sum += file.size;
        });
        return {
            min: formatSize(files[0].size),
            lq: formatSize(files[Math.floor(files.length / 2)].size),
            lowerQuartile: formatSize(files[Math.floor(files.length / 4)].size),
            median: formatSize(files[Math.floor(files.length / 2)].size),
            upperQuartile: formatSize(files[Math.floor(3 * files.length / 4)].size),
            max: formatSize(files[files.length - 1].size),
            sum: formatSize(sum),
            average: formatSize(sum / files.length),
            count: files.length
        };
    };

    const formatSize = (size: number): string => {
        if (size < 10240) {
            return `${Math.round(size)}b`;
        } else {
            return `${Math.ceil(size / 1024)}K`;
        }
    };

    const {handleSubmit, handleChange, handleBlur, values, setValues } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            fileType: Yup.string(),
            maxCount: Yup.number(),
            minSize: Yup.number(),
            maxSize: Yup.number(),
            simultaneousLoads: Yup.number(),
            order: Yup.string(),
        }), onSubmit: (data: FormData) => {
            let files = fileLists?.[data.fileType as keyof FileLists] ?? [];
            if (data.order === 'desc') {
                files = [...files].reverse();
            }
            const _testFiles = [] as TestFile[];
            const maxCount = toInt(values.maxCount, 0);
            const minSize = parseFloat(values.minSize);
            const maxSize = parseFloat(values.maxSize);
            let count = 0;
            for (let i = 0; i < files.length; i++) {
                if (
                    files[i].size >= minSize &&
                    files[i].size <= maxSize
                ) {
                    _testFiles.push(files[i]);
                    count++;
                    if (count >= maxCount) {
                        break;
                    }
                }
            }
            setStats((current) => {
                if (!current) {
                    return undefined;
                }
                return {
                    ...current,
                    testing: makeStats(_testFiles)
                };
            });
            setSimultaneousLoads(toInt(values.simultaneousLoads, 0));
            setTestFiles(_testFiles);
        }
    });

    return (
        <SeaModal
            title="Files Test"
            showModal={showModal}
            setShowModal={setShowModal}
            size="extra-wide"
            onOpened={onOpened}
        >
            {loading &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            }
            {stats && <>
                <table className="files-stats-table"><tbody>
                    <tr>
                        <td></td>
                        <td>Count</td>
                        <td>Min</td>
                        <td>LQ</td>
                        <td>Median</td>
                        <td>UQ</td>
                        <td>Max</td>
                        <td>Average</td>
                        <td>Sum</td>
                    </tr>
                    <tr>
                        <td>Images</td>
                        <td>{stats.images.count}</td>
                        <td>{stats.images.min}</td>
                        <td>{stats.images.lowerQuartile}</td>
                        <td>{stats.images.median}</td>
                        <td>{stats.images.upperQuartile}</td>
                        <td>{stats.images.max}</td>
                        <td>{stats.images.average}</td>
                        <td>{stats.images.sum}</td>
                    </tr>
                    <tr>
                        <td>Thumbnails</td>
                        <td>{stats.thumbnails.count}</td>
                        <td>{stats.thumbnails.min}</td>
                        <td>{stats.thumbnails.lowerQuartile}</td>
                        <td>{stats.thumbnails.median}</td>
                        <td>{stats.thumbnails.upperQuartile}</td>
                        <td>{stats.thumbnails.max}</td>
                        <td>{stats.thumbnails.average}</td>
                        <td>{stats.thumbnails.sum}</td>
                    </tr>
                    <tr>
                        <td>Signatures</td>
                        <td>{stats.signatures.count}</td>
                        <td>{stats.signatures.min}</td>
                        <td>{stats.signatures.lowerQuartile}</td>
                        <td>{stats.signatures.median}</td>
                        <td>{stats.signatures.upperQuartile}</td>
                        <td>{stats.signatures.max}</td>
                        <td>{stats.signatures.average}</td>
                        <td>{stats.signatures.sum}</td>
                    </tr>
                    <tr>
                        <td>Files</td>
                        <td>{stats.files.count}</td>
                        <td>{stats.files.min}</td>
                        <td>{stats.files.lowerQuartile}</td>
                        <td>{stats.files.median}</td>
                        <td>{stats.files.upperQuartile}</td>
                        <td>{stats.files.max}</td>
                        <td>{stats.files.average}</td>
                        <td>{stats.files.sum}</td>
                    </tr>
                    <tr>
                        <td>All files</td>
                        <td>{stats.all.count}</td>
                        <td>{stats.all.min}</td>
                        <td>{stats.all.lowerQuartile}</td>
                        <td>{stats.all.median}</td>
                        <td>{stats.all.upperQuartile}</td>
                        <td>{stats.all.max}</td>
                        <td>{stats.all.average}</td>
                        <td>{stats.all.sum}</td>
                    </tr>
                    {stats.testing &&
                        <tr style={{ backgroundColor: '#ffffcc' }}>
                            <td>Testing</td>
                            <td>{stats.testing.count}</td>
                            <td>{stats.testing.min}</td>
                            <td>{stats.testing.lowerQuartile}</td>
                            <td>{stats.testing.median}</td>
                            <td>{stats.testing.upperQuartile}</td>
                            <td>{stats.testing.max}</td>
                            <td>{stats.testing.average}</td>
                            <td>{stats.testing.sum}</td>
                        </tr>
                    }
                </tbody></table>
                <div style={{ height: '30px' }}></div>
                <form onSubmit={handleSubmit}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="4">
                                <SeaSelect
                                    label="File Type"
                                    name="fileType"
                                    value={values.fileType}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                >
                                    <IonSelectOption value="images">Images</IonSelectOption>
                                    <IonSelectOption value="thumbnails">Thumbnails</IonSelectOption>
                                    <IonSelectOption value="signatures">Signatures</IonSelectOption>
                                    <IonSelectOption value="files">Files</IonSelectOption>
                                    <IonSelectOption value="all">All</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Max Count"
                                    name="maxCount"
                                    value={values.maxCount}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="number"
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Min Size"
                                    name="minSize"
                                    value={values.minSize}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="number"
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Max size"
                                    name="maxSize"
                                    value={values.maxSize}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="number"
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Simultaneous"
                                    name="simultaneousLoads"
                                    value={values.simultaneousLoads}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    type="number"
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaSelect
                                    label="Order"
                                    name="order"
                                    value={values.order}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                >
                                    <IonSelectOption value="asc">Smallest First</IonSelectOption>
                                    <IonSelectOption value="desc">Largest First</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div style={{ height: '20px' }}></div>
                    <SeaButton zone="white" type="submit">Run Test</SeaButton>
                </form>
            </>}
            <div className="test-gallery">
                {testFiles && testFiles.length > 0 && testFiles.map((testFile, index) => {
                    return (
                        <div key={testFile.key} className={testFile.mode}>
                            {testFile.fileType === 'S' ?
                                (
                                    <SeaSignatureImage file={testFile.files[0]}/>
                                ):(
                                    <SeaFileImage
                                        files={testFile.files}
                                        size={testFile.mode as 'tiny' | 'medium' | 'full'}
                                    />
                                )
                            }
                            <div>
                                #{(index+1)} = {formatSize(testFile.size)}
                                <br/>
                                {testFile.files}
                            </div>
                        </div>
                    );
                })}
            </div>
        </SeaModal>
    );
};

export default FilesTest;
