import React, { useEffect, useMemo, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { isTouchDevice } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { Line, Rect, Text } from '@react-pdf/renderer';
import reporting from '../../../lib/reporting';
import SvgText from '../SvgText/SvgText';
import SeaGraph from '../SeaGraph/SeaGraph';
import SvgHoverInfo, { HoverInfo } from '../SvgHoverInfo/SvgHoverInfo';
import './SeaHorizontalBarGraph.css';

export type GraphData = { name: string, value?: number, values?: number[] }
export type GridType = {
    left: number
    right: number
    top: number
    bottom: number
    w: number
    h: number
    yBarHeight: number
    yBarSpacing: number
    yStart: number
}

interface SeaHorizontalBarGraphProps {
    title?: string,
    subTitle?: string,
    mode: 'dashboard' | 'modal' | 'pdf',
    visible?: boolean,
    n?: number,
    data?: GraphData[],
    sortData?: boolean,
    units?: string,
    yLabelWidth?: number,
    onClick?: (e: React.MouseEvent) => void,
    renderValue?: (value: number) => string,
    renderPercent?: (value: number) => string,
    colourPalette?: string[],
    hashNamesForColours?: boolean,
    forAdmin?: boolean
}

const SeaHorizontalBarGraph: React.FC<SeaHorizontalBarGraphProps> = ({
    title,
    subTitle,
    mode,
    visible = true,
    n = 0,
    data,
    sortData,
    units,
    yLabelWidth = (100 + 6),
    onClick,
    renderValue = (value: number) => {
        return Math.round(value).toLocaleString();
    },
    renderPercent = (ratio: number) => {
        if (isNaN(ratio) || ratio <= 0) {
            return '0%';
        }
        if (ratio < 0.01) {
            return '<1%';
        }
        return `${Math.round(ratio * 100)}%`;
    },
    colourPalette = reporting.colours.default,
    hashNamesForColours,
    forAdmin
}) => {
    const modalSpace = sharedState.modalSpace.use();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isReady, setIsReady] = useState(false); // Has completed animating and is ready to be interacted with
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | undefined>();

    useEffect(() => {
        let isActive = true;
        setIsReady(false);
        if (visible) {
            setTimeout(() => {
                if (!isActive) return;
                setIsReady(true);
            }, (n * 100) + 500);
        }
        return () => { isActive = false; };
    }, [visible, n]);

    // const colourTest = useMemo(() => {
    //     const n = 1000;
    //     const tData = [];
    //     for (let i = 0; i < n; i++) {
    //         tData.push({
    //             name: ''+i,
    //             value: 10
    //         });
    //     }
    //     const testMap = reporting.makeColourMapUsingNames(tData, reporting.colours.default);
    //     const divs = [];
    //     for (let i = 0; i < n; i++) {
    //         divs.push(
    //             <div style={{
    //                 display: 'inline-block',
    //                 width: '80px',
    //                 height: '40px',
    //                 backgroundColor: `#${testMap[i]}`,
    //                 color: getBasedOnBackgroundColour(testMap[i]),
    //                 padding: '4px',
    //                 fontWeight: '500',
    //                 fontSize: '12px'
    //             }}>
    //                 {testMap[i]}
    //             </div>
    //         );
    //     }
    //     return divs;
    // }, []);

    const stats = useMemo(() => {
        let stats = {
            total: 0,
            maxValue: 0
        };
        if (data) {
            data.forEach((row) => {
                if (row.value) {
                    stats.total += row.value;
                    if (row.value > stats.maxValue) {
                        stats.maxValue = row.value;
                    }
                }
            });
        }
        return stats;
    }, [data]);

    const sortedData = useMemo(() => {
        if (data) {
            const sorted = [];
            for (let i = 0; i < data.length; i++) {
                //if (data[i].value > 0) {
                    sorted.push({
                        ...data[i],
                        index: i
                    });
                //}
            }
            if (sortData) {
                sorted.sort((a, b) => {
                    return (b.value || 0) - (a.value || 0);
                });
            }
            return sorted;
        }
        return undefined;
    }, [data, sortData]);

    const colourMap = useMemo(() => {
        if (data) {
            if (hashNamesForColours) {
                const map = reporting.makeColourMapUsingNames(data, colourPalette);
                return map;
            }
            return reporting.makeColourMapUsingIndexes(data, colourPalette);
        }
        return {} as {
            [key: string]: string
        };
    }, [data, colourPalette, hashNamesForColours]);

    const xAxis = useMemo(() => {
        return reporting.calculateAxis(stats.maxValue);
    }, [stats]);

    const graph = useMemo(() => {
        if (sortedData) {
            const _graph = reporting.getConfig(forAdmin ? 'dashboard' : mode, modalSpace, colourPalette);
            if (mode !== 'dashboard') {
                //_graph.height = 400;
                _graph.height += Math.max(0, sortedData.length - 10) * 30;
                //_graph.divStyle.height = `${_graph.height}px`;
            }
            if (forAdmin) {
                _graph.showHeader = true;
                //_graph.
            }
            return _graph;
        }
        return undefined;
    }, [mode, modalSpace, sortedData, colourPalette, forAdmin]);

    const grid: Partial<GridType> = graph ? {
        left: graph.padding + yLabelWidth,
        right: graph.width - graph.padding - 10,
        top: graph.headerHeight + reporting.graphPadding.top,
        bottom: graph.height - reporting.graphPadding.bottom - graph.padding - (units ? reporting.xAxisLabelSpace : 0)
    } : {};
    grid.w = grid.right && grid.left ? grid.right - grid.left : 0;
    grid.h = grid.bottom && grid.top ? grid.bottom - grid.top : 0;
    grid.yBarHeight = 20;
    grid.yBarSpacing = 10;
    grid.yStart = grid.top ?? 0;

    let displayRows = 0;
    let overflowRows = 0;
    if (sortedData?.length) {
        if (mode === 'dashboard') {
            displayRows = Math.min(units ? 12 : 14, sortedData.length); // Cap dashboard rows to a maximum
            overflowRows = sortedData.length - displayRows;
        } else {
            displayRows = sortedData.length;
        }
        // Stretch bars to make use of space
        grid.yBarHeight = Math.min(95, (grid.h / displayRows) * (2/3));
        grid.yBarSpacing = (grid.h / displayRows) * (1/3);

        // Centre bars vertically
        const totalHeight = displayRows * grid.yBarHeight + ((displayRows - 1) * grid.yBarSpacing);
        grid.yStart += (grid.h - totalHeight) / 2;
    }

    if (data === undefined || sortedData === undefined || xAxis === undefined ) {
        return reporting.graphSpinner;
    }

    if (mode === 'pdf') {
        return (
            <SeaGraph
                mode={mode}
                n={n}
                colourMode={graph?.colourMode}
                width={graph?.width}
                height={(graph?.height ?? 0) + 40}
            >
                {sortedData.map((item, index: number) => {
                    const y = (grid.yStart || 0) + index * ((grid.yBarHeight || 0) + (grid.yBarSpacing || 0));
                    return (
                        <SvgText
                            key={item.name}
                            text={item.name}
                            x={(grid.left || 0) - yLabelWidth}
                            y={y + (grid.yBarHeight || 0) / 2 + 5}
                            maxWidth={yLabelWidth - 4}
                            fill={graph?.axisLabelColour}
                            forPdf={true}
                            style={reporting.pdf.label}
                        />
                    );
                })}
                {xAxis.values.map((value: number, index: number) => {
                    const x = (grid.left || 0) + ((index / xAxis.n) * (grid.w || 0)) - 0.5; // (the -0.5 allows pixel perfect rendering)
                    return (
                        <React.Fragment key={`${index}_${value}`}>
                            <Line x1={x} y1={grid.top || 0} x2={x} y2={grid.bottom || 0} stroke={graph?.lineColour} />
                            <Text
                                x={x}
                                y={(grid.bottom || 0) + 20}
                                fill={graph?.axisLabelColour}
                                style={reporting.pdf.xValue}
                            >
                                {value}
                            </Text>
                        </React.Fragment>
                    );
                })}
                {sortedData.map((item, index: number) => {
                    const y = (grid.yStart || 0) + index * ((grid.yBarHeight || 0) + (grid.yBarSpacing || 0));
                    const w = ((item.value || 0) / xAxis.range) * (grid.w || 0);
                    return (
                        // Bar with with
                        <React.Fragment key={item.name}>
                            <Rect
                                x={(grid.left || 0)}
                                y={y}
                                width={w}
                                height={(grid.yBarHeight || 0)}
                                fill={graph?.renderColour(item.index, colourMap)}
                            />
                            {reporting.allowBarValue(item.value || 0, xAxis.range) &&
                                <Text
                                    x={(grid.left || 0) + w - 8}
                                    y={y + ((grid.yBarHeight || 0) / 2) + 4}
                                    fill={graph?.renderTextColour(item.index, colourMap)}
                                    style={reporting.pdf.barValue}
                                >
                                    {renderValue(item.value || 0)}
                                </Text>
                            }
                        </React.Fragment>
                    );
                })}
                {units &&
                    <SvgText
                        text={units}
                        x={(grid.left || 0) + grid.w / 2}
                        y={(graph?.height ?? 0) - (graph?.padding ?? 0)  + 8 - 6}
                        fill={graph?.axisLabelColour}
                        forPdf={true}
                        style={reporting.pdf.xAxisLabel}
                    />
                }
            </SeaGraph>
        );
    }

    return (<>
        {/* <div>
            {colourTest}
        </div> */}
        <SeaGraph
            title={title}
            subTitle={subTitle}
            mode={mode}
            n={n}
            divStyle={graph?.divStyle}
            onClick={onClick}
            onMouseOut={() => {
                setActiveIndex(-1);
                setHoverInfo(undefined);
            }}
            colourMode={graph?.colourMode}
            width={graph?.width}
            height={graph?.height}
            showHeader={graph?.showHeader}
            padding={graph?.padding}
        >
            <g className="grid">
                {xAxis.values.map((value: number, index: number) => {
                    const x = (grid.left || 0) + ((index / xAxis.n) * (grid.w || 0)) - 0.5; // (the -0.5 allows pixel perfect rendering)
                    return (
                        <React.Fragment key={`${index}_${value}`}>
                            <line x1={x} y1={grid.top} x2={x} y2={grid.bottom} stroke={graph?.lineColour} />
                            <text
                                className="x-axis-value"
                                x={x}
                                y={(grid.bottom || 0) + 20}
                                fill={graph?.axisLabelColour}
                            >
                                {value}
                            </text>
                        </React.Fragment>
                    );
                })}
            </g>
            <g
                style={{
                    '--x-axis': `${grid.left}px`,
                    '--order-delay': `${Math.round((5 / sortedData.length) * 40)}ms`
                } as React.CSSProperties}
            >
                {sortedData.map((item, index: number) => {
                    if (index >= displayRows) {
                        return undefined;
                    }
                    const y = (grid.yStart || 0) + index * ((grid.yBarHeight || 0) + (grid.yBarSpacing || 0));
                    const w = ((item.value || 0) / xAxis.range) * (grid.w || 0);
                    const onHover = () => {
                        setActiveIndex(index);
                        setHoverInfo({
                            text: (
                                `${renderValue(sortedData[index].value || 0)} / ${renderValue(stats.total)} (${renderPercent((sortedData[index].value || 0) / stats.total)})`
                            ),
                            x: (grid.left || 0) + w / 2,
                            y: y - 4
                        });
                    };
                    return (
                        <g
                            key={item.name}
                            className={`bar-graph ${(activeIndex === index) ? ' active' : ''}`}
                            style={{
                                '--order': index
                            } as React.CSSProperties}
                        >
                            <SvgText
                                text={item.name}
                                x={(grid.left || 0) - yLabelWidth}
                                y={y + (grid.yBarHeight || 0) / 2 + 5}
                                maxWidth={yLabelWidth - 4}
                                fill={graph?.axisLabelColour}
                                className="y-label"
                                setHoverInfo={setHoverInfo}
                            />
                            <g className="in">
                                <rect
                                    className="bar"
                                    x={grid.left}
                                    y={y}
                                    width={w}
                                    height={grid.yBarHeight}
                                    fill={graph?.renderColour(item.index, colourMap)}
                                    //fillOpacity={0.5}
                                />
                                {reporting.allowBarValue(item.value || 0, xAxis.range) &&
                                    <text
                                        className="bar-value"
                                        x={(grid.left || 0) + w - 8}
                                        y={y + ((grid.yBarHeight || 0) / 2) + 4}
                                        fill={graph?.renderTextColour(item.index, colourMap)}
                                    >
                                        {renderValue(item.value || 0)}
                                    </text>
                                }
                                <rect
                                    x={grid.left}
                                    y={y}
                                    width={w}
                                    height={grid.yBarHeight}
                                    fillOpacity={0}
                                    onMouseOver={(e) => {
                                        onHover();
                                    }}
                                    onClick={(e) => {
                                        if (isTouchDevice) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onHover();
                                        }
                                    }}
                                    onMouseOut={(e) => {
                                        setActiveIndex(-1);
                                        setHoverInfo(undefined);
                                    }}
                                />
                            </g>
                        </g>
                    );
                })}
            </g>
            {units &&
                <SvgText
                    text={units}
                    x={(grid.left || 0) + grid.w / 2}
                    y={(graph?.height ?? 0) - (graph?.padding ?? 0)  + 8 - 6}
                    fill={graph?.axisLabelColour}
                    className="label center x-axis-label"
                />
            }
            {overflowRows > 0 &&
                <SvgText
                    text={`(+${overflowRows} more)`}
                    x={(grid.left || 0) - yLabelWidth}
                    y={(grid.bottom || 0) + 16}
                    maxWidth={yLabelWidth - 4}
                    fill={graph?.axisLabelColour}
                    fillOpacity={0.5}
                    className="y-label plus-more"
                    hoverText={`${isPlatform('desktop') ? 'Click' : 'Tap'} to view ALL data...`}
                    setHoverInfo={setHoverInfo}
                />
            }
            {hoverInfo && isReady &&
                <SvgHoverInfo
                    graphWidth={graph?.width ?? 0}
                    graphHeight={graph?.height ?? 0}
                    x={hoverInfo.x}
                    y={hoverInfo.y}
                >
                    <SvgText
                        text={hoverInfo.text}
                        fill="white"
                        className="hover-text"
                    />
                </SvgHoverInfo>
            }
        </SeaGraph>
    </>);
};

export default SeaHorizontalBarGraph;
