import React, { ReactNode, useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaFooter from '../../components/SeaFooter/SeaFooter';
import SeaScrollableArea from '../../components/SeaScrollableArea/SeaScrollableArea';
import './SuperAdminPageLayout.css';

interface SuperAdminPageLayoutProps {
    children: ReactNode
}

const SuperAdminPageLayout: React.FC<SuperAdminPageLayoutProps> = ({ children }) => {

    useEffect(() => {
        sharedState.layoutMode.set('superAdmin');
    }, []);


    return (
        <div className="super-admin-layout">
            <SeaScrollableArea
                scrollX={true}
                className="super-admin-bg"
            >
                <div className="super-admin-content-container">
                    <div className="super-admin-content grey-zone">
                        {children}
                    </div>
                    <SeaFooter/>
                </div>
            </SeaScrollableArea>
        </div>
    );

};

export default SuperAdminPageLayout;
