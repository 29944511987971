import React, { useState, useEffect, useMemo, Profiler } from "react";
import { logPageView } from "../../../lib/firebase";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { formatValue, formatDate, extractSearchTerms } from "../../../lib/util";
import { permissionLevels } from "../../../lib/permissions";
import { onProfilerRender } from "../../../lib/performance";
import { incidentTypes, renderIncidentState } from "./incidents-util";
import { sharedState } from '../../../shared-state/shared-state';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import SeaFileImage from "../../../components/SeaFileImage/SeaFileImage";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import SeaSelectVessel from "../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import EditIncidentsSettings from "../../../modals/HealthSafety/Incidents/EditIncidentsSettings/EditIncidentsSettings";
import EditIncident from "../../../modals/HealthSafety/Incidents/EditIncident/EditIncident";
import ViewIncident from "../../../modals/HealthSafety/Incidents/ViewIncident/ViewIncident";
import IncidentEventReportsPdf from "../../../pdfs/HealthSafety/Incidents/IncidentEventReportsPdf";
import "./Incidents.css";

interface IncidentsProps {
    visible: boolean;
}

const Incidents: React.FC<IncidentsProps> = ({ visible }) => {
    const incidents = sharedState.incidents.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    sharedState.incidentCategories.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.incidentCauses.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.injuryTypes.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.injuryLocations.use(visible ? 51 : 0); // Prepare for modals to access
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [filterVesselId, setFilterVesselId] = useState<string>();
    const [searchText, setSearchText] = useState("");
    const [showSettings, setShowSettings] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Incident>();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    //const [medicalReports, setMedicalReports] = useState<MedicalReport>();

    useEffect(() => {
        if (visible) {
            logPageView("HealthSafety/Incidents");
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [filterVesselId, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && incidents?.byId[selectedItem.id]) {
            setSelectedItem(incidents.byId[selectedItem.id]);
        }
    }, [incidents, selectedItem?.id]);

    const onAddNewItem = () => {
        setSelectedItem(undefined);
        setShowEditItemModal(true);
    };
    const onViewItem = (item: Incident) => {
        console.log(`Viewing incident ${item?.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item);
    };
    const onEditSettings = () => {
        setShowSettings(true);
    };

    const onGeneratePdf = () => {
        setGeneratingPdf(true);
    };

    const isUsingFilter = useMemo(() => {
        return (filterVesselId && filterVesselId !== "all") || searchText;
    }, [filterVesselId, searchText]);

    const filteredIncidents = useMemo(() => {
        let _incidents: Incident[] = [];
        let _filteredIncidents: Incident[] = [];
        const terms = extractSearchTerms(searchText, true);
        if (filterVesselId && filterVesselId !== "all") {
            _incidents = incidents?.byVesselId[filterVesselId] || [];
        } else {
            _incidents = incidents?.all || [];
        }
        if (_incidents) {
            for (let i = 0; i < _incidents.length; i++) {
                const incident = _incidents[i];
                if (terms.length > 0) {
                    let isMatch = true;
                    for (let j = 0; j < terms.length; j++) {
                        if (incident.searchText?.indexOf(terms[j]) === -1) {
                            isMatch = false;
                            break;
                        }
                    }
                    if (!isMatch) {
                        continue;
                    }
                }
                _filteredIncidents.push(incident);
            }
        }
        return _filteredIncidents;
    }, [incidents, filterVesselId, searchText]);

    // TODO: stats to be upgraded (V2)
    // const stats = useMemo(() => {
    //     const o = {
    //         incident: 0,
    //         accident: 0,
    //         medical: 0,
    //         nearMiss: 0
    //     };
    //     if (filteredMedicalReports) {
    //         filteredMedicalReports.forEach((report) => {
    //             if (report.type) {
    //                 o[report.type]++;
    //             }
    //         });
    //     }
    //     return o;
    // }, [filteredMedicalReports]);

    return (
        <>
            <div className="incidents page-head">
                <div>
                    <h2>Incident / Event Reports</h2>
                </div>
                <div className="actions">
                    <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                        <SeaIcon slot="start" icon="add" />
                        Report Incident / Event
                    </SeaButton>

                    <SeaButton
                        onClick={(e) => onGeneratePdf()}
                        zone="grey"
                        shape="circle"
                    >
                        <SeaIcon slot="icon-only" icon="pdf" />
                    </SeaButton>
                    <RequirePermissions
                        role="incidentAccidentMedicalRegister"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                    {/* <div className="spacer"></div>
                    <SeaButton onClick={(e) => onGeneratePdf()} zone="grey" shape="circle"><SeaIcon slot="icon-only" icon="pdf"/></SeaButton> */}
                </div>
            </div>

            {/* Filters */}
            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                <div>
                    <SeaSelectVessel
                        filterVesselId={filterVesselId}
                        setFilterVesselId={setFilterVesselId}
                    />
                </div>
            </div>

            <SeaNoData
                dataName="incident / event reports"
                isLoading={!incidents}
                hasNoData={filteredIncidents && filteredIncidents.length === 0}
                isUsingFilter={isUsingFilter}
            />

            {/* TODO! upgrade stats (v2)
            {filteredIncidents && filteredIncidents.length > 0 &&
                <div className="sea-stat-container" style={{ margin: '8px 0px -8px 0px' }}>
                    <div className="sea-stat">
                        <div>{stats.incident}</div>
                        Incidents
                    </div>
                    <div className="sea-stat">
                        <div>{stats.accident}</div>
                        Accidents
                    </div>
                    <div className="sea-stat">
                        <div>{stats.medical}</div>
                        Injuries
                    </div>
                    <div className="sea-stat">
                        <div>{stats.nearMiss}</div>
                        Near Misses
                    </div>
                </div>
            }
            */}

            <div
                className={`incidents has-thumbs has-status ${
                    isUsingFilter ? "hide-vessel" : "show-vessel"
                } ${filteredIncidents?.length > 0 ? "reveal" : "conceal"}`}
            >
                <div className="sea-row headings">
                    <div></div>
                    <div>Name</div>
                    <div>Vessel</div>
                    <div>Report #</div>
                    <div>Date</div>
                    <div>Type</div>
                    <div>Status</div>
                </div>
                <Profiler
                    id="healthSafety.incidents"
                    onRender={onProfilerRender}
                >
                    {mapArrayWithLimit(filteredIncidents, (incident: Incident) => {
                        return (
                            <div
                                key={incident.id}
                                className={`sea-card sea-row${
                                    incident?.state === "draft" ? " draft" : ""
                                }`}
                                onClick={(e) => onViewItem(incident)}
                            >
                                <div>
                                    <SeaFileImage
                                        files={incident.files}
                                        size="tiny"
                                    />
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(incident.name)}
                                </div>
                                <div className="truncate-2">
                                    {vessels?.byId[incident.vesselId]?.name}
                                </div>
                                <div className="truncate-2">
                                    {formatValue(incident.reportNum)}
                                </div>
                                <div className="truncate-2">
                                    {formatDate(incident.whenEvent)}
                                </div>
                                <div className="truncate-2">
                                    {formatValue(incidentTypes[incident.type])}
                                </div>
                                <div>{renderIncidentState(incident.state)}</div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <EditIncidentsSettings
                        showModal={showSettings}
                        setShowModal={setShowSettings}
                    />
                    <EditIncident
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        defaultVesselId={isUsingFilter ? filterVesselId : undefined}
                    />
                    {selectedItem && <ViewIncident
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    <IncidentEventReportsPdf
                        generatingPdf={generatingPdf}
                        setGeneratingPdf={setGeneratingPdf}
                        vesselId={filterVesselId}
                        incidents={filteredIncidents}
                    />
                </>
            }
        </>
    );
};

export default Incidents;
