import { isPlatform } from "@ionic/react";
import { initFileSync } from "../../lib/fileSync";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../lib/firebase";
import { sharedState } from "../shared-state";

export const initAuthenticationAndFileSync = (): Promise<() => void> => {
    let initFileSyncCalled = false;
    return Promise.resolve().then(() => {
        // If hybrid we want to initFileSync (set up our library of previously cached files) before starting the app.
        // If not hybrid (i.e. browser), we want to delay initFileSync until later because
        // * Desktop an take ages to initFileSync as well as slow onAuthStateChanged because there is a bottleneck accessing indexedDB.
        // * Desktop doesn't rely on the cache being ready as it's not designed for offline mode.
        if (isPlatform('hybrid')) {
            initFileSyncCalled = true;
            return initFileSync();
        }
        return Promise.resolve();
    }).then(() => {
        // Listen to authentication state changes (Firebase)
        let isActive = true;
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            sharedState.authUser.set(authUser);
            sharedState.user.clear();
            sharedState.superAdmin.clear();
            if (!authUser) {
                sharedState.userPending.set(false); // We can't get a user, so there's nothing left to wait for
            }
            if (!initFileSyncCalled) {
                // Desktop only needs to initFileSync AFTER authState has finished setting up
                initFileSyncCalled = true;
                setTimeout(() => {
                    if (!isActive) return;
                    initFileSync();
                }, 10 * 1000);
            }
        });
        return Promise.resolve(() => { // Return cleanup function
            isActive = false;
            unsubscribe();
        });
    });
}
