import React, { ReactNode, useContext, useState } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import './LexDropDown.css';

interface LexDropDownProps {
    children: ReactNode,
    title?: string,
    contentPrefix?: JSX.Element,
    defaultContent?: JSX.Element,
    value?: string,
    setValue?: (value: string) => void,
    onClick?: () => void,
    onChange?: (value: string) => void,
    disabled?: boolean,
    active?: boolean, // Used for toggles
    prefix?: JSX.Element,
    showSelectedContent?: boolean,
    maxWidth?: string
}

const LexDropDownContext = React.createContext({});

export function useLexDropDown(): any {
    return useContext(LexDropDownContext);
}

const LexDropDown: React.FC<LexDropDownProps> = ({
    children,
    title,
    contentPrefix,
    defaultContent = '',
    value,
    setValue,
    onClick,
    onChange,
    disabled,
    active,
    showSelectedContent = true,
    maxWidth
}) => {
    const hidePopover = () => {
        setPopover({ show: false, x: 0, y: 0 });
    };
    const { ref } = useOnClickOutside(() => {
        hidePopover();
    });
    const [popover, setPopover] = useState({ show: false, x: 0, y: 0 });
    const [selectedContent, setSelectedContent] = useState<ReactNode>(defaultContent);

    const shared = {
        selectedValue: value,
        setSelectedValue: setValue,
        setSelectedContent,
        onChange: (value: string) => {
            setPopover({ show: false, x: 0, y: 0 });
            if (onChange) {
                onChange(value);
            }
        },
        hidePopover
    };

    return (
        <LexDropDownContext.Provider value={shared}>
            <div ref={ref}>
                <button
                    className={`lex-button lex-drop-down-button no-select${disabled ? ' disabled' : ''}${active ? ' active' : ''}`}
                    disabled={disabled}
                    type="button"
                    title={title}
                    onClick={(e) => {
                        const rect = (ref.current as any).getBoundingClientRect();

                        let parentNode = (ref.current as any).parentNode;
                        while (parentNode) {
                            if (parentNode.className && parentNode.className.indexOf('ion-page') !== -1) {
                                break;
                            }
                            parentNode = parentNode.parentNode;
                        }
                        const pageRect = parentNode.getBoundingClientRect();

                        setPopover((current) => {
                            return {
                                show: !current.show,
                                x: Math.min(rect.x - pageRect.x, window.innerWidth - 160),
                                y: (rect.bottom) + 2
                            }
                        });
                    }}
                    aria-label={title}
                    style={{
                        maxWidth: maxWidth ? maxWidth : 'unset'
                    }}
                >
                    {contentPrefix}{showSelectedContent && selectedContent}
                    <i className="lex-dropdown-icon" style={{ backgroundImage: 'url(/assets/lex/chevron-down.svg)' }}/>
                </button>
                <div className="lex-dropdown" style={{
                    display: popover.show ? 'block' : 'none',
                    left: popover.x,
                    top: popover.y
                }}>
                    {children}
                </div>
            </div>
        </LexDropDownContext.Provider>
    );
};

export default LexDropDown;
