import React, { ReactNode } from 'react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { openContextualHelp } from '../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaLabel.css';

interface SeaLabelProps {
    children: ReactNode,
    zone?: 'blue' | 'white' | 'grey',
    onClick?: (e: React.MouseEvent) => void,
    help?: SeaHelp
}

const SeaLabel: React.FC<SeaLabelProps> = ({ children, zone, onClick, help }) => {

    const onClicked = (e: React.MouseEvent<Element, MouseEvent>) => {
        if (help) {
            openContextualHelp(e, help);
        }
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <div className={`sea-label${(onClick || help) ? ' clickable' : ''}${zone ? ` ${zone}-zone` : ''}`} onClick={onClicked}>
            {children}
            {help &&
                <div className="sea-help">
                   <SeaIcon icon="help"/>
                </div>
            }
        </div>
    );
};

export default SeaLabel;
