import React, { useState, useRef, useCallback } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc } from "firebase/firestore";
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { cleanupStringArray, hasArrayChanged } from '../../../../lib/util';
import { sharedState } from '../../../../shared-state/shared-state';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';

interface EditMaintenanceScheduleSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditMaintenanceScheduleSettings: React.FC<EditMaintenanceScheduleSettingsProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const [tab, setTab] = useState("systemItems");
    const [systemIds, setSystemIds] = useState<string[]>();
    const [systemNames, setSystemNames] = useState<string[]>();
    const [locationIds, setLocationIds] = useState<string[]>();
    const [locationNames, setLocationNames] = useState<string[]>();
    const [possibleMaintenanceTags, setPossibleMaintenanceTags] = useState<string[]>();
    const formRef = useRef<HTMLFormElement>(null);

    const onOpened = () => {
        if (vesselSystems) {
            setSystemIds(makeCategoryIdsForEditing(vesselSystems));
            setSystemNames(makeCategoryNamesForEditing(vesselSystems));
        }
        setPossibleMaintenanceTags(vessel?.possibleMaintenanceTags ? vessel.possibleMaintenanceTags : []);
        if (vesselLocations) {
            setLocationIds(makeCategoryIdsForEditing(vesselLocations));
            setLocationNames(makeCategoryNamesForEditing(vesselLocations));
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!vesselId) {
            throw new Error("No vesselId");
        }

        const action = traceAction('vessels', 'update') as Action;
        action.docId = vesselId;
        action.data = {
            possibleMaintenanceTags,
            vessel,
            systemIds,
            systemNames,
            locationIds,
            locationNames,
        };
        const batch = splittableBatch(firestore, 20 - 0);
        const vesselRef = vessel ?
            doc(firestore, 'vessels', vesselId as string)
            : doc(collection(firestore, 'vessels'));

        batch.set(
            doc(firestore, 'vessels', vesselId),
            {
                possibleMaintenanceTags: cleanupStringArray(possibleMaintenanceTags)?.sort(),
                updatedBy: userId,
                whenUpdated: action.whenAction
            },
            { merge: true }
        );

        // Save vesselSystems
        saveCategoryChanges(
            batch,
            systemIds,
            systemNames,
            undefined,
            vesselSystems,
            'vesselSystems',
            'vesselId',
            vesselRef.id,
            action
        );

        // Save vesselLocations
        saveCategoryChanges(
            batch,
            locationIds,
            locationNames,
            undefined,
            vesselLocations,
            'vesselLocations',
            'vesselId',
            vesselRef.id,
            action
        );

        action.save(`Update vessel (Maintenance schedule settings)`, batch);
        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error: any) => {
            action.reportError(error.message, error);
        });
        setShowModal(false);
        showToast(`Maintenance schedule settings updated`);
    };

    const isModalDirty = useCallback(() => {
        return (
            hasArrayChanged(possibleMaintenanceTags, vessel?.possibleMaintenanceTags) ||
            haveCategoriesChanged(locationIds, locationNames, vesselLocations) ||
            haveCategoriesChanged(systemIds, systemNames, vesselSystems)
        );
    }, [locationIds, locationNames, systemIds, systemNames,  possibleMaintenanceTags, vessel?.possibleMaintenanceTags, vesselLocations, vesselSystems]);

    return (
        <SeaModal
            title="Maintenance Schedule Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={() => {setTab("systemItems")} }
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="systemItems" mode="forms">Systems</SeaTab>
                    <SeaTab tab="locationItems" mode="forms">Locations</SeaTab>
                    <SeaTab tab="maintenanceTags" mode="forms">Maintenance Tags</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Maintenance Schedule Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="systemItems" selectedTab={tab}>
                    <SeaInputList
                        name="systemItems"
                        label="Systems"
                        maxWidth="500px"
                        ids={systemIds}
                        setIds={setSystemIds}
                        values={systemNames}
                        setValues={setSystemNames}
                        addNewText="Add system item"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="locationItems" selectedTab={tab}>
                    <SeaInputList
                        name="locationItems"
                        label="Locations"
                        maxWidth="500px"
                        ids={locationIds}
                        setIds={setLocationIds}
                        values={locationNames}
                        setValues={setLocationNames}
                        addNewText="Add location"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="maintenanceTags" selectedTab={tab}>
                    <SeaInputList
                        name="possibleMaintenanceTags"
                        label="Maintenance Tags"
                        maxWidth="500px"
                        values={possibleMaintenanceTags}
                        setValues={setPossibleMaintenanceTags}
                        addNewText="Add Tag"
                        confirmDelete={true}
                    />
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditMaintenanceScheduleSettings;
