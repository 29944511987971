import * as React from "react";

function SvgAnchor(props) {
  return (
    <svg
      width={12}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.76 7.37h.645c.43 2.01 2.656 3.124 4.746 3.124 2.07 0 4.297-1.113 4.727-3.125h.644a.226.226 0 00.156-.39l-1.308-1.31a.225.225 0 00-.332 0L8.729 6.98a.226.226 0 00.157.39h.703c-.41 1.074-1.66 1.7-2.832 1.856v-3.73h1.015a.226.226 0 00.235-.235v-.781a.237.237 0 00-.235-.235H6.757v-.098a1.855 1.855 0 001.25-1.757A1.843 1.843 0 006.17.514a1.851 1.851 0 00-1.895 1.855c0 .82.508 1.524 1.25 1.777v.098H4.511a.238.238 0 00-.235.235v.78c0 .137.098.235.235.235h1.015v3.73c-1.152-.156-2.422-.78-2.832-1.855h.703a.226.226 0 00.156-.39l-1.308-1.31a.225.225 0 00-.332 0L.604 6.98a.226.226 0 00.156.39zm5.391-5.626c.332 0 .625.293.625.625a.63.63 0 01-.625.625.617.617 0 01-.625-.625.63.63 0 01.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAnchor;
