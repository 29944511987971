import React, { useEffect, useState } from 'react';
import { formatDate, formatValue, formatEmailReminder, formatInterval, warnDays } from '../../../../lib/util';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../lib/permissions';
import { WriteBatch } from "firebase/firestore";
import { RichTextState, initialRichTextState, loadSfdoc } from '../../../../lib/richText';
import { SplittableBatch } from '../../../../lib/firebase';
import { SeaScrollable } from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import { sharedState } from '../../../../shared-state/shared-state';
import { CompanyDocument } from '../../../../shared-state/CompanyDocuments/companyDocuments';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditCompanyDocument from '../EditCompanyDocument/EditCompanyDocument'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaRichText from '../../../../components/lexical/SeaRichText/SeaRichText';

interface ViewCompanyDocumentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: CompanyDocument,
}

const ViewCompanyDocument: React.FC<ViewCompanyDocumentProps> = ({showModal, setShowModal, selectedItem}) => {
    const companyDocumentCategories = sharedState.companyDocumentCategories.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [richTextState, setRichTextState] = useState<RichTextState>(initialRichTextState);
    const [onScroll, setOnScroll] = useState<(event: Event) => void>();
    const [visible, setVisible] = useState(false);
    const [modalContentRef, setModalContentRef] = useState<React.RefObject<SeaScrollable>>();

    // Handle loading
    useEffect(() => {
        if (selectedItem?.sfdoc) {
            return loadSfdoc(
                selectedItem.sfdoc,
                setRichTextState
                // getDefaultContent,
                // licenseeSettings !== undefined,
            );
        }
    }, [selectedItem?.sfdoc]);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    
    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {selectedItem?.title}
                    </span>
                    {selectedItem?.whenExpires &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenExpires}
                            warnDays={warnDays.companyDocuments[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size="rich-text"
            noTitleOverflow={true}
            scrollEvents={true}
            onScroll={onScroll}
            onOpened={() => {
                setVisible(true);
            }}
            onClosed={() => {
                setVisible(false);
            }}
            setModalContentRef={setModalContentRef}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                {selectedItem?.categoryId && companyDocumentCategories?.byId ? <SeaGridCell label="Category" w="50">
                    {formatValue(companyDocumentCategories.byId[selectedItem.categoryId].name)}
                </SeaGridCell> : null}
                {selectedItem?.type === 'renewable' && 
                    <>
                        <SeaGridCell label="Renewal Interval" w="50">
                            {formatValue(formatInterval(selectedItem?.interval))}
                        </SeaGridCell>
                        <SeaGridCell label="Expiry" w="50">
                            {formatDate(selectedItem?.whenExpires)}
                        </SeaGridCell>
                        <SeaGridCell label="Notification" w="50">
                            {formatValue(formatEmailReminder(selectedItem?.emailReminder))}
                        </SeaGridCell>
                    </>
                }
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions 
                    role="companyDocuments"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Document</SeaButton>
                    <RequirePermissions
                        role="companyDocuments"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'companyDocuments',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'companyDocuments');
                                        setShowModal(false);
                                    },
                                    'company document',
                                    selectedItem?.title
                                );
                            }}
                        >
                            Delete Document
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {showModal &&
                <EditCompanyDocument
                    showModal={showEditItemModal}
                    setShowModal={setShowEditItemModal}
                    itemToUpdate={selectedItem}
                    level={2}
                />
            }

            {selectedItem?.sfdoc &&
                <>
                    <div className="end-info-line"></div>
                    <SeaRichText
                        forModal={true}
                        visible={visible}
                        setOnScroll={setOnScroll}
                        richTextState={richTextState}
                        modalContentRef={modalContentRef}
                    />
                </>
            }

        </SeaModal>
    );
};

export default ViewCompanyDocument;
