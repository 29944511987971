import React, { useCallback, useMemo } from 'react';
import { Document, View, Page, Text } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { formatDate, formatValue } from '../../../lib/util';
import { renderIncidentStatePDF } from '../../../pages/HealthSafety/Incidents/incidents-util';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaNoDataPDF from '../../Components/SeaNoDataPDF';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';

interface IncidentStatusReportPdfProps {
    generatingPdf: boolean,
    setGeneratingPdf: (generatingPdf: boolean) => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    categories?: string[],
    filteredIncidents?: Incident[],
    labelWidthsForPdf?: number[],
    vesselName?: string,
}

const IncidentStatusReportPdf: React.FC<IncidentStatusReportPdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    title,
    subTitle,
    graphData,
    categories,
    filteredIncidents,
    labelWidthsForPdf,
    vesselName
}) => {
    const vessels = sharedState.vessels.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const { mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const subHeading = useMemo(() => {
        if (vesselName && subTitle) {
            return subTitle.replace(/\) *$/, ` ${vesselName})`)
        } else if (vesselName) {
            return `(${vesselName})`;
        }
        return subTitle;
    }, [vesselName, subTitle]);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subHeading}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalStackedBarGraph
                                n={0}
                                mode="pdf"
                                data={graphData}
                                //units={demoIncidentsByStatus.units}
                                categories={categories}
                                labelWidthsForPdf={labelWidthsForPdf}
                                colourPalette={reporting.colours.incidentStatuses}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="incidents"
                        hasNoData={!filteredIncidents?.length}
                        isUsingFilter={true}
                    />
                     {filteredIncidents?.length ? (
                        <View style={pdfStyles.dataContainer}>
                            <View style={pdfStyles.dataHeaderRow}>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.dateColumn]}>Date</Text>
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Name</Text>
                                {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                <Text style={[pdfStyles.dataHeaderText, pdfStyles.statusColumn]}>Status</Text>
                            </View>
                            {mapPdfArrayWithLimit(filteredIncidents, (incident) => (
                                <View wrap={false} key={incident.id} style={pdfStyles.greyDataRow}>
                                    <Text style={pdfStyles.dateColumn}>{formatDate(incident.whenEvent)}</Text>
                                    <Text style={pdfStyles.column2x3}>{formatValue(incident.name)}</Text>
                                    {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[incident.vesselId]?.name}</Text> : null}
                                    <View style={pdfStyles.statusColumn}>
                                        {renderIncidentStatePDF(incident.state)}
                                    </View>
                                </View>
                            ))}
                            <UnloadedPdfRows length={filteredIncidents?.length} />
                        </View>
                    ) : null}

                </Page>
            </Document>
        )
    }, [title, vessels, selectedVesselIds, graphData, categories, labelWidthsForPdf, filteredIncidents, selectedVesselId, mapPdfArrayWithLimit]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="incidentCategoriesReport"
        />
    );

};

export default IncidentStatusReportPdf;
