import { DocumentData, QueryDocumentSnapshot, collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { CreateableDocument, SharedStateConfig, sharedState } from "../../shared-state";
import { registerFiles } from "../../../lib/fileSync";
import { CustomForm } from './customForms';
import { canView } from "../../../lib/permissions";

//
// Load customFormVersions
//

export interface CustomFormVersion extends CreateableDocument {
    customFormId: string;
    deletedBy?: string;
    forVesselIds: string[];
    form: CustomForm;
    historyElementN: number;
    licenseeId: string;
    numCompleted: number;
    state: string;
    version: number;
    whenDeleted?: number;
}

export type CustomFormVersionsData = {
    byFormIdAndVersion: {
        [customFormId: string]: {
            [version: number]: CustomFormVersion
        }
    },
    count: number
};

export const customFormVersionsConfig: SharedStateConfig<CustomFormVersionsData> = {
    isAlwaysActive: true, // customFormVersions always needs to be loaded because dataSyncTasks.processTaskQueryResults needs them
    notes: 'Always needs to be loaded for fileSync file registration to work',
    dependencies: ['licenseeId', 'vesselIds'],
    countLiveDocs: () => sharedState.customFormVersions.current?.count ?? 0,
    run: (done, set, clear) => {
        // clear(); <-- commented this out for the same reason isAlwaysActive is set to true
        const licenseeId = sharedState.licenseeId.current;
        const vesselIds = sharedState.vesselIds.current;
        if (
            licenseeId &&
            vesselIds &&
            vesselIds.length > 0
        ) {
            const processDocs = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
                const _customFormVersions = docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as CustomFormVersion;
                });

                const byFormIdAndVersion = {} as {
                    [customFormId: string]: {
                        [version: number]: CustomFormVersion
                    }
                };
                _customFormVersions.forEach((customFormVersion: CustomFormVersion) => {
                    if (byFormIdAndVersion[customFormVersion.customFormId] === undefined) {
                        byFormIdAndVersion[customFormVersion.customFormId] = {} as {
                            [version: number]: CustomFormVersion
                        };
                    }
                    byFormIdAndVersion[customFormVersion.customFormId][customFormVersion.version] = customFormVersion;

                    for (let key in customFormVersion.form) {
                        if (
                            customFormVersion.form.hasOwnProperty(key) &&
                            customFormVersion.form[key]?.help?.files
                        ) {
                            registerFiles(customFormVersion.form[key].help.files);
                        }
                    }
                });

                set({
                    byFormIdAndVersion,
                    count: _customFormVersions.length
                });
            };

            if (canView('crewParticulars')) {
                // If I can view crewParticulars I need access to ALL customFormVersions across the licensee
                return onSnapshot(
                    query(
                        collection(firestore, 'customFormVersions'),
                        where('licenseeId', '==', licenseeId),
                        where('state', '==', 'active')
                    ),
                    (snap) => {
                        done();
                        processDocs(snap.docs);
                    }, (error) => {
                        done();
                    }
                );
            } else {
                return setupArrayQueryListener(
                    'customFormVersions', // what
                    collection(firestore, 'customFormVersions'),
                    [
                        where('state', '==', 'active'),
                        where('licenseeId', '==', licenseeId)
                    ],
                    'forVesselIds',
                    'array-contains-any',
                    ['none', ...vesselIds],
                    [], // orderByConstraints
                    (
                        docs: QueryDocumentSnapshot<DocumentData>[],
                        isCombined: boolean // (since there is no orderBy, this is irrelevant)
                    ) => { // processDocs
                        done();
                        processDocs(docs);
                    }, (error) => {
                        done();
                    }
                );
            }
        } else {
            done();
        }
    }
};
