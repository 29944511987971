import { UserPermissions } from '../shared-state/Core/userPermissions';
import { sharedState } from "../shared-state/shared-state";

export interface Permissions {
    [role: string]: {
        name: string,
        help?: {
            text: string
        }
    }
}

export const permissionLevels = {
    NONE: 0,        // hidden
    VIEW: 2,        // view only,
    EDIT: 4,        // view create & edit
    FULL: 6         // view, create, edit, archive and delete
};

export const permissions: Permissions = {
    vesselSettings: {
        name: 'Vessel Settings & Particulars'
    },
    engineHours: {
        name: 'Engine Hours'
    },
    logbook: {
        name: 'Logbook'
    },
    safetyEquipmentChecks: {
        name: 'Safety Checks'
    },
    safetyEquipmentList: {
        name: 'Safety Equipment Expiries'
    },
    hazardRegister: {
        name: 'Hazard Register'
    },
    drills: {
        name: 'Drills'
    },
    incidentAccidentMedicalRegister: {
        name: 'Incident / Event Reports',
        help: {
            text: ` Hidden:
                    * Can create and view their own reports
                
                    View Only:
                    * Can create and view their own reports
                    * Can view other people's reports and report reviews
                    
                    View, Create & Edit:
                    * Can create and view their own reports
                    * Can view other people's reports and report reviews
                    * Can review reports unless already completed
                    
                    View, Create, Edit & Archive:
                    * Can create and view their own reports
                    * Can view other people's reports and report reviews
                    * Can review any reports
                    * Can create, view, edit, or delete any reports (including drafts)
            `
        }
    },
    maintenanceSchedule: {
        name: 'Maintenance Schedule'
    },
    maintenanceHistory: {
        name: 'Maintenance History'
    },
    survey: {
        name: 'Survey'
    },
    jobList: {
        name: 'Job List'
    },
    sparePartsList: {
        name: 'Spare Parts List / Inventory List'
    },
    vesselCertificates: {
        name: 'Vessel Certificates / Vehicle Expiries'
    },
    vesselDocuments: {
        name: 'Vessel Documents'
    },
    standardOperatingProcedures: {
        name: 'Standard Operating Procedures'
    },
    healthSafetyMeetings: {
        name: 'Health & Safety Meetings'
    },
    dangerousGoodsRegister: {
        name: 'Dangerous Goods Register'
    },
    equipmentManualDocuments: {
        name: 'Equipment Manuals'
    },
    customForms: {
        name: 'Forms/Checklists'
    },
    crewParticulars: {
        name: 'Crew Particulars'
    },
    crewCertificates: {
        name: 'Crew Certificates'
    },
    crewTraining: {
        name: 'Crew Training'
    },
    contacts: {
        name: 'Contacts'
    },
    companyPlan: {
        name: 'Company Plan'
    },
    companyDocuments: {
        name: 'Company Documents'
    },
};

export const permissionsList = [
    "vesselSettings",
    "engineHours",
    "logbook",
    "safetyEquipmentChecks",
    "safetyEquipmentList",
    "hazardRegister",
    "drills",
    "incidentAccidentMedicalRegister",
    "maintenanceSchedule",
    "maintenanceHistory",
    "survey",
    "jobList",
    "sparePartsList",
    "vesselCertificates",
    "vesselDocuments",
    "standardOperatingProcedures",
    "healthSafetyMeetings",
    "dangerousGoodsRegister",
    "equipmentManualDocuments",
    "customForms",
    "crewParticulars",
    "crewCertificates",
    "crewTraining",
    "contacts",
    "companyPlan",
    "companyDocuments"
] as PermissionRole[];

export type PermissionRole =
     'vesselSettings'
    |'engineHours'
    |'logbook'
    |'safetyEquipmentChecks'
    |'safetyEquipmentList'
    |'hazardRegister'
    |'drills'
    |'incidentAccidentMedicalRegister'
    |'maintenanceSchedule'
    |'maintenanceHistory'
    |'survey'
    |'jobList'
    |'sparePartsList'
    |'vesselCertificates'
    |'vesselDocuments'
    |'standardOperatingProcedures'
    |'healthSafetyMeetings'
    |'dangerousGoodsRegister'
    |'equipmentManualDocuments'
    |'customForms'
    |'crewParticulars'
    |'crewCertificates'
    |'crewTraining'
    |'contacts'
    |'companyPlan'
    |'companyDocuments';

export const canView = (role: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[role] && userPermissions[role] >= permissionLevels.VIEW) ? true : false;
};
export const canViewAny = (roles: PermissionRole[]): boolean => {
    for (let i = 0; i < roles.length; i++) {
        if (canView(roles[i])) {
            return true;
        }
    }
    return false;
}
export const canEdit = (role: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[role] && userPermissions[role] >= permissionLevels.EDIT) ? true : false;
};
export const canArchive = (role: PermissionRole): boolean => {
    const userPermissions = sharedState.userPermissions.current;
    return (userPermissions && userPermissions[role] && userPermissions[role] >= permissionLevels.FULL) ? true : false;
};
export const canAccessVessel = (vesselId: string) => {
    return (
        vesselId &&
        sharedState.vesselIds.current &&
        sharedState.vesselIds.current.includes(vesselId)
    );
};
export const canAccessAllVessels = (vesselIds: string[]) => {
    const myMesselIds = sharedState.vesselIds.current;
    if (myMesselIds) {
        for (let i = 0; i < vesselIds.length; i++) {
            if (!myMesselIds.includes(vesselIds[i])) {
                return false;
            }
        }
        return true;
    }
    return false;
};
export const canAccessAnyVessels = (vesselIds: string[]) => {
    const myMesselIds = sharedState.vesselIds.current;
    if (myMesselIds) {
        for (let i = 0; i < vesselIds.length; i++) {
            if (myMesselIds.includes(vesselIds[i])) {
                return true;
            }
        }
    }
    return false;
}
export const canDelete = canArchive;


export const defaultCrewPermissions = {
    state:                              'active',
    vesselSettings:                     permissionLevels.EDIT,
    engineHours:                        permissionLevels.EDIT,
    logbook:                            permissionLevels.EDIT,
    safetyEquipmentChecks:              permissionLevels.EDIT,
    safetyEquipmentList:                permissionLevels.EDIT,
    hazardRegister:                     permissionLevels.EDIT,
    drills:                             permissionLevels.EDIT,
    incidentAccidentMedicalRegister:    permissionLevels.EDIT,
    maintenanceSchedule:                permissionLevels.EDIT,
    maintenanceHistory:                 permissionLevels.EDIT,
    survey:                             permissionLevels.EDIT,
    jobList:                            permissionLevels.EDIT,
    sparePartsList:                     permissionLevels.EDIT,
    vesselCertificates:                 permissionLevels.EDIT,
    vesselDocuments:                    permissionLevels.EDIT,
    standardOperatingProcedures:        permissionLevels.EDIT,
    healthSafetyMeetings:               permissionLevels.EDIT,
    dangerousGoodsRegister:             permissionLevels.EDIT,
    equipmentManualDocuments:           permissionLevels.EDIT,
    customForms:                        permissionLevels.EDIT,
    crewParticulars:                    permissionLevels.NONE, //
    crewCertificates:                   permissionLevels.NONE, //
    crewTraining:                       permissionLevels.EDIT,
    contacts:                           permissionLevels.EDIT,
    companyPlan:                        permissionLevels.EDIT,
    companyDocuments:                   permissionLevels.EDIT
} as UserPermissions;

export const defaultSkipperPermissions = {
    state:                              'active',
    vesselSettings:                     permissionLevels.EDIT,
    engineHours:                        permissionLevels.EDIT,
    logbook:                            permissionLevels.EDIT,
    safetyEquipmentChecks:              permissionLevels.EDIT,
    safetyEquipmentList:                permissionLevels.EDIT,
    hazardRegister:                     permissionLevels.EDIT,
    drills:                             permissionLevels.EDIT,
    incidentAccidentMedicalRegister:    permissionLevels.EDIT,
    maintenanceSchedule:                permissionLevels.EDIT,
    maintenanceHistory:                 permissionLevels.EDIT,
    survey:                             permissionLevels.EDIT,
    jobList:                            permissionLevels.EDIT,
    sparePartsList:                     permissionLevels.EDIT,
    vesselCertificates:                 permissionLevels.EDIT,
    vesselDocuments:                    permissionLevels.EDIT,
    standardOperatingProcedures:        permissionLevels.EDIT,
    healthSafetyMeetings:               permissionLevels.EDIT,
    dangerousGoodsRegister:             permissionLevels.EDIT,
    equipmentManualDocuments:           permissionLevels.EDIT,
    customForms:                        permissionLevels.EDIT,
    crewParticulars:                    permissionLevels.EDIT,
    crewCertificates:                   permissionLevels.EDIT,
    crewTraining:                       permissionLevels.EDIT,
    contacts:                           permissionLevels.EDIT,
    companyPlan:                        permissionLevels.EDIT,
    companyDocuments:                   permissionLevels.EDIT
} as UserPermissions;

export const defaultLicenseePermissions = {
    state:                              'active',
    vesselSettings:                     permissionLevels.FULL,
    engineHours:                        permissionLevels.FULL,
    logbook:                            permissionLevels.FULL,
    safetyEquipmentChecks:              permissionLevels.FULL,
    safetyEquipmentList:                permissionLevels.FULL,
    hazardRegister:                     permissionLevels.FULL,
    drills:                             permissionLevels.FULL,
    incidentAccidentMedicalRegister:    permissionLevels.FULL,
    maintenanceSchedule:                permissionLevels.FULL,
    maintenanceHistory:                 permissionLevels.FULL,
    survey:                             permissionLevels.FULL,
    jobList:                            permissionLevels.FULL,
    sparePartsList:                     permissionLevels.FULL,
    vesselCertificates:                 permissionLevels.FULL,
    vesselDocuments:                    permissionLevels.FULL,
    standardOperatingProcedures:        permissionLevels.FULL,
    healthSafetyMeetings:               permissionLevels.FULL,
    dangerousGoodsRegister:             permissionLevels.FULL,
    equipmentManualDocuments:           permissionLevels.FULL,
    customForms:                        permissionLevels.FULL,
    crewParticulars:                    permissionLevels.FULL,
    crewCertificates:                   permissionLevels.FULL,
    crewTraining:                       permissionLevels.FULL,
    contacts:                           permissionLevels.FULL,
    companyPlan:                        permissionLevels.FULL,
    companyDocuments:                   permissionLevels.FULL
} as UserPermissions;
