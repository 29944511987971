import React, { ReactNode, useMemo } from 'react';
import { useReportSettings } from '../../pages/Reporting/Reporting';
import SeaSelectVessels from '../SeaSelectVessels/SeaSelectVessels';
import SeaDateRange from '../SeaDateRange/SeaDateRange';
import SeaGrid from '../SeaGrid/SeaGrid';
import SeaGridCell from '../SeaGridCell/SeaGridCell';
import './SeaReportSettings.css';

interface SeaReportSettingsProps {
    mode: 'dashboard' | 'modal',
    showDateRange?: boolean,
    extraComponents?: ReactNode[],
}

const SeaReportSettings: React.FC<SeaReportSettingsProps> = ({
    mode,
    showDateRange = true,
    extraComponents
}) => {
    const { selectedVesselIds, setSelectedVesselIds, dateRange, setDateRange, setDateRangeDescription } = useReportSettings();

    const zone = (mode === 'dashboard') ? 'grey' : 'white';

    const numColumns = useMemo(() => {
        let n = 1;
        if (showDateRange) {
            n++;
        }
        if (extraComponents) {
            n += extraComponents.length;
        }
        return n;
    }, [showDateRange, extraComponents]);

    const columnSize = useMemo(() => {
        if (numColumns > 2 && numColumns % 2 === 1) {
            return "33";
        }
        return "50";
    }, [numColumns]);

    if (mode === 'modal') {
        return (
            <div className={`report-settings ${mode}`}>
                <SeaGrid>
                    <SeaGridCell w={columnSize}>
                        <SeaSelectVessels
                            label="Vessels"
                            zone={zone}
                            vesselIds={selectedVesselIds}
                            setVesselIds={setSelectedVesselIds}
                        />
                    </SeaGridCell>
                    {showDateRange &&
                        <SeaGridCell w={columnSize}>
                            <SeaDateRange
                                label="Date Range"
                                zone={zone}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                setDateRangeDescription={setDateRangeDescription}
                            />
                        </SeaGridCell>
                    }
                    {extraComponents?.map((node: ReactNode, index: number) => {
                        return (
                            <SeaGridCell w={columnSize} key={index}>
                                {node}
                            </SeaGridCell>
                        );
                    })}
                </SeaGrid>
            </div>
        );
    }

    return (
        <div className={`report-settings columns ${mode}`}>
            <div>
                <SeaSelectVessels
                    label="Vessels"
                    zone={zone}
                    vesselIds={selectedVesselIds}
                    setVesselIds={setSelectedVesselIds}
                />
            </div>
            {showDateRange &&
                <div>
                    <SeaDateRange
                        label="Date Range"
                        zone={zone}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        setDateRangeDescription={setDateRangeDescription}
                    />
                </div>
            }
            {extraComponents?.map((node: ReactNode, index: number) => {
                return (
                    <div key={index}>
                        {node}
                    </div>
                );
            })}
        </div>
    );
};

export default SeaReportSettings;
