import React, { ReactNode } from 'react';
import './SeaGrid.css';

interface SeaGridProps {
    children: ReactNode
}

const SeaGrid: React.FC<SeaGridProps> = ({ children }) => {

    return (
        <div className="sea-grid">
            {children}
        </div>
    );
};

export default SeaGrid;
