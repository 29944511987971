import React, { useCallback } from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn } from "../../lib/pdf";
import { formatDate, formatMonthLonger, formatValue, renderCamelCase } from "../../lib/util";
import { renderFullName, renderFullNameForUserId } from "../../shared-state/Core/users";
import { sharedState } from '../../shared-state/shared-state';
import { MaintenanceTaskCompleted } from '../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import { renderCategoryName } from "../../lib/categories";
import SeaPdfGenerator from "../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../components/PdfItemsHeader/PdfItemsHeader";

interface MaintenanceHistoryPdfProps {
    generatingPdf: boolean;
    setGeneratingPdf: (generatingPdf: boolean) => void;
    selectedItems: {
        [key: string]: MaintenanceTaskCompleted[]
    }
    selectedMonthCategories?: string[];
}

const MaintenanceHistoryPdf: React.FC<MaintenanceHistoryPdfProps> = ({
    generatingPdf,
    setGeneratingPdf,
    selectedItems,
    selectedMonthCategories,
}) => {
    const vesselSystems = sharedState.vesselSystems.use();
    const vessel = sharedState.vessel.use();
    const title = `Maintenance History - ${vessel?.name}`;

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Engine Hours</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>System</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Equipment</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Task</Text>
                        </View>
                        <View style={styleItemColumn(0.9)}>
                            <Text>Type</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Completed by</Text>
                        </View>
                    </View>
                    <View
                        wrap={false}
                        style={pdfStyles.itemHeadingsLine}
                    ></View>

                    {selectedMonthCategories?.map((category: string, index: number) => 
                        <React.Fragment key={category}>
                            {index ? <View style={pdfStyles.itemsCategorySpacer} /> : null}
                            <View style={pdfStyles.labelHeading}>
                                <Text style={pdfStyles.itemsCategory}>
                                    {formatMonthLonger(category)}
                                </Text>
                            </View>
                            {selectedItems[category]?.map(
                                (item: MaintenanceTaskCompleted, index: number) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            {index > 0 && (
                                                <View
                                                    style={pdfStyles.itemsRowSpacer}
                                                ></View>
                                            )}
                                            <View
                                                wrap={false}
                                                style={pdfStyles.itemsRow}
                                            >
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>
                                                        {formatDate(item.whenCompleted)}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.numColumn}>
                                                    <Text>
                                                        {item?.engineHours}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(renderCategoryName(item.equipment?.systemId, vesselSystems))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(item.equipment?.equipment)}
                                                        {item.equipment?.state === 'deleted' && ' (deleted)'}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(item.task as string)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(0.9)}>
                                                    <Text>
                                                        {formatValue(item.type === 'unscheduled' ? 'Job' : renderCamelCase(item.type))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {renderFullNameForUserId(item.completedBy)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </React.Fragment>
                    )}
                </Page>
            </Document>
        );
    }, [title, selectedMonthCategories, selectedItems, vesselSystems]);

    return (
        <SeaPdfGenerator
            generatingPdf={generatingPdf}
            setGeneratingPdf={setGeneratingPdf}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="MaintenanceHistory"
        />
    );
};

export default MaintenanceHistoryPdf;
