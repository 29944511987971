import React, { useState, useRef, useCallback } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { preventMultiTap } from '../../../../lib/util';
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { Action, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';

interface EditIncidentsSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const EditIncidentsSettings: React.FC<EditIncidentsSettingsProps> = ({showModal, setShowModal}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const incidentCategories = sharedState.incidentCategories.use(showModal);
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const injuryTypes = sharedState.injuryTypes.use(showModal);
    const injuryLocations = sharedState.injuryLocations.use(showModal);
    const formRef = useRef<HTMLFormElement>(null);

    const [tab, setTab] = useState("incidentCategories");
    const [incidentCategoryIds, setIncidentCategoryIds] = useState<string[]>();
    const [incidentCategoryNames, setIncidentCategoryNames] = useState<string[]>();
    const [incidentCauseIds, setIncidentCauseIds] = useState<string[]>();
    const [incidentCauseNames, setIncidentCauseNames] = useState<string[]>();
    const [injuryTypeIds, setInjuryTypeIds] = useState<string[]>();
    const [injuryTypeNames, setInjuryTypeNames] = useState<string[]>();
    const [injuryLocationIds, setInjuryLocationIds] = useState<string[]>();
    const [injuryLocationNames, setInjuryLocationNames] = useState<string[]>();
    
    const onOpened = () => {
        if (incidentCategories) {
            setIncidentCategoryIds(makeCategoryIdsForEditing(incidentCategories));
            setIncidentCategoryNames(makeCategoryNamesForEditing(incidentCategories));
        }
        if (incidentCauses) {
            setIncidentCauseIds(makeCategoryIdsForEditing(incidentCauses));
            setIncidentCauseNames(makeCategoryNamesForEditing(incidentCauses));
        }
        if (injuryTypes) {
            setInjuryTypeIds(makeCategoryIdsForEditing(injuryTypes));
            setInjuryTypeNames(makeCategoryNamesForEditing(injuryTypes));
        }
        if (injuryLocations) {
            setInjuryLocationIds(makeCategoryIdsForEditing(injuryLocations));
            setInjuryLocationNames(makeCategoryNamesForEditing(injuryLocations));
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (preventMultiTap('editIncidentsSettings')) { return; }

        const action = traceAction('incidentCategories', 'update') as Action;
        action.docId = licenseeId;
        const batch = splittableBatch(firestore, 20 - 0);

        saveCategoryChanges(
            batch,
            incidentCategoryIds,
            incidentCategoryNames,
            undefined,
            incidentCategories,
            'incidentCategories',
            'licenseeId',
            licenseeId,
            action
        );

        saveCategoryChanges(
            batch,
            incidentCauseIds,
            incidentCauseNames,
            undefined,
            incidentCauses,
            'incidentCauses',
            'licenseeId',
            licenseeId,
            action
        );

        saveCategoryChanges(
            batch,
            injuryTypeIds,
            injuryTypeNames,
            undefined,
            injuryTypes,
            'injuryTypes',
            'licenseeId',
            licenseeId,
            action
        );

        saveCategoryChanges(
            batch,
            injuryLocationIds,
            injuryLocationNames,
            undefined,
            injuryLocations,
            'injuryLocations',
            'licenseeId',
            licenseeId,
            action
        );

        action.data = {
            incidentCategoryIds,
            incidentCategoryNames,
            incidentCauseIds,
            incidentCauseNames,
            injuryTypeIds,
            injuryTypeNames,
            injuryLocationIds,
            injuryLocationNames
        };
        action.save('Update incidents settings', batch);

        batch.commit().then(() => {
            action.reportSuccess();
        }).catch((error: any) => {
            action.reportError(error.message, error);
        });
        setShowModal(false);
        showToast(`Incident/event settings updated`);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveCategoriesChanged(incidentCategoryIds, incidentCategoryNames, incidentCategories) ||
            haveCategoriesChanged(incidentCauseIds, incidentCauseNames, incidentCauses) ||
            haveCategoriesChanged(injuryTypeIds, injuryTypeNames, injuryTypes) ||
            haveCategoriesChanged(injuryLocationIds, injuryLocationNames, injuryLocations)
        );
    }, [incidentCategoryIds, incidentCategoryNames, incidentCategories, incidentCauseIds, incidentCauseNames, incidentCauses, injuryTypeIds, injuryTypeNames, injuryTypes, injuryLocationIds, injuryLocationNames, injuryLocations]);

    return (
        <SeaModal
            title="Incident, Event Register Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="incidentCategories" mode="forms">Incident/Event Categories</SeaTab>
                    <SeaTab tab="incidentCauses" mode="forms">Incident/Event Causes</SeaTab>
                    <SeaTab tab="injuryTypes" mode="forms">Injury Types</SeaTab>
                    <SeaTab tab="injuryLocations" mode="forms">Injury Locations</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Incident/Event Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="incidentCategories" selectedTab={tab}>
                    <SeaInputList
                        name="incidentCategories"
                        label="Incident/Event Categories"
                        maxWidth="500px"
                        ids={incidentCategoryIds}
                        setIds={setIncidentCategoryIds}
                        values={incidentCategoryNames}
                        setValues={setIncidentCategoryNames}
                        addNewText="Add Category"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="incidentCauses" selectedTab={tab}>
                    <SeaInputList
                        name="incidentCauses"
                        label="Incident/Event Causes"
                        maxWidth="500px"
                        ids={incidentCauseIds}
                        setIds={setIncidentCauseIds}
                        values={incidentCauseNames}
                        setValues={setIncidentCauseNames}
                        addNewText="Add Cause"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="injuryTypes" selectedTab={tab}>
                    <SeaInputList
                        name="injuryTypes"
                        label="Injury Types"
                        maxWidth="500px"
                        ids={injuryTypeIds}
                        setIds={setInjuryTypeIds}
                        values={injuryTypeNames}
                        setValues={setInjuryTypeNames}
                        addNewText="Add Injury Type"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="injuryLocations" selectedTab={tab}>
                    <SeaInputList
                        name="injuryLocations"
                        label="Injury Locations"
                        maxWidth="500px"
                        ids={injuryLocationIds}
                        setIds={setInjuryLocationIds}
                        values={injuryLocationNames}
                        setValues={setInjuryLocationNames}
                        addNewText="Add Injury Location"
                        confirmDelete={true}
                    />
                </SeaTabContent>
            </form>
        </SeaModal>
    );
};

export default EditIncidentsSettings;
