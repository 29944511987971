import { IonCol } from '@ionic/react';
import { toInt } from '../../lib/util';
import SeaInput from '../SeaInput/SeaInput';
import React from 'react';
import './SeaEngines.css';

export interface EditEngine {
    error?: string;
    hours?: string;
    id?: string;
    initialHours?: string;
    name?: string;
    personnelInvolved?: string[];
    startHours?: number;
}

interface SeaEnginesProps {
    name: string,
    editEngines: EditEngine[],
    setEditEngines:  React.Dispatch<React.SetStateAction<EditEngine[]>>,
    colSize?: string
}

const SeaEngines: React.FC<SeaEnginesProps> = ({ name, editEngines, setEditEngines, colSize="12" }) => {

    const onHoursChange = (e: CustomEvent<any>, index: number) => {
        const _editEngines = [...editEngines];
        _editEngines[index].hours = e.detail.value;
        if (_editEngines[index].hours?.trim().length === 0) {
            _editEngines[index].error = 'This value is required';
        } else if (!/^\d+$/.test(_editEngines[index].hours || '')) {
            _editEngines[index].error = 'Value must be a whole number';
        } else if (toInt(_editEngines[index].hours, 0) > 1000000) {
            _editEngines[index].error = 'Value is too large';
        } else {
            _editEngines[index].error = '';
        }
        setEditEngines(_editEngines);
    };

    return (<>
        {editEngines?.map((editEngine: EditEngine, index) => {
            return (
                <IonCol size={colSize} key={`${name}${editEngine.id}`}>
                    <SeaInput
                        label={editEngine.name}
                        name="name"
                        value={editEngine.hours}
                        onchange={(e) => {onHoursChange(e, index)}}
                        //onblur={(e) => hasFormErrors()}
                        zone="white"
                        type="number"
                        inputmode="numeric"
                        error={editEngine.error}
                    />
                </IonCol>
            );
        })}
    </>);
};

export default SeaEngines;
