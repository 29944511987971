import React, { ReactNode } from 'react';
import './SeaTabContent.css';

interface SeaTabContentProps {
    children?: ReactNode,
    tab: string,
    selectedTab?: string,
}

const SeaTabContent: React.FC<SeaTabContentProps> = ({ children, tab, selectedTab }) => {
    return (
        // <div>
        //     {(tab === selectedTab) && children}
        // </div>
        // I prefer the following so every tab page doesn't need to reload data AND it's less jerky...
        <div style={{ display: (tab === selectedTab) ? 'block' : 'none' }}>
            {children}
        </div>
    );
};

export default SeaTabContent;
