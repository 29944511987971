import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { sharedState } from "../../shared-state";
import { useEffect, useState } from "react";
import { UserType } from '../../Core/user';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { ActionLogEntry } from "../../General/actionLog";

export const useReportingActionLog = (
    isActive: boolean,
    selectedVesselIds: string[],
    filteredUsers: {
        list: UserType[];
        byId: {
            [userId: string]: UserType
        };
    } | undefined
) => {
    const licenseeId = sharedState.licenseeId.use();
    const {dateRange} = useReportSettings();
    const [reportingActionLog, setReportingActionLog] = useState<ActionLogEntry[]>();

    useEffect(() => {
        if (filteredUsers?.list?.length === 0) {
            // Nothing to load
            setReportingActionLog([]);
            return;
        }
        setReportingActionLog(undefined);
        if (isActive && filteredUsers?.list) {
            const userIds = filteredUsers.list.map((user) => {
                return user.id;
            }) as string[];
            return setupArrayQueryListener(
                'actionLog', // what
                collection(firestore, 'actionLog'),
                [
                    where('licenseeId', '==', licenseeId),
                    where('when', '>=', dateRange.from),
                    where('when', '<', dateRange.to + (24*60*60*1000))
                ],
                'userId',
                'in',
                userIds,
                [orderBy('when', 'desc')], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const array = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as ActionLogEntry;
                    });
                    if (isCombined) {
                        array.sort((a, b) => {
                            return b.when - a.when;
                        });
                    }
                    setReportingActionLog(array);
                }
            );

        }
    }, [isActive, licenseeId, filteredUsers, selectedVesselIds, dateRange]);

    return reportingActionLog;
}
