import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../lib/permissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { registerFiles } from '../../lib/fileSync';
import { toFloat } from '../../lib/util';
import { renderCategoryName } from '../../lib/categories';
import { Equipment } from './equipment';

export interface SparePart extends CreateableDocument, UpdateableDocument {
    contactIds?: string[];
    deletedBy?: string;
    equipmentIds?: string[];
    files?: string[];
    item: string;
    locationDescription?: string;
    locationId?: string;
    manufacturer?: string;
    minQuantity?: number;
    model?: string;
    partNum?: string;
    quantity?: number;
    state: string;
    systemId?: string;
    updatedVia?: string;
    vesselId: string;
    whenDeleted?: number;
    orderQuantity?: string;
    searchText?: string;
    equipmentList?: string;
}

export type SparePartsData = {
    all: SparePart[],
    byId: {
        [id: string]: SparePart
    },
    filterOptions: {
        systemIds: string[],
        equipmentIds: string[],
        locationIds: string[]
    }
};

export const sparePartsConfig: SharedStateConfig<SparePartsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSystems', 'vesselLocations', 'equipment'],
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSystems = sharedState.vesselSystems.current;
        const vesselLocations = sharedState.vesselLocations.current;
        const equipment = sharedState.equipment.current;
        if (
            vesselId &&
            equipment?.byId &&
            vesselSystems &&
            vesselLocations &&
            canView('maintenanceSchedule')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'spareParts'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('item', 'asc')
                ),
                (snap) => {
                    done();
                    const rawSpareParts = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SparePart;
                    });
                    if (rawSpareParts) {
                        const all = [...rawSpareParts];
                        const byId = {} as {
                            [id: string]: SparePart
                        };
            
                        // Filters
                        const filterOptions = {
                            systemIds: [] as string[],
                            equipmentIds: [] as string[],
                            locationIds: [] as string[],
                        };
                        const hasFilter = {
                            systemIds: {} as { [id: string]: boolean },
                            equipmentIds: {} as { [id: string]: boolean },
                            locationIds: {} as { [id: string]: boolean },
                        };
            
                        all.forEach((sparePart: SparePart) => {
                            registerFiles(sparePart.files);
                            const minQuantity = toFloat(sparePart.minQuantity);
                            const quantity = toFloat(sparePart.quantity);
                            if (minQuantity) {
                                if (quantity < minQuantity) {
                                    sparePart.orderQuantity = (minQuantity - quantity).toString();
                                }
                            }
                            sparePart.searchText = sparePart.item;
                            if (sparePart.equipmentIds) {
                                let s = '';
                                sparePart.equipmentIds.forEach((id: string) => {
                                    if (s.length > 0) {
                                        s += ', ';
                                    }
                                    const item = equipment.byId[id];
                                    if (item) {
                                        s += item.equipment;
                                        if (item.state === 'deleted') {
                                            s += ' (deleted)';
                                        }
                                    }
            
                                    hasFilter.equipmentIds[id] = true;
                                });
                                sparePart.equipmentList = s;
                                sparePart.searchText += s;
            
                                if (sparePart.systemId) {
                                    sparePart.searchText += renderCategoryName(
                                        sparePart.systemId,
                                        vesselSystems
                                    );
                                    hasFilter.systemIds[sparePart.systemId] = true;
                                }
                            }
                            if (sparePart.locationId) {
                                sparePart.searchText += renderCategoryName(
                                    sparePart.locationId,
                                    vesselLocations
                                );
                                hasFilter.locationIds[sparePart.locationId] = true;
                            }
                            if (sparePart.partNum) {
                                sparePart.searchText += sparePart.partNum;
                            }
                            if (sparePart.manufacturer) {
                                sparePart.searchText += sparePart.manufacturer;
                            }
                            sparePart.searchText = sparePart.searchText.toLowerCase();
                            byId[sparePart.id] = sparePart;
                        });
            
                        equipment.all?.forEach((item: Equipment) => {
                            if (hasFilter.equipmentIds[item.id]) {
                                filterOptions.equipmentIds.push(item.id);
                            }
                        });
            
                        vesselLocations.ids?.forEach((id: string) => {
                            if (hasFilter.locationIds[id]) {
                                filterOptions.locationIds.push(id);
                            }
                        });
            
                        vesselSystems.ids?.forEach((id: string) => {
                            if (hasFilter.systemIds[id]) {
                                filterOptions.systemIds.push(id);
                            }
                        });
                        set({
                            all,
                            byId,
                            filterOptions,
                        });
                    } else {
                        done();
                    }
                },
                (error) => {
                    done();
                    console.error('Error loading spare parts', error);
                }
            );
        }
    }
};