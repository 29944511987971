import React, { useEffect, useState } from 'react';
import { cachedDataInfo, resetCachedDataInfo, updateLicenseeCachedDataInfo, updateVesselCachedDataInfo } from '../../../shared-state/DataSyncSystem/cachedDataInfo';
import { loadingSystem, runSharedState, sharedState } from '../../../shared-state/shared-state';
import { formatDatetime } from '../../../lib/util';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import { LicenseeDataSyncCollection, VesselDataSyncCollection } from '../../../shared-state/DataSyncSystem/dataSyncTasks';
import SeaScrollableArea from '../../../components/SeaScrollableArea/SeaScrollableArea';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SharedStateAnalysis from '../../../components/SharedStateAnalysis/SharedStateAnalysis';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import './DebugView.css';
import AppActivityViewer from '../../../components/AppActivityViewer/AppActivityViewer';

const debugTabs = ['State', 'Data Cache', 'Files', 'Loading System', 'App Activity'];
let currentTab = debugTabs[0];

const DebugView: React.FC = () => {
    const [tab, setTab] = useState<string>(currentTab);
    const [drawCount, setDrawCount] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const dataSyncStatus = sharedState.dataSyncStatus.use();
    const appActivity = sharedState.appActivity.use();
    //console.log(`Render DebugView ${drawCount}`);

    useEffect(() => {
        currentTab = tab;
    }, [tab])

    const refreshLicenseeCache = (collection: string) => {
        updateLicenseeCachedDataInfo(collection as LicenseeDataSyncCollection, undefined);
        runSharedState('whenLicenseeTouched');
        setDrawCount((n) => n+1);
    };
    const refreshVesselCache = (vesselId: string, collection: string) => {
        updateVesselCachedDataInfo(vesselId, collection as VesselDataSyncCollection, undefined);
        runSharedState('whenVesselTouched');
        setDrawCount((n) => n+1);
    };

    return (
        <div
        className="debug-view"
        style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                //backgroundColor: '#e9e5e9',
                backgroundColor: '#33333f',
                color: 'white',
                left: '0px',
                right: '0px',
                top: '0px',
                bottom: '0px',
                zIndex: 100000,
                paddingTop: 'var(--safe-inset-top)'
            }}
        >
            <div className="columns" style={{ height: '64px', padding: '4px 8px 0px 8px', justifyContent: 'space-between', overflowX: 'auto' }}>
                <div className="top-head">
                    <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms">
                        {debugTabs.map((tab) => {
                            return <SeaTab key={tab} tab={tab} mode="forms">{tab}</SeaTab>
                        })}
                    </SeaTabsGroup>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <SeaButton onClick={(e) => sharedState.showDebugger.set(false)}>X</SeaButton>
                </div>
            </div>
            <SeaScrollableArea scrollX className="debug-view-scroll">
                {tab === 'State' && <SharedStateAnalysis />}
                {tab === 'Data Cache' &&
                    <div style={{ padding: '4px 8px 0px 8px', maxWidth: '600px' }}>
                        <div className="columns">
                            <div>
                                Total Tasks: <b>{dataSyncStatus?.totalTasks ?? 0}</b>, Tasks Left: <b>{dataSyncStatus?.tasksLeft ?? 0}</b>
                            </div>
                            <div style={{ flex: '1 1 0', textAlign: 'right', padding: '0px 8px' }}>
                                <div
                                    className="pushy"
                                    style={{ width: '20px', display: 'inline-block' }}
                                    onClick={(e) => resetCachedDataInfo()}
                                >
                                    <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 style={{ fontSize: '16px' }}>Cached Licensee Data</h2>
                        </div>
                        <div className="card" style={{ color: 'white', backgroundColor: 'unset', paddingBottom: '0px', paddingTop: '4px' }}>
                            <div style={{ flex: '0 250px' }}>Collection</div>
                            <div>Most recently touched</div>
                            <div></div>
                        </div>
                        {Object.keys(cachedDataInfo.licensee).sort().map((key) => {
                            const when = cachedDataInfo.licensee?.[key as keyof typeof cachedDataInfo.licensee];
                            return (
                                <div key={key} className="card">
                                    <div style={{ flex: '0 250px' }}>{key}</div>
                                    <div>{when ? formatDatetime(when, ' - ') : '-'}</div>
                                    <div style={{ flex: '1 1 0', textAlign: 'right', color: 'var(--ion-color-primary)' }}>
                                        <div
                                            className="pushy"
                                            style={{ width: '20px', display: 'inline-block' }}
                                            onClick={(e) => refreshLicenseeCache(key)}
                                        >
                                            <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {Object.keys(cachedDataInfo.vessels).map((vesselId) => {
                            return (
                                <React.Fragment key={vesselId}>
                                    <div>
                                        <h2 style={{ fontSize: '16px', paddingTop: '8px' }}>{renderVesselName(vesselId)} - {vesselId}</h2>
                                    </div>
                                    {Object.keys(cachedDataInfo.vessels[vesselId]).sort().map((collection) => {
                                        const when = cachedDataInfo.vessels[vesselId][collection as keyof typeof cachedDataInfo.vessels[string]];
                                        return (
                                            <div key={collection} className="card">
                                                <div style={{ flex: '0 250px' }}>{collection}</div>
                                                <div>{when ? formatDatetime(when, ' - ') : '-'}</div>
                                                <div style={{ flex: '1 1 0', textAlign: 'right', color: 'var(--ion-color-primary)' }}>
                                                    <div
                                                        className="pushy"
                                                        style={{ width: '20px', display: 'inline-block' }}
                                                        onClick={(e) => refreshVesselCache(vesselId, collection)}
                                                    >
                                                        <SeaIcon icon="refresh" forceFontSize="18px" marginTop={-4} marginBottom={-5}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                }
                {tab === 'Loading System' &&
                    <div style={{ padding: '4px 8px 0px 8px' }}>
                        <div style={{ padding: '8px', whiteSpace: 'pre-wrap', cursor: 'text' }}>
                            {JSON.stringify(loadingSystem, undefined, '    ')}
                        </div>
                    </div>
                }
                {tab === 'App Activity' &&
                    <div style={{ padding: '4px 8px 0px 8px' }}>
                        {console.log(appActivity)}
                        <div style={{ padding: '8px', whiteSpace: 'pre-wrap', cursor: 'text' }}>
                            <p>
                                __appIsOkToRestart: {window.localStorage.getItem('__appIsOkToRestart')}
                                <br/>
                                __appLastState: {window.localStorage.getItem('__appLastState')}
                            </p>
                            <AppActivityViewer/>
                            {/* {JSON.stringify(appActivity, undefined, '    ')} */}
                        </div>
                    </div>
                }
            </SeaScrollableArea>
        </div>
    );
};

export default DebugView;
