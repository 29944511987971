import React, { useState, useEffect, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue } from '../../../lib/util';
import { permissionLevels } from '../../../lib/permissions';
import { onProfilerRender } from '../../../lib/performance';
import { Contact } from '../../../shared-state/Crew/contacts';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditContact from '../../../modals/Crew/Contacts/EditContact/EditContact';
import ViewContact from '../../../modals/Crew/Contacts/ViewContact/ViewContact';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import EditContactSettings from '../../../modals/Crew/Contacts/EditContactSettings/EditContactSettings';
import './Contacts.css';

interface ContactsProps {
    visible: boolean
}

const Contacts: React.FC<ContactsProps> = ({visible}) => {
    const contacts = sharedState.contacts.use(visible ? 1 : 101);
    const contactCategories = sharedState.contactCategories.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showEditContactSettings, setShowEditContactSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Contact>();
    
    useEffect(() => {
        if (visible) {
            logPageView('Crew/Contacts');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && contacts?.byId[selectedItem.id]) { 
            setSelectedItem(contacts.byId[selectedItem.id])
        };
    }, [contacts, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: Contact) => {
        console.log(`Viewing contact ${item.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item);
    };

    return (
        <RequirePermissions
            role="contacts"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="crew-contacts page-head">
                <div><h2>Contacts / Suppliers</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="contacts"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add"/>
                            Add New
                        </SeaButton>
                        <div className="spacer"></div>
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => {
                                setShowEditContactSettings(true);
                            }}
                        >
                            <SeaIcon slot="icon-only" icon="settings"/>
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>

            <SeaNoData
                dataName="contacts"
                isLoading={!contacts}
                hasNoData={contacts?.all?.length === 0}
                isUsingFilter={false}
            />

            <div className={`crew-contacts ${(contacts?.all?.length || 0) > 0 ? 'reveal' : 'conceal' }`}>
                <div className="sea-row headings">
                    <div>Company</div>
                    <div>Name</div>
                    <div>Contact #</div>
                    <div>Email</div>
                    <div>Address</div>
                </div>
                <Profiler id="crew.contacts" onRender={onProfilerRender}>
                    {contacts && contactCategories?.ids?.map((categoryId: string) => {
                        if (isLimitReached() || contacts.byCategoryId[categoryId] === undefined) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">{renderCategoryName(categoryId, contactCategories)}</div>
                                {mapArrayWithLimit(contacts.byCategoryId[categoryId], (contact: Contact) => {
                                    return (
                                        <div key={contact.id} className="sea-card sea-row" onClick={(e) => onViewItem(contact)}>
                                            <div className="bold truncate">{formatValue(contact.company)}</div>
                                            <div className="truncate">{formatValue(contact.name)}</div>
                                            <div className="truncate">{formatValue(contact.number)}</div>
                                            <div className="truncate">{formatValue(contact.email)}</div>
                                            <div className="truncate">{formatValue(contact.address)}</div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    <RequirePermissions
                        role="contacts"
                        level={permissionLevels.EDIT}
                    >
                        {selectedItem && <ViewContact
                            showModal={showViewItemModal}
                            setShowModal={setShowViewItemModal}
                            selectedItem={selectedItem}
                        />}
                        <EditContact
                            showModal={showEditItemModal}
                            setShowModal={setShowEditItemModal}
                        />
                        <EditContactSettings
                            showModal={showEditContactSettings} 
                            setShowModal={setShowEditContactSettings}
                        />
                    </RequirePermissions>
                </>
            }

        </RequirePermissions>
    );
};

export default Contacts;
