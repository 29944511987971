import { useEffect, useRef } from 'react';

export default function useOnClickOutside(onClickOutside: () => void) {
    const ref = useRef(null);

    const handleClickOutside = (event: Event) => {
        if (ref.current && !(ref.current as any).contains(event.target)) {
            onClickOutside();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref };
}
