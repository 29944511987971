import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { formatSeaDate, haveValuesChanged, preventMultiTap, toMillis } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { DangerousGood } from '../../../../shared-state/HealthSafety/dangerousGoods';
import { Action, reportError, traceAction } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaDate from '../../../../components/SeaDate/SeaDate';

interface EditDangerousGoodProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: DangerousGood,
    defaultVesselId?: string
}

const EditDangerousGood: React.FC<EditDangerousGoodProps> = ({
    showModal,
    setShowModal,
    itemToUpdate,
    level,
    defaultVesselId
}) => {
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [imageFiles, setImageFiles] = useState<SeaFile[]>([]);
    const [msdsFiles, setMsdsFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                vesselId: itemToUpdate.vesselId ? ''+itemToUpdate.vesselId : '',
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                quantity: itemToUpdate.quantity ? ''+itemToUpdate.quantity : '',
                location: itemToUpdate.location ? ''+itemToUpdate.location : '',
                class: itemToUpdate.class ? ''+itemToUpdate.class : '',
                isHazardous: itemToUpdate.isHazardous ? true : false,
                whenExpires: itemToUpdate.whenExpires ? formatSeaDate(itemToUpdate.whenExpires) : '',
            };
        } else {
            return {
                vesselId: defaultVesselId ? defaultVesselId : '',
                name: '',
                quantity: '',
                location: '',
                class: '',
                isHazardous: '',
                whenExpires: '',
            };

        }
    }, [itemToUpdate, defaultVesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setImageFiles(makeSeaFiles(itemToUpdate?.imageFiles));
        setMsdsFiles(makeSeaFiles(itemToUpdate?.msdsFiles));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().required(),
            name: Yup.string().max(500).required(),
            quantity: Yup.string().max(200),
            location: Yup.string().max(500),
            class: Yup.string().max(500),
            isHazardous: Yup.boolean().required(),
            whenExpires: Yup.date().when('type', {is: 'renewable', then: (schema) => schema.required().min(...notTooOld)}),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('dangerousGood')) { return; }
            // Attempt upload first.... ?
            uploadFiles([...imageFiles, ...msdsFiles]).then(() => {
                // Process form
                const action = traceAction('dangerousGoods') as Action;
                const batch = splittableBatch(firestore, 20 - 0);
                if (itemToUpdate) {
                    action.type = 'update';
                    action.docId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'dangerousGoods', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: action.whenAction,
                            name: data.name,
                            quantity: data.quantity ? data.quantity : deleteValue,
                            location: data.location ? data.location : deleteValue,
                            class: data.class ? data.class : deleteValue,
                            isHazardous: data.isHazardous,
                            whenExpires: data.whenExpires ? toMillis(data.whenExpires) : deleteValue,
                            imageFiles: seaFilesToValue(imageFiles),
                            msdsFiles: seaFilesToValue(msdsFiles),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, imageFiles, 'dangerousGoods', itemToUpdate.id);
                    saveFileRefs(batch, msdsFiles, 'dangerousGoods', itemToUpdate.id);

                    logAction(
                        batch,
                        'Update',
                        'dangerousGoods',
                        itemToUpdate.id,
                        data.name,
                        [itemToUpdate.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'dangerousGoods'));
                    action.type = 'create';
                    action.docId = newRef.id;
                    batch.set(newRef, {
                        vesselId: data.vesselId,
                        addedBy: userId,
                        whenAdded: action.whenAction,
                        name: data.name,
                        quantity: data.quantity ? data.quantity : undefined,
                        location: data.location ? data.location : undefined,
                        class: data.class ? data.class : undefined,
                        isHazardous: data.isHazardous,
                        whenExpires: data.whenExpires ? toMillis(data.whenExpires) : undefined,
                        state: 'active',
                        imageFiles: seaFilesToValue(imageFiles),
                        msdsFiles: seaFilesToValue(msdsFiles),
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, imageFiles, 'dangerousGoods', newRef.id);
                    saveFileRefs(batch, msdsFiles, 'dangerousGoods', newRef.id);

                    logAction(
                        batch,
                        'Add',
                        'dangerousGoods',
                        newRef.id,
                        data.name,
                        [data.vesselId]
                    );
                }

                onCollectionUpdated(batch, 'dangerousGoods');

                action.data = {
                    data,
                    imageFiles: seaFilesToValue(imageFiles),
                    msdsFiles: seaFilesToValue(msdsFiles),
                    itemToUpdate
                };
                action.save(`${itemToUpdate ? 'Update' : 'Add'} dangerous good ${data?.name}`, batch);
                batch.commit().then(() => {
                    action.reportSuccess();
                }).catch((error) => {
                    action.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload dangerous goods files`, error.message, error, {
                        data,
                        imageFiles: seaFilesToValue(imageFiles),
                        msdsFiles: seaFilesToValue(msdsFiles),
                        itemToUpdate
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(imageFiles, itemToUpdate?.imageFiles) ||
            haveFilesChanged(msdsFiles, itemToUpdate?.msdsFiles)
        );
    }, [initialValues, values, imageFiles, msdsFiles, itemToUpdate]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? `Edit - ${itemToUpdate.name}` : 'Add New Dangerous Good'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelect
                                label="Vessel / Facility"
                                name="vesselId"
                                zone="white"
                                value={values.vesselId}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Chemical Name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Quantity"
								name="quantity"
								value={values.quantity}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.quantity ? errors.quantity : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Location"
								name="location"
								value={values.location}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.location ? errors.location : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                label="Hazardous Substance"
                                name="isHazardous"
                                value={values.isHazardous}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.isHazardous ? errors.isHazardous : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                <IonSelectOption value={true}>Yes</IonSelectOption>
                                <IonSelectOption value={false}>No</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="DG Class"
								name="class"
								value={values.class}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.class ? errors.class : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
                                label="Renewal Date"
                                name="whenExpires"
                                value={values.whenExpires}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenExpires ? errors.whenExpires : ''}
                            />   
                        </IonCol>

                        <IonCol size="6">
                        </IonCol>
                        
                        <IonCol size="6">
                            <SeaFileUpload
                                label="Upload Image"
                                files={imageFiles}
                                setFiles={setImageFiles}
                                collection="dangerousGoods"
                                field="imageFiles"
                            />

                        </IonCol>
                        <IonCol size="6">
                            <SeaFileUpload
                                label="Upload SDS Document"
                                files={msdsFiles}
                                setFiles={setMsdsFiles}
                                collection="dangerousGoods"
                                field="msdsFiles"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Dangerous Good' : 'Save Dangerous Good'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDangerousGood;
