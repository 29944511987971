import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../lib/firebase";
import { sharedState } from "../shared-state";
import { useEffect, useState } from "react";
import { UserType } from "../Core/user";
import { dayDifferenceBetweenDates, formatDate } from "../../lib/util";
import { Vessel } from '../Core/vessel';
import { Drill } from '../VesselSafety/drills';
import { DrillReport } from '../VesselSafety/drillReports';

type DrillInfo = {
    drill: Drill;
    lastDone: number;
    whenDue: number | undefined;
    daysOverdue: number;
    history: string;
};

export type UserDrillReportsData = {
    vessels: Vessel[], // Active & alphabetical
    byVesselId: {
        [vesselId: string]: DrillInfo[] // Active & alphabetical
    }
};

export const useUserDrillReports = (selectedUser?: UserType) => {
    const vesselIds = sharedState.vesselIds.use();
    const vessels = sharedState.vessels.use();
    const drills = sharedState.drills.use();
    const [userDrillReports, setUserDrillReports] = useState<UserDrillReportsData>();

    useEffect(() => {
        setUserDrillReports(undefined);
        if (vesselIds && vessels && drills && selectedUser) {
            return setupArrayQueryListener(
                'drillReports', // what
                collection(firestore, 'drillReports'),
                [
                    where('state', '==', 'active'),
                    where('crewInvolvedIds', 'array-contains', selectedUser.id)
                ],
                'vesselId',
                'in',
                vesselIds,
                [orderBy('whenCompleted', 'desc')],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => { // processDocs
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as DrillReport;
                    });

                    if (isCombined) { // Need to sort by whenCompleted
                        all.sort((a, b) => {
                            return b.whenCompleted - a.whenCompleted;
                        });
                    }

                    // Compile drillInfos
                    const byDrillId = {} as {
                        [drillId: string]: DrillInfo
                    };
                    const drillsList = [] as DrillInfo[];
                    all.forEach((report) => {
                        report.drills?.forEach((item) => {
                            if (drills.byId[item.id]) {
                                if (byDrillId[item.id] === undefined) {
                                    const drill = drills.byId[item.id];

                                    const drillInfo = {
                                        drill: drill,
                                        lastDone: report.whenCompleted,
                                        whenDue: item.whenDue,
                                        daysOverdue: dayDifferenceBetweenDates(item.whenDue, Date.now()),
                                        history: ''
                                    };

                                    byDrillId[item.id] = drillInfo;
                                    drillsList.push(drillInfo);
                                } else {
                                    if (byDrillId[item.id].history !== '') {
                                        byDrillId[item.id].history += ', ';
                                    }
                                    byDrillId[item.id].history += formatDate(report.whenCompleted);
                                }
                            }
                        });
                    });

                    // Sort drills alphabetically
                    drillsList.sort((a, b) => {
                        return a.drill.name.localeCompare(b.drill.name);
                    });

                    // Group by vessel
                    const byVesselId = {} as {
                        [vesselId: string]: DrillInfo[]
                    };
                    const vesselsList = [] as Vessel[];
                    drillsList.forEach((drillInfo) => {
                        const vessel = vessels?.byId[drillInfo.drill.vesselId];
                        if (vessel) {
                            if (byVesselId[drillInfo.drill.vesselId] === undefined) {
                                byVesselId[drillInfo.drill.vesselId] = [] as DrillInfo[];
                                vesselsList.push(vessel);
                            }
                            byVesselId[drillInfo.drill.vesselId].push(drillInfo);
                        }
                    });

                    vesselsList.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });

                    setUserDrillReports({
                        vessels: vesselsList,
                        byVesselId: byVesselId
                    });
                }
            );
        }
    }, [vesselIds, vessels, drills, selectedUser]);

    return userDrillReports;

}
