import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { Equipment } from '../../shared-state/VesselMaintenance/equipment';
import SeaInput from '../SeaInput/SeaInput';
import SeaSelect from '../SeaSelect/SeaSelect';
import EditEquipment from '../../modals/VesselMaintenance/EquipmentList/EditEquipment/EditEquipment';
import SeaLinkButton from '../SeaLinkButton/SeaLinkButton';
import SeaSelectCategory from '../SeaSelectCategory/SeaSelectCategory';
import SeaCheckbox from '../SeaCheckbox/SeaCheckbox';
import './SeaEquipment.css';

interface SeaEquipmentProps {
    equipmentId: string | undefined,
    setEquipmentId: (equipmentId: string) => void,
    initialLocationId?: string | undefined,
    locationId?: string | undefined,
    setLocationId?: (locationId: string) => void,
    hasSubmitted?: boolean,
    includeLocation?: boolean,
    includeMakeModelSerial?: boolean,
    includeContactsAndManuals?: boolean,
    includeCritical?: boolean,
    required?: boolean,
    columnSize?: string
}

const SeaEquipment: React.FC<SeaEquipmentProps> = ({
    equipmentId,
    setEquipmentId,
    initialLocationId,
    locationId,
    setLocationId,
    hasSubmitted,
    includeLocation = true,
    includeMakeModelSerial = false,
    includeContactsAndManuals = false,
    includeCritical = false,
    required = true,
    columnSize = '6'
}) => {
    const isMounted = useRef(false);
    const contacts = sharedState.contacts.use();
    const equipment = sharedState.equipment.use();
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const vesselSystems = sharedState.vesselSystems.use();
    const [initialSystemId, setInitialSystemId] = useState<string>('');
    const [systemId, setSystemId] = useState<string>('');
    const [showEditEquipmentModal, setShowEditEquipmentModal] = useState(false);
    const [equipmentItem, setEquipmentItem] = useState<Partial<Equipment>>();
    const [pulseSystem, setPulseSystem] = useState(false);
    const [pulseEquipment, setPulseEquipment] = useState(false);
    const [pulseDetails, setPulseDetails] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    // equipmentId --> equipmentItem
    useEffect(() => {
        if (
            equipmentId &&
            equipmentId !== '' &&
            equipment?.byId[equipmentId]
        ) {
            setEquipmentItem(equipment.byId[equipmentId]);
        } else {
            setEquipmentItem(undefined);
        }
    }, [equipmentId, equipment]);

    // Propagate equipmentId, systemId & locationId if equipmentItem changes
    useEffect(() => {
        if (equipmentItem) {
            setEquipmentId(equipmentItem.id || '');
            setSystemId(equipmentItem.systemId || '');
            setInitialSystemId(equipmentItem.systemId || '');
            if (setLocationId) {
                setLocationId(equipmentItem.locationId ? equipmentItem.locationId : '');
            }
        } else {
            setSystemId('');
            setInitialSystemId('');
            if (setLocationId) {
                setLocationId('');
            }
        }
    }, [equipmentItem]);

    const equipmentList = useMemo(() => {
        if (equipment?.all) {

            let list: Equipment[] = [];
            if (systemId) {
                if (equipment.bySystemId[systemId]) {
                    list = equipment.bySystemId[systemId];
                }
            } else {
                list = equipment.all;
            }

            if (equipmentId) {
                // Make sure equipmentId exists in list
                let found = false;
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === equipmentId) {
                        found = true;
                        break;
                    }
                }
                if (!found && equipment.byId[equipmentId]) { // Add deleted equipment to top of list
                    list = [equipment.byId[equipmentId], ...list];
                }
            }

            return list;
        }
        return undefined;
    }, [equipmentId, equipment, systemId]);

    const setNewEquipment = (newEquipment: Partial<Equipment>) => {
        setEquipmentItem(newEquipment);
    };

    const onSystemChanged = useCallback((e: CustomEvent<any>) => {
        if (systemId !== e.detail.value) {
            setSystemId(e.detail.value);
            setPulseEquipment(true);
            setTimeout(() => {
                if (!isMounted.current) return;
                setPulseEquipment(false);
            }, 1);
            if (equipmentId && equipment) { // Check current selected equipment is within newly selected system
                let found = false;
                const list = equipment.bySystemId[e.detail.value];
                if (list) {
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].id === equipmentId) {
                            found = true;
                            break;
                        }
                    }
                }
                if (!found) {
                    setEquipmentId('');
                    setEquipmentItem(undefined);
                }
            }
        }
    }, [equipment, equipmentId, setEquipmentId, systemId]);

    const onEquipmentChanged = useCallback((e: CustomEvent<any>) => {
        const id = e.detail.value;
        if (id === '') {
            setEquipmentId('');
            setEquipmentItem(undefined);
        } else if (id === '__addNew') {
            setEquipmentId('');
            setEquipmentItem(undefined);
            // Open add new modal here
            setShowEditEquipmentModal(true);
        }  else {
            setEquipmentId(id);
            const item = equipment?.byId[id];
            if (item) {
                if (item.systemId && item.systemId !== systemId) {
                    setSystemId(item.systemId);
                    setPulseSystem(true);
                    setTimeout(() => {
                        if (!isMounted.current) return;
                        setPulseSystem(false);
                    }, 1);
                }
                if (item.locationId && setLocationId) {
                    setLocationId(item.locationId);
                }
                setPulseDetails(true);
                setTimeout(() => {
                    if (!isMounted.current) return;
                    setPulseDetails(false);
                }, 1);
            }
        }
    }, [equipment?.byId, setEquipmentId, setLocationId, systemId]);

    return (
        <>
            <IonCol size={columnSize} className={`${pulseSystem ? 'pulse-left' : 'pulse'}`}>
                <SeaSelectCategory
                    categories={vesselSystems}
                    label="System"
                    name="systemId"
                    initialCategoryId={initialSystemId}
                    categoryId={systemId}
                    otherPlaceholder="System"
                    onchange={onSystemChanged}
                    //onblur={handleBlur}
                    //error={touched.system ? errors.system : ''}
                    error={
                        (hasSubmitted && (systemId === undefined || systemId === ''))
                        ? (required ? 'This field is required' : (equipmentId !== undefined && equipmentId !== '' ? 'Required if you select equipment' : '')) : ''
                    }
                />
            </IonCol>
            <IonCol size={columnSize} className={`${pulseEquipment ? 'pulse-right' : 'pulse'}`}>
                <SeaSelect
                    label="Equipment"
                    value={equipmentId}
                    onchange={onEquipmentChanged}
                    error={
                        (hasSubmitted && (equipmentId === undefined || equipmentId === ''))
                        ? (required ? 'This field is required' : (systemId !== undefined && systemId !== '' ? 'Required if you select a system' : '')) : ''
                    }
                >
                    <IonSelectOption value="">Not Set</IonSelectOption>
                    {equipmentList?.map((item) => {
                        return (
                            <IonSelectOption key={item.id} value={item.id}>
                                {item.equipment}{item.state === 'deleted' && ' (deleted)'}
                            </IonSelectOption>
                        );
                    })}
                    <IonSelectOption value="__addNew">Add New...</IonSelectOption>
                </SeaSelect>
            </IonCol>
            {includeLocation &&
                <IonCol size={columnSize} className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <SeaSelectCategory
                        categories={vesselLocations}
                        label="Location"
                        name="locationId"
                        initialCategoryId={initialLocationId}
                        categoryId={locationId}
                        otherPlaceholder="Add New Location"
                        onchange={(e) => {
                            if (setLocationId) {
                                setLocationId(e.detail.value);
                            }
                        }}
                        //onchange={handleChange}
                        //onblur={handleBlur}
                        //error={touched.locationId ? errors.locationId : ''}
                    />
                </IonCol>
            }
            {equipmentItem && includeCritical &&
                <IonCol size={columnSize} className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <SeaCheckbox
                        mode="beside-input"
                        label="Critical Equipment"
                        name="isCritical"
                        checked={equipmentItem.isCritical ? true : false}
                        disabled
                        help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew. You can change whether an equipment item is critical or not through the Equipment List page.'}}
                    >
                        This equipment is critical
                    </SeaCheckbox>
                </IonCol>
            }
            {equipmentItem && includeMakeModelSerial && (equipmentItem.make || equipmentItem.model || equipmentItem.serial) &&
                <IonCol size="12" className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="4">
                                <SeaInput
                                    label="Make"
                                    value={equipmentItem?.make}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Model"
                                    value={equipmentItem?.model}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Serial"
                                    value={equipmentItem?.serial}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            }
            {(
                equipmentItem &&
                includeContactsAndManuals && (
                    (equipmentItem.equipmentDocumentIds && equipmentItem.equipmentDocumentIds.length > 0) ||
                    (equipmentItem.contactIds && equipmentItem.contactIds.length > 0)
                )
            ) &&
                <IonCol size="12" className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size={columnSize}>
                                <SeaSelect
                                    disabled={true}
                                    label="Contacts / Suppliers"
                                    name="type"
                                    value=""
                                >
                                    <IonSelectOption value="">
                                        {equipmentItem?.contactIds?.map((id: string, index: number) => {
                                            return contacts?.byId[id].name;
                                        }).join(', ')}
                                    </IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            <IonCol size={columnSize}>
                                <SeaSelect
                                    disabled={true}
                                    label="Equipment Manuals"
                                    name="type"
                                    value=""
                                >
                                    <IonSelectOption value="">
                                        {equipmentItem?.equipmentDocumentIds?.map((id: string, index: number) => {
                                            return equipmentManualDocuments?.byId[id]?.title;
                                        }).filter((item) => item !== undefined).join(', ')}
                                    </IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            }
            {equipmentItem &&
                <IonCol size="12">
                    <div style={{
                        textAlign: 'right',
                        margin: '-12px 0px 12px 0px'
                    }}>
                    <SeaLinkButton
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '400px'
                        }}
                        mode="simple-link"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowEditEquipmentModal(true);
                        }}
                    >
                        Edit {equipmentItem.equipment}
                    </SeaLinkButton>
                    </div>
                </IonCol>
            }
            <EditEquipment
                showModal={showEditEquipmentModal}
                setShowModal={setShowEditEquipmentModal}
                itemToUpdate={equipmentItem as Equipment}
                setNewEquipment={setNewEquipment}
                defaultSystemId={initialSystemId} // ???
                disableDelete={true}
                level={3}
            />
        </>
    );
};

export default SeaEquipment;
