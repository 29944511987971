import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import { SeaFile } from '../../../../lib/files';

export interface Help {
    text?: string;
    files?: SeaFile[];
}

interface EditContextualHelpProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    element?: any,
    changeValue: (id: string, value?: any) => void
}

const EditContextualHelp: React.FC<EditContextualHelpProps> = ({showModal, setShowModal, element, changeValue}) => {
    const [text, setText] = useState<string>();
    const [files, setFiles] = useState<SeaFile[]>([]);

    const onOpened = () => {
        setText(undefined);
        setFiles([]);
        if (element?.help?.text) {
            setText(element.help.text);
        }
        if (element?.help?.files) {
            setFiles(element.help.files);
        }
        // setFileToTrash(undefined);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (text || (files.length > 0)) {
            const help = {} as Help;
            if (text) {
                help.text = text;
            }
            if (files.length > 0) {
                help.files = files;
            }
            changeValue('help', help);
        } else {
            changeValue('help', undefined);
        }

        setShowModal(false);
    };

    const isModalDirty = useCallback(() => {
        if (element?.help) {
            if (
                element.help.text !== text ||
                (element.help.files === undefined && files.length > 0) ||
                (element.help.files !== undefined && files.length === 0) ||
                (element.help.files !== undefined && element.help.files.length !== files.length)
            ) {
                return true;
            }
            if (files.length > 0) {
                // Check if any files don't match
                for (let i = 0; i < files.length; i++) {
                    if (files[i].id !== undefined) {
                        if (element.help.files[i].id === undefined || files[i].id !== element.help.files[i].id) {
                            return true;
                        }
                    } else {
                        if (element.help.files[i].unique === undefined || files[i].unique !== element.help.files[i].unique) {
                            return true;
                        }
                    }
                }
            }
            return false;
        } else {
            if (text || files.length > 0) {
                return true;
            }
            return false;
        }
    }, [element?.help, text, files]);

    return (
        <SeaModal
            title={(element?.help) ? 'Edit Help' : 'Add Help'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-thin"
            level={3}
        >
            <form onSubmit={(e) => onSubmit(e)}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Helpful information"
                                name="text"
                                value={text}
                                onchange={(e) => {
                                    setText(e.detail.value);
                                }}
                                //onblur={handleBlur}
                                //zone="white"
                                height={200}
                                inputmode="text"
                                //changeValue
                                //error={touched.previousInjuryOrSurgery ? errors.previousInjuryOrSurgery : ''}
                            />
                        </IonCol>
                        <IonCol>
                            <SeaFileUpload
                                label="Helpful image(s)"
                                files={files}
                                setFiles={setFiles}
                                collection="customForms" // This is not customFormVersions because the file that gets uploaded may end up being associated with multiple customFormVersions
                                field={`form.e${element.n}.help.files`}
                                // allowDocuments={false}
                                // allowMultiple={true}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">
                    Update Help
                </SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditContextualHelp;
