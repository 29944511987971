import * as React from "react";

function SvgFleet(props) {
  return (
    <svg
      width={29}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.677 22.83h-4.61l3.56-10.68a1 1 0 00-.77-1.3l-3.18-.58V5.83a1 1 0 00-1-1h-3v-3a1 1 0 00-1-1h-6a1 1 0 00-1 1v3h-3a1 1 0 00-1 1v4.44l-3.18.56a1 1 0 00-.77 1.3l3.56 10.7h-4.61a1 1 0 000 2h26a1 1 0 000-2zm-15-20h4v2h-4v-2zm-4 4h12V9.9l-5.82-1.07h-.36L8.677 9.9V6.83zm-3.69 5.78l8.69-1.58v11.8h-5.28l-3.41-10.22zm10.69 10.22v-11.8l8.69 1.58-3.41 10.22h-5.28z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFleet;
