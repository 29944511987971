import React, { useEffect, useMemo, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { isTouchDevice } from '../../../lib/util';
import { Line, Rect, Text } from '@react-pdf/renderer';
import { sharedState } from '../../../shared-state/shared-state';
import { GraphData } from '../SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SvgHoverInfo, { HoverInfo } from '../SvgHoverInfo/SvgHoverInfo';
import reporting from '../../../lib/reporting';
import SvgText from '../SvgText/SvgText';
import SeaGraph from '../SeaGraph/SeaGraph';
import SeaPieGraph from '../SeaPieGraph/SeaPieGraph';
import './SeaHorizontalStackedBarGraph.css';

interface SeaHorizontalStackedBarGraphProps {
    title?: string,
    subTitle?: string,
    mode: 'dashboard' | 'modal' | 'pdf'
    visible?: boolean,
    n?: number,
    data?: GraphData[],
    units?: string,
    categories?: (string | undefined)[],
    onClick?: (e: React.MouseEvent) => void,
    renderValue?: (value: number) => string,
    renderPercent?: (value: number) => string,
    labelWidthsForPdf?: number[],
    setLabelWidthsForPdf?: React.Dispatch<React.SetStateAction<number[]>>,
    colourPalette?: string[],
    yLabelWidth?: number
}

const pdfLabelMultiplier = 1.25;

const SeaHorizontalStackedBarGraph: React.FC<SeaHorizontalStackedBarGraphProps> = ({
    title,
    subTitle,
    mode,
    visible = true,
    n = 0,
    data,
    units,
    categories,
    onClick,
    renderValue = (value: number) => {
        return Math.round(value).toLocaleString();
    },
    renderPercent = (ratio: number) => {
        if (isNaN(ratio) || ratio <= 0) {
            return '0%';
        }
        if (ratio < 0.01) {
            return '<1%';
        }
        return `${Math.round(ratio * 100)}%`;
    },
    labelWidthsForPdf,
    setLabelWidthsForPdf,
    colourPalette,
    yLabelWidth = (100 + 6)
}) => {
    const modalSpace = sharedState.modalSpace.use();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeValueIndex, setActiveValueIndex] = useState(-1);
    const [isReady, setIsReady] = useState(false); // Has completed animating and is ready to be interacted with
    const [labelWidths, setLabelWidths] = useState<number[]>([]);
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | undefined>();

    useEffect(() => {
        if (setLabelWidthsForPdf && labelWidths) {
            setLabelWidthsForPdf(labelWidths);
        }
    }, [labelWidths, setLabelWidthsForPdf]);

    useEffect(() => {
        let isActive = true;
        setIsReady(false);
        if (visible) {
            setTimeout(() => {
                if (!isActive) return;
                setIsReady(true);
            }, (n * 100) + 500);
        }
        return () => { isActive = false; };
    }, [visible, n]);

    const totals = useMemo(() => {
        if (data && categories) {
            const totals = {
                rows: [] as number[],
                categories: [] as number[],
                maxTotal: 0,
                grandTotal: 0
            }
            for (let i = 0; i < categories.length; i++) {
                totals.categories[i] = 0;
            }
            data.forEach((row, rowIndex) => {
                let total = 0;
                row.values?.forEach((value, categoryIndex) => {
                    total += value;
                    totals.categories[categoryIndex] += value;
                });
                totals.rows[rowIndex] = total;
                totals.grandTotal += total;
                if (total > totals.maxTotal) {
                    totals.maxTotal = total;
                }
            });
            return totals;
        }
        return undefined;
    }, [data, categories]);

    const xAxis = useMemo(() => {
        return reporting.calculateAxis(totals?.maxTotal);
    }, [totals]);

    const singleData = useMemo(() => {
        if (data?.length === 1 && categories) {
            const _data = [] as {
                name: string,
                value: number
            }[];
            data[0].values?.forEach((value, index) => {
                _data.push({
                    name: categories[index] as string,
                    value: value
                });
            });
            return _data;
        }
        return undefined;
    }, [data, categories]);

    const graph = useMemo(() => {
        if (data) {
            const _graph = reporting.getConfig(mode, modalSpace, colourPalette);
            if (mode !== 'dashboard') {
                //_graph.height = 400;
                _graph.height += Math.max(0, data.length - 10) * 30;
                //_graph.divStyle.height = `${_graph.height}px`;
            }
            return _graph;
        }
        return undefined;
    }, [mode, modalSpace, data, colourPalette]);

    const grid = graph ? {
        left: graph?.padding + yLabelWidth,
        right: graph.width - graph.padding - 10,
        top: graph.headerHeight + reporting.graphPadding.top,
        bottom: graph.height - reporting.graphPadding.bottom - reporting.xAxisLabelSpace - graph.padding
    } : {} as any;
    grid.w = grid.right - grid.left;
    grid.h = grid.bottom - grid.top;
    grid.yBarHeight = 20;
    grid.yBarSpacing = 10;
    grid.yStart = grid.top;

    let displayRows = 0;
    let overflowRows = 0;
    if (data?.length) {
        if (mode === 'dashboard') {
            displayRows = Math.min(12, data.length); // Cap dashboard rows to 12 max
            overflowRows = data.length - displayRows;
        } else {
            displayRows = data.length;
        }
        // Stretch bars to make use of space
        grid.yBarHeight = (grid.h / displayRows) * (2/3);
        grid.yBarSpacing = (grid.h / displayRows) * (1/3);
        // Centre bars vertically
        const totalHeight = displayRows * grid.yBarHeight + ((displayRows - 1) * grid.yBarSpacing);
        grid.yStart += (grid.h - totalHeight) / 2;
    }

    const totalLabelsWidth = useMemo(() => {
        if (categories && categories.length > 0) {
            let numCategories =0;
            categories.forEach((category) => {
                if (category) {
                    numCategories++;
                }
            });
            let total = (
                (numCategories * (reporting.labelBox.w + reporting.labelBox.spacing)) +
                ((numCategories - 1) * reporting.labelBox.gap)
            );
            if (mode === 'pdf') {
                labelWidthsForPdf?.forEach((w: number, index: number) => {
                    if (w && categories[index]) {
                        total += w * pdfLabelMultiplier;
                    }
                });
            } else {
                labelWidths?.forEach((w: number, index: number) => {
                    if (w && categories[index]) {
                        total += w;
                    }
                });
            }
            return total;
        }
        return 0;
    }, [mode, categories, labelWidths, labelWidthsForPdf]);

    if (data === undefined) {
        return reporting.graphSpinner;
    }

    if (data.length === 1) {
        // Just show a pie graph if there is only 1 item
        let _title = title;
        if (_title && _title.indexOf(' by Vessel') > 0) {
            _title = _title.substring(0, _title.indexOf(' by Vessel'));
        }
        if (_title && _title.indexOf(' by Crew') > 0) {
            _title = _title.substring(0, _title.indexOf(' by Crew'));
        }
        return (
            <SeaPieGraph
                n={n}
                title={_title}
                subTitle={subTitle}
                mode={mode}
                visible={visible}
                onClick={onClick}
                data={singleData}
                showAllLabels={true}
                sortData={false}
                colourPalette={colourPalette}
            />
        );
    }

    let labelsX = ((graph?.width ?? 0) - totalLabelsWidth) / 2;
    let previousLabelWidth = 0;

    if (mode === 'pdf') {
        return (
            <SeaGraph
                mode={mode}
                n={n}
                colourMode={graph?.colourMode}
                width={graph?.width}
                height={(graph?.height ?? 0) + 40}
            >
                {data.map((item, index: number) => {
                    const y = grid.yStart + index * (grid.yBarHeight + grid.yBarSpacing);
                    return (
                        <SvgText
                            key={item.name}
                            text={item.name}
                            x={grid.left - yLabelWidth}
                            y={y + grid.yBarHeight / 2 + 5}
                            maxWidth={yLabelWidth - 4}
                            fill={graph?.axisLabelColour}
                            forPdf={true}
                            style={reporting.pdf.label}
                        />
                    );
                })}
                {xAxis?.values.map((value: number, index: number) => {
                    const x = grid.left + ((index / xAxis.n) * grid.w) - 0.5; // (the -0.5 allows pixel perfect rendering)
                    return (
                        <React.Fragment key={`${index}_${value}`}>
                            <Line x1={x} y1={grid.top} x2={x} y2={grid.bottom} stroke={graph?.lineColour} />
                            <Text
                                x={x}
                                y={grid.bottom + 20}
                                fill={graph?.axisLabelColour}
                                style={reporting.pdf.xValue}
                            >
                                {value}
                            </Text>
                        </React.Fragment>
                    );
                })}
                {data.map((item, index: number) => {
                    const y = grid.yStart + index * (grid.yBarHeight + grid.yBarSpacing);
                    let total = 0;
                    return (
                        <React.Fragment key={item.name}>
                            {item.values?.map((value, valueIndex) => {
                                if (value <= 0) {
                                    return null;
                                }
                                const x = grid.left + ((total / xAxis.range) * grid.w);
                                const w = (value / xAxis.range) * grid.w;
                                total += value;
                                return (
                                    <React.Fragment key={`${valueIndex}_${value}`}>
                                        <Rect
                                            x={x}
                                            y={y}
                                            width={w}
                                            height={grid.yBarHeight}
                                            fill={graph?.renderColour(valueIndex)}
                                            //fillOpacity={0.5}
                                        />
                                        {reporting.allowBarValue(value, xAxis.range) &&
                                            <Text
                                                style={reporting.pdf.barValue}
                                                x={x + w - 8}
                                                y={y + (grid.yBarHeight / 2) + 4}
                                                fill={graph?.renderTextColour(valueIndex)}
                                            >
                                                {renderValue(value)}
                                            </Text>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                {totals && categories?.map((category, categoryIndex) => {
                    if (category === undefined) {
                        return undefined; // Skip undefined category
                    }
                    if (previousLabelWidth) {
                        labelsX += previousLabelWidth * pdfLabelMultiplier;
                        labelsX += reporting.labelBox.w + reporting.labelBox.textGap + reporting.labelBox.gap;
                    }
                    previousLabelWidth = labelWidthsForPdf ? labelWidthsForPdf[categoryIndex] : 0;
                    return (
                        <React.Fragment key={category}>
                            <Rect
                                x={labelsX}
                                y={(graph?.height ?? 0) - (graph?.padding ?? 0) - reporting.labelBox.h + 8}
                                width={reporting.labelBox.w}
                                height={reporting.labelBox.h}
                                fill={graph?.renderColour(categoryIndex)}
                            />
                            <Text
                                x={labelsX + reporting.labelBox.w + reporting.labelBox.textGap}
                                y={(graph?.height ?? 0) - (graph?.padding ?? 0) + 8 - 6 + 1}
                                fill={graph?.axisLabelColour}
                                style={reporting.pdf.label}
                            >
                                {category}
                            </Text>
                        </React.Fragment>
                    );
                })}
            </SeaGraph>
        );
    }

    return (<>
        <SeaGraph
            title={title}
            subTitle={subTitle}
            mode={mode}
            n={n}
            divStyle={graph?.divStyle}
            onClick={onClick}
            onMouseOut={() => {
                setActiveIndex(-1);
                setActiveValueIndex(-1);
                setHoverInfo(undefined);
            }}
            colourMode={graph?.colourMode}
            width={graph?.width}
            height={graph?.height}
            showHeader={graph?.showHeader}
            padding={graph?.padding}
        >
            <g className="grid">
                {xAxis.values.map((value: number, index: number) => {
                    const x = grid.left + ((index / xAxis.n) * grid.w) - 0.5; // (the -0.5 allows pixel perfect rendering)
                    return (
                        <React.Fragment key={`${index}_${value}`}>
                            <line x1={x} y1={grid.top} x2={x} y2={grid.bottom} stroke={graph?.lineColour} />
                            <text
                                className="x-axis-value"
                                x={x}
                                y={grid.bottom + 20}
                                fill={graph?.axisLabelColour}
                            >
                                {value}
                            </text>
                        </React.Fragment>
                    );
                })}
            </g>
            <g
                style={{
                    '--x-axis': `${grid.left}px`,
                    '--order-delay': `${Math.round((5 / data.length) * 40)}ms`
                } as React.CSSProperties}
            >
                {categories && totals && data.map((item, index: number) => {
                    if (index >= displayRows) {
                        return undefined;
                    }
                    let y = grid.yStart + index * (grid.yBarHeight + grid.yBarSpacing);
                    let total = 0;
                    return (
                        <g
                            key={item.name}
                            style={{
                                '--order': index
                            } as React.CSSProperties}
                            className={`stacked-bar-graph${(activeIndex === index) ? ' active' : ''}`}
                        >
                            <SvgText
                                text={item.name}
                                x={grid.left - yLabelWidth}
                                y={y + grid.yBarHeight / 2 + 5}
                                maxWidth={yLabelWidth - 4}
                                fill={graph?.axisLabelColour}
                                className="y-label"
                                setHoverInfo={setHoverInfo}
                            />
                            <g className="bars">
                                {item.values?.map((value, valueIndex) => {
                                    if (value <= 0) {
                                        return null;
                                    }
                                    const x = grid.left + ((total / xAxis.range) * grid.w);
                                    const w = (value / xAxis.range) * grid.w;
                                    total += value;
                                    const onHover = () => {
                                        setActiveIndex(index);
                                        setActiveValueIndex(valueIndex);
                                        setHoverInfo({
                                            text: (
                                                `${renderValue(data[index].values?.[valueIndex] ?? 0)} / ${renderValue(totals.rows[index])} ${categories[valueIndex]} (${renderPercent((data[index].values?.[valueIndex] || 0) / totals.rows[index])})`
                                            ),
                                            x: x + w / 2,
                                            y: y - 4
                                        });
                                    };

                                    return (
                                        <g
                                            key={`${valueIndex}_${value}`}
                                            className={`in${activeValueIndex === valueIndex ? ' active-value' : ''}`}
                                        >
                                            <rect
                                                className="bar"
                                                x={x}
                                                y={y}
                                                width={w}
                                                height={grid.yBarHeight}
                                                fill={graph?.renderColour(valueIndex)}
                                                //fillOpacity={0.5}
                                            />
                                            {reporting.allowBarValue(value, xAxis.range) &&
                                                <text
                                                    className="bar-value"
                                                    x={x + w - 8}
                                                    y={y + (grid.yBarHeight / 2) + 4}
                                                    fill={graph?.renderTextColour(valueIndex)}
                                                >
                                                    {renderValue(value)}
                                                </text>
                                            }
                                            <rect
                                                x={x}
                                                y={y}
                                                width={w}
                                                height={grid.yBarHeight}
                                                fillOpacity={0}
                                                onMouseOver={(e) => {
                                                    onHover();
                                                }}
                                                onClick={(e) => {
                                                    if (isTouchDevice) {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onHover();
                                                    }
                                                }}
                                                onMouseOut={(e) => {
                                                    setActiveIndex(-1);
                                                    setActiveValueIndex(-1);
                                                    setHoverInfo(undefined);
                                                }}
                                            />
                                        </g>
                                    );
                                })}
                            </g>
                        </g>
                    );
                })}
            </g>
            {overflowRows > 0 &&
                <SvgText
                    text={`(+${overflowRows} more)`}
                    x={grid.left - yLabelWidth}
                    y={grid.bottom + 16}
                    maxWidth={yLabelWidth - 4}
                    fill={graph?.axisLabelColour}
                    fillOpacity={0.5}
                    className="y-label plus-more"
                    hoverText={`${isPlatform('desktop') ? 'Click' : 'Tap'} to view ALL data...`}
                    setHoverInfo={setHoverInfo}
                />
            }
            <g className="key bottom">
                {totals && categories?.map((category, categoryIndex) => {
                    if (category === undefined) {
                        return undefined; // Skip undefined category
                    }
                    if (previousLabelWidth) {
                        labelsX += previousLabelWidth * pdfLabelMultiplier;
                        labelsX += reporting.labelBox.w + reporting.labelBox.textGap + reporting.labelBox.gap;
                    }
                    previousLabelWidth = labelWidths ? labelWidths[categoryIndex] : 0;
                    const currentLabelsX = labelsX;
                    const onHover = () => {
                        setHoverInfo({
                            text: (
                                `${totals.categories[categoryIndex]} / ${totals.grandTotal} ${categories[categoryIndex]} (${renderPercent(totals.categories[categoryIndex] / totals.grandTotal)})`
                            ),
                            x: (
                                currentLabelsX + reporting.labelBox.w + reporting.labelBox.textGap + ((labelWidths[categoryIndex] ? labelWidths[categoryIndex] : 0) / 2)
                            ),
                            y: ((graph?.height ?? 0) - (graph?.padding ?? 0) - 12)
                        });
                    };
                    return (
                        <g
                            key={category}
                            className="label"
                            onMouseOver={(e) => onHover()}
                            onClick={(e) => {
                                if (isTouchDevice) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onHover();
                                }
                            }}
                            onMouseOut={(e) => {
                                setHoverInfo(undefined);
                            }}
                        >
                            <rect
                                x={labelsX}
                                y={(graph?.height ?? 0) - (graph?.padding ?? 0) - reporting.labelBox.h + 8}
                                width={reporting.labelBox.w}
                                height={reporting.labelBox.h}
                                fill={graph?.renderColour(categoryIndex)}
                            />
                            <text
                                x={labelsX + reporting.labelBox.w + reporting.labelBox.textGap}
                                y={(graph?.height ?? 0) - (graph?.padding ?? 0) + 8 - 6}
                                fill={graph?.axisLabelColour}
                                ref={((element) => {
                                    if (element) {
                                        let w = 0;
                                        if (element.getComputedTextLength !== undefined) {
                                            w = element.getComputedTextLength();
                                        } else if (element.getBBox !== undefined) {
                                            w = element.getBBox().width;
                                        }
                                        if (w) {
                                            setLabelWidths((current: number[]) => {
                                                if (current[categoryIndex] === undefined || current[categoryIndex] !== w) {
                                                    const array = [...current];
                                                    array[categoryIndex] = w;
                                                    return array;
                                                }
                                                return current;
                                            });
                                        }
                                    }
                                })}
                            >{category}</text>
                            <rect
                                x={labelsX}
                                y={(graph?.height ?? 0) - (graph?.padding ?? 0) - reporting.labelBox.h + 8}
                                width={
                                    reporting.labelBox.w +
                                    reporting.labelBox.textGap +
                                    reporting.labelBox.gap +
                                    (labelWidths[categoryIndex] ? labelWidths[categoryIndex] : 0)
                                }
                                height={reporting.labelBox.h}
                                fill="transparent"
                            />
                        </g>
                    )
                })}
            </g>
            {hoverInfo && isReady &&
                <SvgHoverInfo
                    graphWidth={(graph?.width ?? 0)}
                    graphHeight={(graph?.height ?? 0)}
                    x={hoverInfo.x}
                    y={hoverInfo.y}
                >
                    <SvgText
                        text={hoverInfo.text}
                        fill="white"
                        className="hover-text"
                    />
                </SvgHoverInfo>
            }
        </SeaGraph>
    </>);
};

export default SeaHorizontalStackedBarGraph;
