import React, { ReactNode } from 'react';
import './SeaInputError.css';

interface SeaInputErrorProps {
    children: ReactNode,
    zone?: 'blue' | 'white' | 'grey',
    alignLeft?: boolean
}

const SeaInputError: React.FC<SeaInputErrorProps> = ({ children, alignLeft, zone }) => {
    return (
        <div className={`sea-input-error ${children && 'show-error'} ${alignLeft ? 'left' : ''}`}>
            {children}
        </div>
    );
};

export default SeaInputError;
