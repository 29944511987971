import React, { ReactNode } from 'react';
import './SeaBreadcrumb.css';
import AnchorIcon from '../../assets/svg/Anchor';

interface SeaBreadcrumbProps {
    children?: ReactNode
}

const SeaBreadcrumb: React.FC<SeaBreadcrumbProps> = ({ children }) => {
    return (
        <div className="sea-breadcrumb">
            <AnchorIcon style={{ marginBottom: '-1px', marginRight: '3px' }}/>
            {
                children && React.Children.map(children, (child, index) => {
                    return <>
                        {(index > 0) ? <span className="slash">/</span> : null}
                        {child}
                    </>;
                })
            }
        </div>
    );
};

export default SeaBreadcrumb;
