import React from 'react';
import { formatValue, formatTextAreaText, formatDatetime } from '../../../../lib/util';
import { renderCategoryName, renderCategoryNames } from '../../../../lib/categories';
import { authoritiesNotified, incidentTypes, incidentWhoInvolvedTypes, injuryOutcomes } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { sharedState } from '../../../../shared-state/shared-state';
import { Incident, Injury } from '../../../../shared-state/HealthSafety/incidents';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import SeaAuthorship from '../../../../components/SeaAuthorship/SeaAuthorship';


interface CommonIncidentContentProps {
    showModal: boolean,
    incident: Incident
}

const CommonIncidentContent: React.FC<CommonIncidentContentProps> = ({showModal, incident}) => {
    const vessels = sharedState.vessels.use(showModal);
    const incidentCategories = sharedState.incidentCategories.use(showModal);
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const injuryTypes = sharedState.injuryTypes.use(showModal);
    const injuryLocations = sharedState.injuryLocations.use(showModal);

    return (
        <>
            <div className="info-image">
                <SeaFileImage
                    files={incident?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaAuthorship item={incident} />
            <h2 className="for-view-modal" style={{ marginTop: '0px' }}>
                Type
            </h2>
            <SeaGrid>
                <SeaGridCell label="Vessel / Facility" w="50">
                    {vessels?.byId && incident?.vesselId && vessels.byId[incident?.vesselId]?.name}
                </SeaGridCell>
                <SeaGridCell label="Type" w="50">
                    {formatValue(incidentTypes[incident?.type])}
                </SeaGridCell>
                <SeaGridCell label="Event Category" w="50">
                    {formatValue(renderCategoryName(incident?.categoryId, incidentCategories))}
                </SeaGridCell>
                <SeaGridCell label="Report #" w="50">
                    {formatValue(incident?.reportNum)}
                </SeaGridCell>
                {/* <SeaGridCell label="Authorship">
                    Created by Joe Blogs, 20 June 2023, 3:30pm
                    <br/>
                    Edited by Harry Longlongname, 21 June 2023, 9:05am
                </SeaGridCell> */}
            </SeaGrid>
            <div className="end-info-line"></div>
            <h2 className="for-view-modal">
                Personnel
            </h2>
            <SeaGrid>
                <SeaGridCell label="Types of personnel involved" w="50">
                    {formatValue(incident?.whoInvolvedTypes?.map((type: string) => {
                        return incidentWhoInvolvedTypes[type];
                    }).join(', '))}
                </SeaGridCell>
                <SeaGridCell label="First Reported By" w="50">
                    {formatValue(incident?.reportedBy)}
                </SeaGridCell>
                <SeaGridCell label="Role in the Event" w="50">
                    {formatValue(incident?.role)}
                </SeaGridCell>
                <SeaGridCell label="Person(s) involved" w="50">
                    {formatValue(incident?.whoInvolved)}
                </SeaGridCell>
                <SeaGridCell label="Witnesses" w="50">
                    {formatValue(incident?.witnesses)}
                </SeaGridCell>
            </SeaGrid>
            <div className="end-info-line"></div>
            <h2 className="for-view-modal">
                Event
            </h2>
            <SeaGrid>
                <SeaGridCell label="Date &amp; Time" w="50">
                    {formatDatetime(incident?.whenEvent, ', ')}
                </SeaGridCell>
                <SeaGridCell label="Event Location" w="50">
                    {formatValue(incident?.location)}
                </SeaGridCell>
                <SeaGridCell label="Environmental Conditions" w="50">
                    {formatValue(incident?.conditions)}
                </SeaGridCell>
                <SeaGridCell label="Reported to Authorities?" w="50">
                    {formatValue(incident?.notifiedAuthorities && authoritiesNotified[incident?.notifiedAuthorities])}
                </SeaGridCell>
                <SeaGridCell label="Believed causes of this event" w="50">
                    {formatValue(renderCategoryNames(incident?.causeIds, incidentCauses))}
                </SeaGridCell>
                <SeaGridCell label="Damage To Property" w="50">
                    {formatValue(incident?.propertyDamage)}
                </SeaGridCell>
                <SeaGridCell label="Description" w="100">
                    {formatValue(formatTextAreaText(incident?.description))}
                </SeaGridCell>
                <SeaGridCell label="Initial Actions Taken" w="50">
                    {formatValue(formatTextAreaText(incident?.initialActions))}
                </SeaGridCell>
                <SeaGridCell label="Corrective Actions Suggested" w="50">
                    {formatValue(formatTextAreaText(incident?.prevention))}
                </SeaGridCell>
            </SeaGrid>
            {incident?.type === 'injury' && incident?.injuries && incident?.injuries?.length > 0 &&
                <>
                    <div className="end-info-line"></div>
                    <h2 className="for-view-modal">
                        Injur{incident.injuries.length === 1 ? 'y' : 'ies'}
                    </h2>
                    {incident.injuries.map((injury: Injury, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                {index > 0 &&
                                    <div className="end-info-line sub"></div>
                                }
                                <SeaGrid>
                                    <SeaGridCell label={incident.injuries && incident.injuries.length === 1 ? 'Person injured' : `Person injured (${index + 1})`} w="50">
                                        {formatValue(injury?.whoInjured)}
                                    </SeaGridCell>
                                    <SeaGridCell label="Type" w="50">
                                        {formatValue(renderCategoryNames(injury?.typeIds, injuryTypes))}
                                    </SeaGridCell>
                                    <SeaGridCell label="Location" w="50">
                                        {formatValue(renderCategoryNames(injury?.locationIds, injuryLocations))}
                                    </SeaGridCell>
                                    <SeaGridCell label="Outcome" w="50">
                                        {formatValue(injury?.outcome && injuryOutcomes[injury?.outcome])}
                                    </SeaGridCell>
                                </SeaGrid>
                            </React.Fragment>
                        );
                    })}
                </>
            }
            <div className="end-info-line"></div>
            <SeaGrid>
                <SeaGridCell label="Signature" w="100">
                    <SeaSignatureImage file={incident?.signature}/>
                </SeaGridCell>
            </SeaGrid>
        </>
    );
};

export default CommonIncidentContent;
