import { SeaCrewHour } from '../../components/SeaCrewHours/SeaCrewHours';
import { formatDay, toFloat } from '../../lib/util';
import { CustomFields, VoyageDay } from '../../shared-state/VesselLogbook/voyages';

export const renderTripType = (tripType: string | undefined) => {
    if (tripType) {
        if (tripType === 'multiDay') {
            return 'Multi day voyage';
        } else if (tripType === 'multiTrip') {
            return 'Multi trip voyage';
        }
    }
    return 'Single day voyage';
};

export const renderDayName = (whenFirstDay: number, addDays: number) => {
    const whenDay = whenFirstDay += addDays*24*60*60*1000;
    return formatDay(whenDay);
};

export const calculateTotalHours = (customHourFields: CustomFields[] | undefined) => {
    let total = 0;
    customHourFields?.forEach((item) => {
        total += toFloat(item.value, 0);
    });
    return total;
};

export const calculateTotalVoyageHours = (days: VoyageDay[]) => {
    let total = 0;
    days?.forEach((day) => {
        total += calculateTotalHours(day.customHours);
    });
    return total;
};

export const combineVoyageCrewHours = (days?: VoyageDay[]) => {
    const crewHours = [] as SeaCrewHour[];
    days?.forEach((day) => {
        if (!day.crewHours) {
            return;
        }
        //total += calculateTotalHours(day.customHours);
        if (Array.isArray(day.crewHours)) {
            day.crewHours?.forEach((item) => {
                let index = -1;
                for (let i = 0; i < crewHours.length; i++) {
                    if (crewHours[i].id === item.id) {
                        index = i;
                        break;
                    }
                }
                if (index === -1) {
                    crewHours.push({
                        ...item
                    });
                } else {
                    crewHours[index].hours += item.hours;
                }
            });
        } else {
            Object.entries(day.crewHours).forEach(([key, value]) => {
                let index = -1;
                for (let i = 0; i < crewHours.length; i++) {
                    if (crewHours[i].id === key) {
                        index = i;
                        break;
                    }
                }
                if (index === -1) {
                    crewHours.push({
                        id: key,
                        hours: value.hours
                    });
                } else {
                    crewHours[index].hours += value.hours;
                }
            })
        }
    });
    return crewHours;
};
