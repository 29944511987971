import React from 'react';
import { IonSelectOption } from '@ionic/react';
import { formatEmailReminder } from '../../lib/util';
import SeaSelect from '../SeaSelect/SeaSelect';
import './SeaSelectEmailReminder.css';

interface SeaSelectEmailReminderProps {
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    value?: string,
    selectedText?: string,
    error?: string,
    disabled?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    oncancel?: (e: CustomEvent) => void
    safetyMeetingOptions?: boolean,
}

const SeaSelectEmailReminder = React.forwardRef<HTMLInputElement, SeaSelectEmailReminderProps>(({ zone, name, label, value, selectedText, error, disabled, onchange, onblur, onfocus, oncancel, safetyMeetingOptions }, forwardedRef:any) => {
    return (
        <SeaSelect
            ref={forwardedRef}
            zone={zone}
            label={label}
            name={name}
            disabled={disabled}
            value={value}
            selectedText={selectedText}
            error={error}
            onchange={onchange}
            onblur={onblur}
            onfocus={onfocus}
            oncancel={oncancel}
        >
            <IonSelectOption value="">No Reminder Set</IonSelectOption>
            
            {safetyMeetingOptions ? <>
                <IonSelectOption value="0d">{formatEmailReminder('0d')}</IonSelectOption>
                <IonSelectOption value="1d">{formatEmailReminder('1d')}</IonSelectOption>
                <IonSelectOption value="2d">{formatEmailReminder('2d')}</IonSelectOption>
                <IonSelectOption value="3d">{formatEmailReminder('3d')}</IonSelectOption>
                <IonSelectOption value="7d">{formatEmailReminder('7d')}</IonSelectOption>
                <IonSelectOption value="14d">{formatEmailReminder('14d')}</IonSelectOption>
                <IonSelectOption value="1m">{formatEmailReminder('1m')}</IonSelectOption>
            </> : <>
                <IonSelectOption value="1d">{formatEmailReminder('1d')}</IonSelectOption>
                <IonSelectOption value="7d">{formatEmailReminder('7d')}</IonSelectOption>
                <IonSelectOption value="14d">{formatEmailReminder('14d')}</IonSelectOption>
                <IonSelectOption value="1m">{formatEmailReminder('1m')}</IonSelectOption>
                <IonSelectOption value="2m">{formatEmailReminder('2m')}</IonSelectOption>
                <IonSelectOption value="3m">{formatEmailReminder('3m')}</IonSelectOption>
                <IonSelectOption value="6m">{formatEmailReminder('6m')}</IonSelectOption>
            </>}
            
        </SeaSelect>
    );
});

export default SeaSelectEmailReminder;
